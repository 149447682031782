
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import ClientCard from "./ClientCard.vue";

import { ClientList } from "@/property/shared/models/ClientList";

@Component<ClientGrid>({
  components: {
    ClientCard
  }
})
export default class ClientGrid extends Vue {
  @Prop({
    default() {
      return new ClientList();
    }
  })
  private readonly clients!: ClientList;
  @Prop({ default: "" }) private readonly search!: string;

  @Watch("search")
  private onSearchChanged(search: string) {
    this.clients.search = search;
  }

  private mounted() {
    this.load();
  }

  private load() {
    if (this.clients.empty) {
      this.clients.load();
    }
  }
}
