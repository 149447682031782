
import Vue from "vue";
import Component from "vue-class-component";

import { SettingsListViewModel } from "../vms/SettingsListViewModel";
import { SettingsListPresenter } from "../presenters/SettingsListPresenter";
import { SettingsListController } from "../controllers/SettingsListController";
import { SettingsManager } from "../interactors/SettingsManager";
import { SettingsManagerGraphQLGateway } from "../../gateways/graphql/SettingsManagerGraphQLGateway";
import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { UserSettings } from "../../storage/UserSettings";
import { LocalStorage } from "../../storage/LocalStorage";

import Page from "@/components/layout/Page.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Section from "@/components/layout/Section.vue";
import Grid from "@/components/layout/Grid.vue";

import IconButton from "@/components/basic/IconButton.vue";

import PaginatedDatatable from "@/components/collection/PaginatedDatatable.vue";

@Component<SettingsListView>({
  components: {
    Page,
    Row,
    Column,
    Section,
    Grid,
    IconButton,
    PaginatedDatatable
  },
  watch: {
    "vm.goBack"(go: boolean) {
      if (go) {
        this.$router.back();
      }
    },
    "vm.selectedGlobalSetting"(key: string) {
      this.$router.push({
        name: "global-setting",
        params: { settingKey: key }
      });
    },
    "vm.selectedClientSetting"(key: string) {
      this.$router.push({
        name: "client-setting",
        params: { settingKey: key }
      });
    }
  }
})
export default class SettingsListView extends Vue {
  private vm = new SettingsListViewModel();

  get controller() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );

    return new SettingsListController(
      new SettingsListPresenter(this.vm),
      new SettingsManager(new SettingsManagerGraphQLGateway(connection)),
      new UserSettings(new LocalStorage())
    );
  }

  private mounted() {
    this.controller.init();
  }
}
