import { Model } from "./Model";

export abstract class ModelCollection<M extends Model<E>, E> {
  public elements: M[] = [];

  public get notEmpty() {
    return !this.empty;
  }

  public get empty() {
    return this.length === 0;
  }

  public get length() {
    return this.elements.length;
  }

  public at(index: number) {
    if (index < 0 || index >= this.elements.length) {
      throw new Error("Index out of bounds");
    }

    return this.elements[index];
  }

  public contains(model: M) {
    return (
      this.elements.includes(model) || this.elements.some(e => e.equals(model))
    );
  }

  public remove(model: M) {
    this.elements = this.elements.filter(e => !e.equals(model));
  }

  public addToFront(model: M) {
    this.elements.unshift(model);
  }

  public add(model: M) {
    this.elements.push(model);
  }

  public copy(): ModelCollection<M, E> {
    const collectionCopy = this.newInstance();
    collectionCopy.from(this.toEntities());
    return collectionCopy;
  }

  public toEntities(): E[] {
    return this.elements.map(e => e.toEntity());
  }

  public collect(models?: M[]): void {
    this.elements = models?.map(m => m) ?? [];
  }

  public abstract from(entities?: E[]): void;
  protected abstract newInstance(): ModelCollection<M, E>;
}
