
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import DynamicFormBuilder from "@/shared/form/DynamicFormBuilder.vue";

import { DynamicForm } from "@/shared/form/DynamicForm";
import { PlantFieldCategory } from "@/property/shared/models/PlantFieldCategory";
import { PlantFieldCategoryFormGenerator } from "../forms/PlantFieldCategoryFormGenerator";
import { Plant } from "@/property/shared/models/Plant";
import { RequestHandler } from "@/shared/utils/RequestHandler";

@Component<PlantFieldDataForm>({
  components: {
    DynamicFormBuilder
  }
})
export default class PlantFieldDataForm extends Vue {
  @Prop({ type: Object, required: true })
  protected readonly plant!: Plant;
  @Prop({ type: Object, required: true })
  protected readonly category!: PlantFieldCategory;

  protected form = new DynamicForm({});

  @Watch("category")
  protected onCategoryChanged() {
    this.buildForm();
  }

  public reset() {
    this.form.reset();
  }

  protected setFieldData(data: object, fieldName?: string, value?: unknown) {
    RequestHandler.handleFormUpdate(
      () => this.plant.updateFieldData(this.category.id ?? "", data),
      {
        form: this.form,
        fieldName,
        value
      }
    );
  }

  protected created() {
    this.buildForm();
  }

  protected async buildForm() {
    if (this.category && this.category.id) {
      const formGenerator = new PlantFieldCategoryFormGenerator(this.category);
      this.form = formGenerator.generateForm();

      await this.form.init();
      const categoryData = this.plant.data
        ? this.plant.data[this.category.id]
        : undefined;

      this.form.setData(categoryData);
    }
  }
}
