import { PersonAddressEntity } from "../entities/PersonEntity";
import { PersonAddress } from "./PersonAddress";

export class PersonAddressCollection {
  public addresses?: PersonAddress[];

  public get empty() {
    return (this.addresses?.length ?? 0) === 0;
  }

  public get first() {
    return this.empty || !this.addresses
      ? new PersonAddress()
      : this.addresses[0];
  }

  public from(addresses?: PersonAddressEntity[]) {
    this.addresses = addresses?.map(a => PersonAddress.from(a));
  }

  public static from(addresses?: PersonAddressEntity[]) {
    const collection = new PersonAddressCollection();
    collection.from(addresses);
    return collection;
  }
}
