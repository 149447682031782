export abstract class RequestModel<TData> {
  private used = false;
  private loadingState = false;
  private errorState = "";

  public get loading() {
    return this.loadingState;
  }

  public set loading(loading: boolean) {
    this.loadingState = loading;

    if (loading) {
      this.used = true;
    }

    if (this.onLoading) {
      this.onLoading(loading);
    }
  }

  public get loadingOrLoaded() {
    return this.used;
  }

  public set loaded(loaded: boolean) {
    this.used = loaded;
  }

  public get error() {
    return this.errorState;
  }

  public set error(error: string) {
    this.errorState = error;
    if (this.onError) {
      this.onError(error);
    }
  }

  public get failed() {
    return !this.loading && !!this.error;
  }

  public get success() {
    return this.used && !this.loading && !this.error;
  }

  public clearRequest() {
    this.used = false;
    this.loadingState = false;
  }

  public abstract from(data: TData): void;
}

export interface RequestModel<TData> {
  onLoading?(loading: boolean): void;
  onError?(error: string): void;
}
