
import Vue from "vue";
import Component from "vue-class-component";

import Row from "../layout/Row.vue";
import Column from "../layout/Column.vue";
import Icon from "./Icon.vue";
import StyledText from "./StyledText.vue";
import Button from "./Button.vue";

@Component<BasicIconMessage>({
  components: {
    Row,
    Column,
    Icon,
    StyledText,
    Button
  },
  props: {
    icon: {
      type: String,
      default: "info"
    },
    iconSize: {
      type: Number,
      default: 100
    },
    iconColor: {
      type: String,
      default: undefined
    },
    messageStyle: {
      type: String,
      default: "h2"
    },
    messageColor: {
      type: String,
      default: undefined
    },
    action: {
      type: String,
      default: undefined
    },
    actionColor: {
      type: String,
      default: undefined
    }
  }
})
export default class BasicIconMessage extends Vue {
  private emitAction() {
    this.$emit("action");
  }
}
