
import Vue from "vue";
import Component from "vue-class-component";

@Component<Value>({
  props: {
    value: {
      type: Object,
      default() {
        return { label: "Label", value: undefined };
      }
    },
    label: {
      type: String,
      default: undefined
    },
    v: {
      type: String,
      default: undefined
    },
    noDataText: {
      type: String,
      default: undefined
    },
    displayTitle: {
      type: Boolean,
      default: true
    },
    bold: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    },
    labelToValueGap: {
      type: Boolean,
      default: false
    }
  }
})
export default class Value extends Vue {
  protected get rawValue() {
    return this.$props.v ?? this.$props.value?.value;
  }

  protected get text() {
    return this.rawValue || (this.$props.noDataText ?? "Nicht angegeben");
  }

  protected get valueClass() {
    return {
      overline: !this.rawValue,
      "font-weight-bold": this.$props.bold
    };
  }

  protected emitLinkClick() {
    this.$emit("click:link");
  }
}
