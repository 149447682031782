import { Request, Textfield, Selection } from "@/forms/ViewModelFormTypes";

export class PersonDetailViewModel {
  // Other
  public goBack = false;

  // Data
  public deletedPerson = "";

  // Input
  public preDegree: Textfield = {
    value: "",
    label: "Titel voran",
    loading: false,
    error: ""
  };

  public firstname: Textfield = {
    value: "",
    label: "Vorname",
    loading: false,
    error: ""
  };

  public lastname: Textfield = {
    value: "",
    label: "Nachname",
    loading: false,
    error: ""
  };

  public postDegree: Textfield = {
    value: "",
    label: "Titel danach",
    loading: false,
    error: ""
  };

  public email: Textfield = {
    value: "",
    label: "Email",
    loading: false,
    error: ""
  };

  public website: Textfield = {
    value: "",
    label: "Website",
    loading: false,
    error: ""
  };

  public phoneNumbers: PhoneNumberViewModel[] = [];
  public addresses: AddressViewModel[] = [];

  // Requests
  public loadPersonRequest: Request = { loading: false, error: "" };
  public editingPersonalDataRequest: Request = { loading: false, error: "" };
  public editingContactRequest: Request = { loading: false, error: "" };
  public editingAddressesRequest: Request = { loading: false, error: "" };
  public deletePersonRequest: Request = { loading: false, error: "" };

  // States (Visibility, Deactivated, Disabled)
  public editingPersonalData = false;
  public editingContact = false;
  public editingAddresses = false;
  public editingPersonalDataDisabled = false;
  public editingPersonalDataDeactivated = false;
  public editingContactDisabled = false;
  public editingContactDeactivated = false;
  public editingAddressesDisabled = false;
  public editingAddressesDeactivated = false;
  public deletePersonButtonVisible = true;
  public deletePersonButtonDisabled = false;
  public undeletablePersonInfoVisible = false;
  public undeletablePersonInfoDisabled = false;
}

interface PhoneNumberViewModel {
  id: string;
  context: Selection;
  areaCode: Selection;
  number: Textfield;
}

interface AddressViewModel {
  id: string;
  context: Selection;
  street: Textfield;
  zip: Textfield;
  city: Textfield;
  country: Selection;
}
