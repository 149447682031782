
import Vue from "vue";
import Component from "vue-class-component";

import GeneralPlantDataForm from "./widgets/GeneralPlantDataForm.vue";
import PlantLocation from "./widgets/PlantLocation.vue";
import UpdatePlantStructureForm from "./widgets/UpdatePlantStructureForm.vue";
import PlantFieldDataForm from "./widgets/PlantFieldDataForm.vue";
import PlantChecklistEntryList from "./widgets/PlantChecklistEntryList.vue";
import AssignmentForm from "@/inspection/assignment/widgets/AssignmentForm.vue";
import PlantNavigationButton from "./widgets/PlantNavigationButton.vue";

import { Plant } from "../shared/models/Plant";
import { PlantCollection } from "../shared/models/PlantCollection";

@Component<PlantPage>({
  components: {
    GeneralPlantDataForm,
    PlantLocation,
    UpdatePlantStructureForm,
    PlantFieldDataForm,
    PlantChecklistEntryList,
    AssignmentForm,
    PlantNavigationButton
  }
})
export default class PlantPage extends Vue {
  protected plant = new Plant();
  protected editStructureDialogVisible = false;
  protected createAssignmentDialogVisible = false;

  protected get plantId() {
    return parseInt(this.$route.params.plantId ?? "0", 10);
  }

  protected get title() {
    return `Anlage ${this.plant.name ? "'" + this.plant.name + "'" : ""}`;
  }

  protected get plantAsArray() {
    return PlantCollection.collect([this.plant]);
  }

  protected isLastOddCategoryIndex(index: number) {
    const categoryCount = this.plant.fieldCategoryCount;
    return index === categoryCount - 1 && categoryCount % 2 === 1;
  }

  protected applyPlantStructure(plant: Plant) {
    this.plant.from(plant.toEntity());
    this.editStructureDialogVisible = false;
  }

  protected async deletePlant() {
    if (await this.plant.delete()) {
      this.$router.back();
    }
  }

  protected displayPlantTickets() {
    this.$router.push({
      name: "tickets",
      query: { plant: this.plant.id?.toString() ?? "" }
    });
  }

  protected created() {
    this.init();
    this.load();
  }

  protected init() {
    this.plant.init({ id: this.plantId });
  }

  protected load() {
    this.plant.load();
  }
}
