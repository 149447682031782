
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import SyncTicket from "./SyncTicket.vue";

import { TicketList } from "@/inspection/shared/models/TicketList";
import { Synchronizer } from "./Synchronizer";
import { Ticket } from "@/inspection/shared/models/Ticket";
import { TypeChecker } from "../utils/TypeChecker";

@Component<SyncTicketList>({
  components: { SyncTicket }
})
export default class SyncTicketList extends Vue implements Synchronizer {
  @Prop({
    default() {
      return new TicketList(undefined, { dontClearOnLoad: true });
    }
  })
  protected readonly ticketList!: TicketList;

  public syncing = false;
  protected paused = false;

  public async reload(): Promise<void> {
    this.ticketList.clearRequest();
    this.ticketList.load(true, { excludeImages: true });
  }

  public async sync(): Promise<void> {
    const errors: string[] = [];

    for (const ticket of this.ticketList.tickets) {
      if (this.paused) {
        break;
      }

      try {
        await ticket.load(true);
        await ticket.create();

        if (ticket.completed) {
          await ticket.delete(true);
        }
      } catch (err) {
        if (TypeChecker.isError(err)) {
          errors.push(err.message);
        } else if (TypeChecker.isString(err)) {
          errors.push(err);
        } else {
          errors.push(
            `Error when uploading ticket with syncId '${ticket.syncId ?? ""}'`
          );
        }
      } finally {
        ticket.clearImages(true);
      }
    }

    await this.ticketList.removeSyncedTickets();

    if (errors.length > 0) {
      throw new Error(errors.join("\n"));
    }
  }

  public pause() {
    this.paused = true;
  }

  public resume() {
    this.paused = false;
  }

  protected async deleteTicket(ticket: Ticket) {
    if (await ticket.delete(true)) {
      this.ticketList.removeTicket(ticket);
    }
  }
}
