import { Request } from "@/forms/ViewModelFormTypes";

export class MapViewModel {
  public selectedProperty = "";
  public propertySearch = "";
  public propertyLocations: PropertyLocation[] = [];
  public reload = false;

  public mapCenter: PropertyLocation = { lat: 48.220778, lng: 16.3100205 };

  public loadPropertyLocationsRequest: Request = { loading: false, error: "" };
  // public loadDocumentMetaRequest: Request = { loading: false, error: "" };
}

interface PropertyLocation {
  id?: string;
  lat: number;
  lng: number;
}
