import { RecurringDate } from "../datastructures/RecurringDate";
import { TimeSpan } from "../datastructures/TimeSpan";

export class TypeChecker {
  public static isBoolean(obj: unknown): obj is boolean {
    return obj === true || obj === false;
  }

  public static isString(obj: unknown): obj is string {
    return typeof obj === "string";
  }

  public static isDate(obj: unknown): obj is Date {
    return obj instanceof Date;
  }

  public static isTimeSpan(obj: unknown): obj is TimeSpan {
    return obj instanceof TimeSpan;
  }

  public static isRecurringDate(obj: unknown): obj is RecurringDate {
    return obj instanceof RecurringDate;
  }

  public static isError(obj: unknown): obj is Error {
    return obj instanceof Error;
  }

  public static isPromise(obj: unknown): obj is Promise<unknown> {
    return obj instanceof Promise;
  }

  public static isObject(obj: unknown): obj is object {
    return typeof obj === "object" && obj !== null;
  }

  public static isNumber(obj: unknown): obj is number {
    return !isNaN(obj as number);
  }

  public static isArray<T>(obj: T | T[]): obj is T[] {
    return Array.isArray(obj);
  }

  public static isArrayBuffer(obj: unknown): obj is ArrayBuffer {
    const arrayBuffer = obj as ArrayBuffer;
    return arrayBuffer && arrayBuffer.byteLength !== undefined;
  }
}
