
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { TicketCollection } from "@/inspection/shared/models/TicketCollection";

@Component<TicketSelect>({
  components: {}
})
export default class TicketSelect extends Vue {
  @Prop({ type: Object, default: () => new TicketCollection() })
  protected readonly tickets!: TicketCollection;

  protected get items() {
    return (
      this.tickets.tickets?.map(t => ({
        value: t.id,
        text: `(${t.id ?? "???"}) - ${t.title}${
          t.exists ? "" : " (wird synchronisiert...)"
        }`,
        disabled: !t.exists
      })) ?? []
    );
  }

  protected goToTicket(id: number) {
    this.$router.push({
      name: "ticket",
      params: {
        ticketId: id.toString()
      }
    });
  }
}
