import { IAssignmentDetailPresenter } from "../controllers/AssignmentDetailController";
import { AssignmentDetailViewModel } from "../vms/AssignmentDetailViewModel";
import { AssignmentForm } from "@/forms/checklist/AssignmentForm";
import { FullAssignmentData } from "../interactors/AssignmentManager";
import { NumericalId } from "@/datastructures/NumericalId";
import { FormResponse } from "@/forms/FormResponse";
import { DateUtils } from "@/utils/DateUtils";
import { Authorizator } from "@/common/interactors/Authorizator";

export class AssignmentDetailPresenter implements IAssignmentDetailPresenter {
  public plantId: string = "";
  public assignmentId: string = "";
  public assignmentForm: AssignmentForm;

  public constructor(private vm: AssignmentDetailViewModel) {
    this.assignmentForm = new AssignmentForm(this.vm, this.formValidated);

    this.assignmentForm.init();
  }

  public init() {
    if (!this.assignmentId) {
      this.vm.goBack = !Authorizator.canCreateAssignments();
      this.vm.createAssignmentButtonVisible = Authorizator.canCreateAssignments();
      this.vm.editingDataDeactivated = true;
      this.vm.editingData = true;
    } else {
      this.vm.deleteAssignmentButtonVisible = Authorizator.canDeleteAssignments();
      this.vm.editingDataDeactivated = !Authorizator.canEditAssignments();
    }

    this.vm.goBack = !Authorizator.canViewAssignments();
  }

  // Data
  public set editingAssignmentData(editing: boolean) {
    this.vm.editingData = editing;
  }

  public get assignment(): FullAssignmentData {
    return {
      id: NumericalId.fromString(this.assignmentId),
      plantId: NumericalId.fromString(this.plantId),
      number: this.vm.number.value,
      date: new Date(this.vm.date.value),
      description: this.vm.description.value
    };
  }
  public set assignment(data: FullAssignmentData) {
    this.assignmentForm.setFieldValue("number", data.number);
    this.assignmentForm.setFieldValue("date", DateUtils.value(data.date));
    this.assignmentForm.setFieldValue("description", data.description);
  }

  // Responses
  public set createAssignmentResponse(response: FormResponse<NumericalId>) {
    this.vm.createAssignmentRequest = response;

    if (!response.loading && !response.error) {
      this.vm.createdAssignment = response.data.id.toString();
    }
  }

  public set loadAssignmentResponse(
    response: FormResponse<FullAssignmentData>
  ) {
    this.vm.loadAssignmentRequest = response;

    if (!response.loading && !response.error) {
      this.assignment = response.data;
    }
  }

  public set updateDataResponse(response: FormResponse<NumericalId>) {
    this.vm.editingDataRequest = response;

    if (!response.loading && !response.error) {
      this.editingAssignmentData = false;
    }
  }

  public set deleteAssignmentResponse(response: FormResponse<NumericalId>) {
    this.vm.deleteAssignmentRequest = response;

    if (!response.loading && !response.error) {
      this.vm.goBack = true;
    }
  }

  // Helper
  private formValidated(context: AssignmentDetailViewModel, valid: boolean) {
    context.createAssignmentButtonDisabled = !valid;
  }
}
