import {
  AssignmentManagerGateway,
  FullAssignmentData,
  AssignmentDataData,
  AssignmentCatalogRow
} from "@/expert/interactors/AssignmentManager";
import { NumericalId } from "@/datastructures/NumericalId";
import { Page } from "@/datastructures/Page";
import { PaginatedList, createEmptyPage } from "@/datastructures/PaginatedList";
import { Assignment } from "@/entities/Assignment";
import { LocalStorage } from "@/storage/LocalStorage";
import { PlantStorage } from "./PlantStorage";

export class AssignmentManagerCachedGateway
  implements AssignmentManagerGateway {
  private storage: PlantStorage = new PlantStorage(new LocalStorage());

  public constructor(private webGateway: AssignmentManagerGateway) {}

  public async loadAssignmentCatalog(
    plantId: NumericalId,
    page: Page,
    search: string
  ): Promise<PaginatedList<AssignmentCatalogRow>> {
    let assignments: PaginatedList<AssignmentCatalogRow> = createEmptyPage();

    try {
      assignments = await this.webGateway.loadAssignmentCatalog(
        plantId,
        page,
        search
      );

      this.storage.upsertAssignments(plantId.id, assignments.items);
    } catch (error) {
      assignments = this.storage.loadAssignmentsPaginated(plantId.id, page);

      if (assignments.totalCount === 0) {
        throw new Error("Keine Assignments zwischengespeichert");
      }
    }

    return assignments;
  }

  public loadAssignment(id: NumericalId): Promise<Assignment> {
    return this.webGateway.loadAssignment(id);
  }

  public loadAssignmentList(propertyId: NumericalId): Promise<Assignment[]> {
    return this.webGateway.loadAssignmentList(propertyId);
  }

  public createAssignment(data: FullAssignmentData): Promise<Assignment> {
    return this.webGateway.createAssignment(data);
  }

  public async getPlantIdByType(
    propertyId: NumericalId,
    type: string
  ): Promise<number> {
    let plantId = 0;

    try {
      plantId = await this.webGateway.getPlantIdByType(propertyId, type);
      this.storage.upsertPlantId(propertyId.id, type, plantId);
    } catch (error) {
      plantId = this.storage.loadPlantByType(propertyId.id, type);
    }

    return plantId;
  }

  public updateAssignmentData(data: AssignmentDataData): Promise<Assignment> {
    return this.webGateway.updateAssignmentData(data);
  }

  public deleteAssignment(id: NumericalId): Promise<Assignment> {
    return this.webGateway.deleteAssignment(id);
  }
}
