
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import SearchSelect from "@/components/form/SearchSelect.vue";

import { PlanCollection } from "../../models/PlanCollection";
import { Plan } from "../../models/Plan";
import { Property } from "../../models/Property";

@Component<PlanSelect>({
  components: {
    SearchSelect
  }
})
export default class PlanSelect extends Vue {
  @Prop({
    default() {
      return new Property();
    }
  })
  protected readonly property!: Property;
  @Prop({
    default() {
      return new Plan();
    }
  })
  protected readonly plan!: Plan;
  @Prop({ default: false, type: Boolean })
  protected readonly disabled!: boolean;
  @Prop({ default: false, type: Boolean })
  protected readonly hideDetails!: boolean;

  protected plans = new PlanCollection();

  @Watch("property")
  protected onPropertyChanged() {
    this.plans.clearRequest();
    this.load();
  }

  protected get planId() {
    return this.plan?.id ?? 0;
  }

  protected mounted() {
    this.load();
  }

  protected load() {
    this.plans.loadSelection(this.property);
  }

  protected emitSelect(plan: Plan) {
    this.$emit("select", plan);
  }
}
