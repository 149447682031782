import { Page } from "@/datastructures/Page";
import { PaginatedList, createEmptyPage } from "@/datastructures/PaginatedList";
import { AssignmentCatalogRow } from "@/expert/interactors/AssignmentManager";

export class AssignmentStorage {
  public constructor(private assignments: AssignmentCatalogRow[] = []) {}

  public loadAll(): AssignmentCatalogRow[] {
    return this.assignments;
  }

  public loadPaginated(page: Page): PaginatedList<AssignmentCatalogRow> {
    const assignmentCount = this.assignments.length;

    if (assignmentCount === 0) {
      return createEmptyPage();
    }

    let startIndex = page.itemsPerPage * (page.page - 1);
    let endIndex = startIndex + page.itemsPerPage;

    if (endIndex > assignmentCount) {
      const diff = endIndex - assignmentCount;
      endIndex = assignmentCount;

      if (endIndex <= startIndex) {
        startIndex = endIndex - (diff % page.itemsPerPage);
      }

      if (startIndex === endIndex) {
        startIndex -= page.itemsPerPage;
      }

      if (startIndex < 0) {
        startIndex = 0;
      }
    }

    return {
      items: this.assignments.slice(startIndex, endIndex),
      totalCount: assignmentCount
    };
  }

  public upsertAssignments(assignments: AssignmentCatalogRow[]) {
    for (const assignment of assignments) {
      this.upsertAssignment(assignment);
    }
  }

  public upsertAssignment(assignment: AssignmentCatalogRow) {
    const storedAssignmentIndex = this.assignments.findIndex(
      a => a.id === assignment.id
    );
    if (storedAssignmentIndex >= 0) {
      this.assignments[storedAssignmentIndex] = assignment;
    } else {
      this.assignments.push(assignment);
    }
  }
}
