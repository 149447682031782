import { File } from "./File";

export class Logo extends File {
  public context: string = "";

  public constructor(id: number, path: string, context: string) {
    super(id, path);
    this.context = context;
  }
}
