import {
  FormFieldDefinition,
  FormFieldType,
  SelectionItemDefinition
} from "./FormDefinition";
import { FormField } from "./FormData";
import { TimeSpan } from "../datastructures/TimeSpan";

export class FormFieldFactory {
  public static async createFieldAsync(
    fieldName: string,
    definition: FormFieldDefinition<unknown>
  ) {
    return {
      ...FormFieldFactory.preCreateField(fieldName, definition),
      label: definition.label + (definition.required ? " *" : ""),
      items: await this.getItems(definition.itemDefinition)
    };
  }

  public static preCreateField(
    fieldName: string,
    definition: FormFieldDefinition<unknown>
  ) {
    const initValue = this.getInitValue(definition);

    const field: FormField = {
      key: fieldName,
      label: definition.label + " (lädt...)",
      width: definition.width ?? "12",
      value: definition.defaultValue ?? initValue,
      text: "",
      loading: false,
      disabled: false,
      error: "",
      type: definition.type,
      multiline: definition.multiline ?? false,
      items: [],
      changedFirstTime: false,
      hint: definition.hint || "",
      required: definition.required,
      maxLength: definition.maxLength,
      multiple:
        definition.type === FormFieldType.MultiSelect ||
        definition.type === FormFieldType.TimeSpan
    };

    return field;
  }

  private static getInitValue(definition: FormFieldDefinition<unknown>) {
    return this.getDefaultValueForField(definition.type);
  }

  private static getDefaultValueForField(type: FormFieldType) {
    switch (type) {
      case FormFieldType.Selection:
      case FormFieldType.Text:
        return "";

      case FormFieldType.File:
        return null;
      case FormFieldType.Number:
        return 0;
      case FormFieldType.Time:
        return "00:00";
      case FormFieldType.Checkbox:
        return false;
      case FormFieldType.Date:
        return new Date();
      case FormFieldType.MultiSelect:
        return [] as unknown[];
      case FormFieldType.TimeSpan:
        return new TimeSpan(new Date(), new Date());

      default:
        return "";
    }
  }

  private static async getItems(itemDefinition?: SelectionItemDefinition) {
    if (itemDefinition) {
      return (await itemDefinition.items()).map(item => ({
        value: item[
          itemDefinition.valueKey ? itemDefinition.valueKey : "value"
        ] as string,
        text: item[
          itemDefinition.textKey ? itemDefinition.textKey : "text"
        ] as string,
        ...item
      }));
    } else {
      return [];
    }
  }
}
