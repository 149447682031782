var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('GmapMap',{ref:"map",attrs:{"center":_vm.center,"zoom":15,"options":{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false
  },"map-type-id":"roadmap"}},_vm._l((_vm.markers),function(mark,index){return _c('GmapMarker',{key:index,attrs:{"position":mark,"clickable":true},on:{"click":function($event){return _vm.$emit('click', mark)}}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }