import { Dictionary } from "../datastructures/Dictionary";

export interface FormDefinition<T> {
  [key: string]: FormFieldDefinition<T>;
}
export interface FormFieldDefinition<T> {
  label: string;
  required: boolean;
  type: FormFieldType;
  greaterThan?: number;
  precision?: number;
  width?: string;
  regex?: RegExp;
  error?: string;
  hint?: string;
  maxLength?: number;
  defaultValue?: any;
  multiline?: boolean;
  itemDefinition?: SelectionItemDefinition;
  validate?: (formData: T) => string | boolean;
}
export enum FormFieldType {
  Text,
  Number,
  Date,
  Selection,
  MultiSelect,
  Checkbox,
  Time,
  File,
  TimeSpan,
  Interval
}

export interface SelectionItemDefinition {
  items: () => Array<Dictionary<unknown>> | Promise<Array<Dictionary<unknown>>>;
  generator?: Generator;
  valueKey?: string;
  textKey?: string;
}
