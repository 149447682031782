
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { DynamicForm } from "./DynamicForm";
import { FormField } from "./FormData";
import { FormFieldType } from "./FormDefinition";

@Component<DynamicFormBuilder>({
  components: {}
})
export default class DynamicFormBuilder extends Vue {
  @Prop({
    default() {
      return new DynamicForm({});
    }
  })
  protected readonly form!: DynamicForm;
  @Prop({ type: Boolean, default: false })
  protected readonly responsive!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly emitOnInit!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly hideDetails!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly persistentHint!: boolean;

  @Watch("form")
  protected onFormChanged() {
    this.init();
  }

  protected get fields() {
    return Object.values(this.form.fields);
  }

  protected hasAppendSlot(field: FormField) {
    return !!this.$scopedSlots[`append-${field.key}`];
  }

  public validate() {
    this.emitValidate();
  }

  protected isTextfield(field: FormField) {
    return field.type === FormFieldType.Text && !field.multiline;
  }

  protected isTextareafield(field: FormField) {
    return field.type === FormFieldType.Text && field.multiline;
  }

  protected isNumberfield(field: FormField) {
    return field.type === FormFieldType.Number;
  }

  protected isCheckbox(field: FormField) {
    return field.type === FormFieldType.Checkbox;
  }

  protected isDatefield(field: FormField) {
    return field.type === FormFieldType.Date;
  }

  protected isSelect(field: FormField) {
    return field.type === FormFieldType.Selection;
  }

  protected isInterval(field: FormField) {
    return field.type === FormFieldType.Interval;
  }

  protected setFieldValue(fieldName: string, value: unknown) {
    this.form.setFieldValue(fieldName, value);
    this.emitChange(fieldName, value);
  }

  protected emitChange(fieldName?: string, value?: unknown) {
    const data = this.form.getData();
    this.$emit("change", data, fieldName, value);

    if (this.form.isValid()) {
      this.$emit("change:valid", data, fieldName, value);
    }
  }

  protected emitValidate() {
    this.$emit("validate", this.form.isValid());
  }

  protected mounted() {
    this.init();
  }

  protected init() {
    this.form.init();

    if (this.emitOnInit) {
      this.emitChange();
    }
  }
}
