
import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import EditableTextfield from "@/components/form/EditableTextfield.vue";
import EditableCheckbox from "@/components/form/EditableCheckbox.vue";
import EditableDatePicker from "@/components/form/EditableDatePicker.vue";
import EditableSelect from "@/components/form/EditableSelect.vue";
import { Dictionary } from "@/shared/datastructures/Dictionary";

@Component<AdditionalTicketData>({
  components: {
    Row,
    Column,
    EditableTextfield,
    EditableCheckbox,
    EditableDatePicker,
    EditableSelect
  },
  props: {
    fields: {
      type: Array,
      required: true
    },
    values: {
      type: Object,
      required: true
    },
    editing: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  }
})
export default class AdditionalTicketData extends Vue {
  protected values!: Dictionary<any>;

  protected dataChanged(key: string, value: any) {
    this.$emit("change", key, value);
  }

  protected getSelectOptions(options?: any) {
    return options?.map((o: any) => ({
      value: o.label,
      text: o.label
    }));
  }

  protected getValue(field: any) {
    if (this.values[field.key]) {
      return this.values[field.key];
    } else {
      switch (field.type) {
        case "bool":
          return false;
        case "number":
          return 0;
        case "date":
          return new Date();
        default:
          return "";
      }
    }
  }
}
