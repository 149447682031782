import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { GraphQLConnection } from "@/gateways/graphql/connection/GraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { ReportData } from "../../dtos/ReportData";
import { ReportSettings } from "../../dtos/ReportSettings";
import { ReportEntity } from "../../entities/ReportEntity";
import { ReportProvider } from "./ReportProvider";

export class ReportGraphQLProvider implements ReportProvider {
  public constructor(
    private connection: GraphQLConnection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    )
  ) {}

  public async loadReportByType(
    userId: number,
    assignmentId: number,
    reportType: string
  ): Promise<ReportEntity> {
    const result = await this.connection.query(
      "reportByType",
      {
        user_id: userId,
        assignment_id: assignmentId,
        report_type: reportType
      },
      [
        "id",
        "format",
        "content",
        "settings",
        { name: "assignment", fields: ["id"] },
        { name: "file", fields: ["id", "path"] }
      ]
    );

    const data = result.data;

    return {
      id: data.id,
      type: data.format,
      data: data.content,
      settings: data.settings,
      assignment: data.assignment
        ? {
            id: data.assignment.id
          }
        : undefined,
      file: data.file
        ? {
            id: data.file.id,
            path: data.file.path
          }
        : undefined
    };
  }

  public async loadReportSettings(
    userId: number,
    assignmentId: string,
    reportType: string
  ): Promise<ReportSettings> {
    const result = await this.connection.query(
      "reportByType",
      {
        user_id: userId,
        assignment_id: assignmentId,
        report_type: reportType
      },
      ["settings"]
    );

    return JSON.parse(result.data.settings || "{}");
  }

  public async loadReportData(
    userId: number,
    assignmentId: string,
    reportType: string
  ): Promise<ReportData> {
    const result = await this.connection.query(
      "reportByType",
      {
        user_id: userId,
        assignment_id: assignmentId,
        report_type: reportType
      },
      ["content", { name: "file", fields: ["path"] }]
    );

    return {
      data: JSON.parse(result.data.content),
      url: result.data.file?.path ?? ""
    };
  }

  public async updateReportSettings(
    settings: string,
    assignmentId: number,
    reportType: string,
    reportId: number
  ): Promise<string> {
    const result = await this.connection.mutation(
      "updateReportSettings",
      {
        input: {
          assignment_id: assignmentId,
          report_type: reportType,
          report_id: reportId,
          settings
        }
      },
      []
    );

    return result.data;
  }

  public async updateReportData(
    data: string,
    assignmentId: number,
    reportType: string
  ): Promise<string> {
    const result = await this.connection.mutation(
      "updateReportData",
      {
        input: {
          assignment_id: assignmentId,
          report_type: reportType,
          data
        }
      },
      [],
      1200000
    );

    return result.data;
  }

  public async storeReport(
    assignmentId: number,
    reportType: string
  ): Promise<number> {
    const result = await this.connection.mutation(
      "storeReport",
      { input: { assignment_id: assignmentId, report_type: reportType } },
      []
    );

    return result.data;
  }

  public async generateSimpleReport(
    assignmentId: number,
    reportType: string
  ): Promise<string> {
    const result = await this.connection.mutation(
      "exportTicketsAsCSV",
      { assignment_id: assignmentId },
      [],
      60000
    );

    return result.data;
  }
}
