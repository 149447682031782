
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import TicketCard from "@/inspection/ticket/widgets/TicketCard.vue";
import TicketPriorityIcon from "@/inspection/ticket/widgets/TicketPriorityIcon.vue";

import { ChecklistEntry } from "@/inspection/shared/models/ChecklistEntry";
import { Ticket } from "@/inspection/shared/models/Ticket";
import { Dictionary } from "@/datastructures/Dictionary";

@Component<ChecklistEntryTicket>({
  components: { TicketCard, TicketPriorityIcon }
})
export default class ChecklistEntryTicket extends Vue {
  @Prop({ type: Object, default: () => new ChecklistEntry() })
  protected readonly entry!: ChecklistEntry;
  @Prop({ type: Object, default: () => new Ticket() })
  protected readonly ticket!: Ticket;

  protected ticketClicked() {
    if (this.ticket.exists) {
      const params: Dictionary<string> = {
        ticketId: this.ticket.id?.toString() ?? ""
      };

      if (this.entry.exists) {
        params.entry = this.entry.id?.toString() ?? "";
      }

      const routeData = this.$router.resolve({
        name: "ticket",
        params
      });
      window.open(routeData.href, "_blank");
    }
  }
}
