
import Vue from "vue";
import Component from "vue-class-component";

import Checkbox from "@/components/form/Checkbox.vue";

@Component<CheckboxGroup>({
  components: {
    Checkbox
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          label: "Label",
          value: [],
          error: "",
          loading: false,
          items: []
        };
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
export default class CheckboxGroup extends Vue {
  private emitChange(value: string[]) {
    this.$emit("change", value);
  }
}
