import { IAxiosLogger } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { AxiosResponse } from "axios";
import { ILogger } from "./ILogger";

export class AxiosLogger implements IAxiosLogger {
  private logger: ILogger;

  constructor(logger: ILogger) {
    this.logger = logger;
  }

  public logRequest(url: string, body?: any): void {
    this.logger.log("Requested:", url, body);
  }
  public logResponse(response: AxiosResponse<any>): void {
    this.logger.log("Responded:", response);
  }
  public logError(reason: any): void {
    this.logger.error(reason);
  }
}
