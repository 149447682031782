
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { Client } from "@/property/shared/models/Client";
import { Authorizator } from "@/common/interactors/Authorizator";

@Component<DeleteClientButton>({
  components: {}
})
export default class DeleteClientButton extends Vue {
  @Prop({
    default() {
      return new Client();
    }
  })
  protected readonly client!: Client;

  protected get canDeleteClients() {
    return Authorizator.canDeleteClients();
  }

  protected async deleteClient() {
    if (await this.client.del()) {
      this.$emit("deleted", this.client);
    }
  }
}
