import { SelectItem } from "@/shared/datastructures/SelectItem";
import { PlantType } from "../settings/PlantTypeSettings";
import { PropertySettingsContainer } from "../settings/PropertySettingsContainer";

export type PlantTypeSelectItem = SelectItem<string, PlantType>;
export type PlantFieldTypeSelectItem = SelectItem<string, string>;

export class PropertySelectionItemLoader {
  public static loadPlantTypes(): PlantTypeSelectItem[] {
    const types = PropertySettingsContainer.plantTypes.allTypes();

    return types
      .filter(t => !!t.id)
      .map(t => ({
        value: t.id!,
        text: t.name!,
        item: t
      }));
  }

  public static loadPlantFieldTypes(): PlantFieldTypeSelectItem[] {
    return [
      {
        text: "Text",
        value: "string",
        item: "string"
      },
      {
        text: "Zahl",
        value: "number",
        item: "number"
      },
      {
        text: "Checkbox (Ja/Nein)",
        value: "bool",
        item: "bool"
      },
      {
        text: "Datum",
        value: "date",
        item: "date"
      },
      {
        text: "Auswahl",
        value: "select",
        item: "select"
      },
      {
        text: "Intervall",
        value: "interval",
        item: "interval"
      }
    ];
  }
}
