import { Address } from "./Address";
import { PhoneNumber } from "./PhoneNumber";
import { Employee } from "./Employee";

export class Person {
  public preTitle: string = "";
  public firstname: string = "";
  public lastname: string = "";
  public postTitle: string = "";

  public email: string = "";
  public website: string = "";
  public employee: Employee = new Employee(0);
  public phoneNumbers: PhoneNumber[] = [];
  public addresses: Address[] = [];

  public constructor(public id: number) {}
}
