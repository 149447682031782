import { Comment } from "@/common/shared/models/Comment";
import { CommentRepository } from "@/common/shared/repositories/commentRepository/CommentRepository";
import { UserRepository } from "@/common/shared/repositories/userRepository/UserRepository";
import { UseCase } from "@/shared/datastructures/UseCase";
import { RequestHandler } from "@/shared/utils/RequestHandler";

export class AddCommentUseCase extends UseCase<Comment, Comment | undefined> {
  public constructor(
    private commentRepo: CommentRepository,
    private userRepo: UserRepository
  ) {
    super();
  }

  public async do(comment: Comment): Promise<Comment | undefined> {
    if (comment.exists) {
      throw new Error(
        "Kommentar existiert bereits und kann nicht mehr hinzugefügt werden"
      );
    }

    if (!comment.hasCommentable) {
      throw new Error("Der Kommentar muss einem Objekt zugeordnet sein");
    }

    if (await this.userRepo.isImpersonated()) {
      comment.impersonatedUser = await this.userRepo.getImpersonatedUser();
    }

    await RequestHandler.handle(
      this.commentRepo.addComment(comment),
      this.response
    );

    return this.response.result;
  }
}
