import { InMemoryCache } from "@/shared/datastructures/InMemoryCache";
import { PersonEntity } from "../../entities/PersonEntity";
import { PersonGraphQLProvider } from "./PersonGraphQLProvider";
import { PersonProvider } from "./PersonProvider";

export interface PersonRepository {
  getPersonSelection(clientId: number): Promise<PersonEntity[]>;
}

export class DefaultPersonRepository {
  private cachedPersonRequest = new InMemoryCache<Promise<PersonEntity[]>>();

  public constructor(
    private webProvider: PersonProvider = new PersonGraphQLProvider()
  ) {}

  public getPersonSelection(
    clientId: number,
    search: string
  ): Promise<PersonEntity[]> {
    const cacheKey = clientId.toPrecision() + search;

    return this.cachedPersonRequest.hasCache(cacheKey)
      ? this.cachedPersonRequest.cached(cacheKey)
      : this.cachedPersonRequest.cache(
          this.webProvider.loadPersonSelection(clientId, search),
          { key: cacheKey }
        );
  }
}
