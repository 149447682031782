
import { RequestHandler } from "@/shared/utils/RequestHandler";
import Vue from "vue";
import Component from "vue-class-component";

import { Prop, Watch } from "vue-property-decorator";

import { Plant } from "../../shared/models/Plant";
import { GeneralPlantDataForm as Form } from "../forms/GeneralPlantDataForm";

@Component<GeneralPlantDataForm>({
  components: {}
})
export default class GeneralPlantDataForm extends Vue {
  @Prop({ type: Object, default: () => new Plant() })
  protected readonly plant!: Plant;

  @Prop({ type: Object, default: () => new Form() })
  protected readonly form!: Form;

  @Watch("plant")
  protected onPlantChanged() {
    this.fillForm();
  }

  protected fieldChanged(fieldName: string, value: unknown) {
    RequestHandler.handleFormUpdate(
      () => this.plant.updateGeneralData(this.form.getData()),
      {
        form: this.form,
        fieldName,
        value
      }
    );
  }

  protected async created() {
    await this.form.init();
    this.fillForm();
  }

  protected fillForm() {
    this.form.setData(this.plant.toEntity());
  }

  public reset() {
    this.form.reset();
  }
}
