
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import App from "@/App.vue";

import { PropertyPartModel } from "../../models/PropertyPartModel";
import { PlantCollection } from "@/property/shared/models/PlantCollection";

@Component<PropertyPart>({
  components: {},
  props: {
    propertyId: {
      type: Number,
      required: true
    }
  }
})
export default class PropertyPart extends Vue {
  @Prop({ type: Object, required: true })
  protected readonly model!: PropertyPartModel;

  protected confirming = false;

  protected get keepFocus() {
    return (
      this.confirming ||
      this.model.deleteResponse.loading ||
      this.model.deleteResponse.error
    );
  }

  protected get displaySiblingPlantsBadge() {
    return App.instance?.isMobile ?? false;
  }

  protected listSiblingPlants(plants: PlantCollection) {
    return `(${plants.plants?.map(p => p.name ?? "Unbenannt").join(" | ")})`;
  }
}
