import { AccessResource } from "@/common/shared/models/Access";
import { Accesses } from "@/common/shared/models/Accesses";
import { AccessRepository } from "@/common/shared/repositories/accessRepository/AccessRepository";
import { UseCase } from "@/shared/datastructures/UseCase";
import { RequestHandler } from "@/shared/utils/RequestHandler";

export class LoadAccessesUseCase extends UseCase<
  LoadAccessesInput,
  Accesses | undefined
> {
  public constructor(private accessRepo: AccessRepository) {
    super();
  }

  public async do(input: LoadAccessesInput): Promise<Accesses | undefined> {
    if (!input.resource) {
      throw new Error(
        "Zum Laden einer Zugriffsliste muss die freigegebene Entität angegeben werden"
      );
    } else {
      if (!input.resource.id) {
        throw new Error(
          "Es muss eine gülige ID für die freigegebene Entität angegeben werden"
        );
      } else if (!input.resource.type) {
        throw new Error(
          "Es muss ein gültiger Typ für die freigegebene Entität angegeben werden"
        );
      }
    }

    await RequestHandler.handle(
      this.accessRepo.getAccessesOfResource(input.resource!),
      this.response
    );

    return this.response.result;
  }
}

export interface LoadAccessesInput {
  resource?: AccessResource;
}
