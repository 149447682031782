import { IPersonEditor, PersonDetail } from "../interactors/PersonEditor";
import { NumericalId } from "@/datastructures/NumericalId";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";

export class PersonDetailController {
  public constructor(
    private presenter: IPersonDetailPresenter,
    private personEditor: IPersonEditor
  ) {}

  public init(personId: string) {
    this.loadPerson(personId);
  }

  // Actions
  public deletePersonButtonClicked(personId: string) {
    NumericalId.onValidId(personId, id => {
      const request = this.personEditor.deletePerson(id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.deletePersonResponse = response)
      );
    });
  }

  // Requests
  public loadPerson(personId: string) {
    NumericalId.onValidId(personId, id => {
      const request = this.personEditor.loadPerson(id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadPersonResponse = response)
      );
    });
  }
}

export interface IPersonDetailPresenter {
  // Data
  person: PersonDetail;

  // Resonses
  loadPersonResponse: FormResponse<PersonDetail>;
  deletePersonResponse: FormResponse<number>;
}
