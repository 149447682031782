import { Request, Value, NumberValue } from "@/forms/ViewModelFormTypes";

export class DWSBaseSyncViewModel {
  public close: boolean = false;

  public newDocuments: NumberValue = { label: "Neue Reports", value: 0 };
  public updatedDocuments: NumberValue = {
    label: "Aktualisierte Reports",
    value: 0
  };
  public failedDocumentsVisible: boolean = false;
  public failedDocuments: string[] = [];

  // Requests
  public syncDWSBaseRequest: Request = { loading: false, error: "" };
}
