
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component<ClientSearchField>({
  components: {}
})
export default class ClientSearchField extends Vue {
  private emitSearch(search: string) {
    this.$emit("search", search);
  }
}
