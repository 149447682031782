import { Access } from "@/common/shared/models/Access";
import { AccessRepository } from "@/common/shared/repositories/accessRepository/AccessRepository";
import { UseCase } from "@/shared/datastructures/UseCase";
import { RequestHandler } from "@/shared/utils/RequestHandler";

export class DeleteAccessUseCase extends UseCase<Access, Access | undefined> {
  public constructor(private accessRepo: AccessRepository) {
    super();
  }

  public async do(access: Access): Promise<Access | undefined> {
    if (!access.exists) {
      throw new Error("Die zu löschende Freigabe muss eine ID haben");
    }

    await RequestHandler.handle(
      this.accessRepo.deleteAccess(access),
      this.response
    );

    return this.response.result;
  }
}
