
import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

@Component<Card>({
  components: {
    Row,
    Column
  },
  props: {
    title: {
      type: String,
      default: "Titel"
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "primary"
    },
    icon: {
      type: String,
      default: ""
    },
    maxHeight: {
      type: String,
      default: ""
    },
    padding: {
      type: Number,
      default: 4
    },
    expandable: {
      type: Boolean,
      default: false
    },
    startExpanded: {
      type: Boolean,
      default: false
    },
    elevation: {
      type: String,
      default: "4"
    },
    dense: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    }
  }
})
export default class Card extends Vue {
  protected expandable!: boolean;
  protected startExpanded!: boolean;
  protected dense!: boolean;
  protected expanded = false;

  protected emitAction() {
    this.$emit("action");
  }

  protected get contentStyle() {
    const hasMaxHeight = !!this.$props.maxHeight;

    return {
      maxHeight: hasMaxHeight ? this.$props.maxHeight : undefined,
      overflowY: hasMaxHeight ? "scroll" : undefined,
      marginTop: this.$props.fixed ? (this.dense ? "36px" : "52px") : undefined
    };
  }

  protected get titleStyles() {
    return {
      position: this.$props.fixed ? "fixed" : undefined,
      top: this.$props.fixed ? "0" : undefined,
      width: this.$props.fixed ? "100%" : undefined,
      zIndex: 1000,
      borderRadius: "2px 2px 0px 0px"
    };
  }

  protected get titleClasses() {
    return [this.headerColor, this.dense ? "py-0" : "py-2"];
  }

  protected get headerColor() {
    return !!this.$props.error ? "error" : this.$props.color;
  }

  protected get expandIcon() {
    return this.expanded ? "keyboard_arrow_down" : "keyboard_arrow_right";
  }

  protected mounted() {
    this.expanded = this.startExpanded;
  }

  protected expand() {
    this.expanded = !this.expanded;
  }
}
