import { websiteRegex } from "@/data/regex";
import { EnvironmentUtils } from "@/shared/utils/EnvironmentUtils";
import { FileUtils } from "@/shared/utils/FileUtils";
import { PlanEntity } from "../../entities/PlanEntity";
import { ManagedPlanPool } from "./ManagedPlanPool";
import { PlanGraphQLProvider } from "./PlanGraphQLProvider";
import { PlanProvider } from "./PlanProvider";

interface PlanRepositoryOptions {
  planCache?: ManagedPlanPool;
}

export interface PlanRepository {
  getPlan(id: number): Promise<PlanEntity>;
  getPlanSelection(
    propertyId: number,
    propertyIds?: number[],
    onlyOffline?: boolean
  ): Promise<PlanEntity[]>;
}

export class DefaultPlanRepository implements PlanRepository {
  public constructor(
    private webProvider: PlanProvider = new PlanGraphQLProvider(),
    private options?: PlanRepositoryOptions
  ) {}

  private get planCache() {
    return this.options?.planCache;
  }

  public async getPlan(id: number): Promise<PlanEntity> {
    const plan =
      (await this.planCache?.getById(id)) ??
      (await this.webProvider.loadPlan(id));

    if (
      plan.filePath &&
      websiteRegex.test(plan.filePath) &&
      !EnvironmentUtils.isLocalEnv()
    ) {
      try {
        const planImageFile = await FileUtils.fromUrl(
          plan.filePath,
          "plan.jpg",
          "image/jpeg"
        );
        const planImageString = await FileUtils.toBase64(planImageFile);

        plan.filePath = planImageString;
      } catch (e) {
        // tslint:disable-next-line: no-console
        console.log("Plan konnte nicht heruntergeladen werden");
      }
    }

    await this.planCache?.add(plan);

    return plan;
  }

  public async getPlanSelection(
    propertyId: number,
    propertyIds?: number[],
    onlyOffline?: boolean
  ): Promise<PlanEntity[]> {
    const plans = await this.webProvider.loadPlanSelection(
      propertyId,
      propertyIds,
      onlyOffline
    );

    if (this.planCache) {
      plans.forEach(p => {
        if (!this.planCache?.contains(p) && p.offline) {
          this.planCache?.add(p);
        }
      });
    }

    return plans;
  }
}
