import {
  PropertyPart,
  PropertyPartsAndPlants
} from "@/property/shared/dtos/PropertyPart";
import { Plant } from "@/property/shared/models/Plant";
import { PlantCollection } from "@/property/shared/models/PlantCollection";
import { PropertyReportContainer } from "@/property/shared/repositories/PropertyRepoContainer";
import { PropertySettingsContainer } from "@/property/shared/settings/PropertySettingsContainer";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { PropertyPartForm } from "../forms/PropertyPartForm";
import { ServerResponse } from "@/shared/datastructures/Response";

enum PropertyPartEditMode {
  None,
  Create,
  Edit
}

export class PropertyPartModel {
  public static from(data: PropertyPart, parent?: PropertyPartModel) {
    const part = new PropertyPartModel(
      data.id,
      data.name,
      data.type,
      data.hasChildren,
      parent
    );

    part.siblingPlants = PlantCollection.collectFrom(data.siblingPlants);

    return part;
  }

  public static fromArray(data: PropertyPart[], parent?: PropertyPartModel) {
    return data.map(p => PropertyPartModel.from(p, parent));
  }

  public children?: Array<PropertyPartModel | Plant>;
  public siblingPlants?: PlantCollection = new PlantCollection();

  public loadChildPartsResponse = new ServerResponse<PropertyPartsAndPlants>();
  public createOrEditPartResponse = new ServerResponse<number>();
  public deleteResponse = new ServerResponse<number>();

  private partForm: PropertyPartForm;
  private editMode: PropertyPartEditMode = PropertyPartEditMode.None;

  public constructor(
    public id: number,
    public name: string,
    public type: string,
    hasChildren: boolean,
    public parent?: PropertyPartModel
  ) {
    this.children = hasChildren ? [] : undefined;

    this.partForm = new PropertyPartForm();
    this.partForm.init();
  }

  public get isRoot() {
    return !this.parent;
  }

  public get labeledKey() {
    return "part-" + this.id;
  }

  public get hasPlants() {
    return this.children?.some(c => c instanceof Plant) ?? false;
  }

  public get hasSiblingPlants() {
    return this.siblingPlants?.notEmpty ?? false;
  }

  public get formValidity() {
    return this.partForm.isValid();
  }

  public get dialogVisibility() {
    return this.editMode !== PropertyPartEditMode.None;
  }

  public set dialogVisibility(visible: boolean) {
    if (visible) {
      this.editMode = PropertyPartEditMode.Create;
    } else {
      this.editMode = PropertyPartEditMode.None;
    }
  }

  public get dialogTitle() {
    switch (this.editMode) {
      case PropertyPartEditMode.Create:
        return "Gebäudeteil hinzufügen";
      case PropertyPartEditMode.Edit:
        return "Gebäudeteil bearbeiten";
      case PropertyPartEditMode.None:
        return "";
    }
  }

  public get submitButtonText() {
    switch (this.editMode) {
      case PropertyPartEditMode.Create:
        return "Hinzufügen";
      case PropertyPartEditMode.Edit:
        return "Ändern";
      case PropertyPartEditMode.None:
        return "";
    }
  }

  public get icon() {
    const partTypeIcon = PropertySettingsContainer.propertyPartTypes.getIcon(
      this.type
    );
    return partTypeIcon ? partTypeIcon : "remove";
  }

  public getFormField(fieldName: string) {
    return this.partForm.getField(fieldName);
  }

  public setFormField(fieldName: string, value: unknown) {
    this.partForm.setFieldValue(fieldName, value);
  }

  public openCreateDialog() {
    this.editMode = PropertyPartEditMode.Create;
    this.partForm.reset();
  }

  public openEditDialog() {
    this.editMode = PropertyPartEditMode.Edit;
    this.partForm.setFieldValue("type", this.type);
    this.partForm.setFieldValue("name", this.name);
  }

  public closeDialog() {
    this.editMode = PropertyPartEditMode.None;
    this.partForm.reset();
  }

  public async loadChildParts() {
    this.children = [];
    await RequestHandler.handle(
      PropertyReportContainer.propertyRepo.getPropertyChildPartsAndPlants(
        this.id
      ),
      this.loadChildPartsResponse,
      {
        onSuccess: data => {
          this.siblingPlants = PlantCollection.collectFrom(data.siblingPlants);
          this.addChildParts(
            PropertyPartModel.fromArray(data.parts, this),
            PlantCollection.collectFrom(data.plants).plants ?? []
          );
        }
      }
    );
  }

  public createOrEditPropertyPart() {
    if (this.editMode === PropertyPartEditMode.Create) {
      this.createPropertyPart();
    } else {
      this.edit();
    }
  }

  public delete() {
    RequestHandler.handle(
      PropertyReportContainer.propertyRepo.deletePropertyPart(this.id),
      this.deleteResponse,
      {
        onSuccess: _ => this.parent?.deleteChild(this)
      }
    );
  }

  private async createPropertyPart() {
    await this.loadChildParts();

    RequestHandler.handleFormUpdate(
      () =>
        PropertyReportContainer.propertyRepo.createPropertyPart(
          this.id,
          this.partForm.getData()
        ),
      { form: this.partForm },
      this.createOrEditPartResponse,
      {
        onSuccess: newPartId => {
          this.addChildPart(newPartId);
          this.closeDialog();
        }
      }
    );
  }

  private async edit() {
    RequestHandler.handleFormUpdate(
      () =>
        PropertyReportContainer.propertyRepo.editPropertyPart(
          this.id,
          this.partForm.getData()
        ),
      { form: this.partForm },
      this.createOrEditPartResponse,
      {
        onSuccess: _ => {
          const newData = this.partForm.getData();
          this.type = newData.type;
          this.name = newData.name;
          this.closeDialog();
        }
      }
    );
  }

  private addChildPart(newPartId: number) {
    const formData = this.partForm.getData();
    const newPart = new PropertyPartModel(
      newPartId,
      formData.name,
      formData.type,
      false,
      this
    );

    this.addChildParts([newPart], []);
  }

  public addChildParts(childParts: PropertyPartModel[], childPlants: Plant[]) {
    if (!childParts && !childPlants) {
      return;
    }

    if (!this.children) {
      this.children = [];
    }

    this.children.push(...childParts);
    this.children.push(...childPlants);
  }

  private deleteChild(childToDelete: PropertyPartModel) {
    if (this.children) {
      this.children = this.children.filter(c => c !== childToDelete);
    }
  }
}
