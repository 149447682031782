import { storageKeys } from "@/data/storageKeys";
import { Dictionary } from "@/shared/datastructures/Dictionary";
import { Settings } from "@/shared/datastructures/Settings";

export interface TicketStates {
  ticket_states: TicketState[];
}

export interface TicketState extends Dictionary<string> {
  name: string;
  state_id: string;
}

export class TicketStateSettings extends Settings<TicketStates> {
  protected get storageKey(): string {
    return storageKeys.ticket_states;
  }

  public allStates() {
    const settings = this.get();
    return settings.ticket_states ? settings.ticket_states : [];
  }
}
