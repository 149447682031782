export interface FileEntity {
  id?: number;
  syncId?: string;
  name?: string;
  path?: string;
  fileable?: FileableEntity;
  context?: string;
}

export interface FileableEntity {
  id?: number;
  type?: FileableType;
}

export type FileableType = "Plant" | "Ticket" | "Property" | "Client";

export function convertGraphQlToFileEntity(f: any): FileEntity {
  return {
    id: f.id,
    syncId: f.sync_id,
    name: f.name,
    path: f.path,
    fileable: {
      id: f.fileable_id,
      type: f.fileable_type
    },
    context: f.context
  };
}
