var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Grid',[_c('Row',{attrs:{"align-center":""}},[_c('Column',{attrs:{"grow":""}},[_c('PlanSelect',{attrs:{"hideDetails":"","plan":_vm.location.plan,"property":_vm.property},on:{"select":function($event){return _vm.selectPlan($event)}}})],1),(_vm.plant.exists)?_c('Column',{attrs:{"shrink":""}},[_c('RequestButton',{attrs:{"responsive":"","text":"Anlagenverortung laden","icon":"my_location","loading":_vm.plant.loading,"error":_vm.plant.error},on:{"click":function($event){return _vm.plant.load()}}})],1):_vm._e(),_c('Column',{attrs:{"shrink":""}},[_c('InfoTooltip',{attrs:{"top":""}},[_vm._v(" Mit einem Doppelklick können Sie die Ticketposition direkt setzen. "),_c('br'),_vm._v("Zum Zoomen am PC, halten Sie die Shift-Taste gedrückt. ")])],1)],1),(_vm.plantHasNoLocation)?_c('Row',[_c('Column',[_c('Alert',{attrs:{"type":"error","color":"error"}},[_c('Row',{attrs:{"wrap":""}},[_c('Column',{attrs:{"grow":""}},[_vm._v(" Die Anlage besitzt noch keine Verortung. Öffnen sie die Anlage und weisen Sie ihr eine Verortung zu. Klicken Sie anschließend erneut auf \"Anlagenverortung laden\", um die neue Verortung auf das Ticket zu übertragen. ")]),_c('Column',{attrs:{"shrink":""}},[_c('RequestButton',{attrs:{"displayIcon":"","filled":"","text":"Anlage öffnen","icon":"open_in_new","color":"error","outlined":false},on:{"click":function($event){return _vm.goToPlant(_vm.plant)}}})],1)],1)],1)],1)],1):_vm._e(),(_vm.location.hasPlan)?_c('Row',[_c('Column',{attrs:{"grow":""}},[_c('InteractivePlan',{attrs:{"center":"","path":_vm.location.planFilePath,"zoom":_vm.location.zoom,"marker":[
          {
            path: require('@/assets/BS_crosshairs_A.svg'),
            draggable: true,
            scale: 0.8,
            x: _vm.location.x,
            y: _vm.location.y
          }
        ]},on:{"positionChanged":function($event){return _vm.setLocation($event)},"zoom":function($event){_vm.location.zoom = $event},"confirm":function($event){return _vm.emitConfirm()}}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }