
import Vue from "vue";
import Component from "vue-class-component";

import InspectionChecklist from "./widgets/InspectionChecklist.vue";
import ProtectionGoalNiveau from "./widgets/ProtectionGoalNiveau.vue";
import CreateReportButton from "./widgets/CreateReportButton.vue";
import TicketSelect from "@/inspection/ticket/widgets/TicketSelect.vue";
import CreateTicketPage from "@/inspection/ticket/views/CreateTicketPage.vue";

import { Assignment } from "../shared/models/Assignment";
import { Ticket } from "../shared/models/Ticket";
import { ServerResponse } from "@/shared/datastructures/Response";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { InspectionRepoContainer } from "../shared/repositories/InspectionRepoContainer";
import { UserSettings } from "@/storage/UserSettings";

@Component<InspectionPage>({
  components: {
    InspectionChecklist,
    ProtectionGoalNiveau,
    CreateReportButton,
    TicketSelect,
    CreateTicketPage
  }
})
export default class InspectionPage extends Vue {
  protected assignment = new Assignment();
  protected createTicketDialog = false;

  protected get assignmentId() {
    return parseInt(this.$route.params.assignmentId, 10);
  }

  protected get checklist() {
    return this.assignment.checklist;
  }

  protected get propertyId() {
    return this.checklist?.propertyId;
  }

  protected get plantId() {
    return this.assignment.plant?.id?.toString();
  }

  protected get pageTitle() {
    return (
      "Begehung " +
      (this.assignment.number ? `'${this.assignment.number}'` : "")
    );
  }

  protected ticketCreated(ticket?: Ticket) {
    this.createTicketDialog = false;

    if (!ticket) {
      return;
    }

    ticket.tryLoadPeriodically();
    this.checklist?.addLooseTicket(ticket);
    // For performance reasons
    ticket.clearImages();
  }

  protected created() {
    this.assignment.init({ id: this.assignmentId });
    this.assignment.load({ includeChecklist: true });
  }

  // FIX!
  protected selectedFiles: File[] = [];
  protected importDialogVisible = false;
  protected ticketImportResponse = new ServerResponse<number[]>();

  protected get bluewatersClient() {
    return UserSettings.getClientId() === "4";
  }

  protected showImportDialog() {
    this.importDialogVisible = true;
  }

  protected closeImportDialog() {
    this.importDialogVisible = false;
    this.selectedFiles = [];
    this.ticketImportResponse.clear();
  }

  protected startImport(files: File[]) {
    this.selectedFiles = files;

    if (this.selectedFiles && this.selectedFiles.length > 0) {
      RequestHandler.handle(
        InspectionRepoContainer.assignmentRepo.importTickets(
          this.assignmentId,
          this.selectedFiles[0]
        ),
        this.ticketImportResponse,
        {
          onSuccess: () => (this.selectedFiles = [])
        }
      );
    }
  }
}
