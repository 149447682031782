
import Vue from "vue";
import Component from "vue-class-component";

import Block from "@/components/layout/Block.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Divider from "@/components/layout/Divider.vue";
import Accordion from "@/components/layout/Accordion.vue";

import Button from "@/components/basic/Button.vue";
import IconButton from "@/components/basic/IconButton.vue";

import Textarea from "@/components/form/Textarea.vue";
import Textfield from "@/components/form/Textfield.vue";
import Checkbox from "@/components/form/Checkbox.vue";
import Select from "@/components/form/Select.vue";
import MaterialIconSelector from "@/components/form/MaterialIconSelector.vue";

import { FormDefinition, FormFieldType } from "@/forms/Form";
import { DynamicForm } from "../../forms/DynamicForm";
import { Textfield as TextfieldData } from "@/forms/ViewModelFormTypes";
import { ArrayUtils } from "../../utils/ArrayUtils";
import { ObjectUtils } from "../../utils/ObjectUtils";
import { SettingField } from "../../admin/vms/SettingDetailViewModel";

@Component<SettingFields>({
  components: {
    Block,
    Grid,
    Row,
    Column,
    Button,
    IconButton,
    Divider,
    Textarea,
    Textfield,
    Checkbox,
    Select,
    MaterialIconSelector,
    Accordion,
    SettingFields: () => import("@/components/collection/SettingFields.vue")
  },
  props: {
    fields: {
      type: Object,
      default() {
        return {};
      }
    },
    parent: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    forceExtendable: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    fields(fields: any) {
      this.init();
    }
  }
})
export default class SettingFields extends Vue {
  private validFields: { [key: string]: boolean } = {};
  private listData: any = {};
  private fields!: any;

  private get visibleFields() {
    return Object.values(this.fields).filter((f: any) => !f.invisible);
  }

  private created() {
    this.init();
  }

  private init() {
    const lists = Object.values(this.$props.fields).filter(
      (field: any) => field.type === "list"
    );
    for (const list of lists as any) {
      this.$set(this.listData, list.key, ObjectUtils.deepCopy(list));
    }
  }

  private emitChange(key: string, value: string) {
    this.validFields[key] = !!value;
    this.$emit("change", [key], value);
    this.$emit(
      "validate",
      Object.values(this.validFields).every(v => v)
    );
  }

  private emitReset(key: string) {
    this.$emit("reset", key);
  }

  private addLine(field: any) {
    this.$emit("addLine", [field.key], {
      disabled: false,
      deactivated: false,
      value: ObjectUtils.deepCopy(field.fields)
    });
  }

  private addSubLine(
    key: string,
    index: number,
    keyChain: Array<string | number>,
    value: any
  ) {
    keyChain.unshift(index);
    keyChain.unshift(key);
    this.$emit("addLine", keyChain, value);
  }

  private removeLine(listKey: string, index: number) {
    this.$emit("removeLine", [listKey], index);
  }

  private removeSubLine(
    key: string,
    index: number,
    keyChain: Array<string | number>,
    indexToRemove: number
  ) {
    keyChain.unshift(index);
    keyChain.unshift(key);
    this.$emit("removeLine", keyChain, indexToRemove);
  }

  private toggleDeactivated(listKey: string, index: number) {
    this.$emit("toggleDeactivated", [listKey], index);
  }

  private toggleDeactivatedSub(
    key: string,
    index: number,
    keyChain: Array<string | number>,
    indexToRemove: number
  ) {
    keyChain.unshift(index);
    keyChain.unshift(key);
    this.$emit("toggleDeactivated", keyChain, indexToRemove);
  }

  private subFieldChanged(
    key: string,
    index: number,
    keyChain: Array<string | number>,
    value: string
  ) {
    keyChain.unshift(index);
    keyChain.unshift(key);

    this.$emit("change", keyChain, value);
  }

  private getValueOfFirstField(fields: object, defaultValue: string) {
    const fieldData = Object.values(fields);

    if (fieldData.length > 0) {
      return fieldData[0].value;
    } else {
      return defaultValue;
    }
  }
}
