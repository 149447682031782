export const storageKeys = {
  clientId: "clientId",
  clientName: "clientName",
  userId: "userId",
  userName: "userName",
  token: "token",
  role: "role",
  rolePrio: "rolePrio",
  permissions: "permissions",
  guestPermissions: "guest_permissions",
  selectedProperty: "selectedProperty",
  selectedPropertyData: "selectedPropertyData",
  reportContent: "report_content",
  ticketTemplates: "ticket_templates",
  generalDocumentMeta: "general_document_meta",
  propertyDocumentMeta: "property_document_meta",
  lastTicketPlan: "last_ticket_plan",
  properties: "properties",
  priorities: "priorities",
  ticketTypes: "ticket_types",
  checklists: "checklists",
  originalUserId: "originalUserId",
  originalClientId: "originalClientId",
  relationships: "relationships",
  structureTypes: "structure_types",
  plants: "plants",
  plans: "plans",
  createdTickets: "createdTickets",
  synchronizer: "synchronizer",
  ticket_states: "ticket_states",
  plant_templates: "plant_templates",
  lastPlanLocation: "last_plan_location",
  deadlines: "deadlines",
  lastProperty: "last_property",
  syncPaused: "sync_paused",
  accessKey: "access_key",
  guestId: "guest_id",
  guestName: "guest_name"
};
