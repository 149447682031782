
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { TypeChecker } from "@/shared/utils/TypeChecker";
import { InspectionSelectionItemLoader } from "@/inspection/shared/dtos/InspectionSelectionItemLoader";

@Component<TicketPrioritySelect>({
  components: {}
})
export default class TicketPrioritySelect extends Vue {
  @Prop({ type: [Array, String], default: "" })
  protected readonly states!: string[] | string;
  @Prop({ type: Boolean, default: false })
  protected readonly loading!: boolean;
  @Prop({ type: String, default: "" })
  protected readonly error!: string;

  public get hasItems() {
    return this.items.length > 0;
  }

  protected get items() {
    return [
      {
        text: "Kein Status",
        value: ""
      },
      {
        divider: true
      },
      ...InspectionSelectionItemLoader.loadTicketStates()
    ];
  }

  protected get multiple() {
    return TypeChecker.isArray(this.states);
  }

  protected emitChange(states: string | string[]) {
    this.$emit("change", states);
  }
}
