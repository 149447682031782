import {
  ChecklistManagerGateway,
  ChecklistTicket
} from "@/expert/interactors/ChecklistManager";
import { GraphQLConnection } from "./connection/GraphQLConnection";
import { NumericalId } from "@/datastructures/NumericalId";
import { Checklist } from "@/entities/Checklist";
import { ChecklistSection } from "@/entities/ChecklistSection";
import { ArrayUtils } from "@/utils/ArrayUtils";
import { ChecklistEntry } from "@/entities/ChecklistEntry";
import { Ticket } from "@/entities/Ticket";
import { Image } from "@/entities/Image";
import { Assignment } from "@/entities/Assignment";
import { Report } from "@/entities/Report";
import { Plant } from "@/entities/Plant";

export class ChecklistManagerGraphQLGateway implements ChecklistManagerGateway {
  public constructor(private connection: GraphQLConnection) {}

  public loadChecklist(assignmentId: NumericalId): Promise<Assignment> {
    return this.connection
      .query("assignment", { id: assignmentId.id }, [
        "id",
        {
          name: "checklist",
          fields: [
            "id",
            "protection_goal_niveau",
            "type",
            {
              name: "sections",
              fields: [
                "id",
                "name",
                {
                  name: "entries",
                  fields: [
                    "id",
                    "name",
                    "state",
                    "preparation_state",
                    "ticketCount",
                    "checklist_entry_id",
                    { name: "plant", fields: ["id"] }
                  ]
                }
              ]
            }
          ]
        },
        { name: "reports", fields: ["id"] }
      ])
      .then(response => {
        const checklistData = response.data.checklist;
        const reportsData = response.data.reports;

        const assignment = new Assignment(response.data.id);
        const checklist = new Checklist(checklistData.id);
        checklist.type = checklistData.type;
        checklist.protectionGoalNiveau = checklistData.protection_goal_niveau;

        if (ArrayUtils.isArray(reportsData)) {
          assignment.reports = reportsData.map(
            (reportData: any) => new Report(reportData.id)
          );
        }

        checklist.sections = this.generateSections(checklistData.sections);
        assignment.checklist = checklist;

        return assignment;
      });
  }

  public loadChecklistForPreparation(assignmentId: NumericalId) {
    return this.connection
      .query("assignment", { id: assignmentId.id }, [
        "id",
        {
          name: "checklist",
          fields: [
            "id",
            {
              name: "sections",
              fields: [
                "id",
                "name",
                { name: "entries", fields: ["id", "preparation_state"] }
              ]
            }
          ]
        }
      ])
      .then(response => {
        const checklistData = response.data.checklist;
        const checklist = new Checklist(checklistData.id);
        checklist.sections = this.generateSections(checklistData.sections);
        return checklist;
      });
  }

  public loadChecklistEntryTickets(entryId: NumericalId) {
    return this.connection
      .query("checklistEntry", { id: entryId.id }, [
        "id",
        {
          name: "tickets",
          fields: [
            "id",
            "title",
            "priority",
            "description",
            { name: "images", fields: ["id", "path_small"] }
          ]
        }
      ])
      .then(response => {
        let tickets: Ticket[] = [];

        const ticketsData = response.data.tickets;
        if (ticketsData) {
          tickets = ticketsData.map((ticketData: any) => {
            const ticket = new Ticket(ticketData.id);
            ticket.title = ticketData.title;
            ticket.priority = ticketData.priority;
            ticket.description = ticketData.description;

            if (ticketData.images) {
              ticket.images = ticketData.images.map(
                (image: any) => new Image(image.id, image.path_small)
              );
            }

            return ticket;
          });
        }

        return tickets;
      });
  }

  public loadChecklistSectionAndEntryNames(assignmentId: NumericalId) {
    return this.connection
      .query("assignment", { id: assignmentId.id }, [
        {
          name: "checklist",
          fields: [
            "id",
            {
              name: "sections",
              fields: [
                "id",
                "name",
                { name: "entries", fields: ["id", "name"] }
              ]
            }
          ]
        }
      ])
      .then(response => {
        const checklist = new Checklist(response.data.checklist.id);
        const sectionsData = response.data.checklist.sections;

        if (!!sectionsData) {
          checklist.sections = sectionsData.map((sectionData: any) => {
            const section = new ChecklistSection(parseInt(sectionData.id, 10));
            section.name = sectionData.name;

            if (!!sectionData.entries) {
              section.entries = sectionData.entries.map((entryData: any) => {
                const entry = new ChecklistEntry(parseInt(entryData.id, 10));
                entry.name = entryData.name;
                return entry;
              });
            }

            return section;
          });
        }

        return checklist;
      });
  }

  public async loadLooseTickets(
    checklistId: NumericalId
  ): Promise<ChecklistTicket[]> {
    const result = await this.connection.query(
      "checklist",
      { id: checklistId.id },
      ["id", { name: "looseTickets", fields: ["id", "title"] }]
    );
    return result.data.looseTickets.map((ticket: any) => ({
      id: ticket.id,
      title: ticket.title
    }));
  }

  public finishPreparation(checklistId: NumericalId): Promise<NumericalId> {
    return this.connection
      .mutation(
        "updateChecklist",
        { input: { id: checklistId.id, preparation_done: true } },
        ["id"]
      )
      .then(response => new NumericalId(response.data.id));
  }

  public updateProtectionGoalNiveau(
    text: string,
    checklistId: NumericalId
  ): Promise<void> {
    return this.connection
      .mutation(
        "updateChecklist",
        { input: { id: checklistId.id, protection_goal_niveau: text } },
        ["id"]
      )
      .then();
  }

  public setSectionPreparationState(state: string, sectionId: NumericalId) {
    return this.connection
      .query("checklistSection", { id: sectionId.id }, [
        "id",
        { name: "entries", fields: ["id"] }
      ])
      .then((sectionResponse: any) =>
        this.connection
          .mutation(
            "updateChecklistSection",
            {
              input: {
                id: sectionId.id,
                entries: {
                  update: sectionResponse.data.entries.map((entry: any) => ({
                    id: entry.id,
                    preparation_state: state
                  }))
                }
              }
            },
            ["id", "name"]
          )
          .then(response => {
            const section = new ChecklistSection(response.data.id);
            section.name = response.data.name;
            return section;
          })
      );
  }

  public setSectionPreparationStates(
    state: string,
    checklistId: NumericalId,
    sections: NumericalId[]
  ): Promise<ChecklistSection[]> {
    const sectionIds = sections.map(section => section.id.toString());

    return this.connection
      .query("checklist", { id: checklistId.id }, [
        "id",
        {
          name: "sections",
          fields: ["id", { name: "entries", fields: ["id"] }]
        }
      ])
      .then(checklistResponse => {
        const filteredSections = checklistResponse.data.sections.filter(
          (section: any) => sectionIds.includes(section.id)
        );

        return this.connection
          .mutation(
            "updateChecklist",
            {
              input: {
                id: checklistId.id,
                sections: {
                  update: filteredSections.map((section: any) => ({
                    id: section.id,
                    entries: {
                      update: section.entries.map((entry: any) => ({
                        id: entry.id,
                        preparation_state: state
                      }))
                    }
                  }))
                }
              }
            },
            ["id", { name: "sections", fields: ["id", "name"] }]
          )
          .then(response =>
            response.data.sections.map((sectionData: any) => {
              const section = new ChecklistSection(sectionData.id);
              section.name = sectionData.name;
              return section;
            })
          );
      });
  }

  public setEntryPreparationState(state: string, entryId: NumericalId) {
    return this.connection
      .mutation(
        "updateChecklistEntry",
        { input: { id: entryId.id, preparation_state: state } },
        ["id"]
      )
      .then(response => new ChecklistEntry(response.data.id));
  }

  public setEntryInspectionState(state: string | null, entryId: NumericalId) {
    if (state === "clear") {
      state = null;
    }

    return this.connection
      .mutation("updateChecklistEntry", { input: { id: entryId.id, state } }, [
        "id"
      ])
      .then(response => new ChecklistEntry(response.data.id));
  }

  private generateSections(sectionsData: any) {
    if (ArrayUtils.isArray(sectionsData)) {
      return sectionsData.map((sectionData: any) => {
        const section = new ChecklistSection(sectionData.id);

        section.name = sectionData.name;
        section.entries = this.generateEntries(sectionData.entries);

        return section;
      });
    }

    return [];
  }

  private generateEntries(entriesData: any) {
    if (ArrayUtils.isArray(entriesData)) {
      return entriesData.map((entryData: any) => {
        const entry = new ChecklistEntry(entryData.id);

        entry.name = entryData.name;
        entry.state = entryData.state === null ? "clear" : entryData.state;
        entry.preparationState = entryData.preparation_state;
        entry.ticketCount = entryData.ticketCount;
        entry.plant = new Plant(
          parseInt(entryData.plant?.id?.toString() ?? "0", 10)
        );
        entry.checklistEntryTemplateId = entryData.checklist_entry_id;

        return entry;
      });
    }

    return [];
  }
}
