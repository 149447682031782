
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { Client } from "@/property/shared/models/Client";
import { Authorizator } from "@/common/interactors/Authorizator";

@Component<CreateClientButton>({
  components: {}
})
export default class CreateClientButton extends Vue {
  @Prop({
    default() {
      return new Client();
    }
  })
  protected readonly client!: Client;

  protected get canCreateClients() {
    return Authorizator.canCreateClients();
  }
}
