import { Dictionary } from "@/shared/datastructures/Dictionary";
import { ReportDataEntity } from "../entities/ReportEntity";

export class ReportData {
  public sections?: Dictionary<Dictionary<unknown>> = {};

  public getSection(key: string) {
    return this.sections ? this.sections[key] : {};
  }

  public setSection(key: string, data: Dictionary<unknown>) {
    if (!this.sections) {
      this.sections = {};
    }

    this.sections[key] = data;
  }

  public toEntity(): ReportDataEntity {
    return JSON.stringify(this.sections ?? {});
  }

  public from(entity?: ReportDataEntity) {
    this.sections = JSON.parse(entity ?? "{}");
  }

  public static from(entity?: ReportDataEntity) {
    const model = new ReportData();
    model.from(entity);
    return model;
  }
}
