import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";

export interface ReportDataImprintFormData {
  editorialTeam: string;
  editorialDeadline: Date;
  impressumClient: string;
  authors: string;
  allAuthors: string;
  advice: string;
}

export class ReportDataImprintForm extends Form<ReportDataImprintFormData> {
  protected definition = {
    editorialTeam: {
      label: "Redaktion",
      type: FormFieldType.Text,
      required: true
    },
    editorialDeadline: {
      label: "Redaktionsschluss",
      type: FormFieldType.Date,
      required: false
    },
    impressumClient: {
      label: "Auftraggeber",
      type: FormFieldType.Text,
      required: true
    },
    authors: {
      label: "AutorInnen",
      type: FormFieldType.Text,
      required: true
    },
    allAuthors: {
      label: "Alle AutorInnen",
      type: FormFieldType.Text,
      required: true
    },
    advice: {
      label: "Hinweis",
      type: FormFieldType.Text,
      required: true
    }
  };
}
