var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-stage',{ref:"canvas",attrs:{"config":_vm.configKonva},on:{"wheel":_vm.zoomPlan,"touchmove":_vm.touchZoom,"touchend":_vm.resetTouchZoom,"tap":_vm.tapped,"click":_vm.tapped}},[_c('v-layer',{ref:"layer",attrs:{"config":_vm.configLayer}},[_c('v-image',{attrs:{"config":_vm.configImage}}),_vm._l((_vm.marker),function(m,index){return _c('v-image',{key:index,ref:"marker",refInFor:true,attrs:{"config":_vm.configCrosshair[index]},on:{"dragend":function($event){return _vm.handleDragEnd(index)}}})})],2),(_vm.confirmable)?_c('v-layer',{attrs:{"config":{ x: 0, y: 0 }}},[_c('v-circle',{attrs:{"config":{
        x: _vm.stageWidth - 35,
        y: 35,
        radius: 25,
        fill: '#00629a'
      }},on:{"click":function($event){return _vm.emitConfirm()},"tap":function($event){return _vm.emitConfirm()}}}),_c('v-line',{attrs:{"config":{
        x: _vm.stageWidth - 37,
        y: 33,
        points: [-8, 2, 0, 10, 13, -6],
        tension: 0,
        strokeWidth: 3,
        closed: false,
        stroke: 'white'
      }}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }