import { Plan } from "./Plan";
import { Ticket } from "./Ticket";

export class TicketLocation {
  public x: number = 0;
  public y: number = 0;
  private mPlan?: Plan;
  private mTicket?: Ticket;

  public constructor(public id: number) {}

  public get plan(): Plan {
    this.mPlan = this.mPlan || new Plan(0);
    return this.mPlan;
  }
  public set plan(plan: Plan) {
    this.mPlan = plan;
  }

  public get ticket(): Ticket {
    this.mTicket = this.mTicket || new Ticket(0);
    return this.mTicket;
  }
  public set ticket(ticket: Ticket) {
    this.mTicket = ticket;
  }
}
