import { Client } from "@/entities/Client";

export class ProfileManager implements IProfileManager {
  public constructor(private gateway: IProfileManagerGateway) {}

  public loadClientId(): Promise<number> {
    return this.gateway.loadClientId().then(client => client.id);
  }
}

export interface IProfileManager {
  loadClientId(): Promise<number>;
}

export interface IProfileManagerGateway {
  loadClientId(): Promise<Client>;
}
