var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Grid',[_c('Row',{attrs:{"align-center":""}},[(_vm.editable)?_c('Column',{attrs:{"grow":""}},[_c('SearchSelect',{attrs:{"value":_vm.vm.plan,"noDataText":"Keine Pläne vorhanden","hideDetails":"","clearable":""},on:{"change":function($event){return _vm.controller.planChanged($event)}}})],1):_vm._e(),(_vm.editable)?_c('Column',{attrs:{"shrink":""}},[_c('InfoTooltip',{attrs:{"top":""}},[_vm._v(" Mit einem Doppelklick können Sie die Ticketposition direkt setzen. "),_c('br'),_vm._v("Zum Zoomen am PC, halten Sie die Shift-Taste gedrückt. ")])],1):_vm._e()],1),_c('Row',{attrs:{"wrap":""}},[(_vm.vm.planVisible)?_c('Column',{attrs:{"xs12":""}},[(_vm.vm.loadPlanRequest.loading)?_c('v-progress-circular',{staticClass:"d-block mx-auto",attrs:{"indeterminate":"","color":"primary"}}):(!!_vm.vm.loadPlanRequest.error)?_c('div',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"error--text font-weight-bold"},[_vm._v(_vm._s(_vm.vm.loadPlanRequest.error))])]):[_c('InteractivePlan',{ref:"interactivePlan",attrs:{"locked":!_vm.editable,"path":_vm.vm.selectedPlanImagePath,"marker":[
            {
              path: require('@/assets/BS_crosshairs_A.svg'),
              draggable: true,
              scale: 0.8,
              x: _vm.vm.ticketLocation.x,
              y: _vm.vm.ticketLocation.y
            }
          ],"confirmable":_vm.confirmable,"center":_vm.centerPlan,"zoom":_vm.zoom},on:{"positionChanged":function($event){return _vm.controller.ticketLocationChanged($event)},"confirm":function($event){return _vm.emitConfirm()},"zoom":function($event){return _vm.emitZoom($event)}}})]],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }