export class MathUtils {
  public static randomColor() {
    // tslint:disable-next-line: no-bitwise
    return "#" + ((Math.random() * 0xffffff) << 0).toString(16);
  }

  public static parseBoolean(value: any): boolean {
    switch (value) {
      case "true":
      case true:
      case 1:
        return true;
    }
    return false;
  }

  public static distance(x1: number, y1: number, x2: number, y2: number) {
    const deltaX = x1 - x2;
    const deltaY = y1 - y2;
    const sqr = deltaX * deltaX + deltaY * deltaY;
    return Math.sqrt(sqr);
  }

  public static safeParseInt(num: string | undefined, def: number): number {
    if (num) {
      const parsed = parseInt(num, 10);
      return isNaN(parsed) ? def : parsed;
    }
    return def;
  }
}
