import { Form, FormFieldType } from "../Form";

export class PropertyForm extends Form {
  protected definition = {
    name: { type: FormFieldType.Text, required: true },
    description: { type: FormFieldType.Text, required: false },
    legalForm: { type: FormFieldType.Text, required: true },
    cadastralCommunity: { type: FormFieldType.Text, required: true },
    assetNumber: { type: FormFieldType.Text, required: true },
    buildYear: { type: FormFieldType.Number, required: true },
    parcelNumber: { type: FormFieldType.Text, required: true },
    unitCount: { type: FormFieldType.Number, required: true, greaterThan: 0 },
    effectiveArea: {
      type: FormFieldType.Number,
      required: true,
      greaterThan: 0
    },
    dwsBaseNumber: { type: FormFieldType.Text, required: false },
    singleCadastre: { type: FormFieldType.Checkbox, required: false },
    street: { type: FormFieldType.Text, required: true },
    zip: { type: FormFieldType.Text, required: true },
    city: { type: FormFieldType.Text, required: true },
    country: { type: FormFieldType.Selection, required: true }
  };
}
