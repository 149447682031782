import {
  convertGraphQlToTicketEntities,
  convertGraphQlToTicketEntity,
  TicketEntity
} from "./TicketEntity";

export interface ChecklistEntity {
  id?: number;
  protectionGoalNiveau?: string;
  preparationDone?: boolean;
  type?: string;
  entries?: ChecklistEntryEntity[];
  propertyId?: number;
  looseTickets?: TicketEntity[];
}

export interface ChecklistEntryEntity {
  id?: number;
  name?: string;
  description?: string;
  preparationState?: ChecklistPreparationState;
  state?: ChecklistInspectionState;
  checklistEntryTemplateId?: string;
  plantId?: number;
  hasTickets?: boolean;
  hasEntries?: boolean;
  tickets?: TicketEntity[];
  entries?: ChecklistEntryEntity[];
}

export type ChecklistPreparationState =
  | "prove"
  | "irrelevant"
  | "inavailable"
  | "mixed";

export type ChecklistInspectionState =
  | ""
  | "clear"
  | "proven"
  | "maintenance"
  | "inspection"
  | "mixed";

export function convertGraphQlToChecklistEntity(c: any): ChecklistEntity {
  return {
    id: c.id ?? 0,
    protectionGoalNiveau: c.protection_goal_niveau ?? "",
    preparationDone: c.preparation_done ?? false,
    type: c.type ?? "",
    entries: convertGraphQlToChecklistEntryEntities(c.entries),
    propertyId: parseInt(c.property?.id ?? 0, 10),
    looseTickets: convertGraphQlToTicketEntities(c.looseTickets)
  };
}

export function convertGraphQlToChecklistEntryEntities(
  data?: any[]
): ChecklistEntryEntity[] {
  if (!data) {
    return [];
  }

  return data.map<ChecklistEntryEntity>(e =>
    convertGraphQlToChecklistEntryEntity(e)
  );
}

export function convertGraphQlToChecklistEntryEntity(
  e: any
): ChecklistEntryEntity {
  if (!e) {
    return {};
  }

  return {
    id: e.id ?? 0,
    name: e.name ?? "",
    description: e.description ?? "",
    preparationState: e.preparation_state ?? "",
    state: e.state ?? "",
    checklistEntryTemplateId: e.checklist_entry_id ?? "",
    plantId: parseInt(e.plant?.id ?? 0, 10),
    hasTickets: e.has_tickets ?? false,
    hasEntries: e.has_entries ?? false,
    entries: convertGraphQlToChecklistEntryEntities(e.entries),
    tickets: convertGraphQlToTicketEntities(e.tickets)
  };
}
