
import Vue from "vue";
import Component from "vue-class-component";

import SearchSelect from "@/components/form/SearchSelect.vue";

import { RoleSelectVm } from "../models/RoleSelectVm";
import { Role } from "../../models/Role";

@Component<RoleSelect>({
  components: {
    SearchSelect
  },
  props: {
    val: {
      type: Object,
      default: undefined
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class RoleSelect extends Vue {
  private vm = new RoleSelectVm();

  private mounted() {
    this.load();
  }

  private load() {
    this.vm.load();
  }

  private emitSelect(role: Role) {
    this.$emit("select", role);
  }
}
