import {
  Request,
  SearchSelection,
  Textfield,
  FileInput,
  Checkbox
} from "@/forms/ViewModelFormTypes";

export class PropertyPlanViewModel {
  public goBack = false;
  // Data
  public selectedPlanImagePath = "";
  public planScale = "";
  public planDistortion = "";
  public planImageId = "";

  // Requests
  public loadPlansRequest: Request = { loading: false, error: "" };
  public loadPlanRequest: Request = { loading: false, error: "" };
  public createPlanRequest: Request = { loading: false, error: "" };
  public updatePlanDataRequest: Request = { loading: false, error: "" };
  public deletePlanRequest: Request = { loading: false, error: "" };

  // States
  public planDataVisible = false;
  public coordinatesVisible = false;
  public planVisible = false;
  public uploadPlanButtonDisabled = false;
  public updatePlanButtonDisabled = false;
  public infoDialogVisible = false;
  public distortionColor = "black";
  public distortionErrorVisible = false;
  public planEditLockButtonText = "Planbearbeitung entsperren";
  public planEditLockDisabled = false;
  public editPlanLocked = true;
  public newPlanImageVisible = false;

  public createPlanButtonVisible = false;
  public updatePlanButtonVisible = false;
  public deletePlanButtonVisible = false;

  // Input
  public plans: SearchSelection = {
    label: "Pläne durchsuchen",
    selected: "",
    search: "",
    error: "",
    loading: false,
    items: []
  };

  public name: Textfield = {
    label: "Planname",
    value: "",
    loading: false,
    error: ""
  };

  public planImage: FileInput = {
    label: "Planbild",
    value: [],
    loading: false,
    error: ""
  };
  public newPlanImage: FileInput = {
    label: "Neuen Plan hochladen",
    value: [],
    loading: false,
    error: ""
  };

  public offline: Checkbox = {
    label: "Offline verfügbar",
    value: false,
    selected: false,
    loading: false,
    error: ""
  };

  public pixelAX: Textfield = {
    label: "A (X)",
    value: "0",
    loading: false,
    error: ""
  };

  public pixelAY: Textfield = {
    label: "A (Y)",
    value: "0",
    loading: false,
    error: ""
  };

  public pixelBX: Textfield = {
    label: "B (X)",
    value: "100",
    loading: false,
    error: ""
  };

  public pixelBY: Textfield = {
    label: "B (Y)",
    value: "100",
    loading: false,
    error: ""
  };

  public realAX: Textfield = {
    label: "A (X)",
    value: "0",
    loading: false,
    error: ""
  };

  public realAY: Textfield = {
    label: "A (Y)",
    value: "0",
    loading: false,
    error: ""
  };

  public realBX: Textfield = {
    label: "B (X)",
    value: "1",
    loading: false,
    error: ""
  };

  public realBY: Textfield = {
    label: "B (Y)",
    value: "1",
    loading: false,
    error: ""
  };
}
