import { LoadTicketsOptions } from "@/inspection/ticket/useCases/LoadTicketsUseCase";
import { Paginated } from "@/shared/datastructures/Paginated";
import {
  BluebookIndexedDB,
  bluebookDB
} from "@/shared/persistence/indexedDB/BluebookIndexedDB";
import { IndexedDBStorage } from "@/shared/persistence/indexedDB/IndexedDBStorage";
import { ImageEntity } from "@/shared/project/images/ImageEntity";
import { TicketDescription } from "../../dtos/TicketDtos";
import { TicketEntity } from "../../entities/TicketEntity";
import { TicketCollection } from "../../models/TicketCollection";
import { TicketProvider } from "./TicketProvider";

export class TicketCacheProvider implements TicketProvider {
  public constructor(
    private ticketStore: IndexedDBStorage<
      BluebookIndexedDB,
      string,
      TicketEntity
    > = new IndexedDBStorage(bluebookDB, "tickets"),
    private imageStore: IndexedDBStorage<
      BluebookIndexedDB,
      string,
      ImageEntity
    > = new IndexedDBStorage(bluebookDB, "ticketImages")
  ) {}

  public async loadTicket(_: number, syncId: string): Promise<TicketEntity> {
    const ticket = await this.ticketStore.get(syncId);

    if (!ticket) {
      throw new Error("Ticket nicht im Cache enthalten");
    }

    await this.loadTicketImages(ticket);

    return ticket;
  }

  public checkTicketExistence(_: TicketEntity): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  public setState(_: number, __: string): Promise<number> {
    throw new Error("Method not implemented.");
  }

  public connectPerson(_: number, __: number, ___: string): Promise<number> {
    throw new Error("Method not implemented.");
  }

  public assignPlant(_: number, __: number): Promise<number> {
    throw new Error("Method not implemented.");
  }

  public disconnectPerson(_: number, __: string): Promise<number> {
    throw new Error("Method not implemented.");
  }

  public unassignPlant(_: number): Promise<number> {
    throw new Error("Method not implemented.");
  }

  public async loadTickets(
    options: LoadTicketsOptions
  ): Promise<Paginated<TicketEntity>> {
    const allTicketKeys = await this.ticketStore.keys();
    const tickets: TicketEntity[] = [];

    for (const key of allTicketKeys) {
      const ticket = await this.ticketStore.get(key);

      if (ticket) {
        if (!(options.excludeImages ?? false)) {
          await this.loadTicketImages(ticket);
        }

        tickets.push(ticket);
      }
    }

    return new Paginated(tickets, tickets.length);
  }

  public async create(ticket: TicketEntity): Promise<number> {
    await this.ticketStore.set(ticket.syncId ?? "", {
      ...ticket,
      images: ticket.images?.map(i => ({
        ...i,
        path: ""
      }))
    });

    return ticket.id ?? 0;
  }

  public changeDescription(_: number, __: TicketDescription): Promise<number> {
    throw new Error("Method not implemented.");
  }

  public async uploadImage(image: ImageEntity): Promise<number> {
    await this.imageStore.set(image.syncId ?? "", image);

    return image.id ?? 0;
  }

  public async delete(ticket: TicketEntity): Promise<number> {
    await this.ticketStore.remove(ticket.syncId ?? "");

    for (const image of ticket.images ?? []) {
      await this.imageStore.remove(image.syncId ?? "");
    }

    return ticket.id ?? 0;
  }

  private async loadTicketImages(ticket: TicketEntity): Promise<void> {
    for (const image of ticket.images ?? []) {
      const cachedImage = await this.imageStore.get(image.syncId ?? "");

      if (cachedImage) {
        image.path = cachedImage.path;
      }
    }
  }
}
