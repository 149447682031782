import { Request, Textfield } from "@/forms/ViewModelFormTypes";

export class LoginViewModel {
  public loggedIn = false;

  public email: Textfield = {
    value: "",
    label: "Email",
    error: "",
    loading: false
  };
  public password: Textfield = {
    value: "",
    label: "Password",
    error: "",
    loading: false
  };

  public loginRequest: Request = { loading: false, error: "" };

  public loginButtonDisabled = false;
}
