import { fileExtensionRegex } from "@/data/regex";
import { v4 as uuidv4 } from "uuid";

export class StringUtils {
  public static uuid(): string {
    return uuidv4();
  }

  public static isString(value: any): value is string {
    return (
      value !== undefined &&
      value !== null &&
      (typeof value === "string" || value instanceof String)
    );
  }

  public static safeStringifyJSON(value?: string, base?: JSONBase) {
    if (!base) {
      base = JSONBase.Object;
    }

    if (value) {
      return JSON.parse(value);
    } else {
      return base === JSONBase.Object ? {} : [];
    }
  }

  public static getFileExtension(filename: string) {
    const extensionSearch = fileExtensionRegex.exec(filename);
    let extension = "";

    if (extensionSearch != null) {
      extension = extensionSearch[1];
    }

    return extension;
  }
}

export enum JSONBase {
  Object,
  Array
}
