
import Vue from "vue";
import Component from "vue-class-component";

import Button from "@/components/basic/Button.vue";
import RequestButton from "@/components/basic/RequestButton.vue";
import Loading from "@/components/basic/Loading.vue";
import ErrorMessage from "@/components/basic/ErrorMessage.vue";

import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Stepper from "@/components/layout/Stepper.vue";

import Textfield from "@/components/form/Textfield.vue";
import Textarea from "@/components/form/Textarea.vue";
import DatePicker from "@/components/form/DatePicker.vue";
import Checkbox from "@/components/form/Checkbox.vue";
import CheckboxGroup from "@/components/form/CheckboxGroup.vue";

import { ReportDataModel } from "../../models/ReportDataModel";

@Component<ReportData>({
  components: {
    Button,
    RequestButton,
    Loading,
    ErrorMessage,
    Grid,
    Row,
    Column,
    Stepper,
    Textfield,
    Textarea,
    DatePicker,
    Checkbox,
    CheckboxGroup
  },
  props: {
    assignmentId: {
      type: String,
      default: "0"
    },
    reportType: {
      type: String,
      default: ""
    },
    reportId: {
      type: String,
      default: "0"
    }
  },
  watch: {
    "model.reportUrl"(url: string) {
      this.$store.dispatch("setReportUrl", url);
    }
  }
})
export default class ReportData extends Vue {
  private model = new ReportDataModel();
  private assignmentId!: string;
  private reportType!: string;
  private reportId!: string;

  public save() {
    this.model.save();
  }

  public nextStep() {
    this.model.nextStep();
  }

  public prevStep() {
    this.model.prevStep();
  }

  private get steps() {
    return this.model.steps;
  }

  private get isDefaultReport() {
    return this.reportType === "default";
  }

  private mounted() {
    this.load();
  }

  private load() {
    this.$store.dispatch("setReportUrl", "");
    this.model.load(this.assignmentId, this.reportType, this.reportId);
  }
}
