
import Vue from "vue";
import Component from "vue-class-component";

@Component<Treeview>({
  props: {
    items: {
      type: Array,
      default: []
    },
    loadChildren: {
      type: Function,
      default: null
    },
    itemKey: {
      type: String,
      default: "id"
    }
  }
})
export default class Treeview extends Vue {}
