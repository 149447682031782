import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { bluebookDB } from "@/shared/persistence/indexedDB/BluebookIndexedDB";
import { IndexedDBStorage } from "@/shared/persistence/indexedDB/IndexedDBStorage";
import { DefaultAccessRepository } from "./accessRepository/AccessRepository";
import { DefaultClientRepository } from "./clientRepository/ClientRepository";
import { DefaultEmployeeRepository } from "./employeeRepository/EmployeeRepository";
import { DefaultPersonRepository } from "./personRepository/PersonRepository";
import { ManagedPlanPool } from "./planRepository/ManagedPlanPool";
import { PlanGraphQLProvider } from "./planRepository/PlanGraphQLProvider";
import { PlanPool } from "./planRepository/PlanPool";
import { DefaultPlanRepository } from "./planRepository/PlanRepository";
import { DefaultPlantRepository } from "./plantRepository/PlantRepository";
import { PropertyGraphQLProvider } from "./propertyRepository/PropertyGraphQLProvider";
import { DefaultPropertyRepository } from "./propertyRepository/PropertyRepository";
import Redux from "@/plugins/vuex";

const offlinePlanPool = new PlanPool(
  new IndexedDBStorage(bluebookDB, "offlinePlans"),
  50
);
const onlinePlanPool = new PlanPool(
  new IndexedDBStorage(bluebookDB, "plans"),
  50
);

offlinePlanPool.syncWithStore();
onlinePlanPool.syncWithStore();

export class PropertyReportContainer {
  public static planCache = new ManagedPlanPool(
    offlinePlanPool,
    onlinePlanPool
  );

  public static propertyRepo = new DefaultPropertyRepository(
    new PropertyGraphQLProvider(),
    Redux
  );
  public static plantRepo = new DefaultPlantRepository();
  public static planRepo = new DefaultPlanRepository(
    new PlanGraphQLProvider(
      new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger())),
      {
        onlyFromCache: false
      }
    ),
    {
      planCache: PropertyReportContainer.planCache
    }
  );
  public static clientRepo = new DefaultClientRepository();
  public static accessRepo = new DefaultAccessRepository();
  public static employeeRepo = new DefaultEmployeeRepository();
  public static personRepo = new DefaultPersonRepository();

  public static propertyCacheRepo = new DefaultPropertyRepository(
    new PropertyGraphQLProvider(
      new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger())),
      true
    ),
    Redux
  );
  public static planCacheRepo = new DefaultPlanRepository(
    new PlanGraphQLProvider(
      new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger())),
      {
        onlyFromCache: true
      }
    ),
    {
      planCache: PropertyReportContainer.planCache
    }
  );
}
