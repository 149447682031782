
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import TicketDescriptionForm from "./TicketDescriptionForm.vue";
import { TicketCreationForm } from "./TicketCreationWorkflow.vue";

import { Ticket } from "@/inspection/shared/models/Ticket";
import { CreateTicketDataForm as Form } from "../../forms/CreateTicketDataForm";
import { InspectionSettingsContainer } from "@/inspection/shared/settings/InspectionSettingsContainer";
import { DateUtils } from "@/shared/utils/DateUtils";

@Component<TicketDataForm>({
  components: { TicketDescriptionForm }
})
export default class TicketDataForm extends Vue implements TicketCreationForm {
  @Prop({ default: () => new Ticket() })
  protected readonly ticket!: Ticket;
  @Prop() protected readonly checklistType?: string;

  @Watch("checklistType")
  protected onChecklistTypeChanged() {
    this.setInitialTicketType();
  }

  protected form = new Form();

  protected get descriptionForm() {
    return this.$refs.description as TicketDescriptionForm;
  }

  public validate() {
    this.emitValidate();
    return this.isValid();
  }

  public async reset() {
    await this.form.reset();
    await this.descriptionForm.reset();
    this.initDefaultFields();
  }

  protected isValid() {
    return this.descriptionForm.validate() && this.form.isValid();
  }

  protected initDefaultFields() {
    this.setDefaultDeadline();
    this.setDefaultPriority();
    this.setInitialTicketType();
  }

  protected setDefaultDeadline() {
    const defaultDeadline =
      InspectionSettingsContainer.deadlines.getDefaultDeadline();
    this.updateTicketDeadline(defaultDeadline?.name ?? "");
  }

  protected setDefaultPriority() {
    this.updateTicketData("priority", "priority3");
  }

  protected setInitialTicketType() {
    if (this.checklistType) {
      const checklist = InspectionSettingsContainer.checklists.getChecklist(
        this.checklistType
      );

      if (checklist) {
        this.updateTicketType(checklist.ticket_type);
      }
    }
  }

  protected updateTicketDeadline(deadlineName: string) {
    const deadline =
      InspectionSettingsContainer.deadlines.getDeadline(deadlineName);

    if (deadline) {
      const maturity = DateUtils.isoDate(
        DateUtils.add({ days: deadline.days })
      );
      this.updateTicketData("deadline", deadlineName);
      this.updateTicketData("maturity", maturity);
    }
  }

  protected updateTicketType(typeKey: string) {
    this.updateTicketData("type", typeKey);
    this.emitTicketTypeSelect(typeKey);
  }

  protected updateTicketData(fieldName: string, value: unknown) {
    this.form.setFieldValue(fieldName, value);
    this.ticket.updateData(this.form.getData());
    this.validate();
  }

  protected emitValidate() {
    this.$emit("validate", this.isValid());
  }

  protected emitTicketTypeSelect(typeKey: string) {
    this.$emit("ticketTypeSelect", typeKey);
  }

  protected async created() {
    this.form = new Form();
    await this.form.init();
    this.initDefaultFields();
  }
}
