import { AsyncKeyValueStorage } from "@/storage/AsyncKeyValueStorage";
import arrayMove from "array-move";
import { PlanEntity } from "../../entities/PlanEntity";

export class PlanPool {
  private planQueue: number[] = [];

  public constructor(
    private storage: AsyncKeyValueStorage<string, PlanEntity>,
    private amount: number
  ) {
    if (this.amount <= 0) {
      throw new Error(
        "Ein Plan-Pool muss mindestens einen Plan speichern können"
      );
    }
  }

  public get empty() {
    return this.length === 0;
  }

  public get length() {
    return this.planQueue.length;
  }

  public async syncWithStore(): Promise<void> {
    const planIdStrings = await this.storage.keys();
    this.planQueue = planIdStrings.map(id => parseInt(id, 10));
  }

  public async add(plan: PlanEntity): Promise<void> {
    this.checkPlan(plan);

    if (this.isFull) {
      await this.kickFirstPlan();
    }

    await this.addOrUpdatePlan(plan);
  }

  public async getById(id: number): Promise<PlanEntity | undefined> {
    const plan = await this.storage.get(id.toString());

    if (plan) {
      this.putPlanInFront(plan);
      return plan;
    }
  }

  public contains(plan: PlanEntity) {
    return this.getIndexOfPlan(plan) >= 0;
  }

  public async getAll(): Promise<PlanEntity[]> {
    const plans: PlanEntity[] = [];

    for (const planId of this.planQueue) {
      const plan = await this.getById(planId);
      if (plan) {
        plans.push(plan);
      }
    }

    return plans;
  }

  public async remove(plan: PlanEntity): Promise<void> {
    if (plan.id) {
      const index = this.getIndexOfPlan(plan);

      if (index >= 0) {
        await this.storage.remove(plan.id.toString());
        this.planQueue.splice(index, 1);
      }
    }
  }

  private get isFull() {
    return this.length === this.amount;
  }

  private checkPlan(plan: PlanEntity) {
    if (!plan.id) {
      throw new Error(
        "Plan hat keine ID - kann nicht in Cache gespeichert werden"
      );
    }
  }

  private async kickFirstPlan() {
    if (!this.empty) {
      const planToKick = await this.getById(this.planQueue[0]);

      if (planToKick) {
        await this.remove(planToKick);
      }
    }
  }

  private async addOrUpdatePlan(plan: PlanEntity) {
    if (plan.id) {
      await this.storage.set(plan.id.toString(), plan);

      if (this.contains(plan)) {
        this.putPlanInFront(plan);
      } else {
        this.planQueue.push(plan.id);
      }
    }
  }

  private async putPlanInFront(plan: PlanEntity) {
    if (plan.id) {
      const index = this.getIndexOfPlan(plan);

      if (index >= 0) {
        this.planQueue = arrayMove(this.planQueue, index, this.length - 1);
      }
    }
  }

  private getIndexOfPlan(plan: PlanEntity) {
    if (plan.id) {
      return this.planQueue.indexOf(plan.id);
    }
    return -1;
  }
}
