import isOnline from "is-online";

export class OnlineChecker {
  public static async isOnline(): Promise<boolean> {
    if (this.hasSlowConnection()) {
      return false;
    }

    return await isOnline();
  }

  public static hasSlowConnection() {
    if (!!navigator && !navigator.onLine) {
      return true;
    }

    if (!!navigator) {
      const n = navigator as any;
      const connection = n.connection || n.mozConnection || n.webkitConnection;
      if (connection && connection.effectiveType.includes("2g")) {
        return true;
      }
    }

    return false;
  }
}
