import { NumericalId } from "@/datastructures/NumericalId";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { Page } from "@/datastructures/Page";
import { SettingMeta } from "@/entities/SettingMeta";
import {
  Setting,
  GlobalSettingInput,
  ClientSettingInput
} from "@/entities/Setting";

export class SettingsManager implements ISettingsManager {
  public constructor(private gateway: SettingsManagerDataGateway) {}

  public loadSettingsCatalog(
    clientId: NumericalId,
    page: Page
  ): Promise<PaginatedList<SettingsCatalogRow>> {
    return this.gateway.loadSettingsCatalog(clientId, page).then(settings => ({
      items: settings.items.map(setting => ({
        id: new NumericalId(setting.id),
        key: setting.key,
        overwritable: setting.overwritable
      })),
      totalCount: settings.totalCount
    }));
  }

  public loadSettings(clientId: NumericalId) {
    return this.gateway.loadSettings(clientId);
  }

  public loadSetting(
    settingKey: string,
    clientId?: NumericalId
  ): Promise<Setting> {
    if (!!clientId) {
      return Setting.load(settingKey, clientId, this.gateway);
    } else {
      return Setting.loadGlobally(settingKey, this.gateway);
    }
  }

  public saveGlobalSetting(
    setting: GlobalSettingInput,
    settingId?: NumericalId
  ) {
    const settingObj = new Setting(settingId ? settingId.id : 0);
    return settingObj.saveGlobalSetting(setting, this.gateway);
  }

  public saveClientSetting(
    setting: ClientSettingInput,
    settingId?: NumericalId
  ) {
    const settingObj = new Setting(settingId ? settingId.id : 0);
    return settingObj.saveClientSetting(setting, this.gateway);
  }
}

export interface ISettingsManager {
  loadSettingsCatalog(
    clientId: NumericalId,
    page: Page
  ): Promise<PaginatedList<SettingsCatalogRow>>;

  loadSettings(clientId: NumericalId): Promise<AppSettings>;
  loadSetting(settingKey: string, clientId?: NumericalId): Promise<Setting>;
  saveGlobalSetting(
    setting: GlobalSettingInput,
    settingId?: NumericalId
  ): Promise<NumericalId>;
  saveClientSetting(
    setting: ClientSettingInput,
    settingId?: NumericalId
  ): Promise<NumericalId>;
}

export interface SettingsManagerDataGateway {
  loadSettingsCatalog(
    clientId: NumericalId,
    page: Page
  ): Promise<PaginatedList<SettingMeta>>;

  loadSettings(clientId: NumericalId): Promise<AppSettings>;

  loadSetting(
    settingKey: string,
    clientId: NumericalId
  ): Promise<SettingPayload>;

  loadGlobalSetting(settingKey: string): Promise<SettingPayload>;
  loadSettingMeta(settingKey: string): Promise<SettingMetaPayload>;

  saveSetting(setting: Setting): Promise<NumericalId>;
}

export interface SettingsCatalogRow {
  id: NumericalId;
  key: string;
  overwritable: boolean;
}

export interface SettingPayload {
  id: number;
  key: string;
  entry: string;
  variables: string;
}

export interface SettingMetaPayload {
  id: number;
  key: string;
  fields: string;
  variables: string;
}

export type AppSettings = AppSetting[];

export interface AppSetting {
  key: string;
  setting: string;
}
