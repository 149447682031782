import { Dictionary } from "@/shared/datastructures/Dictionary";
import { ServerResponse } from "@/shared/datastructures/Response";
import { RequestModel } from "@/shared/model/RequestModel";
import { FileUtils } from "@/shared/utils/FileUtils";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { UserSettings } from "@/storage/UserSettings";
import { MathUtils } from "@/utils/MathUtils";
import {
  ClientAddressData,
  ClientContactData,
  ClientGeneralData,
  ClientLogo
} from "../dtos/ClientDtos";
import { ClientEntity } from "../entities/ClientEntity";
import { PropertyReportContainer } from "../repositories/PropertyRepoContainer";
import { Employee } from "./Employee";
import { EmployeeList } from "./EmployeeList";

interface ClientProps {
  id?: number;
  name?: string;
  vat?: string;
  logo?: string;
  logos?: Dictionary<ClientLogo>;
  areaCode?: string;
  phoneNumber?: string;
  email?: string;
  website?: string;
  skype?: string;
  street?: string;
  zip?: string;
  city?: string;
  country?: string;
  employees?: Employee[];
}

export class Client extends RequestModel<ClientEntity> {
  public static from(client?: ClientEntity) {
    const c = new Client();
    c.from(client);
    return c;
  }

  public id?: number;
  public name?: string;
  public vat?: string;
  public logo?: string;
  public logos?: Dictionary<ClientLogo>;
  public color?: string;
  public areaCode?: string;
  public phoneNumber?: string;
  public email?: string;
  public website?: string;
  public skype?: string;
  public street?: string;
  public zip?: string;
  public city?: string;
  public country?: string;
  public employees?: EmployeeList;

  public createResponse = new ServerResponse<number>();
  public deleteResponse = new ServerResponse<number>();
  public logoUploadResponse = new ServerResponse<number>();
  public logoDeleteResponse = new ServerResponse<number>();

  public constructor(props?: ClientProps) {
    super();

    this.id = props?.id;
    this.name = props?.name;
    this.vat = props?.vat;
    this.logo = props?.logo;
    this.logos = props?.logos;
    this.areaCode = props?.areaCode;
    this.phoneNumber = props?.phoneNumber;
    this.email = props?.email;
    this.website = props?.website;
    this.skype = props?.skype;
    this.street = props?.street;
    this.zip = props?.zip;
    this.city = props?.city;
    this.country = props?.country;

    this.employees = EmployeeList.from(props?.employees);
    this.employees.client = this;

    this.color = MathUtils.randomColor();
  }

  public get exists() {
    return !!this.id;
  }

  public get isRoot() {
    return this.id === 1;
  }

  public get isOwn() {
    return UserSettings.getClientId() === this.id?.toString();
  }

  public get hasLogo() {
    return !!this.logo;
  }

  public getLogoPath(context: string) {
    return this.logos?.[context]?.path ?? "";
  }

  public getLogo(context: string) {
    return this.logos?.[context];
  }

  public setLogo(path: string, context: string) {
    if (!this.logos) {
      this.logos = {};
    }

    if (this.logos) {
      this.logos[context] = { path, context };
    }
  }

  public removeLogo(context: string) {
    if (this.logos) {
      delete this.logos[context];
    }
  }

  public load() {
    if (this.exists) {
      return RequestHandler.handleModel(
        () => PropertyReportContainer.clientRepo.getClient(this.id ?? 0),
        this
      );
    }
  }

  public create() {
    return RequestHandler.handle(
      PropertyReportContainer.clientRepo.createClient(this.toEntity()),
      this.createResponse,
      {
        onSuccess: id => {
          this.id = id;
          this.loaded = true;
        }
      }
    );
  }

  public async updateGeneralData(data: ClientGeneralData) {
    if (this.exists) {
      return RequestHandler.handle(
        PropertyReportContainer.clientRepo.updateGeneralData(
          this.id ?? 0,
          data
        ),
        undefined,
        {
          onSuccess: () => (this.generalData = data)
        }
      );
    } else {
      this.generalData = data;
    }
  }

  public async updateContactData(data: ClientContactData) {
    if (this.exists) {
      return RequestHandler.handle(
        PropertyReportContainer.clientRepo.updateContactData(
          this.id ?? 0,
          data
        ),
        undefined,
        {
          onSuccess: () => (this.contactData = data)
        }
      );
    } else {
      this.contactData = data;
    }
  }

  public async updateAddress(data: ClientAddressData) {
    if (this.exists) {
      return RequestHandler.handle(
        PropertyReportContainer.clientRepo.updateAddress(this.id ?? 0, data),
        undefined,
        {
          onSuccess: () => (this.address = data)
        }
      );
    } else {
      this.address = data;
    }
  }

  public async uploadLogo(logo: File, context: string) {
    if (!this.exists) {
      this.logoUploadResponse.error = "Mandant muss zuerst gespeichert werden";
      return false;
    }

    return new Promise<boolean>(resolve => {
      return RequestHandler.handle(
        PropertyReportContainer.clientRepo.uploadLogo(
          this.id ?? 0,
          logo,
          context
        ),
        this.logoUploadResponse,
        {
          onSuccess: async () => {
            const logoAsString = await FileUtils.toBase64(logo);
            this.setLogo(logoAsString, context);
            resolve(true);
          },
          onFailure: () => resolve(false)
        }
      );
    });
  }

  public async deleteLogo(context: string) {
    if (!this.exists) {
      this.logoDeleteResponse.error = "Mandant muss zuerst gespeichert werden";
      return false;
    }

    return RequestHandler.handle(
      PropertyReportContainer.clientRepo.deleteLogo(this.id ?? 0, context),
      this.logoDeleteResponse,
      {
        onSuccess: () => this.removeLogo(context)
      }
    );
  }

  public del() {
    return RequestHandler.handle(
      PropertyReportContainer.clientRepo.deleteClient(this.id ?? 0),
      this.deleteResponse
    );
  }

  public from(client?: ClientEntity) {
    this.id = client?.id;
    this.name = client?.name;
    this.vat = client?.vat;
    this.logo = client?.logo;
    this.logos = Object.assign(
      {},
      ...(client?.logos?.map(c => ({
        [c.context]: { path: c.path, context: c.context }
      })) ?? [])
    );
    this.areaCode = client?.areaCode;
    this.phoneNumber = client?.phoneNumber;
    this.email = client?.email;
    this.website = client?.website;
    this.skype = client?.skype;
    this.street = client?.street;
    this.zip = client?.zip;
    this.city = client?.city;
    this.country = client?.country;
  }

  public toEntity(): ClientEntity {
    return {
      id: this.id,
      name: this.name,
      vat: this.vat,
      logo: this.logo,
      logos: Object.values(this.logos ?? {}),
      areaCode: this.areaCode,
      phoneNumber: this.phoneNumber,
      email: this.email,
      website: this.website,
      skype: this.skype,
      street: this.street,
      zip: this.zip,
      city: this.city,
      country: this.country
    };
  }

  private set generalData(data: ClientGeneralData) {
    this.name = data.name;
    this.vat = data.vat;
  }

  private set contactData(data: ClientContactData) {
    this.areaCode = data.areaCode;
    this.phoneNumber = data.phoneNumber;
    this.email = data.email;
    this.website = data.website;
    this.skype = data.skype;
  }

  private set address(data: ClientAddressData) {
    this.street = data.street;
    this.zip = data.zip;
    this.city = data.city;
    this.country = data.country;
  }
}
