import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { GraphQLConnection } from "@/gateways/graphql/connection/GraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { GenericDataConverter } from "@/shared/datastructures/GenericDataConverter";
import { Access } from "../../dtos/Access";
import { AccessProvider } from "./AccessProvider";

interface AccessDto {
  id?: number;
  recipient_id?: number;
  recipient_type?: string;
  role_id?: number;
  resource_id?: number;
  resource_type?: string;
}

export class AccessGraphQLProvider implements AccessProvider {
  private accessDtoConverter = new GenericDataConverter<AccessDto>([
    { from: "recipientId", to: "recipient_id" },
    { from: "recipientType", to: "recipient_type" },
    { from: "roleId", to: "role_id" },
    { from: "resourceId", to: "resource_id" },
    { from: "resourceType", to: "resource_type" }
  ]);

  public constructor(
    private connection: GraphQLConnection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    )
  ) {}

  public async shareAccesses(accesses: Access[]): Promise<number[]> {
    const result = await this.connection.mutation(
      "shareAccesses",
      {
        input: {
          accesses: accesses.map(a => this.accessDtoConverter.convert(a))
        }
      },
      []
    );

    return result.data;
  }

  public async unshareAccesses(accesses: Access[]): Promise<number[]> {
    const result = await this.connection.mutation(
      "unshareAccesses",
      {
        input: {
          accesses: accesses.map(a => this.accessDtoConverter.convert(a))
        }
      },
      []
    );

    return result.data;
  }
}
