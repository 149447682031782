import { Property } from "./Property";
import { Image } from "./Image";
import { TicketLocation } from "./TicketLocation";
import { ChecklistEntry } from "./ChecklistEntry";
import { Checklist } from "./Checklist";

export class Ticket {
  public title: string = "";
  public description: string = "";
  public action: string = "";
  public dueDate: Date = new Date();
  public priority: string = "";
  public state: number = 0;
  public property: Property = new Property(0);
  public location: TicketLocation = new TicketLocation(0);
  public images: Image[] = [];
  public checklist: Checklist = new Checklist(0);
  public entry: ChecklistEntry = new ChecklistEntry(0);

  public constructor(public id: number) {}
}
