
import Vue from "vue";
import Component from "vue-class-component";

import deepEqual from "deep-equal";

import IconButton from "@/components/basic/IconButton.vue";
import { TableOptions, TableHeader } from "../../forms/ViewModelFormTypes";

@Component<Datatable>({
  components: {
    IconButton
  },
  props: {
    headers: {
      type: Array,
      default() {
        return [];
      }
    },
    tabletHeaders: {
      type: Array,
      default() {
        return [];
      }
    },
    mobileHeaders: {
      type: Array,
      default() {
        return [];
      }
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    itemsPerPage: {
      type: Number,
      default: 20
    },
    customHeader: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
export default class Datatable extends Vue {
  private set opts(newValue: any) {
    const opts: TableOptions = {
      sort: newValue.sortBy.map((sortBy: string, index: number) => ({
        by: this.convertHeaderToSort(sortBy),
        desc: newValue.sortDesc[index]
      }))
    };

    if (!deepEqual(opts, this.$props.options)) {
      this.$emit("sort", opts);
    }
  }
  private get opts() {
    const opts = this.$props.options as TableOptions;

    if (!!opts.sort) {
      return {
        sortBy: opts.sort.map(sort => this.convertSortToHeader(sort.by)),
        sortDesc: opts.sort.map(sort => sort.desc)
      };
    } else {
      return {
        sortBy: [],
        sortDesc: []
      };
    }
  }

  private convertHeaderToSort(sortBy: string) {
    const header = this.tableHeaders.find(
      (h: TableHeader) => h.value === sortBy
    );

    if (header && header.sortBy) {
      return header.sortBy;
    } else {
      return sortBy;
    }
  }

  private convertSortToHeader(sortBy: string) {
    const header = this.tableHeaders.find(
      (h: TableHeader) => h.sortBy === sortBy
    );

    if (header) {
      return header.value;
    } else {
      return sortBy;
    }
  }

  private get tableHeaders(): TableHeader[] {
    return this.$vuetify.breakpoint.lgAndUp
      ? this.$props.headers
      : this.$vuetify.breakpoint.xsOnly
      ? this.$props.mobileHeaders.map((header: any) => {
          const mobileKey = "mobile";
          header[mobileKey] = true;
          return header;
        })
      : this.$props.tabletHeaders.length > 0
      ? this.$props.tabletHeaders.map((header: any) => {
          const tabletKey = "tablet";
          header[tabletKey] = true;
          return header;
        })
      : this.$props.headers;
  }
}
