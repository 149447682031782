import { PlantFieldEntity } from "../entities/PlantEntity";
import { PlantTypeField } from "../settings/PlantTypeSettings";
import { PlantField } from "./PlantField";

export class PlantFieldCollection {
  public fields?: PlantField[] = [];

  public get hasFieldWithoutName() {
    return this.fields?.some(f => !f.name) ?? false;
  }

  public getFieldByName(name?: string) {
    return this.fields?.find(f => f.name === name);
  }

  public indexOf(field: PlantField) {
    return this.fields?.indexOf(field) ?? -1;
  }

  public contains(field: PlantField) {
    return this.fields?.includes(field);
  }

  public add(field?: PlantField) {
    if (!field) {
      return;
    }

    this.initIfEmpty();
    this.fields!.push(field);
  }

  public addEmpty() {
    this.initIfEmpty();

    const field = new PlantField();
    this.fields!.push(field);
    return field;
  }

  public merge(fields?: PlantFieldCollection) {
    this.initIfEmpty();

    fields?.fields?.forEach(f => {
      const existingField = this.getFieldByName(f.name);

      if (existingField) {
        this.remove(existingField);
      }

      this.add(f);
    });
  }

  public thinOut(fields?: PlantFieldCollection) {
    this.initIfEmpty();

    fields?.fields?.forEach(f => {
      const existingField = this.getFieldByName(f.name);

      if (existingField) {
        this.remove(existingField);
      }
    });
  }

  public remove(field: PlantField) {
    this.fields = this.fields?.filter(f => f !== field);
  }

  public clear() {
    if (this.fields) {
      this.fields.length = 0;
    }
  }

  public toEntities() {
    return this.fields?.map(f => f.toEntity());
  }

  public static collectFrom(entities?: PlantFieldEntity[]) {
    return this.collect(entities?.map(e => PlantField.from(e)));
  }

  public static collectFromTypes(types?: PlantTypeField[]) {
    return this.collect(types?.map(t => PlantField.fromType(t)));
  }

  public static collect(fields?: PlantField[]) {
    const collection = new PlantFieldCollection();
    collection.fields = fields;
    return collection;
  }

  private initIfEmpty() {
    if (!this.fields) {
      this.fields = [];
    }
  }
}
