import { Person } from "@/entities/Person";
import { Page } from "@/datastructures/Page";
import { NumericalId } from "@/datastructures/NumericalId";

export class PersonManager implements IPersonManager {
  public constructor(private gateway: PersonGateway) {}

  public createPerson(data: FullPersonData): Promise<number> {
    return this.gateway.createPerson(data).then(response => response.id);
  }

  public searchPersons(
    searchText: string,
    clientId: NumericalId,
    page: Page
  ): Promise<PersonSearchResult[]> {
    return this.gateway
      .searchPersons(searchText, clientId, page)
      .then(response =>
        response.map(person => ({
          id: person.id,
          firstname: person.firstname || "",
          lastname: person.lastname || "",
          email: person.email || "",
          street: person.addresses[0] ? person.addresses[0].street : "",
          zip: person.addresses[0] ? person.addresses[0].zip : "",
          city: person.addresses[0] ? person.addresses[0].city : ""
        }))
      );
  }

  public getUserPersonData(
    id: NumericalId,
    clientId: NumericalId
  ): Promise<UserPersonData> {
    return this.gateway.getUserPersonData(id, clientId).then(person => ({
      id: person.id,
      firstname: person.firstname || "",
      lastname: person.lastname || "",
      email: person.email || "",
      street: person.addresses[0] ? person.addresses[0].street : "",
      zip: person.addresses[0] ? person.addresses[0].zip : "",
      city: person.addresses[0] ? person.addresses[0].city : ""
    }));
  }
}

export interface IPersonManager {
  createPerson(data: FullPersonData): Promise<number>;
  searchPersons(
    searchText: string,
    clientId: NumericalId,
    page: Page
  ): Promise<PersonSearchResult[]>;
  getUserPersonData(
    id: NumericalId,
    clientId: NumericalId
  ): Promise<UserPersonData>;
}

export interface PersonGateway {
  createPerson(data: FullPersonData): Promise<Person>;
  searchPersons(
    searchText: string,
    clientId: NumericalId,
    page: Page
  ): Promise<Person[]>;
  getUserPersonData(id: NumericalId, clientId: NumericalId): Promise<Person>;
}

export interface UserPersonData {
  id: number;
  firstname?: string;
  lastname?: string;
  street?: string;
  zip?: string;
  city?: string;
  email?: string;
}

export interface PersonSearchResult extends UserPersonData {}

export interface FullPersonData {
  clientId: NumericalId;
  salutation: string;
  firstname: string;
  lastname: string;
  preTitle: string;
  postTitle: string;

  email: string;
  website: string;
  phoneNumbers: PersonPhoneNumber[];
  addresses: PersonAddress[];
}

export interface PersonPhoneNumber {
  context: string;
  areaCode: string;
  number: string;
}

export interface PersonAddress {
  context: string;
  street: string;
  zip: string;
  city: string;
  country: string;
}
