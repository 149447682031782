export interface Dictionary<T> {
  [key: string]: T;
}

export interface SalutationList extends Dictionary<Salutation> {}
export interface PriorityList extends Dictionary<Priority> {}
export interface TicketStateList extends Dictionary<TicketState> {}
export interface DeadlineList extends Dictionary<Deadline> {}
export interface PhoneContextList extends Dictionary<PhoneContext> {}
export interface AddressContextList extends Dictionary<AddressContext> {}
export interface AreaCodeList extends Dictionary<AreaCode> {}
export interface CountryList extends Dictionary<Country> {}
export interface PreparationStateList extends Dictionary<PreparationState> {}
export interface InspectionStateList extends Dictionary<InspectionState> {}

export interface Salutation {
  value: string;
  text: string;
}

export interface Priority {
  value: string;
  text: string;
  icon: string;
  color: string;
}

export interface TicketState {
  value: string;
  text: string;
  icon: string;
}

export interface Deadline {
  value: string;
  text: string;
  days: number;
}

export interface PhoneContext {
  value: string;
  text: string;
}

export interface AddressContext {
  value: string;
  text: string;
}

export interface AreaCode {
  value: string;
  text: string;
}

export interface Country {
  value: string;
  text: string;
}

export interface PreparationState {
  value: string;
  text: string;
  color: string;
}

export interface InspectionState {
  value: string;
  text: string;
  color: string;
}

export const salutations: SalutationList = {
  mister: {
    value: "mister",
    text: "Herr"
  },
  miss: {
    value: "miss",
    text: "Frau"
  }
};

export const priorities: PriorityList = {
  priority1: {
    value: "priority1",
    text: "Priorität 1",
    icon: "looks_one",
    color: "priority_1"
  },
  priority2: {
    value: "priority2",
    text: "Priorität 2",
    icon: "looks_two",
    color: "priority_2"
  },
  priority3: {
    value: "priority3",
    text: "Priorität 3",
    icon: "looks_3",
    color: "priority_3"
  },
  priority4: {
    value: "priority4",
    text: "Priorität 4",
    icon: "looks_4",
    color: "priority_4"
  },
  priority5: {
    value: "priority5",
    text: "Priorität 5",
    icon: "looks_5",
    color: "priority_5"
  }
};

export const ticketStates: TicketStateList = {
  state1: {
    value: "state1",
    text: "Gemeldet",
    icon: "filter_1"
  },
  state2: {
    value: "state2",
    text: "Angemeldet",
    icon: "filter_2"
  },
  state3: {
    value: "state3",
    text: "Erledigt",
    icon: "filter_3"
  },
  state4: {
    value: "state4",
    text: "Quittiert",
    icon: "filter_4"
  },
  state5: {
    value: "state5",
    text: "Geschlossen",
    icon: "filter_5"
  }
};

export const deadlines: DeadlineList = {
  oneWeek: {
    value: "oneWeek",
    text: "1 Woche",
    days: 7
  },
  twoWeeks: {
    value: "twoWeeks",
    text: "2 Wochen",
    days: 14
  },
  fourWeeks: {
    value: "fourWeeks",
    text: "4 Wochen",
    days: 28
  },
  eightWeeks: {
    value: "eightWeeks",
    text: "8 Wochen",
    days: 56
  }
};

export const phoneContexts: PhoneContextList = {
  private: {
    value: "private",
    text: "Privat"
  },
  work: {
    value: "work",
    text: "Arbeit"
  }
};

export const addressContexts: AddressContextList = {
  private: {
    value: "private",
    text: "Privat"
  },
  work: {
    value: "work",
    text: "Arbeit"
  }
};

export const areaCodes: AreaCodeList = {
  austria: {
    value: "+43",
    text: "(+43) Österreich"
  },
  germany: {
    value: "+49",
    text: "(+49) Deutschland"
  }
};

export const countries: CountryList = {
  austria: {
    value: "austria",
    text: "Österreich"
  },
  germany: {
    value: "germany",
    text: "Deutschland"
  }
};

export const preparationStates: PreparationStateList = {
  prove: {
    value: "prove",
    text: "Zu prüfen",
    color: "accent"
  },
  irrelevant: {
    value: "irrelevant",
    text: "Nicht relevant",
    color: "irrelevant"
  },
  inavailable: {
    value: "inavailable",
    text: "Nicht vorhanden",
    color: "inavailable"
  }
};

export const inspectionStates: InspectionStateList = {
  clear: {
    value: "clear",
    text: "Nicht geprüft",
    color: "deep-purple lighten-3"
  },
  proven: {
    value: "proven",
    text: "Geprüft",
    color: "accent"
  },
  maintenance: {
    value: "maintenance",
    text: "Wartungsvertrag",
    color: "ternary"
  },
  inspection: {
    value: "inspection",
    text: "Aktuelles Gutachten",
    color: "primary"
  }
};
