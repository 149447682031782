import { IMapPresenter } from "../controllers/MapController";
import { MapViewModel } from "../vms/MapViewModel";
import { FormResponse } from "@/forms/FormResponse";
import { PropertyLocation } from "../interactors/PropertyLocator";
import { DocumentMetaDescription } from "@/document/services/DocumentService";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { storageKeys } from "@/data/storageKeys";
import { AppSettings } from "@/admin/interactors/SettingsManager";
import { UserSettings } from "@/storage/UserSettings";

export class MapPresenter implements IMapPresenter {
  private reloadAfterLoadingSettings = false;

  public constructor(
    private vm: MapViewModel,
    private store: SimpleStringStorage
  ) {}

  public impersonate() {
    this.reloadAfterLoadingSettings = true;
  }
  public depersonate() {
    UserSettings.depersonate();
    this.reloadAfterLoadingSettings = true;
  }

  public set loadPropertyLocationsResponse(
    response: FormResponse<PropertyLocation[]>
  ) {
    this.vm.loadPropertyLocationsRequest = response;

    if (!response.loading && !response.error) {
      this.vm.propertyLocations = response.data.map(location => ({
        id: location.id.toString(),
        lat: location.lat,
        lng: location.lng
      }));

      const propertyList = response.data.map(prop => ({
        id: prop.id.toString(),
        label: prop.label
      }));
      this.store.set(storageKeys.properties, JSON.stringify(propertyList));
    }
  }

  public set loadDocumentMetaResponse(
    response: FormResponse<DocumentMetaDescription>
  ) {
    if (response.success) {
      const documentMeta = response.data.map(metum => ({
        id: metum.id,
        label: metum.label
      }));
      const documentMetaJson = JSON.stringify(documentMeta);
      this.store.set(storageKeys.generalDocumentMeta, documentMetaJson);
    }
  }

  public set loadPropertyDocumentMetaResponse(
    response: FormResponse<DocumentMetaDescription>
  ) {
    if (response.success) {
      const documentMeta = response.data.map(metum => ({
        id: metum.id,
        label: metum.label,
        fields: JSON.parse(metum.fields)
      }));
      const documentMetaJson = JSON.stringify(documentMeta);
      this.store.set(storageKeys.propertyDocumentMeta, documentMetaJson);
    }
  }

  public set loadSettingsResponse(response: FormResponse<AppSettings>) {
    if (response.success) {
      response.data.forEach(setting =>
        this.store.set(setting.key, setting.setting)
      );
    }
  }

  public set loadPermissionsResponse(response: FormResponse<string>) {
    if (response.success) {
      this.store.set(storageKeys.permissions, response.data);
      this.vm.reload = this.reloadAfterLoadingSettings;
    }
  }

  public set selectedProperty(id: number) {
    this.vm.selectedProperty = id.toString();
  }

  public set propertySearch(search: string) {
    this.vm.propertySearch = search;
  }
}
