
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Sortable from "sortablejs";
import deepcopy from "deepcopy";

import IconButton from "@/components/basic/IconButton.vue";

import { TypeChecker } from "@/shared/utils/TypeChecker";
import { TableHeader } from "@/shared/datastructures/TableHeader";
import { ArrayUtils } from "@/utils/ArrayUtils";
import { StringUtils } from "@/shared/utils/StringUtils";

@Component<DataTable>({
  components: {
    IconButton
  },
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    itemsPerPage: {
      type: Number,
      default: 20
    }
  }
})
export default class DataTable extends Vue {
  @Prop({ type: Boolean, default: false })
  protected readonly dragAndDrop!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly showSelect!: boolean;
  @Prop() protected readonly noDataText?: string;
  @Prop() protected readonly error?: string;
  @Prop({ default: "name" }) protected readonly itemKey!: string;

  protected name = "";

  protected headers!: TableHeader[];
  protected items!: unknown[];

  protected get emptyTableText() {
    return this.error ? this.error : this.noDataText;
  }

  protected get visibleHeaders(): TableHeader[] {
    const breakpoint = this.currentBreakpoint;

    const filteredHeaders = deepcopy(
      this.headers.filter(header => {
        if (header.visibleOnSize) {
          if (TypeChecker.isArray(header.visibleOnSize)) {
            return header.visibleOnSize.some(size => size === breakpoint);
          } else {
            return header.visibleOnSize === breakpoint;
          }
        }

        return true;
      })
    );

    if (this.dragAndDrop) {
      for (const header of filteredHeaders) {
        header.sortable = !!header.sortable && !this.dragAndDrop;
      }
      if (!filteredHeaders.some(header => header.value === "drag")) {
        filteredHeaders.unshift({
          text: "",
          value: "drag",
          sortable: false,
          width: "1%"
        });
      }
    }

    const xs = breakpoint === "xs";
    const sm = breakpoint === "sm";
    const md = breakpoint === "md";
    const lg = breakpoint === "lg";
    const xl = breakpoint === "xl";

    return filteredHeaders.map(header => ({
      ...header,
      sortable: false,
      currentSize: breakpoint,
      mobile: xs,
      tablet: sm || md,
      desktop: lg || xl,
      tabletAndMobile: xs || sm || md,
      desktopAndTablet: !xs,
      xs,
      sm,
      md,
      lg,
      xl,
      smOrDown: xs || sm,
      mdOrDown: xs || sm || md,
      lgOrDown: xs || sm || md || lg,
      xlOrDown: xs || sm || md || lg || xl
    }));
  }

  protected get currentBreakpoint() {
    return this.$vuetify.breakpoint.name;
  }

  protected created() {
    this.name = StringUtils.randomString(10);
  }

  protected mounted() {
    if (this.dragAndDrop) {
      const table = document.querySelector(`.${this.name} tbody`);

      if (table != null) {
        Sortable.create(table as HTMLElement, {
          animation: 150,
          delay: 300,
          delayOnTouchOnly: true,
          forceFallback: true,
          handle: ".handle",
          onEnd: ({ newIndex, oldIndex }) => {
            if (
              oldIndex !== undefined &&
              newIndex !== undefined &&
              oldIndex !== newIndex
            ) {
              const self = this;
              ArrayUtils.move(self.items, oldIndex, newIndex);

              this.$emit(
                "drop:item",
                this.items[oldIndex],
                newIndex > 0 ? this.items[newIndex - 1] : undefined,
                newIndex,
                oldIndex
              );
            }
          }
        });
      }
    }
  }
}
