
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import TicketLocationForm from "./TicketLocationForm.vue";
import TicketDataForm from "./TicketDataForm.vue";
import TicketDetailsForm from "./TicketDetailsForm.vue";
import TicketImagesForm from "./TicketImagesForm.vue";

import { StepperStep } from "@/shared/form/FormTypes";
import { Ticket } from "@/inspection/shared/models/Ticket";
import { InspectionSettingsContainer } from "@/inspection/shared/settings/InspectionSettingsContainer";

export interface WorkflowStep {
  label: string;
  index: number;
  isLastStep: boolean;
}

export interface TicketCreationForm {
  validate(): boolean;
  reset(): void;
}

@Component<TicketCreationWorkflow>({
  components: {
    TicketLocationForm,
    TicketDataForm,
    TicketDetailsForm,
    TicketImagesForm
  }
})
export default class TicketCreationWorkflow extends Vue {
  @Prop({
    default() {
      return new Ticket();
    }
  })
  protected readonly ticket!: Ticket;
  @Prop({ type: Number }) protected readonly propertyId?: number;
  @Prop() protected readonly checklistType?: string;

  protected static readonly planStepKey = "plan";
  protected static readonly dataStepKey = "data";
  protected static readonly detailsStepKey = "details";
  protected static readonly imagesStepKey = "images";
  protected static readonly lastStep = 4;

  protected ticketType = "";
  protected step = 1;
  protected steps: StepperStep[] = [
    { label: "Verortung", key: TicketCreationWorkflow.planStepKey },
    { label: "Ticketdaten", key: TicketCreationWorkflow.dataStepKey },
    { label: "Details", key: TicketCreationWorkflow.detailsStepKey },
    { label: "Bilder", key: TicketCreationWorkflow.imagesStepKey }
  ];

  protected get currentStepKey() {
    return this.currentStep.key;
  }

  protected get currentStep() {
    return this.steps[this.step - 1];
  }

  protected get currentForm() {
    return this.getForm(this.currentStepKey);
  }

  protected get ticketTypeSelected() {
    return !!this.ticketType;
  }

  public tryNextStep() {
    if (this.validateCurrentForm()) {
      this.nextStep();
    }
  }

  public nextStep() {
    if (
      this.currentStepKey === TicketCreationWorkflow.dataStepKey &&
      !this.ticketTypeSelected
    ) {
      this.jumpToStep(4);
    } else {
      this.jumpToStep(this.step + 1);
    }
  }

  public prevStep() {
    if (
      this.currentStepKey === TicketCreationWorkflow.imagesStepKey &&
      !this.ticketTypeSelected
    ) {
      this.jumpToStep(2);
    } else if (this.step > 1) {
      this.jumpToStep(this.step - 1);
    }
  }

  public jumpToStep(step: number) {
    this.step = step;
    this.emitStepChanged();
    this.validateCurrentForm();
  }

  public reset() {
    this.steps.forEach(s => this.getForm(s.key)?.reset());
    this.ticketType = "";
    this.ticket.clearSyncId();
    this.jumpToStep(1);
  }

  protected getForm(key: string) {
    return this.$refs[key] as unknown as TicketCreationForm;
  }

  protected onTicketTypeSelected(typeKey: string) {
    const ticketType =
      InspectionSettingsContainer.ticketTypes.getTicketType(typeKey);

    this.ticketType = ticketType?.key ?? "";

    if (ticketType?.skipLocation && this.step === 1) {
      this.ticket.location?.clear();
      this.jumpToStep(2);
    }
  }

  protected emitStepChanged() {
    this.$emit("stepChanged", {
      label: this.currentStep.label,
      index: this.step,
      isLastStep: this.step === TicketCreationWorkflow.lastStep
    });
  }

  protected validateCurrentForm() {
    return this.currentForm.validate();
  }

  protected emitValidate(valid: boolean, step: number) {
    if (step === this.step) {
      this.$emit("validate", valid);
    }
  }
}
