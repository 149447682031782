import { StringUtils } from "./StringUtils";

export class MorphTypeConverter {
  public static convertToServer(clientType?: string) {
    if (!clientType) {
      return undefined;
    }

    return `App\\${StringUtils.capitalizeFirstLetter(clientType)}`;
  }

  public static convertToClient(serverType?: string) {
    if (!serverType) {
      return undefined;
    }

    const splitted = serverType.split("\\");

    if (splitted.length === 0) {
      return "";
    } else if (splitted.length === 1) {
      return splitted[0].toLowerCase();
    } else {
      return splitted[1].toLowerCase();
    }
  }
}
