
import Vue from "vue";
import Component from "vue-class-component";

import { LogoutViewModel } from "../vms/LogoutViewModel";
import { LogoutController } from "../controllers/LogoutController";
import { LocalStorage } from "../../storage/LocalStorage";
import { LogoutPresenter } from "../presenters/LogoutPresenter";

@Component<LogoutView>({
  watch: {
    "vm.loggedOut"(loggedOut: boolean) {
      this.$store.dispatch("clearProperty");
      this.$router.push({ name: "login" });
    }
  }
})
export default class LogoutView extends Vue {
  private vm = new LogoutViewModel();

  public get controller() {
    return new LogoutController(
      new LogoutPresenter(this.vm),
      new LocalStorage()
    );
  }

  public mounted() {
    this.controller.init();
  }
}
