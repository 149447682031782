import { Form } from "@/shared/form/Form";
import { ReportDataGeneralFormData } from "./ReportDataGeneralForm";
import { ReportDataImprintFormData } from "./ReportDataImprintForm";
import { ReportDataOtherFormData } from "./ReportDataOtherForm";
import { ReportDataTaskFormData } from "./ReportDataTaskForm";

export interface ReportDataFormData {
  general: ReportDataGeneralFormData;
  imprint: ReportDataImprintFormData;
  task: ReportDataTaskFormData;
  other: ReportDataOtherFormData;
}

export class ReportDataForm extends Form<ReportDataFormData> {
  protected definition = {};
}
