import { countries } from "@/data/settings";
import { ClientAddressData } from "@/property/shared/dtos/ClientDtos";
import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";

export class ClientAddressForm extends Form<ClientAddressData> {
  protected definition = {
    street: {
      label: "Straße",
      required: false,
      type: FormFieldType.Text,
      validate: (data: ClientAddressData) => {
        if (!data.street && (data.zip || data.city || data.country)) {
          return "Erforderlich";
        }
        return true;
      }
    },
    zip: {
      label: "PLZ",
      required: false,
      type: FormFieldType.Text,
      validate: (data: ClientAddressData) => {
        if (!data.zip && (data.street || data.city || data.country)) {
          return "Erforderlich";
        }
        return true;
      }
    },
    city: {
      label: "Stadt",
      required: false,
      type: FormFieldType.Text,
      validate: (data: ClientAddressData) => {
        if (!data.city && (data.zip || data.street || data.country)) {
          return "Erforderlich";
        }
        return true;
      }
    },
    country: {
      label: "Land",
      required: false,
      type: FormFieldType.Selection,
      itemDefinition: {
        items: () => Object.values(countries) as []
      },
      validate: (data: ClientAddressData) => {
        if (!data.country && (data.zip || data.city || data.street)) {
          return "Erforderlich";
        }
        return true;
      }
    }
  };
}
