import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";

export interface ReportDataTaskFormData {
  applicationField: string;
  guidelines: string;
  documents: string;
  commissionBasis: string;
  conditionRegardingLocation: string;
  propertyDescription: string;
}

export class ReportDataTaskForm extends Form<ReportDataTaskFormData> {
  protected definition = {
    applicationField: {
      label: "Anwendungsbereich",
      type: FormFieldType.Text,
      required: true
    },
    guidelines: {
      label: "Herangezogene Richtlinien",
      type: FormFieldType.Text,
      required: true
    },
    documents: {
      label: "Bereitgestellt Unterlagen",
      type: FormFieldType.Text,
      required: true
    },
    commissionBasis: {
      label: "Beauftragungsgrundlage",
      type: FormFieldType.Text,
      required: true
    },
    conditionRegardingLocation: {
      label: "Standortbedingungen",
      type: FormFieldType.Text,
      required: true
    },
    propertyDescription: {
      label: "Gebäudebeschreibung",
      type: FormFieldType.Text,
      required: true
    }
  };
}
