import {
  IChecklistManager,
  ChecklistPreparationData,
  ChecklistSectionPreparationData
} from "../interactors/ChecklistManager";
import { NumericalId } from "@/datastructures/NumericalId";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { preparationStates, PreparationStateList } from "@/data/settings";
import { IndexedFormResponse } from "@/forms/IndexedFormResponse";

export class ChecklistPreparationController {
  public constructor(
    private presenter: IChecklistPreparationPresenter,
    private checklistManager: IChecklistManager
  ) {}

  public init(assignmentId: string) {
    this.presenter.preparationStates = preparationStates;

    this.loadChecklistSections(assignmentId);
  }

  // Requests
  public stateChanged(state: string, sectionId: string) {
    NumericalId.onValidId(sectionId, id => {
      const request = this.checklistManager.setSectionPreparationState(
        state,
        id
      );

      FormRequestHandler.handleIndexed(
        id.id,
        request,
        response => (this.presenter.setSectionStateResponse = response),
        "Status konnte nicht geändert werden"
      );
    });
  }

  public remainingStatesChanged(checklistId: string, state: string) {
    NumericalId.onValidId(checklistId, id => {
      const request = this.checklistManager.setSectionPreparationStates(
        state,
        id,
        this.presenter.sectionsWithoutState
      );

      FormRequestHandler.handle(
        request,
        response => (this.presenter.setSectionStatesResponse = response),
        "Kategorien konnten nicht aktualisiert werden",
        { state }
      );
    });
  }

  public continueToInspectionButtonClicked(checklistId: string) {
    NumericalId.onValidId(checklistId, id => {
      const request = this.checklistManager.finishPreparation(id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.finishPreparationResponse = response),
        "Checklistenvorbereitung konnte nicht abgeschlossen werden"
      );
    });
  }

  private loadChecklistSections(assignmentId: string) {
    NumericalId.onValidId(assignmentId, id => {
      const request = this.checklistManager.loadChecklistForPreparation(id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadChecklistResponse = response),
        "Checkliste konnte nicht geladen werden"
      );
    });
  }
}

export interface IChecklistPreparationPresenter {
  // Settings
  preparationStates: PreparationStateList;

  // Data
  sectionsWithoutState: NumericalId[];

  // Responses
  loadChecklistResponse: FormResponse<ChecklistPreparationData>;
  setSectionStateResponse: IndexedFormResponse<ChecklistSectionPreparationData>;
  setSectionStatesResponse: FormResponse<ChecklistSectionPreparationData[]>;
  finishPreparationResponse: FormResponse<NumericalId>;
}
