
import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import Accordion from "@/components/layout/Accordion.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import RequestButton from "@/components/basic/RequestButton.vue";
import Select from "@/components/form/Select.vue";

import { ChecklistPreparationViewModel } from "@/expert/vms/ChecklistPreparationViewModel";
import { ChecklistPreparationController } from "@/expert/controllers/ChecklistPreparationController";
import { ChecklistPreparationPresenter } from "@/expert/presenters/ChecklistPreparationPresenter";
import { ChecklistManager } from "@/expert/interactors/ChecklistManager";
import { ChecklistManagerGraphQLGateway } from "@/gateways/graphql/ChecklistManagerGraphQLGateway";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connection/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
// import { LocalStorage } from "@/storage/LocalStorage";

@Component<ChecklistPreparationView>({
  components: {
    Page,
    Section,
    Accordion,
    Grid,
    Row,
    Column,
    Select,
    RequestButton
  },
  watch: {
    "vm.goToInspection"(go: boolean) {
      if (go) {
        this.$router.push({
          name: "inspection",
          params: { assignmentId: this.assignmentId }
        });
      }
    }
  }
})
export default class ChecklistPreparationView extends Vue {
  private vm = new ChecklistPreparationViewModel();

  private get controller() {
    return new ChecklistPreparationController(
      new ChecklistPreparationPresenter(this.vm),
      new ChecklistManager(
        new ChecklistManagerGraphQLGateway(
          new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger()))
        )
      )
    );
  }

  private get assignmentId(): string {
    return this.$route.params.assignmentId;
  }

  private mounted() {
    this.controller.init(this.assignmentId);
  }
}
