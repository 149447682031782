import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import de from "vuetify/src/locale/de";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de },
    current: "de"
  },
  icons: {
    iconfont: "md"
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#00629a",
        secondary: "#00568b",
        ternary: "#a0c2dd",
        accent: "#a1c000",
        background: "#ffffff",
        surface: "#f0f0f0",
        error: "#b00020",
        on_primary: "#ffffff",
        on_secondary: "#ffffff",
        on_background: "#000000",
        on_surface: "#000000",
        on_error: "#ffffff",
        priority_1: "#dd112a",
        priority_2: "#e95527",
        priority_3: "#f08723",
        priority_4: "#f8b421",
        priority_5: "#ffd61f",
        irrelevant: "#6b6b6b",
        inavailable: "#9e9e9e"
      }
    }
  }
});
