import { DateUtils } from "@/utils/DateUtils";
import { MathUtils } from "@/utils/MathUtils";
import { Dictionary } from "@/datastructures/Dictionary";
import { SelectionItem } from "./ViewModelFormTypes";
import { ArrayUtils } from "@/utils/ArrayUtils";
import { ObjectUtils } from "@/utils/ObjectUtils";

export abstract class Form {
  public static generateSelectionList<T extends SelectionItem>(
    dictionary: Dictionary<T>
  ): SelectionItem[] {
    return Object.entries(dictionary).map(item => item[1]);
  }

  public data: FormData = {};
  protected abstract definition: FormDefinition;
  private subForms: Form[] = [];
  private hooks: FormHooks = {};
  private hasMatches: boolean = false;

  public constructor(
    protected fieldContext: any,
    public validatedEvent?: (context: any, valid: boolean) => void,
    protected formContext?: any
  ) {
    if (!this.formContext) {
      this.formContext = fieldContext;
    }
  }

  public getFieldValue(fieldName: string) {
    return this.getField(fieldName).text;
  }
  public setFieldValue(
    fieldName: string,
    value: string,
    subFormIndex?: number,
    skipValidation = false
  ) {
    if (subFormIndex !== undefined) {
      this.subForms[subFormIndex].setFieldValue(fieldName, value);
      this.validateForm();
      return;
    }

    const field = this.getField(fieldName);
    field.text = value !== undefined ? value?.toString() ?? "" : "false";
    field.changedFirstTime = true;

    let validField = true;
    if (!skipValidation) {
      validField = this.validateField(fieldName, field);
    }

    if (this.hasMatches) {
      const matchingFieldDef = Object.entries(this.definition).find(
        def => def[1].match === fieldName
      );
      if (matchingFieldDef) {
        const matchingField = this.getField(matchingFieldDef[0]);
        if (!!matchingField.text) {
          this.validateField(matchingFieldDef[0], matchingField);
          this.fieldValueChanged(
            this.fieldContext,
            matchingFieldDef[0],
            matchingField
          );
        }
      }
    }

    this.fieldValueChanged(this.fieldContext, fieldName, field);
    this.validateForm();

    return validField;
  }

  public setFieldError(fieldName: string, error: string) {
    const field = this.getField(fieldName);
    field.error = error;
    this.fieldContext[fieldName].error = error;
    this.validateForm();
  }

  public init() {
    if (Object.keys(this.definition).length === Object.keys(this.data).length) {
      return;
    }

    Object.keys(this.definition).forEach(
      fieldName => (this.data[fieldName] = this.createField(fieldName))
    );

    this.hasMatches = Object.values(this.definition).some(def => !!def.match);
    this.validateAllFields();
    this.validateForm();
  }

  public validateForm(): boolean {
    let valid = Object.entries(this.data).every(field => {
      return (
        !field[1].error &&
        (field[1].changedFirstTime || !this.definition[field[0]].required)
      );
    });

    valid = valid && this.subForms.every(form => form.validateForm());

    if (this.validatedEvent) {
      this.validatedEvent(this.formContext, valid);
    }

    return valid;
  }

  public subscribeSubForm(subForm: Form) {
    subForm.init();
    this.subForms.push(subForm);
  }

  public unsubscribeSubForm(subForm: Form) {
    this.subForms = ArrayUtils.remove(subForm, this.subForms);
  }

  public addHook(
    fieldname: string,
    hook: (context: any, value: string) => void,
    context?: any
  ) {
    this.hooks[fieldname] = { hook, context };
  }

  public reset() {
    Object.keys(this.data).forEach(fieldName =>
      this.setFieldValue(fieldName, "", undefined, true)
    );
    ObjectUtils.clean(this.data);
    this.init();
  }

  protected validateAllFields() {
    Object.entries(this.data).forEach(field => {
      this.validateField(field[0], field[1]);
    });
  }
  protected validateField(fieldName: string, field: FormField): boolean {
    const definition = this.definition[fieldName];
    field.error = "";

    if (definition.required && field.changedFirstTime) {
      field.error = !!field.text ? "" : "required";
    }
    if (!field.error && definition.greaterThan !== undefined) {
      const value = parseInt(field.text, 10);
      field.error = isNaN(value)
        ? "not-a-number"
        : value > definition.greaterThan
        ? ""
        : "not-greater-than(" + definition.greaterThan + ")";
    }
    if (
      !field.error &&
      field.text &&
      field.text.length > 0 &&
      definition.regex !== undefined
    ) {
      field.error = definition.regex.test(field.text)
        ? ""
        : this.getFieldError(definition, "not-matching");
    }
    if (
      !field.error &&
      field.text &&
      field.text.length > 0 &&
      definition.match !== undefined
    ) {
      const matchingField = this.getFieldValue(definition.match);
      field.error =
        matchingField === field.text
          ? ""
          : this.getFieldError(definition, "not-matching");
    }

    return !field.error;
  }
  protected getField(fieldName: string) {
    this.throwErrorIfFieldNotExists(fieldName);
    this.init();
    return this.data[fieldName];
  }

  private fieldValueChanged(context: any, fieldName: string, field: FormField) {
    const definition = this.definition[fieldName];
    const contextField = context[fieldName];

    switch (definition.type) {
      case FormFieldType.Text:
        contextField.value = field.text;
        contextField.error = field.error;
        break;

      case FormFieldType.Number:
        contextField.value =
          parseFloat(field.text.replace(",", ".")) || contextField.default || 0;
        contextField.error = field.error;
        break;

      case FormFieldType.Selection:
        contextField.selected = field.text;
        contextField.error = field.error;
        break;

      case FormFieldType.Date:
        contextField.value = field.text;
        contextField.text = DateUtils.format(field.text);
        contextField.error = field.error;
        break;

      case FormFieldType.Checkbox:
        contextField.value = MathUtils.parseBoolean(field.text);
        contextField.selected = MathUtils.parseBoolean(field.text);
        break;
    }

    if (this.hookExists(fieldName)) {
      const hook = this.hooks[fieldName];
      hook.hook(hook.context || this.fieldContext, field.text);
    }
  }

  private createField(fieldName: string) {
    return {
      text: "",
      error: "",
      changedFirstTime: !this.needToObserveChange(fieldName)
    };
  }

  private needToObserveChange(fieldName: string) {
    return this.definition[fieldName].required;
  }

  private throwErrorIfFieldNotExists(fieldName: string) {
    if (!this.fieldExists(fieldName)) {
      throw new Error(`Field ${fieldName} doesn't exist`);
    }
  }

  private fieldExists(fieldName: string) {
    return Object.keys(this.definition).some(field => field === fieldName);
  }

  private hookExists(hookName: string) {
    return Object.keys(this.hooks).some(hook => hook === hookName);
  }

  private getFieldError(definition: FormFieldDefinition, defaultError: string) {
    return definition.error ? definition.error : defaultError;
  }
}

export interface FormData {
  [key: string]: FormField;
}

export interface FormField {
  text: string;
  error: string;
  changedFirstTime: boolean;
}

export enum FormFieldType {
  Text,
  Number,
  Date,
  Selection,
  Checkbox
}

export interface FormDefinition {
  [key: string]: FormFieldDefinition;
}
export interface FormFieldDefinition {
  required: boolean;
  type: FormFieldType;
  greaterThan?: number;
  regex?: RegExp;
  match?: string;
  error?: string;
}

export interface FormHooks {
  [key: string]: FieldHook;
}

export interface FieldHook {
  hook: (context: any, value: string) => void;
  context?: any;
}
