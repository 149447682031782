import { PersonEntity } from "../entities/PersonEntity";
import { PersonAddressCollection } from "./PersonAddressCollection";

export class Person {
  public id?: number;
  public firstname?: string;
  public lastname?: string;
  public addressList?: PersonAddressCollection;

  public get exists() {
    return !!this.id;
  }

  public get name() {
    return [this.firstname, this.lastname].filter(n => !!n).join(" ");
  }

  public get hasAddress() {
    return !(this.addressList?.empty ?? false);
  }

  public get primaryAddress() {
    return this.addressList?.first;
  }

  public toString() {
    if (!this.exists) {
      return "Nicht mehr vorhanden";
    }

    let text = this.firstname;

    if (this.lastname) {
      text += " " + this.lastname;
    }

    if (this.hasAddress) {
      text += ": " + this.addressList?.first.toString();
    }

    return text;
  }

  public toEntity(): PersonEntity {
    return {
      id: this.id,
      firstname: this.firstname,
      lastname: this.lastname
    };
  }

  public from(entity?: PersonEntity) {
    this.id = entity?.id;
    this.firstname = entity?.firstname;
    this.lastname = entity?.lastname;
    this.addressList = PersonAddressCollection.from(entity?.addresses);
  }

  public static from(entity?: PersonEntity) {
    const person = new Person();
    person.from(entity);
    return person;
  }
}
