import { ChecklistEntryCollection } from "@/inspection/shared/models/ChecklistEntryCollection";
import { UseCase } from "@/shared/datastructures/UseCase";

export class SortEntriesUseCase extends UseCase<
  ChecklistEntryCollection,
  void
> {
  public async do(input: ChecklistEntryCollection): Promise<void> {
    input.sortAlphabetically();
  }
}
