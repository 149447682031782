
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import TicketPrioritySelect from "./TicketPrioritySelect.vue";
import TicketStateSelect from "./TicketStateSelect.vue";
import PropertySelect from "@/property/shared/widgets/views/PropertySelect.vue";
import { InspectionSelectionItemLoader } from "@/inspection/shared/dtos/InspectionSelectionItemLoader";
import { Property } from "@/property/shared/models/Property";

@Component<TicketFilter>({
  components: { TicketPrioritySelect, TicketStateSelect, PropertySelect }
})
export default class TicketFilter extends Vue {
  @Prop({ type: String, default: "" })
  protected readonly fulltextSearch!: string;
  @Prop({ type: Array, default: () => [] })
  protected readonly properties!: Property[];
  @Prop({ type: Array, default: () => [] })
  protected readonly priorities!: string[];
  @Prop({ type: Array, default: () => [] })
  protected readonly states!: string[];
  @Prop({ type: Boolean, default: false })
  protected readonly noReferences!: string;
  @Prop({ type: Boolean, default: false })
  protected readonly loading!: boolean;

  protected get ticketStatesAvailable() {
    return InspectionSelectionItemLoader.loadTicketStates().length > 0;
  }

  protected get propertyIds() {
    return this.properties.map(p => p.id ?? 0);
  }

  protected emitPropertiesChanged(propertyIds?: number[]) {
    this.emitChange("properties", propertyIds ?? []);
  }

  protected emitChange(field: string, value: unknown) {
    this.$emit(`change:${field}`, value);
  }
}
