
import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import IconButton from "@/components/basic/IconButton.vue";
import Button from "@/components/basic/Button.vue";

import ReportSettings from "./editReportPageComponents/ReportSettings.vue";
import ReportData from "./editReportPageComponents/ReportData.vue";

import { Report } from "../../shared/models/Report";

@Component<EditReportPage>({
  components: {
    Page,
    Section,
    Card,
    Grid,
    Row,
    Column,
    IconButton,
    Button,
    ReportSettings,
    ReportData
  },
  props: {
    assignmentId: {
      type: String,
      required: true
    },
    reportType: {
      type: String,
      required: true
    }
  }
})
export default class EditReportPage extends Vue {
  protected report: Report = new Report();
  protected assignmentId!: string;
  protected reportType!: string;

  protected reportStoredDialogVisible = false;

  protected get hasSettings() {
    return this.reportType === "default";
  }

  protected get reportDataClass() {
    return this.hasSettings ? "mt-2" : "";
  }

  protected get reportUrl() {
    return this.$store.state.inspection.reportState.reportUrl;
  }

  protected prevDataStep() {
    (this.$refs.reportData as ReportData).prevStep();
  }

  protected nextDataStep() {
    (this.$refs.reportData as ReportData).nextStep();
  }

  protected saveData() {
    (this.$refs.reportData as ReportData).save();
  }

  protected async saveReport(type: string) {
    if (type === "download") {
      this.downloadReport(this.reportUrl);
    } else {
      this.report.init({
        reportType: this.reportType,
        assignmentId: parseInt(this.assignmentId, 10)
      });

      if (await this.report.storeReportInDocuments()) {
        this.reportStoredDialogVisible = true;
      }
    }
  }

  protected downloadReport(url: string) {
    window.open(url + "?nocache=" + new Date().getTime(), "_blank");
  }
}
