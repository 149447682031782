
import Vue from "vue";
import Component from "vue-class-component";

import { textTheme } from "../TextStyles";

@Component<StyledText>({
  props: {
    s: {
      type: String,
      default: "body"
    },
    color: {
      type: String,
      default: ""
    },
    weight: {
      type: String,
      default: ""
    },
    align: {
      type: String,
      default: "left"
    },
    bold: {
      type: Boolean,
      default: false
    },
    italic: {
      type: Boolean,
      default: false
    },
    letterSpacing: {
      type: String,
      default: ""
    },
    bottomMargin: {
      type: Number,
      default: -11
    },
    width: {
      type: String,
      default: ""
    },
    underlined: {
      type: Boolean,
      default: undefined
    },
    uppercased: {
      type: Boolean,
      default: undefined
    },
    inline: {
      type: Boolean,
      default: false
    },
    fontSize: {
      type: Number,
      default: undefined
    },
    crossed: {
      type: Boolean,
      default: false
    },
    noWrap: {
      type: Boolean,
      default: false
    }
  }
})
export default class StyledText extends Vue {
  private s!: string;
  private color!: string;
  private weight!: string;
  private width!: string;
  private align!: string;
  private letterSpacing!: string;
  private bottomMargin!: number;
  private underlined!: boolean;
  private uppercased!: boolean;
  private bold!: boolean;
  private italic!: boolean;
  private inline!: boolean;
  private fontSize!: number;
  private crossed!: boolean;
  private noWrap!: boolean;

  private get cssStyle() {
    return {
      textTransform: this.textUppercased ? "uppercase" : "none",
      fontSize: this.textSize + "rem",
      fontWeight: this.textWeight,
      color: this.usesHexColor ? this.textColor : undefined,
      letterSpacing: this.textLetterSpacing
        ? this.textLetterSpacing + "px"
        : undefined,
      "--width": this.width,
      display: this.inline ? "inline-block" : "block",
      textDecoration: this.crossed ? "line-through" : undefined,
      whiteSpace: "pre-wrap"
    };
  }

  private get cssClasses() {
    const classes: string[] = [];

    if (this.width) {
      classes.push("truncate");
    }

    if (this.noWrap) {
      classes.push("text-no-wrap");
    }

    if (this.italic) {
      classes.push("font-italic");
    }

    if (!this.usesHexColor) {
      const [baseColor, shade, degree] = this.textColor.split("-");
      const baseColorClass = baseColor + "--text";
      const shadeClass = shade ? "text--" + shade + "-" + degree : undefined;
      classes.push(baseColorClass);

      if (shadeClass) {
        classes.push(shadeClass);
      }
    }

    if (this.textUnderlined) {
      classes.push("text-decoration-underline");
    }

    classes.push("mb-" + this.textBottomMargin);
    classes.push("text-" + this.align);

    return classes;
  }

  private get textStyle() {
    let style = textTheme[this.s];

    if (!style) {
      style = textTheme.body;
    }

    return style;
  }

  private get textColor() {
    return this.color ? this.color : this.textStyle.color;
  }

  private get textSize() {
    return this.fontSize !== undefined
      ? this.fontSize
      : this.textStyle.fontSize;
  }

  private get textWeight() {
    return this.weight
      ? this.weight
      : this.bold
      ? "bold"
      : this.textStyle.fontWeight;
  }

  private get textUppercased() {
    return this.uppercased !== undefined
      ? this.uppercased
      : this.textStyle.uppercase;
  }

  private get textLetterSpacing() {
    return this.letterSpacing
      ? this.letterSpacing
      : this.textStyle.letterSpacing;
  }

  private get textUnderlined() {
    return this.underlined !== undefined
      ? this.underlined
      : this.textStyle.underlined;
  }

  private get textBottomMargin() {
    const margin =
      this.bottomMargin >= -10
        ? this.bottomMargin
        : this.textStyle.bottomMargin;

    return margin >= 0 ? margin.toString() : "n" + Math.abs(margin);
  }

  private get usesHexColor() {
    return this.textColor.startsWith("#");
  }
}
