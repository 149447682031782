
import Vue from "vue";
import Component from "vue-class-component";

@Component<GoogleMaps>({
  props: {
    center: {
      type: Object,
      default() {
        return { lat: 0, lng: 0 };
      }
    },
    marker: {
      type: [Object, Array],
      default() {
        return { lat: 0, lng: 0 };
      }
    }
  },
  watch: {
    marker(marker) {
      this.fitMarkers();
    }
  }
})
export default class GoogleMaps extends Vue {
  private get google() {
    return (window as any).google;
  }

  private get markers() {
    return Array.isArray(this.$props.marker)
      ? this.$props.marker
      : [this.$props.marker];
  }

  private fitMarkers() {
    if (!this.google || !this.$refs.map) {
      return;
    }

    (this.$refs.map as any).$mapPromise.then((map: any) => {
      const bounds = new this.google.maps.LatLngBounds();

      for (const marker of this.markers) {
        bounds.extend(marker);
      }

      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const extendPoint1 = new this.google.maps.LatLng(
          bounds.getNorthEast().lat() + 0.01,
          bounds.getNorthEast().lng() + 0.01
        );
        const extendPoint2 = new this.google.maps.LatLng(
          bounds.getNorthEast().lat() - 0.01,
          bounds.getNorthEast().lng() - 0.01
        );
        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }
      map.fitBounds(bounds);
    });
  }

  private mounted() {
    this.fitMarkers();
  }
}
