import { ModelCollection } from "@/shared/model/ModelCollection";
import { AccessEntity } from "../entities/AccessEntity";
import { Access } from "./Access";

export class Accesses extends ModelCollection<Access, AccessEntity> {
  public from(entities?: AccessEntity[]): void {
    this.elements = entities?.map(e => Access.from(e)) ?? [];
  }
  protected newInstance(): ModelCollection<Access, AccessEntity> {
    return new Accesses();
  }

  public static collect(accesses: Access[]) {
    const collection = new Accesses();
    collection.elements = accesses;
    return collection;
  }

  public static from(entities?: AccessEntity[]) {
    const collection = new Accesses();
    collection.from(entities);
    return collection;
  }
}
