import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { GraphQLConnection } from "@/gateways/graphql/connection/GraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { Paginated } from "@/shared/datastructures/Paginated";
import { NewPropertyPartData } from "../../dtos/NewPropertyPartData";
import { Property } from "../../dtos/Property";
import { PropertyPart, PropertyPartsAndPlants } from "../../dtos/PropertyPart";
import { PlantGraphQLProvider } from "../plantRepository/PlantGraphQLProvider";
import { PropertyEntity } from "../../entities/PropertyEntity";
import { PropertyProvider } from "./PropertyProvider";

export class PropertyGraphQLProvider implements PropertyProvider {
  public constructor(
    private connection: GraphQLConnection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    ),
    private fromCache = false
  ) {}

  public async loadProperties(
    userId: number,
    page: number,
    search: string
  ): Promise<Paginated<Property>> {
    const result = await this.connection.queryPaginated(
      "userProperties",
      20,
      page,
      { user_id: userId },
      [
        "id",
        "name",
        "build_year",
        "unit_count",
        "legal_form",
        "effective_area",
        { name: "address", fields: ["postcode", "street", "city"] }
      ],
      search,
      undefined,
      undefined,
      this.fromCache
    );

    return Paginated.fromResponse(result, this.convertToProperties);
  }

  public async loadPropertySelection(
    userId: number
  ): Promise<PropertyEntity[]> {
    const result = await this.connection.queryPaginated(
      "userProperties",
      500,
      1,
      { user_id: userId },
      [
        "id",
        "name",
        { name: "address", fields: ["postcode", "street", "city"] }
      ],
      "",
      undefined,
      undefined,
      this.fromCache
    );

    return this.convertToProperties(result.data);
  }

  public async loadPropertyBasePart(propertyId: number): Promise<PropertyPart> {
    const result = await this.connection.query(
      "propertyParts",
      { property_id: propertyId },
      ["id", "type", "name", "hasParts"]
    );

    const baseParts = this.convertToPropertyParts(result.data);

    if (baseParts.length > 0) {
      return baseParts[0];
    } else {
      throw Error("Gebäude hat kein Basis-Gebäudeteil");
    }
  }

  public async loadPropertyChildPartsAndPlants(
    parentPartId: number
  ): Promise<PropertyPartsAndPlants> {
    const result = await this.connection.query(
      "propertyPartsAndPlants",
      { parent_id: parentPartId },
      [
        {
          name: "parts",
          fields: [
            "id",
            "type",
            "name",
            "hasParts",
            { name: "sibling_plants", fields: ["id", "name"] }
          ]
        },
        { name: "plants", fields: ["id", "name", "has_child_plants"] },
        { name: "sibling_plants", fields: ["id", "name", "has_child_plants"] }
      ]
    );

    return {
      parts: this.convertToPropertyParts(result.data.parts),
      plants: PlantGraphQLProvider.convertToPlantEntities(result.data.plants),
      siblingPlants: result.data.sibling_plants
        ? PlantGraphQLProvider.convertToPlantEntities(
            result.data.sibling_plants
          )
        : []
    };
  }

  public async createPropertyPart(
    parentPartId: number,
    data: NewPropertyPartData
  ): Promise<number> {
    const result = await this.connection.mutation(
      "createPropertyPart",
      {
        input: {
          parent_id: parentPartId,
          name: data.name,
          type: data.type
        }
      },
      []
    );

    return result.data;
  }

  public async editPropertyPart(
    partId: number,
    data: NewPropertyPartData
  ): Promise<number> {
    const result = await this.connection.mutation(
      "updatePropertyPart",
      { input: { id: partId, name: data.name, type: data.type } },
      []
    );

    return result.data;
  }

  public async deletePropertyPart(partId: number): Promise<number> {
    const result = await this.connection.mutation(
      "deletePropertyPart",
      { id: partId },
      []
    );

    return result.data;
  }

  private convertToProperties(data: any[]): Property[] {
    return data.map(p => ({
      id: parseInt(p.id, 10),
      name: p.name,
      buildYear: p.build_year,
      unitCount: p.unit_count,
      legalForm: p.legal_form,
      effectiveArea: p.effective_area,
      street: p.address?.street || "",
      zip: p.address?.postcode || "",
      city: p.address?.city
    }));
  }

  private convertToPropertyParts(data: any[]) {
    return data.map(p => ({
      id: parseInt(p.id.toString(), 10),
      name: p.name,
      type: p.type,
      hasChildren: p.hasParts,
      siblingPlants: p.sibling_plants
        ? PlantGraphQLProvider.convertToPlantEntities(p.sibling_plants)
        : undefined
    }));
  }
}
