import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { GraphQLConnection } from "@/gateways/graphql/connection/GraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import {
  GenericData,
  GenericDataConverter
} from "@/shared/datastructures/GenericDataConverter";
import { PersonEntity } from "../../entities/PersonEntity";
import { PersonProvider } from "./PersonProvider";

export class PersonGraphQLProvider implements PersonProvider {
  public static personConverter = new GenericDataConverter<PersonEntity>([
    { from: "postcode", to: "zip" }
  ]);

  public constructor(
    private connection: GraphQLConnection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    )
  ) {}

  public async loadPersonSelection(
    clientId: number,
    search: string
  ): Promise<PersonEntity[]> {
    const result = await this.connection.queryPaginated(
      "newPeople",
      100,
      1,
      { client_id: clientId },
      [
        "id",
        "firstname",
        "lastname",
        { name: "addresses", fields: ["street", "city", "postcode"] }
      ],
      search
    );

    return result.data.map((d: GenericData) =>
      PersonGraphQLProvider.personConverter.convert(d)
    );
  }
}
