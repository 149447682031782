
import Vue from "vue";
import Component from "vue-class-component";

import SearchSelect from "@/components/form/SearchSelect.vue";

import { EmployeeSelectVm } from "../models/EmployeeSelectVm";
import { EmployeeModel } from "@/property/client/models/EmployeeModel";

@Component<EmployeeSelect>({
  components: {
    SearchSelect
  },
  props: {
    clientId: {
      type: Number,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    clientId(clientId: number) {
      this.load();
    }
  }
})
export default class EmployeeSelect extends Vue {
  private vm = new EmployeeSelectVm();
  private clientId!: number;

  private mounted() {
    this.load();
  }

  private load() {
    this.vm.load(this.clientId);
  }

  private emitSelect(employee: EmployeeModel) {
    this.$emit("select", employee);
  }
}
