import { LocalStorage } from "@/storage/LocalStorage";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";

export abstract class Settings<TSetting> {
  public constructor(
    private storage: SimpleStringStorage = new LocalStorage()
  ) {}

  protected abstract get storageKey(): string;
  protected get isArray(): boolean {
    return false;
  }

  public get(): TSetting {
    return this.storage.getJson(this.storageKey, this.isArray) as TSetting;
  }
}
