
import Vue from "vue";
import Component from "vue-class-component";

import Section from "@/components/layout/Section.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import InfoTooltip from "@/components/basic/InfoTooltip.vue";
import StyledText from "@/components/basic/StyledText.vue";
import Button from "@/components/basic/Button.vue";
import IconButton from "@/components/basic/IconButton.vue";
import Alert from "@/components/basic/Alert.vue";
import InfoDialog from "@/components/basic/InfoDialog.vue";

import ClientSelect from "@/property/shared/widgets/views/ClientSelect.vue";
import EmployeeSelect from "@/property/shared/widgets/views/EmployeeSelect.vue";

import AccessRecipient from "./AccessRecipient.vue";
import AccessResourceTree from "./AccessResourceTree.vue";

import { AccessManagerVm } from "./vms/AccessManagerVm";

@Component<AccessManager>({
  components: {
    Section,
    Grid,
    Row,
    Column,
    ClientSelect,
    EmployeeSelect,
    StyledText,
    InfoTooltip,
    Button,
    IconButton,
    Alert,
    InfoDialog,
    AccessRecipient,
    AccessResourceTree
  },
  props: {
    vm: {
      type: Object,
      default() {
        return new AccessManagerVm();
      }
    }
  }
})
export default class AccessManager extends Vue {}
