import { Paginated } from "@/shared/datastructures/Paginated";
import { Assignment } from "../../dtos/Assignment";
import { AssignmentEntity } from "../../entities/AssignmentEntity";
import { AssignmentGraphQLProvider } from "./AssignmentGraphQLProvider";
import { AssignmentProvider } from "./AssignmentProvider";

export interface AssignmentRepository {
  getAssignmentById(
    id: number,
    includeChecklist: boolean
  ): Promise<AssignmentEntity>;

  getBaseAssignmentByType(
    page: number,
    type: string,
    propertyIds: number[],
    search: string
  ): Promise<Paginated<Assignment>>;
  getPropertyAssignments(
    page: number,
    propertyIds: number[],
    search?: string
  ): Promise<Paginated<Assignment>>;

  create(assignment: AssignmentEntity): Promise<number>;

  importTickets(assignmentId: number, file: File): Promise<number[]>;
}

export class DefaultAssignmentRepository implements AssignmentRepository {
  public constructor(
    private webProvider: AssignmentProvider = new AssignmentGraphQLProvider()
  ) {}

  public getAssignmentById(
    id: number,
    includeChecklist: boolean
  ): Promise<AssignmentEntity> {
    return this.webProvider.loadAssignmentById(id, includeChecklist);
  }

  public getBaseAssignmentByType(
    page: number,
    type: string,
    propertyIds: number[],
    search = ""
  ): Promise<Paginated<Assignment>> {
    return this.webProvider.loadBaseAssignmentByType(
      page,
      type,
      propertyIds,
      search
    );
  }

  public getPropertyAssignments(
    page: number,
    propertyIds: number[],
    search = ""
  ): Promise<Paginated<Assignment>> {
    return this.webProvider.loadPropertyAssignments(page, propertyIds, search);
  }

  public create(assignment: AssignmentEntity): Promise<number> {
    return this.webProvider.create(assignment);
  }

  public importTickets(assignmentId: number, file: File): Promise<number[]> {
    return this.webProvider.importTickets(assignmentId, file);
  }
}
