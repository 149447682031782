import { Dictionary } from "@/datastructures/Dictionary";

export class ObjectUtils {
  public static deepCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
  }

  public static isEmpty(obj: object) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  public static isObject(obj: any): boolean {
    return typeof obj === "object" && obj !== null;
  }

  public static hasKey(key: string, obj: any) {
    return this.isObject(obj) && Object.keys(obj).includes(key);
  }

  public static clean(obj: Dictionary<any>) {
    Object.keys(obj).forEach(key => delete obj[key]);
  }
}
