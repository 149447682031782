import { Form } from "./Form";

export class FormArray<T> {
  public constructor(public subForms: Array<Form<T>>) {}

  public push(subForm: Form<T>) {
    this.subForms.push(subForm);
  }

  public removeByTarget(target: object) {
    const subForm = this.getSubFormByTarget(target);
    if (subForm) {
      this.remove(subForm);
    }
  }

  public remove(subForm?: Form<T>) {
    if (!subForm) {
      return;
    }

    const index = this.subForms.indexOf(subForm);
    if (index >= 0) {
      this.removeAt(index);
    }
  }

  public removeAt(index: number) {
    this.subForms.splice(index, 1);
  }

  public getSubFormByTarget(target: object) {
    const subForm = this.subForms.find(f => f.target === target) as Form<T>;
    return subForm;
    // if (subForm) {
    //   return subForm;
    // } else {
    //   throw Error("Subform mit mit Zielobjekt nicht gefunden");
    // }
  }

  public clear() {
    this.subForms.length = 0;
  }
}
