import { ILogoutPresenter } from "../controllers/LogoutController";
import { LogoutViewModel } from "../vms/LogoutViewModel";

export class LogoutPresenter implements ILogoutPresenter {
  public constructor(private vm: LogoutViewModel) {}

  public set loggedOut(loggedOut: boolean) {
    this.vm.loggedOut = true;
  }
}
