
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import ChecklistEntryStateSelect from "./ChecklistEntryStateSelect.vue";

import { ChecklistEntry } from "@/inspection/shared/models/ChecklistEntry";
import { InspectionSelectionItemLoader } from "@/inspection/shared/dtos/InspectionSelectionItemLoader";

@Component<EditChecklistEntryDialog>({
  components: { ChecklistEntryStateSelect }
})
export default class EditChecklistEntryDialog extends Vue {
  @Prop({ type: Object, default: () => new ChecklistEntry() })
  protected readonly entry!: ChecklistEntry;
  @Prop() protected readonly checklistType?: string;

  protected get preparationStateItems() {
    return InspectionSelectionItemLoader.loadChecklistPreparationStates();
  }
}
