import {
  ITicketManager,
  EditTicketData,
  TicketImage,
  UpdateTicketLocationData,
  UpdateChecklistReferenceData
} from "@/expert/interactors/TicketManager";
import { NumericalId } from "@/datastructures/NumericalId";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { TicketData } from "../interactors/TicketViewer";
import { TicketDescriptionForm } from "@/forms/ticket/TicketDescriptionForm";
import { TicketOrganizationForm } from "@/forms/ticket/TicketOrganizationForm";
import { Point } from "@/datastructures/Point";
import { LocationViewModel } from "../vms/TicketDetailViewModel";
import {
  IAssignmentManager,
  AssignmentListRow
} from "@/expert/interactors/AssignmentManager";
import {
  IChecklistManager,
  ChecklistName
} from "@/expert/interactors/ChecklistManager";
import { Priorities, UserSettings } from "@/storage/UserSettings";

export class TicketDetailController {
  public constructor(
    private presenter: ITicketDetailPresenter,
    private ticketManager: ITicketManager,
    private assignmentManager: IAssignmentManager,
    private checklistManager: IChecklistManager
  ) {}

  public init(
    ticketId: string,
    search?: string,
    priority?: string,
    state?: string,
    entryId?: string,
    plant?: number,
    entryTemplate?: string
  ) {
    this.presenter.priorities = UserSettings.getPriorities();

    this.loadTicket(
      ticketId,
      search,
      priority,
      state,
      entryId,
      plant,
      entryTemplate
    );
  }

  // Requests
  public loadTicket(
    ticketId: string,
    search?: string,
    priority?: string,
    state?: string,
    entryId?: string,
    plant?: number,
    entryTemplate?: string
  ) {
    NumericalId.onValidId(ticketId, id => {
      const request = this.ticketManager.loadTicket(
        id,
        UserSettings.getClientId(),
        this.getPropertyId(),
        search,
        priority,
        state,
        entryId,
        plant,
        entryTemplate
      );

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadTicketResponse = response),
        "Ticket konnte nicht geladen werden"
      );
    });
  }

  public deleteTicketButtonClicked(ticketId: string) {
    NumericalId.onValidId(ticketId, id => {
      const request = this.ticketManager.deleteTicket(id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.deleteTicketResponse = response),
        "Ticket konnte nicht gelöscht werden"
      );
    });
  }

  public deleteImageButtonClicked(rawTicketId: number, rawImageId: string) {
    NumericalId.onValidId(rawTicketId, ticketId => {
      NumericalId.onValidId(rawImageId, imageId => {
        const request = this.ticketManager.deleteImage(ticketId, imageId);

        FormRequestHandler.handle(
          request,
          response => (this.presenter.deleteImageResponse = response),
          "Bild konnte nicht entfernt werden"
        );
      });
    });
  }

  public uploadImageChanged(image: File, ticketId: string) {
    NumericalId.onValidId(ticketId, id => {
      const request = this.ticketManager.uploadImage(id, image);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.uploadImageResponse = response),
        "Bild konnte nicht hinzugefügt werden"
      );
    });
  }

  public disableDescriptionEditing(propertyId: string) {
    NumericalId.onValidId(propertyId, id => {
      const request = this.ticketManager.updateDescription(
        this.presenter.ticket
      );

      FormRequestHandler.handle(
        request,
        response => (this.presenter.updateDescriptionResponse = response)
      );
    });
  }

  public disableAdditionalDataEditing(ticketId: string) {
    NumericalId.onValidId(ticketId, id => {
      const request = this.ticketManager.updateAdditionalData(
        this.presenter.ticket
      );

      FormRequestHandler.handle(
        request,
        response => (this.presenter.updateAdditionalDataResponse = response)
      );
    });
  }

  public disableOrganizationEditing(propertyId: string) {
    NumericalId.onValidId(propertyId, id => {
      const request = this.ticketManager.updateOrganization(
        this.presenter.ticket
      );

      FormRequestHandler.handle(
        request,
        response => (this.presenter.updateOrganizationResponse = response)
      );
    });
  }

  public saveLocationButtonClicked(
    ticketId: string,
    locationId: string,
    planId: string,
    location: LocationViewModel
  ) {
    NumericalId.onValidId(ticketId, validTicketId => {
      NumericalId.onValidId(planId, validPlanId => {
        NumericalId.onValidId(
          locationId,
          validLocationId =>
            this.upsertTicketLocation(
              location,
              validTicketId,
              validPlanId,
              validLocationId
            ),
          () => this.upsertTicketLocation(location, validTicketId, validPlanId)
        );
      });
    });
  }

  public deleteLocationButtonClicked(ticketId: string, locationId: string) {
    NumericalId.onValidId(ticketId, validTicketId => {
      NumericalId.onValidId(locationId, validLocationId => {
        const request = this.ticketManager.deleteLocation(
          validTicketId,
          validLocationId
        );

        FormRequestHandler.handle(
          request,
          response => (this.presenter.deleteLocationResponse = response),
          "Verortung konnte nicht entfernt werden"
        );
      });
    });
  }

  public addChecklistReferenceButtonClicked(
    propertyId: string,
    assignmentId?: string
  ) {
    NumericalId.onValidId(propertyId, id => {
      const request = this.assignmentManager.loadAssignmentList(id);

      FormRequestHandler.handle(
        request,
        response => {
          this.assignmentChanged(assignmentId);
          this.presenter.loadAssignmentListResponse = response;
        },
        "Aufträge konnten nicht geladen werden"
      );
    });
  }

  public assignmentChanged(
    assignmentId?: string,
    sectionId?: string,
    entryId?: string
  ) {
    NumericalId.onValidId(assignmentId, id => {
      const request = this.checklistManager.loadChecklistSectionAndEntryNames(
        id
      );

      FormRequestHandler.handle(
        request,
        response => {
          this.presenter.loadChecklistSectionAndEntryNames = response;
          if (sectionId) {
            this.checklistSectionChanged(sectionId);
          }
          if (entryId) {
            this.checklistEntryChanged(entryId);
          }
        },
        "Checklisten-Daten konnten nicht geladen werden",
        { assignmentId }
      );
    });
  }

  public saveChecklistReferenceButtonClicked(
    ticketId: string,
    prevChecklistId: string,
    checklistId: string,
    entryId: string
  ) {
    NumericalId.onValidId(ticketId, validTicketId => {
      NumericalId.onValidId(checklistId, validChecklistId => {
        NumericalId.onValidId(entryId, validEntryId => {
          const numericalPrevChecklistId = NumericalId.fromString(
            prevChecklistId
          );

          if (numericalPrevChecklistId.valid) {
            this.upsertChecklistReference(
              validTicketId,
              validChecklistId,
              validEntryId,
              numericalPrevChecklistId
            );
          } else {
            this.upsertChecklistReference(
              validTicketId,
              validChecklistId,
              validEntryId
            );
          }
        });
      });
    });
  }

  public deleteChecklistReferenceButtonClicked(
    ticketId: string,
    checklistId: string
  ) {
    NumericalId.onValidId(ticketId, validTicketId => {
      NumericalId.onValidId(checklistId, validChecklistId => {
        const request = this.ticketManager.deleteChecklistReference(
          validTicketId,
          validChecklistId
        );

        FormRequestHandler.handle(
          request,
          response =>
            (this.presenter.deleteChecklistReferenceResponse = response),
          "Checklisten-Referenz konnte nicht entfernt werden"
        );
      });
    });
  }

  // Actions
  public prevTicketButtonClicked(ticketId: string) {
    NumericalId.onValidId(ticketId, id => {
      this.presenter.selectedTicket = id.id.toString();
    });
  }

  public nextTicketButtonClicked(ticketId: string) {
    NumericalId.onValidId(ticketId, id => {
      this.presenter.selectedTicket = id.id.toString();
    });
  }

  public imageSelected(imageIndex: string) {
    if (!this.presenter.editingImages) {
      this.presenter.selectedImage = imageIndex;
    }
  }

  public propertySelected(propertyId: string) {
    this.presenter.selectedProperty = propertyId;
  }

  public locationSelected() {
    this.presenter.locationDialogVisible = true;
  }

  public checklistReferenceSelected(
    propertyId: string,
    assignmentId: string,
    sectionId: string,
    entryId: string
  ) {
    this.addChecklistReferenceButtonClicked(propertyId);
    this.assignmentChanged(assignmentId, sectionId, entryId);
  }

  public imageDialogVisibilityChanged(visible: boolean) {
    this.presenter.imageDialogVisible = visible;
  }

  public locationDialogVisibilityChanged(visible: boolean) {
    this.presenter.locationDialogVisible = visible;
  }

  public checklistReferenceDialogVisibilityChanged(visible: boolean) {
    this.presenter.checklistReferenceDialogVisible = visible;
  }

  public enableDescriptionEditing() {
    this.presenter.editingDescription = true;
  }

  public enableAdditionalDataEditing() {
    this.presenter.editingAdditionalData = true;
  }

  public enableOrganizationEditing() {
    this.presenter.editingOrganization = true;
  }

  public enableImagesEditing() {
    this.presenter.editingImages = true;
  }

  public disableImagesEditing() {
    this.presenter.editingImages = false;
  }

  public ticketLocationPlanChanged(planId: number) {
    this.presenter.newPlanId = planId;
  }

  public ticketLocationChanged(location: Point) {
    this.presenter.newPlanLocation = location;
  }

  public enableReferencesEditing() {
    this.presenter.editingReferences = true;
  }

  public disableReferencesEditing() {
    this.presenter.editingReferences = false;
  }

  public addLocationButtonClicked() {
    this.presenter.locationDialogVisible = true;
  }

  public checklistSectionChanged(sectionId: string) {
    NumericalId.onValidId(sectionId, id => {
      this.presenter.selectedChecklistSection = id;
    });
  }

  public checklistEntryChanged(entryId: string) {
    NumericalId.onValidId(entryId, id => {
      this.presenter.selectedChecklistEntry = id;
    });
  }

  // Input
  public titleChanged(title: string) {
    this.presenter.descriptionForm.setFieldValue("title", title);
  }
  public descriptionChanged(description: string) {
    this.presenter.descriptionForm.setFieldValue("description", description);
  }
  public actionChanged(action: string) {
    this.presenter.descriptionForm.setFieldValue("action", action);
  }

  public additionalDataChanged(key: string, value: any) {
    this.presenter.setAdditionalData(key, value);
  }

  public priorityChanged(priority: string) {
    this.presenter.organizationForm.setFieldValue("priority", priority);
  }
  public dueDateChanged(dueDate: string) {
    this.presenter.organizationForm.setFieldValue("dueDate", dueDate);
  }

  // Helper
  private upsertTicketLocation(
    location: LocationViewModel,
    ticketId: NumericalId,
    planId: NumericalId,
    locationId?: NumericalId
  ) {
    const locationData = {
      id: locationId,
      ticketId,
      planId,
      x: parseFloat(location.x),
      y: parseFloat(location.y)
    };
    const request = this.ticketManager.upsertLocation(ticketId, locationData);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.updateLocationResponse = response),
      "Verortung konnte nicht aktualisiert werden"
    );
  }

  private upsertChecklistReference(
    ticketId: NumericalId,
    checklistId: NumericalId,
    entryId: NumericalId,
    prevChecklistId?: NumericalId
  ) {
    const request = this.ticketManager.upsertChecklistReference(
      ticketId,
      checklistId,
      entryId,
      prevChecklistId
    );

    FormRequestHandler.handle(
      request,
      response => (this.presenter.updateChecklistReferenceResponse = response),
      "Checklist-Referenz konnte nicht aktualisiert werden"
    );
  }

  // Helper
  //
  private getPropertyId() {
    const property = UserSettings.getSelectedProperty();
    return property === "" ? undefined : property;
  }
}

export interface ITicketDetailPresenter {
  descriptionForm: TicketDescriptionForm;
  organizationForm: TicketOrganizationForm;
  editingDescription: boolean;
  editingAdditionalData: boolean;
  editingOrganization: boolean;
  editingImages: boolean;
  editingReferences: boolean;

  ticket: EditTicketData;

  selectedTicket: string;
  priorities: Priorities;
  selectedProperty: string;
  selectedImage: string;
  newPlanId: number;
  newPlanLocation: Point;
  selectedChecklistSection: NumericalId;
  selectedChecklistEntry: NumericalId;

  imageDialogVisible: boolean;
  locationDialogVisible: boolean;
  checklistReferenceDialogVisible: boolean;

  loadTicketResponse: FormResponse<TicketData>;
  deleteTicketResponse: FormResponse<number>;
  updateDescriptionResponse: FormResponse<number>;
  updateAdditionalDataResponse: FormResponse<number>;
  updateOrganizationResponse: FormResponse<number>;
  uploadImageResponse: FormResponse<TicketImage>;
  deleteImageResponse: FormResponse<number>;
  updateLocationResponse: FormResponse<UpdateTicketLocationData>;
  deleteLocationResponse: FormResponse<number>;
  loadAssignmentListResponse: FormResponse<AssignmentListRow[]>;
  loadChecklistSectionAndEntryNames: FormResponse<ChecklistName>;
  updateChecklistReferenceResponse: FormResponse<UpdateChecklistReferenceData>;
  deleteChecklistReferenceResponse: FormResponse<number>;

  setAdditionalData(key: string, value: any): void;
}
