import { Form, FormDefinition } from "./Form";

export class DynamicForm extends Form {
  public constructor(
    protected definition: FormDefinition,
    fieldContext: any,
    validatedEvent?: (context: any, valid: boolean) => void,
    formContext?: any
  ) {
    super(fieldContext, validatedEvent, formContext);
  }
}
