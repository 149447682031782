
import { Authorizator } from "@/common/interactors/Authorizator";
import Vue from "vue";
import Component from "vue-class-component";

@Component<CreateClientButton>({
  components: {}
})
export default class CreateClientButton extends Vue {
  protected get canCreateClients() {
    return Authorizator.canCreateClients();
  }

  protected goToNewClientPage() {
    this.$router.push({ name: "create-client" });
  }
}
