import {
  TicketViewerGateway,
  TicketData,
  TicketImage,
  TicketLocationData,
  TicketEntryData
} from "@/fieldwork/interactors/TicketViewer";
import {
  GraphQLConnection,
  SortOrder,
  OrderByClause
} from "./connection/GraphQLConnection";
import { NumericalId } from "@/datastructures/NumericalId";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { Ticket } from "@/entities/Ticket";
import { Property } from "@/entities/Property";
import { Address } from "@/entities/Address";
import { Image } from "@/entities/Image";
import { Page } from "@/datastructures/Page";
import { PersonGraphQLProvider } from "@/property/shared/repositories/personRepository/PersonGraphQLProvider";
import { PlantGraphQLProvider } from "@/property/shared/repositories/plantRepository/PlantGraphQLProvider";

export class TicketViewerGraphQLGateway implements TicketViewerGateway {
  public constructor(private connection: GraphQLConnection) {}

  public async ticketExists(ticketId: NumericalId): Promise<boolean> {
    const result = await this.connection.query("ticket", { id: ticketId.id }, [
      "id"
    ]);
    return !!result.data.id;
  }

  public async loadTicket(
    ticketId: NumericalId,
    clientId: string,
    propertyId?: string,
    search?: string,
    priority?: string,
    state?: string,
    entryId?: string,
    plantId?: number,
    entryTemplate?: string
  ): Promise<TicketData> {
    const loadAnchestorTickets = !!clientId;

    let anchestorParams = `client_id: ${clientId}`;
    anchestorParams += propertyId ? `, property_id: ${propertyId}` : "";
    anchestorParams += search ? `, fulltext_search: "${search}"` : "";
    anchestorParams += priority ? `, priority: "${priority}"` : "";
    anchestorParams += state ? `, state: "${state}"` : "";
    anchestorParams += entryId ? `, entry_id: ${entryId}` : "";
    anchestorParams += plantId ? `, plant_id: ${plantId}` : "";
    anchestorParams += entryTemplate
      ? `, checklist_entry_template_id: "${entryTemplate}"`
      : "";

    const fields = [
      "id",
      "title",
      "description",
      "action",
      "priority",
      "maturity",
      "type",
      "data",
      "state",
      "created_at",
      {
        name: "property",
        fields: [
          "id",
          "name",
          { name: "address", fields: ["street", "postcode", "city"] }
        ]
      },
      {
        name: "plant",
        fields: ["id", "name", { name: "parts", fields: ["id", "name"] }]
      },
      {
        name: "creator",
        fields: [
          "id",
          {
            name: "person",
            fields: [
              "id",
              "firstname",
              "lastname",
              {
                name: "addresses",
                fields: ["street", "postcode", "city"]
              }
            ]
          }
        ]
      },
      {
        name: "responsiblePerson",
        fields: [
          "id",
          "firstname",
          "lastname",
          {
            name: "addresses",
            fields: ["street", "postcode", "city"]
          }
        ]
      },
      {
        name: "images",
        fields: [
          "id",
          "path",
          "path_big",
          "path_medium",
          "path_small",
          "path_thumbnail"
        ]
      },
      {
        name: "location",
        fields: [
          "id",
          "point_pixel_x",
          "point_pixel_y",
          {
            name: "plan",
            fields: ["id", "name"]
          }
        ]
      },
      {
        name: "checklists",
        fields: ["id", { name: "assignment", fields: ["id", "number"] }]
      },
      {
        name: "entry",
        fields: [
          "id",
          "name",
          {
            name: "section",
            fields: [
              "id",
              "name",
              {
                name: "checklist",
                fields: ["id", { name: "assignment", fields: ["id", "number"] }]
              }
            ]
          }
        ]
      }
    ];

    if (loadAnchestorTickets) {
      fields.push({
        name: "anchestorTickets(" + anchestorParams + ")",
        fields: [
          { name: "prev", fields: ["id"] },
          { name: "next", fields: ["id"] }
        ]
      });
    }

    const response = await this.connection.queryResponse(
      "ticket",
      { id: ticketId.id },
      fields
    );

    const data = response.data;

    let images: TicketImage[] = [];
    let location: TicketLocationData = {
      id: new NumericalId(),
      planId: new NumericalId(),
      planName: "",
      x: 0.0,
      y: 0.0
    };
    let entry: TicketEntryData = {
      assignmentId: new NumericalId(),
      checklistId: new NumericalId(),
      sectionId: new NumericalId(),
      entryId: new NumericalId(),
      assignmentNumber: "",
      sectionName: "",
      entryName: ""
    };

    if (data.images) {
      images = data.images.map((image: any) => ({
        id: image.id,
        path: image.path,
        pathBig: image.path_big,
        pathMedium: image.path_medium,
        pathSmall: image.path_small,
        pathThumbnail: image.path_thumbnail
      }));
    }

    if (data.location) {
      location = {
        id: new NumericalId(data.location.id),
        planId: new NumericalId(data.location.plan.id),
        planName: data.location.plan.name,
        x: data.location.point_pixel_x,
        y: data.location.point_pixel_y
      };
    }

    if (data.entry?.section?.checklist?.assignment) {
      const entryData = data.entry;
      const sectionData = entryData.section;
      const checklistData = sectionData.checklist;
      const assignmentData = checklistData.assignment;

      entry = {
        assignmentId: new NumericalId(assignmentData.id),
        checklistId: new NumericalId(checklistData.id),
        sectionId: new NumericalId(sectionData.id),
        entryId: new NumericalId(entryData.id),
        assignmentNumber: assignmentData.number,
        sectionName: sectionData.name,
        entryName: entryData.name
      };
    } else if (data.checklists && data.checklists.length > 0) {
      const checklistData = data.checklists[0];

      if (checklistData.assignment) {
        const assignmentData = checklistData.assignment;

        entry = {
          assignmentId: new NumericalId(assignmentData.id),
          checklistId: new NumericalId(checklistData.id),
          sectionId: new NumericalId(),
          entryId: new NumericalId(),
          assignmentNumber: assignmentData.number,
          sectionName: "",
          entryName: ""
        };
      }
    }

    return {
      id: data.id,
      title: data.title,
      description: data.description,
      action: data.action,
      propertyId: data.property.id,
      propertyName: data.property.name,
      propertyStreet: data.property.address.street,
      propertyZip: data.property.address.postcode,
      propertyCity: data.property.address.city,
      priority: data.priority,
      dueDate: data.maturity,
      images,
      location,
      entry,
      state: data.state,
      createdAt: new Date(data.created_at),
      type: data.type,
      data: data.data,
      prevTicketId: data.anchestorTickets?.prev?.id ?? undefined,
      nextTicketId: data.anchestorTickets?.next?.id ?? undefined,
      creator: PersonGraphQLProvider.personConverter.convert(
        data.creator?.person
      ),
      responsiblePerson: PersonGraphQLProvider.personConverter.convert(
        data.responsiblePerson
      ),
      plant: PlantGraphQLProvider.convertToPlantEntity(data.plant)
    };
  }

  public async loadTicketCatalog(
    page: Page,
    clientId: NumericalId,
    search: string = "",
    priority?: string,
    state?: string,
    plantId?: number,
    entryTemplate?: string,
    propertyId?: NumericalId,
    entryId?: string,
    sort?: OrderByClause
  ): Promise<PaginatedList<Ticket>> {
    return this.connection
      .queryPaginated(
        "searchTickets",
        page.itemsPerPage,
        page.page,
        {
          client_id: clientId.id,
          priority,
          state,
          property_id: propertyId ? propertyId.id : undefined,
          checklist_entry_id: entryId,
          plant_id: plantId,
          checklist_entry_template_id: entryTemplate
        },
        [
          "id",
          "title",
          "description",
          "action",
          "priority",
          "maturity",
          {
            name: "property",
            fields: ["id", "name", { name: "address", fields: ["street"] }]
          },
          { name: "images", fields: ["id", "path"] }
        ],
        search,
        sort || { field: "id", order: SortOrder.DESC }
      )
      .then(response => ({
        items: response.data.map((data: any) => {
          const ticket = new Ticket(data.id);
          ticket.title = data.title;
          ticket.description = data.description;
          ticket.action = data.action;
          ticket.priority = data.priority;
          ticket.dueDate = new Date(data.maturity);
          ticket.state = 0;

          const property = new Property(data.property.id);
          property.name = data.property.name;
          ticket.property = property;

          if (data.images && data.images.length > 0) {
            const imageData = data.images[0];
            const image = new Image(imageData.id, imageData.path);
            ticket.images.push(image);
          }

          const address = new Address(data.property.address.street, "", "");
          property.address = address;

          return ticket;
        }),
        totalCount: response.count
      }));
  }

  public generateTicketReport(
    priorities: string[],
    propertyId?: NumericalId
  ): Promise<string> {
    return this.connection
      .query(
        "ticketReport",
        {
          priority: priorities,
          property_id: [0, propertyId ? propertyId.id : undefined]
        },
        []
      )
      .then(response => response.data);
  }
}
