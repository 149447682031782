import { InspectionSelectionItemLoader } from "@/inspection/shared/dtos/InspectionDomainSelectionItemLoader";
import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";

export interface ReportSettingsFormData {
  hidePages: boolean;
  hideChecklist: boolean;
  onlyPriority: string;
}

export class ReportSettingsForm extends Form<ReportSettingsFormData> {
  protected definition = {
    hidePages: {
      label: "Seitenanzahl ausblenden",
      required: false,
      type: FormFieldType.Checkbox
    },
    hideChecklist: {
      label: "Checkliste ausblenden",
      required: false,
      type: FormFieldType.Checkbox
    },
    priorityFilter: {
      label: "Prioritätenfilter",
      required: false,
      type: FormFieldType.Selection,
      itemDefinition: {
        items: () => {
          return [
            {
              text: "Alle Prioritäten",
              value: ""
            },
            ...InspectionSelectionItemLoader.loadPriorities()
          ];
        }
      }
    },
    tickets_title: {
      label: "Kapitelüberschrift Fotodokumentation",
      required: false,
      type: FormFieldType.Text
    },
    ticket_headlines: {
      label: "Kapitelstruktur Fotodokumentation",
      required: false,
      type: FormFieldType.Selection,
      itemDefinition: {
        items: () => {
          return [
            {
              text: "Nur erste Checklisten-Hierarchieebene (B1300)",
              value: ""
            },
            {
              text: "Gesamte Checklisten-Hierarchie",
              value: "sections_entries"
            }
          ];
        }
      }
    },
    skip_actions: {
      label: "Maßnahmenkatalog ausblenden",
      required: false,
      type: FormFieldType.Checkbox
    }
  };
}
