import { AuthorizatorStorageGateway } from "@/gateways/storage/AuthorizatorStorageGateway";

export type AppModules =
  | "clients"
  | "settings"
  | "employees"
  | "people"
  | "properties"
  | "tickets"
  | "ticketImages"
  | "ticketAccess"
  | "ticketComments"
  | "assignments"
  | "documents"
  | "plans"
  | "checklists";

export type AppActions =
  | "list"
  | "view"
  | "viewOwn"
  | "select"
  | "edit"
  | "editOwn"
  | "create"
  | "delete"
  | "deleteOwn"
  | "deletePerm"
  | "deletePermOwn";

export class Authorizator {
  public static canListClients(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("clients", "list");
  }
  public static canCreateClients(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "clients",
      "create"
    );
  }
  public static canDeleteClients(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "clients",
      "delete"
    );
  }
  public static canEditClients(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("clients", "edit");
  }
  public static canViewClients(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("clients", "view");
  }
  public static canViewOwnClient(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "clients",
      "viewOwn"
    );
  }
  public static canEditOwnClient(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "clients",
      "editOwn"
    );
  }

  public static canListSettings(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("settings", "list");
  }
  public static canEditSettings(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("settings", "edit");
  }
  public static canViewSettings(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("settings", "view");
  }
  public static canViewOwnSettings(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "settings",
      "viewOwn"
    );
  }
  public static canEditOwnSettings(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "settings",
      "editOwn"
    );
  }

  public static canCreateEmployees(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "employees",
      "create"
    );
  }
  public static canListEmployees(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "employees",
      "list"
    );
  }
  public static canDeleteEmployees(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "employees",
      "delete"
    );
  }

  public static canCreatePeople(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("people", "create");
  }
  public static canDeletePeople(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("people", "delete");
  }
  public static canListPeople(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("people", "list");
  }
  public static canViewPeople(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("people", "view");
  }
  public static canEditPeople(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("people", "edit");
  }

  public static canListProperties(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "properties",
      "list"
    );
  }
  public static canCreateProperties(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "properties",
      "create"
    );
  }
  public static canDeleteProperties(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "properties",
      "delete"
    );
  }
  public static canEditProperties(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "properties",
      "edit"
    );
  }
  public static canViewProperties(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "properties",
      "view"
    );
  }
  public static canSelectProperties(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "properties",
      "select"
    );
  }

  public static canCreateTickets(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "tickets",
      "create"
    );
  }
  public static canListTickets(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("tickets", "list");
  }
  public static canEditTickets(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("tickets", "edit");
  }
  public static canViewTickets(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("tickets", "view");
  }
  public static canDeleteTickets(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "tickets",
      "delete"
    );
  }

  public static canCreateTicketImages(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "ticketImages",
      "create"
    );
  }
  public static canViewTicketImages(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "ticketImages",
      "view"
    );
  }
  public static canDeleteTicketImages(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "ticketImages",
      "delete"
    );
  }

  public static canCreateTicketAccesses(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "ticketAccess",
      "create"
    );
  }
  public static canEditTicketAccesses(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "ticketAccess",
      "edit"
    );
  }
  public static canViewTicketAccesses(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "ticketAccess",
      "view"
    );
  }
  public static canDeleteTicketAccesses(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "ticketAccess",
      "delete"
    );
  }

  public static canCreateTicketComments(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "ticketComments",
      "create"
    );
  }
  public static canEditTicketComments(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "ticketComments",
      "edit"
    );
  }
  public static canEditOwnTicketComments(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "ticketComments",
      "editOwn"
    );
  }
  public static canViewTicketComments(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "ticketComments",
      "view"
    );
  }
  public static canDeleteTicketComments(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "ticketComments",
      "delete"
    );
  }
  public static canDeleteOwnTicketComments(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "ticketComments",
      "deleteOwn"
    );
  }
  public static canDeleteTicketCommentsPermanently(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "ticketComments",
      "deletePerm"
    );
  }
  public static canDeleteOwnTicketCommentsPermanently(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "ticketComments",
      "deletePermOwn"
    );
  }

  public static canCreateAssignments(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "assignments",
      "create"
    );
  }
  public static canListAssignments(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "assignments",
      "list"
    );
  }
  public static canEditAssignments(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "assignments",
      "edit"
    );
  }
  public static canViewAssignments(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "assignments",
      "view"
    );
  }
  public static canDeleteAssignments(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "assignments",
      "delete"
    );
  }

  public static canCreateDocuments(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "documents",
      "create"
    );
  }
  public static canListDocuments(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "documents",
      "list"
    );
  }
  public static canEditDocuments(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "documents",
      "edit"
    );
  }
  public static canViewDocuments(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "documents",
      "view"
    );
  }
  public static canDeleteDocuments(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "documents",
      "delete"
    );
  }

  public static canCreatePlans(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("plans", "create");
  }
  public static canEditPlans(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("plans", "edit");
  }
  public static canViewPlans(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("plans", "view");
  }
  public static canDeletePlans(): boolean {
    return new AuthorizatorStorageGateway().checkPermission("plans", "delete");
  }

  public static canEditChecklists(): boolean {
    return new AuthorizatorStorageGateway().checkPermission(
      "checklists",
      "edit"
    );
  }
}

export interface AuthorizatorGateway {
  checkPermission(type: AppModules, operation: AppActions): boolean;
}
