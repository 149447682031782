
import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import StyledText from "@/components/basic/StyledText.vue";

import TreeView from "@/components/collection/Treeview.vue";

import AccessResource from "./AccessResource.vue";

import { AccessResourceTreeVm } from "./vms/AccessResourceTreeVm";
import { AccessResourceVm } from "./vms/AccessResourceVm";
import { AccessModel } from "../../models/AccessModel";

@Component<AccessResourceTree>({
  components: {
    Row,
    Column,
    TreeView,
    StyledText,
    AccessResource
  },
  props: {
    vm: {
      type: Object,
      default() {
        return new AccessResourceTreeVm();
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
export default class AccessResourceTree extends Vue {
  private vm?: AccessResourceTreeVm;

  private accessShared(access: AccessModel) {
    this.$emit("share", access);
  }

  private accessUnshared(access: AccessModel) {
    this.$emit("unshare", access);
  }
}
