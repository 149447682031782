import { Form, FormFieldType } from "../Form";
import { emailRegex, passwordRegex } from "@/data/regex";

export class LoginForm extends Form {
  protected definition = {
    email: {
      type: FormFieldType.Text,
      required: true,
      regex: emailRegex,
      error: "E-Mail-Adresse noch unvollständig"
    },
    password: {
      type: FormFieldType.Text,
      required: true,
      regex: passwordRegex,
      error: "Passwort entspricht noch nicht den Richtlinien"
    }
  };
}
