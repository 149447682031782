import { Duration } from "./Duration";

export class RecurringDate {
  public startDate?: Date = new Date();
  public recurringDuration?: Duration = {};

  public constructor(startDate?: Date, recurringDuration?: Duration) {
    this.startDate = startDate;
    this.recurringDuration = recurringDuration;
  }

  public get isComplete() {
    return !!this.startDate && !!this.recurringDuration;
  }
}
