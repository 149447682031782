export interface PaginatedList<T> {
  items: T[];
  totalCount: number;
}

export function createEmptyPage<T>(): PaginatedList<T> {
  return {
    items: [],
    totalCount: 0
  };
}
