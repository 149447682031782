import { AssignmentData } from "@/inspection/shared/entities/AssignmentEntity";
import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";

export class AssignmentForm extends Form<AssignmentData> {
  protected definition = {
    number: {
      label: "Auftragsnummer",
      required: true,
      type: FormFieldType.Text
    },
    date: {
      label: "Datum",
      required: true,
      type: FormFieldType.Date
    },
    description: {
      label: "Beschreibung",
      required: false,
      type: FormFieldType.Text
    }
  };
}
