
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import ReportDataForm from "./ReportDataForm.vue";

import { Report } from "../../shared/models/Report";
import { StepperStep } from "@/shared/form/FormTypes";
import { DynamicForm } from "@/shared/form/DynamicForm";
import { ReportDataFormGenerator } from "../forms/ReportDataFormGenerator";
import { ReportTemplateDataStep } from "@/inspection/shared/settings/ReportTemplateSettings";
import { Dictionary } from "@/shared/datastructures/Dictionary";

export interface ReportDataAssistentStep {
  step: number;
  hasPrevStep: boolean;
  hasNextStep: boolean;
}

interface ReportStepperStep extends StepperStep {
  structure: ReportTemplateDataStep;
}

@Component<ReportDataAssistent>({
  components: { ReportDataForm }
})
export default class ReportDataAssistent extends Vue {
  @Prop({ required: true, default: () => new Report() })
  protected readonly report!: Report;

  protected currentStep = 1;
  protected form: DynamicForm = new DynamicForm({});

  @Watch("report")
  protected onReportChanged() {
    this.initForm();
  }
  @Watch("steps")
  protected onStepsChanged() {
    this.initForm();
  }

  protected get steps(): ReportStepperStep[] {
    return (
      this.report.dataStructure?.steps?.map(s => ({
        label: s.label ?? "",
        key: s.key ?? "",
        editable: true,
        structure: s
      })) ?? []
    );
  }

  protected get nextButtonText() {
    return this.atEnd ? "Speichern" : "Weiter";
  }

  protected get nextButtonIcon() {
    return this.atEnd ? "done" : "chevron_right";
  }

  protected get atStart() {
    return this.currentStep <= 1;
  }

  protected get atEnd() {
    return this.currentStep >= this.stepCount;
  }

  protected get stepCount() {
    return this.steps.length;
  }

  protected jumpToStep(step: number) {
    this.currentStep = Math.max(1, Math.min(step, this.stepCount));
    this.emitChange();
  }

  public nextStep() {
    if (!this.atEnd) {
      this.jumpToStep(this.currentStep + 1);
    } else {
      this.report.saveData();
    }
  }

  public prevStep() {
    this.jumpToStep(this.currentStep - 1);
  }

  protected mounted() {
    this.initForm();
    this.emitChange();
  }

  protected initForm() {
    this.form = new DynamicForm({});

    this.steps.forEach(async step => {
      const subForm = new ReportDataFormGenerator(
        step.structure.fields ?? []
      ).generateForm();

      await subForm.init();
      subForm.setData(this.report.data?.getSection(step.key));

      this.form.addSubForm(subForm, step.key, true);
    });

    this.form.init();
  }

  protected emitChange() {
    this.$emit("change", {
      step: this.currentStep,
      hasPrevStep: !this.atStart,
      hasNextStep: !this.atEnd
    });
  }
}
