import {
  Request,
  SelectionItem,
  RequestMap,
  Selection
} from "@/forms/ViewModelFormTypes";

export class ChecklistPreparationViewModel {
  // Settings
  public states: SelectionItem[] = [];

  // Data
  public checklistId: string = "";
  public checklistSections: ChecklistSectionViewModel[] = [];
  public goToInspection = false;

  // Requests
  public loadChecklistRequest: Request = { loading: false, error: "" };
  public setStateRequests: RequestMap = {};
  public setRemainindStatesRequest: Request = { loading: false, error: "" };
  public finishPreparationRequest: Request = { loading: false, error: "" };

  // States (Disabled, Visible, etc.)
  public continueButtonDisabled = false;

  // Input
  public remainingStates: Selection = {
    label: "Status für restliche Kategorien",
    selected: "",
    loading: false,
    error: "",
    items: []
  };
}

interface ChecklistSectionViewModel {
  id: string;
  name: string;
  state: string;
  color: string;
}
