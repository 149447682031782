
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import App from "@/App.vue";

import { Access } from "@/common/shared/models/Access";
import { CommonRepoContainer } from "@/common/shared/repositories/CommonRepoContainer";
import { DeleteAccessUseCase } from "@/common/access/useCases/DeleteAccessUseCase";
import { Authorizator } from "@/common/interactors/Authorizator";
import { SelectionItem } from "@/shared/form/FormTypes";

@Component<AccessEntry>({
  components: {}
})
export default class AccessEntry extends Vue {
  @Prop({ type: Object, default: () => new Access() })
  protected readonly access!: Access;

  protected showCopyHint = false;

  protected accessDeleter = new DeleteAccessUseCase(
    CommonRepoContainer.accessRepo
  );

  protected get isMobile() {
    return App.instance?.isMobile;
  }

  protected get descriptionIconColor() {
    return this.access.hasDescription ? "" : "grey";
  }

  protected get actions(): SelectionItem[] {
    return [
      { value: "copy_link", text: "Link kopieren" },
      {
        value: "edit",
        text: "Freigabe bearbeiten",
        disabled: !this.canEditAccesses
      },
      {
        value: "delete",
        text: "Freigabe löschen",
        disabled: !this.canDeleteAccesses
      }
    ];
  }

  protected get canEditAccesses() {
    return Authorizator.canEditTicketAccesses();
  }

  protected get canDeleteAccesses() {
    return Authorizator.canDeleteTicketAccesses();
  }

  protected actionSelected(action: string) {
    if (action === "copy_link") {
      this.copyLink();
    } else if (action === "edit") {
      this.editAccess();
    } else if (action === "delete") {
      this.deleteAccess();
    }
  }

  protected async editAccess() {
    this.$emit("edit", this.access);
  }

  protected async deleteAccess() {
    const deletedAccess = await this.accessDeleter.do(this.access);

    if (deletedAccess) {
      this.$emit("deleted", deletedAccess);
    }
  }

  protected async copyLink() {
    if (this.access.link) {
      navigator.clipboard.writeText(this.access.link);
      this.showCopyHint = true;
    }
  }
}
