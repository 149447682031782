import {
  PersonGateway,
  FullPersonData,
  PersonPhoneNumber,
  PersonAddress
} from "@/office/interactors/PersonManager";
import { Person } from "@/entities/Person";
import {
  GraphQLConnection,
  GraphQLResponse
} from "./connection/GraphQLConnection";
import { Page } from "@/datastructures/Page";
import { Address } from "@/entities/Address";
import { NumericalId } from "@/datastructures/NumericalId";

export class PersonGraphQLGateway implements PersonGateway {
  public constructor(private connection: GraphQLConnection) {}

  public async createPerson(data: FullPersonData): Promise<Person> {
    const personRequest = await this.connection.mutation(
      "createPerson",
      this.convertFullPerson(data),
      ["id"]
    );
    await this.createPhoneNumbers(data.phoneNumbers, personRequest.data.id);
    await this.createAddresses(data.addresses, personRequest.data.id);
    return new Person(personRequest.data.id);
  }

  public searchPersons(
    searchText: string,
    clientId: NumericalId,
    page: Page
  ): Promise<Person[]> {
    return this.connection
      .queryPaginated(
        "people",
        page.itemsPerPage,
        page.page,
        { client_id: clientId.id },
        [
          "id",
          "firstname",
          "lastname",
          "email",
          { name: "addresses", fields: ["street", "postcode", "city"] }
        ],
        searchText
      )
      .then(response =>
        response.data.map((data: any) => {
          const person = new Person(data.id);

          person.firstname = data.firstname;
          person.lastname = data.lastname;
          person.email = data.email;
          person.addresses = data.addresses.map(
            (address: any) =>
              new Address(address.street, address.postcode, address.city)
          );

          return person;
        })
      );
  }

  public getUserPersonData(
    id: NumericalId,
    clientId: NumericalId
  ): Promise<Person> {
    return this.connection
      .query("person", { id: id.id }, [
        "id",
        "firstname",
        "lastname",
        "email",
        { name: "addresses", fields: ["street", "postcode", "city"] }
      ])
      .then(response => {
        const data = response.data;
        const person = new Person(data.id);

        person.firstname = data.firstname;
        person.lastname = data.lastname;
        person.email = data.email;
        person.addresses = data.addresses.map(
          (address: any) =>
            new Address(address.street, address.postcode, address.city)
        );

        return person;
      });
  }

  private async createPhoneNumbers(
    numbers: PersonPhoneNumber[],
    personId: number
  ) {
    let chain = Promise.resolve<GraphQLResponse>({ data: {}, errors: {} });

    for (const num of numbers) {
      chain = chain.then(() =>
        this.connection.mutation(
          "createPhoneNumber",
          this.convertFullNumber(num, personId),
          [{ name: "phoneable", fragment: "Person", fields: ["id"] }]
        )
      );
    }

    return chain;
  }

  private async createAddresses(addresses: PersonAddress[], personId: number) {
    let chain = Promise.resolve<GraphQLResponse>({ data: {}, errors: {} });

    for (const address of addresses) {
      chain = chain.then(() =>
        this.connection.mutation(
          "createAddress",
          this.convertFullAddress(address, personId),
          [{ name: "addressable", fragment: "Person", fields: ["id"] }]
        )
      );
    }

    return chain;
  }

  private convertFullPerson(data: FullPersonData) {
    return {
      input: {
        salutation: data.salutation,
        pre_degree: data.preTitle,
        firstname: data.firstname,
        lastname: data.lastname,
        post_degree: data.postTitle,
        email: data.email,
        website: data.website,
        client: {
          connect: data.clientId.id
        }
      }
    };
  }

  private convertFullAddress(data: PersonAddress, personId: number) {
    return {
      input: {
        context: data.context,
        street: data.street,
        postcode: data.zip,
        city: data.city,
        country: data.country,
        addressable: {
          connect: {
            id: personId,
            type: "App\\Person"
          }
        }
      }
    };
  }

  private convertFullNumber(data: PersonPhoneNumber, personId: number) {
    return {
      input: {
        context: data.context,
        area_code: data.areaCode,
        number: data.number,
        phoneable: {
          connect: {
            id: personId,
            type: "App\\Person"
          }
        }
      }
    };
  }
}
