import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { GraphQLConnection } from "@/gateways/graphql/connection/GraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { Paginated } from "@/shared/datastructures/Paginated";
import { Assignment, AssignmentState } from "../../dtos/Assignment";
import { AssignmentEntity } from "../../entities/AssignmentEntity";
import {
  convertGraphQlToChecklistEntity,
  convertGraphQlToChecklistEntryEntities
} from "../../entities/ChecklistEntity";
import { ChecklistGraphQLProvider } from "../checklistRepository/ChecklistGraphQLProvider";
import { AssignmentProvider } from "./AssignmentProvider";

export class AssignmentGraphQLProvider implements AssignmentProvider {
  public constructor(
    private connection: GraphQLConnection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    )
  ) {}

  public async loadAssignmentById(
    id: number,
    includeChecklist: boolean
  ): Promise<AssignmentEntity> {
    const result = await this.connection.query("assignment", { id }, [
      "id",
      "number",
      "date",
      "statement",
      {
        name: "checklist",
        fields: [
          "id",
          "protection_goal_niveau",
          "preparation_done",
          "type",
          {
            name: "entries",
            fields: [
              "id",
              "name",
              "preparation_state",
              "state",
              "has_tickets",
              "has_entries",
              "checklist_entry_id",
              { name: "plant", fields: ["id"] },
              {
                name: "tickets",
                fields: [
                  "id",
                  "title",
                  "description",
                  { name: "images", fields: ["id", "path"] }
                ]
              },
              {
                name: "entries",
                fields: [
                  "id",
                  "name",
                  "description",
                  "preparation_state",
                  "state",
                  "checklist_entry_id",
                  "has_tickets",
                  "has_entries",
                  { name: "plant", fields: ["id"] }
                ]
              }
            ]
          },
          { name: "property", fields: ["id"] },
          { name: "looseTickets", fields: ["id", "title"] }
        ]
      }
    ]);

    return this.convertToAssignment(result.data);
  }

  public async loadBaseAssignmentByType(
    page: number,
    type: string,
    propertyIds: number[],
    search: string
  ): Promise<Paginated<Assignment>> {
    const result = await this.connection.queryPaginated(
      "baseAssignments",
      20,
      page,
      { page, type, property_ids: propertyIds },
      [
        "id",
        "number",
        "date",
        "statement",
        { name: "first_entry", fields: ["id"] },
        "state",
        { name: "checklist", fields: ["id", "type"] },
        {
          name: "property",
          fields: [
            "id",
            "name",
            { name: "address", fields: ["street", "postcode", "city"] }
          ]
        },
        {
          name: "plant",
          fields: ["id"]
        }
      ],
      search
    );

    return Paginated.fromResponse(result, this.convertToAssignments);
  }

  public async loadPropertyAssignments(
    page: number,
    propertyIds: number[],
    search: string
  ): Promise<Paginated<Assignment>> {
    const result = await this.connection.queryPaginated(
      "propertyAssignments",
      20,
      page,
      { page, property_ids: propertyIds },
      [
        "id",
        "number",
        "date",
        "statement",
        { name: "first_entry", fields: ["id"] },
        "state",
        { name: "checklist", fields: ["id", "type"] },
        {
          name: "property",
          fields: [
            "id",
            "name",
            { name: "address", fields: ["street", "postcode", "city"] }
          ]
        },
        {
          name: "plant",
          fields: ["id"]
        }
      ],
      search
    );

    return Paginated.fromResponse(result, this.convertToAssignments);
  }

  public async create(assignment: AssignmentEntity): Promise<number> {
    const result = await this.connection.mutation(
      "newCreateAssignment",
      {
        input: {
          number: assignment.number,
          date: assignment.date,
          description: assignment.description,
          plant_id: assignment.plant?.id,
          checklist_plant_ids: assignment.checklistPlants?.map(p => p.id),
          follow_up_inspection_of: assignment.prevAssignment?.id
        }
      },
      [],
      30000
    );

    return result.data;
  }

  public async importTickets(
    assignmentId: number,
    file: File
  ): Promise<number[]> {
    const result = await this.connection.mutation(
      "importTickets",
      {
        input: {
          assignment_id: assignmentId,
          file: null
        }
      },
      [],
      60000,
      { file, path: "input.file" }
    );

    return result.data;
  }

  private convertToAssignments(assignments: any[]): Assignment[] {
    return assignments.map(a => ({
      id: a.id || 0,
      number: a.number || "",
      date: a.date || new Date(),
      description: a.statement || "",
      firstEntryId: a.first_entry?.id || 0,
      state: a.state || AssignmentState.Preparation,
      checklist: {
        id: a.checklist?.id || 0,
        type: a.checklist?.type || ""
      },
      property: {
        id: a.property?.id || 0,
        name: a.property?.name || "",
        street: a.property?.address?.street || "",
        zip: a.property?.address?.postcode || "",
        city: a.property?.address?.city || ""
      },
      plant: {
        id: a.plant?.id || 0
      }
    }));
  }

  private convertToAssignment(a: any): AssignmentEntity {
    return {
      id: a.id,
      number: a.number,
      date: a.date,
      description: a.description,
      checklist: a.checklist ? convertGraphQlToChecklistEntity(a.checklist) : {}
    };
  }
}
