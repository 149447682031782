import { PlantEntity } from "@/property/shared/entities/PlantEntity";
import { Checklist } from "./Checklist";
import { Property } from "./Property";

export enum AssignmentState {
  Preparation = "preparation",
  Inspection = "inspection",
  Finished = "finished"
}

export interface Assignment {
  id: number;
  number: string;
  date: Date;
  description: string;
  firstEntryId: number;
  state: AssignmentState;
  property: Property;
  checklist: Checklist;
  plant: PlantEntity;
}
