import { PaginatedGraphQLResponse } from "@/gateways/graphql/connection/GraphQLConnection";
import { GenericDataConverter } from "./GenericDataConverter";

export class Paginated<T> {
  public static fromResponse<T>(
    data: PaginatedGraphQLResponse,
    itemConverter?: (items: any[]) => T[]
  ): Paginated<T> {
    const items = itemConverter ? itemConverter(data.data) : data.data;
    return new Paginated(items, data.count);
  }

  public static fromResponseConverted<T extends object>(
    data: PaginatedGraphQLResponse,
    converter?: GenericDataConverter<T>
  ): Paginated<T> {
    const items = converter
      ? data.data.map((d: object) => converter.convert(d))
      : data.data;
    return new Paginated(items, data.count);
  }

  public constructor(public items: T[], public totalCount: number) {}
}
