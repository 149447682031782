
import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Section from "@/components/layout/Section.vue";

import SubmitTextfield from "@/components/basic/SubmitTextfield.vue";

import GoogleMaps from "@/components/services/GoogleMaps.vue";

import { MapViewModel } from "@/common/vms/MapViewModel";
import { MapController } from "@/common/controllers/MapController";
import { MapPresenter } from "@/common/presenters/MapPresenter";
import { PropertyLocator } from "../interactors/PropertyLocator";
import { PropertyLocatorGraphQLGateway } from "@/gateways/graphql/PropertyLocatorGraphQLGateway";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connection/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { LocalStorage } from "../../storage/LocalStorage";
import { GraphQLDocumentService } from "../../document/services/DocumentService";
import { SettingsManager } from "../../admin/interactors/SettingsManager";
import { SettingsManagerGraphQLGateway } from "../../gateways/graphql/SettingsManagerGraphQLGateway";
import { Authenticator } from "../interactors/Authenticator";
import { AuthenticatorGraphQLGateway } from "../../gateways/graphql/AuthenticatorGraphQLGateway";

@Component<MapView>({
  components: {
    Page,
    Row,
    Column,
    Section,
    GoogleMaps,
    SubmitTextfield
  },
  watch: {
    "vm.selectedProperty"(propertyId: string) {
      this.$router.push({ name: "property", params: { propertyId } });
    },
    "vm.reload"(reload: boolean) {
      if (reload) {
        this.$router.push({ name: "home" });
        location.reload();
      }
    }
  }
})
export default class MapView extends Vue {
  private vm = new MapViewModel();

  private get controller() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new MapController(
      new MapPresenter(this.vm, new LocalStorage()),
      new PropertyLocator(new PropertyLocatorGraphQLGateway(connection)),
      new GraphQLDocumentService(connection),
      new SettingsManager(new SettingsManagerGraphQLGateway(connection)),
      new Authenticator(new AuthenticatorGraphQLGateway(connection))
    );
  }

  private get action() {
    return this.$route.params.action;
  }

  private mounted() {
    if (this.action === "impersonate" || this.action === "depersonate") {
      this.$store.dispatch("clearProperty");
    }
    this.controller.init(this.action);
  }
}
