
import { FormField } from "@/shared/form/FormData";
import Vue from "vue";
import Component from "vue-class-component";

@Component<Textarea>({
  props: {
    value: {
      type: Object,
      default() {
        return { label: "Label", value: "", error: "" };
      }
    },
    val: {
      type: [String, Number],
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: undefined
    },
    password: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    filled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    autoGrow: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 5
    },
    toggleDetails: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ""
    }
  }
})
export default class Textarea extends Vue {
  protected value!: FormField;
  protected error?: string;
  protected hideDetails!: boolean;
  protected toggleDetails!: boolean;

  protected get hiddenDetails() {
    return (
      this.hideDetails ||
      (this.toggleDetails && !(this.error ?? this.value.error))
    );
  }

  protected get type(): string {
    return this.$props.password ? "password" : "text";
  }
}
