
import Vue from "vue";
import Component from "vue-class-component";

import Value from "@/components/basic/Value.vue";

@Component<Alert>({
  components: {
    Value
  },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: "info"
    },
    prominent: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: "primary"
    }
  }
})
export default class Alert extends Vue {}
