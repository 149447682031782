import { ChecklistSection } from "./ChecklistSection";
import { Assignment } from "./Assignment";

export class Checklist {
  public preparationDone: boolean = false;
  public protectionGoalNiveau: string = "";
  public type: string = "";
  public sections: ChecklistSection[] = [];

  private mAssignment?: Assignment;

  public constructor(public id: number) {}

  public get assignment() {
    this.mAssignment = this.mAssignment || new Assignment(0);
    return this.mAssignment;
  }

  public set assignment(newChecklist: Assignment) {
    this.mAssignment = newChecklist;
  }
}
