import { ReportData } from "@/inspection/shared/dtos/ReportData";
import { InspectionRepoContainer } from "@/inspection/shared/repositories/InspectionRepoContainer";
import { Dictionary } from "@/shared/datastructures/Dictionary";
import { ServerResponse } from "@/shared/datastructures/Response";
import { StepperStep } from "@/shared/form/FormTypes";
import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { UserSettings } from "@/storage/UserSettings";
import { DateUtils } from "@/utils/DateUtils";
import { ReportDataForm } from "../forms/ReportDataForm";
import { ReportDataGeneralForm } from "../forms/ReportDataGeneralForm";
import { ReportDataImprintForm } from "../forms/ReportDataImprintForm";
import { ReportDataOtherForm } from "../forms/ReportDataOtherForm";
import { ReportDataTaskForm } from "../forms/ReportDataTaskForm";
import { ReportWaterForm } from "../forms/ReportWaterForm";

export const reportDataSteps: StepperStep[] = [
  {
    label: "Allgemein",
    key: "general",
    editable: true
  },
  {
    label: "Impressum",
    key: "imprint",
    editable: true
  },
  {
    label: "Aufgabenstellung",
    key: "tasks",
    editable: true
  },
  {
    label: "Weitere Kontrollen und Annex",
    key: "other",
    editable: true
  }
];

export class ReportDataModel extends RequestModel<ReportData> {
  public currentStep = 1;
  public reportUrl = "";

  public saveResponse = new ServerResponse<string>();

  private form: ReportDataForm;
  private generalForm: ReportDataGeneralForm;
  private imprintForm: ReportDataImprintForm;
  private taskForm: ReportDataTaskForm;
  private otherForm: ReportDataOtherForm;
  private waterForm: ReportWaterForm;

  private assignmentId = 0;
  private reportType = "";
  private reportId = 0;

  public constructor() {
    super();
    this.form = new ReportDataForm();
    this.form.init();

    this.generalForm = new ReportDataGeneralForm();
    this.generalForm.init();

    this.imprintForm = new ReportDataImprintForm();
    this.imprintForm.init();

    this.taskForm = new ReportDataTaskForm();
    this.taskForm.init();

    this.otherForm = new ReportDataOtherForm();
    this.otherForm.init();

    this.waterForm = new ReportWaterForm();
    this.waterForm.init();
  }

  public load(assignmentId: string, reportType: string, reportId: string) {
    this.assignmentId = parseInt(assignmentId, 10);
    this.reportType = reportType;
    this.reportId = parseInt(reportId, 10);

    if (this.isDefaultReport) {
      this.form.addSubForm(this.generalForm, "general", true);
      this.form.addSubForm(this.imprintForm, "imprint", true);
      this.form.addSubForm(this.taskForm, "task", true);
      this.form.addSubForm(this.otherForm, "other", true);
    } else {
      this.form.addSubForm(this.waterForm, "general", true);
    }

    RequestHandler.handleModel(
      () =>
        InspectionRepoContainer.reportRepo.getReportData(
          UserSettings.getNumericUserId(),
          assignmentId,
          reportType
        ),
      this,
      undefined,
      {
        onSuccess: data => (this.reportUrl = data.url)
      }
    );
  }

  public save() {
    RequestHandler.handleFormUpdate(
      () => {
        const data = JSON.stringify(this.form.getData());
        return InspectionRepoContainer.reportRepo.updateReportData(
          data,
          this.assignmentId,
          this.reportType
        );
      },
      {
        form: this.form
      },
      this.saveResponse,
      {
        onSuccess: (url: string) => (this.reportUrl = url),
        onLoad: () => (this.reportUrl = "")
      }
    );
  }

  public get steps(): StepperStep[] {
    return this.reportType === "default" ? reportDataSteps : [];
  }

  public get isFirstStep() {
    return this.currentStep === 1;
  }

  public get isDefaultReport() {
    return this.reportType === "default";
  }

  public get nextButtonText() {
    return this.currentStep >= this.steps.length
      ? "Speichern & Erstellen"
      : "Weiter";
  }

  public get currentFormValid() {
    switch (this.currentStep) {
      case 1:
        return this.isDefaultReport
          ? this.generalForm.isValid()
          : this.completeFormValid;
      case 2:
        return this.imprintForm.isValid();
      case 3:
        return this.taskForm.isValid();
      case 4:
        return this.completeFormValid;

      default:
        return false;
    }
  }

  public get completeFormValid() {
    return this.form.isValid();
  }

  public getGeneralField(fieldName: string) {
    return this.generalForm.getField(fieldName);
  }

  public setGeneralFieldValue(fieldName: string, value: unknown) {
    return this.generalForm.setFieldValue(fieldName, value);
  }

  public getImprintField(fieldName: string) {
    return this.imprintForm.getField(fieldName);
  }

  public setImprintFieldValue(fieldName: string, value: unknown) {
    return this.imprintForm.setFieldValue(fieldName, value);
  }

  public getTaskField(fieldName: string) {
    return this.taskForm.getField(fieldName);
  }

  public setTaskFieldValue(fieldName: string, value: unknown) {
    return this.taskForm.setFieldValue(fieldName, value);
  }

  public getOtherField(fieldName: string) {
    return this.otherForm.getField(fieldName);
  }

  public setOtherFieldValue(fieldName: string, value: unknown) {
    return this.otherForm.setFieldValue(fieldName, value);
  }

  public getWaterField(fieldName: string) {
    return this.waterForm.getField(fieldName);
  }

  public setWaterFieldValue(fieldName: string, value: unknown) {
    return this.waterForm.setFieldValue(fieldName, value);
  }

  public nextStep() {
    if (this.currentStep < this.steps.length) {
      this.currentStep++;
    } else {
      this.save();
    }
  }

  public prevStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  public jumpToStep(step: number) {
    if (this.currentFormValid) {
      this.currentStep = step;
    }
  }

  public from(data: ReportData): void {
    const reportData = data.data;
    this.reportUrl = data.url;

    for (const subFormKey of Object.keys(reportData)) {
      const subData = reportData[subFormKey] as Dictionary<unknown>;
      const subForm = this.form.getSubFormByIndex(subFormKey);

      for (const fieldName of Object.keys(subData)) {
        const value = subData[fieldName];
        subForm.setFieldValue(fieldName, value);
      }
    }

    if (reportData.imprint && (reportData.imprint as any).editorialDeadline) {
      this.imprintForm.setFieldValue(
        "editorialDeadline",
        DateUtils.value((reportData.imprint as any).editorialDeadline)
      );
    }
  }
}
