import { ILogger } from "./ILogger";
import { DateUtils } from "@/utils/DateUtils";

const loggingEnabled = process.env.VUE_APP_CONSOLE_LOG === "true";

export class ConsoleLogger implements ILogger {
  public log(...messages: any) {
    if (!loggingEnabled) {
      return;
    }
    // tslint:disable-next-line: no-console
    console.log(this.now);
    // tslint:disable-next-line: no-console
    console.log(messages);
  }

  public warning(...messages: any) {
    if (!loggingEnabled) {
      return;
    }
    // tslint:disable-next-line: no-console
    console.warn(this.now);
    // tslint:disable-next-line: no-console
    console.warn(messages);
  }

  public error(...messages: any) {
    if (!loggingEnabled) {
      return;
    }
    // tslint:disable-next-line: no-console
    console.error(this.now);
    // tslint:disable-next-line: no-console
    console.error(messages);
  }

  private get now() {
    return DateUtils.datetime(new Date());
  }
}
