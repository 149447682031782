import { Authorizator } from "@/common/interactors/Authorizator";
import { UserSettings } from "@/storage/UserSettings";
import { MenuItem } from "./SidebarModel";

export class UserMenuModel {
  public get items(): MenuItem[] {
    return [
      {
        text: "Mandant",
        visible: Authorizator.canViewOwnClient(),
        icon: "person",
        routeName: "client",
        params: { clientId: UserSettings.getClientId() }
      },
      {
        text: "Mandanten",
        visible: Authorizator.canListClients(),
        icon: "group",
        routeName: "clients"
      },
      {
        text: "Einstellungen",
        visible: Authorizator.canListSettings(),
        icon: "settings_applications",
        routeName: "settings"
      },
      {
        text: "DWSBase-Sync",
        visible: Authorizator.canEditClients(),
        icon: "autorenew",
        routeName: "dwsbase-sync"
      },
      {
        text: "Personifizierung aufheben",
        visible: UserSettings.isImpersonated(),
        icon: "voice_over_off",
        routeName: "home",
        params: { action: "depersonate" }
      },
      {
        text: "Logout",
        visible: true,
        icon: "exit_to_app",
        routeName: "logout"
      }
    ];
  }
}
