
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { component as Viewer } from "v-viewer";

import { ImageCollection } from "./ImageCollection";
import { Image } from "./Image";

@Component<ImageGallery>({
  components: { Viewer }
})
export default class ImageGallery extends Vue {
  @Prop({ type: Object, required: true })
  protected readonly images!: ImageCollection;

  protected $imageViewer: any;
  protected confirmIndex = -1;

  protected initImageViewer(viewer: any) {
    this.$imageViewer = viewer;
  }

  protected imageSelected(index: number) {
    this.$imageViewer.view(index);
  }

  protected openImage(url: string) {
    window.open(url, "_blank");
  }

  protected confirmingDeletion(confirming: boolean, index: number) {
    if (confirming) {
      this.confirmIndex = index;
    } else {
      this.confirmIndex = -1;
    }
  }

  protected emitUpload(file: File) {
    this.$emit("upload", file);
  }

  protected emitDelete(image: Image) {
    this.$emit("delete", image);
  }
}
