
import Vue from "vue";
import Component from "vue-class-component";

import Loading from "@/components/basic/Loading.vue";
import ErrorMessage from "@/components/basic/ErrorMessage.vue";
import Treeview from "@/components/collection/Treeview.vue";
import PropertyPart from "./PropertyPart.vue";
import PropertyPlant from "./PropertyPlant.vue";

import CreatePlantFormDialog from "./CreatePlantFormDialog.vue";

import { PropertyStructureModel } from "../../models/PropertyStructureModel";
import { PropertyPartModel } from "../../models/PropertyPartModel";
import { Plant } from "@/property/shared/models/Plant";

type PropertyPartElementType = "plant" | "propertyPart";

@Component<PropertyStructure>({
  components: {
    Loading,
    ErrorMessage,
    Treeview,
    PropertyPart,
    PropertyPlant,
    CreatePlantFormDialog
  },
  props: {
    propertyId: {
      type: Number,
      required: true
    }
  }
})
export default class PropertyStructure extends Vue {
  protected propertyId!: number;
  protected model: PropertyStructureModel = new PropertyStructureModel(
    this.propertyId
  );

  protected part = new PropertyPartModel(0, "", "", false);
  protected plant = new Plant();
  protected addElementContextMenu = false;
  protected editElementContextMenu = false;
  protected deleteElementContextMenu = false;
  protected createPlantDialogVisible = false;
  protected creatingSiblingPlant = false;

  protected get editPartItems() {
    return [
      { text: "Gebäudeteil", value: this.part },
      ...(this.part?.siblingPlants?.plants?.map(p => ({
        text: "Anlage: " + (p.name ?? "???"),
        value: p
      })) ?? [])
    ];
  }

  protected isPropertyPart(obj: unknown) {
    return obj instanceof PropertyPartModel;
  }

  protected editPart(part: PropertyPartModel) {
    this.setPart(part);
    if (part.hasSiblingPlants) {
      this.editElementContextMenu = true;
    } else {
      this.part.openEditDialog();
    }
  }

  protected addPartChild(part: PropertyPartModel) {
    this.setPart(part);
    this.addElementContextMenu = true;
  }

  protected addPartSibling(part: PropertyPartModel) {
    this.setPart(part);
    this.createSiblingPlant(true);
  }

  protected addPlantChild(plant: Plant) {
    this.setPlant(plant);
    this.$nextTick(() => (this.createPlantDialogVisible = true));
  }

  protected addPartTypeSelected(type: PropertyPartElementType) {
    if (type === "plant") {
      this.createSiblingPlant(false);
    } else if (type === "propertyPart") {
      this.part.openCreateDialog();
    }
  }

  protected editPartTypeSelected(entity: PropertyPartModel | Plant) {
    if (entity instanceof Plant) {
      this.goToPlant(entity);
    } else if (entity instanceof PropertyPartModel) {
      entity.openEditDialog();
    }
  }

  protected createSiblingPlant(sibling = false) {
    this.creatingSiblingPlant = sibling;
    this.$nextTick(() => (this.createPlantDialogVisible = true));
  }

  protected async plantCreated(plant: Plant) {
    if (plant) {
      this.createPlantDialogVisible = false;

      if (this.part.id !== 0) {
        await this.part.loadChildParts();
      }
      if (this.plant.exists) {
        await this.plant.loadChildren();
      }
    }
  }

  protected setPart(part: PropertyPartModel) {
    this.plant = new Plant();
    this.part = part;
  }

  protected setPlant(plant: Plant) {
    this.plant = plant;
    this.part = new PropertyPartModel(0, "", "", false);
  }

  protected goToPlant(plant?: Plant) {
    this.$router.push({
      name: "plant",
      params: { plantId: plant?.id?.toString() ?? "" }
    });
  }

  protected mounted() {
    this.loadStructure();
  }

  protected loadStructure() {
    this.model.loadStructure();
  }
}
