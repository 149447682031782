
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { TypeChecker } from "@/shared/utils/TypeChecker";
import { InspectionSelectionItemLoader } from "@/inspection/shared/dtos/InspectionDomainSelectionItemLoader";

@Component<TicketPrioritySelect>({
  components: {}
})
export default class TicketPrioritySelect extends Vue {
  @Prop({ type: [Array, String], default: "" })
  protected readonly priorities!: string[] | string;
  @Prop({ type: Boolean, default: false })
  protected readonly loading!: boolean;
  @Prop({ type: String, default: "" })
  protected readonly error!: string;

  protected get multiple() {
    return TypeChecker.isArray(this.priorities);
  }

  protected get items() {
    return InspectionSelectionItemLoader.loadPriorities();
  }

  protected emitChange(priorities: string | string[]) {
    this.$emit("change", priorities);
  }
}
