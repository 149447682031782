import { Dictionary } from "@/datastructures/Dictionary";
import {
  Textfield,
  StringValue,
  Date,
  Request,
  SelectableSelection,
  Selection
} from "@/forms/ViewModelFormTypes";
import { Ticket } from "@/inspection/shared/models/Ticket";
import { Person } from "@/property/shared/models/Person";
import { Plant } from "@/property/shared/models/Plant";
import { TicketTypeFields } from "@/storage/UserSettings";

export class TicketDetailViewModel {
  public ticket = new Ticket();
  public ticketId = 0;
  public goBack = false;
  public goToTicket = "";

  public deletedTicket = "";
  public selectedProperty = "";
  public selectedImageIndex = "";
  public deleteTicketButtonVisible = true;
  public tmpPlanId = "";
  public tmpPlanLocation: LocationViewModel = { x: "0", y: "0" };

  public ticketTypeName = "Weitere Daten";
  public ticketType?: string = "";
  public ticketFields: TicketTypeFields = [];
  public additionalTicketData: Dictionary<any> = {};

  public createdAt = "";
  public ticketState = "";
  public creator = new Person();
  public responsiblePerson = new Person();
  public plant = new Plant();

  public canEditTicket = false;
  public ticketAccessButtonVisible = false;
  public ticketImagesVisible = false;
  public imageUploadVisible = false;
  public deleteImageButtonVisible = false;
  public displayComments = false;

  // Requests
  public loadTicketRequest: Request = {
    loading: false,
    error: ""
  };
  public deleteTicketRequest: Request = {
    loading: false,
    error: ""
  };
  public editingDescriptionRequest: Request = {
    loading: false,
    error: ""
  };
  public editingOrganizationRequest: Request = {
    loading: false,
    error: ""
  };
  public editingAdditionalDataRequest: Request = {
    loading: false,
    error: ""
  };
  public deleteImageRequest: Request = {
    loading: false,
    error: ""
  };
  public uploadImageRequest: Request = {
    loading: false,
    error: ""
  };
  public updateLocationRequest: Request = {
    loading: false,
    error: ""
  };
  public deleteLocationRequest: Request = {
    loading: false,
    error: ""
  };
  public deleteChecklistReferenceRequest: Request = {
    loading: false,
    error: ""
  };
  public saveChecklistReferenceRequest: Request = {
    loading: false,
    error: ""
  };

  // States
  public editingDescription = false;
  public editingDescriptionDisabled = false;
  public editingDescriptionDeactivated = false;

  public editingAdditionalData = false;
  public editingAdditionalDataDisabled = false;
  public editingAdditionalDataDeactivated = false;

  public editingOrganization = false;
  public editingOrganizationDisabled = false;
  public editingOrganizationDeactivated = false;

  public editingImagesDeactivated = false;
  public editingImages = false;

  public editingReferencesDisabled = false;
  public editingReferencesVisible = false;
  public editLocationAllowed = false;
  public editChecklistReferenceAllowed = false;
  public addLocationButtonVisible = false;
  public deleteLocationButtonDisabled = true;

  public addChecklistReferenceButtonVisible = false;

  public ticketLocationVisible = false;
  public checklistReferenceVisible = false;
  public checklistSectionsVisible = false;
  public checklistEntriesVisible = false;
  public saveChecklistReferenceButtonDisabled = true;
  public deleteChecklistReferenceButtonDisabled = true;

  // Prev / Next ticket
  public prevTicketId = "";
  public nextTicketId = "";
  public prevTicketButtonDisabled = false;
  public nextTicketButtonDisabled = false;
  public prevTicketButtonText = "Voriges Ticket";
  public nextTicketButtonText = "Nächstes Ticket";

  public imageDialogVisible = false;
  public locationDialogVisible = false;
  public checklistReferenceDialogVisible = false;

  public pageTitle = "Ticket";

  // Input
  public title: Textfield = {
    label: "Titel",
    value: "",
    error: "",
    loading: false
  };
  public description: Textfield = {
    label: "Beschreibung",
    value: "",
    error: "",
    loading: false
  };
  public action: Textfield = {
    label: "Maßnahme",
    value: "",
    error: "",
    loading: false
  };

  public dueDate: Date = {
    label: "Frist",
    value: "",
    error: "",
    loading: false,
    text: ""
  };
  // public priorities: PriorityViewData[] = [];
  public priority: SelectableSelection<PriorityViewData> = {
    label: "Priorität",
    selected: "",
    error: "",
    loading: false,
    items: []
  };

  public images: ImageViewModel[] = [];

  public property: PropertyViewModel = {
    label: "Gebäude",
    value: "",
    id: ""
  };

  public location: PlanLocationViewModel = {
    label: "Verortung",
    value: "",
    id: "",
    planId: "",
    location: {
      x: "",
      y: ""
    }
  };

  public checklistReference: ChecklistReferenceViewModel = {
    assignmentId: "",
    checklistId: "",
    sectionId: "",
    entryId: "",
    label: "Checklist-Referenz",
    value: ""
  };

  public assignment: AssignmentSelection = {
    label: "Auftrag auswählen",
    selected: "",
    error: "",
    loading: false,
    items: [],
    checklistId: ""
  };

  public checklistSection: Selection = {
    label: "Checklisten-Ordner auswählen",
    selected: "",
    error: "",
    loading: false,
    items: []
  };

  public checklistEntry: Selection = {
    label: "Checklisten-Eintrag auswählen",
    selected: "",
    error: "",
    loading: false,
    items: []
  };
}

interface PropertyViewModel extends StringValue {
  id: string;
}

interface PlantViewModel extends StringValue {
  id: string;
}

interface PlanLocationViewModel extends StringValue {
  id: string;
  planId: string;
  location: LocationViewModel;
}

interface ChecklistReferenceViewModel extends StringValue {
  assignmentId: string;
  checklistId: string;
  sectionId: string;
  entryId: string;
}

export interface LocationViewModel {
  x: string;
  y: string;
}

interface ImageViewModel {
  id: string;
  path: string;
  downloadPath: string;
}

interface PriorityViewData {
  text: string;
  value: string;
  icon: string;
  color: string;
}

interface AssignmentSelection extends Selection {
  checklistId: string;
}
