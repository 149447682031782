
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import DynamicFormBuilder from "@/shared/form/DynamicFormBuilder.vue";

import { DynamicForm } from "@/shared/form/DynamicForm";

@Component<ReportDataForm>({
  components: { DynamicFormBuilder }
})
export default class ReportDataForm extends Vue {
  @Prop({ required: true }) protected readonly form!: DynamicForm;

  protected dataChanged(data: unknown) {
    this.$emit("change", data);
  }
}
