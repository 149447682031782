
import Vue from "vue";
import Component from "vue-class-component";

import { EnvironmentUtils } from "@/utils/EnvironmentUtils";

@Component<IconButton>({
  props: {
    tooltipWidth: {
      type: Number,
      default: 100
    },
    tooltipText: {
      type: String,
      default: ""
    },
    tooltipTextColor: {
      type: String,
      default: "white"
    },
    icon: {
      type: String,
      required: true
    },
    iconColor: {
      type: String,
      default: undefined
    },
    color: {
      type: String,
      default: "primary"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ""
    },
    skipTooltip: {
      type: Boolean,
      default: false
    },
    noTooltip: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "normal"
    }
  }
})
export default class IconButton extends Vue {
  private tooltipVisible: boolean = false;

  private get buttonIcon() {
    return !this.$props.error ? this.$props.icon : "replay";
  }

  private get iconSize() {
    switch (this.$props.size) {
      case "normal":
        return 22;
      case "small":
        return 16;
      case "x-small":
        return 12;

      default:
        return 22;
    }
  }

  private click(skipTooltip: boolean = false) {
    if (skipTooltip) {
      this.tooltipVisible = false;
      this.$emit("click");
      return;
    }
    this.tooltipVisible = true;
  }

  private hover() {
    if (EnvironmentUtils.isMobile()) {
      return;
    }
    this.tooltipVisible = true;
  }

  private unhover() {
    if (EnvironmentUtils.isMobile()) {
      return;
    }
    this.tooltipVisible = false;
  }
}
