
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import PropertySelect from "@/property/shared/widgets/views/PropertySelect.vue";

import PlanPositionLocator from "@/inspection/shared/widgets/PlanPositionLocator.vue";
import { Property } from "@/property/shared/models/Property";
import { Ticket } from "@/inspection/shared/models/Ticket";
import { TicketCreationForm } from "./TicketCreationWorkflow.vue";
import { Plant } from "@/property/shared/models/Plant";

@Component<TicketLocationForm>({
  components: {
    PropertySelect,
    PlanPositionLocator
  }
})
export default class TicketLocationForm
  extends Vue
  implements TicketCreationForm
{
  @Prop({
    default() {
      return new Ticket();
    }
  })
  protected readonly ticket!: Ticket;
  @Prop({ type: Number }) protected readonly propertyId?: number;

  protected plant = new Plant();

  @Watch("ticket")
  protected ticketChanged() {
    this.initPlant();
  }

  @Watch("ticket.plant")
  protected ticketPlantChanged() {
    this.initPlant();
  }

  @Watch("propertyId")
  protected onPropertyIdChanged() {
    this.loadSelectedProperty();
  }

  protected get valid() {
    return !!this.ticket.propertyId;
  }

  protected get locator() {
    return this.$refs.locator as PlanPositionLocator;
  }

  public validate() {
    this.emitValidate();
    return this.valid;
  }

  public async reset() {
    this.loadSelectedProperty();
  }

  protected loadSelectedProperty() {
    let preselectedProperty = new Property();

    if (this.propertyId) {
      preselectedProperty.id = this.propertyId;
    } else if (this.$store.getters.hasExactlyOnePropertySelected) {
      preselectedProperty.id = parseInt(
        this.$store.getters.getFirstSelectedProperty.id,
        10
      );
    } else {
      preselectedProperty = Property.loadLast();
    }

    this.selectProperty(preselectedProperty);
  }

  protected selectProperty(property: Property) {
    this.ticket.property = property;
    this.emitValidate();
  }

  protected emitValidate() {
    this.$emit("validate", this.valid);
  }
  protected emitConfirm() {
    this.$emit("confirm");
  }

  protected mounted() {
    this.loadSelectedProperty();
    this.initPlant();
  }

  protected initPlant() {
    this.plant = Plant.from({ id: this.ticket.plant?.id });
  }
}
