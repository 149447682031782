import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { PropertyEntity } from "../entities/PropertyEntity";
import { PropertyReportContainer } from "../repositories/PropertyRepoContainer";
import { Property } from "./Property";

interface PropertyListProps {
  properties?: Property[];
}

export class PropertyList extends RequestModel<PropertyEntity[]> {
  public properties?: Property[];

  public constructor(props?: PropertyListProps) {
    super();
    this.properties = props?.properties;
  }

  public get selectionItems() {
    return (
      this.properties?.map(p => ({
        value: p.id ?? 0,
        text: p.label,
        item: p
      })) ?? []
    );
  }

  public findByIds(ids?: number[]) {
    return (
      this.properties?.filter(p => ids?.includes(p.id ?? 0) ?? false) ?? []
    );
  }

  public findById(id?: number) {
    return this.properties?.find(p => p.id === id);
  }

  public async loadSelection(userId: number, onlyCached = false) {
    await RequestHandler.handleModel(
      () =>
        PropertyReportContainer.propertyCacheRepo.getPropertySelection(userId),
      this
    );

    if (!onlyCached) {
      await RequestHandler.handleModel(
        () => PropertyReportContainer.propertyRepo.getPropertySelection(userId),
        this
      );
    }
  }

  public from(data: PropertyEntity[]) {
    this.properties = data.map(p => Property.from(p));
  }

  public collect(properties?: Property[]) {
    this.properties = properties;
  }

  public static collect(properties?: Property[]) {
    const list = new PropertyList();
    list.collect(properties);
    return list;
  }
}
