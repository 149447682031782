import { SettingsListViewModel } from "../vms/SettingsListViewModel";
import { ISettingsListPresenter } from "../controllers/SettingsListController";
import { FormResponse } from "@/forms/FormResponse";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { SettingsCatalogRow } from "../interactors/SettingsManager";
import { Authorizator } from "@/common/interactors/Authorizator";
import { Translator } from "@/shared/i18n/Translator";

export class SettingsListPresenter implements ISettingsListPresenter {
  public constructor(private vm: SettingsListViewModel) {}

  public init() {
    this.vm.goBack = !Authorizator.canListSettings();
    this.vm.editSettingsGloballyButtonVisible = Authorizator.canEditSettings();
    this.vm.editSettingsButtonVisible = Authorizator.canViewOwnSettings();
  }

  // Routes
  public set globalSettingToEdit(settingId: string) {
    this.vm.selectedGlobalSetting = settingId;
  }

  public set clientSettingToEdit(settingId: string) {
    this.vm.selectedClientSetting = settingId;
  }

  // Data
  public get settingsPerPage(): number {
    return this.vm.settingsPerPage;
  }

  public set currentPage(page: number) {
    this.vm.currentPage = page;
  }

  // Responses
  public set loadSettingsCatalogResponse(
    response: FormResponse<PaginatedList<SettingsCatalogRow>>
  ) {
    this.vm.loadSettingsRequest = response;

    if (!response.loading && !response.error) {
      this.vm.settings = response.data.items.map(setting => ({
        id: setting.id.id.toString(),
        key: setting.key,
        name: Translator.t(setting.key),
        overwritable: setting.overwritable
      }));

      this.vm.totalSettings = response.data.totalCount;
    }
  }
}
