
import Vue from "vue";
import Component from "vue-class-component";

@Component<Divider>({
  props: {
    inset: {
      type: Boolean,
      default: true
    }
  }
})
export default class Divider extends Vue {}
