
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component<PlantDropdownItemsDefinition>({
  components: {}
})
export default class PlantDropdownItemsDefinition extends Vue {
  @Prop({ type: Array, default: () => [] })
  protected readonly items!: string[];

  protected addItem() {
    const items = this.copyItems();
    items.push("");
    this.emitChange(items);
  }

  protected itemChanged(index: number, newText: string) {
    const items = this.copyItems();
    items[index] = newText;
    this.emitChange(items);
  }

  protected deleteItem(index: number) {
    const items = this.copyItems();
    items.splice(index, 1);
    this.emitChange(items);
  }

  protected copyItems() {
    return this.items.map(i => i);
  }

  protected emitChange(items: string[]) {
    this.$emit("change", items);
  }

  protected emitDone() {
    this.$emit("done");
  }
}
