import { ChecklistEntry } from "./ChecklistEntry";
import { Checklist } from "./Checklist";

export class ChecklistSection {
  public name: string = "";
  public entries: ChecklistEntry[] = [];
  public checklist: Checklist = new Checklist(0);

  public constructor(public id: number) {}
}
