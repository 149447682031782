
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { Client } from "@/property/shared/models/Client";

@Component<ClientCard>({
  components: {}
})
export default class ClientCard extends Vue {
  @Prop({
    default() {
      return new Client();
    }
  })
  private readonly client!: Client;

  private goToClient(clientId: number) {
    this.$router.push({
      name: "client",
      params: { clientId: clientId.toString() }
    });
  }
}
