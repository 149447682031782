import { ReportTemplateSettings } from "./ReportTemplateSettings";
import { TicketStateSettings } from "./TicketStateSettings";
import { ChecklistSettings } from "./ChecklistSettings";
import { DeadlineSettings } from "./DeadlineSettings";
import { PrioritySettings } from "./PrioritySettings";
import { TicketTemplateSettings } from "./TicketTemplateSettings";
import { TicketTypeSettings } from "./TicketTypeSettings";

export class InspectionSettingsContainer {
  public static ticketStates = new TicketStateSettings();
  public static reportTemplates = new ReportTemplateSettings();
  public static checklists = new ChecklistSettings();
  public static ticketTemplates = new TicketTemplateSettings();
  public static ticketTypes = new TicketTypeSettings();
  public static deadlines = new DeadlineSettings();
  public static priorities = new PrioritySettings();
}
