
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import Pagination from "@/components/basic/Pagination.vue";
import DataTable from "./DataTable.vue";

@Component<PaginatedDataTable>({
  components: {
    Pagination,
    DataTable
  },
  props: {
    value: {
      type: Number,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      default: 20
    },
    totalItems: {
      type: Number,
      required: true
    },
    headers: {
      type: Array,
      default() {
        return [];
      }
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
export default class PaginatedDataTable extends Vue {
  @Prop({ type: Boolean, default: false })
  protected readonly showSelect!: boolean;
  @Prop() protected readonly noDataText?: string;
  @Prop() protected readonly error?: string;
  @Prop({ default: "name" }) protected readonly itemKey!: string;

  protected emitSort(newOptions: any) {
    this.$emit("sort", newOptions);
  }
}
