import { PersonEntity } from "@/property/shared/entities/PersonEntity";

export class PersonModel {
  public static from(person?: PersonEntity) {
    return new PersonModel(person?.id, person?.firstname, person?.lastname);
  }

  public constructor(
    public id?: number,
    public firstname?: string,
    public lastname?: string
  ) {}

  public get name() {
    return [this.firstname, this.lastname].filter(n => !!n).join(" ");
  }
}
