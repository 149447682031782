
import Vue from "vue";
import Component from "vue-class-component";

import SyncPanel from "./SyncPanel.vue";

import { Synchronizer } from "./Synchronizer";
import { LocalStorage } from "@/storage/LocalStorage";
import { storageKeys } from "@/data/storageKeys";
import { MathUtils } from "../utils/MathUtils";

@Component<SyncPanelHeaderButton>({
  components: { SyncPanel }
})
export default class SyncPanelHeaderButton extends Vue implements Synchronizer {
  protected reloadHandler = 0;
  protected syncHandler = 0;
  protected timerHandler = 0;

  protected reloading = false;
  public syncing = false;
  protected timer = 0;
  protected inSyncTimer = 0;
  protected paused = false;

  protected get menuWidth() {
    return this.isDesktop ? "70%" : "90%";
  }

  protected get buttonWidth() {
    return this.isDesktop ? "56px" : "32px";
  }

  protected get isDesktop() {
    return this.$vuetify.breakpoint.mdAndUp;
  }

  protected get syncPanel(): SyncPanel {
    return this.$refs.syncPanel as SyncPanel;
  }

  protected get syncIconColor() {
    return this.syncing ? "secondary" : "grey";
  }

  protected get timerString() {
    return this.timer.toString();
  }

  protected get syncWarning() {
    return this.syncingTakesLongTime
      ? "Die Synchronisation dauert nun über 30 Sekunden. Falls Sie eine schwache Internetverbindung haben, können Sie die Synchronisation pausieren und später fortsetzen, um die Internetverbindung für die momentane Arbeit frei zu halten."
      : "";
  }

  protected get syncingTakesLongTimeOrPaused() {
    return this.syncingTakesLongTime || this.paused;
  }

  protected get badgeIcon() {
    return this.paused ? "pause" : "priority_high";
  }

  protected get badgeColor() {
    return this.paused ? "primary" : "orange";
  }

  protected get syncingTakesLongTime() {
    return this.syncing && this.inSyncTimer > 30;
  }

  public async reload() {
    if (this.syncing) {
      this.initReloadTimer();
      return;
    }

    if (!this.reloading) {
      this.reloading = true;

      await this.syncPanel.reload();
      this.initReloadTimer();

      this.reloading = false;
    }
  }

  public async sync() {
    await this.reload();

    if (this.reloading) {
      this.initSyncTimer(true);
      return;
    }

    if (!this.syncing) {
      this.syncing = true;
      this.inSyncTimer = 0;

      try {
        await this.syncPanel.sync();
      } finally {
        this.initSyncTimer();
      }

      this.syncing = false;
    }
  }

  public pause() {
    this.syncPanel.pause();
  }

  public resume() {
    this.syncPanel.resume();
  }

  protected initReloadTimer() {
    clearTimeout(this.reloadHandler);
    this.reloadHandler = setTimeout(() => this.reload(), 3000);
  }

  protected initSyncTimer(shortInterval = false) {
    clearTimeout(this.syncHandler);

    const seconds = shortInterval ? 1 : 20;

    this.syncHandler = setTimeout(() => this.sync(), seconds * 1000);
    this.initTimerInterval(seconds);
  }

  protected initTimerInterval(seconds = 20) {
    clearTimeout(this.timerHandler);

    this.timer = seconds;
    this.timerHandler = setInterval(() => {
      this.timer--;
      if (this.syncing) {
        this.inSyncTimer++;
      }
    }, 1000);
  }

  protected async mounted() {
    const pausedString = new LocalStorage().get(storageKeys.syncPaused);
    const paused = MathUtils.parseBoolean(pausedString);

    if (paused) {
      this.pause();
    } else {
      this.resume();
    }

    this.sync();
    this.initTimerInterval();

    setInterval(() => (this.paused = this.syncPanel.paused), 1000);
  }
}
