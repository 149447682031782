import { RequestModel } from "../model/RequestModel";
import { TypeChecker } from "./TypeChecker";
import { Form } from "../form/Form";
import { ServerResponse } from "@/shared/datastructures/Response";
import * as Sentry from "@sentry/vue";

const loggingEnabled = process.env.VUE_APP_CONSOLE_LOG === "true";

export interface RequestCallbacks<T> {
  onLoad?(loading: boolean): void;
  onSuccess?(result: T): void;
  onFailure?(error: string): void;
}

export class RequestHandler {
  public static async handleModel<T>(
    request: () => Promise<T>,
    model: RequestModel<T>,
    response?: ServerResponse<T>,
    callbacks?: RequestCallbacks<T>
  ) {
    const requestExecution = request();

    this.handle(requestExecution, response, callbacks);

    if (TypeChecker.isPromise(requestExecution)) {
      try {
        model.loading = true;
        model.error = "";

        const result = await requestExecution;

        model.from(result);
        model.loading = false;
      } catch (error) {
        model.loading = false;

        if (TypeChecker.isError(error)) {
          this.handleError(error, model);
        } else {
          model.error = error;
        }

        return false;
      }
    } else if (TypeChecker.isError(requestExecution)) {
      this.handleError(requestExecution, model);
    } else {
      model.from(requestExecution);
    }

    return true;
  }

  public static async handle<T>(
    request: Promise<T>,
    response?: ServerResponse<T> | Array<ServerResponse<T>>,
    callbacks?: RequestCallbacks<T>
  ) {
    if (!response) {
      response = [];
    } else if (!TypeChecker.isArray(response)) {
      response = [response];
    }

    if (TypeChecker.isPromise(request)) {
      try {
        response.forEach(r => {
          r.loading = true;
          r.error = "";
          r.failure = false;
        });

        this.safeParamCallback(true, callbacks?.onLoad);

        const result = await request;

        response.forEach(r => {
          r.loading = false;
          r.success = true;
          r.result = result;
        });

        this.safeParamCallback(false, callbacks?.onLoad);
        this.safeParamCallback(result, callbacks?.onSuccess);
      } catch (error) {
        this.safeParamCallback(false, callbacks?.onLoad);

        let errorMessage = "";
        if (error != null && (TypeChecker.isError(error) || error.message)) {
          errorMessage = error.message;
        } else {
          errorMessage = error ?? "Fehler";
        }

        this.safeParamCallback(errorMessage, callbacks?.onFailure);

        response.forEach(r => {
          r.loading = false;
          r.failure = true;
          r.error = errorMessage;
        });

        Sentry.captureException(new Error(errorMessage));

        throw new Error(errorMessage);
      }
    } else if (TypeChecker.isError(request)) {
      this.safeParamCallback((request as Error).message, callbacks?.onFailure);
      return false;
    } else {
      this.safeParamCallback(request, callbacks?.onSuccess);
    }

    return true;
  }

  public static handleFormUpdate<T, U>(
    request: () => Promise<T>,
    formData: { form: Form<U>; fieldName?: string; value?: unknown },
    response?: ServerResponse<T>,
    callbacks?: RequestCallbacks<T>
  ) {
    if (formData.fieldName !== undefined && formData.value !== undefined) {
      return RequestHandler.handleFormFieldUpdate(
        request,
        formData.form,
        formData.fieldName,
        formData.value,
        response
      );
    } else {
      if (!formData.form.validateForm(true)) {
        return Promise.resolve(false);
      }
      return RequestHandler.handle(request(), response, {
        onLoad: (loading: boolean) => {
          formData.form.enabled = !loading;
          this.safeParamCallback(loading, callbacks?.onLoad);
        },
        onFailure: callbacks?.onFailure,
        onSuccess: callbacks?.onSuccess
      });
    }
  }

  private static handleFormFieldUpdate<T, U>(
    request: () => Promise<T>,
    form: Form<U>,
    fieldName: string,
    value: unknown,
    response?: ServerResponse<T>
  ) {
    const prevValue = form.getFieldValue(fieldName);

    if (form.setFieldValue(fieldName, value)) {
      if (!form.validateForm(true)) {
        return Promise.resolve(false);
      }

      return RequestHandler.handle(request(), response, {
        onLoad: (loading: boolean) => (form.enabled = !loading),
        onFailure: (error: string) => {
          form.setFieldValue(fieldName, prevValue);
          form.setFieldError(fieldName, error);
        }
      });
    } else {
      return Promise.resolve(false);
    }
  }

  private static handleError<T>(error: Error, model: RequestModel<T>) {
    if (loggingEnabled) {
      // tslint:disable-next-line: no-console
      console.log(error.stack);
    }
    model.error = error.message;
  }

  private static safeParamCallback<T>(value: T, callback?: (value: T) => void) {
    if (callback) {
      callback(value);
    }
  }
}
