import {
  DocumentService,
  Document,
  DocumentMetumDescription
} from "../services/DocumentService";
import { NumericalId } from "@/datastructures/NumericalId";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { DocumentForm } from "../forms/DocumentForm";
import { DynamicForm } from "@/forms/DynamicForm";

export class DocumentDetailController {
  public constructor(
    private presenter: IDocumentDetailPresenter,
    private documentService: DocumentService
  ) {}

  public init(documentId: string) {
    this.presenter.documentId = documentId;

    this.presenter.init();
    this.loadDocument(documentId);
  }

  public openDocumentButtonClicked(link: string) {
    this.presenter.documentToOpen = link;
  }

  public nameChanged(value: string) {
    this.presenter.documentForm.setFieldValue("name", value);
  }

  public metaValueChanged(fieldName: string, value: string) {
    this.presenter.metaForm.setFieldValue(fieldName, value);
  }

  public loadMetum(metumId: number) {
    NumericalId.onValidId(metumId, id => {
      const request = this.documentService.getDocumentMetum(id.id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadDocumentMetumResponse = response),
        "Metadaten konnten nicht geladen werden"
      );
    });
  }

  public saveDocumentButtonClicked() {
    const request = this.documentService.saveDocument(this.presenter.document);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.saveDocumentResponse = response),
      "Dokument konnte nicht gespeichert werden"
    );
  }

  public deleteDocumentButtonClicked(documentId: string) {
    NumericalId.onValidId(documentId, id => {
      const request = this.documentService.deleteDocument(id.id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.deleteDocumentResponse = response),
        "Löschen des Dokuments fehlgeschlagen"
      );
    });
  }

  private loadDocument(documentId: string) {
    NumericalId.onValidId(documentId, id => {
      const request = this.documentService.getDocument(id.id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadDocumentResponse = response),
        "Dokument konnte nicht geladen werden"
      );
    });
  }
}

export interface IDocumentDetailPresenter {
  documentId: string;

  documentForm: DocumentForm;
  metaForm: DynamicForm;
  document: Document;
  documentToOpen: string;

  loadDocumentResponse: FormResponse<Document>;
  loadDocumentMetumResponse: FormResponse<DocumentMetumDescription>;
  saveDocumentResponse: FormResponse<number>;
  deleteDocumentResponse: FormResponse<number>;

  init(): void;
}
