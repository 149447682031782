import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";

export interface ReportDataGeneralFormData {
  name: string;
  date: Date;
  issueDate: Date;
  latestAlternationDate: Date;
  creator: string;
  approvedBy: string;
  client: string;
}

export class ReportDataGeneralForm extends Form<ReportDataGeneralFormData> {
  protected definition = {
    name: { label: "Name", type: FormFieldType.Text, required: true },
    date: {
      label: "Berichtdatum",
      type: FormFieldType.Date,
      required: false
    },
    issueDate: {
      label: "Ausgabedatum",
      type: FormFieldType.Date,
      required: false
    },
    latestAlternationDate: {
      label: "Letztes Änderungsdatum",
      type: FormFieldType.Date,
      required: false
    },
    creator: {
      label: "Erstellt von",
      type: FormFieldType.Text,
      required: true
    },
    approvedBy: {
      label: "Freigegeben von",
      type: FormFieldType.Text,
      required: true
    },
    client: { label: "Auftraggeber", type: FormFieldType.Text, required: true }
  };
}
