import { PropertyLocatorGateway } from "@/common/interactors/PropertyLocator";
import { GraphQLConnection } from "./connection/GraphQLConnection";
import { Page } from "@/datastructures/Page";
import { Property } from "@/entities/Property";
import { NumericalId } from "@/datastructures/NumericalId";
import { Address } from "@/entities/Address";

export class PropertyLocatorGraphQLGateway implements PropertyLocatorGateway {
  public constructor(private connection: GraphQLConnection) {}

  public loadPropertyLocations(
    clientId: NumericalId,
    search: string,
    page: Page
  ) {
    return this.connection
      .queryPaginated(
        "properties",
        page.itemsPerPage,
        page.page,
        { client_id: clientId.id },
        [
          "id",
          "name",
          { name: "address", fields: ["street", "postcode", "city"] },
          "latitude",
          "longitude"
        ],
        search
      )
      .then(response =>
        response.data.map((data: any) => {
          const property = new Property(data.id);

          property.name = data.name;

          if (!!data.address) {
            property.address = new Address(
              data.address.street,
              data.address.postcode,
              data.address.city
            );
          }
          property.address.location.lat = data.latitude;
          property.address.location.lng = data.longitude;

          return property;
        })
      );
  }
}
