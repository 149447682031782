import { Property } from "@/inspection/shared/dtos/Property";

export class PropertyModel {
  public static from(property: Property) {
    return new PropertyModel(
      property.id,
      property.name,
      property.street,
      property.zip,
      property.city
    );
  }

  private constructor(
    public id: number,
    public name: string,
    public street: string,
    public zip: string,
    public city: string
  ) {}

  public get address(): string {
    return `${this.street}, ${this.fullCity}`;
  }

  public get fullCity(): string {
    return `${this.zip} ${this.city}`;
  }
}
