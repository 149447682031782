import { StringUtils } from "@/shared/utils/StringUtils";
import {
  PlantChecklistEntryData,
  PlantChecklistEntryEntity
} from "../entities/PlantEntity";
import { PlantTypeChecklistEntry } from "../settings/PlantTypeSettings";

export class PlantChecklistEntry {
  public id?: string = "";
  public name?: string = "";
  public description?: string = "";
  public interval?: string = "";

  public updateData(data?: PlantChecklistEntryData) {
    this.id = StringUtils.uuid();
    this.name = data?.name;
    this.description = data?.description;
    this.interval = data?.interval;
  }

  public copy() {
    return PlantChecklistEntry.from(this.toEntity());
  }

  public toEntity(): PlantChecklistEntryEntity {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      interval: this.interval
    };
  }

  public from(entity?: PlantChecklistEntryEntity) {
    this.id = entity?.id ?? "";
    this.name = entity?.name ?? "";
    this.description = entity?.description ?? "";
    this.interval = entity?.interval ?? "";
  }

  public fromType(type?: PlantTypeChecklistEntry) {
    this.id = type?.id ?? "";
    this.name = type?.name ?? "";
    this.description = type?.description ?? "";
    this.interval = type?.intervall ?? "";
  }

  public static from(entity?: PlantChecklistEntryEntity) {
    const field = new PlantChecklistEntry();
    field.from(entity);
    return field;
  }

  public static fromType(type?: PlantTypeChecklistEntry) {
    const field = new PlantChecklistEntry();
    field.fromType(type);
    return field;
  }
}
