
import { Plant } from "@/property/shared/models/Plant";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component<PlantNavigationButton>({
  components: {}
})
export default class PlantNavigationButton extends Vue {
  @Prop({ type: Object }) protected readonly plant?: Plant;
  @Prop({ type: Array, default: () => [] }) protected readonly plants!: Plant[];
  @Prop({ default: "" }) protected readonly text!: string;
  @Prop() protected readonly icon?: string;

  protected get buttonText() {
    return this.plantCount === 1
      ? "Anlage: " + this.combinedPlants[0].toString()
      : this.text + ": " + this.plantCount;
  }

  protected get disabled() {
    return this.plantCount === 0;
  }

  protected get skipTooltip() {
    return this.plantCount > 1;
  }

  protected get plantCount() {
    return this.combinedPlants.length;
  }

  protected get items() {
    return this.combinedPlants.map(p => ({
      text: p.toString(),
      value: p.id?.toString() ?? "",
      item: p
    }));
  }

  protected get combinedPlants() {
    return [...(this.plant ? [this.plant] : []), ...this.plants];
  }

  protected goToPlant(plantId: string) {
    this.$router.push({ name: "plant", params: { plantId } });
  }
}
