import { PropertyPlanViewModel } from "../vms/PropertyPlanViewModel";
import { IPropertyPlanPresenter } from "../controllers/PropertyPlanController";
import { FormResponse } from "@/forms/FormResponse";
import {
  PlanData,
  PlanCatalogRow,
  PlanPayload,
  PlanImageData
} from "../interactors/PropertyPlanManager";
import { CreatePlanForm } from "@/forms/property/CreatePlanForm";
import { NumericalId } from "@/datastructures/NumericalId";
import { Point } from "@/datastructures/Point";
import { PlanPositionForm } from "@/forms/property/PlanPositionForm";
import { MathUtils } from "@/utils/MathUtils";
import { Authorizator } from "@/common/interactors/Authorizator";

export class PropertyPlanPresenter implements IPropertyPlanPresenter {
  public planForm: CreatePlanForm;
  public positionForm: PlanPositionForm;

  public constructor(private vm: PropertyPlanViewModel) {
    this.planForm = new CreatePlanForm(this.vm, this.planFormValidated);
    this.positionForm = new PlanPositionForm(
      this.vm,
      this.positionFormValidated
    );

    this.positionForm.addHook("realAX", this.realPositionChanged, this);
    this.positionForm.addHook("realAY", this.realPositionChanged, this);
    this.positionForm.addHook("realBX", this.realPositionChanged, this);
    this.positionForm.addHook("realBY", this.realPositionChanged, this);

    this.planForm.init();
  }

  public init() {
    this.vm.goBack = !Authorizator.canViewPlans();
    this.vm.createPlanButtonVisible = Authorizator.canCreatePlans();
    this.vm.deletePlanButtonVisible = Authorizator.canDeletePlans();
    this.vm.updatePlanButtonVisible = Authorizator.canEditPlans();
    this.vm.planEditLockDisabled = !Authorizator.canEditPlans();
    this.vm.newPlanImageVisible = Authorizator.canEditPlans();
  }

  // Data
  public get plan(): PlanData {
    return {
      id: NumericalId.fromString(this.vm.plans.selected),
      name: this.vm.name.value,
      offline: this.vm.offline.value,
      file: this.vm.planImage.value[0],
      pixelDistance: {
        a: {
          x: parseFloat(this.vm.pixelAX.value),
          y: parseFloat(this.vm.pixelAY.value)
        },
        b: {
          x: parseFloat(this.vm.pixelBX.value),
          y: parseFloat(this.vm.pixelBY.value)
        }
      },
      realDistance: {
        a: {
          x: parseFloat(this.vm.realAX.value),
          y: parseFloat(this.vm.realAY.value)
        },
        b: {
          x: parseFloat(this.vm.realBX.value),
          y: parseFloat(this.vm.realBY.value)
        }
      }
    };
  }

  public set selectedPlanId(planId: string) {
    const editPlan = !!planId;

    this.vm.planDataVisible = editPlan;
    this.vm.planVisible = editPlan;
    this.vm.coordinatesVisible = editPlan;
    this.vm.plans.selected = planId;
    this.vm.loadPlanRequest.error = "";
  }

  public set planImage(image: File) {
    this.vm.planImage.value.length = 0;
    if (!!image) {
      this.vm.planImage.value.push(image);
    }
    this.planForm.validateForm();
  }

  public set positionA(pos: Point) {
    this.vm.pixelAX.value = pos.x.toString();
    this.vm.pixelAY.value = pos.y.toString();
    this.calculatePlanScale();
    this.calculateDistortion();
  }

  public set positionB(pos: Point) {
    this.vm.pixelBX.value = pos.x.toString();
    this.vm.pixelBY.value = pos.y.toString();
    this.calculatePlanScale();
    this.calculateDistortion();
  }

  public set infoDialogVisible(visible: boolean) {
    this.vm.infoDialogVisible = visible;
  }

  // Responses
  public set loadPlansResponse(response: FormResponse<PlanCatalogRow[]>) {
    this.vm.loadPlansRequest = response;

    if (!response.loading && !response.error) {
      this.vm.plans.items = response.data.map(plan => ({
        text: plan.name,
        value: plan.id.id.toString()
      }));
    }
  }

  public set loadPlanResponse(response: FormResponse<PlanPayload>) {
    this.vm.loadPlanRequest = response;

    if (!response.loading && !response.error) {
      this.vm.selectedPlanImagePath = response.data.file.path;
      this.vm.planImageId = response.data.file.id.id.toString();
      this.positionForm.setFieldValue("name", response.data.name);
      this.positionForm.setFieldValue(
        "offline",
        response.data.offline.toString()
      );

      this.vm.pixelAX.value = response.data.pixelDistance.a.x.toString();
      this.vm.pixelAY.value = response.data.pixelDistance.a.y.toString();
      this.vm.pixelBX.value = response.data.pixelDistance.b.x.toString();
      this.vm.pixelBY.value = response.data.pixelDistance.b.y.toString();

      this.positionForm.setFieldValue(
        "realAX",
        response.data.realDistance.a.x.toString()
      );
      this.positionForm.setFieldValue(
        "realAY",
        response.data.realDistance.a.y.toString()
      );
      this.positionForm.setFieldValue(
        "realBX",
        response.data.realDistance.b.x.toString()
      );
      this.positionForm.setFieldValue(
        "realBY",
        response.data.realDistance.b.y.toString()
      );

      this.calculatePlanScale();
      this.calculateDistortion();
    }
  }

  public set createPlanResponse(response: FormResponse<PlanCatalogRow>) {
    this.vm.createPlanRequest = response;

    if (!response.loading && !response.error) {
      this.vm.plans.items.push({
        text: response.data.name,
        value: response.data.id.id.toString()
      });
      this.vm.plans.selected = response.data.id.id.toString();
    }
  }

  public set updatePlanResponse(response: FormResponse<void>) {
    this.vm.updatePlanDataRequest = response;

    if (!response.loading && !response.error) {
      this.vm.infoDialogVisible = true;
    }
  }

  public set replacePlanImageResponse(response: FormResponse<PlanImageData>) {
    this.vm.updatePlanDataRequest = response;
    this.vm.newPlanImage.loading = response.loading;

    if (response.success) {
      this.vm.planImageId = response.data.id.id.toString();
      this.vm.selectedPlanImagePath = response.data.path;
    }
  }

  public set deletePlanResponse(response: FormResponse<NumericalId>) {
    this.vm.deletePlanRequest = response;

    if (!response.loading && !response.error) {
      this.vm.plans.selected = "";
      this.vm.planDataVisible = false;
      this.vm.planVisible = false;
      this.vm.plans.items = this.vm.plans.items.filter(
        item => item.value !== response.data.id.toString()
      );
    }
  }

  // Methods
  public newPlan() {
    this.vm.planDataVisible = true;
    this.vm.planVisible = false;
    this.vm.coordinatesVisible = false;
    this.planForm.reset();
    this.vm.planImage.value = [];
    this.vm.plans.selected = "";
  }

  public togglePlanEditLock() {
    this.vm.editPlanLocked = !this.vm.editPlanLocked;
    this.vm.planEditLockButtonText = this.vm.editPlanLocked
      ? "Planbearbeitung entsperren"
      : "Planbearbeitung sperren";
  }

  // Helpers
  private calculatePlanScale() {
    const pixelDistance = MathUtils.distance(
      parseFloat(this.vm.pixelAX.value),
      parseFloat(this.vm.pixelAY.value),
      parseFloat(this.vm.pixelBX.value),
      parseFloat(this.vm.pixelBY.value)
    );
    const realDistance = MathUtils.distance(
      parseFloat(this.vm.realAX.value),
      parseFloat(this.vm.realAY.value),
      parseFloat(this.vm.realBX.value),
      parseFloat(this.vm.realBY.value)
    );

    this.vm.planScale = (realDistance / pixelDistance).toFixed(4).toString();
  }
  private calculateDistortion() {
    const pixelXDist = Math.abs(
      parseFloat(this.vm.pixelAX.value) - parseFloat(this.vm.pixelBX.value)
    );
    const pixelYDist = Math.abs(
      parseFloat(this.vm.pixelAY.value) - parseFloat(this.vm.pixelBY.value)
    );
    const realXDist = Math.abs(
      parseFloat(this.vm.realAX.value) - parseFloat(this.vm.realBX.value)
    );
    const realYDist = Math.abs(
      parseFloat(this.vm.realAY.value) - parseFloat(this.vm.realBY.value)
    );

    const distortion = Math.abs(
      (pixelXDist / realXDist / (pixelYDist / realYDist) - 1.0) * 100
    );

    const tooBigDistortion = isNaN(distortion) || distortion > 10;
    this.vm.planDistortion = distortion.toFixed(2);
    this.vm.updatePlanButtonDisabled = tooBigDistortion;
    this.vm.distortionErrorVisible = tooBigDistortion;
    this.vm.distortionColor = tooBigDistortion ? "error" : "black";
  }

  // Form validation
  private planFormValidated(context: any, valid: boolean) {
    valid = valid && context.planImage.value.length > 0;
    context.uploadPlanButtonDisabled = !valid;
  }

  private positionFormValidated(context: any, valid: boolean) {
    // context.vm.updatePlanButtonDisabled = !valid;
  }

  private realPositionChanged(context: any, value: string) {
    context.calculatePlanScale();
    context.calculateDistortion();
  }
}
