import { FileableEntity, FileableType } from "./FileEntity";

export class Fileable {
  public id?: number = 0;
  public type?: FileableType = "Ticket";

  public get exists() {
    return !!this.id;
  }

  public toEntity(): FileableEntity {
    return {
      id: this.id,
      type: this.type
    };
  }

  public from(entity?: FileableEntity) {
    this.id = entity?.id;
    this.type = entity?.type;
  }

  public static from(entity?: FileableEntity) {
    const instance = new Fileable();
    instance.from(entity);
    return instance;
  }
}
