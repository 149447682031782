import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { GraphQLConnection } from "@/gateways/graphql/connection/GraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import {
  convertGraphQlToTicketEntities,
  convertGraphQlToTicketEntity,
  TicketEntity
} from "../../entities/TicketEntity";
import { FileUtils } from "@/shared/utils/FileUtils";
import { TicketProvider } from "./TicketProvider";
import { ImageEntity } from "@/shared/project/images/ImageEntity";
import { LoadTicketsOptions } from "@/inspection/ticket/useCases/LoadTicketsUseCase";
import { Paginated } from "@/shared/datastructures/Paginated";
import { TicketDescription } from "../../dtos/TicketDtos";

export class TicketGraphQLProvider implements TicketProvider {
  public constructor(
    private connection: GraphQLConnection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    )
  ) {}

  public async loadTickets(
    options: LoadTicketsOptions
  ): Promise<Paginated<TicketEntity>> {
    const result = await this.connection.queryPaginated(
      "newTickets",
      20,
      options.page ?? 1,
      {
        client_id: options.client?.id,
        property_ids: options.properties?.map(p => p.id),
        plant_id: options.plant?.id,
        checklist_entry_template_id: options.plantChecklistEntry?.id,
        priorities: options.priorities,
        states: options.states,
        checklist_entry_id: options.missingChecklistEntryReference
          ? 0
          : undefined,
        count: 20,
        page: options.page ?? 1
      },
      [
        "id",
        "title",
        "description",
        "action",
        "priority",
        "maturity",
        {
          name: "property",
          fields: [
            "id",
            "name",
            { name: "address", fields: ["street", "city", "postcode"] }
          ]
        }
      ],
      options.search ?? ""
    );

    return new Paginated(
      convertGraphQlToTicketEntities(result.data),
      result.count
    );
  }

  public async loadTicket(id: number, syncId: string): Promise<TicketEntity> {
    const result = await this.connection.query(
      "newTicket",
      {
        id,
        sync_id: syncId
      },
      ["id", "sync_id", "title", "description", "priority"]
    );

    return convertGraphQlToTicketEntity(result.data);
  }

  public async checkTicketExistence(ticket: TicketEntity): Promise<boolean> {
    const result = await this.connection.query(
      "ticketExistence",
      { id: ticket.id },
      []
    );
    return result.data;
  }

  public async create(ticket: TicketEntity): Promise<number> {
    const result = await this.connection.mutation(
      "newCreateTicket",
      {
        input: {
          property_id: ticket.property?.id,
          sync_id: ticket.syncId,
          title: ticket.title,
          description: ticket.description,
          action: ticket.action,
          deadline: ticket.deadline,
          maturity: ticket.maturity,
          priority: ticket.priority,
          data: ticket.details,
          type: ticket.type,
          plant_id: ticket.plant?.id,
          checklist_entry_template_id: ticket.checklistEntryTemplateId,
          ticketable: !!ticket.ticketable?.id
            ? {
                id: ticket.ticketable?.id,
                type: ticket.ticketable?.type
              }
            : undefined,
          location: !!ticket.location?.plan?.id
            ? {
                plan_id: ticket.location?.plan?.id,
                x: ticket.location?.x,
                y: ticket.location?.y
              }
            : undefined,
          plant_field_sync: !!ticket.plantFieldsToSync
            ? JSON.stringify(ticket.plantFieldsToSync)
            : undefined
        }
      },
      []
    );

    return result.data;
  }

  public async changeDescription(
    id: number,
    newDescription: TicketDescription
  ): Promise<number> {
    const result = await this.connection.mutation(
      "changeTicketDescription",
      {
        input: {
          id,
          title: newDescription.title,
          description: newDescription.description,
          action: newDescription.action
        }
      },
      []
    );

    return result.data;
  }

  public async uploadImage(image: ImageEntity): Promise<number> {
    const imageFile = await FileUtils.fromUrl(
      image.path ?? "",
      "ticket_image.jpg",
      "image/jpg"
    );

    const result = await this.connection.mutation(
      "uploadTicketImage",
      {
        input: {
          ticket_id: image.fileable?.id,
          sync_id: image.syncId,
          image: null
        }
      },
      [],
      30000,
      {
        path: "input.image",
        file: imageFile
      }
    );

    return result.data;
  }

  public async setState(ticketId: number, state: string): Promise<number> {
    const result = await this.connection.mutation(
      "setTicketState",
      {
        input: { ticket_id: ticketId, state }
      },
      []
    );

    return result.data;
  }

  public async connectPerson(
    ticketId: number,
    personId: number,
    role: string
  ): Promise<number> {
    const result = await this.connection.mutation(
      "connectPersonToTicket",
      {
        input: { ticket_id: ticketId, person_id: personId, role }
      },
      []
    );

    return result.data;
  }

  public async assignPlant(ticketId: number, plantId: number): Promise<number> {
    const result = await this.connection.mutation(
      "assignPlantToTicket",
      {
        input: { ticket_id: ticketId, plant_id: plantId }
      },
      []
    );

    return result.data;
  }

  public async disconnectPerson(
    ticketId: number,
    role: string
  ): Promise<number> {
    const result = await this.connection.mutation(
      "disconnectPersonFromTicket",
      {
        input: { ticket_id: ticketId, role }
      },
      []
    );

    return result.data;
  }

  public async unassignPlant(ticketId: number): Promise<number> {
    const result = await this.connection.mutation(
      "unassignPlantFromTicket",
      {
        input: { ticket_id: ticketId }
      },
      []
    );

    return result.data;
  }

  public delete(ticket: TicketEntity): Promise<number> {
    throw new Error("Method not implemented.");
  }
}
