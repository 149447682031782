import { StringUtils } from "@/shared/utils/StringUtils";
import { Fileable } from "./Fileable";
import { FileEntity } from "./FileEntity";

export class File {
  public name?: string = "";
  public id?: number = 0;
  public syncId?: string = "";
  public path?: string = "";
  public fileable?: Fileable = new Fileable();
  public context?: string = "";

  public constructor() {
    this.syncId = StringUtils.uuid();
  }

  public get exists() {
    return !!this.id;
  }

  public clear() {
    this.path = "";
  }

  public toEntity(): FileEntity {
    return {
      id: this.id,
      syncId: this.syncId,
      name: this.name,
      path: this.path,
      fileable: this.fileable?.toEntity(),
      context: this.context
    };
  }

  public from(entity?: FileEntity) {
    this.id = entity?.id;
    this.syncId = entity?.syncId ?? StringUtils.uuid();
    this.name = entity?.name;
    this.path = entity?.path;
    this.fileable = Fileable.from(entity?.fileable);
    this.context = entity?.context;
  }

  public static from(entity?: FileEntity) {
    const instance = new File();
    instance.from(entity);
    return instance;
  }
}
