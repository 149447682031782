import {
  IPropertyLocator,
  PropertyLocation
} from "../interactors/PropertyLocator";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import {
  DocumentService,
  DocumentMetaDescription
} from "@/document/services/DocumentService";
import { UserSettings } from "@/storage/UserSettings";
import { NumericalId } from "@/datastructures/NumericalId";
import {
  ISettingsManager,
  AppSettings
} from "@/admin/interactors/SettingsManager";
import { IAuthenticator } from "../interactors/Authenticator";

export class MapController {
  public constructor(
    private presenter: IMapPresenter,
    private propertyLocator: IPropertyLocator,
    private documentService: DocumentService,
    private settingService: ISettingsManager,
    private authenticator: IAuthenticator
  ) {}

  public init(action: string) {
    this.handleActions(action);
    this.loadPropertyLocations("");
    this.loadDocumentMeta();
    this.loadPropertyDocumentMeta();
    this.loadSettings();
    this.loadUserPermissions();
  }

  public loadPropertyLocations(search: string) {
    const clientId = UserSettings.getClientId();

    NumericalId.onValidId(clientId, id => {
      const request = this.propertyLocator.loadPropertyLocations(id, search);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadPropertyLocationsResponse = response),
        "Gebäudekoordinaten konnten nicht geladen werden"
      );
    });
  }

  // Input
  public propertySearchChanged(search: string) {
    this.presenter.propertySearch = search;
  }

  // Actions
  public propertySearchSubmitted(search: string) {
    this.presenter.propertySearch = search;
    this.loadPropertyLocations(search);
  }

  public mapMarkerClicked(marker: PropertyLocation) {
    this.presenter.selectedProperty = marker.id;
  }

  private loadDocumentMeta() {
    const request = this.documentService.getAllGeneralDocumentMeta();

    FormRequestHandler.handle(
      request,
      response => (this.presenter.loadDocumentMetaResponse = response),
      "Dokumenten-Metadaten konnten nicht geladen werden"
    );
  }

  private loadPropertyDocumentMeta() {
    const request = this.documentService.getAllPropertyDocumentMeta();

    FormRequestHandler.handle(
      request,
      response => (this.presenter.loadPropertyDocumentMetaResponse = response),
      "Dokumenten-Metadaten für Gebäude konnten nicht geladen werden"
    );
  }

  private loadSettings() {
    NumericalId.onValidId(UserSettings.getUserId(), id => {
      const request = this.settingService.loadSettings(id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadSettingsResponse = response),
        "Einstellungen konnten nicht geladen werden"
      );
    });
  }

  private loadUserPermissions() {
    NumericalId.onValidId(UserSettings.getUserId(), id => {
      const request = this.authenticator.getPermissions(id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadPermissionsResponse = response),
        "Berechtigungen konnten nicht geladen werden"
      );
    });
  }

  private handleActions(action: string) {
    switch (action) {
      case "impersonate":
        this.presenter.impersonate();
        break;
      case "depersonate":
        this.presenter.depersonate();
        break;
    }
  }
}

export interface IMapPresenter {
  selectedProperty: number;
  propertySearch: string;

  loadPropertyLocationsResponse: FormResponse<PropertyLocation[]>;
  loadDocumentMetaResponse: FormResponse<DocumentMetaDescription>;
  loadPropertyDocumentMetaResponse: FormResponse<DocumentMetaDescription>;
  loadSettingsResponse: FormResponse<AppSettings>;
  loadPermissionsResponse: FormResponse<string>;

  impersonate(): void;
  depersonate(): void;
}
