
import Vue from "vue";
import Component from "vue-class-component";

@Component<Combobox>({
  props: {
    value: {
      type: Object,
      default() {
        return { label: "Label", value: "", error: "", items: [] };
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    maxDropDownHeight: {
      type: Number,
      default: 304
    }
  }
})
export default class Combobox extends Vue {
  public blur() {
    (this.$refs.input as any).blur();
  }
}
