export class FormResponse<TResponse> {
  public data: TResponse = {} as TResponse;
  public loading: boolean = false;
  public error: string = "";
  public params: any;

  public get success() {
    return !this.loading && !this.error;
  }

  public get fail() {
    return !this.loading && !!this.error;
  }
}
