
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { ChecklistEntryCollection } from "@/inspection/shared/models/ChecklistEntryCollection";
import { SortEntriesUseCase } from "../useCases/SortEntriesUseCase";
import { UnsortEntriesUseCase } from "../useCases/UnsortEntriesUseCase";

@Component<ChecklistEntrySortButton>({
  components: {}
})
export default class ChecklistEntrySortButton extends Vue {
  @Prop({ type: Object, default: () => new ChecklistEntryCollection() })
  protected readonly entries!: ChecklistEntryCollection;

  protected entrySorter = new SortEntriesUseCase();
  protected entryUnsorter = new UnsortEntriesUseCase();

  protected get text() {
    return this.entries.sorted
      ? "Sortierung aufheben"
      : "Alphabetisch sortieren";
  }

  protected get icon() {
    return this.entries.sorted ? "shuffle" : "sort";
  }

  protected get loading() {
    return this.entrySorter.loads || this.entryUnsorter.loads;
  }

  protected get error() {
    return this.entrySorter.error || this.entryUnsorter.error;
  }

  protected sortOrUnsort() {
    if (this.entries.sorted) {
      this.entryUnsorter.do(this.entries);
    } else {
      this.entrySorter.do(this.entries);
    }

    this.$emit("click");
  }
}
