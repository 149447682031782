import {
  AppActions,
  AppModules,
  AuthorizatorGateway
} from "@/common/interactors/Authorizator";
import { storageKeys } from "@/data/storageKeys";
import { LocalStorage } from "@/storage/LocalStorage";
import { UserSettings } from "@/storage/UserSettings";

export class AuthorizatorStorageGateway implements AuthorizatorGateway {
  public checkPermission(type: AppModules, operation: AppActions) {
    const rawPermissions = UserSettings.loggedIn()
      ? new LocalStorage().get(storageKeys.permissions)
      : new LocalStorage().get(storageKeys.guestPermissions);

    if (!!rawPermissions) {
      const permission = JSON.parse(rawPermissions);

      return !!permission[type] && !!permission[type][operation];
    }
    return false;
  }
}
