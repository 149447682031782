import { storageKeys } from "@/data/storageKeys";
import { LocalStorage } from "@/storage/LocalStorage";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { PlanLocationEntity } from "./PlanLocationEntity";
import { PlanLocationProvider } from "./PlanLocationProvider";

export class PlanLocationLocalProvider implements PlanLocationProvider {
  public constructor(private store: SimpleStringStorage = new LocalStorage()) {}

  public async loadLastLocation(): Promise<PlanLocationEntity> {
    const location = this.store.getJson(storageKeys.lastPlanLocation);
    return location as PlanLocationEntity;
  }

  public async saveLastLocation(location: PlanLocationEntity): Promise<number> {
    if (location.plan) {
      location.plan.filePath = "";
    }

    this.store.setJson(storageKeys.lastPlanLocation, location);
    return 0;
  }
}
