
import Vue from "vue";
import Component from "vue-class-component";

@Component<ResponsiveIconButton>({
  components: {
    IconButton: () => import("./IconButton.vue")
  },
  props: {
    buttonText: {
      type: String,
      default: ""
    },
    height: {
      type: Number,
      default: 36
    },
    tooltipWidth: {
      type: Number,
      default: 100
    },
    tooltipText: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "primary"
    },
    outlined: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ""
    }
  }
})
export default class ResponsiveIconButton extends Vue {
  // private tooltipVisible: boolean = false;

  private click(skipTooltip: boolean = false) {
    // if (skipTooltip || this.tooltipVisible) {
    this.$emit("click");
    // return;
    // }
    // this.tooltipVisible = true;
  }

  // private blur() {
  //   this.tooltipVisible = false;
  // }

  private get heightStyle() {
    const height = this.$props.height;
    return height < 0 ? "height: 100%;" : "height: " + height + "px;";
  }
}
