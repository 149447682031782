import { FormResponse } from "./FormResponse";
import { IndexedFormResponse } from "./IndexedFormResponse";

export class FormRequestHandler {
  public static async handleIndexed<TResponse>(
    index: number,
    request: Promise<TResponse>,
    responseCallback: (response: IndexedFormResponse<TResponse>) => void,
    errorSlug: string = "error",
    params: any = {}
  ) {
    const wrappedCallback = (response: FormResponse<TResponse>) => {
      const indexedResponse: IndexedFormResponse<TResponse> = {
        index: index.toString(),
        loading: response.loading,
        error: response.error,
        data: response.data,
        params: response.params,
        success: response.success,
        fail: response.fail
      };

      responseCallback(indexedResponse);
    };
    await this.handle(request, wrappedCallback, errorSlug, params);
  }

  public static async handle<TResponse>(
    request: Promise<TResponse>,
    responseCallback: (response: FormResponse<TResponse>) => void,
    errorSlug: string = "error",
    params: any = {}
  ) {
    const formResponse = new FormResponse<TResponse>();

    formResponse.loading = true;
    formResponse.error = "";
    formResponse.params = params;
    responseCallback(formResponse);

    await request
      .then(response => {
        formResponse.data = response;
        formResponse.loading = false;
        formResponse.params = params;
      })
      .catch(reason => {
        if (process.env.VUE_APP_CONSOLE_LOG === "true") {
          // tslint:disable-next-line: no-console
          console.log(reason);
        }
        if (!!errorSlug) {
          formResponse.error = errorSlug;
        } else if (!!reason && !!reason.message) {
          formResponse.error = reason.message;
        }
        formResponse.loading = false;
        formResponse.params = params;
      });

    responseCallback(formResponse);
  }
}
