
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import PlantTypeList from "./PlantTypeList.vue";
import PlantFieldCategoryList from "./PlantFieldCategoryList.vue";
import PlantChecklistEntryTable from "./PlantChecklistEntryTable.vue";

import { Plant } from "../../shared/models/Plant";
import { UpdatePlantStructureForm as Form } from "../forms/UpdatePlantStructureForm";

@Component<UpdatePlantStructureForm>({
  components: {
    PlantTypeList,
    PlantFieldCategoryList,
    PlantChecklistEntryTable
  }
})
export default class UpdatePlantStructureForm extends Vue {
  @Prop({ type: Object, default: () => new Plant() })
  protected readonly plant!: Plant;

  protected plantCopy = new Plant();
  protected form = new Form();

  protected get plantTypes() {
    return this.$refs.plantTypes as PlantTypeList;
  }

  protected get fieldCategories() {
    return this.$refs.fieldCategories as PlantFieldCategoryList;
  }

  protected get checklistEntries() {
    return this.$refs.checklistEntries as PlantChecklistEntryTable;
  }

  protected async updateStructure() {
    if (await this.plantCopy.updateStructure()) {
      this.$emit("update", this.plantCopy);
    }
  }

  protected created() {
    this.plantCopy = this.plant.copy();
    this.form.addSubForm(undefined, "fieldCategories");
    this.form.addSubForm(undefined, "checklistEntries");
  }
}
