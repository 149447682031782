import { Paginated } from "@/shared/datastructures/Paginated";
import { PlanLocationEntity } from "@/shared/project/planLocation/PlanLocationEntity";
import { Plant } from "../../dtos/Plant";
import {
  GeneralPlantData,
  PlantEntity,
  PlantStructure
} from "../../entities/PlantEntity";
import { PlantGraphQLProvider } from "./PlantGraphQLProvider";
import { PlantProvider } from "./PlantProvider";

export interface PlantRepository {
  getPlant(id: number): Promise<PlantEntity>;
  getPlantLocation(id: number): Promise<PlanLocationEntity>;
  getChildPlants(id: number): Promise<PlantEntity[]>;

  getBasePlants(
    propertyId: number | number[],
    distinctByType: boolean
  ): Promise<Plant[]>;

  getPlantsPaginated(
    propertyIds: number[],
    search?: string,
    includePropertyPartPlants?: boolean,
    page?: number,
    count?: number
  ): Promise<Paginated<PlantEntity>>;

  createPlant(
    plant: PlantEntity,
    propertyId: number,
    partId?: number,
    parentPlantId?: number,
    siblingPartId?: number
  ): Promise<string>;

  updateGeneralData(id: number, data: GeneralPlantData): Promise<string>;
  updateData(id: number, data: string): Promise<string>;
  updateLocation(id: number, location: PlanLocationEntity): Promise<string>;
  updateStructure(id: number, structure: PlantStructure): Promise<string>;
  uploadPlantImage(id: number, image: File): Promise<number>;

  deletePlantImage(id: number): Promise<number>;
  deletePlant(id: number): Promise<string>;
}

export class DefaultPlantRepository implements PlantRepository {
  public constructor(
    private webProvider: PlantProvider = new PlantGraphQLProvider()
  ) {}

  public getPlant(id: number): Promise<PlantEntity> {
    return this.webProvider.loadPlant(id);
  }

  public getPlantLocation(id: number): Promise<PlanLocationEntity> {
    return this.webProvider.loadPlantLocation(id);
  }

  public getChildPlants(id: number): Promise<PlantEntity[]> {
    return this.webProvider.loadChildPlants(id);
  }

  public getBasePlants(
    propertyId: number | number[],
    distinctByType: boolean
  ): Promise<Plant[]> {
    return this.webProvider.loadBasePlants(propertyId, distinctByType);
  }

  public getPlantsPaginated(
    propertyIds: number[],
    search?: string,
    includePropertyPartPlants?: boolean,
    page?: number,
    count?: number
  ): Promise<Paginated<PlantEntity>> {
    return this.webProvider.getPlantsPaginated(
      propertyIds,
      includePropertyPartPlants,
      search,
      page,
      count
    );
  }

  public createPlant(
    plant: PlantEntity,
    propertyId: number,
    partId?: number,
    parentPlantId?: number,
    siblingPartId?: number
  ): Promise<string> {
    return this.webProvider.createPlant(
      plant,
      propertyId,
      partId,
      parentPlantId,
      siblingPartId
    );
  }

  public updateGeneralData(
    id: number,
    data: GeneralPlantData
  ): Promise<string> {
    return this.webProvider.updateGeneralData(id, data);
  }

  public updateData(id: number, data: string): Promise<string> {
    return this.webProvider.updateData(id, data);
  }

  public updateStructure(
    id: number,
    structure: PlantStructure
  ): Promise<string> {
    return this.webProvider.updateStructure(id, structure);
  }

  public updateLocation(
    id: number,
    location: PlanLocationEntity
  ): Promise<string> {
    return this.webProvider.updateLocation(id, location);
  }

  public uploadPlantImage(id: number, image: File): Promise<number> {
    return this.webProvider.uploadPlantImage(id, image);
  }

  public deletePlantImage(id: number): Promise<number> {
    return this.webProvider.deletePlantImage(id);
  }

  public deletePlant(id: number): Promise<string> {
    return this.webProvider.deletePlant(id);
  }
}
