import { InMemoryCache } from "@/shared/datastructures/InMemoryCache";
import { Existence } from "@/shared/types/Existence";
import { EmployeeEntity } from "../../entities/EmployeeEntity";
import { RoleEntity } from "../../entities/RoleEntity";
import { EmployeeGraphQLProvider } from "./EmployeeGraphQLProvider";
import { EmployeeProvider } from "./EmployeeProvider";

export interface EmployeeRepository {
  getEmployees(clientId: number): Promise<EmployeeEntity[]>;
  getEmployeeSelection(clientId: number): Promise<EmployeeEntity[]>;
  getRoles(): Promise<RoleEntity[]>;
  checkExistence(email: string): Promise<Existence>;

  createEmployee(employee: EmployeeEntity): Promise<number>;

  reactivateEmployee(id: number): Promise<number>;

  deleteEmployee(id: number): Promise<number>;
}

export class DefaultEmployeeRepository {
  private cachedEmployeeRequest = new InMemoryCache<
    Promise<EmployeeEntity[]>
  >();
  private cachedRoleRequest = new InMemoryCache<Promise<RoleEntity[]>>();

  public constructor(
    private webProvider: EmployeeProvider = new EmployeeGraphQLProvider()
  ) {}

  public getEmployees(clientId: number): Promise<EmployeeEntity[]> {
    return this.webProvider.loadEmployees(clientId);
  }

  public getEmployeeSelection(clientId: number): Promise<EmployeeEntity[]> {
    return this.cachedEmployeeRequest.hasCache(clientId)
      ? this.cachedEmployeeRequest.cached(clientId)
      : this.cachedEmployeeRequest.cache(
          this.webProvider.loadEmployeeSelection(clientId),
          { key: clientId }
        );
  }

  public getRoles(): Promise<RoleEntity[]> {
    return this.cachedRoleRequest.hasCache()
      ? this.cachedRoleRequest.cached()
      : this.cachedRoleRequest.cache(this.webProvider.loadRoles());
  }

  public checkExistence(email: string): Promise<Existence> {
    return this.webProvider.checkExistence(email);
  }

  public createEmployee(employee: EmployeeEntity): Promise<number> {
    return this.webProvider.createEmployee(employee);
  }

  public reactivateEmployee(id: number): Promise<number> {
    return this.webProvider.reactivateEmployee(id);
  }

  public deleteEmployee(id: number): Promise<number> {
    return this.webProvider.deleteEmployee(id);
  }
}
