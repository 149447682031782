import { storageKeys } from "@/data/storageKeys";
import { RequestModel } from "@/shared/model/RequestModel";
import { LocalStorage } from "@/storage/LocalStorage";
import { PropertyEntity } from "../entities/PropertyEntity";
import { Client } from "./Client";
import { Plan } from "./Plan";
import { PlanCollection } from "./PlanCollection";

export class Property extends RequestModel<PropertyEntity> {
  public id?: number = 0;
  public name?: string = "";
  public street?: string = "";
  public zip?: string = "";
  public city?: string = "";
  public client?: Client;
  public planList?: PlanCollection;

  public get exists() {
    return !!this.id;
  }

  public get plans() {
    return this.planList?.plans ?? [];
  }
  public set plans(plans: Plan[]) {
    this.planList = PlanCollection.from(plans);
  }

  public get hasPlans() {
    return this.plans.length > 0;
  }

  public get label() {
    return `${this.name}: ${this.address}`;
  }

  public get address() {
    return `${this.street}, ${this.zip} ${this.city}`;
  }

  public hasPlan(plan?: Plan) {
    return !!this.plans.find(p => p.id === plan?.id);
  }

  public toEntity() {
    return {
      id: this.id,
      name: this.name,
      street: this.street,
      zip: this.zip,
      city: this.city
    };
  }

  public from(entity?: PropertyEntity) {
    this.id = entity?.id;
    this.name = entity?.name;
    this.street = entity?.street;
    this.zip = entity?.zip;
    this.city = entity?.city;
  }

  public static from(entity?: PropertyEntity) {
    const model = new Property();
    model.from(entity);
    return model;
  }

  public static saveLast(property?: Property) {
    if (property) {
      const store = new LocalStorage();
      store.setJson(storageKeys.lastProperty, property);
    }
  }

  public static loadLast() {
    const store = new LocalStorage();
    const entity = store.getJson(storageKeys.lastProperty) as PropertyEntity;
    return Property.from(entity);
  }
}
