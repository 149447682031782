import { PropertyModel } from "@/property/property/models/PropertyModel";
import { Property } from "@/property/shared/dtos/Property";

export class HeaderModel {
  public selectedProperties: PropertyModel[] = [];

  public get propertyName() {
    if (this.hasOneSelectedProperty) {
      if (this.selectedProperties.length > 1) {
        return `${this.selectedProperties.length} gewählte Gebäude`;
      } else {
        return this.selectedProperties[0].name;
      }
    } else {
      return "";
    }
  }

  public get propertyAddress() {
    if (this.hasOneSelectedProperty && this.selectedProperties.length === 1) {
      return this.selectedProperties[0].address;
    } else {
      return "";
    }
  }

  public get hasOneSelectedProperty() {
    return this.selectedProperties && this.selectedProperties.length > 0;
  }

  public init(selectedProperties: Property[]) {
    this.selectedProperties = selectedProperties.map(p =>
      PropertyModel.from(p)
    );
  }
}
