
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import AssignmentForm from "@/inspection/assignment/widgets/AssignmentForm.vue";

import { TableHeader } from "@/shared/datastructures/TableHeader";
import { PlantCollection } from "../../shared/models/PlantCollection";
import { Plant } from "@/property/shared/models/Plant";
import { MathUtils } from "@/shared/utils/MathUtils";

@Component<PlantTable>({
  components: { AssignmentForm }
})
export default class PlantTable extends Vue {
  @Prop({ type: Array, required: true })
  protected readonly propertyIds!: number[];

  protected plants = new PlantCollection();
  protected selectedPlants = new PlantCollection();
  protected createAssignmentDialogVisible = false;

  protected headers: TableHeader[] = [
    { text: "Name", value: "name" },
    { text: "Typen", value: "types", align: "center" },
    { text: "Beschreibung", value: "description" },
    { text: "Standort", value: "location" },
    { text: "Aktionen", value: "actions", align: "right", width: "1%" }
  ];

  protected get noPlantsText() {
    return this.search
      ? "Keine Anlagen gefunden"
      : "Noch keine Anlagen vorhanden";
  }

  protected get page() {
    return parseInt(this.$route.query.page?.toString() ?? "1", 10);
  }

  protected get search() {
    return this.$route.query.search?.toString() ?? "";
  }

  protected get propertyPartPlants() {
    return MathUtils.parseBoolean(this.$route.query.propertyPartPlants);
  }

  protected changePage(page: number) {
    this.$router.push({
      query: { ...this.$route.query, page: page.toString() }
    });
  }

  protected plantTypesAsString(plant: Plant) {
    return (
      plant.types
        ?.map(t => t.name)
        ?.filter(n => !!n)
        ?.join(", ") ?? ""
    );
  }

  protected propertyOfPlant(plant: Plant) {
    return plant.property?.name ?? "";
  }

  protected locationOfPlant(plant: Plant) {
    return plant.location?.plan?.name ?? "";
  }

  protected editPlant(plant: Plant) {
    this.$router.push({
      name: "plant",
      params: { plantId: plant.id?.toString() ?? "" }
    });
  }

  protected toggleAllPlants(data: { items: Plant[]; value: boolean }) {
    data.items.forEach(i => this.plantSelected({ item: i, value: data.value }));
  }

  protected plantSelected(data: { item: Plant; value: boolean }) {
    if (data.value) {
      this.selectedPlants.add(data.item);
    } else {
      this.selectedPlants.remove(data.item);
    }
  }

  protected created() {
    this.reload();
  }

  protected reload() {
    this.plants.init({
      page: this.page,
      propertyIds: this.propertyIds,
      search: this.search,
      includePropertyPartPlants: this.propertyPartPlants
    });

    this.plants.load();
  }
}
