
import Vue from "vue";
import Component from "vue-class-component";

import Textfield from "@/components/form/Textfield.vue";
import Value from "@/components/basic/Value.vue";

@Component<EditableTextfield>({
  components: {
    Textfield,
    Value
  },
  props: {
    value: {
      type: Object,
      default() {
        return { label: "Label", value: "", error: "" };
      }
    },
    val: {
      type: [String, Number],
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    editing: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    filled: {
      type: Boolean,
      default: false
    },
    displayTitle: {
      type: Boolean,
      default: true
    },
    boldValue: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  }
})
export default class EditableTextfield extends Vue {
  private get transformedValue() {
    const value = this.$props.val || this.$props.value.value;
    return {
      label: this.$props.label || this.$props.value.label,
      value: !!value ? value : undefined
    };
  }
}
