
import Vue from "vue";
import Component from "vue-class-component";

import Select from "@/components/form/Select.vue";
import Value from "@/components/basic/Value.vue";

@Component<EditableSelect>({
  components: {
    Select,
    Value
  },
  props: {
    value: {
      type: Object,
      default() {
        return { label: "Label", value: "", error: "" };
      }
    },
    val: {
      type: [String, Number],
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    items: {
      type: Array,
      default: undefined
    },
    editing: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    filled: {
      type: Boolean,
      default: false
    },
    displayTitle: {
      type: Boolean,
      default: true
    },
    displayValue: {
      type: Boolean,
      default: false
    },
    boldValue: {
      type: Boolean,
      default: false
    }
  }
})
export default class EditableSelect extends Vue {
  protected get translatedValue() {
    const props = this.$props;
    const value = props.value;
    const items = props.items ?? value.items;
    const selected = props.val ?? value.selected ?? value.value;

    let item = {
      value: selected,
      text: selected
    };

    if (items.length > 0) {
      const matchingValue = items.find(
        (selectionItem: any) => selectionItem.value === selected
      );
      if (matchingValue) {
        item = matchingValue;
      }
    }

    return {
      label: props.label || value.label,
      value: props.displayValue ? item.value : item.text
    };
  }
}
