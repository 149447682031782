import { RoleModel } from "@/property/client/models/RoleModel";
import { ServerResponse } from "@/shared/datastructures/Response";
import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { RoleEntity } from "../../entities/RoleEntity";
import { Role } from "../../models/Role";
import { PropertyReportContainer } from "../../repositories/PropertyRepoContainer";

export class RoleSelectVm extends RequestModel<RoleEntity[]> {
  public roles: Role[] = [];

  public loadResponse = new ServerResponse<RoleEntity[]>();

  public get roleSelectionList() {
    return this.roles.map(r => ({
      value: r,
      text: r.name
    }));
  }

  public load() {
    RequestHandler.handleModel(
      () => PropertyReportContainer.employeeRepo.getRoles(),
      this,
      this.loadResponse
    );
  }

  public from(data: RoleEntity[]): void {
    this.roles = data.map(r => Role.from(r));
  }
}
