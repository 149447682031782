import moment from "moment";
import { Duration } from "../datastructures/Duration";
moment.locale("de");

export class DateUtils {
  public static get min() {
    return new Date(-8640000000000000);
  }
  public static get max() {
    return new Date(8640000000000000);
  }

  public static format(
    date: string | Date | number,
    outputFormat = "DD. MMM YYYY"
  ) {
    return moment(date).format(outputFormat);
  }

  public static isoDate(date: string | Date | number | undefined) {
    if (date) {
      return this.format(date, "YYYY-MM-DD");
    } else {
      return undefined;
    }
  }

  public static toDate(date: string | Date | number) {
    const d = moment(date).toDate();
    return d;
  }

  public static beginningOfDay(date = new Date()) {
    return moment(date)
      .startOf("day")
      .toDate();
  }

  public static add(duration: Duration, date = new Date()) {
    if (duration.milliseconds) {
      date.setMilliseconds(date.getMilliseconds() + duration.milliseconds);
    }

    if (duration.seconds) {
      date.setSeconds(date.getSeconds() + duration.seconds);
    }

    if (duration.minutes) {
      date.setMinutes(date.getMinutes() + duration.minutes);
    }

    if (duration.hours) {
      date.setHours(date.getHours() + duration.hours);
    }

    if (duration.days) {
      date.setDate(date.getDate() + duration.days);
    }

    if (duration.weeks) {
      date.setDate(date.getDate() + duration.weeks * 7);
    }

    if (duration.months) {
      date = moment(date)
        .add(duration.months, "months")
        .toDate();
    }

    if (duration.years) {
      date.setFullYear(date.getFullYear() + duration.years);
    }

    return date;
  }
}
