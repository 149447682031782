
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import DynamicFormBuilder from "@/shared/form/DynamicFormBuilder.vue";

import { TicketCreationForm } from "./TicketCreationWorkflow.vue";

import { Ticket } from "@/inspection/shared/models/Ticket";
import { DynamicForm } from "@/shared/form/DynamicForm";
import { TicketTypeFormGenerator } from "@/inspection/shared/settings/TicketTypeFormGenerator";
import { InspectionSettingsContainer } from "@/inspection/shared/settings/InspectionSettingsContainer";
import { Plant } from "@/property/shared/models/Plant";
import { PlantField } from "@/property/shared/models/PlantField";
import { FormField } from "@/shared/form/FormData";
import { TicketType } from "@/inspection/shared/settings/TicketTypeSettings";

enum SyncDirection {
  FromPlantToTicket,
  FromTicketToPlant
}

@Component<TicketDetailsForm>({
  components: {
    DynamicFormBuilder
  }
})
export default class TicketDetailsForm extends Vue
  implements TicketCreationForm {
  @Prop({
    default() {
      return new Ticket();
    }
  })
  protected readonly ticket!: Ticket;
  @Prop() protected readonly ticketType!: string;

  protected form = new DynamicForm({});
  protected ticketTypeSetting?: TicketType;

  @Watch("ticket")
  protected onTicketChanged() {
    this.loadTicketPlant();
  }

  @Watch("ticketType")
  protected onTicketTypeChanged() {
    this.loadTicketTypeSetting();
    this.buildForm();
    this.loadTicketPlant();
  }

  protected get plant() {
    return this.ticket.plant ?? new Plant();
  }

  protected get formFieldsWithInfoOrPlantField() {
    return Object.values(this.form.fields).filter(
      f =>
        this.displaySyncButton(f.key) || this.ticketFieldHasDescription(f.key)
    );
  }

  protected displaySyncButton(fieldKey: string) {
    return (
      this.plant.hasFieldWithKey(fieldKey) || this.shouldSyncToPlant(fieldKey)
    );
  }

  protected shouldSyncToPlant(fieldKey: string) {
    const ticketField = this.getTicketField(fieldKey);

    if (ticketField) {
      return ticketField.sync === "toPlant" || ticketField.sync === "both";
    } else {
      return false;
    }
  }

  protected ticketFieldHasDescription(fieldKey: string) {
    return !!this.getTicketField(fieldKey)?.info;
  }

  protected getTicketField(fieldKey: string) {
    return this.ticketTypeSetting?.fields.find(f => f.key === fieldKey);
  }

  protected buildSyncOptions(formField: FormField) {
    const plantField = this.plant.getFieldByKey(formField.key);
    const plantValue = this.plant.getDataOfField(plantField?.id ?? "");
    const fieldValue = formField.value;

    return [
      {
        text: !!plantField
          ? `Anlagenwert '${plantValue}' laden`
          : "Anlagenwert konnte nicht geladen werden",
        value: SyncDirection.FromPlantToTicket,
        disabled: !plantField
      },
      {
        text: this.ticket.hasPlantFieldToSync(formField.key)
          ? `Wert '${fieldValue}' in Anlage nicht speichern`
          : `Wert '${fieldValue}' in Anlage speichern`,
        value: SyncDirection.FromTicketToPlant
      }
    ];
  }

  protected syncValue(
    syncDirection: SyncDirection,
    formField: FormField,
    plantField: PlantField
  ) {
    if (syncDirection === SyncDirection.FromPlantToTicket) {
      this.loadValueFromPlant(formField, plantField);
    } else {
      if (!this.ticket.hasPlantFieldToSync(formField.key)) {
        this.saveValueToPlant(formField);
      } else {
        this.removeValueToPlant(formField);
      }
    }
  }

  public validate() {
    this.emitValidate();
    return this.form.isValid();
  }

  public reset() {
    this.form.reset();
  }

  protected setTicketDetails(details: unknown, changedFieldName: string) {
    const detailsAsJson = JSON.stringify(details);
    this.ticket.updateDetails(detailsAsJson);
    this.autoSaveValueToPlant(this.form.getField(changedFieldName));
    this.emitValidate();
  }

  protected emitValidate() {
    this.$emit("validate", this.form.isValid());
  }

  protected created() {
    this.loadTicketTypeSetting();
    this.buildForm();
    this.loadTicketPlant();
  }

  protected loadTicketTypeSetting() {
    this.ticketTypeSetting = InspectionSettingsContainer.ticketTypes.getTicketType(
      this.ticketType
    );

    this.autoLoadValuesFromPlant();
  }

  protected buildForm() {
    if (this.ticketTypeSetting) {
      const formGenerator = new TicketTypeFormGenerator(this.ticketTypeSetting);
      this.form = formGenerator.generateForm();
    }
  }

  protected async loadTicketPlant() {
    if (this.ticket.hasPlant && (await this.ticket.plant!.load())) {
      this.autoLoadValuesFromPlant();
      this.autoSaveValuesToPlant();
    }
  }

  protected autoLoadValuesFromPlant() {
    if (this.ticketTypeSetting) {
      for (const field of this.ticketTypeSetting.fields) {
        if (
          this.plant.hasFieldWithKey(field.key) &&
          (field.sync === "fromPlant" || field.sync === "both")
        ) {
          this.loadValueFromPlant(
            this.form.getField(field.key),
            this.plant.getFieldByKey(field.key)!
          );
        }
      }
    }
  }

  protected autoSaveValuesToPlant() {
    if (this.ticketTypeSetting) {
      for (const field of this.ticketTypeSetting.fields) {
        this.saveTicketFieldToPlant(this.form.getField(field.key));
      }
    }
  }

  protected autoSaveValueToPlant(formField: FormField) {
    this.saveTicketFieldToPlant(formField);
  }

  protected saveTicketFieldToPlant(formField: FormField) {
    if (this.shouldSyncToPlant(formField.key)) {
      this.saveValueToPlant(formField);
    }
  }

  protected loadValueFromPlant(formField: FormField, plantField: PlantField) {
    const plantValue = this.plant.getDataOfField(plantField.id ?? "");
    this.form.setFieldValue(formField.key, plantValue);
  }

  protected saveValueToPlant(formField: FormField) {
    const fieldValue = formField.value;

    this.ticket.addPlantFieldToSync(formField.key, fieldValue);

    formField.hint = this.plant.hasFieldWithKey(formField.key)
      ? `Wert '${fieldValue}' wird bei Erstellung des Tickets in der Anlage gespeichert`
      : `Die Anlagendaten konnten nicht geladen werden. Bei der Erstellung des Tickets wird trotzdem versucht den Wert '${fieldValue}' in der Anlage zu speichern`;
  }

  protected removeValueToPlant(formField: FormField) {
    this.ticket.removePlantFieldToSync(formField.key);
    formField.hint = "";
  }
}
