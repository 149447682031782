import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";

export interface ReportWaterFormData {
  name: string;
  date: Date;
  recipient: string;
}

export class ReportWaterForm extends Form<ReportWaterFormData> {
  protected definition = {
    name: { label: "Name", type: FormFieldType.Text, required: true },
    date: {
      label: "Berichtdatum",
      type: FormFieldType.Date,
      required: false
    },
    recipient: {
      label: "Empfänger",
      type: FormFieldType.Text,
      required: true
    }
  };
}
