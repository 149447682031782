import { AccessClientsVm } from "./AccessClientsVm";
import { AccessResourceVm } from "./AccessResourceVm";

export class AccessResourceTreeVm {
  public base = new AccessClientsVm(0, "Mandanten");

  public loadSubResources(resource: AccessResourceVm) {
    return resource.loadChildren();
  }
}
