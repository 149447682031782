import {
  CreateTicketData,
  TicketManagerGateway,
  UpdateTicketData,
  UpdateTicketDescription,
  UpdateTicketOrganization,
  UpsetTicketLocation
} from "@/expert/interactors/TicketManager";
import { GraphQLConnection } from "./connection/GraphQLConnection";
import { Ticket } from "@/entities/Ticket";
import { Page } from "@/datastructures/Page";
import { NumericalId } from "@/datastructures/NumericalId";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { TicketViewerGateway } from "@/fieldwork/interactors/TicketViewer";
import { DateUtils } from "@/utils/DateUtils";
import { Image } from "@/entities/Image";
import { StringUtils } from "@/utils/StringUtils";
import { TicketLocation } from "@/entities/TicketLocation";
import { Plan } from "@/entities/Plan";
import { ChecklistEntry } from "@/entities/ChecklistEntry";
import { ChecklistSection } from "@/entities/ChecklistSection";
import { Checklist } from "@/entities/Checklist";
import { Assignment } from "@/entities/Assignment";
import SubjectTypes from "@/document/utils/SubjectTypes";
import { fileExtensionRegex } from "@/data/regex";
import { FileUtils } from "@/shared/utils/FileUtils";

export class TicketManagerGraphQLGateway implements TicketManagerGateway {
  public constructor(
    private connection: GraphQLConnection,
    private viewer: TicketViewerGateway
  ) {}

  public async createTicket(
    data: CreateTicketData,
    images: File[],
    failedImageUploads?: (ticketId: number, imageIndex: number[]) => void
  ): Promise<Ticket> {
    const ticketId = await this.createTicketData(data);

    if (!!images && images.length > 0) {
      const insuccessfulImages: number[] = [];
      Promise.all(
        images.map(async (image: File, index: number) => {
          try {
            await this.uploadImage(new NumericalId(ticketId), image);
          } catch (error) {
            insuccessfulImages.push(index);
          }
        })
      ).finally(() => {
        if (failedImageUploads) {
          failedImageUploads(ticketId, insuccessfulImages);
        }
      });
    }

    return new Ticket(ticketId);
  }

  public deleteTicket(id: NumericalId): Promise<Ticket> {
    return this.connection
      .mutation("deleteTicket", { id: id.id }, ["id"])
      .then(response => new Ticket(response.data.id));
  }

  public updateDescription(data: UpdateTicketDescription): Promise<Ticket> {
    return this.connection
      .mutation(
        "changeTicketDescription",
        {
          input: {
            id: data.id,
            title: data.title,
            description: data.description,
            action: data.action
          }
        },
        []
      )
      .then(response => new Ticket(response.data));
  }

  public updateAdditionalData(data: UpdateTicketData): Promise<Ticket> {
    return this.connection
      .mutation(
        "updateTicket",
        {
          input: {
            id: data.id,
            data: data.data
          }
        },
        ["id"]
      )
      .then(response => new Ticket(response.data.id));
  }

  public updateOrganization(data: UpdateTicketOrganization): Promise<Ticket> {
    return this.connection
      .mutation(
        "changeTicketOrganization",
        {
          input: {
            id: data.id,
            priority: data.priority,
            maturity: DateUtils.value(data.dueDate)
          }
        },
        []
      )
      .then(response => new Ticket(response.data));
  }

  public upsertLocation(
    ticketId: NumericalId,
    data: UpsetTicketLocation
  ): Promise<Ticket> {
    const locationData = {
      id: data.id ? data.id.id : undefined,
      point_pixel_x: data.x,
      point_pixel_y: data.y,
      plan: {
        connect: data.planId.id
      }
    };

    return this.connection
      .mutation(
        "updateTicket",
        {
          input: {
            id: ticketId.id,
            location: {
              update: data.id ? locationData : undefined,
              create: !data.id ? locationData : undefined
            }
          }
        },
        [
          "id",
          {
            name: "location",
            fields: [
              "id",
              "point_pixel_x",
              "point_pixel_y",
              { name: "plan", fields: ["id", "name"] }
            ]
          }
        ]
      )
      .then(response => {
        const ticketData = response.data;
        const ticket = new Ticket(ticketData.id);

        if (ticketData.location) {
          const locationResponse = ticketData.location;
          const location = new TicketLocation(locationResponse.id);
          location.x = locationResponse.point_pixel_x;
          location.y = locationResponse.point_pixel_y;
          ticket.location = location;

          if (locationResponse.plan) {
            const planResponse = locationResponse.plan;
            const plan = new Plan(planResponse.id);
            plan.name = planResponse.name;
            location.plan = plan;
          }
        }

        return ticket;
      });
  }

  public deleteLocation(
    ticketId: NumericalId,
    locationId: NumericalId
  ): Promise<Ticket> {
    return this.connection
      .mutation(
        "updateTicket",
        {
          input: {
            id: ticketId.id,
            location: {
              delete: locationId.id
            }
          }
        },
        ["id"]
      )
      .then(response => new Ticket(response.data.id));
  }

  public async upsertChecklistReference(
    ticketId: NumericalId,
    checklistId: NumericalId,
    entryId: NumericalId,
    prevChecklistId?: NumericalId
  ) {
    await this.setEntryStateIfNeeded(entryId);

    return this.connection
      .mutation(
        "updateTicket",
        {
          input: {
            id: ticketId.id,
            entry: { connect: entryId.id },
            checklists: {
              connect: [checklistId.id],
              disconnect:
                prevChecklistId && checklistId.id !== prevChecklistId.id
                  ? [prevChecklistId.id]
                  : undefined
            }
          }
        },
        [
          "id",
          {
            name: "entry",
            fields: [
              "id",
              "name",
              {
                name: "section",
                fields: [
                  "id",
                  "name",
                  {
                    name: "checklist",
                    fields: [
                      "id",
                      {
                        name: "assignment",
                        fields: ["id", "number"]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      )
      .then(response => {
        const data = response.data;
        const ticket = new Ticket(data.id);

        if (data.entry) {
          const entryData = data.entry;
          const sectionData = entryData.section;
          const assignmentData = sectionData.checklist.assignment;

          const entry = new ChecklistEntry(entryData.id);
          entry.name = entryData.name;

          const section = new ChecklistSection(sectionData.id);
          section.name = sectionData.name;

          const checklist = new Checklist(sectionData.checklist.id);
          const assignment = new Assignment(assignmentData.id);
          assignment.number = assignmentData.number;

          entry.section = section;
          section.checklist = checklist;
          checklist.assignment = assignment;
          ticket.entry = entry;
        }

        return ticket;
      });
  }

  public deleteChecklistReference(
    ticketId: NumericalId,
    checklistId: NumericalId
  ) {
    return this.connection
      .mutation(
        "updateTicket",
        {
          input: {
            id: ticketId.id,
            entry: { connect: 0 },
            checklists: { disconnect: [checklistId.id] }
          }
        },
        ["id"]
      )
      .then(response => new Ticket(response.data.id));
  }

  public uploadImage(ticketId: NumericalId, image: File): Promise<Image> {
    return this.uploadTicketImage(image, ticketId.id);
  }

  public deleteImage(
    ticketId: NumericalId,
    imageId: NumericalId
  ): Promise<Image> {
    return this.connection
      .mutation(
        "deleteTicketImage",
        { input: { ticket_id: ticketId.id, image_id: imageId.id } },
        []
      )
      .then(response => new Image(response.data, ""));
  }

  public ticketExists(ticketId: NumericalId) {
    return this.viewer.ticketExists(ticketId);
  }

  public loadTicket(
    ticketId: NumericalId,
    clientId: string,
    propertyId?: string,
    search?: string,
    priority?: string,
    state?: string,
    entryId?: string,
    plantId?: number,
    entryTemplate?: string
  ) {
    return this.viewer.loadTicket(
      ticketId,
      clientId,
      propertyId,
      search,
      priority,
      state,
      entryId,
      plantId,
      entryTemplate
    );
  }

  public loadTicketCatalog(
    page: Page,
    clientId: NumericalId,
    search: string = "",
    priority?: string,
    state?: string,
    plantId?: number,
    entryTemplate?: string,
    propertyId?: NumericalId,
    entryId?: string
  ): Promise<PaginatedList<Ticket>> {
    return this.viewer.loadTicketCatalog(
      page,
      clientId,
      search,
      priority,
      state,
      plantId,
      entryTemplate,
      propertyId,
      entryId
    );
  }

  public generateTicketReport(
    priorities: string[],
    propertyId?: NumericalId
  ): Promise<string> {
    return this.viewer.generateTicketReport(priorities, propertyId);
  }

  private async createTicketData(data: CreateTicketData): Promise<number> {
    if (data.checklistEntry) {
      await this.setEntryStateIfNeeded(data.checklistEntry);
    }

    const result = await this.connection.mutation(
      "createTicket",
      {
        input: {
          title: data.title,
          description: data.description,
          action: data.action,
          deadline: data.deadline,
          maturity: data.dueDate,
          priority: data.priority,
          data: data.additionalData,
          type: data.type,
          entry: data.checklistEntry
            ? { connect: data.checklistEntry.id }
            : undefined,
          client: { connect: data.client.id },
          property: { connect: data.property.id },
          creator: { connect: data.creatorId.id },
          plant_id: data.plantId?.id,
          checklist_entry_template_id: data.checklistEntryTemplateId,
          location: data.planId
            ? {
                create: {
                  plan: { connect: data.planId.id },
                  point_pixel_x: data.x,
                  point_pixel_y: data.y
                }
              }
            : undefined,
          checklists: data.checklist
            ? { connect: data.checklist.id }
            : undefined,
          sync_id: data.syncId
        }
      },
      ["id"],
      30000
    );

    return parseInt(result.data.id, 10);
  }

  private async setEntryStateIfNeeded(entryId: NumericalId) {
    const entryStateResponse = await this.connection.query(
      "checklistEntry",
      { id: entryId.id },
      ["state"]
    );

    const state = !!entryStateResponse.data.state
      ? entryStateResponse.data.state
      : "proven";

    await this.connection.mutation(
      "updateChecklistEntry",
      { input: { id: entryId.id, preparation_state: "prove", state } },
      ["id"]
    );
  }

  private uploadTicketImages(
    uploadImages: File[],
    ticketId: number
  ): Promise<any> {
    return Promise.all(
      uploadImages.map(image => this.uploadTicketImage(image, ticketId))
    )
      .then(images =>
        this.connection.mutation(
          "updateTicket",
          {
            input: {
              id: ticketId,
              images: {
                update: images.map(image => ({
                  id: image.id,
                  fileable_id: ticketId,
                  fileable_type: "App\\Ticket"
                }))
              }
            }
          },
          ["id", { name: "images", fields: ["id", "path"] }]
        )
      )
      .then(response => response.data);
  }

  private async uploadTicketImage(
    image: File,
    ticketId: number
  ): Promise<Image> {
    const result = await this.connection.mutation(
      "uploadTicketImage",
      {
        input: {
          ticket_id: ticketId,
          sync_id: StringUtils.uuid(),
          image: null
        }
      },
      [],
      undefined,
      {
        path: "input.image",
        file: image
      }
    );

    const filesAsBase64 = await FileUtils.toBase64(image);

    return new Image(parseInt(result.data, 10), filesAsBase64);
  }
}
