
import Vue from "vue";
import Component from "vue-class-component";

import { materialIcons } from "@/data/materialIcons";

import Row from "../layout/Row.vue";
import Column from "../layout/Column.vue";
import IconButton from "../basic/IconButton.vue";

@Component<MaterialIconSelector>({
  components: {
    Row,
    Column,
    IconButton
  },
  props: {
    value: {
      type: Object,
      default() {
        return { label: "Label", value: "", loading: false, error: "" };
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class MaterialIconSelector extends Vue {
  private menuVisible = false;
  private iconFilter = "";
  private iconsToDisplay = 84;
  private totalIcons = 0;

  private get iconList() {
    const filteredIcons = this.iconFilter
      ? materialIcons.filter(i => i.includes(this.iconFilter))
      : materialIcons;

    this.totalIcons = filteredIcons.length;

    return filteredIcons.slice(0, this.iconsToDisplay);
  }

  private get moreIconsToLoad() {
    return this.totalIcons > this.iconsToDisplay;
  }

  private emitChange(icon: string) {
    this.$emit("input", icon);
    this.$emit("change", icon);
    this.menuVisible = false;
  }

  private filterIcons(filter: string) {
    this.iconFilter = filter.toLowerCase();
    this.iconsToDisplay = 84;
  }

  private loadMoreIcons() {
    this.iconsToDisplay += 84;
  }

  private openMaterialIcons() {
    window.open(
      "https://material.io/resources/icons/?style=baseline",
      "_blank"
    );
  }
}
