import { ChecklistEntryEntity } from "../entities/ChecklistEntity";
import { ChecklistEntry } from "./ChecklistEntry";

export enum ChecklistEntrySort {
  None,
  Alphabetically
}

export class ChecklistEntryCollection {
  private entriesRaw?: ChecklistEntry[] = [];
  private sort = ChecklistEntrySort.None;

  public get entries() {
    return this.sort === ChecklistEntrySort.None
      ? this.entriesRaw
      : this.entriesRaw
          ?.map(e => e)
          ?.sort((a, b) => a.name?.localeCompare(b.name ?? "") ?? 0);
  }

  public get notEmpty() {
    return !this.empty;
  }

  public get empty() {
    return (this.entriesRaw?.length ?? 0) === 0;
  }

  public get preparationState() {
    if (this.notEmpty) {
      return this.entriesRaw?.reduce(
        (state, entry) => (state === entry.preparationState ? state : "mixed"),
        this.entriesRaw[0].preparationState
      );
    } else {
      return undefined;
    }
  }

  public get state() {
    if (this.notEmpty) {
      return this.entriesRaw?.reduce(
        (state, entry) => (state === entry.state ? state : "mixed"),
        this.entriesRaw[0].state
      );
    } else {
      return undefined;
    }
  }

  public get hasTickets() {
    if (this.notEmpty) {
      return this.entriesRaw?.some(e => e.hasTickets) ?? false;
    } else {
      return false;
    }
  }

  public get sorted() {
    return this.sort !== ChecklistEntrySort.None;
  }

  public sortAlphabetically() {
    this.sort = ChecklistEntrySort.Alphabetically;
    this.entriesRaw?.forEach(e => e.sortAlphabetically());
  }

  public unsort() {
    this.sort = ChecklistEntrySort.None;
    this.entriesRaw?.forEach(e => e.unsort());
  }

  public toEntities(): ChecklistEntryEntity[] {
    return this.entriesRaw?.map(e => e.toEntity()) ?? [];
  }

  public from(entities?: ChecklistEntryEntity[]) {
    this.entriesRaw = entities?.map(e => ChecklistEntry.from(e));
  }

  public static from(entities?: ChecklistEntryEntity[]) {
    const entries = new ChecklistEntryCollection();
    entries.from(entities);
    return entries;
  }
}
