
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component<Url>({
  components: {}
})
export default class Url extends Vue {
  @Prop({ type: String, default: "link" })
  protected readonly s!: string;
  @Prop({ type: String, default: "primary" })
  protected readonly color!: string;
  @Prop({ type: Boolean, default: false })
  protected readonly inline!: boolean;
  @Prop({ type: String, default: "secondary" })
  protected readonly hoverColor!: string;
  @Prop({ type: String, default: "secondary" })
  protected readonly pressedColor!: string;
  @Prop({ type: Boolean, default: true })
  protected readonly underlined!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly disabled!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly loading!: boolean;
  @Prop({ type: String, default: "" })
  protected readonly error!: string;

  protected hovered = false;
  protected pressed = false;

  protected get linkColor() {
    return this.disabled || this.loading
      ? this.color
      : this.error
      ? "error"
      : this.pressed
      ? this.pressedColor
      : this.hovered
      ? this.hoverColor
      : this.color;
  }

  protected emitClick() {
    if (!this.disabled && !this.loading) {
      this.$emit("click");
    }
  }

  protected hover() {
    this.hovered = true;
  }

  protected leave() {
    this.hovered = false;
    this.pressed = false;
  }

  protected press() {
    this.pressed = true;
  }

  protected unpress() {
    this.pressed = false;
  }

  protected get cssProps() {
    return {
      "--cursor": this.disabled || this.loading ? "default" : "pointer",
      "--display": this.inline ? "inline" : "block"
    };
  }
}
