import { RestConnection } from "./RestConnection";
import Axios, { AxiosInstance } from "axios";
import { IAxiosLogger } from "@/gateways/graphql/connection/AxiosGraphQLConnection";

export class AxiosRestConnection implements RestConnection {
  private connection: AxiosInstance;

  public constructor(private logger: IAxiosLogger) {
    this.connection = Axios.create();
  }

  public get(path: string, params: object): Promise<any> {
    this.logger.logRequest(path, params);
    return this.connection
      .get(path, { params })
      .then(response => {
        this.logger.logResponse(response);
        return response.data;
      })
      .catch(reason => {
        this.logger.logError(reason);
        throw new Error(reason);
      });
  }
}
