import { PlantFieldCategoryData } from "@/property/shared/entities/PlantEntity";
import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";

export class PlantFieldCategoryForm extends Form<PlantFieldCategoryData> {
  protected definition = {
    name: {
      label: "Kategorie",
      type: FormFieldType.Text,
      required: true
    }
  };
}
