
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component<Progress>({})
export default class Progress extends Vue {
  @Prop({ type: [String, Number] }) protected readonly value?: string;
  @Prop({ type: Boolean, default: false }) protected readonly linear!: boolean;
  @Prop({ type: Boolean, default: true })
  protected readonly indeterminate!: boolean;
  @Prop({ type: String, default: "primary" })
  protected readonly color!: string;
  @Prop({ type: Number, default: 32 }) protected readonly radius!: number;
}
