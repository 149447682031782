import { DefaultPlanLocationRepository } from "@/shared/project/planLocation/PlanLocationRepository";
import { DefaultAssignmentRepository } from "./assignmentRepository/AssignmentRepository";
import { DefaultChecklistRepository } from "./checklistRepository/ChecklistRepository";
import { DefaultReportRepository } from "./reportRepository/ReportRepository";
import { DefaultTicketRepository } from "./ticketRepository/TicketRepository";
import { TicketCacheProvider } from "./ticketRepository/TicketCacheProvider";
import Redux from "@/plugins/vuex";
import { TicketGraphQLProvider } from "./ticketRepository/TicketGraphQLProvider";

export class InspectionRepoContainer {
  public static assignmentRepo = new DefaultAssignmentRepository();
  public static checklistRepo = new DefaultChecklistRepository();
  public static reportRepo = new DefaultReportRepository();
  public static ticketRepo = new DefaultTicketRepository(
    new TicketGraphQLProvider(),
    Redux
  );
  public static planLocationRepo = new DefaultPlanLocationRepository();

  public static cachedTicketRepo = new DefaultTicketRepository(
    new TicketCacheProvider(),
    Redux
  );
}
