import { PropertyPlanViewerGateway } from "@/expert/interactors/PropertyPlanViewer";
import { NumericalId } from "@/datastructures/NumericalId";
import {
  PlanCatalogRow,
  PlanPayload
} from "@/office/interactors/PropertyPlanManager";
import { PropertyPlanStorage } from "./PropertyPlanStorage";
import { IndexedDBKeyValueStorage } from "@/storage/IndexedDBKeyValueStorage";
import { OnlineChecker } from "@/utils/OnlineChecker";

export class PropertyPlanViewerCachedGateway
  implements PropertyPlanViewerGateway {
  private store: PropertyPlanStorage = new PropertyPlanStorage(
    new IndexedDBKeyValueStorage("plan-db", "plans-store")
  );

  public constructor(private webProvider: PropertyPlanViewerGateway) {}

  public async loadPlanCatalog(
    propertyId: NumericalId
  ): Promise<PlanCatalogRow[]> {
    let plans: PlanCatalogRow[] = [];

    try {
      plans = await this.webProvider.loadPlanCatalog(propertyId);
    } catch (e) {
      await this.store.load(propertyId.id);
      plans = this.store.planCatalog;
    }

    return plans;
  }

  public async loadPlan(planId: NumericalId): Promise<PlanPayload> {
    let plan: PlanPayload;

    try {
      plan = await this.webProvider.loadPlan(planId);
    } catch (e) {
      await this.store.load();
      plan = this.store.getPlan(planId.id);
      return plan;
    }

    return plan;
  }

  public async loadTicketPlan(
    ticketId: NumericalId,
    planId: NumericalId
  ): Promise<PlanPayload> {
    let plan: PlanPayload;

    try {
      plan = await this.webProvider.loadTicketPlan(ticketId, planId);
    } catch (e) {
      await this.store.load();
      plan = this.store.getPlan(planId.id);
      return plan;
    }

    return plan;
  }
}
