import {
  AssignmentManagerGateway,
  FullAssignmentData,
  AssignmentDataData,
  AssignmentCatalogRow,
  AssignmentState
} from "@/expert/interactors/AssignmentManager";
import { GraphQLConnection } from "./connection/GraphQLConnection";
import { Assignment } from "@/entities/Assignment";
import { DateUtils } from "@/utils/DateUtils";
import { NumericalId } from "@/datastructures/NumericalId";
import { Plant } from "@/entities/Plant";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { Page } from "@/datastructures/Page";
import { Checklist } from "@/entities/Checklist";
import { Report } from "@/entities/Report";
import { Property } from "@/entities/Property";
import { Address } from "@/entities/Address";

export class AssignmentManagerGraphQLGateway
  implements AssignmentManagerGateway {
  public constructor(private connection: GraphQLConnection) {}

  public createAssignment(data: FullAssignmentData): Promise<Assignment> {
    return this.connection
      .mutation(
        "createAssignment",
        {
          input: {
            number: data.number,
            date: DateUtils.value(data.date),
            statement: data.description,
            plant: {
              connect: data.plantId.id
            }
          }
        },
        ["id"]
      )
      .then(response => {
        const assignment = new Assignment(response.data.id);

        return assignment;
      });
  }

  public getPlantIdByType(
    propertyId: NumericalId,
    type: string
  ): Promise<number> {
    return this.connection
      .query("plant", { property_id: propertyId.id, type }, ["id"])
      .then(response => response.data.id);
  }

  public loadAssignmentCatalog(
    plantId: NumericalId,
    page: Page,
    search: string
  ): Promise<PaginatedList<AssignmentCatalogRow>> {
    return this.connection
      .queryPaginated(
        "assignments",
        page.itemsPerPage,
        page.page,
        { plant_id: plantId.id },
        [
          "id",
          "number",
          "date",
          "statement",
          { name: "checklist", fields: ["id", "preparation_done"] },
          { name: "reports", fields: ["id"] },
          {
            name: "plant",
            fields: [
              "id",
              {
                name: "property",
                fields: [
                  "id",
                  { name: "address", fields: ["street", "city", "postcode"] }
                ]
              }
            ]
          }
        ],
        search
      )
      .then(response => ({
        items: response.data.map((data: any) => {
          const checklistData = data.checklist
            ? {
                checklistId: data.checklist.id,
                checklistPrepared: data.checklist.preparation_done
              }
            : {
                checklistId: 0,
                checklistPrepared: false
              };

          const reportCount = data.reports ? data.reports.length : 0;

          const address =
            data.plant && data.plant.property && data.plant.property.address
              ? {
                  street: data.plant.property.address.street,
                  zip: data.plant.property.address.postcode,
                  city: data.plant.property.address.city
                }
              : {
                  street: "",
                  zip: "",
                  city: ""
                };

          return {
            id: NumericalId.fromString(data.id).id,
            number: data.number,
            date: new Date(data.date),
            description: data.statement,
            checklistId: checklistData.checklistId,
            checklistPrepared: checklistData.checklistPrepared,
            state: this.getAssignmentState(
              checklistData.checklistPrepared,
              reportCount
            ),
            street: address.street,
            zip: address.zip,
            city: address.city
          };
        }),
        totalCount: response.count
      }));
  }

  public loadAssignment(id: NumericalId): Promise<Assignment> {
    return this.connection
      .query("assignment", { id: id.id }, ["id", "number", "date", "statement"])
      .then(response => {
        const assignment = new Assignment(response.data.id);

        assignment.number = response.data.number;
        assignment.date = new Date(response.data.date);
        assignment.description = response.data.statement;

        return assignment;
      });
  }

  public loadAssignmentList(propertyId: NumericalId): Promise<Assignment[]> {
    return this.connection
      .queryPaginated(
        "propertyAssignments",
        1000,
        1,
        { property_ids: [propertyId.id], include_base_plants: true },
        ["id", "number", { name: "plant", fields: ["id", "type"] }],
        ""
      )
      .then(response => {
        const assignmentsData = response.data;
        const assignments: Assignment[] = [];

        if (!!assignmentsData) {
          assignmentsData.forEach((assignmentData: any) => {
            const assignment = new Assignment(assignmentData.id);

            assignment.number = assignmentData.number;

            if (assignmentData.plant) {
              const plantData = assignmentData.plant;
              assignment.plant = new Plant(plantData.id);
              assignment.plant.type = plantData.type;
            }

            assignments.push(assignment);
          });
        }

        return assignments;
      });
  }

  public updateAssignmentData(data: AssignmentDataData): Promise<Assignment> {
    return this.connection
      .mutation(
        "updateAssignment",
        {
          input: {
            id: data.id ? data.id.id : 0,
            number: data.number,
            date: DateUtils.value(data.date),
            statement: data.description
          }
        },
        ["id"]
      )
      .then(response => new Assignment(response.data.id));
  }

  public deleteAssignment(id: NumericalId): Promise<Assignment> {
    return this.connection
      .mutation("deleteAssignment", { id: id.id }, ["id"])
      .then(response => new Assignment(response.data.id));
  }

  private getAssignmentState(prepDone: boolean, reportCount: number) {
    if (prepDone) {
      if (reportCount > 0) {
        return AssignmentState.Finsihed;
      } else {
        return AssignmentState.Inspection;
      }
    } else {
      return AssignmentState.Preparation;
    }
  }
}
