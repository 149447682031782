import { ObjectUtils } from "@/utils/ObjectUtils";

export interface MultiSelection extends BaseSelection<SelectionItem> {
  selected: string[];
}

export interface SearchSelection extends SelectableSelection<SelectionItem> {
  search: string;
}

export interface Selection extends SelectableSelection<SelectionItem> {}

export interface SelectableSelection<T extends SelectionItem>
  extends BaseSelection<T> {
  selected: string;
}

export interface BaseSelection<T extends SelectionItem> {
  label: string;
  error: string;
  loading: boolean;
  items: T[];
  noDataText?: string;
}

export interface SelectionItem {
  text: string;
  value: string;
}

export interface Value<T> {
  label: string;
  value: T;
}

export interface Input<T> extends Value<T> {
  error: string;
  loading: boolean;
}

export interface StringValue extends Value<string> {}
export interface NumberValue extends Value<number> {}
export interface Textfield extends Input<string> {}
export interface Checkbox extends Input<boolean> {
  selected: boolean;
}
export interface SingleFileInput
  extends Input<(File & { preview?: string }) | undefined> {
  id?: string;
  path?: string;
}
export interface FileInput extends Input<Array<File & { preview?: string }>> {}
export interface Numberfield extends Input<number> {
  default?: number;
}

export interface Date extends Input<string> {
  text: string;
}

export interface Request {
  loading: boolean;
  error: string;
}

export interface RequestMap {
  [key: string]: Request | undefined;
}

export interface TableHeader {
  text: string;
  value: string;
  icon?: string;
  align?: string;
  mobile?: boolean;
  width?: string;
  bold?: boolean;
  sortable?: boolean;
  sortBy?: string;
}

export interface TableOptions {
  sort: TableSort[];
}

export interface TableSort {
  by: string;
  desc: boolean;
}

export interface Tab {
  label: string;
  key: string;
}

export function getSelectedText(selection: Selection): string {
  const selectedItem = selection.items.find(
    i => i.value === selection.selected
  );
  if (selectedItem) {
    return selectedItem.text;
  }
  return "";
}

export class RequestMapFunctions {
  public static updateRequest(
    requestMap: RequestMap,
    key: string,
    newRequest: Request
  ) {
    const copy = ObjectUtils.deepCopy(requestMap);
    copy[key] = newRequest;
    return copy;
  }

  public static removeRequest(requestMap: RequestMap, key: string) {
    requestMap[key] = undefined;
  }
}
