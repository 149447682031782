import { Request, TableHeader } from "@/forms/ViewModelFormTypes";

export class PersonListViewModel {
  // Other
  public goBack = false;

  // Data
  public personSearch = "";
  public people: PersonViewData[] = [];
  public goToPerson = "";

  // Pagination
  public currentPage = 1;
  public peoplePerPage = 20;
  public totalPeople = 20;

  // Requests
  public loadPeopleRequest: Request = { loading: false, error: "" };

  // Visibility
  public createPersonButtonVisible = false;
  public createPersonDialogVisible = false;
  public viewPersonButtonVisible = false;

  // Table headers
  public peopleTableHeaders: TableHeader[] = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Adresse",
      value: "address"
    },
    {
      text: "Kontakt",
      value: "contact"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "center"
    }
  ];
  public peopleTableHeadersTablet: TableHeader[] = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Kontakt",
      value: "contact"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "center"
    }
  ];
  public peopleTableHeadersMobile: TableHeader[] = [
    {
      text: "Informationen",
      value: "contact"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "center"
    }
  ];
}

export interface PersonViewData {
  id: string;
  name: string;
  phoneNumber: string;
  email: string;
  address: string;
}
