
import Vue from "vue";
import Component from "vue-class-component";
import Sortable from "sortablejs";

import PlantFieldDefinitionTable from "./PlantFieldDefinitionTable.vue";

import { Prop, Watch } from "vue-property-decorator";

import { PlantFieldCategoryForm as Form } from "../forms/PlantFieldCategoryForm";
import { FormArray } from "@/shared/form/SubFormArray";
import { PlantFieldCategoryEntity } from "@/property/shared/entities/PlantEntity";
import { PlantFieldCategoryCollection } from "@/property/shared/models/PlantFieldCategoryCollection";
import { PlantFieldCategory } from "@/property/shared/models/PlantFieldCategory";
import { ArrayUtils } from "@/utils/ArrayUtils";

@Component<PlantFieldCategoryList>({
  components: {
    PlantFieldDefinitionTable
  }
})
export default class PlantFieldCategoryList extends Vue {
  @Prop({ type: Object, default: () => new PlantFieldCategoryCollection() })
  protected readonly categories!: PlantFieldCategoryCollection;
  @Prop({
    type: Object,
    default: () => new FormArray<PlantFieldCategoryEntity>([])
  })
  protected readonly forms!: FormArray<PlantFieldCategoryEntity>;

  @Watch("categories.categories")
  protected onCategoriesChanged() {
    this.initForms();
  }

  protected addCategory() {
    const newCategory = this.categories.addEmpty();
    const newCategoryForm = new Form(
      undefined,
      "plantFieldCategory",
      newCategory
    );
    newCategoryForm.addSubForm(undefined, "fields");

    this.forms.push(newCategoryForm);

    return newCategory;
  }

  protected removeCategory(category: PlantFieldCategory) {
    this.forms.removeByTarget(category);
    this.categories.remove(category);
  }

  protected getFieldFormsOfCategory(category: PlantFieldCategory) {
    return this.forms.getSubFormByTarget(category)?.subForms?.fields;
  }

  protected getFormField(fieldName: string, category: PlantFieldCategory) {
    const form = this.forms.getSubFormByTarget(category);
    return form?.getField(fieldName);
  }

  protected setFormField(
    fieldName: string,
    value: unknown,
    category: PlantFieldCategory
  ) {
    const form = this.forms.getSubFormByTarget(category);
    if (form) {
      form?.setFieldValue(fieldName, value);
      category.updateData(form?.getData());
    }
  }

  protected mounted() {
    const categoryList = document.querySelector(".category-list");

    if (categoryList != null) {
      Sortable.create(categoryList as HTMLElement, {
        animation: 150,
        delay: 300,
        delayOnTouchOnly: true,
        forceFallback: true,
        handle: ".handle",
        onEnd: ({ newIndex, oldIndex }) => {
          if (
            oldIndex !== undefined &&
            newIndex !== undefined &&
            oldIndex !== newIndex
          ) {
            ArrayUtils.move(this.categories.categories, oldIndex, newIndex);
          }
        }
      });
    }
  }

  protected async created() {
    await this.initForms();
  }

  protected async initForms() {
    this.forms.clear();

    const formPromises =
      this.categories.categories?.map(async c => {
        const form = new Form(undefined, "plantFieldCategory", c);
        await form.init();
        form.addSubForm(undefined, "fields");
        form.setData(c.toEntity());
        this.forms.push(form);
      }) ?? [];

    await Promise.all(formPromises);

    if (this.$refs.plantFieldDefinitionTable) {
      for (const plantFieldDefinitionTable of this.$refs
        .plantFieldDefinitionTable as any[]) {
        plantFieldDefinitionTable.initForms();
      }
    }
  }

  public reset() {
    this.categories.clear();
    this.forms.clear();
  }
}
