
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { PlantTypeSelectItem } from "@/property/shared/dtos/PropertySelectionItemLoader";
import { Plant } from "@/property/shared/models/Plant";
import { PlantType } from "@/property/shared/settings/PlantTypeSettings";

@Component<PlantTypeList>({
  components: {}
})
export default class PlantTypeList extends Vue {
  @Prop({ type: Object, default: () => new Plant() })
  protected readonly plant!: Plant;

  protected selectedTypes: PlantTypeSelectItem[] = [];
  protected plantTypeToAdd?: PlantType | null = null;
  protected plantTypeToDelete?: PlantType | null = null;

  @Watch("plant")
  protected onPlantChanged() {
    this.initTypes();
  }

  protected get items() {
    return Plant.typesAsSelectItems();
  }

  protected get hasPlantTypeToAdd() {
    return this.plantTypeToAdd?.id !== undefined;
  }

  protected get hasPlantTypeToDelete() {
    return this.plantTypeToDelete?.id !== undefined;
  }

  protected applyPlantType() {
    this.plant.addType(this.plantTypeToAdd!);
    this.plantTypeToAdd = null;
  }

  protected removePlantType() {
    this.plant.removeType(this.plantTypeToDelete!);
    this.plantTypeToDelete = null;
  }

  protected changeType(types: PlantTypeSelectItem[]) {
    this.selectedTypes = types;

    const deleted = this.plant.types?.filter(
      pt => !types.find(t => t.value === pt.id)
    );
    const added = types.filter(
      t => !this.plant.types?.find(pt => pt.id === t.value)
    );

    if (added && added.length > 0) {
      this.plantTypeToAdd = added[0].item;
    }

    if (deleted && deleted.length > 0) {
      this.plantTypeToDelete = deleted[0];
    }
  }

  protected cancelAddingPlantType() {
    if (!this.plantTypeToAdd) {
      return;
    }

    this.selectedTypes = this.selectedTypes.filter(
      t => t.value !== this.plantTypeToAdd!.id
    );
    this.plantTypeToAdd = null;
  }

  protected cancelDeletingPlantType() {
    if (!this.plantTypeToDelete) {
      return;
    }

    this.selectedTypes.push({
      value: this.plantTypeToDelete!.id ?? "",
      text: this.plantTypeToDelete!.name ?? "",
      item: this.plantTypeToDelete!
    });

    this.plantTypeToDelete = null;
  }

  protected created() {
    this.initTypes();
  }

  protected initTypes() {
    this.selectedTypes =
      this.plant.types?.map(t => ({
        value: t.id ?? "",
        text: t.name ?? "",
        item: t
      })) ?? [];
  }

  public reset() {
    this.selectedTypes = [];
  }
}
