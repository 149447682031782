import { NumericalId } from "@/datastructures/NumericalId";
import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { Person } from "@/entities/Person";

export class PersonEditor implements IPersonEditor {
  public constructor(private gateway: PersonEditorGateway) {}

  public loadPeopleCatalog(
    clientId: NumericalId,
    page: Page,
    search: string
  ): Promise<PaginatedList<PersonCatalogRow>> {
    return this.gateway
      .loadPeopleCatalog(clientId, page, search)
      .then(people => ({
        items: people.items.map(person => ({
          id: person.id,
          preTitle: person.preTitle,
          postTitle: person.postTitle,
          firstname: person.firstname,
          lastname: person.lastname,
          email: person.email,
          areaCode: person.phoneNumbers[0].areaCode,
          phoneNumber: person.phoneNumbers[0].number,
          street: person.addresses[0].street,
          zip: person.addresses[0].zip,
          city: person.addresses[0].city,
          country: person.addresses[0].country
        })),
        totalCount: people.totalCount
      }));
  }

  public loadPerson(personId: NumericalId): Promise<PersonDetail> {
    return this.gateway.loadPerson(personId).then(person => ({
      isEmployee: person.employee.id !== 0,
      preDegree: person.preTitle,
      postDegree: person.postTitle,
      firstname: person.firstname,
      lastname: person.lastname,
      email: person.email,
      website: person.website,
      phoneNumbers: person.phoneNumbers.map(phoneNumber => ({
        id: phoneNumber.id,
        context: phoneNumber.context,
        areaCode: phoneNumber.areaCode,
        number: phoneNumber.number
      })),
      addresses: person.addresses.map(address => ({
        id: address.id,
        context: address.context,
        street: address.street,
        zip: address.zip,
        city: address.city,
        country: address.country
      }))
    }));
  }

  public deletePerson(personId: NumericalId): Promise<number> {
    return this.gateway.deletePerson(personId).then(person => person.id);
  }
}

export interface IPersonEditor {
  loadPeopleCatalog(
    clientId: NumericalId,
    page: Page,
    search: string
  ): Promise<PaginatedList<PersonCatalogRow>>;

  loadPerson(personId: NumericalId): Promise<PersonDetail>;
  deletePerson(personId: NumericalId): Promise<number>;
}

export interface PersonEditorGateway {
  loadPeopleCatalog(
    clientId: NumericalId,
    page: Page,
    search: string
  ): Promise<PaginatedList<Person>>;

  loadPerson(personId: NumericalId): Promise<Person>;
  deletePerson(personId: NumericalId): Promise<Person>;
}

export interface PersonCatalogRow {
  id: number;
  preTitle: string;
  postTitle: string;
  firstname: string;
  lastname: string;
  email: string;
  areaCode: string;
  phoneNumber: string;
  street: string;
  zip: string;
  city: string;
  country: string;
}

export interface PersonDetail {
  id?: number;
  isEmployee: boolean;
  preDegree: string;
  postDegree: string;
  firstname: string;
  lastname: string;
  email: string;
  website: string;
  phoneNumbers: PersonPhoneNumber[];
  addresses: PersonAddress[];
}

export interface PersonPhoneNumber {
  id?: number;
  context: string;
  areaCode: string;
  number: string;
}

export interface PersonAddress {
  id?: number;
  context: string;
  street: string;
  zip: string;
  city: string;
  country: string;
}
