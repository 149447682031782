import { PropertyReportContainer } from "../repositories/PropertyRepoContainer";

export class PropertySelectionItemLoader {
  public static async loadRoles() {
    const roles = await PropertyReportContainer.employeeRepo.getRoles();

    return roles.map(r => ({
      value: r.id,
      text: r.name,
      item: r
    }));
  }

  public static async loadPeople(clientId: number) {
    const people = await PropertyReportContainer.personRepo.getPersonSelection(
      clientId,
      ""
    );

    return people.map(p => ({
      value: p.id,
      text: p.firstname + " " + p.lastname,
      item: p
    }));
  }
}
