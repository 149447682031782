import {
  IPropertyPlanManager,
  PlanData,
  PlanCatalogRow,
  PlanPayload,
  PlanImageData
} from "../interactors/PropertyPlanManager";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { NumericalId } from "@/datastructures/NumericalId";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { CreatePlanForm } from "@/forms/property/CreatePlanForm";
import { Point } from "@/datastructures/Point";
import { PlanPositionForm } from "@/forms/property/PlanPositionForm";

export class PropertyPlanController {
  public constructor(
    private presenter: IPropertyPlanPresenter,
    private planManager: IPropertyPlanManager,
    private storage: SimpleStringStorage
  ) {}

  public init(propertyId: string) {
    this.presenter.init();
    this.loadPlans(propertyId);
  }

  // Actions
  public createPlanButtonClicked() {
    this.presenter.newPlan();
  }

  public planInfoDialogButtonClicked() {
    this.presenter.infoDialogVisible = false;
  }

  public planEditLockButtonClicked() {
    this.presenter.togglePlanEditLock();
  }

  // Input
  public selectedPlanChanged(planId: string) {
    this.presenter.selectedPlanId = planId;
  }

  public nameChanged(name: string) {
    this.presenter.planForm.setFieldValue("name", name);
    this.presenter.positionForm.setFieldValue("name", name);
  }

  public offlineChanged(offline: string) {
    this.presenter.positionForm.setFieldValue("offline", offline);
  }

  public planImageChanged(image: File[]) {
    this.presenter.planImage = image[0];
  }

  public positionChanged(pos: Point & { index: number }) {
    if (pos.index === 0) {
      this.presenter.positionA = pos;
    } else {
      this.presenter.positionB = pos;
    }
  }

  public realAXChanged(realAX: string) {
    this.presenter.positionForm.setFieldValue("realAX", realAX);
  }

  public realAYChanged(realAY: string) {
    this.presenter.positionForm.setFieldValue("realAY", realAY);
  }

  public realBXChanged(realBX: string) {
    this.presenter.positionForm.setFieldValue("realBX", realBX);
  }

  public realBYChanged(realBY: string) {
    this.presenter.positionForm.setFieldValue("realBY", realBY);
  }

  // Requests
  public loadPlan(planId: string) {
    this.presenter.selectedPlanId = planId;

    NumericalId.onValidId(planId, id => {
      const request = this.planManager.loadPlan(id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadPlanResponse = response),
        "Plan konnte nicht geladen werden"
      );
    });
  }

  public uploadPlanDataClicked(propertyId: string) {
    NumericalId.onValidId(propertyId, id => {
      const request = this.planManager.createPlan(id, this.presenter.plan);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.createPlanResponse = response),
        "Plan konnte nicht erstellt werden"
      );
    });
  }

  public newPlanImageChanged(
    propertyId: string,
    planId: string,
    oldImageId: string,
    image: File[]
  ) {
    if (image && image.length > 0) {
      const planImage = image[0];

      NumericalId.onValidId(propertyId, validPropertyId => {
        NumericalId.onValidId(planId, validPlanId => {
          NumericalId.onValidId(oldImageId, validImageId => {
            const request = this.planManager.replacePlanImage(
              validPropertyId,
              validPlanId,
              validImageId,
              planImage
            );

            FormRequestHandler.handle(
              request,
              response => (this.presenter.replacePlanImageResponse = response),
              "Bild konnte nicht ausgetaushct werden"
            );
          });
        });
      });
    }
  }

  public updatePlanButtonClicked() {
    const request = this.planManager.updatePlan(this.presenter.plan);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.updatePlanResponse = response),
      "Plan konnte nicht aktualisiert werden"
    );
  }

  public deletePlanButtonClicked() {
    const request = this.planManager.deletePlan(this.presenter.plan.id);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.deletePlanResponse = response),
      "Plan konnte nicht gelöscht werden"
    );
  }

  private loadPlans(propertyId: string) {
    NumericalId.onValidId(propertyId, id => {
      const request = this.planManager.loadPlanCatalog(id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadPlansResponse = response),
        "Pläne konnten nicht geladen werden"
      );
    });
  }
}

export interface IPropertyPlanPresenter {
  // Data
  selectedPlanId: string;
  plan: PlanData;
  planImage: File;
  positionA: Point;
  positionB: Point;
  infoDialogVisible: boolean;

  // Forms
  planForm: CreatePlanForm;
  positionForm: PlanPositionForm;

  // Responses
  loadPlansResponse: FormResponse<PlanCatalogRow[]>;
  loadPlanResponse: FormResponse<PlanPayload>;
  createPlanResponse: FormResponse<PlanCatalogRow>;
  updatePlanResponse: FormResponse<void>;
  replacePlanImageResponse: FormResponse<PlanImageData>;
  deletePlanResponse: FormResponse<NumericalId>;

  // Methods
  init(): void;
  newPlan(): void;
  togglePlanEditLock(): void;
}
