import { IndexedDBKeyValueStorage } from "@/storage/IndexedDBKeyValueStorage";
import {
  PlanCatalogRow,
  PlanPayload
} from "@/office/interactors/PropertyPlanManager";
import { storageKeys } from "@/data/storageKeys";
import axios from "axios";
import { Buffer } from "buffer";
import { IndexDictionary } from "@/datastructures/Dictionary";
import { NumericalId } from "@/datastructures/NumericalId";

interface DownloadedPlanImage {
  planId: NumericalId;
  image: string;
}

export class PropertyPlanStorage {
  private static lastPropertyId: number;
  private plans: PlanPayload[] = [];

  public constructor(private storage: IndexedDBKeyValueStorage) {}

  public get planCatalog(): PlanCatalogRow[] {
    return this.plans.map(plan => ({
      id: plan.id,
      name: plan.name,
      offline: plan.offline,
      file: plan.file
    }));
  }

  public getPlan(planId: number): PlanPayload {
    const plan = this.plans.find(p => p.id.id.toString() === planId.toString());

    if (plan) {
      return plan;
    } else {
      throw new Error("plan-not-existent");
    }
  }

  public async clearPlans() {
    await this.storage.clear();
    this.plans = [];
  }

  public async addPlans(propertyId: number, plans: PlanCatalogRow[]) {
    const imageDownloads: IndexDictionary<Promise<DownloadedPlanImage>> = {};

    for (const plan of plans) {
      if (!plan.offline) {
        continue;
      }

      const imageResponse = axios
        .get(plan.file.path + "?nocache=" + new Date().getTime(), {
          responseType: "arraybuffer"
        })
        .then(response => {
          const image = Buffer.from(response.data, "binary").toString("base64");
          return {
            planId: plan.id,
            image: image ? "data:image/jpeg;base64, " + image : ""
          };
        });

      imageDownloads[plan.id.id] = imageResponse;
    }

    const downloadedImages = await Promise.allSettled(
      Object.values(imageDownloads) as Array<Promise<DownloadedPlanImage>>
    );

    for (const imageDownload of downloadedImages) {
      if (imageDownload.status === "rejected") {
        continue;
      }

      const image = imageDownload.value;

      const plan = plans.find(p => p.id.id === image.planId.id);
      if (plan) {
        this.plans.push({
          id: plan.id,
          name: plan.name,
          offline: plan.offline,
          file: {
            id: plan.file.id,
            path: image.image
          },
          pixelDistance: {
            a: {
              x: 0,
              y: 0
            },
            b: {
              x: 100,
              y: 100
            }
          },
          realDistance: {
            a: {
              x: 0,
              y: 0
            },
            b: {
              x: 1,
              y: 1
            }
          }
        });
      }
    }

    // for (const plan of plans) {
    //   if (!plan.offline) {
    //     continue;
    //   }

    //   let imageString = "";

    //   try {
    //     const imageResponse = await axios.get(
    //       plan.file.path + "?nocache=" + new Date().getTime(),
    //       {
    //         responseType: "arraybuffer"
    //       }
    //     );
    //     const image = Buffer.from(imageResponse.data, "binary").toString(
    //       "base64"
    //     );
    //     imageString = image ? "data:image/jpeg;base64, " + image : "";

    //     this.plans.push({
    //       id: plan.id,
    //       name: plan.name,
    //       offline: plan.offline,
    //       file: {
    //         id: plan.file.id,
    //         path: imageString
    //       },
    //       pixelDistance: {
    //         a: {
    //           x: 0,
    //           y: 0
    //         },
    //         b: {
    //           x: 100,
    //           y: 100
    //         }
    //       },
    //       realDistance: {
    //         a: {
    //           x: 0,
    //           y: 0
    //         },
    //         b: {
    //           x: 1,
    //           y: 1
    //         }
    //       }
    //     });
    //   } catch (error) {
    //     // tslint:disable-next-line: no-console
    //     console.log(
    //       "Plan-Bild '" +
    //         plan.file.path +
    //         "' des Plans '" +
    //         plan.name +
    //         "' konnte nicht heruntergeladen werden"
    //     );
    //   }
    // }

    await this.save(propertyId);
  }

  public async load(propertyId = 0) {
    if (propertyId === 0) {
      propertyId = PropertyPlanStorage.lastPropertyId;
    } else {
      PropertyPlanStorage.lastPropertyId = propertyId;
    }

    const plans = await this.storage.get<string>(
      storageKeys.plans + "+" + propertyId
    );

    if (plans) {
      this.plans = JSON.parse(plans);
    } else {
      this.plans = [];
    }
  }

  private async save(propertyId: number) {
    await this.storage.set(
      storageKeys.plans + "+" + propertyId,
      JSON.stringify(this.plans)
    );
  }
}
