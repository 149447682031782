import { AsyncKeyValueStorage } from "./AsyncKeyValueStorage";
import { set, get, keys, del, clear, createStore, UseStore } from "idb-keyval";

export class IndexedDBKeyValueStorage
  implements AsyncKeyValueStorage<string, any> {
  private store: UseStore;

  public constructor(db: string = "keyval-store", store: string = "keyval") {
    this.store = createStore(db, store);
  }

  public set(key: string, value: any): Promise<void> {
    return set(key, value, this.store);
  }

  public get<T>(key: string): Promise<T | undefined> {
    return get(key, this.store);
  }

  public async move(oldKey: string, newKey: string): Promise<void> {
    const val = await this.get(oldKey);
    await this.remove(oldKey);
    await this.set(newKey, val);
  }

  public async remove(key: string): Promise<boolean> {
    try {
      await del(key, this.store);
      return true;
    } catch (error) {
      return false;
    }
  }

  public keys(): Promise<string[]> {
    return keys(this.store) as Promise<string[]>;
  }

  public async has(key: string): Promise<boolean> {
    const allKeys = await keys(this.store);
    return allKeys.includes(key);
  }

  public clear(): Promise<void> {
    return clear(this.store);
  }

  public async empty(): Promise<boolean> {
    const allKeys = await keys(this.store);
    return !allKeys || allKeys.length === 0;
  }

  public available(): boolean {
    return true;
  }
}
