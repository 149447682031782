import { Access } from "../../dtos/Access";
import { AccessGraphQLProvider } from "./AccessGraphQLProvider";
import { AccessProvider } from "./AccessProvider";

export interface AccessRepository {
  shareAccesses(accesses: Access[]): Promise<number[]>;
  unshareAccesses(accesses: Access[]): Promise<number[]>;
}

export class DefaultAccessRepository {
  public constructor(
    private webProvider: AccessProvider = new AccessGraphQLProvider()
  ) {}

  public shareAccesses(accesses: Access[]): Promise<number[]> {
    return this.webProvider.shareAccesses(accesses);
  }

  public unshareAccesses(accesses: Access[]): Promise<number[]> {
    return this.webProvider.unshareAccesses(accesses);
  }
}
