
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component<Accordion>({
  props: {
    connected: {
      type: Boolean,
      default: false
    }
  }
})
export default class Accordion extends Vue {
  @Prop({ type: Number }) protected readonly value?: number;
  @Prop({ type: Array, default: () => [] })
  protected readonly items!: unknown[];
  @Prop({ type: Boolean }) protected readonly flat?: boolean;
  @Prop({ type: Boolean }) protected readonly focusable?: boolean;
  @Prop({ type: Number }) protected readonly leftPadding?: number;
  @Prop({ type: Number }) protected readonly rightPadding?: number;
  @Prop({ type: Number }) protected readonly bottomPadding?: number;

  @Watch("value")
  protected onValueChanged(value: number | null) {
    this.selectedItem = value != null ? this.items[value] : null;
  }

  protected selectedItem: unknown | null = null;

  protected panelChanged(index: number | null) {
    const item = index != null ? this.items[index] : this.selectedItem;
    this.stateChanged(item, index);
  }

  protected stateChanged(item: any, index: number | null) {
    if (item !== this.selectedItem) {
      this.$emit("open", item, index);
      this.selectedItem = item;
    } else {
      this.$emit("close", item, index);
      this.selectedItem = null;
    }
  }

  protected get cssProps() {
    return {
      "--left-padding": (this.leftPadding ?? 24) + "px",
      "--right-padding": (this.rightPadding ?? 24) + "px",
      "--bottom-padding": (this.bottomPadding ?? 16) + "px"
    };
  }
}
