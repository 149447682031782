
import Vue from "vue";
import Component from "vue-class-component";

import CreateTicketPage from "@/inspection/ticket/views/CreateTicketPage.vue";

import IconButton from "@/components/basic/IconButton.vue";
import RequestButton from "@/components/basic/RequestButton.vue";
import ErrorMessage from "@/components/basic/ErrorMessage.vue";
import InfoTooltip from "@/components/basic/InfoTooltip.vue";

import FullScreenDialog from "@/components/layout/FullScreenDialog.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Chip from "@/components/layout/Chip.vue";
import AssignmentForm from "../../widgets/AssignmentForm.vue";

import Checkbox from "@/components/form/Checkbox.vue";

import PaginatedDataTable from "@/components/collection/new/PaginatedDataTable.vue";

import {
  AssignmentListModel,
  tableHeaders
} from "../../models/AssignmentListModel";
import { Authorizator } from "@/common/interactors/Authorizator";
import { AssignmentModel } from "../../models/AssignmentModel";
import { Translator } from "@/shared/i18n/Translator";
import { AssignmentState } from "@/inspection/shared/dtos/Assignment";
import { Ticketable } from "@/inspection/shared/models/Ticketable";
import { Checklist } from "@/inspection/shared/models/Checklist";
import { ChecklistEntry } from "@/inspection/shared/models/ChecklistEntry";
import { SelectionItem } from "@/shared/form/FormTypes";

type AssignmentAction = "followup-inspection" | "edit-assignment";

@Component<AssignmentList>({
  components: {
    IconButton,
    RequestButton,
    ErrorMessage,
    Checkbox,
    PaginatedDataTable,
    FullScreenDialog,
    Row,
    Column,
    Chip,
    InfoTooltip,
    CreateTicketPage,
    AssignmentForm
  },
  props: {
    propertyId: {
      type: String,
      default: undefined
    },
    plantType: {
      type: String,
      default: undefined
    },
    search: {
      type: String,
      default: ""
    }
  },
  watch: {
    search(_: string) {
      this.load(1);
    }
  }
})
export default class AssignmentList extends Vue {
  protected model = new AssignmentListModel();
  protected propertyId?: string;
  protected plantType?: string;
  protected search!: string;

  protected createTicketDialogVisible = false;
  protected followUpAssignmentDialogVisible = false;
  protected selectedAssignment?: AssignmentModel | null = null;

  protected get headers() {
    return tableHeaders;
  }

  protected get propertyIds() {
    if (this.plantType) {
      return this.$store.getters.getPropertyIdsOfPlantType(this.plantType);
    } else if (this.propertyId) {
      return [parseInt(this.propertyId.toString(), 10)];
    } else {
      return [];
    }
  }

  protected get editAssignmentButtonVisible() {
    return Authorizator.canEditAssignments();
  }

  protected get editChecklistButtonsVisible() {
    return Authorizator.canEditChecklists();
  }

  protected get addTicketButtonVisible() {
    return Authorizator.canEditChecklists();
  }

  protected get ticketContext() {
    return this.selectedAssignment
      ? [this.selectedAssignment.assignmentNumber]
      : [];
  }

  protected mounted() {
    this.load();
  }

  protected async load(page = 1) {
    this.model.load({
      page,
      plantType: this.plantType,
      propertyIds: this.propertyIds,
      search: this.search
    });
  }

  protected goToAssignment(id: string) {
    this.$router.push({ name: "assignment", params: { assignmentId: id } });
  }

  protected goToPreparation(id: string) {
    this.$router.push({
      name: "prepare-checklist",
      params: { assignmentId: id }
    });
  }

  protected goToInspection(id: string) {
    this.$router.push({ name: "inspection", params: { assignmentId: id } });
  }

  protected createAssignmentTicket(assignment: AssignmentModel) {
    this.selectedAssignment = assignment;
    this.createTicketDialogVisible = true;

    // this.$router.push({
    //   name: "create-ticket",
    //   query: {
    //     propertyId: assignment.propertyId.toString(),
    //     checklistEntryId: assignment.firstEntryId.toString(),
    //     checklistId: assignment.checklistId.toString(),
    //     checklistType: assignment.checklistType,
    //     redirect: "_back"
    //   }
    // });
  }

  protected get selectedPropertyId() {
    return parseInt(this.selectedAssignment?.propertyId.toString() ?? "0", 10);
  }

  protected get checklistType() {
    return this.selectedAssignment?.checklistType;
  }

  protected get ticketable(): Ticketable<unknown> | undefined {
    return this.selectedAssignment?.firstEntryId
      ? ChecklistEntry.from({
          id: parseInt(this.selectedAssignment.firstEntryId.toString(), 10)
        })
      : this.selectedAssignment?.checklistId
      ? Checklist.from({
          id: parseInt(
            this.selectedAssignment?.checklistId.toString() ?? "0",
            10
          )
        })
      : undefined;
  }

  protected canCreateFollowUpInspection(assignment: AssignmentModel) {
    return assignment.hasPlant && assignment.prepared;
  }

  protected getAssignmentActions(assignment: AssignmentModel) {
    const actions: SelectionItem[] = [];
    const canFollowupInspection = this.canCreateFollowUpInspection(assignment);

    actions.push({
      text: "Folgebegehung starten",
      value: "followup-inspection",
      disabled: !canFollowupInspection,
      item: !assignment.hasPlant
        ? "Das Erstellen von Folgebegehungen mit beliebigen Anlagen ist im Moment noch nicht möglich"
        : "Der Vorbereitungsschritt des Auftrag muss abgeschlossen sein."
    });

    actions.push({
      text: "Auftrag bearbeiten",
      value: "edit-assignment"
    });

    return actions;
  }

  protected getStateTranslation(state: string) {
    return Translator.t(state);
  }

  protected getStateColor(state: AssignmentState) {
    switch (state) {
      case AssignmentState.Preparation:
        return "";
      case AssignmentState.Inspection:
        return "primary";
      case AssignmentState.Finished:
        return "accent";
    }
  }

  protected actionSelected(
    action: AssignmentAction,
    assignment: AssignmentModel
  ) {
    if (action === "edit-assignment") {
      this.goToAssignment(assignment.id.toString());
    } else if (action === "followup-inspection") {
      this.selectedAssignment = assignment;
      this.followUpAssignmentDialogVisible = true;
    }
  }
}
