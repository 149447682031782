import { storageKeys } from "@/data/storageKeys";
import { Dictionary } from "@/shared/datastructures/Dictionary";
import { Settings } from "@/shared/datastructures/Settings";

export interface PropertyPartTypes {
  structure_types: PropertyPartType[];
}

export interface PropertyPartType extends Dictionary<string> {
  name: string;
  icon: string;
}

export class PropertyPartTypeSettings extends Settings<PropertyPartTypes> {
  protected get storageKey(): string {
    return storageKeys.structureTypes;
  }

  public getIcon(typeName: string) {
    const type = this.findType(typeName);
    return type ? type.icon : "";
  }

  public allTypes() {
    const settings = this.get();
    return settings.structure_types ? settings.structure_types : [];
  }

  private findType(typeName: string) {
    const settings = this.get();
    return settings.structure_types
      ? settings.structure_types.find(t => t.name === typeName)
      : undefined;
  }
}
