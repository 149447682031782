
import Vue from "vue";
import Component from "vue-class-component";

import { DWSBaseSyncViewModel } from "@/document/vms/DWSBaseSyncViewModel";
import { DWSBaseSyncController } from "@/document/controllers/DWSBaseSyncController";
import { DWSBaseSyncPresenter } from "@/document/presenters/DWSBaseSyncPresenter";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Dialog from "@/components/layout/Dialog.vue";
import SmallHeading from "@/components/layout/SmallHeading.vue";

import Value from "@/components/basic/Value.vue";

import { AxiosGraphQLConnection } from "../../gateways/graphql/connection/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { GraphQLDocumentService } from "../services/DocumentService";
import { UserSettings } from "../../storage/UserSettings";
import { LocalStorage } from "../../storage/LocalStorage";
import { GraphQLDWSBaseService } from "../services/DWSBaseService";

@Component<DWSBaseSyncView>({
  components: {
    Row,
    Column,
    Dialog,
    SmallHeading,
    Value
  },
  watch: {
    "vm.close"(close: boolean) {
      if (close) {
        this.$router.push({ name: "home" });
      }
    }
  }
})
export default class DWSBaseSyncView extends Vue {
  private vm = new DWSBaseSyncViewModel();

  private get metumId() {
    return this.$route.params.metumId;
  }

  private get controller() {
    return new DWSBaseSyncController(
      new DWSBaseSyncPresenter(this.vm),
      new GraphQLDWSBaseService(
        new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger()))
      )
    );
  }

  private mounted() {
    this.controller.init();
  }
}
