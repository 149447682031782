
import Vue from "vue";
import Component from "vue-class-component";

import Img from "@/components/basic/Img.vue";
import FileInput from "@/components/form/FileInput.vue";

@Component<SingleImageUpload>({
  components: {
    Img,
    FileInput
  },
  props: {
    value: {
      type: Object,
      default() {
        return { label: "Label", value: null, error: "", loading: false };
      }
    },
    label: {
      type: String,
      default: ""
    },
    src: {
      type: String,
      default: ""
    },
    uploadable: {
      type: Boolean,
      default: true
    },
    deletable: {
      type: Boolean,
      default: true
    },
    uploadLoading: {
      type: Boolean,
      default: false
    },
    uploadError: {
      type: String,
      default: ""
    },
    deleteLoading: {
      type: Boolean,
      default: false
    },
    deleteError: {
      type: String,
      default: ""
    },
    maxHeight: {
      type: [Number, String],
      default: undefined
    },
    contain: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class SingleImageUpload extends Vue {
  private emitChange(value: any) {
    this.$emit("change", value);
  }
  private emitDelete() {
    this.$emit("delete");
  }
}
