import { ServerResponse } from "@/shared/datastructures/Response";
import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { TicketEntity } from "../entities/TicketEntity";
import { InspectionRepoContainer } from "../repositories/InspectionRepoContainer";
import { Ticket } from "./Ticket";
import { TicketCollection } from "./TicketCollection";

interface TicketListProps {
  tickets?: Ticket[];
}

interface TicketListOptions {
  dontClearOnLoad: boolean;
}

interface TicketListLoadOptions {
  excludeImages?: boolean;
}

export class TicketList extends RequestModel<TicketEntity[]> {
  public tickets: Ticket[];

  public loadResponse = new ServerResponse<TicketCollection>();

  public constructor(
    props?: TicketListProps,
    private options?: TicketListOptions
  ) {
    super();
    this.tickets = props?.tickets ?? [];
  }

  public get empty() {
    return this.tickets.length === 0;
  }

  public isLastTicket(ticket: Ticket) {
    return this.tickets.indexOf(ticket) === this.tickets.length - 1;
  }

  public removeTicket(ticket: Ticket) {
    this.tickets = this.tickets.filter(t => !t.isSameAs(ticket));
  }

  public async removeSyncedTickets() {
    const syncedTickets = this.tickets.filter(t => t.completed);

    for (const syncedTicket of syncedTickets) {
      await syncedTicket.delete(true);
      syncedTicket.clearImages();
    }

    this.tickets = this.tickets.filter(t => !t.completed);
  }

  public load(cached = false, options?: TicketListLoadOptions) {
    const repo = cached
      ? InspectionRepoContainer.cachedTicketRepo
      : InspectionRepoContainer.ticketRepo;

    RequestHandler.handle(repo.getTickets({ excludeImages: options?.excludeImages }), this.loadResponse, {
      onSuccess: tickets => this.from(tickets.toEntities())
    });
  }

  public from(entities?: TicketEntity[]): void {
    if (this.options?.dontClearOnLoad) {
      const newTickets =
        entities
          ?.filter(e => !this.containsTicketWithSyncId(e.syncId))
          ?.map(e => Ticket.from(e)) ?? [];

      this.tickets.push(...newTickets);

      this.tickets = this.tickets.filter(
        t => !!entities?.find(e => e.id === t.id || e.syncId === t.syncId)
      );
    } else {
      this.tickets = entities?.map(t => Ticket.from(t)) ?? [];
    }
  }

  public static from(images?: Ticket[]) {
    return new TicketList({
      tickets: images
    });
  }

  private containsTicketWithSyncId(syncId?: string) {
    return !!this.tickets.find(t => t.syncId === syncId);
  }
}
