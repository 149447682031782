import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { GraphQLConnection } from "@/gateways/graphql/connection/GraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import {
  ChecklistEntryEntity,
  convertGraphQlToChecklistEntryEntity
} from "../../entities/ChecklistEntity";
import { ChecklistProvider } from "./ChecklistProvider";

export class ChecklistGraphQLProvider implements ChecklistProvider {
  public constructor(
    private connection: GraphQLConnection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    )
  ) {}

  public async loadChecklistEntry(id: number): Promise<ChecklistEntryEntity> {
    const result = await this.connection.query(
      "newChecklistEntry",
      {
        id
      },
      [
        "id",
        "name",
        "description",
        "preparation_state",
        "state",
        "checklist_entry_id",
        "has_tickets",
        "has_entries",
        { name: "plant", fields: ["id"] },
        {
          name: "tickets",
          fields: [
            "id",
            "title",
            "description",
            "priority",
            { name: "images", fields: ["id", "path"] }
          ]
        },
        {
          name: "entries",
          fields: [
            "id",
            "name",
            "description",
            "preparation_state",
            "state",
            "checklist_entry_id",
            "has_tickets",
            "has_entries",
            { name: "plant", fields: ["id"] }
          ]
        }
      ]
    );

    return convertGraphQlToChecklistEntryEntity(result.data);
  }

  public async setProtectionGoalNiveau(
    id: number,
    protectionGoalNiveau: string
  ): Promise<number> {
    const result = await this.connection.mutation(
      "setProtectionGoalNiveau",
      {
        input: {
          id,
          protection_goal_niveau: protectionGoalNiveau
        }
      },
      []
    );

    return result.data;
  }

  public async setPreparationState(id: number, state: string): Promise<number> {
    const result = await this.connection.mutation(
      "setChecklistPreparationState",
      {
        input: {
          id,
          preparation_state: state
        }
      },
      []
    );

    return result.data;
  }

  public async setInspectionState(id: number, state: string): Promise<number> {
    const result = await this.connection.mutation(
      "setChecklistState",
      {
        input: {
          id,
          state
        }
      },
      []
    );

    return result.data;
  }
}
