import { TicketCollection } from "@/inspection/shared/models/TicketCollection";
import { TicketRepository } from "@/inspection/shared/repositories/ticketRepository/TicketRepository";
import { Client } from "@/property/shared/models/Client";
import { Plant } from "@/property/shared/models/Plant";
import { PlantChecklistEntry } from "@/property/shared/models/PlantChecklistEntry";
import { Property } from "@/property/shared/models/Property";
import { UseCase } from "@/shared/datastructures/UseCase";
import { RequestHandler } from "@/shared/utils/RequestHandler";

export class LoadTicketsUseCase extends UseCase<
  LoadTicketsOptions,
  TicketCollection
> {
  public constructor(private ticketRepo: TicketRepository) {
    super();
  }

  public async do(input: LoadTicketsOptions): Promise<TicketCollection> {
    let ticketCollection = new TicketCollection();

    await RequestHandler.handle(
      this.ticketRepo.getTickets(input),
      this.response,
      {
        onSuccess: tickets => (ticketCollection = tickets)
      }
    );

    return ticketCollection;
  }
}

export interface LoadTicketsOptions {
  client?: Client;
  properties?: Property[];
  plant?: Plant;
  plantChecklistEntry?: PlantChecklistEntry;
  search?: string;
  priorities?: string[];
  states?: string[];
  missingChecklistEntryReference?: boolean;
  page?: number;
  excludeImages?: boolean;
}
