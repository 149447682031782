import { PropertyModel } from "@/property/property/models/PropertyModel";
import { Plant } from "@/property/shared/dtos/Plant";

export class PlantModel {
  public static from(plant: Plant): PlantModel {
    return new PlantModel(
      plant.id,
      plant.type,
      plant.checklist,
      PropertyModel.from(plant.property)
    );
  }

  public constructor(
    public id: number,
    public type: string,
    public checklist: string,
    public property: PropertyModel
  ) {}
}
