import { VueAccessLinkBuilder } from "@/common/access/useCases/AccessLinkBuilder";
import { Access, AccessResource } from "../../models/Access";
import { Accesses } from "../../models/Accesses";
import { AccessGraphQlProvider } from "./AccessGraphQlProvider";

export interface AccessRepository {
  addAccess(access: Access): Promise<Access>;
  getAccessesOfResource(resource: AccessResource): Promise<Accesses>;
  updateAccess(access: Access): Promise<Access>;
  deleteAccess(access: Access): Promise<Access>;
}

export class DefaultAccessRepository implements AccessRepository {
  public constructor(
    private provider = new AccessGraphQlProvider(),
    private linkBuilder = new VueAccessLinkBuilder()
  ) {}

  public async addAccess(access: Access): Promise<Access> {
    const accessEntity = await this.provider.addAccess(access);
    return this.generateLink(Access.from(accessEntity));
  }

  public async getAccessesOfResource(
    resource: AccessResource
  ): Promise<Accesses> {
    const accessEntities = await this.provider.loadAccessesOfResource(resource);
    return this.generateLinks(Accesses.from(accessEntities));
  }

  public async updateAccess(access: Access): Promise<Access> {
    const accessEntity = await this.provider.updateAccess(access);
    return this.generateLink(Access.from(accessEntity));
  }

  public async deleteAccess(access: Access): Promise<Access> {
    const accessEntity = await this.provider.deleteAccess(access);
    return Access.from(accessEntity);
  }

  private generateLinks(accesses: Accesses) {
    accesses.elements.forEach(access => this.generateLink(access));
    return accesses;
  }

  private generateLink(access: Access) {
    access.link = this.linkBuilder.build(access);
    return access;
  }
}
