import { NumericalId } from "@/datastructures/NumericalId";

export class Authenticator implements IAuthenticator {
  public constructor(private gateway: AuthenticatorGateway) {}

  public login(data: LoginData): Promise<AuthPayload> {
    return this.gateway.login(data);
  }

  public getPermissions(userId: NumericalId): Promise<string> {
    return this.gateway.getPermissions(userId);
  }
}

export interface IAuthenticator {
  login(data: LoginData): Promise<AuthPayload>;
  getPermissions(userId: NumericalId): Promise<string>;
}

export interface AuthenticatorGateway {
  login(data: LoginData): Promise<AuthPayload>;
  getPermissions(userId: NumericalId): Promise<string>;
}

export interface LoginData {
  email: string;
  password: string;
}

export interface AuthPayload {
  accessToken: string;

  userId: number;
  firstname: string;
  lastname: string;

  clientId: number;
  clientName: string;

  role: string;
  rolePrio: number;
  permissions: string;
}
