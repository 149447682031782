import { MathUtils } from "../utils/MathUtils";
import { TypeChecker } from "../utils/TypeChecker";
import { Dictionary } from "./Dictionary";

export type GenericData = Dictionary<GenericDataEntry>;
export type GenericDataEntry = string | GenericData | GenericData[];

export interface GenericDataMapping {
  from: string;
  to?: string;
  skip?: boolean;
}

export class GenericDataConverter<T extends object> {
  public constructor(public mappings: GenericDataMapping[] = []) {}

  public convert(data?: object): T | undefined {
    if (!data) {
      return undefined;
    }
    return this.convertPart(data, "") as T;
  }

  private convertPart(data: object, path: string): object {
    let converted: any = {};

    for (const entry of Object.entries(data)) {
      const unmappedKey = entry[0];
      const extendedPath = path ? `${path}.${unmappedKey}` : unmappedKey;
      const unsafeKey = this.getMappedKey(entry[0], extendedPath);
      const key = unsafeKey ?? entry[0];

      if (this.findMapping(extendedPath)?.skip) {
        continue;
      }

      if (TypeChecker.isArray(entry[1])) {
        converted[key] = entry[1].map(d => this.convertPart(d, extendedPath));
      } else if (TypeChecker.isObject(entry[1])) {
        const convertedSubPart = this.convertPart(entry[1], extendedPath);
        if (unsafeKey) {
          converted[key] = convertedSubPart;
        } else {
          converted = {
            ...converted,
            ...convertedSubPart
          };
        }
      } else {
        entry[1] = entry[1] ?? "";
        converted[key] = entry[1];

        if (
          TypeChecker.isNumber(entry[1]) &&
          !entry[1].toString().startsWith("+")
        ) {
          const parsedInt = parseInt(entry[1]?.toString() ?? "", 10);
          const parsedFloat = parseFloat(entry[1]?.toString() ?? "");

          if (!isNaN(parsedInt) && !isNaN(parsedFloat)) {
            converted[key] =
              parsedInt === parsedFloat ? parsedInt : parsedFloat;
          } else if (!isNaN(parsedInt)) {
            converted[key] = parsedInt;
          } else if (!isNaN(parsedFloat)) {
            converted[key] = parsedFloat;
          }
        } else {
          const parsedBoolean = MathUtils.parseBooleanOrUndefined(entry[1]);

          if (parsedBoolean !== undefined) {
            converted[key] = parsedBoolean;
          }
        }
      }
    }

    return converted;
  }

  private getMappedKey(sourceKey: string, path: string): string | undefined {
    const mapping = this.findMapping(path) ?? this.findMapping(sourceKey);
    return mapping ? mapping.to : sourceKey;
  }

  private findMapping(sourceKey: string): GenericDataMapping | undefined {
    return this.mappings.find(m => m.from === sourceKey);
  }
}
