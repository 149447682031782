
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { PlantChecklistEntry } from "@/property/shared/models/PlantChecklistEntry";
import { PlantChecklistEntryCollection } from "@/property/shared/models/PlantChecklistEntryCollection";
import { SelectionItem } from "@/shared/form/FormTypes";

@Component<PlantChecklistEntrySelect>({
  components: {}
})
export default class PlantChecklistEntrySelect extends Vue {
  @Prop({ type: Object, default: () => new PlantChecklistEntry() })
  protected readonly checklistEntry!: PlantChecklistEntry;
  @Prop({ type: Object, default: () => new PlantChecklistEntryCollection() })
  protected readonly checklistEntries!: PlantChecklistEntryCollection;
  @Prop({ type: Boolean, default: false })
  protected readonly loading!: boolean;
  @Prop({ type: String, default: "" })
  protected readonly error!: string;

  protected get selectedId() {
    return this.checklistEntry.id ?? "";
  }

  public get hasItems() {
    return this.items.length > 0;
  }

  protected get items(): SelectionItem[] {
    return (
      this.checklistEntries.entries?.map(e => ({
        text: e.name ?? "",
        value: e.id?.toString() ?? "",
        item: e
      })) ?? []
    );
  }

  protected emitChange(selected?: SelectionItem) {
    this.$emit("change", selected?.item);
  }
}
