export abstract class Model<T> {
  public id?: number;
  public deletedAt?: Date;

  public get exists() {
    return !!this.id;
  }

  public get deleted() {
    return !!this.deletedAt && this.deletedAt.getTime() < Date.now();
  }

  public equals(other: Model<T>) {
    return this === other || (this.exists && this.id === other.id);
  }

  public copy(): Model<T> {
    const modelCopy = this.newInstance();
    modelCopy.from(this.toEntity());
    return modelCopy;
  }

  public abstract toEntity(): T;
  public abstract from(entity?: T): void;
  protected abstract newInstance(): Model<T>;
}
