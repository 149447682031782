
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import App from "@/App.vue";
import Textarea from "@/components/form/Textarea.vue";

import { Comment as CommentModel } from "@/common/shared/models/Comment";
import { DateUtils } from "@/shared/utils/DateUtils";
import { DeleteCommentUseCase } from "../useCases/DeleteCommentUseCase";
import { UpdateCommentUseCase } from "../useCases/UpdateCommentUseCase";
import { ChangeInternalCommentStateUseCase } from "../useCases/ChangeInternalCommentStateUseCase";
import { CommonRepoContainer } from "@/common/shared/repositories/CommonRepoContainer";
import { Authorizator } from "@/common/interactors/Authorizator";
import { UserSettings } from "@/storage/UserSettings";

@Component<Comment>({
  components: {}
})
export default class Comment extends Vue {
  @Prop({ type: Object, required: true })
  public readonly comment!: CommentModel;

  protected previousText = "";
  protected editing = false;

  protected commentDeleter = new DeleteCommentUseCase(
    CommonRepoContainer.commentRepo
  );

  protected commentUpdater = new UpdateCommentUseCase(
    CommonRepoContainer.commentRepo
  );

  protected commentStateChanger = new ChangeInternalCommentStateUseCase(
    CommonRepoContainer.commentRepo
  );

  protected get commentColor() {
    return this.comment.internal ? "grey lighten-2" : "grey lighten-3";
  }

  protected get internalButtonColor() {
    return this.comment.internal ? "black" : "grey";
  }

  protected get impersonatedCreatorText() {
    return `Durch ${this.comment.realCreator?.name} erstellt`;
  }

  protected get commentDateText() {
    const editedText = this.comment.edited ? " - bearbeitet" : "";
    return this.formattedDate + editedText;
  }

  protected get formattedDate() {
    return this.comment.date
      ? DateUtils.format(this.comment.date, "[um] HH:mm [Uhr am] DD. MMM YYYY")
      : "???";
  }

  protected get displayInternalCheckbox() {
    return (
      this.displayEditButton &&
      UserSettings.loggedIn() &&
      !this.comment.isGuestComment
    );
  }

  protected get displayInternalSeparator() {
    return (
      (this.displayEditButton || this.displayDeleteButton) &&
      this.displayInternalCheckbox
    );
  }

  protected get internLabel() {
    if (this.comment.internal) {
      return this.isMobile ? "Intern" : "Nur intern sichtbar";
    } else {
      return this.isMobile ? "Öffentlich" : "Öffentlich sichtbar";
    }
  }

  protected get isMobile() {
    return App.instance?.isMobile;
  }

  protected get deleteCommentConfirmText() {
    return `Möchten Sie den Kommentar '${this.comment.text}' von ${this.comment.creator?.name} wirklich entfernen?`;
  }

  protected get displayButtonSeparator() {
    return this.displayEditButton && this.displayDeleteButton;
  }

  protected get displayEditButton() {
    return (
      Authorizator.canEditTicketComments() ||
      (this.comment.isCreator(UserSettings.getUser()) &&
        Authorizator.canEditOwnTicketComments())
    );
  }

  protected get editTextarea() {
    return this.$refs.editTextarea as Textarea;
  }

  protected get displayDeleteButton() {
    return (
      Authorizator.canDeleteTicketComments() ||
      (this.comment.isCreator(UserSettings.getUser()) &&
        Authorizator.canDeleteOwnTicketComments())
    );
  }

  protected startEditing() {
    this.previousText = this.comment.text ?? "";
    this.editing = true;
    this.emitEditStart();
  }

  protected async updateComment() {
    if (!this.editing) {
      return;
    }

    if (this.comment.text === this.previousText) {
      this.cancelEditing();
    } else {
      const updatedComment = await this.commentUpdater.do(this.comment);

      if (updatedComment) {
        this.comment.edited = true;
        this.editing = false;
      }
    }
  }

  public async cancelEditing() {
    if (this.editing) {
      this.editing = false;
      this.comment.text = this.previousText;
    }
  }

  protected async changeInternalState(internal: boolean) {
    const prevInternalState = this.comment.internal;
    this.comment.internal = internal;

    try {
      await this.commentStateChanger.do({
        comment: this.comment,
        internal
      });
    } catch {
      this.comment.internal = prevInternalState;
    }
  }

  protected async deleteComment() {
    const deletedComment = await this.commentDeleter.do(this.comment);

    if (deletedComment) {
      this.emitDeleted();
    }
  }

  protected emitDeleted() {
    this.$emit("deleted", this.comment);
  }

  protected emitEditStart() {
    this.$emit("edit:start", this.comment);
  }
}
