import { AccessModel } from "@/property/client/models/AccessModel";
import { RoleModel } from "@/property/client/models/RoleModel";

export abstract class AccessResourceVm {
  public sharing = false;

  public abstract get children(): undefined | AccessResourceVm[];
  public abstract get type(): string;
  public abstract get shareable(): boolean;

  public get isSharing() {
    return this.shareable && this.sharing;
  }

  public get accessModel() {
    return new AccessModel({
      roleId: this.role?.id,
      resourceId: this.id,
      resourceType: this.type
    });
  }

  public constructor(public id = 0, public name = "", public role?: RoleModel) {
    this.sharing = role ? true : false;
  }

  public setSharing(sharing: boolean) {
    this.sharing = sharing;

    if (sharing === false) {
      this.role = undefined;
    }
  }

  public setRole(role: RoleModel) {
    this.role = role;
  }

  public abstract loadChildren(): Promise<void>;

  protected addResources(
    resources: AccessResourceVm[],
    collection: AccessResourceVm[]
  ) {
    if (!resources || !collection) {
      return;
    }

    collection.push(...resources);
  }
}
