import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { PlanEntity } from "../entities/PlanEntity";
import { PropertyReportContainer } from "../repositories/PropertyRepoContainer";
import { Plan } from "./Plan";
import { Property } from "./Property";

export enum PlanSelectionCacheStrategy {
  OnlyCache,
  OnlyNetwork,
  CacheAndNetwork
}

interface PlanCollectionLoadOptions {
  cacheStrategy?: PlanSelectionCacheStrategy;
  onlyOffline?: boolean;
}

export class PlanCollection extends RequestModel<PlanEntity[]> {
  public plans?: Plan[] = [];

  public get selectionItems() {
    return (
      this.plans?.map(p => ({
        value: p.id ?? 0,
        text: `${p.name}`,
        item: p
      })) ?? []
    );
  }

  public async loadSelection(
    property?: Property,
    options?: PlanCollectionLoadOptions
  ) {
    if (!property || !property.exists) {
      return;
    }

    await this.loadSelectionOfMultipleProperties([property], options);
  }

  public async loadSelectionOfMultipleProperties(
    properties?: Property[],
    options?: PlanCollectionLoadOptions
  ) {
    if (!properties || properties.length === 0) {
      return;
    }

    properties = properties.filter(p => p && p.exists);

    if (
      !options ||
      options.cacheStrategy === PlanSelectionCacheStrategy.CacheAndNetwork ||
      options.cacheStrategy === PlanSelectionCacheStrategy.OnlyCache
    ) {
      await this.loadAndHandleSelection(properties, true, options?.onlyOffline);
    }

    this.clearRequest();

    if (
      !options ||
      options.cacheStrategy === PlanSelectionCacheStrategy.CacheAndNetwork ||
      options.cacheStrategy === PlanSelectionCacheStrategy.OnlyNetwork
    ) {
      await this.loadAndHandleSelection(
        properties,
        false,
        options?.onlyOffline
      );
    }
  }

  public from(entities?: PlanEntity[]) {
    this.plans = entities?.map(p => Plan.from(p)) ?? [];
  }

  public static from(entities?: Plan[]) {
    const list = new PlanCollection();
    list.from(entities);
    return list;
  }

  private async loadAndHandleSelection(
    properties: Property[],
    cached = false,
    onlyOffline = false
  ) {
    const repo = cached
      ? PropertyReportContainer.planCacheRepo
      : PropertyReportContainer.planRepo;

    await RequestHandler.handleModel(
      () =>
        repo.getPlanSelection(
          0,
          properties.map(p => p.id ?? 0),
          onlyOffline
        ),
      this,
      undefined,
      {
        // onSuccess: plans =>
        //   (properties.plans = plans?.map(p => Plan.from(p)) ?? [])
      }
    );
  }
}
