export class NumericalId {
  public static fromString(id: string): NumericalId {
    let numericalId = parseInt(id, 10);
    numericalId = !isNaN(numericalId) ? numericalId : 0;
    return new NumericalId(numericalId);
  }

  public static onValidId(
    id: string | number | undefined,
    validCallback: (id: NumericalId) => void,
    notValidCallback?: () => void
  ) {
    if (id === undefined) {
      if (notValidCallback) {
        notValidCallback();
      }
      return;
    }

    const numericalId = NumericalId.fromString(
      typeof id === "string" ? id : id.toString()
    );

    if (numericalId.valid) {
      validCallback(numericalId);
    } else if (notValidCallback) {
      notValidCallback();
    }
  }

  public static invalidId() {
    return new NumericalId();
  }

  constructor(public readonly id: number = 0) {}

  public get valid() {
    return !isNaN(this.id) && this.id > 0;
  }
}
