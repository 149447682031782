
import Vue from "vue";
import Component from "vue-class-component";

import BasicLayout from "@/components/layout/BasicLayout.vue";

@Component<App>({
  components: {
    BasicLayout
  }
})
export default class App extends Vue {
  public static instance?: App;

  protected created() {
    App.instance = this;
  }

  public get isMobile() {
    return this.$vuetify.breakpoint.xs;
  }

  public get isTablet() {
    return this.$vuetify.breakpoint.smAndDown;
  }
}
