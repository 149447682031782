
import Vue from "vue";
import Component from "vue-class-component";

import { AssignmentDetailViewModel } from "@/expert/vms/AssignmentDetailViewModel";
import { AssignmentDetailPresenter } from "@/expert/presenters/AssignmentDetailPresenter";
import { AssignmentDetailController } from "@/expert/controllers/AssignmentDetailController";
// import { AssignmentManager } from "../../expert/interactors/AssignmentManager";
// import { AssignmentManagerGraphQLGateway } from "@/gateways/graphql/AssignmentManagerGraphQLGateway";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connection/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import ToggleCard from "@/components/layout/ToggleCard.vue";
import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import Value from "@/components/basic/Value.vue";
import RequestButton from "@/components/basic/RequestButton.vue";
import IconButton from "@/components/basic/IconButton.vue";

import EditableTextfield from "@/components/form/EditableTextfield.vue";
import EditableTextarea from "@/components/form/EditableTextarea.vue";
import EditableDatePicker from "@/components/form/EditableDatePicker.vue";
import { Authorizator } from "../../common/interactors/Authorizator";
import { AuthorizatorStorageGateway } from "../../gateways/storage/AuthorizatorStorageGateway";
import { LocalStorage } from "../../storage/LocalStorage";
import { AssignmentManager } from "../interactors/AssignmentManager";
import { AssignmentManagerGraphQLGateway } from "../../gateways/graphql/AssignmentManagerGraphQLGateway";
import { AssignmentManagerCachedGateway } from "../../gateways/cached/AssignmentManagerCachedGateway";

@Component<AssignmentDetailView>({
  components: {
    Page,
    Section,
    ToggleCard,
    Card,
    Grid,
    Row,
    Column,
    EditableTextfield,
    EditableTextarea,
    Value,
    RequestButton,
    IconButton,
    EditableDatePicker
  },
  watch: {
    "vm.goBack"(go: boolean) {
      this.$router.back();
    },
    "vm.createdAssignment"(assignmentId: string) {
      this.$router.push({
        name: "prepare-checklist",
        params: { assignmentId }
      });
    },
    "vm.deletedAssignment"(assignmentId: string) {
      this.$router.push({
        name: "assignments",
        params: { plantId: this.plantId }
      });
    }
  }
})
export default class AssignmentDetailView extends Vue {
  private vm = new AssignmentDetailViewModel();

  private get controller() {
    return new AssignmentDetailController(
      new AssignmentDetailPresenter(this.vm),
      new AssignmentManager(
        new AssignmentManagerCachedGateway(
          new AssignmentManagerGraphQLGateway(
            new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger()))
          )
        )
      )
    );
  }

  private get assignmentId() {
    return this.$route.params.assignmentId;
  }

  private get plantId() {
    return this.$route.params.plantId;
  }

  private mounted() {
    this.controller.init(this.plantId, this.assignmentId);
  }
}
