import SubjectTypes from "@/document/utils/SubjectTypes";
import { AccessModel } from "@/property/client/models/AccessModel";
import { ClientModel } from "@/property/client/models/ClientModel";
import { EmployeeModel } from "@/property/client/models/EmployeeModel";
import { StringUtils } from "@/shared/utils/StringUtils";

export class AccessRecipientVm {
  public key = StringUtils.uuid();
  public selectedClient: ClientModel | null = null;
  public selectedEmployee: EmployeeModel | null = null;

  public get selectedClientId() {
    return this.selectedClient?.id;
  }

  public get accessModel() {
    return new AccessModel({
      recipientId: this.selectedEmployee?.id,
      recipientType: SubjectTypes.employee()
    });
  }

  public selectClient(client: ClientModel) {
    this.selectedClient = client;
  }

  public selectEmployee(employee: EmployeeModel) {
    this.selectedEmployee = employee;
  }
}
