
import Vue from "vue";
import Component from "vue-class-component";

import DatePicker from "@/components/form/DatePicker.vue";
import Value from "@/components/basic/Value.vue";

import { ObjectUtils } from "@/utils/ObjectUtils";
import { DateUtils } from "@/shared/utils/DateUtils";

@Component<EditableDatePicker>({
  components: {
    DatePicker,
    Value
  },
  props: {
    value: {
      type: Object,
      default() {
        return { value: "", text: "", label: "Datum", error: "" };
      }
    },
    val: {
      type: [Date, String, Number],
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    editing: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: "event"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayTitle: {
      type: Boolean,
      default: true
    },
    boldValue: {
      type: Boolean,
      default: false
    }
  }
})
export default class EditableDatePicker extends Vue {
  private value!: any;
  private val!: Date | string | number;
  private label!: string;

  private get transformedValue() {
    const val = this.val || this.value.value;
    const label = this.label || this.value.label;
    return {
      value: DateUtils.format(val),
      label
    };
  }

  private emitInput(date: string) {
    this.$emit("input", date);
  }

  private emitChange(date: string) {
    this.$emit("change", date);
  }
}
