import { Client } from "@/property/shared/models/Client";
import { Employee } from "@/property/shared/models/Employee";
import { UserSettings } from "@/storage/UserSettings";

export class UserSessionProvider {
  public isImpersonated(): boolean {
    return UserSettings.isImpersonated();
  }

  public getImpersonatedUser(): Employee | undefined {
    if (this.isImpersonated()) {
      const client = new Client();
      client.id = UserSettings.getNumericClientId();
      const impersonated = new Employee();
      impersonated.id = UserSettings.getNumericUserId();
      impersonated.client = client;

      return impersonated;
    }

    return undefined;
  }
}
