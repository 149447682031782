import { InMemoryCache } from "@/shared/datastructures/InMemoryCache";
import {
  ClientAddressData,
  ClientContactData,
  ClientGeneralData
} from "../../dtos/ClientDtos";
import { ClientEntity } from "../../entities/ClientEntity";
import { ClientGraphQLProvider } from "./ClientGraphQLProvider";
import { ClientProvider } from "./ClientProvider";

export interface ClientRepository {
  getClients(): Promise<ClientEntity[]>;
  getClientSelection(): Promise<ClientEntity[]>;
  getClient(id: number): Promise<ClientEntity>;

  createClient(client: ClientEntity): Promise<number>;

  updateGeneralData(id: number, data: ClientGeneralData): Promise<number>;
  updateContactData(id: number, data: ClientContactData): Promise<number>;
  updateAddress(id: number, data: ClientAddressData): Promise<number>;
  uploadLogo(id: number, logo: File, context: string): Promise<number>;

  deleteLogo(id: number, context: string): Promise<number>;
  deleteClient(id: number): Promise<number>;
}

export class DefaultClientRepository {
  private cachedClientRequest = new InMemoryCache<Promise<ClientEntity[]>>();

  public constructor(
    private webProvider: ClientProvider = new ClientGraphQLProvider()
  ) {}

  public getClients(): Promise<ClientEntity[]> {
    return this.webProvider.loadClients();
  }

  public getClientSelection(): Promise<ClientEntity[]> {
    if (this.cachedClientRequest.hasCache()) {
      return this.cachedClientRequest.cached();
    } else {
      return this.cachedClientRequest.cache(
        this.webProvider.loadClientSelection()
      );
    }
  }

  public getClient(id: number): Promise<ClientEntity> {
    return this.webProvider.loadClient(id);
  }

  public createClient(client: ClientEntity): Promise<number> {
    return this.webProvider.createClient(client);
  }

  public updateGeneralData(
    id: number,
    data: ClientGeneralData
  ): Promise<number> {
    return this.webProvider.updateGeneralData(id, data);
  }

  public updateContactData(
    id: number,
    data: ClientContactData
  ): Promise<number> {
    return this.webProvider.updateContactData(id, data);
  }

  public updateAddress(id: number, data: ClientAddressData): Promise<number> {
    return this.webProvider.updateAddress(id, data);
  }

  public uploadLogo(id: number, logo: File, context: string): Promise<number> {
    return this.webProvider.uploadLogo(id, logo, context);
  }

  public deleteLogo(id: number, context: string): Promise<number> {
    return this.webProvider.deleteLogo(id, context);
  }

  public deleteClient(id: number): Promise<number> {
    return this.webProvider.deleteClient(id);
  }
}
