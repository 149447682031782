
import Vue from "vue";
import Component from "vue-class-component";

import Column from "@/components/layout/Column.vue";
import Row from "@/components/layout/Row.vue";
import UserMenu from "@/components/layout/UserMenu.vue";
import SyncPanelHeaderButton from "@/shared/syncPanel/SyncPanelHeaderButton.vue";

import { HeaderModel } from "@/shared/layout/models/HeaderModel";
import { PropertyModel } from "@/property/property/models/PropertyModel";

@Component<Header>({
  components: {
    Column,
    Row,
    UserMenu,
    SyncPanelHeaderButton
  },
  props: {
    userName: {
      type: String,
      default: ""
    },
    clientName: {
      type: String,
      default: ""
    },
    roleName: {
      type: String,
      default: "Admin"
    }
  },
  watch: {
    selectedProperties(properties: PropertyModel[]) {
      this.init();
    }
  }
})
export default class Header extends Vue {
  private model = new HeaderModel();

  private get selectedProperties() {
    return this.$store.state.property.selectedProperties;
  }

  private mounted() {
    this.init();
  }

  private init() {
    this.model.init(this.selectedProperties);
  }

  private navIconClicked() {
    this.$emit("navIconClicked");
  }

  private unselectProperties() {
    this.$store.dispatch("unselectProperties");
    this.$router.push({ name: "map" });
  }
}
