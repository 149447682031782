import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { GraphQLConnection } from "@/gateways/graphql/connection/GraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { RoleEntity } from "@/property/shared/entities/RoleEntity";

export interface GraphQlRole {
  id?: string;
  name?: string;
}

export class RoleGraphQlProvider {
  public constructor(
    private connection: GraphQLConnection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    )
  ) {}

  public static convertGraphQlToRoleEntity(data?: GraphQlRole): RoleEntity {
    if (!data) {
      return {};
    }

    return {
      id: data.id ? parseInt(data.id, 10) : undefined,
      name: data.name
    };
  }
}
