import { Commentable } from "@/common/shared/models/Comment";
import { Comments } from "@/common/shared/models/Comments";
import { CommentRepository } from "@/common/shared/repositories/commentRepository/CommentRepository";
import { UseCase } from "@/shared/datastructures/UseCase";
import { RequestHandler } from "@/shared/utils/RequestHandler";

export class LoadCommentsUseCase extends UseCase<
  Commentable,
  Comments | undefined
> {
  public constructor(private commentRepo: CommentRepository) {
    super();
  }

  public async do(commentable: Commentable): Promise<Comments | undefined> {
    if (!commentable.id) {
      throw new Error(
        "Zum Laden von Kommentaren muss die ID des kommentierten Objekts angegeben werden"
      );
    }

    await RequestHandler.handle(
      this.commentRepo.getComments(commentable),
      this.response
    );

    return this.response.result;
  }
}
