import {
  ISettingsManager,
  SettingsCatalogRow
} from "../interactors/SettingsManager";
import { NumericalId } from "@/datastructures/NumericalId";
import { IUserSettings } from "@/storage/UserSettings";
import { Page } from "@/datastructures/Page";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { PaginatedList } from "@/datastructures/PaginatedList";

export class SettingsListController {
  public constructor(
    private presenter: ISettingsListPresenter,
    private settingsManager: ISettingsManager,
    private userSettings: IUserSettings
  ) {}

  public init() {
    this.presenter.init();
    this.loadSettings(1);
  }

  // Actions
  public pageSwitched(page: number) {
    this.loadSettings(page);
  }

  public editSettingsGloballyButtonClicked(settingKey: string) {
    // NumericalId.onValidId(settingKey, id => {
    this.presenter.globalSettingToEdit = settingKey;
    // });
  }

  public editClientSettingsButtonClicked(settingKey: string) {
    // NumericalId.onValidId(settingKey, id => {
    this.presenter.clientSettingToEdit = settingKey;
    // });
  }

  // Requests
  private loadSettings(pageNumber: number) {
    this.presenter.currentPage = pageNumber;

    NumericalId.onValidId(this.userSettings.clientId, id => {
      const page = new Page(this.presenter.settingsPerPage, pageNumber);
      const request = this.settingsManager.loadSettingsCatalog(id, page);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadSettingsCatalogResponse = response),
        "Einstellungen konnten nicht geladen werden"
      );
    });
  }
}

export interface ISettingsListPresenter {
  // Routes
  globalSettingToEdit: string;
  clientSettingToEdit: string;

  // Data
  settingsPerPage: number;
  currentPage: number;

  // Responses
  loadSettingsCatalogResponse: FormResponse<PaginatedList<SettingsCatalogRow>>;

  // Methods
  init(): void;
}
