
import Vue from "vue";
import Component from "vue-class-component";

import { PersonDetailController } from "@/common/controllers/PersonDetailController";
import { PersonDetailPresenter } from "@/common/presenters/PersonDetailPresenter";
import { PersonDetailViewModel } from "@/common/vms/PersonDetailViewModel";

import Page from "@/components/layout/Page.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Section from "@/components/layout/Section.vue";
import Grid from "@/components/layout/Grid.vue";
import Card from "@/components/layout/Card.vue";
import ToggleCard from "@/components/layout/ToggleCard.vue";

import EditableCheckbox from "@/components/form/EditableCheckbox.vue";
import EditableSelect from "@/components/form/EditableSelect.vue";
import EditableTextfield from "@/components/form/EditableTextfield.vue";
import EditableTextarea from "@/components/form/EditableTextarea.vue";
import RequestButton from "@/components/basic/RequestButton.vue";
import ResponsiveIconButton from "@/components/basic/ResponsiveIconButton.vue";
import ConfirmDialog from "@/components/basic/ConfirmDialog.vue";
import IconButton from "@/components/basic/IconButton.vue";
import Alert from "@/components/basic/Alert.vue";

import { PersonEditor } from "../interactors/PersonEditor";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connection/AxiosGraphQLConnection";
import { PersonEditorGraphQLGateway } from "../../gateways/graphql/PersonEditorGraphQLGateway";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { Authorizator } from "../interactors/Authorizator";
import { AuthorizatorStorageGateway } from "../../gateways/storage/AuthorizatorStorageGateway";
import { LocalStorage } from "../../storage/LocalStorage";

@Component<PersonDetailView>({
  components: {
    Page,
    Row,
    Column,
    Section,
    Grid,
    Card,
    ToggleCard,
    EditableTextfield,
    EditableTextarea,
    RequestButton,
    ResponsiveIconButton,
    ConfirmDialog,
    IconButton,
    EditableSelect,
    EditableCheckbox,
    Alert
  },
  watch: {
    "vm.goBack"(go: boolean) {
      if (go) {
        this.$router.back();
      }
    },
    "vm.deletedPerson"(personId: string) {
      this.$router.push({ name: "people" });
    }
  }
})
export default class PersonDetailView extends Vue {
  private vm = new PersonDetailViewModel();

  private get controller() {
    return new PersonDetailController(
      new PersonDetailPresenter(this.vm),
      new PersonEditor(
        new PersonEditorGraphQLGateway(
          new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger()))
        )
      )
    );
  }

  private get personId() {
    return this.$route.params.personId;
  }

  private mounted() {
    this.controller.init(this.personId);
  }
}
