import { Form } from "./Form";
import { FormDefinition } from "./FormDefinition";

export class DynamicForm extends Form<unknown> {
  public constructor(
    protected definition: FormDefinition<unknown>,
    public validatedEvent?: (valid: boolean) => void,
    public name?: string
  ) {
    super(validatedEvent, name);
  }
}
