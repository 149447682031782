
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import SearchSelect from "@/components/form/SearchSelect.vue";

import { PropertyList } from "../../models/PropertyList";
import { Property } from "../../models/Property";
import { UserSettings } from "@/storage/UserSettings";
import { TypeChecker } from "@/shared/utils/TypeChecker";

@Component<PropertySelect>({
  components: {
    SearchSelect
  }
})
export default class PropertySelect extends Vue {
  @Prop({ type: [Number, Array], default: undefined })
  protected readonly val!: number | number[];
  @Prop({ type: String, default: "Gebäude" })
  protected readonly label!: string;
  @Prop({ default: false, type: Boolean })
  protected readonly disabled!: boolean;
  @Prop({ default: false, type: Boolean })
  protected readonly hideDetails!: boolean;
  @Prop({ default: false, type: Boolean })
  protected readonly lock!: boolean;
  @Prop({ default: false, type: Boolean })
  protected readonly clearable!: boolean;

  protected properties = new PropertyList();

  protected get multiple() {
    return TypeChecker.isArray(this.val);
  }

  protected get hasSelectedProperties() {
    return this.$store.getters.hasOnePropertySelected;
  }

  protected get hasPropertiesChosen() {
    return this.val && (!this.multiple || (this.val as number[]).length > 0);
  }

  protected get displayChooseAllSelectedPropertiesItem() {
    return this.multiple && this.hasSelectedProperties;
  }

  protected get selectedProperties(): Property[] {
    return this.$store.getters.getSelectedProperties;
  }

  protected get chosenProperties(): Property[] {
    if (this.multiple) {
      return this.properties.findByIds(this.val as number[]);
    } else {
      const property = this.properties.findById(this.val as number);
      return !!property ? [property] : [];
    }
  }

  protected clearSelection() {
    this.emitSelect([]);
  }

  protected chooseSelectedProperties() {
    if (this.multiple) {
      this.emitSelect(this.selectedProperties.map(p => p.id ?? 0));
    }
  }

  protected mounted() {
    this.load();
  }

  protected load() {
    this.properties.loadSelection(UserSettings.getNumericUserId());
  }

  protected emitSelect(propertyId: number | number[], property?: Property) {
    if (!this.multiple) {
      this.$emit("select", property);
    } else {
      this.$emit("select", propertyId);
    }
  }
}
