import { Ticket } from "@/inspection/shared/models/Ticket";
import { TicketRepository } from "@/inspection/shared/repositories/ticketRepository/TicketRepository";
import { UseCase } from "@/shared/datastructures/UseCase";
import { RequestHandler } from "@/shared/utils/RequestHandler";

export class ChangeTicketDescriptionUseCase extends UseCase<Ticket, number> {
  public constructor(private ticketRepo: TicketRepository) {
    super();
  }

  public async do(ticket: Ticket): Promise<number> {
    if (!ticket.exists) {
      throw new Error(
        "Ticketbeschreibung kann nicht geändert werden: Ticket muss eine ID haben"
      );
    }

    if (!ticket.title) {
      throw new Error(
        "Ticketbeschreibung kann nicht geändert werden: Ticket muss einen Titel haben"
      );
    }

    await RequestHandler.handle(
      this.ticketRepo.changeDescription(ticket.id!, {
        title: ticket.title,
        description: ticket.description,
        action: ticket.action
      }),
      this.response
    );

    return ticket.id!;
  }
}
