import { IPersonListPresenter } from "../controllers/PersonListController";
import { PersonListViewModel } from "../vms/PersonListViewModel";
import { FormResponse } from "@/forms/FormResponse";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { PersonCatalogRow } from "../interactors/PersonEditor";
import { Authorizator } from "../interactors/Authorizator";
import { Translator } from "@/shared/i18n/Translator";

export class PersonListPresenter implements IPersonListPresenter {
  public constructor(private vm: PersonListViewModel) {
    this.initAuthorization();
  }

  public get personSearch() {
    return this.vm.personSearch;
  }
  public set personSearch(search: string) {
    this.vm.personSearch = search;
  }

  public set createPersonVisible(visible: boolean) {
    this.vm.createPersonDialogVisible = visible;
  }

  public set personToEdit(person: string) {
    this.vm.goToPerson = person;
  }

  public set createdPerson(person: string) {
    this.vm.goToPerson = person;
  }

  public set currentPage(page: number) {
    this.vm.currentPage = page;
  }
  public get currentPage(): number {
    return this.vm.currentPage;
  }

  public get peoplePerPage(): number {
    return this.vm.peoplePerPage;
  }

  // Responses
  public set loadPeopleResponse(
    response: FormResponse<PaginatedList<PersonCatalogRow>>
  ) {
    this.vm.loadPeopleRequest = response;

    if (!response.loading && !response.error) {
      this.vm.totalPeople = response.data.totalCount;

      this.vm.people = response.data.items.map(row => {
        const name =
          (row.preTitle ? row.preTitle + " " : "") +
          row.firstname +
          " " +
          row.lastname +
          " " +
          (row.postTitle ? row.postTitle : "");

        const phoneNumber = row.areaCode + " " + row.phoneNumber;
        const address =
          (row.street ? row.street + ", " : "") +
          (row.zip ? row.zip + " " : "") +
          (row.city ? row.city + " - " : "") +
          (row.country ? Translator.t(row.country) : "");

        return {
          id: row.id.toString(),
          name,
          phoneNumber,
          address,
          email: row.email
        };
      });
    }
  }

  private initAuthorization() {
    this.vm.createPersonButtonVisible = Authorizator.canCreatePeople();
    this.vm.viewPersonButtonVisible = Authorizator.canViewPeople();
    this.vm.goBack = !Authorizator.canListPeople();
  }
}
