import { Selection, Textfield, Request } from "@/forms/ViewModelFormTypes";
import {
  PhoneNumberData,
  AddressData
} from "../controllers/CreatePersonController";

export class CreatePersonViewModel {
  public clientId = "0";
  public formStep = 1;
  public personFormCompleted = false;
  public contactFormCompleted = false;
  public personCreated = 0;

  public salutation: Selection = {
    label: "Anrede",
    selected: "",
    error: "",
    loading: false,
    items: []
  };
  public preDegree: Textfield = {
    label: "Titel voran",
    value: "",
    error: "",
    loading: false
  };
  public firstname: Textfield = {
    label: "Vorname",
    value: "",
    error: "",
    loading: false
  };
  public lastname: Textfield = {
    label: "Nachname",
    value: "",
    error: "",
    loading: false
  };
  public postDegree: Textfield = {
    label: "Titel danach",
    value: "",
    error: "",
    loading: false
  };
  public completePersonDataButtonDisabled = false;

  public email: Textfield = {
    label: "Email",
    value: "",
    error: "",
    loading: false
  };
  public website: Textfield = {
    label: "Webseite",
    value: "",
    error: "",
    loading: false
  };
  public phoneNumbers: PhoneNumberData[] = [];
  public completeContactDataButtonDisabled = false;

  public addresses: AddressData[] = [];
  public addAddressButtonDisabled = true;
  public completeAddressDataButtonDisabled = false;

  public createPersonRequest: Request = { loading: false, error: "" };
}
