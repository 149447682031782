import { PlantFieldCategoryEntity } from "../entities/PlantEntity";
import { PlantTypeFieldCategory } from "../settings/PlantTypeSettings";
import { PlantFieldCategory } from "./PlantFieldCategory";

export class PlantFieldCategoryCollection {
  public categories?: PlantFieldCategory[] = [];

  public get hasFields() {
    return this.categories?.some(c => c.fields.length > 0) ?? false;
  }

  public get hasCategoryWithoutName() {
    return this.categories?.some(c => !c.name) ?? false;
  }

  public getCategoryByName(name?: string) {
    return this.categories?.find(c => c.name === name);
  }

  public indexOf(category: PlantFieldCategory) {
    return this.categories?.indexOf(category) ?? -1;
  }

  public add(category?: PlantFieldCategory) {
    if (!category) {
      return;
    }

    this.initIfEmpty();
    this.categories!.push(category);
  }

  public addEmpty() {
    this.initIfEmpty();

    const category = new PlantFieldCategory();
    this.categories!.push(category);
    return category;
  }

  public merge(newCategories?: PlantFieldCategoryCollection) {
    this.initIfEmpty();

    newCategories?.categories?.forEach(c => {
      const existingCategory = this.getCategoryByName(c.name);

      if (!existingCategory) {
        this.categories!.push(c);
      } else {
        existingCategory.merge(c);
      }
    });
  }

  public thinOut(categories?: PlantFieldCategoryCollection) {
    this.initIfEmpty();

    categories?.categories?.forEach(c => {
      const existingCategory = this.getCategoryByName(c.name);

      if (existingCategory) {
        existingCategory.thinOut(c);

        if (!existingCategory.hasFields) {
          this.remove(existingCategory);
        }
      }
    });
  }

  public remove(category: PlantFieldCategory) {
    this.categories = this.categories?.filter(f => f !== category);
  }

  public clear() {
    if (this.categories) {
      this.categories.splice(0);
    }
  }

  public toEntities() {
    return this.categories?.map(c => c.toEntity());
  }

  public static collectFrom(entities?: PlantFieldCategoryEntity[]) {
    return this.collect(entities?.map(e => PlantFieldCategory.from(e)));
  }

  public static collectFromTypes(types?: PlantTypeFieldCategory[]) {
    return this.collect(types?.map(t => PlantFieldCategory.fromType(t)));
  }

  public static collect(fields?: PlantFieldCategory[]) {
    const collection = new PlantFieldCategoryCollection();
    collection.categories = fields;
    return collection;
  }

  private initIfEmpty() {
    if (!this.categories) {
      this.categories = [];
    }
  }
}
