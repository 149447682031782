import { ClientModel } from "@/property/client/models/ClientModel";
import { ServerResponse } from "@/shared/datastructures/Response";
import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { ClientEntity } from "../../entities/ClientEntity";
import { PropertyReportContainer } from "../../repositories/PropertyRepoContainer";

export class ClientSelectVm extends RequestModel<ClientEntity[]> {
  public clients: ClientModel[] = [];

  public loadResponse = new ServerResponse<ClientEntity[]>();

  public get clientSelectionList() {
    return this.clients.map(c => ({
      value: c,
      text: c.name
    }));
  }

  public load() {
    RequestHandler.handleModel(
      () => PropertyReportContainer.clientRepo.getClientSelection(),
      this,
      this.loadResponse
    );
  }

  public from(data: ClientEntity[]): void {
    this.clients = data.map(c => ClientModel.from(c));
  }
}
