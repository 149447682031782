import { EmployeeEntity } from "@/property/shared/entities/EmployeeEntity";
import { PersonModel } from "./PersonModel";

export class EmployeeModel {
  public static from(employee?: EmployeeEntity) {
    return new EmployeeModel(employee?.id, PersonModel.from(employee?.person));
  }

  public constructor(public id?: number, public person?: PersonModel) {}

  public get name() {
    return this.person?.name ?? "";
  }
}
