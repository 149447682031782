import { ChecklistEntryEntity } from "../../entities/ChecklistEntity";
import { ChecklistGraphQLProvider } from "./ChecklistGraphQLProvider";
import { ChecklistProvider } from "./ChecklistProvider";

export interface ChecklistRepository {
  getChecklistEntry(id: number): Promise<ChecklistEntryEntity>;

  setProtectionGoalNiveau(
    id: number,
    protectionGoalNiveau: string
  ): Promise<number>;

  setPreparationState(id: number, state: string): Promise<number>;
  setInspectionState(id: number, state: string): Promise<number>;
}

export class DefaultChecklistRepository implements ChecklistRepository {
  public constructor(
    private webProvider: ChecklistProvider = new ChecklistGraphQLProvider()
  ) {}

  public getChecklistEntry(id: number): Promise<ChecklistEntryEntity> {
    return this.webProvider.loadChecklistEntry(id);
  }

  public setProtectionGoalNiveau(
    id: number,
    protectionGoalNiveau: string
  ): Promise<number> {
    return this.webProvider.setProtectionGoalNiveau(id, protectionGoalNiveau);
  }

  public setPreparationState(id: number, state: string): Promise<number> {
    return this.webProvider.setPreparationState(id, state);
  }

  public setInspectionState(id: number, state: string): Promise<number> {
    return this.webProvider.setInspectionState(id, state);
  }
}
