
import Vue from "vue";
import Component from "vue-class-component";

@Component<Pagination>({
  props: {
    value: {
      type: Number,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      default: 20
    },
    totalItems: {
      type: Number,
      required: true
    }
  }
})
export default class Pagination extends Vue {
  private get pages() {
    if (!this.$props.totalItems || !this.$props.itemsPerPage) {
      return 0;
    }
    return Math.ceil(this.$props.totalItems / this.$props.itemsPerPage);
  }

  private emitInput(page: number) {
    if (page !== this.$props.value) {
      this.$emit("input", page);
    }
  }
}
