import { TicketLocationViewModel } from "../vms/TicketLocationViewModel";
import { ITicketLocationPresenter } from "../controllers/TicketLocationController";
import { FormResponse } from "@/forms/FormResponse";
import {
  PlanCatalogRow,
  PlanPayload
} from "@/office/interactors/PropertyPlanManager";
import { Point } from "@/datastructures/Point";

export class TicketLocationPresenter implements ITicketLocationPresenter {
  public constructor(private vm: TicketLocationViewModel) {}

  // Data
  public set selectedPlanId(id: string) {
    this.vm.planId = id;
    this.vm.planVisible = !!id;
  }

  public set ticketLocation(location: Point) {
    this.vm.ticketLocation = {
      x: location.x.toString(),
      y: location.y.toString()
    };
  }

  // Responses
  public set loadPlansResponse(response: FormResponse<PlanCatalogRow[]>) {
    this.vm.plan.loading = response.loading;
    this.vm.plan.error = response.error;

    if (!response.loading && !response.error) {
      this.vm.plan.items = response.data
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(row => ({
          text: row.name,
          value: row.id.id.toString()
        }));
    }
  }

  public set loadPlanResponse(response: FormResponse<PlanPayload>) {
    this.vm.loadPlanRequest = response;
    this.vm.planVisible = true;

    if (!response.loading && !response.error) {
      this.vm.plan.selected = response.data.id.id.toString();
      this.vm.selectedPlanImagePath = response.data.file.path;
    }
  }
}
