
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import TicketPriorityIcon from "@/inspection/ticket/widgets/TicketPriorityIcon.vue";

import { Ticket } from "@/inspection/shared/models/Ticket";

@Component<TicketCard>({
  components: { TicketPriorityIcon }
})
export default class TicketCard extends Vue {
  @Prop({ type: Object, default: () => new Ticket() })
  protected readonly ticket!: Ticket;

  protected ticketClicked() {
    this.$emit("click");
  }
}
