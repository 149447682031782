import { Form, FormFieldType } from "../Form";

export class PropertyDetailsForm extends Form {
  protected definition = {
    legalForm: { type: FormFieldType.Text, required: true },
    cadastralCommunity: { type: FormFieldType.Text, required: true },
    assetNumber: { type: FormFieldType.Text, required: true },
    buildYear: { type: FormFieldType.Number, required: true },
    parcelNumber: { type: FormFieldType.Text, required: true },
    unitCount: { type: FormFieldType.Number, required: true, greaterThan: 0 },
    effectiveArea: {
      type: FormFieldType.Number,
      required: true,
      greaterThan: 0
    },
    singleCadastre: { type: FormFieldType.Checkbox, required: false },
    dwsBaseNumber: { type: FormFieldType.Text, required: false }
  };
}
