
import Vue from "vue";
import Component from "vue-class-component";

@Component<InfoTooltip>({
  props: {
    icon: {
      type: String,
      default: "info"
    },
    iconColor: {
      type: String,
      required: false
    },
    tooltipColor: {
      type: String,
      required: false
    },
    bottom: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: "500"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class InfoTooltip extends Vue {
  protected bottom!: boolean;
  protected top!: boolean;

  protected get defaultBottom() {
    return this.bottom || !this.top;
  }
}
