import { InspectionSelectionItemLoader } from "@/inspection/shared/dtos/InspectionDomainSelectionItemLoader";
import { TicketDescription } from "@/inspection/shared/dtos/TicketDtos";
import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";

export class TicketDescriptionForm extends Form<TicketDescription> {
  protected definition = {
    title: {
      label: "Titel",
      required: true,
      type: FormFieldType.Selection,
      itemDefinition: {
        items: () => InspectionSelectionItemLoader.loadTicketTemplates()
      }
    },
    description: {
      label: "Beschreibung",
      required: false,
      type: FormFieldType.Text
    },
    action: {
      label: "Maßnahme",
      required: false,
      type: FormFieldType.Text
    }
  };
}
