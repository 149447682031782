import {
  PropertyViewerGateway,
  SelectPropertyData
} from "@/manager/interactors/PropertyViewer";
import { GraphQLConnection } from "./connection/GraphQLConnection";
import { NumericalId } from "@/datastructures/NumericalId";
import { Property } from "@/entities/Property";
import { Page } from "@/datastructures/Page";
import { Address } from "@/entities/Address";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { storageKeys } from "@/data/storageKeys";

export class PropertyViewerGraphQLGateway implements PropertyViewerGateway {
  public constructor(
    private connection: GraphQLConnection,
    private storage: SimpleStringStorage
  ) {}

  public loadPropertyCatalog(
    clientId: NumericalId,
    page: Page,
    search: string
  ): Promise<PaginatedList<Property>> {
    return this.connection
      .queryPaginated(
        "properties",
        page.itemsPerPage,
        page.page,
        { client_id: clientId.id },
        [
          "id",
          "name",
          "build_year",
          "unit_count",
          "legal_form",
          "effective_area",
          { name: "address", fields: ["postcode", "street", "city"] }
        ],
        search
      )
      .then(response => ({
        items: response.data.map((data: any) => {
          const property = new Property(data.id);

          property.name = data.name;
          property.buildYear = data.build_year;
          property.unitCount = data.unit_count;
          property.legalForm = data.legal_form;
          property.effectiveArea = data.effective_area;

          if (!!data.address) {
            const address = new Address(
              data.address.street,
              data.address.postcode,
              data.address.city
            );
            property.address = address;
          }

          return property;
        }),
        totalCount: response.count
      }));
  }

  public selectProperty(property: SelectPropertyData): Promise<number> {
    // this.storage.set(storageKeys.selectedProperty, property.id.toString());
    // this.storage.set(
    //   storageKeys.selectedPropertyData,
    //   JSON.stringify(property)
    // );

    // return Promise.resolve(property.id);
    return Promise.resolve(0);
  }
}
