
import Vue from "vue";
import Component from "vue-class-component";

@Component<Container>({
  name: "Container",
  inheritAttrs: false,
  props: {
    margin: {
      type: Number,
      default: 0
    },
    padding: {
      type: Number,
      default: 0
    }
  }
})
export default class Container extends Vue {}
