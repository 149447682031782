interface TextStyle {
  color: string;
  fontSize: number;
  fontWeight: FontWeight;
  uppercase: boolean;
  letterSpacing: number;
  underlined: boolean;
  bottomMargin: number;
}

interface TextTheme {
  h1: TextStyle;
  h2: TextStyle;
  h3: TextStyle;
  h4: TextStyle;
  h5: TextStyle;
  body: TextStyle;
  bodyLight: TextStyle;
  bodySmallLight: TextStyle;
  error: TextStyle;

  [key: string]: TextStyle;
}

export enum FontWeight {
  w100 = 100,
  w200 = 200,
  w300 = 300,
  w400 = 400,
  w500 = 500,
  w600 = 600,
  w700 = 700,
  w800 = 800,
  w900 = 900
}

export const textTheme: TextTheme = {
  h1: {
    color: "primary",
    fontSize: 1.9,
    fontWeight: FontWeight.w900,
    uppercase: true,
    letterSpacing: 7,
    underlined: false,
    bottomMargin: 4
  },
  h2: {
    color: "primary",
    fontSize: 1.7,
    fontWeight: FontWeight.w700,
    uppercase: false,
    letterSpacing: 0.5,
    underlined: false,
    bottomMargin: 0
  },
  h3: {
    color: "primary",
    fontSize: 1.5,
    fontWeight: FontWeight.w700,
    uppercase: false,
    letterSpacing: 1,
    underlined: false,
    bottomMargin: 0
  },
  h4: {
    color: "black",
    fontSize: 1.2,
    fontWeight: FontWeight.w700,
    uppercase: false,
    letterSpacing: 0.5,
    underlined: false,
    bottomMargin: 0
  },
  h5: {
    color: "black",
    fontSize: 1,
    fontWeight: FontWeight.w700,
    uppercase: false,
    letterSpacing: 0.5,
    underlined: false,
    bottomMargin: 0
  },
  h6: {
    color: "black",
    fontSize: 0.93,
    fontWeight: FontWeight.w600,
    uppercase: false,
    letterSpacing: 0.5,
    underlined: false,
    bottomMargin: 0
  },
  strong: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 0.875,
    fontWeight: FontWeight.w600,
    uppercase: false,
    letterSpacing: 0,
    underlined: false,
    bottomMargin: 0
  },
  body: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 0.875,
    fontWeight: FontWeight.w300,
    uppercase: false,
    letterSpacing: 0,
    underlined: false,
    bottomMargin: 0
  },
  bodyLight: {
    color: "black",
    fontSize: 0.8,
    fontWeight: FontWeight.w200,
    uppercase: false,
    letterSpacing: 0.5,
    underlined: false,
    bottomMargin: 0
  },
  bodySmallLight: {
    color: "black",
    fontSize: 0.7,
    fontWeight: FontWeight.w100,
    uppercase: false,
    letterSpacing: 0.3,
    underlined: false,
    bottomMargin: 0
  },
  link: {
    color: "primary",
    fontSize: 0.9,
    fontWeight: FontWeight.w400,
    uppercase: false,
    letterSpacing: 0,
    underlined: true,
    bottomMargin: 0
  },
  error: {
    color: "error",
    fontSize: 0.9,
    fontWeight: FontWeight.w400,
    uppercase: false,
    letterSpacing: 0,
    underlined: false,
    bottomMargin: 0
  },
  errorSmall: {
    color: "error",
    fontSize: 0.75,
    fontWeight: FontWeight.w400,
    uppercase: false,
    letterSpacing: 0,
    underlined: false,
    bottomMargin: 0
  }
};
