
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { Client } from "@/property/shared/models/Client";
import { ClientGeneralDataForm as Form } from "../../forms/ClientGeneralDataForm";
import { RequestHandler } from "@/shared/utils/RequestHandler";

@Component<ClientGeneralDataForm>({
  components: {}
})
export default class ClientGeneralDataForm extends Vue {
  @Prop({
    default() {
      return new Client();
    }
  })
  protected client!: Client;
  @Prop({ type: Boolean, default: false })
  protected readonly disabled!: boolean;

  protected form = new Form();

  @Watch("client.success")
  protected onClientLoaded(success: boolean) {
    this.form.setFieldValue("name", this.client.name, true);
    this.form.setFieldValue("vat", this.client.vat, true);
  }

  protected async updateClient(fieldName: string, value: unknown) {
    RequestHandler.handleFormUpdate(
      () => this.client.updateGeneralData(this.form.getData()),
      {
        form: this.form,
        fieldName,
        value
      }
    );
  }

  protected async created() {
    this.form = new Form();
    await this.form.init();
    this.form.enabled = !this.disabled;
  }

  protected mounted() {
    this.load();
  }

  protected load() {
    if (!this.client.loadingOrLoaded) {
      this.client.load();
    }
  }
}
