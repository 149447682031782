import { ChecklistSection } from "./ChecklistSection";
import { Plant } from "./Plant";

export class ChecklistEntry {
  public name: string = "";
  public description: string = "";
  public preparationState: string = "";
  public state: string = "";
  public ticketCount: number = 0;
  public checklistEntryTemplateId: string = "";
  public section: ChecklistSection = new ChecklistSection(0);
  public plant: Plant = new Plant(0);

  public constructor(public id: number) {}
}
