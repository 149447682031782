import {
  AuthenticatorGateway,
  LoginData,
  AuthPayload
} from "@/common/interactors/Authenticator";
import { GraphQLConnection } from "./connection/GraphQLConnection";
import { Setting } from "@/entities/Setting";
import { LocalStorage } from "@/storage/LocalStorage";
import { storageKeys } from "@/data/storageKeys";
import { NumericalId } from "@/datastructures/NumericalId";

export class AuthenticatorGraphQLGateway implements AuthenticatorGateway {
  public constructor(private connection: GraphQLConnection) {}

  public async login(loginData: LoginData): Promise<AuthPayload> {
    const loginResponse = await this.connection.mutation(
      "login",
      { input: { username: loginData.email, password: loginData.password } },
      [
        "access_token",
        {
          name: "user",
          fields: [
            "id",
            { name: "person", fields: ["id", "firstname", "lastname"] },
            { name: "client", fields: ["id", "name"] },
            { name: "role", fields: ["name", "permissions", "priority"] }
          ]
        }
      ]
    );

    const loginPayload = loginResponse.data;

    new LocalStorage().set(storageKeys.token, loginPayload.access_token);

    return {
      accessToken: loginPayload.access_token,
      userId: loginPayload.user.id,
      firstname: loginPayload.user.person.firstname,
      lastname: loginPayload.user.person.lastname,
      clientId: loginPayload.user.client.id,
      clientName: loginPayload.user.client.name,
      role: loginPayload.user.role.name,
      rolePrio: loginPayload.user.role.priority,
      permissions: loginPayload.user.role.permissions
    };
  }

  public async getPermissions(userId: NumericalId) {
    const response = await this.connection.query("user", { id: userId.id }, [
      { name: "role", fields: ["permissions"] }
    ]);

    return response.data.role.permissions;
  }
}
