
import Vue from "vue";
import Component from "vue-class-component";

import PlantTable from "./widgets/PlantTable.vue";

import { Authorizator } from "@/common/interactors/Authorizator";
import { Property } from "../shared/dtos/Property";
import { MathUtils } from "@/shared/utils/MathUtils";

@Component<PlantListPage>({
  components: { PlantTable }
})
export default class PlantListPage extends Vue {
  protected get selectedPropertyIds() {
    return (
      this.$store.state.property.selectedProperties?.map(
        (p: Property) => p.id
      ) ?? []
    );
  }

  protected get addPlantButtonVisible() {
    return Authorizator.canCreateProperties();
  }

  protected get search() {
    return this.$route.query.search?.toString() ?? "";
  }

  protected get propertyPartPlants() {
    return MathUtils.parseBoolean(this.$route.query.propertyPartPlants);
  }

  protected setSearch(search: string) {
    this.$router.push({ query: { ...this.$route.query, search, page: "1" } });
  }

  protected showPropertyPartPlants(show: boolean) {
    this.$router.push({
      query: {
        ...this.$route.query,
        propertyPartPlants: show ? "true" : "false",
        page: "1"
      }
    });
  }
}
