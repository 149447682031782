import { PropertyPlanViewerGateway } from "@/expert/interactors/PropertyPlanViewer";
import { GraphQLConnection } from "./connection/GraphQLConnection";
import { NumericalId } from "@/datastructures/NumericalId";
import { Plan } from "@/entities/Plan";
import { Image } from "@/entities/Image";
import {
  PlanCatalogRow,
  PlanPayload
} from "@/office/interactors/PropertyPlanManager";

export class PropertyPlanViewerGraphQLGateway
  implements PropertyPlanViewerGateway {
  public constructor(private connection: GraphQLConnection) {}

  public loadPlanCatalog(propertyId: NumericalId): Promise<PlanCatalogRow[]> {
    return this.connection
      .query(
        "property",
        { id: propertyId.id },
        [
          "id",
          {
            name: "plans",
            fields: [
              "id",
              "name",
              "offline",
              { name: "file", fields: ["id", "path"] }
            ]
          }
        ],
        undefined,
        undefined,
        true
      )
      .then(response =>
        response.data.plans.map((planData: any) => ({
          id: new NumericalId(planData.id),
          name: planData.name,
          offline: planData.offline,
          file: {
            id: new NumericalId(planData.file?.id || 0),
            path: planData.file?.path || ""
          }
        }))
      );
  }

  public loadPlan(planId: NumericalId): Promise<PlanPayload> {
    return this.connection
      .query(
        "plan",
        { id: planId.id },
        [
          "id",
          "name",
          "offline",
          "point_pixel_a_x",
          "point_pixel_a_y",
          "point_pixel_b_x",
          "point_pixel_b_y",
          "point_real_a_x",
          "point_real_a_y",
          "point_real_b_x",
          "point_real_b_y",
          { name: "file", fields: ["id", "path"] }
        ],
        undefined,
        undefined,
        true
      )
      .then(response => ({
        id: new NumericalId(response.data.id),
        name: response.data.name,
        offline: response.data.offline,
        file: {
          id: new NumericalId(response.data.file?.id || 0),
          path: response.data.file?.path || ""
        },
        pixelDistance: {
          a: {
            x: response.data.point_pixel_a_x,
            y: response.data.point_pixel_a_y
          },
          b: {
            x: response.data.point_pixel_b_x,
            y: response.data.point_pixel_b_y
          }
        },
        realDistance: {
          a: {
            x: response.data.point_real_a_x,
            y: response.data.point_real_a_y
          },
          b: {
            x: response.data.point_real_b_x,
            y: response.data.point_real_b_y
          }
        }
      }));
  }

  public loadTicketPlan(
    ticketId: NumericalId,
    planId: NumericalId
  ): Promise<PlanPayload> {
    return this.connection
      .query("ticketPlan", { ticket_id: ticketId.id, plan_id: planId.id }, [
        "id",
        "name",
        { name: "file", fields: ["id", "path"] }
      ])
      .then(response => ({
        id: new NumericalId(response.data.id),
        name: response.data.name,
        offline: false,
        file: {
          id: new NumericalId(response.data.file?.id || 0),
          path: response.data.file?.path || ""
        },
        pixelDistance: {
          a: {
            x: 0,
            y: 0
          },
          b: {
            x: 0,
            y: 0
          }
        },
        realDistance: {
          a: {
            x: 0,
            y: 0
          },
          b: {
            x: 0,
            y: 0
          }
        }
      }));
  }
}
