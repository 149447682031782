import { EmployeeModel } from "@/property/client/models/EmployeeModel";
import { ServerResponse } from "@/shared/datastructures/Response";
import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { EmployeeEntity } from "../../entities/EmployeeEntity";
import { PropertyReportContainer } from "../../repositories/PropertyRepoContainer";

export class EmployeeSelectVm extends RequestModel<EmployeeEntity[]> {
  public employee: EmployeeModel[] = [];

  public loadResponse = new ServerResponse<EmployeeEntity[]>();

  public get employeeSelectionList() {
    return this.employee.map(e => ({
      value: e,
      text: e.name
    }));
  }

  public load(clientId?: number) {
    if (!clientId) {
      return;
    }

    RequestHandler.handleModel(
      () => PropertyReportContainer.employeeRepo.getEmployeeSelection(clientId),
      this,
      this.loadResponse
    );
  }

  public from(data: EmployeeEntity[]): void {
    this.employee = data.map(c => EmployeeModel.from(c));
  }
}
