
import Vue from "vue";
import Component from "vue-class-component";

import GeneralPlantDataForm from "./GeneralPlantDataForm.vue";

import { Prop, Watch } from "vue-property-decorator";

import { TableHeader } from "@/shared/datastructures/TableHeader";
import { PlantChecklistEntryForm as Form } from "../forms/PlantChecklistEntryForm";
import { PlantChecklistEntry } from "@/property/shared/models/PlantChecklistEntry";
import { FormArray } from "@/shared/form/SubFormArray";
import { PlantChecklistEntryEntity } from "@/property/shared/entities/PlantEntity";
import { PlantChecklistEntryCollection } from "@/property/shared/models/PlantChecklistEntryCollection";

@Component<PlantChecklistEntryTable>({
  components: {
    GeneralPlantDataForm
  }
})
export default class PlantChecklistEntryTable extends Vue {
  @Prop({ type: Object, default: () => new PlantChecklistEntryCollection() })
  protected readonly entries!: PlantChecklistEntryCollection;
  @Prop({
    type: Object,
    default: () => new FormArray<PlantChecklistEntryEntity>([])
  })
  protected readonly forms!: FormArray<PlantChecklistEntryEntity>;

  protected headers: TableHeader[] = [
    { text: "Name", value: "name", width: "25%" },
    { text: "Beschreibung", value: "description" },
    { text: "Intervall", value: "interval", width: "20%" },
    { text: "", value: "actions", align: "right", width: "1%" }
  ];

  @Watch("entries.entries")
  protected onEntriesChanged() {
    this.initFields();
  }

  protected addEntry() {
    const newEntry = this.entries.addEmpty();
    const newEntryForm = new Form(undefined, "checklistEntry", newEntry);

    this.forms.push(newEntryForm);
  }

  protected removeEntry(entry: PlantChecklistEntry) {
    this.forms.removeByTarget(entry);
    this.entries.remove(entry);
  }

  protected getFormField(fieldName: string, entry: PlantChecklistEntry) {
    const form = this.forms.getSubFormByTarget(entry);
    return form?.getField(fieldName);
  }

  protected setFormField(
    fieldName: string,
    value: unknown,
    entry: PlantChecklistEntry
  ) {
    const form = this.forms.getSubFormByTarget(entry);
    form?.setFieldValue(fieldName, value);
    entry.updateData(form?.getData());
  }

  protected created() {
    this.initFields();
  }

  protected initFields() {
    this.forms.clear();

    this.entries.entries?.forEach(async e => {
      const form = new Form(undefined, "checklistEntry", e);
      await form.init();
      form.setData(e.toEntity());
      this.forms.push(form);
    });
  }

  public reset() {
    this.entries.clear();
    this.forms.clear();
  }
}
