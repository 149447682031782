
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import App from "@/App.vue";
import Comment from "./Comment.vue";

import { CommonRepoContainer } from "@/common/shared/repositories/CommonRepoContainer";
import {
  Comment as CommentModel,
  Commentable
} from "@/common/shared/models/Comment";
import { AddCommentUseCase } from "../useCases/AddCommentUseCase";
import { LoadCommentsUseCase } from "../useCases/LoadCommentsUseCase";
import { Comments } from "@/common/shared/models/Comments";
import { Authorizator } from "@/common/interactors/Authorizator";
import { UserSettings } from "@/storage/UserSettings";

@Component<CommentList>({
  components: { Comment }
})
export default class CommentList extends Vue {
  @Prop({ type: Object, required: true })
  protected readonly commentable!: Commentable;

  protected commentLoader = new LoadCommentsUseCase(
    CommonRepoContainer.commentRepo
  );
  protected commentAdder = new AddCommentUseCase(
    CommonRepoContainer.commentRepo,
    CommonRepoContainer.userRepo
  );

  protected newComment = new CommentModel();
  protected comments = new Comments();

  protected get displayNameField() {
    return UserSettings.loggedOut();
  }

  protected get displayInternalCheckbox() {
    return UserSettings.loggedIn();
  }

  protected get displayAddComment() {
    return Authorizator.canCreateTicketComments();
  }

  protected get commentElements() {
    return this.$refs.comments as Comment[];
  }

  protected get internLabel() {
    return this.isMobile ? "Intern" : "Nur intern sichtbar";
  }

  protected get isMobile() {
    return App.instance?.isMobile;
  }

  protected async addComment(comment: CommentModel) {
    const createdComment = await this.commentAdder.do(comment);

    if (createdComment) {
      this.newComment.text = "";
      this.comments.addToFront(createdComment);
    }
  }

  protected editCommentStarted(comment: CommentModel) {
    this.commentElements
      .filter(c => !comment.equals(c.comment))
      .forEach(c => c.cancelEditing());
  }

  protected setGuestName(name: string) {
    UserSettings.setGuestName(name);
    this.setGuestCreator();
  }

  protected mounted() {
    this.newComment.commentable = this.commentable;
    this.setGuestCreator();
    this.load();
  }

  protected setGuestCreator() {
    this.newComment.realCreator = UserSettings.getUser();
  }

  protected async load() {
    const comments = await this.commentLoader.do(this.commentable);

    if (comments) {
      this.comments = comments;
    }
  }
}
