import { ITicketDetailPresenter } from "../controllers/TicketDetailController";
import { TicketDetailViewModel } from "../vms/TicketDetailViewModel";
import { FormResponse } from "@/forms/FormResponse";
import {
  TicketData,
  TicketLocationData,
  TicketEntryData
} from "../interactors/TicketViewer";
import { TicketDescriptionForm } from "@/forms/ticket/TicketDescriptionForm";
import { TicketOrganizationForm } from "@/forms/ticket/TicketOrganizationForm";
import {
  EditTicketData,
  TicketImage,
  UpdateTicketLocationData,
  UpdateChecklistReferenceData
} from "@/expert/interactors/TicketManager";
import { Point } from "@/datastructures/Point";
import { NumericalId } from "@/datastructures/NumericalId";
import { AssignmentListRow } from "@/expert/interactors/AssignmentManager";
import {
  ChecklistSectionName,
  ChecklistName
} from "@/expert/interactors/ChecklistManager";
import { Authorizator } from "@/common/interactors/Authorizator";
import { Priorities, UserSettings } from "@/storage/UserSettings";
import Vue from "vue";
import { Person } from "@/property/shared/models/Person";
import { DateUtils } from "@/shared/utils/DateUtils";
import { DateUtil } from "@/shared/utils/DateUtil";
import { Plant } from "@/property/shared/models/Plant";
import { Ticket } from "@/inspection/shared/models/Ticket";
import { TicketPriority } from "@/inspection/shared/entities/TicketEntity";

export class TicketDetailPresenter implements ITicketDetailPresenter {
  public descriptionForm: TicketDescriptionForm;
  public organizationForm: TicketOrganizationForm;

  private mLocationAlreadyAssigned = false;

  private checklistSectionAndEntryNames: ChecklistSectionName[] = [];
  private mChecklistReferenceAlreadyAssigned = false;

  public constructor(private vm: TicketDetailViewModel) {
    this.descriptionForm = new TicketDescriptionForm(
      this.vm,
      this.descriptionFormValidated
    );
    this.organizationForm = new TicketOrganizationForm(
      this.vm,
      this.organizationFormValidated
    );

    this.descriptionForm.init();
    this.organizationForm.init();
  }

  // Init
  public set priorities(prios: Priorities) {
    this.vm.priority.items = prios.map(prio => ({
      value: prio.id,
      text: prio.label,
      icon: prio.icon,
      color: prio.color
    }));
  }

  // Responses
  public set loadTicketResponse(response: FormResponse<TicketData>) {
    this.vm.loadTicketRequest = response;

    if (!response.loading && !response.error) {
      const dto = {
        ...response.data,
        priority: response.data.priority as TicketPriority
      };

      this.vm.ticket = Ticket.from(dto);
      this.ticket = response.data;

      this.vm.ticketState = response.data.state;
      this.vm.createdAt = response.data.createdAt
        ? DateUtils.format(
            response.data.createdAt,
            "DD. MMM YYYY [um] HH:mm:ss"
          )
        : "";
      this.vm.creator = Person.from(response.data.creator);
      this.vm.responsiblePerson = Person.from(response.data.responsiblePerson);

      this.vm.pageTitle = "Ticket #" + response.data.id;
      this.vm.property.value = this.buildPropertyDescription(response.data);
      this.vm.property.id = response.data.propertyId.toString();
      this.vm.images = response.data.images.map(image => ({
        id: image.id.toString(),
        path: image.pathBig,
        downloadPath: image.path
      }));

      this.vm.plant = Plant.from(response.data.plant);

      if (response.data.location && response.data.location.planId.valid) {
        this.updateLocationData(response.data.location);
      }

      if (response.data.entry) {
        this.updateChecklistReferenceData(response.data.entry);
      }

      const prevTicketId = response.data.prevTicketId;
      this.vm.prevTicketButtonDisabled = !prevTicketId;
      if (prevTicketId) {
        this.vm.prevTicketId = prevTicketId.toString();
        this.vm.prevTicketButtonText = "Voriges Ticket (#" + prevTicketId + ")";
      }

      const nextTicketId = response.data.nextTicketId;
      this.vm.nextTicketButtonDisabled = !nextTicketId;
      if (nextTicketId) {
        this.vm.nextTicketId = nextTicketId.toString();
        this.vm.nextTicketButtonText =
          "Nächstes Ticket (#" + nextTicketId + ")";
      }
    }
  }

  public set deleteTicketResponse(response: FormResponse<number>) {
    this.vm.deleteTicketRequest = response;

    if (!response.loading && !response.error) {
      this.vm.deletedTicket = response.data.toString();
    }
  }

  public set updateDescriptionResponse(response: FormResponse<number>) {
    this.vm.editingDescriptionRequest = response;

    if (!response.loading) {
      this.vm.editingDescriptionDisabled = false;
      if (!response.error) {
        this.vm.editingDescription = false;
      }
    }
  }

  public set updateAdditionalDataResponse(response: FormResponse<number>) {
    this.vm.editingAdditionalDataRequest = response;

    if (!response.loading) {
      this.vm.editingAdditionalDataDisabled = false;
      if (!response.error) {
        this.vm.editingAdditionalData = false;
      }
    }
  }

  public set updateOrganizationResponse(response: FormResponse<number>) {
    this.vm.editingOrganizationRequest = response;

    if (!response.loading) {
      this.vm.editingOrganizationDisabled = false;
      if (!response.error) {
        this.vm.editingOrganization = false;
      }
    }
  }

  public set deleteImageResponse(response: FormResponse<number>) {
    this.vm.deleteImageRequest = response;

    if (!response.loading && !response.error) {
      this.vm.images = this.vm.images.filter(
        image => image.id !== response.data.toString()
      );
    }
  }

  public set uploadImageResponse(response: FormResponse<TicketImage>) {
    this.vm.uploadImageRequest = response;

    if (!response.loading && !response.error) {
      this.vm.images.push({
        id: response.data.id.toString(),
        path: response.data.path,
        downloadPath: response.data.path
      });
    }
  }

  public set updateLocationResponse(
    response: FormResponse<UpdateTicketLocationData>
  ) {
    this.vm.updateLocationRequest = response;

    if (!response.loading && !response.error) {
      this.updateLocationData(response.data);
      this.vm.locationDialogVisible = false;
      this.vm.ticketLocationVisible = true;
    }
  }

  public set deleteLocationResponse(response: FormResponse<number>) {
    this.vm.deleteLocationRequest = response;

    if (!response.loading && !response.error) {
      this.vm.locationDialogVisible = false;
      this.resetLocation();
    }
  }

  public set loadAssignmentListResponse(
    response: FormResponse<AssignmentListRow[]>
  ) {
    this.vm.checklistReferenceDialogVisible = true;
    this.vm.assignment.loading = response.loading;
    this.vm.assignment.error = response.error;

    if (!response.loading && !response.error) {
      this.vm.assignment.items = response.data.map(row => ({
        text:
          "Auftrag '" +
          row.number +
          "'" +
          (row.plantType ? " (" + row.plantType + "-Checkliste)" : ""),
        value: row.id.id.toString()
      }));
      this.vm.assignment.items.sort((a, b) => a.text.localeCompare(b.text));
    }
  }

  public set loadChecklistSectionAndEntryNames(
    response: FormResponse<ChecklistName>
  ) {
    this.vm.assignment.loading = response.loading;
    this.vm.assignment.error = response.error;

    if (!response.loading && !response.error) {
      this.vm.assignment.selected = response.params.assignmentId;
      this.vm.assignment.checklistId = response.data.id;
      this.vm.checklistEntriesVisible = false;
      this.vm.checklistEntry.selected = "";
      this.vm.checklistSection.selected = "";
      this.vm.checklistSectionsVisible = true;
      this.checklistSectionAndEntryNames = response.data.sections;
      this.vm.checklistSection.items = response.data.sections.map(section => ({
        text: section.name,
        value: section.id.id.toString()
      }));
      this.vm.checklistSection.items.sort((a, b) =>
        a.text.localeCompare(b.text)
      );
    }
  }

  public set updateChecklistReferenceResponse(
    response: FormResponse<UpdateChecklistReferenceData>
  ) {
    this.vm.saveChecklistReferenceRequest = response;

    if (!response.loading && !response.error) {
      this.updateChecklistReferenceData(response.data);
      this.vm.checklistReferenceDialogVisible = false;
      this.vm.checklistReferenceVisible = true;
    }
  }

  public set deleteChecklistReferenceResponse(response: FormResponse<number>) {
    this.vm.deleteChecklistReferenceRequest = response;

    if (!response.loading && !response.error) {
      this.vm.checklistReferenceDialogVisible = false;
      this.resetChecklistReference();
    }
  }

  // Inputs
  public set selectedProperty(propertyId: string) {
    this.vm.selectedProperty = propertyId;
  }

  public set selectedTicket(ticketId: string) {
    this.vm.goToTicket = ticketId;
  }

  public setAdditionalData(key: string, value: any) {
    Vue.set(this.vm.additionalTicketData, key, value);
  }

  public set selectedImage(imageIndex: string) {
    this.vm.imageDialogVisible = true;
    this.vm.selectedImageIndex = imageIndex;
  }

  public set imageDialogVisible(visible: boolean) {
    this.vm.imageDialogVisible = visible;
  }

  public set locationDialogVisible(visible: boolean) {
    this.vm.locationDialogVisible = visible;
  }

  public set checklistReferenceDialogVisible(visible: boolean) {
    this.vm.checklistReferenceDialogVisible = visible;
  }

  public set newPlanId(id: number) {
    this.vm.tmpPlanId = id.toString();
  }

  public set newPlanLocation(location: Point) {
    this.vm.tmpPlanLocation = {
      x: location.x.toString(),
      y: location.y.toString()
    };
  }

  public set selectedChecklistSection(sectionId: NumericalId) {
    this.vm.checklistSection.selected = sectionId.id.toString();

    const selectedSection = this.checklistSectionAndEntryNames.find(
      section => section.id.id === sectionId.id
    );

    if (selectedSection) {
      this.vm.checklistEntriesVisible = true;
      this.vm.checklistEntry.selected = "";
      this.vm.checklistEntry.items = selectedSection.entries.map(entry => ({
        text: entry.name,
        value: entry.id.id.toString()
      }));
      this.vm.checklistEntry.items.sort((a, b) => a.text.localeCompare(b.text));
    }
  }

  public set selectedChecklistEntry(entryId: NumericalId) {
    this.vm.checklistEntry.selected = entryId.id.toString();
    this.vm.saveChecklistReferenceButtonDisabled = false;
  }

  // Flags
  public set editingDescription(editing: boolean) {
    this.vm.editingDescription = editing;
  }

  public set editingAdditionalData(editing: boolean) {
    this.vm.editingAdditionalData = editing;
  }

  public set editingOrganization(editing: boolean) {
    this.vm.editingOrganization = editing;
  }

  public set editingImages(editing: boolean) {
    this.vm.editingImages = editing;
  }
  public get editingImages(): boolean {
    return this.vm.editingImages;
  }

  public set editingReferences(editing: boolean) {
    this.vm.addLocationButtonVisible = editing && !this.locationAlreadyAssigned;
    this.vm.addChecklistReferenceButtonVisible =
      editing && !this.checklistReferenceAlreadyAssigned;
  }

  // Data
  public get ticket(): EditTicketData {
    return {
      id: this.vm.ticketId,
      title: this.vm.title.value,
      description: this.vm.description.value,
      action: this.vm.action.value,
      priority: this.vm.priority.selected,
      dueDate: new Date(this.vm.dueDate.value),
      type: this.vm.ticketType || "",
      data: this.vm.additionalTicketData
        ? JSON.stringify(this.vm.additionalTicketData)
        : "{}"
    };
  }
  public set ticket(ticket: EditTicketData) {
    this.vm.ticketId = ticket.id || 0;

    this.descriptionForm.setFieldValue("title", ticket.title);
    this.descriptionForm.setFieldValue("description", ticket.description);
    this.descriptionForm.setFieldValue("action", ticket.action);

    this.organizationForm.setFieldValue("priority", ticket.priority);
    this.organizationForm.setFieldValue(
      "dueDate",
      DateUtils.format(ticket.dueDate, "YYYY-MM-DD")
    );

    this.vm.ticketType = ticket.type === "no_ticket_type" ? "" : ticket.type;
    this.vm.additionalTicketData = JSON.parse(ticket.data || "{}");
    if (this.vm.ticketType) {
      const ticketType = UserSettings.getTicketType(this.vm.ticketType);
      if (ticketType) {
        this.vm.ticketFields = ticketType.fields;
        this.vm.ticketTypeName = ticketType.name;
      }
    }
  }

  // Helper
  private buildPropertyDescription(data: TicketData) {
    return (
      data.propertyName +
      " - " +
      data.propertyStreet +
      ", " +
      data.propertyZip +
      " " +
      data.propertyCity
    );
  }

  private buildLocationDescription(planName: string, x: number, y: number) {
    return "Plan '" + planName + "' - (" + x + ", " + y + ")";
  }

  private descriptionFormValidated(context: any, valid: boolean) {
    context.editingDescriptionDisabled = !valid;
  }

  private organizationFormValidated(context: any, valid: boolean) {
    context.editingOrganizationDisabled = !valid;
  }

  private resetLocation() {
    this.updateLocationData({
      id: new NumericalId(),
      planId: new NumericalId(),
      planName: "",
      x: 0,
      y: 0
    });
  }

  private resetChecklistReference() {
    this.updateChecklistReferenceData({
      assignmentId: new NumericalId(),
      checklistId: new NumericalId(),
      sectionId: new NumericalId(),
      entryId: new NumericalId(),
      assignmentNumber: "",
      sectionName: "",
      entryName: ""
    });

    this.checklistSectionAndEntryNames = [];
    this.vm.assignment.selected = "";
    this.vm.checklistSection.selected = "";
    this.vm.checklistEntry.selected = "";
    this.vm.checklistSectionsVisible = false;
    this.vm.checklistEntriesVisible = false;
  }

  private updateLocationData(location: TicketLocationData) {
    const valid = location.id.valid;
    this.vm.location = {
      label: this.vm.location.label,
      value: this.buildLocationDescription(
        location.planName,
        location.x,
        location.y
      ),
      id: valid ? location.id.id.toString() : "",
      planId: valid ? location.planId.id.toString() : "",
      location: {
        x: location.x.toString(),
        y: location.y.toString()
      }
    };
    this.vm.ticketLocationVisible = valid;
    this.locationAlreadyAssigned = valid;
  }

  private updateChecklistReferenceData(entry: TicketEntryData) {
    const loselyConnected = entry.checklistId.valid;
    const fullyConnected = entry.entryId.valid;

    this.vm.checklistReference = {
      label: "Checklist-Referenz '" + entry.assignmentNumber + "'",
      value: entry.entryName,
      assignmentId: loselyConnected ? entry.assignmentId.id.toString() : "",
      checklistId: loselyConnected ? entry.checklistId.id.toString() : "",
      sectionId: fullyConnected ? entry.sectionId.id.toString() : "",
      entryId: fullyConnected ? entry.entryId.id.toString() : ""
    };

    this.vm.checklistReferenceVisible = fullyConnected;
    this.checklistReferenceAlreadyAssigned = fullyConnected;
  }

  private set locationAlreadyAssigned(assigned: boolean) {
    this.mLocationAlreadyAssigned = assigned;
    this.editingReferences = false;
    this.vm.deleteLocationButtonDisabled = !assigned;
    this.updateEditReferenceButtonDisabled();
  }

  private get locationAlreadyAssigned(): boolean {
    return this.mLocationAlreadyAssigned;
  }

  private set checklistReferenceAlreadyAssigned(assigned: boolean) {
    this.mChecklistReferenceAlreadyAssigned = assigned;
    this.editingReferences = false;
    this.vm.deleteChecklistReferenceButtonDisabled = !assigned;
    this.updateEditReferenceButtonDisabled();
  }

  private get checklistReferenceAlreadyAssigned(): boolean {
    return this.mChecklistReferenceAlreadyAssigned;
  }

  private updateEditReferenceButtonDisabled() {
    this.vm.editingReferencesDisabled =
      this.locationAlreadyAssigned && this.checklistReferenceAlreadyAssigned;
  }
}
