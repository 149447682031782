import { Person } from "./Person";

export class Employee {
  public username?: string;
  public person?: Person;
  public role?: Role;

  public constructor(public id: number) {}
}

export interface Role {
  id: number;
  name: string;
  permissions?: object;
}
