import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { EmployeeEntity } from "../entities/EmployeeEntity";
import { PropertyReportContainer } from "../repositories/PropertyRepoContainer";
import { Client } from "./Client";
import { Employee } from "./Employee";

interface EmployeeListProps {
  client?: Client;
  employees?: Employee[];
}

export class EmployeeList extends RequestModel<EmployeeEntity[]> {
  public client?: Client;
  public employees?: Employee[];

  public constructor(props?: EmployeeListProps) {
    super();
    this.employees = props?.employees;
  }

  public get empty() {
    return (this.employees?.length ?? 0) === 0;
  }

  public addEmployee(employee: Employee) {
    this.employees?.push(employee);
  }

  public load(clientId?: number) {
    if (this.empty) {
      RequestHandler.handleModel(
        () =>
          PropertyReportContainer.employeeRepo.getEmployees(
            this.client?.id ?? clientId ?? 0
          ),
        this
      );
    }
  }

  public async deleteEmployee(employee: Employee) {
    const alreadyDeleted = employee.deleted;

    if ((await employee.del()) && alreadyDeleted) {
      this.employees = this.employees?.filter(e => e.id !== employee.id);
    }
  }

  public static from(employees?: Employee[]) {
    return new EmployeeList({
      employees
    });
  }

  public from(employees?: EmployeeEntity[]) {
    this.employees = employees?.map(e => {
      const employee = Employee.from(e);
      employee.client = this.client;
      return employee;
    });
  }
}
