
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import CreatePlantFormDialog from "./CreatePlantFormDialog.vue";

import { Plant } from "@/property/shared/models/Plant";

@Component<PropertyPlant>({
  components: { CreatePlantFormDialog },
  props: {
    propertyId: {
      type: Number,
      required: true
    }
  }
})
export default class PropertyPlant extends Vue {
  @Prop({ type: Object, required: true })
  protected readonly plant!: Plant;

  protected confirming = false;

  protected get keepFocus() {
    return (
      this.confirming ||
      this.plant.deleteResponse.loading ||
      this.plant.deleteResponse.error
    );
  }

  protected goToPlant() {
    this.$router.push({
      name: "plant",
      params: { plantId: this.plant.id?.toString() ?? "" }
    });
  }
}
