import { storageKeys } from "@/data/storageKeys";
import { Settings } from "@/shared/datastructures/Settings";
import { DynamicForm } from "@/shared/form/DynamicForm";
import { FormDefinition, FormFieldType } from "@/shared/form/FormDefinition";

export interface TicketTypes {
  ticket_types: TicketType[];
}

export interface TicketType {
  id: string;
  name: string;
  key: string;
  skipLocation?: boolean;
  fields: TicketTypeField[];
}

export interface TicketTypeField {
  id: string;
  label: string;
  key: string;
  type: TicketTypeFieldType;
  readonly: boolean;
  options?: TicketTypeFieldOption[];
  sync?: TicketAndPlantValueSyncType;
  info?: string;
}

export interface TicketTypeFieldOption {
  id: string;
  label: string;
}

export type TicketTypeFieldType =
  | "string"
  | "number"
  | "bool"
  | "date"
  | "select";

export type TicketAndPlantValueSyncType = "fromPlant" | "toPlant" | "both";

export class TicketTypeSettings extends Settings<TicketTypes> {
  protected get storageKey(): string {
    return storageKeys.ticketTypes;
  }

  public allTicketTypes(addNoTicketType = true) {
    const settings = this.get();

    const types = settings?.ticket_types ?? [];

    if (addNoTicketType) {
      types.unshift({
        id: "",
        key: "no_ticket_type",
        name: "Keine Ticketart",
        fields: []
      });
    }

    return types;
  }

  public getTicketType(typeKey: string) {
    return this.allTicketTypes(false).find(t => t.key === typeKey);
  }
}
