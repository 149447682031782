var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-file-input',{attrs:{"label":"Bild hochladen","prepend-icon":"","prepend-inner-icon":"add_photo_alternate","outlined":"","dense":"","accept":"image/*","show-size":1000,"loading":_vm.images.uploadResponse.loading,"error-messages":_vm.images.uploadResponse.error},on:{"change":function($event){return _vm.emitUpload($event)}}}),_c('Grid',[_c('Row',{attrs:{"wrap":""}},[_c('viewer',{ref:"imageViewer",staticStyle:{"display":"contents"},attrs:{"images":_vm.images.images.map(i => i.path),"options":{
          title: false,
          toolbar: {
            zoomIn: false,
            zoomOut: false,
            oneToOne: true,
            reset: true,
            prev: 'large',
            play: false,
            next: 'large',
            rotateLeft: false,
            rotateRight: false,
            flipHorizontal: false,
            flipVertical: false
          }
        }},on:{"inited":_vm.initImageViewer}},[_vm._l((_vm.images.images),function(image,index){return _c('Column',{key:image.id,attrs:{"xs6":"","sm4":""}},[_c('img',{staticStyle:{"display":"none"},attrs:{"src":image.path}}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-img',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"pointer",attrs:{"src":image.path,"max-height":"100"}},[_c('v-fade-transition',[(hover || _vm.confirmIndex === index)?_c('v-overlay',{attrs:{"absolute":"","color":"white"}},[_c('IconButton',{staticClass:"pa-1",attrs:{"icon":"image","filled":true,"tooltipText":"Bild betrachten","skipTooltip":""},on:{"click":function($event){return _vm.imageSelected(index)}}}),(image.hasUrlPath)?_c('IconButton',{staticClass:"pa-1",attrs:{"icon":"open_in_new","filled":true,"tooltipText":"Bild öffnen","skipTooltip":""},on:{"click":function($event){return _vm.openImage(image.path)}}}):_vm._e(),_c('RequestButton',{staticClass:"pa-1",attrs:{"iconButton":"","confirm":"","icon":"delete","color":"error","text":"Bild löschen","confirmTitle":"Wirklich löschen?","confirmText":"Sind Sie sicher, dass sie das Anlagenbild löschen möchten?","filled":true,"disabled":image.deleteResponse.loading,"loading":image.deleteResponse.loading,"error":image.deleteResponse.error},on:{"update:confirming":function($event){return _vm.confirmingDeletion($event, index)},"click":function($event){return _vm.emitDelete(image)}}})],1):_vm._e()],1)],1)]}}],null,true)})],1)})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }