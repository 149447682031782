import { IProfileManagerGateway } from "@/common/interactors/ProfileManager";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { Client } from "@/entities/Client";
import { storageKeys } from "@/data/storageKeys";

export class ProfileManagerGateway implements IProfileManagerGateway {
  public constructor(private storage: SimpleStringStorage) {}

  public loadClientId(): Promise<Client> {
    return Promise.resolve(this.storage.get(storageKeys.clientId)).then(
      clientId => new Client(parseInt(clientId, 10), "")
    );
  }
}
