import { ChecklistModel } from "@/inspection/checklist/models/ChecklistModel";
import {
  Assignment,
  AssignmentState
} from "@/inspection/shared/dtos/Assignment";
import { AssignmentEntity } from "@/inspection/shared/entities/AssignmentEntity";
import { Plant } from "@/property/shared/models/Plant";
import { DateUtils } from "@/shared/utils/DateUtils";
import { PropertyModel } from "./PropertyModel";

export class AssignmentModel {
  public static from(assignment: Assignment) {
    return new AssignmentModel(
      assignment.id,
      assignment.number,
      assignment.date,
      assignment.description,
      assignment.firstEntryId,
      assignment.state,
      ChecklistModel.from(assignment.checklist),
      PropertyModel.from(assignment.property),
      Plant.from(assignment.plant)
    );
  }

  private constructor(
    public id: number,
    public assignmentNumber: string,
    public date: Date,
    public description: string,
    public firstEntryId: number,
    public state: AssignmentState,
    public checklist: ChecklistModel,
    public property: PropertyModel,
    public plant: Plant
  ) {}

  public get exists() {
    return !!this.id;
  }

  public get number() {
    return this.assignmentNumber;
  }

  public get address() {
    return this.property.address;
  }

  public get street() {
    return this.property.street;
  }

  public get city() {
    return this.property.fullCity;
  }

  public get propertyId() {
    return this.property.id;
  }

  public get checklistId() {
    return this.checklist.id;
  }

  public get checklistType() {
    return this.checklist.type;
  }

  public get formattedDate() {
    return DateUtils.format(this.date);
  }

  public get prepared() {
    return this.state !== AssignmentState.Preparation;
  }

  public get hasPlant() {
    return this.plant.exists;
  }

  public toEntity(): AssignmentEntity {
    return {
      id: this.id,
      number: this.number,
      date: this.date,
      description: this.description,
      plant: this.plant?.toEntity()
    };
  }
}
