
import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import SubmitTextfield from "@/components/basic/SubmitTextfield.vue";

import CreateAssignmentButton from "./assignmentListComponents/CreateAssignmentButton.vue";
import AssignmentList from "./assignmentListComponents/AssignmentList.vue";

import { Authorizator } from "@/common/interactors/Authorizator";

@Component<AssignmentListPage>({
  components: {
    Page,
    Section,
    Card,
    Grid,
    Row,
    Column,
    SubmitTextfield,
    CreateAssignmentButton,
    AssignmentList
  },
  props: {
    propertyId: {
      type: String,
      default: undefined
    },
    plantType: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: ""
    }
  }
})
export default class AssignmentListPage extends Vue {
  protected search = "";

  protected get addAssignmentButtonVisible() {
    return Authorizator.canCreateProperties();
  }
}
