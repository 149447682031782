import { SelectionItem } from "../form/FormTypes";
import { Dictionary } from "./Dictionary";

export interface Duration extends Dictionary<number | undefined> {
  milliseconds?: number;
  seconds?: number;
  minutes?: number;
  hours?: number;
  days?: number;
  weeks?: number;
  months?: number;
  years?: number;
}

export type DurationInterval =
  | "milliseconds"
  | "seconds"
  | "minutes"
  | "hours"
  | "days"
  | "weeks"
  | "months"
  | "years";

export function buildDurationInvervalSelectionItems(
  intervals?: DurationInterval[]
): SelectionItem[] {
  if (!intervals) {
    return [];
  }

  const selectionItems: SelectionItem[] = [];

  for (const interval of intervals) {
    switch (interval) {
      case "milliseconds":
        selectionItems.push({
          text: "Millisekunden",
          value: "milliseconds"
        });
        break;

      case "seconds":
        selectionItems.push({
          text: "Sekunden",
          value: "seconds"
        });
        break;

      case "minutes":
        selectionItems.push({
          text: "Minuten",
          value: "minutes"
        });
        break;

      case "hours":
        selectionItems.push({
          text: "Stunden",
          value: "hours"
        });
        break;

      case "days":
        selectionItems.push({
          text: "Tage",
          value: "days"
        });
        break;

      case "weeks":
        selectionItems.push({
          text: "Wochen",
          value: "weeks"
        });
        break;

      case "months":
        selectionItems.push({
          text: "Monate",
          value: "months"
        });
        break;

      case "years":
        selectionItems.push({
          text: "Jahre",
          value: "years"
        });
        break;
    }
  }

  return selectionItems;
}
