
import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";

import AccessManager from "./accessManagementComponents/AccessManager.vue";

@Component<AccessManagementPage>({
  components: {
    Page,
    AccessManager
  }
})
export default class AccessManagementPage extends Vue {}
