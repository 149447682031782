import { DWSBaseService, DWSBaseSync } from "../services/DWSBaseService";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";

export class DWSBaseSyncController {
  public constructor(
    private presenter: IDWSBaseSyncPresenter,
    private dwsBaseService: DWSBaseService
  ) {}

  public init() {
    this.syncDWSBaseReports();
  }

  public closeButtonClicked() {
    this.presenter.close = true;
  }

  private syncDWSBaseReports() {
    const request = this.dwsBaseService.syncDWSBaseReports();

    FormRequestHandler.handle(
      request,
      response => (this.presenter.syncDWSBaseResponse = response),
      "Synchronisation der DWSBase-Reports gescheitert."
    );
  }
}

export interface IDWSBaseSyncPresenter {
  close: boolean;

  syncDWSBaseResponse: FormResponse<DWSBaseSync>;
}
