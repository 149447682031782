import { Comment } from "@/common/shared/models/Comment";
import { CommentRepository } from "@/common/shared/repositories/commentRepository/CommentRepository";
import { UseCase } from "@/shared/datastructures/UseCase";
import { RequestHandler } from "@/shared/utils/RequestHandler";

export class ChangeInternalCommentStateUseCase extends UseCase<
  ChangeInternalCommentStateUseCaseInput,
  Comment | undefined
> {
  public constructor(private commentRepo: CommentRepository) {
    super();
  }

  public async do(
    input: ChangeInternalCommentStateUseCaseInput
  ): Promise<Comment | undefined> {
    const comment = input.comment;

    if (!comment.exists) {
      throw new Error("Der zu aktualisierende Kommentar muss eine ID haben");
    }

    await RequestHandler.handle(
      this.commentRepo.changeInternalStateOfComment(comment, input.internal),
      this.response
    );

    return this.response.result;
  }
}

interface ChangeInternalCommentStateUseCaseInput {
  comment: Comment;
  internal: boolean;
}
