
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import PlanSelect from "@/property/shared/widgets/views/PlanSelect.vue";
import InteractivePlan from "@/components/services/InteractivePlan.vue";

import { Property } from "@/property/shared/models/Property";
import { Plan } from "@/property/shared/models/Plan";
import { PlanLocation } from "../models/PlanLocation";
import { Plant } from "@/property/shared/models/Plant";

@Component<PlanPositionLocator>({
  components: {
    PlanSelect,
    InteractivePlan
  }
})
export default class PlanPositionLocator extends Vue {
  @Prop({
    default() {
      return new PlanLocation();
    }
  })
  protected readonly location!: PlanLocation;
  @Prop({
    default() {
      return new Plant();
    }
  })
  protected readonly plant!: Plant;
  @Prop({
    default() {
      return new Property();
    }
  })
  protected readonly property!: Property;

  protected plantHasNoLocation = false;

  @Watch("property")
  protected async onPropertyChanged() {
    if (this.property.id === Property.loadLast().id) {
      this.reset();
    } else {
      this.location.clear();
    }
  }

  @Watch("plant.location")
  protected async onPlantLocationChanged(location?: PlanLocation) {
    if (location && location.hasPlan) {
      this.plantHasNoLocation = false;

      this.location.setPlan(location.plan);
      this.location.updateLocation(location.x ?? 0, location.y ?? 0);

      Property.saveLast(this.plant.property);
      await this.location.saveLastLocation();

      this.emitChangeProperty(this.plant.property);
    } else {
      this.plantHasNoLocation = true;
    }
  }

  public async reset() {
    this.location.clearRequest();
    await this.location.loadLastLocation();
    await this.loadPlan(this.location.plan);
  }

  protected async selectPlan(plan?: Plan) {
    await this.loadPlan(plan);
    this.location.setPlan(plan);
  }

  protected async loadPlan(plan?: Plan) {
    if (plan) {
      await plan.load();
    }
  }

  protected setLocation(pos: { x: number; y: number }) {
    this.location.updateLocation(pos.x, pos.y);
  }

  protected goToPlant(plant?: Plant) {
    if (plant && plant.exists) {
      const routeData = this.$router.resolve({
        name: "plant",
        params: { plantId: plant.id!.toString() }
      });

      window.open(routeData.href, "_blank");
    }
  }

  protected mounted() {
    if (this.property.id === Property.loadLast().id) {
      this.reset();
    }
  }

  protected emitConfirm() {
    this.$emit("confirm");
  }

  protected emitChangeProperty(property?: Property) {
    if (property) {
      this.$emit("change:property", property);
    }
  }
}
