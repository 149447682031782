
import Vue from "vue";
import Component from "vue-class-component";

import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import RequestButton from "@/components/basic/RequestButton.vue";
import Textfield from "@/components/form/Textfield.vue";

import { LoginViewModel } from "@/common/vms/LoginViewModel";
import { LoginController } from "@/common/controllers/LoginController";
import { LoginPresenter } from "@/common/presenters/LoginPresenter";
import { Authenticator } from "@/common/interactors/Authenticator";
import { AuthenticatorGraphQLGateway } from "@/gateways/graphql/AuthenticatorGraphQLGateway";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connection/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { LocalStorage } from "@/storage/LocalStorage";

@Component<LoginView>({
  components: {
    Grid,
    Row,
    Column,
    Textfield,
    RequestButton
  },
  watch: {
    "vm.loggedIn"(loggedIn: boolean) {
      if (loggedIn) {
        this.$router.push({ name: "home" });
      }
    }
  }
})
export default class LoginView extends Vue {
  private vm = new LoginViewModel();

  private get controller() {
    return new LoginController(
      new LoginPresenter(this.vm, new LocalStorage()),
      new Authenticator(
        new AuthenticatorGraphQLGateway(
          new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger()))
        )
      )
    );
  }

  private mounted() {
    this.controller.init();
  }
}
