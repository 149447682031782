import { Plant } from "@/property/shared/models/Plant";
import { PlantCollection } from "@/property/shared/models/PlantCollection";
import { ServerResponse } from "@/shared/datastructures/Response";
import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { AssignmentData, AssignmentEntity } from "../entities/AssignmentEntity";
import { InspectionRepoContainer } from "../repositories/InspectionRepoContainer";
import { Checklist } from "./Checklist";

interface AssignmentInitOptions {
  id?: number;
}

interface AssignmentLoadOptions {
  includeChecklist?: boolean;
}

export class Assignment extends RequestModel<AssignmentEntity> {
  public id?: number = 0;
  public number?: string = "";
  public date?: Date = new Date();
  public description?: string = "";
  public plant?: Plant = new Plant();
  public checklistPlants?: PlantCollection = new PlantCollection();
  public checklist?: Checklist = new Checklist();
  public prevAssignment?: Assignment;

  public upsertResponse = new ServerResponse<number>();
  public generateReportResponse = new ServerResponse<string>();

  public get exists() {
    return !!this.id;
  }

  public get prepared() {
    return this.checklist?.preparationDone ?? false;
  }

  public get hasPlant() {
    return this.plant?.exists ?? false;
  }

  public set data(data: AssignmentData) {
    this.number = data.number;
    this.date = data.date;
    this.description = data.description;
  }

  public init(options?: AssignmentInitOptions) {
    this.id = options?.id;
  }

  public followUp(assignment: Assignment) {
    if (!assignment.exists) {
      return;
    }

    this.prevAssignment = assignment;
    this.plant = assignment.plant;
  }

  public load(options?: AssignmentLoadOptions) {
    return RequestHandler.handleModel(
      () =>
        InspectionRepoContainer.assignmentRepo.getAssignmentById(
          this.id ?? 0,
          options?.includeChecklist ?? false
        ),
      this
    );
  }

  public upsert() {
    if (!this.exists) {
      return RequestHandler.handle(
        InspectionRepoContainer.assignmentRepo.create(this.toEntity()),
        this.upsertResponse,
        {
          onSuccess: id => (this.id = id)
        }
      );
    }

    return false;
  }

  public generateCsv() {
    return RequestHandler.handle(
      InspectionRepoContainer.reportRepo.generateSimpleReport(
        this.id ?? 0,
        "csv"
      ),
      this.generateReportResponse
    );
  }

  public toEntity(): AssignmentEntity {
    return {
      id: this.id,
      number: this.number,
      date: this.date,
      description: this.description,
      plant: this.plant?.toEntity(),
      checklistPlants: this.checklistPlants?.toEntities(),
      checklist: this.checklist?.toEntity(),
      prevAssignment: this.prevAssignment?.toEntity()
    };
  }

  public from(entity?: AssignmentEntity) {
    this.id = entity?.id;
    this.number = entity?.number;
    this.date = entity?.date;
    this.description = entity?.description;
    this.plant = Plant.from(entity?.plant);
    this.checklistPlants = PlantCollection.collectFrom(entity?.checklistPlants);
    this.checklist = Checklist.from(entity?.checklist);
    this.checklist.assignment = this;

    if (entity?.prevAssignment) {
      this.prevAssignment = Assignment.from(entity?.prevAssignment);
    }
  }

  public static from(entity?: AssignmentEntity) {
    const assignment = new Assignment();
    assignment.from(entity);
    return assignment;
  }
}
