import { storageKeys } from "@/data/storageKeys";
import { Settings } from "@/shared/datastructures/Settings";

export interface TicketTemplates {
  templates: TicketTemplate[];
}

export interface TicketTemplate {
  id: string;
  title: string;
  description: string;
  action: string;
}

export class TicketTemplateSettings extends Settings<TicketTemplates> {
  protected get storageKey(): string {
    return storageKeys.ticketTemplates;
  }

  public allTicketTemplates() {
    const settings = this.get();
    return settings?.templates ?? [];
  }

  public getTicketTemplate(templateTitle: string) {
    return this.allTicketTemplates().find(c => c.title === templateTitle);
  }
}
