const variablePrefix = "App\\";
const uploadPrefix = "App\\\\";

function getType(upload = false, type: string) {
  return (upload ? uploadPrefix : variablePrefix) + type;
}

export default {
  client: (upload = false) => getType(upload, "Client"),
  property: (upload = false) => getType(upload, "Property"),
  ticket: (upload = false) => getType(upload, "Ticket"),
  employee: (upload = false) => getType(upload, "Employee")
} as { [type: string]: (upload?: boolean) => string };
