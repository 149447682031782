import { NumericalId } from "@/datastructures/NumericalId";
import { PropertyForm } from "@/forms/property/PropertyForm";
import { CountryList, countries } from "@/data/settings";
import {
  IPropertyManager,
  CreatePropertyData,
  PayloadPropertyLocation,
  PropertyImage,
  RelationshipInput
} from "../interactors/PropertyManager";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { PropertyGeneralDataForm } from "@/forms/property/PropertyGeneralDataForm";
import { PropertyDetailsForm } from "@/forms/property/PropertyDetailsForm";
import { PropertyAddressForm } from "@/forms/property/PropertyAddressForm";
import {
  IPersonEditor,
  PersonCatalogRow
} from "@/common/interactors/PersonEditor";
import { UserSettings } from "@/storage/UserSettings";
import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { RelationshipDTO } from "../vms/PropertyDetailViewModel";
import { PropertyRelationshipForm } from "@/forms/property/PropertyRelationshipForm";

export class PropertyDetailController {
  public constructor(
    private presenter: IPropertyDetailPresenter,
    private interactor: IPropertyManager,
    private personService: IPersonEditor
  ) {}

  public init(propertyId: string) {
    this.presenter.init();
    this.presenter.propertyId = propertyId;
    this.presenter.countries = countries;

    this.loadProperty(propertyId);
    this.loadPeople();
  }

  // Requests
  public loadProperty(propertyId: string) {
    NumericalId.onValidId(
      propertyId,
      id => {
        const request = this.interactor.loadProperty(id);

        FormRequestHandler.handle(
          request,
          response => (this.presenter.loadPropertyResponse = response),
          "Gebäude konnte nicht geladen werden"
        );
      },
      () => (this.presenter.editProperty = false)
    );
  }

  public loadPeople() {
    NumericalId.onValidId(UserSettings.getClientId(), clientId => {
      const page = new Page(1000, 1);
      const request = this.personService.loadPeopleCatalog(clientId, page, "");

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadPeopleResponse = response),
        "Personen konnten nicht geladen werden"
      );
    });
  }

  public createPropertyButtonClicked() {
    const request = this.interactor.createProperty(this.presenter.property);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.createPropertyResponse = response),
      "Gebäude konnte nicht erstellt werden"
    );
  }

  public deletePropertyButtonClicked(propertyId: string) {
    NumericalId.onValidId(propertyId, id => {
      const request = this.interactor.deleteProperty(id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.deletePropertyResponse = response),
        "Gebäude konnte nicht gelöscht werden"
      );
    });
  }

  // Actions
  public enableGeneralDataEditing() {
    this.presenter.editingGeneralData = true;
  }

  public disableGeneralDataEditing(propertyId: string) {
    NumericalId.onValidId(propertyId, id => {
      const request = this.interactor.updateGeneralData(
        this.presenter.property
      );

      FormRequestHandler.handle(
        request,
        response => (this.presenter.updateGeneralDataResponse = response)
      );
    });
  }

  public enableDetailsEditing() {
    this.presenter.editingDetails = true;
  }

  public disableDetailsEditing(propertyId: string) {
    NumericalId.onValidId(propertyId, id => {
      const request = this.interactor.updateDetails(this.presenter.property);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.updateDetailsResponse = response)
      );
    });
  }

  public enableAddressEditing() {
    this.presenter.editingAddress = true;
  }

  public disableAddressEditing(propertyId: string) {
    NumericalId.onValidId(propertyId, id => {
      const request = this.interactor.updateAddress(this.presenter.property);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.updateAddressResponse = response)
      );
    });
  }

  public propertyImageChanged(image: File[], propertyId: string) {
    NumericalId.onValidId(propertyId, id => {
      const request = this.interactor.setPropertyImage(image[0], id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.setPropertyImageResponse = response),
        "Bild-Upload fehlgeschlagen"
      );
    });
  }

  public deletePropertyImageButtonClicked(imageId: number) {
    NumericalId.onValidId(imageId, id => {
      const request = this.interactor.deletePropertyImage(id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.deletePropertyImageResponse = response),
        "Löschen des Bildes fehlgeschlagen"
      );
    });
  }

  public addRelationshipButtonClicked(relationshipId: string) {
    this.presenter.relationshipDialogVisible = true;
    this.presenter.editRelationship(undefined);
    this.presenter.selectedRelationshipType = relationshipId;
  }

  public closeRelationshipDialogButtonClicked() {
    this.presenter.relationshipDialogVisible = false;
  }

  public cancelRelationshipButtonClicked() {
    this.presenter.relationshipDialogVisible = false;
  }

  public saveRelationshipButtonClicked(
    propertyId: string,
    relationship?: RelationshipDTO
  ) {
    NumericalId.onValidId(
      propertyId,
      id => {
        const request = this.interactor.upsertRelationship(
          id,
          this.presenter.relationship
        );

        FormRequestHandler.handle(
          request,
          response => (this.presenter.upsertRelationshipResponse = response),
          "Beziehung konnte nicht gespeichert werden",
          { relationship }
        );
      },
      () => {
        if (relationship) {
          this.presenter.updateRelationship(relationship);
        } else {
          this.presenter.addRelationship(this.presenter.relationshipUnderEdit);
        }
      }
    );
  }

  public editRelationshipButtonClicked(relationship: RelationshipDTO) {
    this.presenter.editRelationship(relationship);
  }

  public deleteRelationshipButtonClicked(
    propertyId: string,
    relationship: RelationshipDTO
  ) {
    NumericalId.onValidId(
      propertyId,
      numPropertyId => {
        NumericalId.onValidId(relationship.id, id => {
          const request = this.interactor.deleteRelationship(numPropertyId, id);

          FormRequestHandler.handle(
            request,
            response => (this.presenter.deleteRelationshipResponse = response),
            "Beziehung konnte nicht gelöscht werden",
            { relationship }
          );
        });
      },
      () => {
        this.presenter.removeRelationship(relationship);
      }
    );
  }

  // Input
  public nameChanged(name: string) {
    this.presenter.propertyForm.setFieldValue("name", name);
    this.presenter.generalDataForm.setFieldValue("name", name);
  }
  public descriptionChanged(description: string) {
    this.presenter.propertyForm.setFieldValue("description", description);
    this.presenter.generalDataForm.setFieldValue("description", description);
  }

  public legalFormChanged(legalForm: string) {
    this.presenter.propertyForm.setFieldValue("legalForm", legalForm);
    this.presenter.detailsForm.setFieldValue("legalForm", legalForm);
  }
  public cadastralCommunityChanged(cadastralCommunity: string) {
    this.presenter.propertyForm.setFieldValue(
      "cadastralCommunity",
      cadastralCommunity
    );
    this.presenter.detailsForm.setFieldValue(
      "cadastralCommunity",
      cadastralCommunity
    );
  }
  public assetNumberChanged(assetNumber: string) {
    this.presenter.propertyForm.setFieldValue("assetNumber", assetNumber);
    this.presenter.detailsForm.setFieldValue("assetNumber", assetNumber);
  }
  public buildYearChanged(buildYear: string) {
    this.presenter.propertyForm.setFieldValue("buildYear", buildYear);
    this.presenter.detailsForm.setFieldValue("buildYear", buildYear);
  }
  public parcelNumberChanged(parcelNumber: string) {
    this.presenter.propertyForm.setFieldValue("parcelNumber", parcelNumber);
    this.presenter.detailsForm.setFieldValue("parcelNumber", parcelNumber);
  }
  public effectiveAreaChanged(effectiveArea: string) {
    this.presenter.propertyForm.setFieldValue("effectiveArea", effectiveArea);
    this.presenter.detailsForm.setFieldValue("effectiveArea", effectiveArea);
  }
  public unitCountChanged(unitCount: string) {
    this.presenter.propertyForm.setFieldValue("unitCount", unitCount);
    this.presenter.detailsForm.setFieldValue("unitCount", unitCount);
  }
  public singleCadastreChanged(singleCadastre: string) {
    this.presenter.propertyForm.setFieldValue("singleCadastre", singleCadastre);
    this.presenter.detailsForm.setFieldValue("singleCadastre", singleCadastre);
  }
  public dwsBaseNumberChanged(dwsBaseNumber: string) {
    this.presenter.propertyForm.setFieldValue("dwsBaseNumber", dwsBaseNumber);
    this.presenter.detailsForm.setFieldValue("dwsBaseNumber", dwsBaseNumber);
  }

  public streetChanged(street: string) {
    this.presenter.propertyForm.setFieldValue("street", street);
    this.presenter.addressForm.setFieldValue("street", street);
  }
  public zipChanged(zip: string) {
    this.presenter.propertyForm.setFieldValue("zip", zip);
    this.presenter.addressForm.setFieldValue("zip", zip);
  }
  public cityChanged(city: string) {
    this.presenter.propertyForm.setFieldValue("city", city);
    this.presenter.addressForm.setFieldValue("city", city);
  }
  public countryChanged(country: string) {
    this.presenter.propertyForm.setFieldValue("country", country);
    this.presenter.addressForm.setFieldValue("country", country);
  }

  public relationshipFieldChanged(fieldName: string, value: string) {
    this.presenter.relationshipForm.setFieldValue(fieldName, value);
  }
}

export interface IPropertyDetailPresenter {
  propertyId: string;

  relationshipDialogVisible: boolean;
  selectedRelationshipType: string;

  propertyForm: PropertyForm;
  generalDataForm: PropertyGeneralDataForm;
  detailsForm: PropertyDetailsForm;
  addressForm: PropertyAddressForm;
  relationshipForm: PropertyRelationshipForm;
  editingGeneralData: boolean;
  editingDetails: boolean;
  editingAddress: boolean;

  property: CreatePropertyData;
  relationshipUnderEdit: RelationshipDTO;
  relationship: RelationshipInput;

  loadPropertyResponse: FormResponse<CreatePropertyData>;
  loadPeopleResponse: FormResponse<PaginatedList<PersonCatalogRow>>;
  createPropertyResponse: FormResponse<number>;
  deletePropertyResponse: FormResponse<number>;
  updateGeneralDataResponse: FormResponse<number>;
  updateDetailsResponse: FormResponse<number>;
  updateAddressResponse: FormResponse<PayloadPropertyLocation>;
  setPropertyImageResponse: FormResponse<PropertyImage>;
  deletePropertyImageResponse: FormResponse<void>;
  upsertRelationshipResponse: FormResponse<number>;
  deleteRelationshipResponse: FormResponse<void>;

  countries: CountryList;

  editProperty: boolean;

  init(): void;
  addRelationship(relationship: RelationshipDTO): void;
  editRelationship(relationship?: RelationshipDTO): void;
  updateRelationship(relationship: RelationshipDTO): void;
  removeRelationship(relationship: RelationshipDTO): void;
}
