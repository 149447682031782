
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { Checklist } from "@/inspection/shared/models/Checklist";

@Component<ProtectionGoalNiveau>({
  components: {}
})
export default class ProtectionGoalNiveau extends Vue {
  @Prop({ type: Object, default: () => new Checklist() })
  protected readonly checklist!: Checklist;
}
