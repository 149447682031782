import { Access } from "@/common/shared/models/Access";
import Router from "@/router";

export interface AccessLinkBuilder {
  build(access: Access): string;
}

export class VueAccessLinkBuilder implements AccessLinkBuilder {
  public build(access: Access): string {
    const resource = access.resource;

    if (resource) {
      const idName = resource.type + "Id";

      const host = window.location.host;
      const route = Router.resolve({
        name: resource.type,
        params: { [idName]: resource.id?.toString() ?? "" },
        query: { accessKey: access.key }
      }).href;

      return host + route;
    } else {
      throw new Error("Link kann nicht gebaut werden");
    }
  }
}
