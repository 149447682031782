import { Logo } from "./Logo";
import { PhoneNumber } from "./PhoneNumber";
import { Address } from "./Address";

export class Client {
  public id: number;
  public name: string;
  public vat = "";
  public logo = new Logo(0, "", "");
  public logos: Logo[] = [];
  public phoneNumber = new PhoneNumber(0);
  public email = "";
  public website = "";
  public skype = "";
  public address = new Address("", "", "");

  public constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}
