
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { Ticket } from "@/inspection/shared/models/Ticket";

import Combobox from "@/components/form/Combobox.vue";
import { TicketDescriptionForm as Form } from "../../forms/TicketDescriptionForm";
import { TicketCreationForm } from "./TicketCreationWorkflow.vue";

import { InspectionSettingsContainer } from "@/inspection/shared/settings/InspectionSettingsContainer";

@Component<TicketDescriptionForm>({
  components: {}
})
export default class TicketDescriptionForm
  extends Vue
  implements TicketCreationForm
{
  @Prop({ default: () => new Ticket() })
  protected readonly ticket!: Ticket;

  @Prop({ type: Boolean, default: false })
  protected readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  protected readonly moreSpace!: boolean;

  protected form = new Form();
  protected matchesWithTicketTemplate = false;

  @Watch("ticket")
  protected ticketChanged() {
    this.initFormData();
  }

  protected get titleField() {
    return this.$refs.titleField as Combobox;
  }

  public validate() {
    this.emitValidate();
    return this.form.isValid();
  }

  public async reset() {
    this.matchesWithTicketTemplate = false;
    await this.form.reset();
  }

  protected loadTicketTemplate(title: string) {
    const template =
      InspectionSettingsContainer.ticketTemplates.getTicketTemplate(title);

    if (template) {
      this.updateTicketTitle(template.title);
      this.updateTicketDescription("description", template.description);
      this.updateTicketDescription("action", template.action);

      this.$nextTick(() => this.titleField.blur());
    }
  }

  protected updateTicketTitle(title: string) {
    this.matchesWithTicketTemplate =
      !!InspectionSettingsContainer.ticketTemplates.getTicketTemplate(title);

    this.updateTicketDescription("title", title);
  }

  protected updateTicketDescription(fieldName: string, value: unknown) {
    this.form.setFieldValue(fieldName, value);
    this.ticket.updateDescription(this.form.getData());
    this.validate();
    this.emitChange();
  }

  protected emitValidate() {
    this.$emit("validate", this.form.isValid());
  }

  protected emitChange() {
    this.$emit("change", this.ticket);
  }

  protected async created() {
    this.form = new Form();
    await this.form.init();
    this.initFormData();
  }

  protected initFormData() {
    this.form.setData(this.ticket.toEntity());
  }
}
