
import Vue from "vue";
import Component from "vue-class-component";

import ClientForm from "./clientDetailComponents/ClientForm.vue";
import CreateClientButton from "./clientDetailComponents/CreateClientButton.vue";
import DeleteClientButton from "./clientDetailComponents/DeleteClientButton.vue";
import EmployeeTable from "./employeeListComponents/EmployeeTable.vue";

import { Client } from "@/property/shared/models/Client";
import { Authorizator } from "@/common/interactors/Authorizator";

@Component<ClientDetailPage>({
  components: {
    ClientForm,
    CreateClientButton,
    DeleteClientButton,
    EmployeeTable
  }
})
export default class ClientDetailPage extends Vue {
  protected client = new Client();

  protected get title() {
    return (
      "Mandant " +
      (this.client?.success ? `'${this.client?.name}' ` : "") +
      (this.client?.exists ? "" : "erstellen")
    );
  }

  protected get clientId() {
    return this.clientIdString ? parseInt(this.clientIdString, 10) : undefined;
  }

  protected get clientIdString() {
    return this.$route.params?.clientId;
  }

  protected get canListEmployees() {
    return Authorizator.canListEmployees();
  }

  protected goToClientsPage() {
    this.$router.push({ name: "clients" });
  }

  protected created() {
    this.client = new Client({ id: this.clientId });
  }
}
