import { Dictionary } from "../datastructures/Dictionary";

export class ObjectUtils {
  public static deepCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
  }

  public static isEmpty(obj: object) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  public static isObject(obj: unknown): obj is object {
    return typeof obj === "object" && obj !== null;
  }

  public static hasKey(key: string, obj: unknown) {
    return this.isObject(obj) && Object.keys(obj).includes(key);
  }

  public static clean(obj: Dictionary<unknown>) {
    Object.keys(obj).forEach(key => delete obj[key]);
  }

  public static shallowEqual(
    object1: Dictionary<any>,
    object2: Dictionary<any>
  ) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }

    return true;
  }
}
