
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import Row from "../layout/Row.vue";
import Column from "../layout/Column.vue";
import Progress from "./Progress.vue";

@Component<Loading>({
  components: {
    Row,
    Column,
    Progress
  }
})
export default class Loading extends Vue {
  @Prop({ type: Number, default: 32 }) protected readonly size!: number;
}
