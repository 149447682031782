import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { UserSettings } from "@/storage/UserSettings";
import { PersonEntity } from "../entities/PersonEntity";
import { PropertyReportContainer } from "../repositories/PropertyRepoContainer";
import { Person } from "./Person";

export class PersonCollection extends RequestModel<PersonEntity[]> {
  public people?: Person[] = [];

  public get empty() {
    return (this.people?.length ?? 0) === 0;
  }

  public clear() {
    this.people = [];
    this.clearRequest();
  }

  public search(search: string) {
    RequestHandler.handleModel(
      () =>
        PropertyReportContainer.personRepo.getPersonSelection(
          UserSettings.getNumericClientId(),
          search
        ),
      this
    );
  }

  public from(people?: PersonEntity[]) {
    this.people = people?.map(c => Person.from(c));
  }
}
