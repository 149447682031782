import { render, staticRenderFns } from "./PaginatedDatatable.vue?vue&type=template&id=304ea25b&scoped=true&"
import script from "./PaginatedDatatable.vue?vue&type=script&lang=ts&"
export * from "./PaginatedDatatable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "304ea25b",
  null
  
)

export default component.exports