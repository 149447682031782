
import Vue from "vue";
import Component from "vue-class-component";

import { FormField } from "@/shared/form/FormData";
import { DateUtils } from "@/shared/utils/DateUtils";

@Component<DatePicker>({
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      default() {
        return { value: "", text: "", label: "Datum", error: "" };
      }
    },
    val: {
      type: [Date, String, Number],
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    error: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: "event"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayTodayButton: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  }
})
export default class DatePicker extends Vue {
  protected value!: FormField;
  protected val!: Date | string | number;
  protected expanded = false;

  protected get currentValue() {
    return this.val ? this.val : this.value.value;
  }

  protected get dateString() {
    return DateUtils.isoDate(this.currentValue);
  }

  protected get text() {
    return DateUtils.format(this.currentValue);
  }

  protected chooseDate(date: string) {
    this.expanded = false;
    this.emitInput(date);
  }

  protected emitInput(date: string) {
    this.$emit("input", date);
    this.$emit("change", date);
  }

  protected mounted() {
    if (!this.currentValue) {
      this.resetDate();
    }
  }

  protected resetDate() {
    this.emitInput(DateUtils.isoDate(new Date())!);
  }
}
