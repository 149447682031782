import { NumericalId } from "@/datastructures/NumericalId";
import { Ticket } from "@/entities/Ticket";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { Page } from "@/datastructures/Page";
import { TableSort } from "@/forms/ViewModelFormTypes";
import { OrderByClause } from "@/gateways/graphql/connection/GraphQLConnection";
import { TypeConverter } from "@/forms/Converter";
import { PersonEntity } from "@/property/shared/entities/PersonEntity";
import { PlantEntity } from "@/property/shared/entities/PlantEntity";

export class TicketViewer implements ITicketViewer {
  public constructor(private gateway: TicketViewerGateway) {}

  public loadTicketCatalog(
    page: Page,
    client: NumericalId,
    search: string = "",
    priority?: string,
    state?: string,
    plantId?: number,
    entryTemplate?: string,
    propertyId?: NumericalId,
    entryId?: string,
    sort?: TableSort[]
  ): Promise<PaginatedList<TicketCatalogRow>> {
    return this.gateway
      .loadTicketCatalog(
        page,
        client,
        search,
        priority,
        state,
        plantId,
        entryTemplate,
        propertyId,
        entryId,
        TypeConverter.toOrderByClause(sort)
      )
      .then(catalog => ({
        items: catalog.items.map(row => ({
          id: row.id,
          imageUrl: row.images.length > 0 ? row.images[0].path : "",
          title: row.title,
          description: row.description,
          action: row.action,
          propertyName: row.property.name,
          propertyStreet: row.property.address.street,
          priority: row.priority,
          state: row.state,
          dueDate: row.dueDate
        })),
        totalCount: catalog.totalCount
      }));
  }

  public ticketExists(ticketId: NumericalId): Promise<boolean> {
    return this.gateway.ticketExists(ticketId);
  }

  public loadTicket(
    ticketId: NumericalId,
    clientId: string,
    propertyId?: string,
    search?: string,
    priority?: string,
    state?: string,
    entryId?: string,
    plantId?: number,
    entryTemplate?: string
  ): Promise<TicketData> {
    return this.gateway.loadTicket(
      ticketId,
      clientId,
      propertyId,
      search,
      priority,
      state,
      entryId,
      plantId,
      entryTemplate
    );
  }

  public generateTicketReport(
    priorities: string[],
    propertyId?: NumericalId
  ): Promise<string> {
    return this.gateway.generateTicketReport(priorities, propertyId);
  }
}

export interface ITicketViewer {
  loadTicketCatalog(
    page: Page,
    client: NumericalId,
    search: string,
    priorities?: string,
    state?: string,
    plantId?: number,
    entryTemplate?: string,
    propertyId?: NumericalId,
    entryId?: string,
    sort?: TableSort[]
  ): Promise<PaginatedList<TicketCatalogRow>>;

  ticketExists(ticketId: NumericalId): Promise<boolean>;
  loadTicket(
    ticketId: NumericalId,
    clientId: string,
    propertyId?: string,
    search?: string,
    priority?: string,
    state?: string,
    entryId?: string,
    plantId?: number,
    entryTemplate?: string
  ): Promise<TicketData>;
  generateTicketReport(
    priorities: string[],
    propertyId?: NumericalId
  ): Promise<string>;
}

export interface TicketViewerGateway {
  loadTicketCatalog(
    page: Page,
    client: NumericalId,
    search: string,
    priorities?: string,
    state?: string,
    plantId?: number,
    entryTemplate?: string,
    propertyId?: NumericalId,
    entryId?: string,
    sort?: OrderByClause
  ): Promise<PaginatedList<Ticket>>;

  ticketExists(ticketId: NumericalId): Promise<boolean>;
  loadTicket(
    ticketId: NumericalId,
    clientId: string,
    propertyId?: string,
    search?: string,
    priority?: string,
    state?: string,
    entryId?: string,
    plantId?: number,
    entryTemplate?: string
  ): Promise<TicketData>;
  generateTicketReport(
    priorities: string[],
    propertyId?: NumericalId
  ): Promise<string>;
}

export interface TicketData {
  id: number;
  title: string;
  description: string;
  action: string;
  propertyId: number;
  propertyName: string;
  propertyStreet: string;
  propertyZip: string;
  propertyCity: string;
  priority: string;
  dueDate: Date;
  type: string;
  data: string;
  state: string;
  images: TicketImage[];
  location?: TicketLocationData;
  entry?: TicketEntryData;
  prevTicketId?: number;
  nextTicketId?: number;
  createdAt?: Date;
  creator?: PersonEntity;
  responsiblePerson?: PersonEntity;
  plant?: PlantEntity;
}

export interface TicketImage {
  id: number;
  path: string;
  pathBig: string;
  pathMedium: string;
  pathSmall: string;
  pathThumbnail: string;
}

export interface TicketCatalogRow {
  id: number;
  imageUrl: string;
  title: string;
  description: string;
  action: string;
  propertyName: string;
  propertyStreet: string;
  priority: string;
  state: number;
  dueDate: Date;
}

export interface TicketLocationData {
  id: NumericalId;
  planId: NumericalId;
  planName: string;
  x: number;
  y: number;
}

export interface TicketEntryData {
  assignmentId: NumericalId;
  checklistId: NumericalId;
  sectionId: NumericalId;
  entryId: NumericalId;
  assignmentNumber: string;
  sectionName: string;
  entryName: string;
}
