
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { TicketCreationForm } from "./TicketCreationWorkflow.vue";

import { Ticket } from "@/inspection/shared/models/Ticket";

import "../../../../../node_modules/js-camera/dist/build.esm";
import { Image } from "@/shared/project/images/Image";
import { StringUtils } from "@/shared/utils/StringUtils";

@Component<TicketImagesForm>({
  components: {}
})
export default class TicketImagesForm
  extends Vue
  implements TicketCreationForm
{
  @Prop({
    default() {
      return new Ticket();
    }
  })
  protected readonly ticket!: Ticket;

  protected selectedImages: File[] = [];
  protected processingImagesRounds = 0;
  protected cameraPermissionDenied = true;

  protected get processingImages() {
    return this.processingImagesRounds > 0;
  }

  protected get camera() {
    return document.querySelector("#camera") as any;
  }

  protected async capturePicture() {
    const cam = this.camera;

    if (!cam) return;

    let permission = await cam.permission();

    if (!!permission && permission !== "granted") {
      this.cameraPermissionDenied = true;
      return;
    } else {
      this.cameraPermissionDenied = false;
    }

    if (!cam.opened) await cam.open();

    await cam.waitOpen();

    if (!cam || !cam.opened) return;

    const base64 = cam.capture({ format: "image/jpeg" });
    const image = Image.from({ name: StringUtils.uuid(), path: base64 });
    this.ticket.imageCollection?.add(image);
  }

  public validate() {
    this.emitValidate();
    return true;
  }

  public reset() {
    this.selectedImages = [];
    this.ticket.clearImages();
    this.checkCameraPermission();
    this.closeCamera();
  }

  protected async imagesChanged(images: File[]) {
    this.selectedImages = images;

    try {
      this.processingImagesRounds++;
      this.emitValidate();

      for (const image of images) {
        await this.ticket.imageCollection?.addImageFromFileIfNotExistent(image);
      }
    } finally {
      this.processingImagesRounds--;
      this.emitValidate();
    }
  }

  protected emitValidate() {
    this.$emit("validate", !this.processingImages);
  }

  protected async checkCameraPermission() {
    const cam = this.camera;

    if (cam) {
      const permission = await cam.permission();
      this.cameraPermissionDenied = !!permission && permission !== "granted";

      if (!this.cameraPermissionDenied && !cam.opened) {
        await cam.open();
      }
    }
  }

  protected closeCamera() {
    const cam = this.camera;

    if (cam && cam.opened) {
      // cam.stream.getTracks().forEach((track: any) => {
      //   track.stop();
      // });
      cam.close();
    }
  }

  protected async mounted() {
    this.checkCameraPermission();
  }
}
