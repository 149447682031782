import { ServerResponse } from "@/shared/datastructures/Response";
import { Existence } from "@/shared/types/Existence";
import { DateUtils } from "@/shared/utils/DateUtils";
import { MathUtils } from "@/shared/utils/MathUtils";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { UserSettings } from "@/storage/UserSettings";
import { NewEmployeeData } from "../dtos/EmployeeDtos";
import { EmployeeEntity } from "../entities/EmployeeEntity";
import { PropertyReportContainer } from "../repositories/PropertyRepoContainer";
import { Client } from "./Client";
import { Person } from "./Person";
import { Role } from "./Role";

interface EmployeeProps {
  id?: number;
  guestId?: string;
  email?: string;
  role?: Role;
  person?: Person;
  client?: Client;
  deletedAt?: Date;
}

export class Employee {
  public id?: number;
  public guestId?: string = "";
  public email?: string;
  public role?: Role;
  public person?: Person;
  public client?: Client;
  public deletedAt?: Date;

  public password?: string;

  public checkExistenceResponse = new ServerResponse<Existence>();
  public createResponse = new ServerResponse<number>();
  public reactivateResponse = new ServerResponse<number>();
  public deleteResponse = new ServerResponse<number>();

  public constructor(props?: EmployeeProps) {
    this.id = props?.id;
    this.guestId = props?.guestId;
    this.email = props?.email;
    this.role = props?.role;
    this.person = props?.person;
    this.client = props?.client;
    this.deletedAt = props?.deletedAt;
  }

  public get exists() {
    return !!this.id || !!this.guestId;
  }

  public get deleted() {
    return (this.deletedAt?.getTime() ?? DateUtils.max) <= Date.now();
  }
  public set deleted(del: boolean) {
    this.deletedAt = del ? new Date() : undefined;
  }

  public get isRootUser() {
    return this.id === 1;
  }

  public get isSelf() {
    return (
      this.id === UserSettings.getNumericUserId() ||
      this.id === UserSettings.getNumericOriginalUserId()
    );
  }

  public get isGuest() {
    return !this.id && !!this.guestId;
  }

  public get name() {
    return this.person?.name ?? "";
  }

  public set creationData(data: NewEmployeeData) {
    this.email = data.email;
    this.role = new Role({ id: data.role });
    this.person = Person.from({
      id: data.personId,
      firstname: data.firstname,
      lastname: data.lastname
    });
    this.password = data.password;
  }

  public equals(other: Employee) {
    return (
      this === other ||
      (!!this.id && this.id === other.id) ||
      (!!this.guestId &&
        (this.guestId === other.guestId ||
          this.guestId === MathUtils.md5(other.guestId ?? "")))
    );
  }

  public impersonate() {
    if (this.client?.exists && this.exists) {
      UserSettings.impersonate(this.client.id!.toString(), this.id!.toString());
    }
  }

  public checkExistence() {
    return RequestHandler.handle(
      PropertyReportContainer.employeeRepo.checkExistence(this.email ?? ""),
      this.checkExistenceResponse,
      {
        onSuccess: existence =>
          (this.checkExistenceResponse.error =
            existence === "EXISTS"
              ? "Email ist vergeben"
              : existence === "DELETED"
              ? "Email ist vergeben, aber der User wurde bereits gelöscht. Fragen Sie einen Superadmin, der den User endgültig löschen kann, um die Email wieder freizugeben"
              : "")
      }
    );
  }

  public create() {
    return RequestHandler.handle(
      PropertyReportContainer.employeeRepo.createEmployee(this.toEntity()),
      this.createResponse,
      {
        onSuccess: id => (this.id = id)
      }
    );
  }

  public reactivate() {
    return RequestHandler.handle(
      PropertyReportContainer.employeeRepo.reactivateEmployee(this.id ?? 0),
      this.reactivateResponse,
      {
        onSuccess: () => (this.deleted = false)
      }
    );
  }

  public del() {
    return RequestHandler.handle(
      PropertyReportContainer.employeeRepo.deleteEmployee(this.id ?? 0),
      this.deleteResponse,
      {
        onSuccess: () => (this.deleted = true)
      }
    );
  }

  public toEntity(): EmployeeEntity {
    return {
      id: this.id,
      guestId: this.guestId,
      email: this.email,
      client: this.client?.toEntity(),
      role: this.role?.toEntity(),
      person: this.person?.toEntity(),
      password: this.password,
      deletedAt: DateUtils.isoDate(this.deletedAt)
    };
  }

  public static from(employee?: EmployeeEntity) {
    return new Employee({
      id: employee?.id,
      guestId: employee?.guestId,
      email: employee?.email,
      client: Client.from(employee?.client),
      role: Role.from(employee?.role),
      person: Person.from(employee?.person),
      deletedAt: new Date(employee?.deletedAt ?? "")
    });
  }
}
