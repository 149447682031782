import { Comment, Commentable } from "../../models/Comment";
import { Comments } from "../../models/Comments";
import { CommentGraphQlProvider } from "./CommentGraphQlProvider";

export interface CommentRepository {
  getComments(commentable: Commentable): Promise<Comments>;
  addComment(comment: Comment): Promise<Comment>;
  updateComment(comment: Comment): Promise<Comment>;
  changeInternalStateOfComment(
    comment: Comment,
    internal: boolean
  ): Promise<Comment>;
  deleteComment(comment: Comment): Promise<Comment>;
}

export class DefaultCommentRepository implements CommentRepository {
  public constructor(private provider = new CommentGraphQlProvider()) {}

  public async getComments(commentable: Commentable): Promise<Comments> {
    const comments = await this.provider.loadComments(commentable);
    return Comments.from(comments);
  }

  public async addComment(comment: Comment): Promise<Comment> {
    const commentEntity = comment.toEntity();
    const createdComment = await this.provider.addComment(commentEntity);
    return Comment.from(createdComment);
  }

  public async updateComment(comment: Comment): Promise<Comment> {
    const commentEntity = comment.toEntity();
    const updatedComment = await this.provider.updateComment(commentEntity);
    return Comment.from(updatedComment);
  }

  public async changeInternalStateOfComment(
    comment: Comment,
    internal: boolean
  ): Promise<Comment> {
    const commentEntity = comment.toEntity();
    const updatedComment = await this.provider.changeInternalStateOfComment(
      commentEntity,
      internal
    );
    return Comment.from(updatedComment);
  }

  public async deleteComment(comment: Comment): Promise<Comment> {
    const commentEntity = comment.toEntity();
    const deletedComment = await this.provider.deleteComment(commentEntity);
    return Comment.from(deletedComment);
  }
}
