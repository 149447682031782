import { convertGraphQlToFileEntity, FileEntity } from "../file/FileEntity";

export interface ImageEntity extends FileEntity {
  pathBig?: string;
  pathMedium?: string;
  pathSmall?: string;
  pathThumbnail?: string;
}

export function convertGraphQlToImageEntity(i: any): ImageEntity {
  return {
    ...convertGraphQlToFileEntity(i),
    pathBig: i.path_big,
    pathMedium: i.path_medium,
    pathSmall: i.path_small,
    pathThumbnail: i.path_thumbnail
  };
}
