import { storageKeys } from "@/data/storageKeys";
import { Dictionary } from "@/shared/datastructures/Dictionary";
import { Settings } from "@/shared/datastructures/Settings";

export enum DocumentCategory {
  General,
  Property
}

export type DocumentTypes = DocumentType[];

export interface DocumentType {
  id: string;
  fields: Dictionary<DocumentField>;
  label: string;
}

interface DocumentField {
  label: string;
  type: string;
}

export class DocumentSettings extends Settings<DocumentTypes> {
  public constructor(private documentType: DocumentCategory) {
    super();
  }

  protected get storageKey(): string {
    return this.documentType === DocumentCategory.Property
      ? storageKeys.propertyDocumentMeta
      : storageKeys.generalDocumentMeta;
  }

  protected get isArray() {
    return true;
  }

  public allTypes() {
    return this.get();
  }
}
