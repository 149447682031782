
import { FormField } from "@/shared/form/FormData";
import { SelectionItem } from "@/shared/form/FormTypes";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component<ListSelect>({})
export default class ListSelect extends Vue {
  @Prop({ type: Object, default: undefined })
  protected readonly value?: FormField;
  @Prop({ type: Array, default: undefined })
  protected readonly items?: SelectionItem[];
  @Prop({ type: Boolean, default: false })
  protected readonly returnObject!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly dense!: boolean;

  protected get selectionItems() {
    return this.items ?? this.value?.items ?? [];
  }

  protected emitInput(item: SelectionItem) {
    this.$emit("input", this.returnObject ? item : item.value);
  }
}
