import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { GraphQLConnection } from "@/gateways/graphql/connection/GraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import {
  GenericData,
  GenericDataConverter
} from "@/shared/datastructures/GenericDataConverter";
import {
  ClientAddressData,
  ClientContactData,
  ClientGeneralData
} from "../../dtos/ClientDtos";
import { ClientEntity } from "../../entities/ClientEntity";
import { ClientProvider } from "./ClientProvider";

export class ClientGraphQLProvider implements ClientProvider {
  private clientConverter = new GenericDataConverter<ClientEntity>([
    { from: "logo" },
    { from: "phoneNumber" },
    { from: "address" },
    { from: "area_code", to: "areaCode" },
    { from: "number", to: "phoneNumber" },
    { from: "postcode", to: "zip" },
    { from: "logo.path_thumbnail", to: "logo" },
    { from: "logos.path_thumbnail", to: "path" }
  ]);

  public constructor(
    private connection: GraphQLConnection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    )
  ) {}

  public async loadClients(): Promise<ClientEntity[]> {
    const result = await this.connection.query("newClients", {}, [
      "id",
      "name",
      { name: "logo", fields: ["path_thumbnail"] }
    ]);

    const converted = result.data.map((d: GenericData) =>
      this.clientConverter.convert(d)
    );

    return converted;
  }

  public async loadClientSelection(): Promise<ClientEntity[]> {
    const result = await this.connection.query("newClients", {}, [
      "id",
      "name"
    ]);

    return result.data.map((d: GenericData) => this.clientConverter.convert(d));
  }

  public async loadClient(id: number): Promise<ClientEntity> {
    const result = await this.connection.query("client", { id }, [
      "id",
      "name",
      "vat",
      "email",
      "website",
      "skype",
      { name: "phoneNumber", fields: ["area_code", "number"] },
      { name: "address", fields: ["street", "postcode", "city", "country"] },
      { name: "logo", fields: ["path_thumbnail"] },
      { name: "logos", fields: ["path_thumbnail", "context"] }
    ]);

    const converted = this.clientConverter.convert(result.data);

    return converted ?? {};
  }

  public async createClient(client: ClientEntity): Promise<number> {
    const result = await this.connection.mutation(
      "newCreateClient",
      {
        input: {
          name: client.name,
          vat: client.vat,
          areaCode: client.areaCode,
          phoneNumber: client.phoneNumber,
          email: client.email,
          website: client.website,
          skype: client.skype,
          street: client.street,
          zip: client.zip,
          city: client.city,
          country: client.country
        }
      },
      []
    );

    return result.data;
  }

  public async updateGeneralData(
    id: number,
    data: ClientGeneralData
  ): Promise<number> {
    const result = await this.connection.mutation(
      "updateGeneralClientData",
      {
        input: {
          id,
          name: data.name,
          vat: data.vat
        }
      },
      []
    );

    return result.data;
  }

  public async updateContactData(
    id: number,
    data: ClientContactData
  ): Promise<number> {
    const result = await this.connection.mutation(
      "updateClientContact",
      {
        input: {
          id,
          areaCode: data.areaCode,
          phoneNumber: data.phoneNumber,
          email: data.email,
          website: data.website,
          skype: data.skype
        }
      },
      []
    );

    return result.data;
  }

  public async updateAddress(
    id: number,
    data: ClientAddressData
  ): Promise<number> {
    const result = await this.connection.mutation(
      "updateClientAddress",
      {
        input: {
          id,
          street: data.street,
          zip: data.zip,
          city: data.city,
          country: data.country
        }
      },
      []
    );

    return result.data;
  }

  public async uploadLogo(
    id: number,
    logo: File,
    context: string
  ): Promise<number> {
    const result = await this.connection.mutation(
      "uploadClientLogo",
      {
        input: {
          id,
          logo: null,
          context
        }
      },
      [],
      undefined,
      {
        path: "input.logo",
        file: logo
      }
    );

    return result.data;
  }

  public async deleteLogo(id: number, context: string): Promise<number> {
    const result = await this.connection.mutation(
      "deleteClientLogo",
      { input: { id, context } },
      []
    );

    return result.data;
  }

  public async deleteClient(id: number): Promise<number> {
    const result = await this.connection.mutation(
      "newDeleteClient",
      {
        id
      },
      []
    );

    return result.data;
  }
}
