
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { Assignment } from "@/inspection/shared/models/Assignment";
import { SelectionItem } from "@/shared/form/FormTypes";
import { UserSettings } from "@/storage/UserSettings";

@Component<CreateReportButton>({
  components: {}
})
export default class CreateReportButton extends Vue {
  @Prop({ type: Object, default: () => new Assignment() })
  protected readonly assignment!: Assignment;

  protected get availableReports() {
    const reports: SelectionItem[] = [];

    if (this.assignment.checklist?.type === "water_meter_inspection") {
      reports.push({
        text: "Wassermesserkontrolle",
        value: "water_meter_inspection"
      });
    } else {
      reports.push({
        text: "PDF-Bericht",
        value: "default"
      });
      reports.push({
        text: "CSV-Datei (Excel)",
        value: "csv"
      });

      if (this.testClient) {
        reports.push({
          text: "Wohnungsübergabeprotokoll",
          value: "move_out_checklist"
        });
      }
    }

    return reports;
  }

  protected get testClient() {
    return (
      UserSettings.getClientId() === "17" || UserSettings.getClientId() === "10"
    );
  }

  protected reportSelected(type: string) {
    const suffix = type === "move_out_checklist" ? "-new" : "";

    if (type === "csv") {
      this.assignment.generateCsv();
    } else {
      this.$router.push({
        name: "edit-report" + suffix,
        params: {
          assignmentId: this.assignment.id?.toString() ?? ""
        },
        query: {
          reportType: type
        }
      });
    }
  }

  protected downloadReport() {
    window.open(this.assignment.generateReportResponse.result, "_blank");
    this.assignment.generateReportResponse.clear();
  }
}
