import Vue from "vue";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Tabs from "@/components/layout/Tabs.vue";
import Stepper from "@/components/layout/Stepper.vue";
import Dialog from "@/components/layout/Dialog.vue";
import FullScreenDialog from "@/components/layout/FullScreenDialog.vue";
import ContextMenu from "@/components/layout/ContextMenu.vue";
import Chip from "@/components/layout/Chip.vue";
import Divider from "@/components/layout/Divider.vue";
import Accordion from "@/components/layout/Accordion.vue";

import StyledText from "@/components/basic/StyledText.vue";
import Button from "@/components/basic/Button.vue";
import IconButton from "@/components/basic/IconButton.vue";
import RequestButton from "@/components/basic/RequestButton.vue";
import ResponsiveIconButton from "@/components/basic/ResponsiveIconButton.vue";
import DropdownButton from "@/components/basic/DropdownButton.vue";
import Loading from "@/components/basic/Loading.vue";
import Progress from "@/components/basic/Progress.vue";
import InfoDialog from "@/components/basic/InfoDialog.vue";
import BasicIconMessage from "@/components/basic/BasicIconMessage.vue";
import ErrorMessage from "@/components/basic/ErrorMessage.vue";
import InfoTooltip from "@/components/basic/InfoTooltip.vue";
import Alert from "@/components/basic/Alert.vue";
import Img from "@/components/basic/Img.vue";
import Icon from "@/components/basic/Icon.vue";
import Value from "@/components/basic/Value.vue";
import ConfirmDialog from "@/components/basic/ConfirmDialog.vue";
import SubmitTextfield from "@/components/basic/SubmitTextfield.vue";
import Url from "@/components/basic/Url.vue";

import Textfield from "@/components/form/Textfield.vue";
import Textarea from "@/components/form/Textarea.vue";
import Select from "@/components/form/Select.vue";
import ListSelect from "@/components/form/ListSelect.vue";
import Combobox from "@/components/form/Combobox.vue";
import Checkbox from "@/components/form/Checkbox.vue";
import RadioGroup from "@/components/form/RadioGroup.vue";
import DatePicker from "@/components/form/DatePicker.vue";
import SearchSelect from "@/components/form/SearchSelect.vue";
import SearchSelectDropdown from "@/components/form/SearchSelectDropdown.vue";
import SingleImageUpload from "@/components/form/SingleImageUpload.vue";
import FileInput from "@/components/form/FileInput.vue";
import IntervalField from "@/components/form/IntervalField.vue";

import DataTable from "@/components/collection/new/DataTable.vue";
import PaginatedDataTable from "@/components/collection/new/PaginatedDataTable.vue";

import ImageGallery from "@/shared/project/images/ImageGallery.vue";

Vue.component("Page", Page);
Vue.component("Section", Section);
Vue.component("Card", Card);
Vue.component("Grid", Grid);
Vue.component("Row", Row);
Vue.component("Column", Column);
Vue.component("Tabs", Tabs);
Vue.component("Stepper", Stepper);
Vue.component("Dialog", Dialog);
Vue.component("FullScreenDialog", FullScreenDialog);
Vue.component("ContextMenu", ContextMenu);
Vue.component("Chip", Chip);
Vue.component("Divider", Divider);
Vue.component("Accordion", Accordion);

Vue.component("StyledText", StyledText);
Vue.component("Button", Button);
Vue.component("IconButton", IconButton);
Vue.component("RequestButton", RequestButton);
Vue.component("ResponsiveIconButton", ResponsiveIconButton);
Vue.component("DropdownButton", DropdownButton);
Vue.component("Loading", Loading);
Vue.component("Progress", Progress);
Vue.component("InfoDialog", InfoDialog);
Vue.component("BasicIconMessage", BasicIconMessage);
Vue.component("ErrorMessage", ErrorMessage);
Vue.component("InfoTooltip", InfoTooltip);
Vue.component("Alert", Alert);
Vue.component("Img", Img);
Vue.component("Icon", Icon);
Vue.component("Value", Value);
Vue.component("ConfirmDialog", ConfirmDialog);
Vue.component("SubmitTextfield", SubmitTextfield);
Vue.component("Url", Url);

Vue.component("Textfield", Textfield);
Vue.component("Textarea", Textarea);
Vue.component("Select", Select);
Vue.component("ListSelect", ListSelect);
Vue.component("Combobox", Combobox);
Vue.component("Checkbox", Checkbox);
Vue.component("RadioGroup", RadioGroup);
Vue.component("DatePicker", DatePicker);
Vue.component("SearchSelect", SearchSelect);
Vue.component("SearchSelectDropdown", SearchSelectDropdown);
Vue.component("SingleImageUpload", SingleImageUpload);
Vue.component("FileInput", FileInput);
Vue.component("IntervalField", IntervalField);

Vue.component("DataTable", DataTable);
Vue.component("PaginatedDataTable", PaginatedDataTable);

Vue.component("ImageGallery", ImageGallery);
