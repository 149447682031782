import { Dictionary } from "@/shared/datastructures/Dictionary";
import { ServerResponse } from "@/shared/datastructures/Response";
import { RequestModel } from "@/shared/model/RequestModel";
import { Image } from "@/shared/project/images/Image";
import { ImageCollection } from "@/shared/project/images/ImageCollection";
import { PlanLocation } from "@/shared/project/planLocation/PlanLocation";
import { FileUtils } from "@/shared/utils/FileUtils";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { PropertySelectionItemLoader } from "../dtos/PropertySelectionItemLoader";
import { GeneralPlantData, PlantEntity } from "../entities/PlantEntity";
import { PropertyReportContainer } from "../repositories/PropertyRepoContainer";
import { PlantType } from "../settings/PlantTypeSettings";
import { PlantChecklistEntryCollection } from "./PlantChecklistEntryCollection";
import { PlantCollection } from "./PlantCollection";
import { PlantField } from "./PlantField";
import { PlantFieldCategoryCollection } from "./PlantFieldCategoryCollection";
import { Property } from "./Property";
import { PropertyPart } from "./PropertyPart";

interface PlantOptions {
  id?: number;
  propertyId?: number;
  partId?: number;
  siblingPartId?: number;
  parentPlantId?: number;
}

export class Plant extends RequestModel<PlantEntity> {
  public id?: number = 0;
  public name?: string = "";
  public bimNumber?: string = "";
  public description?: string = "";
  public data?: Dictionary<object> = {};
  public types?: PlantType[] = [];
  public location?: PlanLocation = new PlanLocation();
  public fieldCategoryCollection?: PlantFieldCategoryCollection =
    new PlantFieldCategoryCollection();
  public checklistEntryCollection?: PlantChecklistEntryCollection =
    new PlantChecklistEntryCollection();
  public plantCollection?: PlantCollection = new PlantCollection();
  public siblingPlantCollection?: PlantCollection = new PlantCollection();
  public parentPlantCollection?: PlantCollection = new PlantCollection();
  public property?: Property = new Property();
  public propertyParts?: PropertyPart[] = [];
  public imageCollection?: ImageCollection = new ImageCollection();
  private hasPersistedChildPlants?: boolean = false;

  public partId?: number = 0;
  public siblingPartId?: number = 0;
  public parentPlant?: Plant;

  public loadLocationResponse = new ServerResponse<PlanLocation>();
  public loadChildrenResponse = new ServerResponse<PlantEntity[]>();
  public createResponse = new ServerResponse<string>();
  public updateGeneralResponse = new ServerResponse<string>();
  public updateDataResponse = new ServerResponse<string>();
  public updateLocationResponse = new ServerResponse<string>();
  public updateStructureResponse = new ServerResponse<string>();
  public deleteResponse = new ServerResponse<string>();

  public get exists() {
    return !!this.id;
  }

  public get labeledKey() {
    return "plant-" + this.id;
  }

  public get children() {
    return this.hasChildPlants ? this.childPlants : undefined;
  }

  public get hasChildPlants() {
    return this.hasPersistedChildPlants || this.childPlants.length > 0;
  }

  public get childPlants() {
    return this.plantCollection?.plants ?? [];
  }

  public get siblingPlants() {
    return this.siblingPlantCollection?.plants ?? [];
  }

  public get parentPlants() {
    return this.parentPlantCollection?.plants ?? [];
  }

  public get images() {
    return this.imageCollection?.images ?? [];
  }

  public get hasLocation() {
    return this.location?.hasPlan ?? false;
  }

  public get fieldCategoryCount() {
    return this.fieldCategories.length;
  }

  public get fieldCategories() {
    return this.fieldCategoryCollection?.categories ?? [];
  }

  public get checklistEntries() {
    return this.checklistEntryCollection?.entries ?? [];
  }

  public get fields() {
    return this.fieldCategories.flatMap(c => c.fields);
  }

  public get hasFields() {
    return this.fieldCategoryCollection?.hasFields ?? false;
  }

  public get flatFieldData() {
    return Object.assign({}, ...Object.values(this.data ?? {}));
  }

  public get hasChecklistEntries() {
    return !(this.checklistEntryCollection?.empty ?? true);
  }

  public get isSiblingPlant() {
    return !!this.siblingPartId;
  }

  public init(options?: PlantOptions) {
    this.id = options?.id;
    this.property = Property.from({ id: options?.propertyId });
    this.partId = options?.partId;
    this.siblingPartId = options?.siblingPartId;
    this.parentPlant = Plant.from({ id: options?.parentPlantId });
  }

  public hasFieldWithKey(fieldKey: string) {
    return !!this.getFieldByKey(fieldKey);
  }

  public getFieldByKey(fieldKey: string) {
    return !!fieldKey ? this.fields.find(f => f.key === fieldKey) : undefined;
  }

  public getFieldById(fieldId: string) {
    return this.fields.find(f => f.id === fieldId);
  }

  public getDataOfField(fieldId: string) {
    return this.flatFieldData[fieldId];
  }

  public getCategoryOfField(field: PlantField) {
    return this.fieldCategories.find(c => c.containsField(field));
  }

  public getChecklistEntryById(id?: string) {
    return this.checklistEntryCollection?.getEntryById(id);
  }

  public addType(type?: PlantType) {
    if (!type) {
      return;
    }

    this.initIfEmpty();

    this.types!.push(type);

    this.fieldCategoryCollection!.merge(
      PlantFieldCategoryCollection.collectFromTypes(type.fieldCategories)
    );
    this.checklistEntryCollection!.merge(
      PlantChecklistEntryCollection.collectFromTypes(type.checklistEntries)
    );
  }

  public removeType(type?: PlantType) {
    if (!type) {
      return;
    }

    this.initIfEmpty();

    this.types = this.types?.filter(t => t.id !== type.id);

    this.fieldCategoryCollection!.thinOut(
      PlantFieldCategoryCollection.collectFromTypes(type.fieldCategories)
    );
    this.checklistEntryCollection!.thinOut(
      PlantChecklistEntryCollection.collectFromTypes(type.checklistEntries)
    );
  }

  public copy() {
    return Plant.from(this.toEntity());
  }

  public clear() {
    this.id = 0;
    this.name = "";
    this.description = "";
    this.types = [];
    this.data = {};
    this.location?.clear();
    this.fieldCategoryCollection?.clear();
    this.checklistEntryCollection?.clear();
    this.plantCollection?.clear();
    this.imageCollection?.clear();
  }

  public toString() {
    if (!this.exists) {
      return "Nicht mehr vorhanden";
    }

    let name = this.name;

    if (this.propertyParts?.length) {
      name += " (" + this.propertyParts.map(p => p.name).join(", ") + ")";
    }

    return name;
  }

  public load() {
    return RequestHandler.handleModel(
      () => PropertyReportContainer.plantRepo.getPlant(this.id ?? 0),
      this
    );
  }

  public loadLocation() {
    return RequestHandler.handle(
      PropertyReportContainer.plantRepo.getPlantLocation(this.id ?? 0),
      this.loadLocationResponse,
      {
        onSuccess: location => (this.location = PlanLocation.from(location))
      }
    );
  }

  public async loadChildren() {
    this.plantCollection?.clear();

    await RequestHandler.handle(
      PropertyReportContainer.plantRepo.getChildPlants(this.id ?? 0),
      this.loadChildrenResponse,
      {
        onSuccess: data =>
          (this.plantCollection = PlantCollection.collectFrom(data))
      }
    );
  }

  public create() {
    return RequestHandler.handle(
      PropertyReportContainer.plantRepo.createPlant(
        this.toEntity(),
        this.property?.id ?? 0,
        this.partId,
        this.parentPlant?.id,
        this.siblingPartId
      ),
      this.createResponse,
      {
        onSuccess: id => {
          this.id = parseInt(id.toString(), 10);
          this.location?.saveLastLocation();
        }
      }
    );
  }

  public async updateGeneralData(data: GeneralPlantData) {
    if (this.exists) {
      return await RequestHandler.handle(
        PropertyReportContainer.plantRepo.updateGeneralData(this.id ?? 0, data),
        this.updateGeneralResponse,
        {
          onSuccess: () => (this.generalData = data)
        }
      );
    } else {
      this.generalData = data;
    }

    return true;
  }

  public async updateFieldValue(field: PlantField, value: object) {
    const category = this.getCategoryOfField(field);

    if (category && category.id && field.id && this.data) {
      const dataCopy = {
        ...(this.data[category.id] as Dictionary<unknown>)
      };

      dataCopy[field.id] = value;

      await this.updateFieldData(category.id, dataCopy);
    }
  }

  public async updateFieldData(categoryId: string, data: object) {
    if (!this.data) {
      return false;
    }

    if (!categoryId) {
      return;
    }

    const prevData = this.data[categoryId];
    this.data[categoryId] = data;

    return await RequestHandler.handle(
      PropertyReportContainer.plantRepo.updateData(
        this.id ?? 0,
        JSON.stringify(this.data)
      ),
      this.updateDataResponse,
      {
        onFailure: () => (this.data![categoryId!] = prevData)
      }
    );
  }

  public async updateLocation(location: PlanLocation) {
    if (this.exists) {
      return await RequestHandler.handle(
        PropertyReportContainer.plantRepo.updateLocation(
          this.id ?? 0,
          location.toEntity()
        ),
        this.updateLocationResponse,
        {
          onSuccess: () => (this.location = location)
        }
      );
    } else {
      this.location = location;
    }

    return true;
  }

  public updateStructure() {
    return RequestHandler.handle(
      PropertyReportContainer.plantRepo.updateStructure(
        this.id ?? 0,
        this.toEntity()
      ),
      this.updateStructureResponse
    );
  }

  public async uploadImage(imageFile: File) {
    const imageAsString = await FileUtils.toBase64(imageFile);
    const image = new Image();
    image.path = imageAsString;

    return RequestHandler.handle(
      PropertyReportContainer.plantRepo.uploadPlantImage(
        this.id ?? 0,
        imageFile
      ),
      this.imageCollection?.uploadResponse,
      {
        onSuccess: id => {
          image.id = id;
          this.imageCollection?.add(image);
        }
      }
    );
  }

  public async deleteImage(image: Image) {
    return RequestHandler.handle(
      PropertyReportContainer.plantRepo.deletePlantImage(image.id),
      image.deleteResponse,
      {
        onSuccess: () => this.imageCollection?.remove(image)
      }
    );
  }

  public delete() {
    return RequestHandler.handle(
      PropertyReportContainer.plantRepo.deletePlant(this.id ?? 0),
      this.deleteResponse
    );
  }

  public toEntity(): PlantEntity {
    return {
      id: this.id,
      name: this.name,
      bimNumber: this.bimNumber,
      description: this.description,
      data: JSON.stringify(this.data ?? {}),
      types: JSON.stringify(this.types ?? []),
      location: this.location?.toEntity(),
      fieldCategories: this.fieldCategoryCollection?.toEntities(),
      plants: this.plantCollection?.toEntities(),
      checklistEntries: this.checklistEntryCollection?.toEntities(),
      property: this.property?.toEntity(),
      images: this.imageCollection?.toEntities(),
      parts: this.propertyParts?.map(p => p.toEntity()),
      hasChildPlants: this.hasChildPlants,
      childPlants: this.plantCollection?.toEntities(),
      siblingPlants: this.siblingPlantCollection?.toEntities(),
      parentPlants: this.parentPlantCollection?.toEntities(),
      siblingPartId: this.siblingPartId
    };
  }

  public from(entity?: PlantEntity): void {
    this.id = entity?.id;
    this.name = entity?.name;
    this.bimNumber = entity?.bimNumber;
    this.description = entity?.description;
    this.data = entity?.data ? JSON.parse(entity.data) : {};
    this.types = JSON.parse(entity?.types ?? "[]");
    this.location = PlanLocation.from(entity?.location);
    this.plantCollection = PlantCollection.collectFrom(entity?.plants);
    this.fieldCategoryCollection = PlantFieldCategoryCollection.collectFrom(
      entity?.fieldCategories
    );
    this.checklistEntryCollection = PlantChecklistEntryCollection.collectFrom(
      entity?.checklistEntries
    );
    this.property = Property.from(entity?.property);
    this.propertyParts = entity?.parts?.map(p => PropertyPart.from(p));
    this.plantCollection = PlantCollection.collectFrom(entity?.childPlants);
    this.siblingPlantCollection = PlantCollection.collectFrom(
      entity?.siblingPlants
    );
    this.parentPlantCollection = PlantCollection.collectFrom(
      entity?.parentPlants
    );
    this.imageCollection = ImageCollection.collectFrom(entity?.images);
    this.hasPersistedChildPlants = entity?.hasChildPlants;

    this.checklistEntryCollection.plant = this;
    this.siblingPartId = entity?.siblingPartId;
  }

  public static from(entity?: PlantEntity) {
    const plant = new Plant();
    plant.from(entity);
    return plant;
  }

  public static typesAsSelectItems() {
    return PropertySelectionItemLoader.loadPlantTypes();
  }

  private initIfEmpty() {
    if (!this.types) {
      this.types = [];
    }

    if (!this.fieldCategoryCollection) {
      this.fieldCategoryCollection = new PlantFieldCategoryCollection();
    }

    if (!this.checklistEntryCollection) {
      this.checklistEntryCollection = new PlantChecklistEntryCollection();
    }

    if (!this.imageCollection) {
      this.imageCollection = new ImageCollection();
    }
  }

  private set generalData(data: GeneralPlantData) {
    this.name = data.name;
    this.description = data.description;
    this.bimNumber = data.bimNumber;
  }
}
