
import Vue from "vue";
import Component from "vue-class-component";

interface Marker {
  path: string;
  scale: number;
  draggable: boolean;
  x: number;
  y: number;
}

@Component<InteractivePlan>({
  props: {
    path: {
      type: String,
      default: ""
    },
    marker: {
      type: Array,
      default() {
        return [];
      }
    },
    locked: {
      type: Boolean,
      default: false
    },
    confirmable: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    zoom: {
      type: Number,
      default: 0.8
    }
  },
  watch: {
    path() {
      this.initCanvas();
    },
    locked(locked: boolean) {
      this.configCrosshair.forEach(
        (config: any, index: number) => (config.draggable = !locked)
      );
    },
    marker: {
      deep: true,
      handler() {
        this.initCrosshair();
      }
    }
  }
})
export default class InteractivePlan extends Vue {
  private image?: HTMLImageElement = undefined;
  private configKonva = {};
  private configImage = {};
  private configCrosshair: any[] = [];
  private configLayer = {
    x: 100,
    y: 100,
    draggable: true,
    scaleX: 1,
    scaleY: 1,
    preventDefault: true
  };
  private lastDist = 0;
  private tapTime = 0;
  private skipNextTap = false;
  private center!: boolean;
  private marker!: Marker[];

  private get safeZoom() {
    return this.$props.zoom;
  }

  private get stageWidth() {
    return (this.configKonva as any).width || 0;
  }
  private get stageHeight() {
    return (this.configKonva as any).height || 0;
  }

  private crosshairSize(index: number) {
    return (this.$refs as any).marker[index]
      ? (this.$refs as any).marker[index].getNode().size().width
      : 0.0;
  }

  private resizeCanvas() {
    if (!this.$refs.canvas || !this.image) {
      return;
    }
    const width = (this.$refs.canvas as Vue & { $el: any }).$el.offsetWidth;
    const height = window.innerHeight;

    // const ratio =
    //   width < this.image.width
    //     ? width / this.image.width
    //     : this.image.width / width;
    // const height = Math.max(
    //   ratio * this.image.height,
    //   window.innerHeight - 100
    // );

    this.configKonva = {
      width,
      height
    };
    this.configImage = {
      image: this.image
    };
  }

  private tapped(event: any) {
    if (this.$props.locked) {
      return;
    }

    if (this.skipNextTap) {
      this.skipNextTap = false;
    } else if (Date.now() - this.tapTime < 200) {
      this.setFirstMarkerPos(event);
      this.skipNextTap = true;
    }
    this.tapTime = Date.now();
  }

  private setFirstMarkerPos(event: any) {
    const stage = (this.$refs.canvas as any).getNode();
    const layer = (this.$refs.layer as any).getNode();
    const marker = (this.$refs as any).marker[0].getNode();

    const pos = stage.getPointerPosition();
    const layerPos = layer.getPosition();
    const transformedPos = {
      x:
        (pos.x - layerPos.x) / this.configLayer.scaleX -
        this.crosshairSize(0) * 0.4,
      y:
        (pos.y - layerPos.y) / this.configLayer.scaleX -
        this.crosshairSize(0) * 0.4
    };

    marker.position(transformedPos);
    this.handleDragEnd(0);
    stage.draw();
  }

  private zoomPlan(event: any) {
    if (!event.evt.shiftKey) {
      return;
    }

    const stage = (this.$refs.canvas as any).getNode();
    const oldScale = this.configLayer.scaleX;
    const newScale = event.evt.deltaY < 0 ? oldScale * 1.03 : oldScale / 1.03;
    this.zoomToPoint(stage.getPointerPosition(), oldScale, newScale);
  }

  private zoomToPoint(point: any, oldScale: number, newScale: number) {
    const layer = (this.$refs.layer as any).getNode();

    const prevPos = layer.getPosition();
    const mousePointTo = {
      x: point.x / oldScale - prevPos.x / oldScale,
      y: point.y / oldScale - prevPos.y / oldScale
    };
    const newPos = {
      x: -(mousePointTo.x - point.x / newScale) * newScale,
      y: -(mousePointTo.y - point.y / newScale) * newScale
    };
    layer.position(newPos);

    this.configLayer.scaleX = newScale;
    this.configLayer.scaleY = newScale;
    this.$emit("zoom", newScale);
  }

  private getDistance(p1: any, p2: any) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  private resetTouchZoom(event: any) {
    this.lastDist = 0;
    this.configLayer.draggable = true;
  }

  private touchZoom(event: any) {
    if (!event) {
      return;
    }

    const evt = event.evt;

    if (!evt || !evt.touches) {
      return;
    }

    const touch1 = evt.touches[0];
    const touch2 = evt.touches[1];

    if (touch1 && touch2) {
      this.configLayer.draggable = false;

      const dist = this.getDistance(
        {
          x: touch1.clientX,
          y: touch1.clientY
        },
        {
          x: touch2.clientX,
          y: touch2.clientY
        }
      );

      const elementRect = (
        this.$refs.canvas as any
      ).$el.getBoundingClientRect();

      const middle = {
        x:
          touch1.clientX +
          (touch2.clientX - touch1.clientX) * 0.5 -
          elementRect.x,
        y:
          touch1.clientY +
          (touch2.clientY - touch1.clientY) * 0.5 -
          elementRect.y
      };

      if (!this.lastDist) {
        this.lastDist = dist;
      }

      const canvas = this.$refs.canvas as any;
      const oldScale = this.configLayer.scaleX;

      const scale = (oldScale * dist) / this.lastDist;
      this.zoomToPoint(middle, oldScale, scale);

      this.lastDist = dist;
    }
  }

  private handleDragEnd(index: number) {
    const pos = (this.$refs as any).marker[index].getNode().position();
    const x = Math.round(
      pos.x +
        this.crosshairSize(index) * 0.5 * this.configCrosshair[index].scaleX
    );
    const y = Math.round(
      pos.y +
        this.crosshairSize(index) * 0.5 * this.configCrosshair[index].scaleY
    );
    this.$emit("positionChanged", { index, x, y });
  }
  private initCanvas() {
    if (!this.$props.path) {
      this.reset();
      return;
    }
    this.initCrosshair();
    this.loadImage(this.$props.path).then((image: any) => {
      this.image = image;
      this.resizeCanvas();
      if (this.center) {
        // setTimeout(() => {
        this.centerStage();
        // }, 1000);
      }
    });
  }
  private initCrosshair() {
    if (!this.marker) {
      return;
    }

    this.configCrosshair.length = 0;

    this.marker.forEach((marker: any) => {
      this.loadImage(marker.path).then((image: any) => {
        this.configCrosshair.push({
          image,
          scaleX: marker.scale,
          scaleY: marker.scale,
          draggable: !this.$props.locked
        });

        this.$nextTick(() => {
          (this.$refs.marker as any).forEach(
            (markerImg: any, index: number) => {
              markerImg.getNode().position({
                x: this.marker[index].x
                  ? this.marker[index].x -
                    this.crosshairSize(index) * 0.5 * this.marker[index].scale
                  : 0,
                y: this.marker[index].y
                  ? this.marker[index].y -
                    this.crosshairSize(index) * 0.5 * this.marker[index].scale
                  : 0
              });
            }
          );
        });
      });
    });
  }
  private loadImage(path: string) {
    return new Promise((resolve: (value?: HTMLImageElement) => void) => {
      const image = new window.Image();
      image.src = path;
      image.onload = () => {
        resolve(image);
      };
    });
  }
  private reset() {
    this.configKonva = {};
    this.configImage = {};
    this.configCrosshair = [];
  }
  private centerStage() {
    if (!this.marker || this.marker.length === 0) {
      return;
    }

    const marker = this.marker[0];
    const planSize = this.configKonva as any;

    if (!marker.x && !marker.y) {
      marker.x = (this.image?.width ?? 0) / 2;
      marker.y = (this.image?.height ?? 0) / 2;
    }

    this.configLayer.scaleX = this.safeZoom;
    this.configLayer.scaleY = this.safeZoom;
    this.configLayer.x = -marker.x * this.safeZoom + planSize.width / 2;
    this.configLayer.y = -marker.y * this.safeZoom + planSize.height / 2;
  }
  private mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.resizeCanvas);
    });
    this.initCanvas();
  }
  private beforeDestroy() {
    document.removeEventListener("resize", this.resizeCanvas);
  }

  private visibilityChanged(visible: boolean) {
    if (visible) {
      this.initCanvas();
    }
  }

  private emitConfirm() {
    return this.$emit("confirm");
  }
}
