
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { Client } from "@/property/shared/models/Client";
import { FileUtils } from "@/shared/utils/FileUtils";

@Component<DeleteClientButton>({
  components: {}
})
export default class DeleteClientButton extends Vue {
  @Prop({
    default() {
      return new Client();
    }
  })
  protected readonly client!: Client;
  @Prop({ type: Boolean, default: false })
  protected readonly disabled!: boolean;

  protected get tabs() {
    return [
      { label: "Mandantenlogo", key: "clientLogo" },
      { label: "Berichtlogo 1", key: "reportLogo1" },
      { label: "Berichtlogo 2", key: "reportLogo2" }
    ];
  }

  protected async uploadLogo(logo: File[], context: string) {
    if (logo && logo.length === 0) {
      return;
    }

    if (await this.client.uploadLogo(logo[0], context)) {
      //   const logoAsString = await FileUtils.toBase64(logo[0]);
      //   Vue.set(this.client.logos!, context, { path: logoAsString, context });
      this.$forceUpdate();
    }
  }

  protected mounted() {
    this.load();
  }

  protected load() {
    if (!this.client.loadingOrLoaded) {
      this.client.load();
    }
  }
}
