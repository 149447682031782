import {
  Request,
  Textfield,
  SingleFileInput,
  Date
} from "@/forms/ViewModelFormTypes";

export class DocumentDetailViewModel {
  public goBack: boolean = false;
  public documentId: string = "";
  public fileId: number = 0;
  public filePath: string = "";
  public metumId: number = 0;
  public documentToOpen: string = "";
  public createdAt: string = "";
  public savedDocument: number = 0;
  public deletedDocument: number = 0;

  public saveDocumentButtonDisabled: boolean = false;
  public saveDocumentButtonVisible: boolean = false;
  public deleteDocumentButtonVisible: boolean = false;
  public openDocumentButtonVisible: boolean = false;

  public uploadDocument: SingleFileInput = {
    label: "Neues Dokument",
    value: undefined,
    loading: false,
    error: ""
  };
  public name: Textfield = {
    label: "Name",
    value: "",
    loading: false,
    error: ""
  };
  public metaFields: MetaFields = {};

  public loadDocumentRequest: Request = { loading: false, error: "" };
  public loadDocumentMetumRequest: Request = { loading: false, error: "" };
  public saveDocumentRequest: Request = { loading: false, error: "" };
  public deleteDocumentRequest: Request = { loading: false, error: "" };
}

export interface MetaFields {
  [key: string]: MetumField;
}

type MetumField = (Textfield | Date) & { type: string; name: string };
