import { ISettingDetailPresenter } from "../controllers/SettingDetailController";
import {
  SettingDetailViewModel,
  SettingFields,
  SettingListViewModel,
  SettingFieldViewModel,
  SettingValueViewModel
} from "../vms/SettingDetailViewModel";
import { FormResponse } from "@/forms/FormResponse";
import {
  Setting,
  GlobalSettingInput,
  GlobalSettingList,
  SettingInput,
  ClientSettingList,
  ClientSettingInput
} from "@/entities/Setting";
import { DynamicForm } from "@/forms/DynamicForm";
import { FormDefinition, FormFieldType } from "@/forms/Form";
import { NumericalId } from "@/datastructures/NumericalId";
import { Authorizator } from "@/common/interactors/Authorizator";
import { StringUtils } from "@/utils/StringUtils";
import { ArrayUtils } from "@/utils/ArrayUtils";
import { LocalStorage } from "@/storage/LocalStorage";

export class SettingDetailPresenter implements ISettingDetailPresenter {
  public settingForm: DynamicForm = new DynamicForm({}, this);

  public constructor(private vm: SettingDetailViewModel) {}

  public init() {
    if (this.vm.clientId !== "0") {
      this.vm.goBack = !Authorizator.canViewOwnSettings();
      this.vm.saveSettingButtonVisible = Authorizator.canEditOwnSettings();
    } else {
      this.vm.goBack = !Authorizator.canViewSettings();
      this.vm.saveSettingButtonVisible = Authorizator.canEditSettings();
    }
  }

  // Responses
  public set loadSettingResponse(response: FormResponse<Setting>) {
    this.vm.loadSettingRequest = response;

    if (!response.loading && !response.error) {
      const meta = response.data.meta;

      this.vm.settingId = response.data.id.toString();
      this.vm.settingKey = response.data.key;

      this.vm.fields = this.buildFieldStructure(meta.fields);
      this.initFields(
        this.vm.fields,
        meta.fields,
        response.data.entry,
        response.data.default
      );
      this.settingForm = new DynamicForm(
        this.buildFormDefinition(meta.fields),
        this.vm.fields,
        this.settingFormValidated
      );
    }
  }

  public set saveSettingResponse(response: FormResponse<NumericalId>) {
    this.vm.saveSettingRequest = response;

    if (!response.loading && !response.error) {
      this.vm.settingId = response.data.id.toString();
      this.vm.settingSavedDialogVisible = true;
    }
  }

  // States
  public set settingSavedDialogVisible(visible: boolean) {
    this.vm.settingSavedDialogVisible = visible;
  }

  // Methods
  public changeFieldValue(keyChain: Array<string | number>, value: string) {
    const field = this.getField(keyChain);
    field.value = value;
  }

  public reset(key: string) {
    const field = this.vm.fields[key] as SettingFieldViewModel;
    field.value = field.default;
  }

  public addLine(keyChain: string[], values: SettingValueViewModel) {
    const field = this.getField(keyChain);
    values.value.id = {
      key: "id",
      label: "ID",
      value: StringUtils.uuid(),
      default: "",
      error: "",
      type: "string",
      required: true,
      invisible: true
    };
    field.values.push(values);
  }

  public removeLine(keyChain: Array<string | number>, index: number) {
    const field = this.getField(keyChain);
    field.values.splice(index, 1);
  }

  public toggleDeactivated(keyChain: Array<string | number>, index: number) {
    const field = this.getField(keyChain);
    field.values[index].deactivated = !field.values[index].deactivated;
  }

  // Data
  public get globalSetting(): GlobalSettingInput {
    const baseData = this.buildBaseSettingList();

    return {
      key: baseData.key,
      variables: baseData.variables,
      clientId: baseData.clientId,
      settings: this.buildGlobalSettingElements(this.vm.fields)
    };
  }

  public get clientSetting(): ClientSettingInput {
    const baseData = this.buildBaseSettingList();

    return {
      key: baseData.key,
      variables: baseData.variables,
      clientId: baseData.clientId,
      settings: this.buildClientSettingElements(this.vm.fields)
    };
  }

  public set clientId(id: string) {
    this.vm.clientId = id;
    this.vm.parentSettings = id !== "0";
  }

  // Helper
  private buildFieldStructure(
    fields: { [key: string]: any },
    child: boolean = false
  ): SettingFields {
    const fieldStructure: SettingFields = {};

    for (const [key, field] of Object.entries(fields)) {
      if (field.type !== "list") {
        fieldStructure[key] = {
          key,
          label: field.label,
          value: "",
          default: "",
          error: "",
          type: field.type,
          required: field.required,
          items: this.buildSelectionItems(field)
        };
      } else {
        fieldStructure[key] = {
          key,
          label: field.label,
          type: "list",
          values: [],
          fields: this.buildFieldStructure(field.fields, true),
          deactivatable: field.deactivatable,
          extendable: field.extendable
        };
      }
    }

    if (child) {
      fieldStructure.id = {
        key: "id",
        label: "ID",
        value: "",
        default: "",
        error: "",
        type: "string",
        required: true
      };
    }

    return fieldStructure;
  }

  private initFields(
    context: SettingFields,
    metas: { [key: string]: any },
    values: { [key: string]: any },
    defaultValues?: { [key: string]: any }
  ) {
    for (const [key, field] of Object.entries(context)) {
      let value = values[key];
      const defaultValue = defaultValues ? defaultValues[key] : undefined;

      if (!this.isList(field)) {
        const defaultVal = defaultValue || "";
        field.value = value || defaultVal;
        field.default = defaultVal;
        if (key === "id") {
          field.invisible = true;
          field.value = !!field.value ? field.value : StringUtils.uuid();
        }
      } else {
        if (defaultValue) {
          defaultValue.forEach((val: any, index: number) => {
            const id = val.id || "";
            let clientVal = {};

            if (!!value) {
              clientVal = value.find((v: any) => v.id === id);

              if (!!clientVal) {
                value = ArrayUtils.remove(clientVal, value);
              } else {
                clientVal = {};
              }
            }

            const deactivated =
              !!value && (value.includes(index) || value.includes(id));
            const newVal = {
              disabled: true,
              deactivated,
              value: this.buildFieldStructure(field.fields)
            };

            this.initFields(newVal.value, field.fields, clientVal, val);
            field.values.push(newVal);
          });
        }

        if (value) {
          const clientValues = value.filter(
            (val: any) => isNaN(val) && !StringUtils.isString(val)
          );

          clientValues.forEach((val: any) => {
            const newVal = {
              disabled: false,
              deactivated: false,
              value: this.buildFieldStructure(field.fields)
            };

            this.initFields(newVal.value, field.fields, val, {});
            field.values.push(newVal);
          });
        }
      }
    }
  }

  private buildFormDefinition(fields: { [key: string]: any }) {
    const definition: FormDefinition = {};

    for (const [key, field] of Object.entries(fields)) {
      if (field.type !== "list") {
        definition[key] = {
          type: FormFieldType.Text,
          required: field.required
        };
      }
    }

    return definition;
  }

  private buildBaseSettingList(): SettingInput {
    return {
      key: this.vm.settingKey,
      variables: {},
      clientId: NumericalId.fromString(this.vm.clientId)
    };
  }

  private buildGlobalSettingElements(fields: SettingFields): GlobalSettingList {
    const settings: GlobalSettingList = {};

    for (const [key, value] of Object.entries(fields)) {
      if (!this.isList(value)) {
        settings[key] = value.value;
      } else {
        settings[key] = value.values.map(subFields =>
          this.buildGlobalSettingElements(subFields.value)
        );
      }
    }

    return settings;
  }

  private buildClientSettingElements(fields: SettingFields): ClientSettingList {
    const settings: ClientSettingList = {};

    for (const [key, field] of Object.entries(fields)) {
      if (!this.isList(field)) {
        settings[key] = field.value;
      } else {
        const listValues: ClientSettingList[] = [];

        field.values.forEach(
          (subFields: SettingValueViewModel, index: number) => {
            if (subFields.deactivated && subFields.disabled) {
              const idVal = Object.entries(subFields.value).find(
                val => val[0] === "id"
              );
              const id = idVal
                ? (idVal[1] as SettingFieldViewModel).value
                : index;

              listValues.push(id);
            } else {
              listValues.push(this.buildClientSettingElements(subFields.value));
            }
          }
        );

        settings[key] = listValues;
      }
    }

    return settings;
  }

  private isList(list: any): list is SettingListViewModel {
    return !!list.values;
  }

  private getField(path: Array<string | number>) {
    let context: any = this.vm.fields[path[0]];

    for (let i = 1; i < path.length; i = i + 2) {
      context = context.values[path[i]].value[path[i + 1]];
    }

    return context;
  }

  private buildSelectionItems(field: any) {
    if (field.type === "select") {
      const references = field.items.filter((i: any) => !!i.reference);
      const staticItems = field.items.filter((i: any) => !i.reference);
      const allItems = staticItems;

      const storage = new LocalStorage();
      for (const reference of references) {
        const refData = storage.getJson(reference.reference);
        if (!!refData[reference.reference]) {
          const dataList = refData[reference.reference];
          if (dataList && dataList.length > 0) {
            for (const referencedItem of dataList) {
              allItems.push({
                value: referencedItem[reference.value],
                text: referencedItem[reference.text]
              });
            }
          }
        }
      }

      return allItems;
    } else {
      return undefined;
    }
  }

  private settingFormValidated(context: any, valid: boolean) {
    // Validate
  }
}
