import { PlanEntity } from "../../entities/PlanEntity";
import { PlanPool } from "./PlanPool";

export class ManagedPlanPool {
  public constructor(
    private offlinePool: PlanPool,
    private onlinePool: PlanPool
  ) {}

  public async syncWithStore(): Promise<void> {
    await this.offlinePool.syncWithStore();
    await this.onlinePool.syncWithStore();
  }

  public async add(plan: PlanEntity): Promise<void> {
    if (plan.offline) {
      await this.onlinePool.remove(plan);
      await this.offlinePool.add(plan);
    } else {
      await this.offlinePool.remove(plan);
      await this.onlinePool.add(plan);
    }
  }

  public async getById(id: number): Promise<PlanEntity | undefined> {
    return (
      (await this.offlinePool.getById(id)) ??
      (await this.onlinePool.getById(id))
    );
  }

  public contains(plan: PlanEntity): boolean {
    return this.offlinePool.contains(plan) || this.onlinePool.contains(plan);
  }

  public async getAll(): Promise<PlanEntity[]> {
    return [
      ...(await this.offlinePool.getAll()),
      ...(await this.onlinePool.getAll())
    ];
  }
}
