
import Vue from "vue";
import Component from "vue-class-component";

import RequestButton from "@/components/basic/RequestButton.vue";

@Component<ConfirmDialog>({
  components: {
    RequestButton
  },
  props: {
    title: {
      type: String,
      default: "Titel"
    },
    text: {
      type: String,
      default: ""
    },
    confirmText: {
      type: String,
      default: "OK"
    },
    denyText: {
      type: String,
      default: "Abbrechen"
    },
    value: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 300
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ""
    },
    persistent: {
      type: Boolean,
      default: true
    }
  }
})
export default class ConfirmDialog extends Vue {
  protected confirm() {
    this.$emit("confirm");
    this.emitInput(true);
  }

  protected deny() {
    this.$emit("deny");
    this.emitInput(false);
  }

  protected emitInput(val: boolean) {
    this.$emit("input", val);
  }
}
