import { Authorizator } from "@/common/interactors/Authorizator";
import { InspectionSettingsContainer } from "@/inspection/shared/settings/InspectionSettingsContainer";
import { PropertyModel } from "@/property/property/models/PropertyModel";
import { Plant } from "@/property/shared/dtos/Plant";
import { Property } from "@/property/shared/dtos/Property";
import { DocumentTypes } from "@/property/shared/settings/DocumentSettings";
import { PropertySettingsContainer } from "@/property/shared/settings/PropertySettingsContainer";
import { PlantModel } from "@/property/structure/models/PlantModel";
import { Dictionary } from "@/shared/datastructures/Dictionary";

export interface MenuItem {
  text: string;
  visible: boolean;
  icon?: string;
  routeName?: string;
  items?: MenuItem[];
  params?: object;
  query?: object;
}

export class SidebarModel {
  private selectedProperties: PropertyModel[] = [];
  private basePlants: PlantModel[] = [];

  public get menuItems(): MenuItem[] {
    return [
      this.mapItems,
      this.addressItems,
      this.propertyItems,
      this.plantItems,
      this.ticketItems,
      this.assignmentItems,
      this.documentItems
    ];
  }

  private get mapItems(): MenuItem {
    return {
      text: "Karte",
      visible: true,
      icon: "map",
      routeName: "map"
    };
  }

  private get addressItems(): MenuItem {
    return {
      text: "Adressbuch",
      visible: Authorizator.canListPeople(),
      icon: "book",
      items: [{ text: "Adressliste", visible: true, routeName: "people" }]
    };
  }

  private get propertyItems(): MenuItem {
    return {
      text: "Gebäudebuch",
      visible: Authorizator.canListProperties(),
      icon: "home",
      items: [
        {
          text: "Gebäudeliste",
          visible: true,
          routeName: "properties"
        },
        {
          text: "Pläne",
          visible:
            this.hasSingleSelectedProperty && Authorizator.canViewPlans(),
          routeName: "property-plans",
          params: { propertyId: this.selectedPropertyId }
        },
        {
          text: "Struktur",
          visible:
            this.hasSingleSelectedProperty && Authorizator.canEditProperties(),
          routeName: "property-structure",
          params: { propertyId: this.selectedPropertyId }
        },
        this.initDocumentMenuItems(
          PropertySettingsContainer.propertyDocuments.allTypes(),
          Authorizator.canListDocuments() && this.hasSingleSelectedProperty,
          undefined,
          "property",
          this.selectedPropertyId
        )
      ]
    };
  }

  private get plantItems(): MenuItem {
    return {
      text: "Anlagenbuch",
      visible:
        this.hasSingleSelectedProperty && Authorizator.canEditProperties(),
      icon: "category",
      items: [
        {
          text: "Anlagenliste",
          visible:
            this.hasSingleSelectedProperty && Authorizator.canEditProperties(),
          routeName: "plants"
        }
      ]
    };
  }

  private get ticketItems(): MenuItem {
    return {
      text: "Tickets",
      visible: Authorizator.canListTickets(),
      icon: "local_offer",
      items: [{ text: "Ticketliste", visible: true, routeName: "tickets" }]
    };
  }

  private get assignmentItems(): MenuItem {
    const baseItems = this.hasBasePlants
      ? this.basePlants
          .filter(
            p =>
              !!InspectionSettingsContainer.checklists.getChecklist(p.checklist)
          )
          .map(p => {
            const name = InspectionSettingsContainer.checklists.getChecklist(
              p.checklist
            )!.name;

            return {
              text: name,
              visible: true,
              routeName: "assignments",
              params: { plantType: p.type },
              query: { name }
            };
          })
      : [{ text: "Lädt...", visible: true }];

    return {
      text: "Begehungen",
      visible: Authorizator.canListAssignments() && this.hasSelectedProperties,
      icon: "assignment",
      items: [
        ...baseItems,
        {
          text: "Sonstige",
          visible:
            this.hasSingleSelectedProperty &&
            Authorizator.canCreateAssignments(),
          routeName: "property-assignments",
          params: { propertyId: this.selectedPropertyId },
          query: { name: "Sonstige" }
        }
      ]
    };
  }

  private get documentItems() {
    return this.initDocumentMenuItems(
      PropertySettingsContainer.generalDocuments.allTypes(),
      Authorizator.canViewOwnClient() && Authorizator.canListDocuments(),
      "file_copy"
    );
  }

  public init(selectedProperties: Property[], basePlants: Dictionary<Plant[]>) {
    this.selectedProperties = selectedProperties.map(p =>
      PropertyModel.from(p)
    );

    this.basePlants = [];
    for (const plantKey of Object.keys(basePlants)) {
      const plants = basePlants[plantKey];
      this.basePlants.push(PlantModel.from(plants[0]));
    }
  }

  private initDocumentMenuItems(
    documentMeta: DocumentTypes,
    visible: boolean,
    icon?: string,
    subjectType?: string,
    subjectId?: number
  ): MenuItem {
    if (!documentMeta || documentMeta.length === 0) {
      return {
        text: "Dokumente",
        visible,
        icon,
        items: [
          {
            text: "Bitte neu laden...",
            visible: true
          }
        ]
      };
    }

    return {
      text: "Dokumente",
      icon,
      visible,
      items: documentMeta.map((metum: any) => ({
        text: metum.label,
        visible: true,
        routeName: "documents",
        params: { metumId: metum.id, subjectType, subjectId }
      }))
    };
  }

  private get hasSelectedProperties() {
    return this.selectedProperties && this.selectedProperties.length > 0;
  }

  private get hasSingleSelectedProperty() {
    return this.selectedProperties && this.selectedProperties.length === 1;
  }

  private get hasMultipleSelectedProperties() {
    return this.selectedProperties && this.selectedProperties.length > 1;
  }

  private get selectedPropertyId() {
    return this.hasSingleSelectedProperty ? this.selectedProperties[0].id : 0;
  }

  private get hasBasePlants() {
    return this.basePlants && this.basePlants.length > 0;
  }
}
