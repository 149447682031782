import {
  DocumentDetailViewModel,
  MetaFields
} from "../vms/DocumentDetailViewModel";
import { IDocumentDetailPresenter } from "../controllers/DocumentDetailController";
import { FormResponse } from "@/forms/FormResponse";
import {
  Document,
  DocumentMetumDescription
} from "../services/DocumentService";
import { DocumentForm } from "../forms/DocumentForm";
import { DateUtils } from "@/utils/DateUtils";
import { DynamicForm } from "@/forms/DynamicForm";
import { FormDefinition, FormFieldType } from "@/forms/Form";
import { Authorizator } from "@/common/interactors/Authorizator";

export class DocumentDetailPresenter implements IDocumentDetailPresenter {
  public documentForm: DocumentForm;
  public metaForm: DynamicForm;
  private attributes: DocumentAttribute = {};

  public constructor(private vm: DocumentDetailViewModel) {
    this.documentForm = new DocumentForm(this.vm, this.documentFormValidated);
    this.metaForm = new DynamicForm({}, this.vm.metaFields);

    this.documentForm.init();
  }

  public init() {
    this.vm.openDocumentButtonVisible = Authorizator.canViewDocuments();
    this.vm.saveDocumentButtonVisible = Authorizator.canEditDocuments();
    this.vm.deleteDocumentButtonVisible = Authorizator.canDeleteDocuments();

    this.vm.goBack = !Authorizator.canViewDocuments();
  }

  public set documentId(id: string) {
    this.vm.documentId = id;
  }

  public set documentToOpen(link: string) {
    this.vm.documentToOpen = link;
  }

  public set loadDocumentResponse(response: FormResponse<Document>) {
    this.vm.loadDocumentRequest = response;

    if (response.success) {
      this.document = response.data;
    }
  }

  public set loadDocumentMetumResponse(
    response: FormResponse<DocumentMetumDescription>
  ) {
    this.vm.loadDocumentMetumRequest = response;

    if (response.success) {
      this.vm.metaFields = this.initMetaFields(response.data.fields);
      this.metaForm = new DynamicForm(
        this.initMetaForm(response.data.fields),
        this.vm.metaFields,
        this.documentFormValidated,
        this.vm
      );
      this.metaForm.init();
      this.initMetaValues();
    }
  }

  public set saveDocumentResponse(response: FormResponse<number>) {
    this.vm.saveDocumentRequest = response;

    if (response.success) {
      this.vm.savedDocument = response.data;
    }
  }

  public set deleteDocumentResponse(response: FormResponse<number>) {
    this.vm.deleteDocumentRequest = response;

    if (response.success) {
      this.vm.deletedDocument = response.data;
    }
  }

  public get document(): Document {
    return {
      id: parseInt(this.vm.documentId, 10),
      name: this.vm.name.value,
      metumId: this.vm.metumId,
      fileId: this.vm.fileId,
      filePath: this.vm.filePath,
      attributes: this.buildAttributes(),
      createdAt: new Date()
    };
  }
  public set document(doc: Document) {
    this.vm.fileId = doc.fileId;
    this.vm.filePath = doc.filePath;
    this.vm.metumId = doc.metumId;
    this.vm.createdAt = "Hinzugefügt am " + DateUtils.format(doc.createdAt);

    this.documentForm.setFieldValue("name", doc.name);
    this.attributes = JSON.parse(doc.attributes);
  }

  private documentFormValidated(context: any, valid: boolean) {
    context.saveDocumentButtonDisabled = !valid;
  }

  private initMetaFields(rawFields: string) {
    const fields = JSON.parse(rawFields);
    const metaFields: MetaFields = {};

    for (const entry of Object.entries(fields)) {
      const [name, field]: [string, any] = entry;

      metaFields[name] = {
        label: field.label,
        value: "",
        name,
        loading: false,
        error: "",
        type: field.type
      };
    }

    return metaFields;
  }

  private initMetaForm(rawFields: string) {
    const fields = JSON.parse(rawFields);
    const definition: FormDefinition = {};

    for (const entry of Object.entries(fields)) {
      const [name, field]: [string, any] = entry;

      definition[name] = {
        type: this.getFormType(field.type),
        required: true
      };
    }

    return definition;
  }

  private initMetaValues() {
    for (const attribute of Object.entries(this.attributes)) {
      const [name, value]: [string, string] = attribute;
      this.metaForm.setFieldValue(name, value);
    }
  }

  private buildAttributes() {
    const attributes: DocumentAttribute = {};

    for (const entry of Object.entries(this.vm.metaFields)) {
      const [name, field]: [string, any] = entry;
      attributes[name] = field.value;
    }

    return JSON.stringify(attributes);
  }

  private getFormType(type: string) {
    switch (type) {
      case "date":
        return FormFieldType.Date;
      case "number":
        return FormFieldType.Number;

      default:
        return FormFieldType.Text;
    }
  }
}

interface DocumentAttribute {
  [key: string]: string;
}
