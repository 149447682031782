
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component<Chip>({})
export default class Chip extends Vue {
  @Prop({ type: Boolean, default: true })
  protected readonly outlined!: boolean;
  @Prop({ type: String, default: "primary" })
  protected readonly color!: string;
  @Prop({ type: String, default: "primary" })
  protected readonly loadingColor!: string;
  @Prop({ type: String, default: "small" })
  protected readonly size!: string;
  @Prop({ type: Boolean, default: false })
  protected readonly close!: boolean;
  @Prop({ type: String, default: undefined })
  protected readonly closeLabel?: string;
  @Prop({ type: Boolean, default: false })
  protected readonly loading!: boolean;
  @Prop({ type: String, default: "" })
  protected readonly error!: string;

  protected get hasError() {
    return !!this.error;
  }

  protected get closeable() {
    return !this.loading && this.close;
  }

  protected get chipColor() {
    return this.error ? "error" : this.color;
  }

  protected get loadingSize() {
    if (this.isXLarge) {
      return 32;
    } else if (this.isLarge) {
      return 25;
    } else if (this.isMedium) {
      return 20;
    } else if (this.isSmall) {
      return 16;
    } else if (this.isXSmall) {
      return 12;
    }
  }

  protected get isXLarge() {
    return this.size === "x-large";
  }

  protected get isLarge() {
    return this.size === "large";
  }

  protected get isMedium() {
    return !this.isXLarge && !this.isLarge && !this.isSmall && !this.isXSmall;
  }

  protected get isSmall() {
    return this.size === "small";
  }

  protected get isXSmall() {
    return this.size === "x-small";
  }

  protected emitClick() {
    this.$emit("click");
  }

  protected emitCloseClick() {
    this.$emit("click:close");
  }
}
