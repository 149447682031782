import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { storageKeys } from "@/data/storageKeys";
import { ObjectUtils } from "@/utils/ObjectUtils";
import { priorities } from "@/data/settings";
import { Client } from "@/property/shared/models/Client";
import { LocalStorage } from "./LocalStorage";
import { Employee } from "@/property/shared/models/Employee";
import { StringUtils } from "@/shared/utils/StringUtils";

const defaultStorage = new LocalStorage();

export class UserSettings implements IUserSettings {
  public static loggedOut() {
    return !UserSettings.loggedIn();
  }
  public static loggedIn() {
    return !!UserSettings.getUserId();
  }
  public static getUser() {
    return Employee.from({
      id: UserSettings.getNumericUserId(),
      guestId: UserSettings.getGuestId(),
      person: {
        firstname: UserSettings.getGuestName()
      }
    });
  }

  public static getClient() {
    return new Client({ id: this.getNumericClientId() });
  }
  public static getClientId() {
    return defaultStorage.get(storageKeys.clientId);
  }
  public static getNumericClientId() {
    return parseInt(this.getClientId(), 10);
  }
  public static getOriginalClientId() {
    return defaultStorage.get(storageKeys.originalClientId);
  }
  public static getNumericOriginalClientId() {
    return parseInt(this.getOriginalClientId(), 10);
  }
  public static getSelectedProperty() {
    return defaultStorage.get(storageKeys.selectedProperty);
  }
  public static getUserId() {
    return defaultStorage.get(storageKeys.userId);
  }
  public static getOriginalUserId() {
    return defaultStorage.get(storageKeys.originalUserId);
  }
  public static getGuestId() {
    let guestId = defaultStorage.get(storageKeys.guestId);

    if (!guestId) {
      guestId = StringUtils.uuid();
      defaultStorage.set(storageKeys.guestId, guestId);
    }

    return guestId;
  }
  public static getGuestName() {
    return defaultStorage.get(storageKeys.guestName);
  }
  public static setGuestName(name: string) {
    defaultStorage.set(storageKeys.guestName, name);
  }

  public static getNumericUserId() {
    return parseInt(this.getUserId(), 10);
  }

  public static getNumericOriginalUserId() {
    return parseInt(this.getOriginalUserId(), 10);
  }

  public static impersonate(clientId: string, userId: string) {
    const storage = defaultStorage;
    const originalUser = UserSettings.getUserId();
    const originalClient = UserSettings.getClientId();
    storage.set(storageKeys.originalUserId, originalUser);
    storage.set(storageKeys.originalClientId, originalClient);
    storage.set(storageKeys.userId, userId);
    storage.set(storageKeys.clientId, clientId);
    this.deselectProperty();
  }

  public static depersonate() {
    const storage = defaultStorage;
    const originalUser = storage.get(storageKeys.originalUserId);
    const originalClient = storage.get(storageKeys.originalClientId);
    storage.set(storageKeys.userId, originalUser);
    storage.set(storageKeys.clientId, originalClient);
    storage.remove(storageKeys.originalUserId);
    storage.remove(storageKeys.originalClientId);
    this.deselectProperty();
  }

  public static isImpersonated() {
    const storage = defaultStorage;
    return (
      (storage.has(storageKeys.originalClientId) ||
        storage.has(storageKeys.originalUserId)) &&
      UserSettings.getUserId() !== UserSettings.getOriginalUserId()
    );
  }

  public static deselectProperty() {
    const storage = defaultStorage;
    storage.remove(storageKeys.selectedPropertyData);
    storage.remove(storageKeys.selectedProperty);
  }

  public static getRolePrio() {
    return defaultStorage.get(storageKeys.rolePrio);
  }
  public static getPriorities() {
    return new UserSettings(defaultStorage).priorities;
  }
  public static getRelationships() {
    return new UserSettings(defaultStorage).relationships;
  }

  public static getTicketType(type: string) {
    return new UserSettings(defaultStorage).getTicketType(type);
  }

  public static getChecklist(key: string) {
    return new UserSettings(defaultStorage).getChecklist(key);
  }

  public static getReportContent() {
    return new UserSettings(defaultStorage).reportContent;
  }

  public static getProperties() {
    return new UserSettings(defaultStorage).properties;
  }

  public static setGuestPermissions(permissions: string) {
    new UserSettings(defaultStorage).guestPermissions = permissions;
  }
  public static getGuestPermissions() {
    return new UserSettings(defaultStorage).guestPermissions;
  }

  public constructor(private storage: SimpleStringStorage) {}

  public get clientId(): string {
    return this.storage.get(storageKeys.clientId);
  }

  public set lastPlanId(id: LastPlanId) {
    this.storage.set(storageKeys.lastTicketPlan, JSON.stringify(id));
  }
  public get lastPlanId(): LastPlanId {
    return this.getDataOrEmptyObject(storageKeys.lastTicketPlan);
  }

  public set guestPermissions(permissions: string) {
    this.storage.set(storageKeys.guestPermissions, permissions);
  }
  public get guestPermissions(): string {
    return this.storage.get(storageKeys.guestPermissions);
  }

  public get reportContent(): any {
    return this.getDataOrEmptyObject(storageKeys.reportContent);
  }

  public get ticketTemplates(): TicketTemplates | {} {
    return this.getDataOrEmptyObject(storageKeys.ticketTemplates);
  }

  public get properties(): PropertySelection | {} {
    return this.getDataOrEmptyObject(storageKeys.properties);
  }

  public get relationships(): Relationships {
    return this.getDataOrEmptyObject(storageKeys.relationships, true);
  }

  public get priorities(): Priorities {
    const prios = this.getDataOrEmptyObject(storageKeys.priorities);

    if (ObjectUtils.isEmpty(prios)) {
      return Object.values(priorities).map(prio => ({
        id: prio.value,
        label: prio.text,
        color: prio.color,
        icon: prio.icon
      }));
    } else {
      return Object.entries(prios).map((prio: any) => {
        const [key, name]: [string, string] = prio;

        return {
          id: key,
          label: name,
          icon: priorities[key].icon,
          color: priorities[key].color
        };
      });
    }
  }

  public get ticketTypes(): TicketTypes {
    const types = this.getDataOrEmptyObject(storageKeys.ticketTypes);
    const noType: TicketType = {
      key: "no_ticket_type",
      name: "Keine Ticketart",
      fields: []
    };
    const allTypes = [noType];

    if (!ObjectUtils.isEmpty(types)) {
      for (const type of types.ticket_types) {
        type.fields.forEach((f: any) => (f.key = f.key || f.label));
        allTypes.push(type);
      }
    }
    return allTypes;
  }

  public getTicketType(type: string) {
    const types = this.ticketTypes;
    return types.find(t => t.key === type);
  }

  public get checklists(): Checklists {
    const checklists = this.getDataOrEmptyObject(storageKeys.checklists);

    if (!ObjectUtils.isEmpty(checklists)) {
      return checklists.checklists;
    }
    return [];
  }

  public getChecklist(key: string) {
    return this.checklists.find(c => c.key === key);
  }

  private getDataOrEmptyObject(
    key: string,
    returnArrayIfEmpty: boolean = false
  ) {
    if (this.storage.has(key)) {
      return JSON.parse(this.storage.get(key));
    } else {
      return returnArrayIfEmpty ? [] : {};
    }
  }
}

export interface IUserSettings {
  clientId: string;
  reportContent: any;
  ticketTemplates: any;
  properties: any;
  priorities: any;
  ticketTypes: any;
  lastPlanId: LastPlanId;
}

export interface TicketTemplates {
  templates: TicketTemplate[];
}

export interface TicketTemplate {
  summary: string;
  title: string;
  description: string;
  action: string;
}

export interface LastPlanId {
  planId: string;
  propertyId: string;
  x: number;
  y: number;
  zoom: number;
}

export type PropertySelection = PropertySelectionEntry[];

export interface PropertySelectionEntry {
  id: string;
  label: string;
}

export type Priorities = Priority[];

interface Priority {
  id: string;
  label: string;
  icon: string;
  color: string;
}

export type TicketTypes = TicketType[];

interface TicketType {
  key: string;
  name: string;
  fields: TicketTypeField[];
}

export type Checklists = Checklist[];

interface Checklist {
  key: string;
  name: string;
}

export type TicketTypeFields = TicketTypeField[];

export interface TicketTypeField {
  key: string;
  label: string;
  type: string;
  readonly: boolean;
  options: TicketTypeFieldOption[];
}

export interface TicketTypeFieldOption {
  label: string;
}

export interface Relationships {
  relationships: Relationship[];
}

interface Relationship {
  id: string;
  name: string;
  roles: RelationshipRole[];
}

interface RelationshipRole {
  id: string;
  name: string;
}
