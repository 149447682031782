import { PropertyPartTypeSettings } from "@/property/shared/settings/PropertyPartTypeSettings";
import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";

export interface PropertyPartFormData {
  type: string;
  name: string;
}

export class PropertyPartForm extends Form<PropertyPartFormData> {
  protected definition = {
    type: {
      label: "Gebäudeteil-Typ",
      required: true,
      type: FormFieldType.Selection,
      itemDefinition: {
        items: () => new PropertyPartTypeSettings().allTypes(),
        valueKey: "name",
        textKey: "name"
      }
    },
    name: {
      label: "Name",
      required: true,
      type: FormFieldType.Text
    }
  };
}
