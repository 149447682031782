import { Assignment } from "@/inspection/shared/dtos/Assignment";
import { InspectionRepoContainer } from "@/inspection/shared/repositories/InspectionRepoContainer";
import { Paginated } from "@/shared/datastructures/Paginated";
import { TableHeader } from "@/shared/datastructures/TableHeader";
import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { AssignmentModel } from "./AssignmentModel";

export const tableHeaders: TableHeader[] = [
  {
    text: "Information",
    value: "information",
    visibleOnSize: ["xl", "lg", "md", "sm"]
  },
  { text: "Info", value: "information", width: "80%", visibleOnSize: ["xs"] },
  { text: "Datum", value: "date", visibleOnSize: ["xl", "lg"] },
  {
    text: "Status",
    value: "state",
    align: "center",
    visibleOnSize: ["xl", "lg", "md", "sm"]
  },
  { text: "Aktionen", value: "actions", align: "center" }
];

interface AssignmentListOptions {
  page?: number;
  plantType?: string;
  propertyIds?: number[];
  search?: string;
}

export class AssignmentListModel extends RequestModel<Paginated<Assignment>> {
  public assignments: AssignmentModel[] = [];
  public totalAssignments = 20;
  public currentPage = 1;

  public load(options?: AssignmentListOptions) {
    this.currentPage = options?.page ?? 1;

    RequestHandler.handleModel(
      () =>
        options?.plantType
          ? InspectionRepoContainer.assignmentRepo.getBaseAssignmentByType(
              options?.page ?? 1,
              options?.plantType,
              options?.propertyIds ?? [],
              options?.search
            )
          : InspectionRepoContainer.assignmentRepo.getPropertyAssignments(
              options?.page ?? 1,
              options?.propertyIds ?? [],
              options?.search
            ),
      this
    );
  }

  public from(data: Paginated<Assignment>): void {
    this.assignments = data.items.map(a => AssignmentModel.from(a));
    this.totalAssignments = data.totalCount;
  }
}
