
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import App from "@/App.vue";
import ChecklistEntryTicketList from "./ChecklistEntryTicketList.vue";
import ChecklistEntryStateSelect from "./ChecklistEntryStateSelect.vue";

import { ChecklistEntryCollection } from "@/inspection/shared/models/ChecklistEntryCollection";
import { ChecklistEntry } from "@/inspection/shared/models/ChecklistEntry";
import { Dictionary } from "@/datastructures/Dictionary";

const preparationStateColors: Dictionary<string> = {
  prove: "accent",
  irrelevant: "irrelevant",
  inavailable: "inavailable",
  mixed: "grey lighten-5"
};

const inspectionStateColors: Dictionary<string> = {
  clear: "deep-purple lighten-3",
  proven: "accent",
  maintenance: "ternary",
  inspection: "primary",
  mixed: "grey lighten-5",
  new: "#D2E8C6",
  like_new: "#FAF49D",
  signs_of_usage: "#FDCD9B",
  damaged: "#F49AC2"
};

@Component<ChecklistEntryList>({
  name: "ChecklistEntryList",
  components: {
    ChecklistEntryTicketList,
    ChecklistEntryStateSelect
  }
})
export default class ChecklistEntryList extends Vue {
  @Prop({ type: Object, default: () => new ChecklistEntryCollection() })
  protected readonly entries!: ChecklistEntryCollection;
  @Prop() protected readonly checklistType?: string;

  protected openedEntry: number | null = null;
  protected openedTickets: number | null = null;

  protected get displayButtons() {
    return this.isMobile;
  }

  protected get isMobile() {
    return App.instance?.isMobile ?? false;
  }

  public close() {
    this.openedEntry = null;
    this.openedTickets = null;
  }

  protected entryOpened(entry: ChecklistEntry, index: number | null) {
    this.openedEntry = index;

    if (!entry.success) {
      this.loadEntry(entry);
    }
  }

  protected loadEntry(entry: ChecklistEntry) {
    entry.load();
  }

  protected getPreparationStateColor(entry: ChecklistEntry) {
    const prepState = entry.preparationState;

    if (prepState === "prove" || !prepState) {
      return this.getInspectionStateColor(entry);
    } else {
      return preparationStateColors[prepState];
    }
  }

  protected getInspectionStateColor(entry: ChecklistEntry) {
    const inspectionState = entry.state;

    return inspectionStateColors[inspectionState];
  }

  protected getDoneIcon(entry: ChecklistEntry) {
    return entry.done ? "done" : "close";
  }

  protected getDoneIconColor(entry: ChecklistEntry) {
    return entry.done ? "accent" : "grey";
  }
}
