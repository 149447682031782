
import Vue from "vue";
import Component from "vue-class-component";

import Loading from "@/components/basic/Loading.vue";
import ErrorMessage from "@/components/basic/ErrorMessage.vue";

import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import Checkbox from "@/components/form/Checkbox.vue";

import { ReportSettingsModel } from "../../models/ReportSettingsModel";

@Component<ReportSettings>({
  components: {
    Loading,
    ErrorMessage,
    Grid,
    Row,
    Column,
    Checkbox
  },
  props: {
    assignmentId: {
      type: String,
      default: "0"
    },
    reportType: {
      type: String,
      default: ""
    },
    reportId: {
      type: String,
      default: "0"
    }
  }
})
export default class ReportSettings extends Vue {
  private model = new ReportSettingsModel();
  private assignmentId!: string;
  private reportType!: string;
  private reportId!: string;

  private mounted() {
    this.load();
  }

  private load() {
    this.model.load(this.assignmentId, this.reportType, this.reportId);
  }
}
