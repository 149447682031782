import { ServerResponse } from "@/shared/datastructures/Response";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import {
  ChecklistEntryEntity,
  ChecklistInspectionState,
  ChecklistPreparationState
} from "../entities/ChecklistEntity";
import { InspectionRepoContainer } from "../repositories/InspectionRepoContainer";
import {
  ChecklistEntryCollection,
  ChecklistEntrySort
} from "./ChecklistEntryCollection";
import { Ticket } from "./Ticket";
import { Ticketable, TicketableType } from "./Ticketable";
import { TicketCollection } from "./TicketCollection";

export class ChecklistEntry extends Ticketable<ChecklistEntryEntity> {
  public id?: number = 0;
  public name?: string = "";
  public description?: string = "";
  public checklistEntryTemplateId?: string = "";
  public plantId?: number = 0;
  public ticketCollection?: TicketCollection = new TicketCollection();
  public entryCollection?: ChecklistEntryCollection = new ChecklistEntryCollection();

  public setPreparationStateResponse = new ServerResponse<number>();
  public setStateResponse = new ServerResponse<number>();

  private preparationStateSaved?: ChecklistPreparationState;
  private stateSaved?: ChecklistInspectionState;
  private hasEntriesSaved?: boolean = false;
  private hasTicketsSaved?: boolean = false;

  private sort = ChecklistEntrySort.None;

  public get exists() {
    return !!this.id;
  }

  public get label(): string {
    return this.name ?? "";
  }

  public get ticketableType(): TicketableType {
    return TicketableType.ChecklistEntry;
  }

  public get toProve() {
    return (
      this.preparationState === "prove" || this.preparationState === "mixed"
    );
  }

  public get hasMixedPreparationState() {
    return this.preparationState === "mixed";
  }

  public get hasMixedState() {
    return this.state === "mixed";
  }

  public get done() {
    return (
      this.preparationState !== "prove" ||
      (this.state !== "clear" && this.state !== "mixed")
    );
  }

  public get preparationState(): ChecklistPreparationState | undefined {
    return this.entryCollection?.preparationState || this.preparationStateSaved;
  }

  public get state(): ChecklistInspectionState {
    return this.entryCollection?.state || this.stateSaved || "clear";
  }

  public get hasEntries() {
    return this.entryCollection?.notEmpty || this.hasEntriesSaved;
  }

  public get hasTicketsInChildren(): boolean {
    return (
      (this.entryCollection?.hasTickets ?? false) ||
      this.hasTickets ||
      (this.hasTicketsSaved ?? false)
    );
  }

  public get hasTickets(): boolean {
    return this.ticketCollection?.notEmpty ?? false;
  }

  public get entries() {
    return this.entryCollection?.entries ?? [];
  }

  public get tickets() {
    return this.ticketCollection?.tickets ?? [];
  }

  public get sorted() {
    return this.sort !== ChecklistEntrySort.None;
  }

  public addTicket(ticket: Ticket) {
    this.ticketCollection?.add(ticket);
  }

  public sortAlphabetically() {
    this.sort = ChecklistEntrySort.Alphabetically;
    this.entryCollection?.sortAlphabetically();
  }

  public unsort() {
    this.sort = ChecklistEntrySort.None;
    this.entryCollection?.unsort();
  }

  public load() {
    return RequestHandler.handleModel(
      () =>
        InspectionRepoContainer.checklistRepo.getChecklistEntry(this.id ?? 0),
      this
    );
  }

  public setPreparationState(state: ChecklistPreparationState) {
    return RequestHandler.handle(
      InspectionRepoContainer.checklistRepo.setPreparationState(
        this.id ?? 0,
        state
      ),
      this.setPreparationStateResponse,
      {
        onSuccess: () => this.updatePreparationState(state)
      }
    );
  }

  public setState(state: ChecklistInspectionState) {
    if (state === "clear") {
      state = "";
    }

    return RequestHandler.handle(
      InspectionRepoContainer.checklistRepo.setInspectionState(
        this.id ?? 0,
        state
      ),
      this.setStateResponse,
      {
        onSuccess: () => this.updateState(state)
      }
    );
  }

  public toEntity(): ChecklistEntryEntity {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      preparationState: this.preparationStateSaved,
      state: this.stateSaved,
      checklistEntryTemplateId: this.checklistEntryTemplateId,
      plantId: this.plantId,
      hasTickets: this.hasTicketsSaved,
      hasEntries: this.hasEntriesSaved,
      tickets: this.ticketCollection?.toEntities(),
      entries: this.entryCollection?.toEntities()
    };
  }

  public from(entity?: ChecklistEntryEntity) {
    this.id = entity?.id;
    this.name = entity?.name;
    this.description = entity?.description;
    this.preparationStateSaved = entity?.preparationState;
    this.stateSaved = entity?.state;
    this.checklistEntryTemplateId = entity?.checklistEntryTemplateId;
    this.plantId = entity?.plantId;
    this.hasTicketsSaved = entity?.hasTickets;
    this.hasEntriesSaved = entity?.hasEntries;
    this.ticketCollection = TicketCollection.from(entity?.tickets);
    this.entryCollection = ChecklistEntryCollection.from(entity?.entries);

    this.sorted
      ? this.entryCollection?.sortAlphabetically()
      : this.entryCollection?.unsort();
  }

  public static from(entity?: ChecklistEntryEntity) {
    const entry = new ChecklistEntry();
    entry.from(entity);
    return entry;
  }

  private updatePreparationState(state: ChecklistPreparationState) {
    this.preparationStateSaved = state;
    this.entries.forEach(e => e.updatePreparationState(state));
  }

  private updateState(state: ChecklistInspectionState) {
    this.stateSaved = state;
    this.entries.forEach(e => e.updateState(state));
  }
}
