import { EnvironmentUtils } from "./EnvironmentUtils";

export class FileUtils {
  public static generateUniqueName(file: File) {
    const uniqueNumber = file.lastModified + file.size;
    return `${uniqueNumber}_${file.name}`;
  }

  public static toBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  public static async fromUrl(url: string, filename: string, mimeType: string) {
    const response = await fetch(url, {
      mode: EnvironmentUtils.isLocalEnv() ? "no-cors" : undefined
    });
    const buffer = await response.arrayBuffer();

    return new File([buffer], filename, { type: mimeType });
  }
}
