import { Form, FormFieldType } from "../Form";

export class PlanPositionForm extends Form {
  protected definition = {
    name: { required: true, type: FormFieldType.Text },
    offline: { required: false, type: FormFieldType.Checkbox },
    realAX: { required: true, type: FormFieldType.Number },
    realAY: { required: true, type: FormFieldType.Number },
    realBX: { required: true, type: FormFieldType.Number },
    realBY: { required: true, type: FormFieldType.Number }
  };
}
