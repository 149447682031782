import { Checklist } from "./Checklist";
import { Report } from "./Report";
import { Plant } from "./Plant";

export class Assignment {
  public number: string = "";
  public date: Date = new Date();
  public description: string = "";
  public plant: Plant = new Plant(0);
  public reports: Report[] = [];

  private mChecklist?: Checklist;

  public constructor(public id: number) {}

  public get checklist() {
    this.mChecklist = this.mChecklist || new Checklist(0);
    return this.mChecklist;
  }

  public set checklist(newChecklist: Checklist) {
    this.mChecklist = newChecklist;
  }
}
