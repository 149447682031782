import { DateUtils } from "../utils/DateUtils";
import { Dictionary } from "./Dictionary";
import { Duration } from "./Duration";

export interface CacheOptions {
  duration?: Duration;
  key?: unknown;
}

export interface CachedObject<T> {
  obj: T;
  expiredAt: number;
}

export class InMemoryCache<T> {
  private cachedObjects: Dictionary<CachedObject<T>> = {};

  public hasCache(key?: unknown) {
    const cache = this.cachedObjects[this.getKey(key)];
    return cache && cache.obj && cache.expiredAt > Date.now();
  }

  public cached(key?: unknown) {
    if (!this.hasCache(key)) {
      throw new Error("Tried to access an empty cache");
    }
    return this.cachedObjects[this.getKey(key)].obj;
  }

  public cache(obj: T, options?: CacheOptions) {
    const key = this.getKey(options?.key);

    this.cachedObjects[key] = {
      obj,
      expiredAt: DateUtils.add(options?.duration ?? { seconds: 10 }).getTime()
    };

    return obj;
  }

  private getKey(key?: unknown) {
    return key ? JSON.stringify(key) : "Key";
  }
}
