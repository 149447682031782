
import Vue from "vue";
import Component from "vue-class-component";

import { PersonListViewModel } from "@/common/vms/PersonListViewModel";
import { PersonListController } from "@/common/controllers/PersonListController";
import { PersonListPresenter } from "@/common/presenters/PersonListPresenter";

import Page from "@/components/layout/Page.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Section from "@/components/layout/Section.vue";
import Grid from "@/components/layout/Grid.vue";
import FullScreenDialog from "@/components/layout/FullScreenDialog.vue";

import CreatePersonView from "@/office/views/CreatePersonView.vue";

import PaginatedDatatable from "@/components/collection/PaginatedDatatable.vue";
import ResponsiveIconButton from "@/components/basic/ResponsiveIconButton.vue";
import SubmitTextfield from "@/components/basic/SubmitTextfield.vue";
import IconButton from "@/components/basic/IconButton.vue";

import { AxiosGraphQLConnection } from "../../gateways/graphql/connection/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { LocalStorage } from "../../storage/LocalStorage";
import { Authorizator } from "../../common/interactors/Authorizator";
import { AuthorizatorStorageGateway } from "../../gateways/storage/AuthorizatorStorageGateway";
import { PersonEditor } from "../interactors/PersonEditor";
import { PersonEditorGraphQLGateway } from "@/gateways/graphql/PersonEditorGraphQLGateway";

@Component<PersonListView>({
  components: {
    CreatePersonView,
    Page,
    Section,
    Grid,
    Row,
    Column,
    ResponsiveIconButton,
    SubmitTextfield,
    PaginatedDatatable,
    IconButton,
    FullScreenDialog
  },
  watch: {
    "vm.goBack"(go: boolean) {
      if (go) {
        this.$router.back();
      }
    },
    "vm.goToPerson"(personId: string) {
      this.$router.push({ name: "person", params: { personId } });
    },
    "vm.createPersonDialogVisible"(visible: boolean) {
      if (!visible) {
        (this.$refs.createPersonView as Vue & { reset(): void }).reset();
      }
    }
  }
})
export default class PersonListView extends Vue {
  private vm = new PersonListViewModel();

  private get controller() {
    return new PersonListController(
      new PersonListPresenter(this.vm),
      new PersonEditor(
        new PersonEditorGraphQLGateway(
          new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger()))
        )
      ),
      new LocalStorage()
    );
  }

  private mounted() {
    this.controller.init();
  }
}
