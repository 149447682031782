import { PlantFieldData } from "@/property/shared/entities/PlantEntity";
import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";

export class PlantFieldForm extends Form<PlantFieldData> {
  protected definition = {
    name: {
      label: "Feldname",
      type: FormFieldType.Text,
      required: true
    },
    key: {
      label: "Feld-ID",
      type: FormFieldType.Text,
      required: false
    },
    type: {
      label: "Feldtyp",
      type: FormFieldType.Selection,
      required: true
    },
    required: {
      label: "Benötigt",
      type: FormFieldType.Checkbox,
      required: false
    },
    items: {
      label: "Auswahlmöglichkeiten",
      type: FormFieldType.MultiSelect,
      required: false,
      validate: (data: PlantFieldData) => {
        if (
          data.type === "select" &&
          (!data.items || data.items.length === 0 || data.items.every(i => !i))
        ) {
          return "Keine Auswahlmöglichkeiten festgelegt";
        }

        return true;
      }
    }
  };
}
