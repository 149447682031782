
import Vue from "vue";
import Component from "vue-class-component";

import Checkbox from "@/components/form/Checkbox.vue";
import Value from "@/components/basic/Value.vue";

@Component<EditableCheckbox>({
  components: {
    Checkbox,
    Value
  },
  props: {
    value: {
      type: Object,
      default() {
        return { label: "Label", value: false, error: "" };
      }
    },
    val: {
      type: Boolean,
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    editing: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayTitle: {
      type: Boolean,
      default: true
    },
    boldValue: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  }
})
export default class EditableCheckbox extends Vue {
  private get translatedValue() {
    return {
      label: this.$props.label || this.$props.value.label,
      value: this.$props.val || this.$props.value.value ? "Ja" : "Nein"
    };
  }
}
