import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { GraphQLConnection } from "@/gateways/graphql/connection/GraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import {
  GenericData,
  GenericDataConverter
} from "@/shared/datastructures/GenericDataConverter";
import { Existence } from "@/shared/types/Existence";
import { EmployeeEntity } from "../../entities/EmployeeEntity";
import { RoleEntity } from "../../entities/RoleEntity";
import { EmployeeProvider } from "./EmployeeProvider";

export class EmployeeGraphQLProvider implements EmployeeProvider {
  private employeeConverter = new GenericDataConverter<EmployeeEntity>([
    { from: "deleted_at", to: "deletedAt" }
  ]);
  private roleConverter = new GenericDataConverter<RoleEntity>();

  public constructor(
    private connection: GraphQLConnection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    )
  ) {}

  public async loadEmployees(clientId: number): Promise<EmployeeEntity[]> {
    const result = await this.connection.query(
      "employees",
      { client_id: clientId },
      [
        "id",
        "name",
        "email",
        { name: "role", fields: ["id", "name"] },
        { name: "person", fields: ["firstname", "lastname"] },
        "deleted_at"
      ]
    );

    return result.data.map((d: GenericData) =>
      this.employeeConverter.convert(d)
    );
  }

  public async loadEmployeeSelection(
    clientId: number
  ): Promise<EmployeeEntity[]> {
    const result = await this.connection.query(
      "employees",
      { client_id: clientId },
      ["id", { name: "person", fields: ["firstname", "lastname"] }]
    );

    return result.data.map((d: GenericData) =>
      this.employeeConverter.convert(d)
    );
  }

  public async loadRoles(): Promise<RoleEntity[]> {
    const result = await this.connection.query("newRoles", {}, ["id", "name"]);

    return result.data.map((d: GenericData) => this.roleConverter.convert(d));
  }

  public async checkExistence(email: string): Promise<Existence> {
    const result = await this.connection.query(
      "employeeExistence",
      { email },
      []
    );

    return result.data;
  }

  public async createEmployee(employee: EmployeeEntity): Promise<number> {
    const result = await this.connection.mutation(
      "createEmployee",
      {
        input: {
          client_id: employee.client?.id,
          person_id: employee.person?.id,
          firstname: employee.person?.firstname,
          lastname: employee.person?.lastname,
          role_id: employee.role?.id,
          email: employee.email,
          password: employee.password
        }
      },
      []
    );

    return result.data;
  }

  public async reactivateEmployee(id: number): Promise<number> {
    const result = await this.connection.mutation(
      "reactivateEmployee",
      { id },
      []
    );

    return result.data;
  }

  public async deleteEmployee(id: number): Promise<number> {
    const result = await this.connection.mutation(
      "newDeleteEmployee",
      { id },
      []
    );

    return result.data;
  }
}
