
import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import StyledText from "@/components/basic/StyledText.vue";

import Checkbox from "@/components/form/Checkbox.vue";

import RoleSelect from "@/property/shared/widgets/views/RoleSelect.vue";

import { AccessResourceVm } from "./vms/AccessResourceVm";
import { AccessClientsVm } from "./vms/AccessClientsVm";
import { RoleModel } from "../../models/RoleModel";

@Component<AccessResource>({
  components: {
    Row,
    Column,
    StyledText,
    RoleSelect,
    Checkbox
  },
  props: {
    vm: {
      type: Object,
      default() {
        return new AccessClientsVm();
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
export default class AccessResource extends Vue {
  private vm!: AccessResourceVm;

  private setSharing(sharing: boolean) {
    this.vm.setSharing(sharing);
    if (!sharing) {
      this.$emit("unshare", this.vm.accessModel);
    }
  }

  private setRole(role: RoleModel) {
    this.vm.setRole(role);
    this.$emit("share", this.vm.accessModel);
  }
}
