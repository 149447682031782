
import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";

@Component<Dialog>({
  components: {
    Card
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "50%"
    },
    padding: {
      type: Number,
      default: 4
    },
    title: {
      type: String,
      default: ""
    },
    closeable: {
      type: Boolean,
      default: true
    },
    persistent: {
      type: Boolean,
      default: true
    },
    fullWidthOnMobile: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    eager: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ""
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  }
})
export default class Dialog extends Vue {
  private width!: string;
  private fullWidthOnMobile!: boolean;

  private emitInput(visible: boolean) {
    this.$emit("input", visible);
    if (!visible) {
      this.$emit("close");
    }
  }

  private get displayTitle() {
    return !!this.$props.title;
  }

  private get closeIcon() {
    return this.$props.closeable ? "close" : "";
  }

  private get dialogWidth() {
    return this.fullWidthOnMobile && this.$vuetify.breakpoint.smAndDown
      ? "80%"
      : this.width;
  }
}
