export class ArrayUtils {
  public static isArray(obj: any): boolean {
    return obj !== undefined && obj !== null && Array.isArray(obj);
  }

  public static remove<T>(item: T, array: T[]): T[] {
    return array.filter(i => i !== item);
  }

  public static removeInPlace<T>(item: T, array: T[]): T[] {
    const index = array.indexOf(item);

    if (index >= 0) {
      array.splice(index, 1);
    }

    return array;
  }

  public static findMax<T extends { [key: string]: any }>(
    array: T[],
    key: string
  ) {
    return array.reduce((prev, current) =>
      prev[key] > current[key] ? prev : current
    );
  }

  public static move<T>(items?: T[], from?: number, to?: number) {
    if (!items) {
      return;
    }

    items.splice(to ?? 0, 0, items.splice(from ?? 0, 1)[0]);
  }
}
