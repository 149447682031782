import { SettingsManagerDataGateway } from "@/admin/interactors/SettingsManager";

export class SettingMeta {
  public static async load(key: string, gateway: SettingsManagerDataGateway) {
    const metaData = await gateway.loadSettingMeta(key);

    const meta = new SettingMeta(metaData.id);
    meta.key = key;
    meta.fields = JSON.parse(metaData.fields);
    if (!!metaData.variables) {
      meta.variables = JSON.parse(metaData.variables);
    }

    return meta;
  }

  public key: string = "";
  public fields: object[] = [];
  public variables: object[] = [];
  public overwritable: boolean = false;

  public constructor(public id: number) {}
}
