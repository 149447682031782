import { DocumentService, DocumentRow } from "../services/DocumentService";
import { Page } from "@/datastructures/Page";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { IUserSettings } from "@/storage/UserSettings";
import { NumericalId } from "@/datastructures/NumericalId";
import { PaginatedList } from "@/datastructures/PaginatedList";

export class DocumentListController {
  public constructor(
    private presenter: IDocumentListPresenter,
    private documentService: DocumentService,
    private settings: IUserSettings
  ) {}

  public init(metumId: string, subjectType: string, subjectId: string) {
    this.presenter.metumId = metumId;
    this.presenter.subjectType = subjectType;
    this.presenter.subjectId = subjectId;

    this.presenter.init();
    this.loadDocuments(metumId, subjectType, subjectId, 1, "");
  }

  public uploadDocumentButtonClicked() {
    this.presenter.uploadDocumentDialogVisible = true;
  }

  public uploadDocumentCloseButtonClicked() {
    this.presenter.uploadDocumentDialogVisible = false;
  }

  public uploadDocumentChanged(
    document: File[],
    metumIdRaw: string,
    subjectType: string,
    subjectIdRaw: string
  ) {
    if (!document || document.length === 0 || !subjectType) {
      return;
    }

    NumericalId.onValidId(metumIdRaw, metumId => {
      NumericalId.onValidId(subjectIdRaw, subjectId => {
        const request = this.documentService.uploadDocument(
          document[0],
          metumId.id,
          subjectType,
          subjectId.id
        );

        FormRequestHandler.handle(
          request,
          response => (this.presenter.uploadDocumentResponse = response),
          "Dokument konnte nicht hochgeladen werden"
        );
      });
    });
  }

  public openDocumentButtonClicked(link: string) {
    this.presenter.linkToOpen = link;
  }

  public editDocumentButtonClicked(documentId: string) {
    this.presenter.documentToOpen = documentId;
  }

  public pageSwitched(page: number) {
    this.loadDocuments(
      this.presenter.metumId,
      this.presenter.subjectType,
      this.presenter.subjectId,
      page,
      ""
    );
  }

  private loadDocuments(
    metumIdString: string,
    subjectType: string,
    subjectIdString: string,
    pageNumber: number,
    search: string
  ) {
    if (!subjectIdString) {
      subjectIdString = this.settings.clientId;
      subjectType = "client";
    }

    this.presenter.currentPage = pageNumber;

    NumericalId.onValidId(metumIdString, metumId => {
      NumericalId.onValidId(subjectIdString, subjectId => {
        const page = new Page(this.presenter.documentsPerPage, pageNumber);
        const request = this.documentService.getDocuments(
          metumId.id,
          subjectType,
          subjectId.id,
          page,
          search
        );

        FormRequestHandler.handle(
          request,
          response => (this.presenter.loadDocumentsResponse = response),
          "Dokumente konnten nicht geladen werden"
        );
      });
    });
  }
}

export interface IDocumentListPresenter {
  metumId: string;
  subjectType: string;
  subjectId: string;
  currentPage: number;
  uploadDocumentDialogVisible: boolean;

  // Data
  documentsPerPage: number;
  linkToOpen: string;
  documentToOpen: string;

  // Responses
  loadDocumentsResponse: FormResponse<PaginatedList<DocumentRow>>;
  uploadDocumentResponse: FormResponse<number>;

  init(): void;
}
