export class BasicLayoutViewModel {
  public nextRoute = "";
  public selectedProperty = "";
  public selectedPropertyName = "";
  public selectedPropertyLocation = "";
  public propertyUnselected = false;

  public userName = "";
  public clientName = "";
  public role = "";
  public clientId = "";

  public menuItems: MenuItemViewData[] = [];
  public userMenuItems: MenuItemViewData[] = [];
}

interface MenuItemViewData {
  text: string;
  icon?: string;
  routeName?: string;
  items?: MenuItemViewData[];
  params?: object;
}
