
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { PlantCollection } from "@/property/shared/models/PlantCollection";
import { Plant } from "@/property/shared/models/Plant";

@Component<PlantSelect>({
  components: {}
})
export default class PlantSelect extends Vue {
  @Prop({
    type: Object,
    default() {
      return new Plant();
    }
  })
  protected readonly selectedPlant!: Plant;
  @Prop({ required: true }) protected readonly propertyId!: number;
  @Prop() protected readonly label?: string;
  @Prop({ default: "Suche" }) protected readonly searchLabel!: string;
  @Prop() protected readonly error?: string;
  @Prop({ type: Boolean, default: false })
  protected readonly loading!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly disabled!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly clearable!: boolean;

  protected plants = new PlantCollection();

  protected get text() {
    return this.selectedPlant?.exists
      ? this.selectedPlant?.toString()
      : "Nicht zugewiesen";
  }

  protected get items() {
    return (
      this.plants.plants?.map(p => ({
        text: p.toString(),
        value: p.id,
        item: p
      })) ?? []
    );
  }

  protected searchPlants(search: string) {
    this.plants.init({
      search,
      propertyId: this.propertyId,
      count: 100
    });

    this.plants.load();
  }

  protected clearPlantList() {
    this.plants.clear();
  }

  protected emitClear() {
    this.$emit("clear");
  }

  protected emitSelect(plant?: Plant) {
    this.$emit("select", plant);
  }
}
