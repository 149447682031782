import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { GraphQLConnection } from "@/gateways/graphql/connection/GraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import {
  GenericData,
  GenericDataConverter
} from "@/shared/datastructures/GenericDataConverter";
import { PlanEntity } from "../../entities/PlanEntity";
import { PlanProvider } from "./PlanProvider";

interface PlanGraphQLProviderOptions {
  onlyFromCache?: boolean;
}

export class PlanGraphQLProvider implements PlanProvider {
  private planConverter = new GenericDataConverter<PlanEntity>([
    { from: "file" },
    { from: "path", to: "filePath" },
    { from: "point_pixel_a_x", to: "pointPixelAX" },
    { from: "point_pixel_a_y", to: "pointPixelAY" },
    { from: "point_pixel_b_x", to: "pointPixelBX" },
    { from: "point_pixel_b_y", to: "pointPixelBY" },
    { from: "point_real_a_x", to: "pointRealAX" },
    { from: "point_real_a_y", to: "pointRealAY" },
    { from: "point_real_b_x", to: "pointRealBX" },
    { from: "point_real_b_y", to: "pointRealBY" }
  ]);

  public constructor(
    private connection: GraphQLConnection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    ),
    private options?: PlanGraphQLProviderOptions
  ) {}

  public async loadPlan(id: number): Promise<PlanEntity> {
    const result = await this.connection.query(
      "plan",
      { id },
      [
        "id",
        "name",
        "offline",
        "point_pixel_a_x",
        "point_pixel_a_y",
        "point_pixel_b_x",
        "point_pixel_b_y",
        "point_real_a_x",
        "point_real_a_y",
        "point_real_b_x",
        "point_real_b_y",
        {
          name: "file",
          fields: ["path"]
        }
      ],
      undefined,
      undefined,
      undefined,
      this.options?.onlyFromCache
    );

    return this.planConverter.convert(result.data) ?? {};
  }

  public async loadPlanSelection(
    propertyId: number,
    propertyIds?: number[],
    onlyOffline?: boolean
  ): Promise<PlanEntity[]> {
    const result = await this.connection.query(
      "plans",
      {
        property_id: propertyId,
        property_ids: propertyIds,
        only_offline: onlyOffline
      },
      [
        "id",
        "name",
        "offline",
        {
          name: "file",
          fields: ["path"]
        }
      ],
      undefined,
      undefined,
      undefined,
      this.options?.onlyFromCache
    );

    return result.data.map((p: GenericData) => this.planConverter.convert(p));
  }
}
