
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import CreatePlantForm from "@/property/plant/widgets/CreatePlantForm.vue";

import { Plant } from "@/property/shared/models/Plant";

@Component<CreatePlantFormDialog>({
  components: {
    CreatePlantForm
  }
})
export default class CreatePlantFormDialog extends Vue {
  @Prop({ type: Boolean, default: false }) protected readonly visible!: boolean;
  @Prop({ type: Number }) protected readonly propertyId?: number;
  @Prop({ type: Number }) protected readonly partId?: number;
  @Prop({ type: Boolean, default: false })
  protected readonly siblingPart!: boolean;
  @Prop({ type: Number }) protected readonly plantId?: number;

  @Watch("visible")
  protected visibilityChanged(visible: boolean) {
    if (!visible) {
      this.createPlantForm.reset();
    } else {
      this.createPlantForm.init();
    }
  }

  protected get createPlantForm() {
    return this.$refs.createPlantForm as CreatePlantForm;
  }

  protected async plantCreated(plant: Plant) {
    this.$emit("create", plant);
  }
}
