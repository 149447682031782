export interface PropertyEntity {
  id?: number;
  name?: string;
  street?: string;
  zip?: string;
  city?: string;
}

export function convertGraphQlToPropertyEntities(
  data?: any[]
): PropertyEntity[] {
  return data?.map(p => convertGraphQlToPropertyEntity(p)) ?? [];
}

export function convertGraphQlToPropertyEntity(data?: any): PropertyEntity {
  if (!data) {
    return {};
  }

  return {
    id: data.id,
    name: data.name,
    street: data.address?.street,
    zip: data.address?.postcode,
    city: data.address?.city
  };
}
