import { ReportSettings } from "@/inspection/shared/dtos/ReportSettings";
import { InspectionRepoContainer } from "@/inspection/shared/repositories/InspectionRepoContainer";
import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { UserSettings } from "@/storage/UserSettings";
import { ReportSettingsForm } from "../forms/ReportSettingsForm";

export class ReportSettingsModel extends RequestModel<ReportSettings> {
  private form: ReportSettingsForm;
  private assignmentId = 0;
  private reportType = "";
  private reportId = 0;

  public constructor() {
    super();
    this.form = new ReportSettingsForm();
    this.form.init();
  }

  public load(assignmentId: string, reportType: string, reportId: string) {
    this.assignmentId = parseInt(assignmentId, 10);
    this.reportType = reportType;
    this.reportId = parseInt(reportId, 10);

    RequestHandler.handleModel(
      () =>
        InspectionRepoContainer.reportRepo.getReportSettings(
          UserSettings.getNumericUserId(),
          assignmentId,
          reportType
        ),
      this
    );
  }

  public getFormField(fieldname: string) {
    return this.form.getField(fieldname);
  }

  public setFormField(fieldName: string, value: unknown) {
    RequestHandler.handleFormUpdate(
      () => {
        const settings = JSON.stringify(this.form.getData());
        return InspectionRepoContainer.reportRepo.updateReportSettings(
          settings,
          this.assignmentId,
          this.reportType,
          this.reportId
        );
      },
      {
        form: this.form,
        fieldName,
        value
      }
    );
  }

  public from(data: ReportSettings): void {
    for (const key of Object.keys(data)) {
      const value = data[key];
      if (this.form.fieldExists(key)) {
        this.form.setFieldValue(key, value);
      }
    }
  }
}
