
import Vue from "vue";
import Component from "vue-class-component";

import { PlanCollection } from "@/property/shared/models/PlanCollection";
import { Synchronizer } from "./Synchronizer";
import { PropertyReportContainer } from "@/property/shared/repositories/PropertyRepoContainer";
import { FileUtils } from "../utils/FileUtils";
import { EnvironmentUtils } from "../utils/EnvironmentUtils";

@Component<SyncPlanList>({
  components: {}
})
export default class SyncPlanList extends Vue implements Synchronizer {
  public syncing = false;
  protected paused = false;

  protected readonly plans = new PlanCollection();

  public async reload(): Promise<void> {
    await PropertyReportContainer.planCache.syncWithStore();
    this.plans.from(await PropertyReportContainer.planCache.getAll());
  }

  public async sync(): Promise<void> {
    for (const plan of this.plans.plans ?? []) {
      if (this.paused) {
        break;
      }

      if (plan.isLink && plan.offline && !EnvironmentUtils.isLocalEnv()) {
        try {
          const planImageFile = await FileUtils.fromUrl(
            plan.filePath!,
            "plan.jpg",
            "image/jpeg"
          );
          const planImageString = await FileUtils.toBase64(planImageFile);

          plan.filePath = planImageString;

          await PropertyReportContainer.planCache.add(plan);
        } catch (e) {
          // tslint:disable-next-line: no-console
          console.log("Plan konnte nicht heruntergeladen werden");
        }
      }
    }
  }

  public pause() {
    this.paused = true;
  }

  public resume() {
    this.paused = false;
  }
}
