import { NumericalId } from "@/datastructures/NumericalId";

export interface GraphQLConnection {
  query(
    name: string,
    params: object,
    fields: FieldValue[],
    search?: string,
    orderBy?: OrderByClause,
    skipCache?: boolean,
    fromCache?: boolean
  ): Promise<GraphQLResponse>;

  queryResponse(
    name: string,
    params: object,
    fields: FieldValue[],
    skipCache?: boolean,
    fromCache?: boolean
  ): Promise<GraphQLResponse>;

  mutation(
    name: string,
    params: object,
    fields: FieldValue[],
    timeout?: number,
    fileUpload?: FileUpload
  ): Promise<GraphQLResponse>;

  queryPaginated(
    name: string,
    count: number,
    page: number,
    params: object,
    fields: FieldValue[],
    search: string,
    orderBy?: OrderByClause,
    skipCache?: boolean,
    fromCache?: boolean
  ): Promise<PaginatedGraphQLResponse>;

  uploadFile(
    file: File,
    path: string,
    name: string,
    fields: FieldValue[],
    id?: NumericalId,
    connection?: MorphConnection
  ): Promise<GraphQLResponse>;
}

export type FieldValue = string | FieldList;
export interface FieldList {
  name: string;
  fragment?: string;
  fields: FieldValue[];
}

export interface OrderByClause {
  field: string;
  order: SortOrder;
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC"
}

export function isFieldList(val: any): val is FieldList {
  return val.name !== undefined;
}

export interface GraphQLResponse {
  data: any;
  errors: any;
}

export interface PaginatedGraphQLResponse extends GraphQLResponse {
  count: number;
}

export interface MorphConnection {
  id: number;
  type: string;
  context?: string;
}

export interface FileUpload {
  path: string;
  file: File;
}
