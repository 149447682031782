
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import AccessEntry from "./AccessEntry.vue";
import AccessForm from "./AccessForm.vue";

import { Access, AccessResource } from "@/common/shared/models/Access";
import { LoadAccessesUseCase } from "@/common/access/useCases/LoadAccessesUseCase";
import { CommonRepoContainer } from "@/common/shared/repositories/CommonRepoContainer";
import { Accesses } from "@/common/shared/models/Accesses";
import { Authorizator } from "@/common/interactors/Authorizator";

@Component<AccessList>({
  components: { AccessEntry, AccessForm }
})
export default class AccessList extends Vue {
  @Prop({ type: Object, required: true })
  protected readonly resource!: AccessResource;

  protected accessesLoader = new LoadAccessesUseCase(
    CommonRepoContainer.accessRepo
  );
  protected accesses = new Accesses();

  protected createAccessVisible = false;
  protected accessToEdit: Access | null = null;

  protected get canCreateAccesses() {
    return Authorizator.canCreateTicketAccesses();
  }

  protected accessCreated(access: Access) {
    this.accesses.add(access);
    this.createAccessVisible = false;
  }

  protected accessUpdated(access: Access) {
    this.accessToEdit?.from(access.toEntity());
    this.accessToEdit = null;
  }

  protected mounted() {
    this.load();
  }

  protected async load() {
    const accesses = await this.accessesLoader.do({ resource: this.resource });

    if (accesses) {
      this.accesses = accesses;
    }
  }
}
