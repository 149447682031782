export const variableTypes: {
  [key: string]: { [key: string]: string };
} = {
  user: {
    id: "ID!"
  },
  login: {
    input: "LoginInput"
  },
  refreshToken: {
    input: "RefreshTokenInput"
  },
  register: {
    input: "RegisterInput"
  },
  logout: {},
  updateUser: {
    input: "UpdateUserInput"
  },
  assignment: {
    id: "ID!"
  },
  assignments: {
    search: "String",
    plant_id: "ID!"
  },
  baseAssignments: {
    page: "Int!",
    count: "Int",
    type: "String!",
    property_ids: "[ID!]!",
    search: "String"
  },
  propertyAssignments: {
    property_ids: "[ID!]!",
    include_base_plants: "Boolean",
    search: "String",
    page: "Int!",
    count: "Int"
  },
  basePlants: {
    property_ids: "[ID]!",
    distinctByType: "Boolean"
  },
  checklist: {
    id: "ID!"
  },
  checklistSection: {
    id: "ID!"
  },
  checklistEntry: {
    id: "ID!"
  },
  newChecklistEntry: {
    id: "ID!"
  },
  report: {
    id: "ID!"
  },
  reportByType: {
    user_id: "ID!",
    assignment_id: "ID!",
    report_type: "String!"
  },
  storeReport: {
    input: "StoreReportInput!"
  },
  createAssignment: {
    input: "CreateAssignmentInput!"
  },
  newCreateAssignment: {
    input: "NewCreateAssignmentInput!"
  },
  updateAssignment: {
    input: "UpdateAssignmentInput!"
  },
  deleteAssignment: {
    id: "ID!"
  },
  updateChecklist: {
    input: "UpdateChecklistInput!"
  },
  setProtectionGoalNiveau: {
    input: "SetProtectionGoalNiveauInput!"
  },
  setChecklistPreparationState: {
    input: "SetChecklistPreparationStateInput!"
  },
  setChecklistState: {
    input: "SetChecklistStateInput!"
  },
  updateChecklistSection: {
    input: "UpdateChecklistSectionInput!"
  },
  updateChecklistEntry: {
    input: "UpdateChecklistEntryInput!"
  },
  createReport: {
    input: "CreateReportInput!"
  },
  updateReport: {
    input: "UpdateReportInput!"
  },
  renderReport: {
    id: "ID!"
  },
  exportTicketsAsCSV: {
    assignment_id: "ID!"
  },
  renderWaterMeterReport: {
    assignment_id: "ID!"
  },
  updateReportSettings: {
    input: "UpdateReportSettingsInput!"
  },
  updateReportData: {
    input: "UpdateReportDataInput!"
  },
  newClients: {},
  client: {
    id: "ID!"
  },
  searchClients: {
    search: "String",
    orderBy: "[OrderByClause!]"
  },
  createClient: {
    input: "CreateClientInput!"
  },
  newCreateClient: {
    input: "NewCreateClientInput!"
  },
  updateClient: {
    input: "UpdateClientInput!"
  },
  updateGeneralClientData: {
    input: "UpdateGeneralClientDataInput!"
  },
  updateClientContact: {
    input: "UpdateClientContactInput!"
  },
  updateClientAddress: {
    input: "UpdateClientAddressInput!"
  },
  uploadClientLogo: {
    input: "UploadClientLogoInput!"
  },
  deleteClientLogo: {
    input: "DeleteClientLogoInput!"
  },
  deleteClient: {
    id: "ID!"
  },
  newDeleteClient: {
    id: "ID!"
  },
  shareAccesses: {
    input: "ShareAccessesInput!"
  },
  unshareAccesses: {
    input: "UnshareAccessesInput!"
  },
  documents: {
    subject_id: "ID",
    subject_type: "String",
    document_metum_id: "ID"
  },
  document: {
    id: "ID!"
  },
  documentMeta: {
    general: "Boolean",
    associated_with: "String"
  },
  documentMetum: {
    id: "ID!"
  },
  createDocument: {
    input: "CreateDocumentInput!"
  },
  updateDocument: {
    input: "UpdateDocumentInput!"
  },
  deleteDocument: {
    id: "ID!"
  },
  syncDWSBase: {},
  employees: {
    client_id: "ID!"
  },
  employee: {
    email: "String",
    person_id: "ID",
    client_id: "ID!"
  },
  employeeExistence: {
    email: "String!"
  },
  employeeClientExists: {
    email: "String"
  },
  newRoles: {},
  createEmployee: {
    input: "CreateEmployeeInput!"
  },
  reactivateEmployee: {
    id: "ID!"
  },
  deleteEmployee: {
    id: "ID!"
  },
  newDeleteEmployee: {
    id: "ID!"
  },
  person: {
    id: "ID!"
  },
  people: {
    search: "String",
    client_id: "ID!"
  },
  newPeople: {
    client_id: "ID!",
    search: "String",
    page: "Int",
    count: "Int"
  },
  searchPersons: {
    search: "String",
    client_id: "ID"
  },
  createPerson: {
    input: "CreatePersonInput!"
  },
  deletePerson: {
    id: "ID!"
  },
  createAddress: {
    input: "CreateAddressInput!"
  },
  createPhoneNumber: {
    input: "CreatePhoneNumberInput!"
  },
  plant: {
    id: "ID",
    property_id: "ID",
    type: "String"
  },
  childPlants: {
    plant_id: "ID!"
  },
  plantsPaginated: {
    property_ids: "[ID]",
    include_property_part_plants: "Boolean",
    search: "String",
    page: "Int",
    count: "Int"
  },
  createPlant: {
    input: "CreatePlantInput!"
  },
  updateGeneralPlantData: {
    input: "UpdateGeneralPlantDataInput!"
  },
  updatePlantData: {
    input: "UpdatePlantDataInput!"
  },
  updatePlantLocation: {
    input: "UpdatePlantLocationInput!"
  },
  updatePlantStructure: {
    input: "UpdatePlantStructureInput!"
  },
  uploadPlantImage: {
    input: "UploadPlantImageInput!"
  },
  deletePlantImage: {
    id: "ID!"
  },
  deletePlant: {
    id: "ID!"
  },
  properties: {
    search: "String",
    client_id: "ID!"
  },
  userProperties: {
    user_id: "ID!",
    page: "Int!",
    count: "Int",
    search: "String"
  },
  property: {
    id: "ID",
    asset_number: "String"
  },
  plan: {
    id: "ID!"
  },
  plans: {
    property_id: "ID",
    property_ids: "[ID!]",
    only_offline: "Boolean"
  },
  createProperty: {
    input: "CreatePropertyInput!"
  },
  updateProperty: {
    input: "UpdatePropertyInput!"
  },
  deleteProperty: {
    id: "ID!"
  },
  propertyParts: {
    property_id: "ID",
    parent_id: "ID"
  },
  propertyPartsAndPlants: {
    parent_id: "ID"
  },
  createPropertyPart: {
    input: "CreatePropertyPartInput!"
  },
  updatePropertyPart: {
    input: "UpdatePropertyPartInput!"
  },
  deletePropertyPart: {
    id: "ID!"
  },
  createPlan: {
    input: "CreatePlanInput!"
  },
  updatePlan: {
    input: "UpdatePlanInput!"
  },
  deletePlan: {
    id: "ID!"
  },
  setting: {
    key: "String!",
    client_id: "ID!"
  },
  settings: {
    client_id: "[ID!]"
  },
  settingMeta: {
    key: "String"
  },
  settingMetas: {
    client_id: "ID"
  },
  createSetting: {
    input: "CreateSettingInput!"
  },
  updateSetting: {
    input: "UpdateSettingInput!"
  },
  ticket: {
    id: "ID!"
  },
  ticketExistence: {
    id: "ID!"
  },
  newTicket: {
    id: "ID",
    sync_id: "String"
  },
  newTickets: {
    client_id: "ID",
    property_ids: "[ID!]",
    priorities: "[String!]",
    states: "[String!]",
    plant_id: "ID",
    checklist_entry_template_id: "String",
    checklist_entry_id: "ID",
    search: "String",
    count: "Int!",
    page: "Int!",
    orderBy: "[OrderByClause!]"
  },
  ticketPlan: {
    ticket_id: "ID!",
    plan_id: "ID!"
  },
  searchTickets: {
    search: "String",
    client_id: "ID!",
    property_id: "ID",
    priority: "String",
    state: "String",
    plant_id: "ID",
    checklist_entry_template_id: "String",
    checklist_entry_id: "ID",
    orderBy: "[OrderByClause!]"
  },
  createTicket: {
    input: "CreateTicketInput!"
  },
  newCreateTicket: {
    input: "NewCreateTicketInput!"
  },
  uploadTicketImage: {
    input: "UploadTicketImageInput!"
  },
  deleteTicketImage: {
    input: "DeleteTicketImageInput!"
  },
  updateTicket: {
    input: "UpdateTicketInput!"
  },
  changeTicketDescription: {
    input: "ChangeTicketDescriptionInput!"
  },
  changeTicketOrganization: {
    input: "ChangeTicketOrganizationInput!"
  },
  connectPersonToTicket: {
    input: "ConnectPersonToTicketInput!"
  },
  assignPlantToTicket: {
    input: "AssignPlantToTicketInput!"
  },
  disconnectPersonFromTicket: {
    input: "DisconnectPersonFromTicketInput!"
  },
  unassignPlantFromTicket: {
    input: "UnassignPlantFromTicketInput!"
  },
  setTicketState: {
    input: "SetTicketStateInput!"
  },
  deleteTicket: {
    id: "ID!"
  },
  resourceAccesses: {
    resource_id: "ID!",
    resource_type: "String!"
  },
  shareAccess: {
    input: "ShareAccessInput!"
  },
  updateAccess: {
    input: "UpdateAccessInput!"
  },
  deleteAccess: {
    input: "DeleteAccessInput!"
  },
  updateFile: {
    input: "UpdateFileInput!"
  },
  deleteFile: {
    id: "ID!"
  },
  deleteFiles: {
    fileable_id: "ID!",
    fileable_type: "String!"
  },
  importTickets: {
    input: "ImportTicketsInput!"
  },
  comments: {
    commentable_id: "ID!",
    commentable_type: "String!"
  },
  addComment: {
    input: "AddCommentInput!"
  },
  updateComment: {
    input: "UpdateCommentInput!"
  },
  changeCommentState: {
    input: "ChangeCommentStateInput!"
  },
  deleteComment: {
    input: "DeleteCommentInput!"
  }
};
