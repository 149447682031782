import { CreatePersonViewModel } from "../vms/CreatePersonViewModel";
import {
  ICreatePersonPresenter,
  PhoneNumberData,
  AddressData
} from "../controllers/CreatePersonController";
import { SalutationList, addressContexts } from "@/data/settings";
import { Form } from "@/forms/Form";
import { PersonForm } from "@/forms/person/PersonForm";
import { ArrayUtils } from "@/utils/ArrayUtils";
import { PersonContactForm } from "@/forms/person/PersonContactForm";
import { PersonPhoneNumberForm } from "@/forms/person/PersonPhoneNumberForm";
import { PersonAddressesForm } from "@/forms/person/PersonAddressesForm";
import { PersonAddressForm } from "@/forms/person/PersonAddressForm";
import { FullPersonData } from "../interactors/PersonManager";
import { FormResponse } from "@/forms/FormResponse";
import { NumericalId } from "@/datastructures/NumericalId";

export class CreatePersonPresenter implements ICreatePersonPresenter {
  public personForm: PersonForm;
  public contactForm: PersonContactForm;
  public phoneNumberForms: PersonPhoneNumberForm[] = [];
  public addressesForm: PersonAddressesForm;
  public addressForms: PersonAddressForm[] = [];

  constructor(private vm: CreatePersonViewModel) {
    this.personForm = new PersonForm(this.vm, this.setPersonFormValid);
    this.contactForm = new PersonContactForm(this.vm, this.setContactFormValid);
    this.addressesForm = new PersonAddressesForm(
      this.vm,
      this.setAddressesFormValid
    );

    this.personForm.init();
    this.contactForm.init();
    this.addressesForm.init();
  }

  public set clientId(id: string) {
    this.vm.clientId = id;
  }

  public get personData(): FullPersonData {
    return {
      clientId: NumericalId.fromString(this.vm.clientId),
      salutation: this.vm.salutation.selected,
      preTitle: this.vm.preDegree.value,
      firstname: this.vm.firstname.value,
      lastname: this.vm.lastname.value,
      postTitle: this.vm.postDegree.value,

      email: this.vm.email.value,
      website: this.vm.website.value,
      phoneNumbers: this.vm.phoneNumbers.map(num => ({
        context: num.context.selected,
        areaCode: num.areaCode.selected,
        number: num.number.value
      })),

      addresses: this.vm.addresses.map(address => ({
        context: address.context.selected,
        street: address.street.value,
        zip: address.zip.value,
        city: address.city.value,
        country: address.country.selected
      }))
    };
  }

  public set salutations(salutations: SalutationList) {
    this.vm.salutation.items = Form.generateSelectionList(salutations);
  }

  public set personFormCompleted(completed: boolean) {
    this.vm.formStep = completed ? 2 : 1;
    this.vm.personFormCompleted = completed;
  }

  public set contactFormCompleted(completed: boolean) {
    this.vm.formStep = completed ? 3 : 2;
    this.vm.contactFormCompleted = completed;
    this.vm.addAddressButtonDisabled = !completed;
  }

  public set newPhoneNumber(newNumber: PhoneNumberData) {
    const phoneNumberForm = new PersonPhoneNumberForm(newNumber);
    newNumber.deletable = this.vm.phoneNumbers.length !== 0;

    this.vm.phoneNumbers.push(newNumber);
    this.phoneNumberForms.push(phoneNumberForm);

    this.contactForm.subscribeSubForm(phoneNumberForm);
    this.contactForm.validateForm();
  }

  public set phoneNumberToDelete(numberToDelete: PhoneNumberData) {
    const numbers = this.vm.phoneNumbers;
    const formIndex = this.vm.phoneNumbers.indexOf(numberToDelete);
    const phoneNumberForm = this.phoneNumberForms[formIndex];

    this.contactForm.unsubscribeSubForm(phoneNumberForm);
    this.contactForm.validateForm();

    this.vm.phoneNumbers = ArrayUtils.remove(numberToDelete, numbers);
    this.phoneNumberForms.splice(formIndex, 1);
  }

  public set newAddress(newAddress: AddressData) {
    const addressForm = new PersonAddressForm(newAddress);
    newAddress.deletable = this.vm.addresses.length !== 0;

    this.vm.addresses.push(newAddress);
    this.addressForms.push(addressForm);

    this.addressesForm.subscribeSubForm(addressForm);
    this.addressesForm.validateForm();
  }

  public set addressToDelete(addressToDelete: AddressData) {
    const addresses = this.vm.addresses;
    const formIndex = this.vm.addresses.indexOf(addressToDelete);
    const addressForm = this.addressForms[formIndex];

    this.addressesForm.unsubscribeSubForm(addressForm);
    this.addressesForm.validateForm();

    this.vm.addresses = ArrayUtils.remove(addressToDelete, addresses);
    this.addressForms.splice(formIndex, 1);
  }

  public set createPersonResponse(response: FormResponse<number>) {
    this.vm.createPersonRequest = response;

    if (!response.loading && !response.error) {
      this.vm.personCreated = response.data;
    }
  }

  private setPersonFormValid(context: any, valid: boolean) {
    context.completePersonDataButtonDisabled = !valid;
  }

  private setContactFormValid(context: any, valid: boolean) {
    context.completeContactDataButtonDisabled = !valid;
  }

  private setAddressesFormValid(context: any, valid: boolean) {
    context.completeAddressDataButtonDisabled = !valid;
  }
}
