import { Plant } from "@/property/shared/dtos/Plant";
import { PlantModel } from "@/property/structure/models/PlantModel";

export class CreateAssignmentButtonModel {
  public plants: PlantModel[] = [];

  public get hasSinglePlant() {
    return this.plants && this.plants.length === 1;
  }

  public get hasMultiplePlants() {
    return this.plants && this.plants.length > 1;
  }

  public get firstPlant() {
    if (this.plants && this.plants.length > 0) {
      return this.plants[0];
    }

    return undefined;
  }

  public init(plants?: Plant[]) {
    this.plants = plants?.map(p => PlantModel.from(p)) ?? [];
  }
}
