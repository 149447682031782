
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { PersonCollection } from "@/property/shared/models/PersonCollection";
import { Person } from "@/property/shared/models/Person";

@Component<PersonSelect>({
  components: {}
})
export default class PersonSelect extends Vue {
  @Prop({
    type: Object,
    default() {
      return new Person();
    }
  })
  protected readonly selectedPerson!: Person;
  @Prop() protected readonly label?: string;
  @Prop({ default: "Suche" }) protected readonly searchLabel!: string;
  @Prop() protected readonly error?: string;
  @Prop({ type: Boolean, default: false })
  protected readonly loading!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly disabled!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly clearable!: boolean;

  protected people = new PersonCollection();

  protected get text() {
    return this.selectedPerson?.exists
      ? this.selectedPerson?.toString()
      : "Nicht zugewiesen";
  }

  protected get items() {
    return (
      this.people.people?.map(p => ({
        text: p.toString(),
        value: p.id,
        item: p
      })) ?? []
    );
  }

  protected searchPeople(search: string) {
    this.people.clearRequest();
    this.people.search(search);
  }

  protected clearPeopleList() {
    this.people.clear();
  }

  protected emitClear() {
    this.$emit("clear");
  }

  protected emitSelect(person?: Person) {
    this.$emit("select", person);
  }
}
