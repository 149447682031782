import { AccessModel } from "@/property/client/models/AccessModel";
import { PropertyReportContainer } from "@/property/shared/repositories/PropertyRepoContainer";
import { ServerResponse } from "@/shared/datastructures/Response";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { ArrayUtils } from "@/utils/ArrayUtils";
import { AccessRecipientVm } from "./AccessRecipientVm";

export class AccessManagerVm {
  public recipients: AccessRecipientVm[] = [];

  public upsertResponse = new ServerResponse<number[]>();

  public get hasNoRecipients() {
    return !this.recipients || this.recipients.length === 0;
  }

  public get recipientSelectionValid() {
    return (
      !this.hasNoRecipients &&
      this.recipients.every(r => r.selectedClient && r.selectedEmployee)
    );
  }

  public get changingAccesses() {
    return this.upsertResponse.loading;
  }

  public get error() {
    return this.upsertResponse.error;
  }

  public get errorDialogVisible() {
    return !!this.error;
  }

  public addRecipient() {
    this.recipients.push(new AccessRecipientVm());
  }

  public deleteRecipient(recipient: AccessRecipientVm) {
    ArrayUtils.removeInPlace(recipient, this.recipients);
  }

  public clearError() {
    this.upsertResponse.error = "";
  }

  public recipientSelected() {
    // TODO: load current shares
  }

  public share(access: AccessModel) {
    const recipientAccesses = this.buildAccessesFromResource(access);

    RequestHandler.handle(
      PropertyReportContainer.accessRepo.shareAccesses(recipientAccesses),
      this.upsertResponse
    );
  }

  public unshare(access: AccessModel) {
    const recipientAccesses = this.buildAccessesFromResource(access);

    RequestHandler.handle(
      PropertyReportContainer.accessRepo.unshareAccesses(recipientAccesses),
      this.upsertResponse
    );
  }

  private buildAccessesFromResource(access: AccessModel) {
    return this.recipients.map(r => r.accessModel.mergeCopy(access));
  }
}
