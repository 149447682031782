
import Vue from "vue";
import Component from "vue-class-component";

import Title from "@/components/layout/Title.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import ConfirmDialog from "@/components/basic/ConfirmDialog.vue";

@Component<Page>({
  components: {
    Row,
    Column,
    ConfirmDialog,
    Title
  },
  props: {
    title: {
      type: String,
      default: "Titel"
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ""
    }
  }
})
export default class Page extends Vue {}
