
import Vue from "vue";
import Component from "vue-class-component";

import ClientSelect from "../../shared/widgets/views/ClientSelect.vue";
import ClientGrid from "./clientListComponents/ClientGrid.vue";
import ClientSearchField from "./clientListComponents/ClientSearchField.vue";
import CreateClientButton from "./clientListComponents/CreateClientButton.vue";

@Component<ClientListPage>({
  components: {
    ClientSearchField,
    ClientSelect,
    CreateClientButton,
    ClientGrid
  }
})
export default class ClientListPage extends Vue {
  protected search = "";

  protected searchClients(search: string) {
    this.search = search;
  }
}
