import { IBasicLayoutPresenter } from "../controllers/BasicLayoutController";
import { BasicLayoutViewModel } from "../vms/BasicLayoutViewModel";
import { SelectedProperty } from "@/datastructures/SelectedProperty";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { storageKeys } from "@/data/storageKeys";
import { Authorizator } from "../interactors/Authorizator";
import { UserSettings } from "@/storage/UserSettings";
import { Plant } from "@/property/shared/dtos/Plant";

export class BasicLayoutPresenter implements IBasicLayoutPresenter {
  private listPeopleAllowed = false;
  private listPropertiesAllowed = false;
  private listTicketsAllowed = false;
  private listClientsAllowed = false;
  private viewOwnClientAllowed = false;
  private listSettingsAllowed = false;
  private listDocumentsAllowed = false;
  private editClientsAllowed = false;
  private listAssignmentsAllowed = false;
  private propertyPlants: Plant[] = [];

  public constructor(
    private vm: BasicLayoutViewModel,
    private store: SimpleStringStorage
  ) {
    this.initAuthorization();
  }

  public set selectedProperty(property: SelectedProperty) {
    this.vm.selectedProperty = property.id;
    this.vm.selectedPropertyName = property.name;
    this.vm.selectedPropertyLocation =
      property.street + ", " + property.zip + " " + property.city;
  }

  public set propertyUnselected(unselected: boolean) {
    this.vm.propertyUnselected = unselected;
  }

  public set selectedRoute(route: string) {
    this.vm.nextRoute = route;
  }

  public set userName(name: string) {
    this.vm.userName = name;
  }

  public set clientId(id: number) {
    this.vm.clientId = id.toString();
  }

  public set clientName(name: string) {
    this.vm.clientName = name;
  }

  public set role(role: string) {
    this.vm.role = role;
  }

  // Init
  public init() {
    this.vm.menuItems = [];

    this.vm.menuItems.push({
      text: "Karte",
      icon: "map",
      routeName: "map"
    });

    if (this.listPeopleAllowed) {
      this.vm.menuItems.push({
        text: "Adressbuch",
        icon: "book",
        items: [{ text: "Adressliste", routeName: "people" }]
      });
    }

    const propertyItems = [];
    if (this.listPropertiesAllowed) {
      propertyItems.push({ text: "Gebäudeliste", routeName: "properties" });
    }

    if (!!this.vm.selectedProperty) {
      propertyItems.push({
        text: "Pläne",
        routeName: "property-plans",
        params: { propertyId: this.vm.selectedProperty }
      });

      propertyItems.push({
        text: "Struktur",
        routeName: "property-structure",
        params: { propertyId: this.vm.selectedProperty }
      });

      if (this.listDocumentsAllowed) {
        propertyItems.push(
          this.initDocumentMenuItems(
            storageKeys.propertyDocumentMeta,
            undefined,
            "property",
            this.vm.selectedProperty
          )
        );
      }
    }

    if (propertyItems.length > 0) {
      this.vm.menuItems.push({
        text: "Gebäudebuch",
        icon: "home",
        items: propertyItems
      });
    }

    if (this.listTicketsAllowed) {
      this.vm.menuItems.push({
        text: "Tickets",
        icon: "local_offer",
        items: [{ text: "Ticketliste", routeName: "tickets" }]
      });
    }

    if (!!this.vm.selectedPropertyName) {
      if (this.listAssignmentsAllowed) {
        const assignmentItems = [];

        if (this.propertyPlants && this.propertyPlants.length > 0) {
          for (const plant of this.propertyPlants) {
            const checklist = UserSettings.getChecklist(plant.checklist);

            if (checklist) {
              const name = checklist.name;

              assignmentItems.push({
                text: name,
                routeName: "assignments",
                params: { plantId: plant.id, name }
              });
            }
          }
        } else {
          assignmentItems.push({ text: "Lädt..." });
        }

        this.vm.menuItems.push({
          text: "Begehungen",
          icon: "assignment",
          items: assignmentItems
        });
      }
    }

    if (this.viewOwnClientAllowed && this.listDocumentsAllowed) {
      this.vm.menuItems.push(
        this.initDocumentMenuItems(storageKeys.generalDocumentMeta, "file_copy")
      );
    }

    this.vm.userMenuItems = [];

    if (this.viewOwnClientAllowed) {
      this.vm.userMenuItems.push({
        text: "Mandant",
        icon: "person",
        routeName: "client",
        params: { clientId: this.vm.clientId }
      });
    }

    if (this.listClientsAllowed) {
      this.vm.userMenuItems.push({
        text: "Mandanten",
        icon: "group",
        routeName: "clients"
      });
    }

    if (this.listSettingsAllowed) {
      this.vm.userMenuItems.push({
        text: "Einstellungen",
        icon: "settings_applications",
        routeName: "settings"
      });
    }

    if (this.editClientsAllowed) {
      this.vm.userMenuItems.push({
        text: "DWSBase-Sync",
        icon: "autorenew",
        routeName: "dwsbase-sync"
      });
    }

    if (
      this.store.has(storageKeys.originalUserId) &&
      UserSettings.getUserId() !== UserSettings.getOriginalUserId()
    ) {
      this.vm.userMenuItems.push({
        text: "Personifizierung aufheben",
        icon: "voice_over_off",
        routeName: "home",
        params: { action: "depersonate" }
      });
    }

    this.vm.userMenuItems.push({
      text: "Logout",
      icon: "exit_to_app",
      routeName: "logout"
    });
  }

  public set plants(plants: Plant[]) {
    this.propertyPlants = plants;
    this.init();
  }

  // private initDocumentMenuItems() {
  //   const documentMetaRaw = this.store.get(storageKeys.generalDocumentMeta);
  //   const documentMeta = JSON.parse(documentMetaRaw);

  //   this.vm.menuItems.push({
  //     text: "Dokumente",
  //     icon: "file_copy",
  //     items: documentMeta.map((metum: any) => ({
  //       text: metum.label,
  //       routeName: "documents",
  //       params: { metumId: metum.id }
  //     }))
  //   });
  // }

  private initDocumentMenuItems(
    storageKey: string,
    icon?: string,
    subjectType?: string,
    subjectId?: string
  ) {
    const documentMetaRaw = this.store.get(storageKey);

    if (!documentMetaRaw) {
      return {
        text: "Dokumente",
        icon,
        items: [
          {
            text: "Bitte neu laden..."
          }
        ]
      };
    }
    const documentMeta = JSON.parse(documentMetaRaw);

    return {
      text: "Dokumente",
      icon,
      items: documentMeta.map((metum: any) => ({
        text: metum.label,
        routeName: "documents",
        params: { metumId: metum.id, subjectType, subjectId }
      }))
    };
  }

  private initAuthorization() {
    this.listPeopleAllowed = Authorizator.canListPeople();
    this.listPropertiesAllowed = Authorizator.canListProperties();
    this.listTicketsAllowed = Authorizator.canListTickets();
    this.listClientsAllowed = Authorizator.canListClients();
    this.viewOwnClientAllowed = Authorizator.canViewOwnClient();
    this.listSettingsAllowed = Authorizator.canListSettings();
    this.listDocumentsAllowed = Authorizator.canListDocuments();
    this.listAssignmentsAllowed = Authorizator.canListAssignments();
    this.editClientsAllowed = Authorizator.canEditClients();
  }
}
