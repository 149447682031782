import { ReportTemplateDataField } from "@/inspection/shared/settings/ReportTemplateSettings";
import { DynamicForm } from "@/shared/form/DynamicForm";
import { FormDefinition, FormFieldType } from "@/shared/form/FormDefinition";

export class ReportDataFormGenerator {
  public constructor(private fields: ReportTemplateDataField[]) {}

  public generateForm(): DynamicForm {
    const definition: FormDefinition<unknown> = {};

    for (const field of this.fields) {
      definition[(field.key || field.id) ?? ""] = {
        label: field.label ?? "",
        required: false,
        defaultValue: field.default,
        multiline: field.type === "text",
        width: field.width,
        type:
          field.type === "number"
            ? FormFieldType.Number
            : field.type === "date"
            ? FormFieldType.Date
            : FormFieldType.Text
      };
    }

    return new DynamicForm(definition);
  }
}
