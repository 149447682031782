import { Property } from "@/entities/Property";
import { Page } from "@/datastructures/Page";
import { NumericalId } from "@/datastructures/NumericalId";

export class PropertyLocator implements IPropertyLocator {
  public constructor(private gateway: PropertyLocatorGateway) {}

  public loadPropertyLocations(clientId: NumericalId, search: string) {
    const page = new Page(1000, 1);
    return this.gateway
      .loadPropertyLocations(clientId, search, page)
      .then(properties =>
        properties
          .map(property => ({
            id: property.id,
            label:
              property.name +
              " - " +
              property.address.street +
              ", " +
              property.address.zip +
              " " +
              property.address.city,
            lat: property.address.location.lat,
            lng: property.address.location.lng
          }))
          .filter(property => property.lat > 0.0001 && property.lng > 0.0001)
      );
  }
}

export interface IPropertyLocator {
  loadPropertyLocations(
    clientId: NumericalId,
    search: string
  ): Promise<PropertyLocation[]>;
}

export interface PropertyLocatorGateway {
  loadPropertyLocations(
    clientId: NumericalId,
    search: string,
    page: Page
  ): Promise<Property[]>;
}

export interface PropertyLocation {
  id: number;
  label: string;
  lat: number;
  lng: number;
}
