import moment from "moment";

export class DateUtils {
  public static datetime(date: Date) {
    return (
      date.getUTCFullYear().toString() +
      "/" +
      (date.getUTCMonth() + 1).toString() +
      "/" +
      date.getUTCDate() +
      " " +
      date.getUTCHours() +
      ":" +
      date.getUTCMinutes() +
      ":" +
      date.getUTCSeconds()
    );
  }

  public static format(date: string | Date) {
    return moment(date, "YYYY-MM-DD").format("DD. MMM YYYY");
  }

  public static value(
    date: string | Date,
    fallbackIfInvalid: Date = new Date()
  ) {
    let d = moment(date);

    if (!d.isValid()) {
      d = moment(fallbackIfInvalid);
    }
    return d.format("YYYY-MM-DD");
  }

  public static addDays(days: number, fromDate?: Date): Date {
    if (!fromDate) {
      fromDate = new Date();
    }
    fromDate.setDate(fromDate.getDate() + days);

    return fromDate;
  }
}
