import { PaginatedRequestModel } from "@/shared/model/PaginatedRequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { PlantEntity } from "../entities/PlantEntity";
import { PropertyReportContainer } from "../repositories/PropertyRepoContainer";
import { Plant } from "./Plant";

interface PlantCollectionOptions {
  propertyIds?: number[];
  propertyId?: number;
  search?: string;
  page?: number;
  count?: number;
  includePropertyPartPlants?: boolean;
}

export class PlantCollection extends PaginatedRequestModel<PlantEntity> {
  public plants?: Plant[] = [];

  public propertyIds?: number[] = [];
  public search?: string = "";
  public page?: number = 1;
  public count?: number = 20;
  public includePropertyPartPlants?: boolean = false;

  public get notEmpty() {
    return !this.empty;
  }

  public get empty() {
    return this.length === 0;
  }

  public get length() {
    return this.plants?.length ?? 0;
  }

  public init(options?: PlantCollectionOptions) {
    if (options?.propertyIds) {
      this.propertyIds = options?.propertyIds;
    } else if (options?.propertyId) {
      this.propertyIds = [options.propertyId];
    }

    this.search = options?.search;
    this.page = options?.page ?? 1;
    this.count = options?.count ?? 20;
    this.includePropertyPartPlants =
      options?.includePropertyPartPlants ?? false;
  }

  public add(plant: Plant) {
    if (!this.plants) {
      this.plants = [];
    }
    this.plants.push(plant);
  }

  public remove(plant: Plant) {
    this.plants = this.plants?.filter(p => p.id !== plant.id);
  }

  public clear() {
    this.plants = [];
  }

  public load() {
    return RequestHandler.handleModel(
      () =>
        PropertyReportContainer.plantRepo.getPlantsPaginated(
          this.propertyIds ?? [],
          this.search,
          this.includePropertyPartPlants,
          this.page,
          this.count
        ),
      this
    );
  }

  public toEntities() {
    return this.plants?.map(p => p.toEntity());
  }

  public fromPaginated(entities?: PlantEntity[]) {
    this.plants = entities?.map(e => Plant.from(e));
  }

  public static collectFrom(entities?: PlantEntity[]) {
    return this.collect(entities?.map(e => Plant.from(e)));
  }

  public static collect(plants?: Plant[]) {
    const collection = new PlantCollection();
    collection.plants = plants;
    return collection;
  }
}
