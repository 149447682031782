import { IPersonDetailPresenter } from "../controllers/PersonDetailController";
import { PersonDetailViewModel } from "../vms/PersonDetailViewModel";
import { FormResponse } from "@/forms/FormResponse";
import { PersonDetail } from "../interactors/PersonEditor";
import {
  SelectionItem,
  Selection,
  Textfield
} from "@/forms/ViewModelFormTypes";
import {
  phoneContexts,
  areaCodes,
  addressContexts,
  countries
} from "@/data/settings";
import { Form } from "@/forms/Form";
import { Authorizator } from "../interactors/Authorizator";

export class PersonDetailPresenter implements IPersonDetailPresenter {
  public constructor(private vm: PersonDetailViewModel) {
    this.initAuthorization();
  }

  // Responses
  public set loadPersonResponse(response: FormResponse<PersonDetail>) {
    this.vm.loadPersonRequest = response;

    if (!response.loading && !response.error) {
      this.person = response.data;
    }
  }

  public set deletePersonResponse(response: FormResponse<number>) {
    this.vm.deletePersonRequest = response;

    if (!response.loading && !response.error) {
      this.vm.deletedPerson = response.data.toString();
    }
  }

  // Data
  public set person(data: PersonDetail) {
    const phoneContextItems = Form.generateSelectionList(phoneContexts);
    const areaCodeItems = Form.generateSelectionList(areaCodes);
    const addressContextItems = Form.generateSelectionList(addressContexts);
    const countryItems = Form.generateSelectionList(countries);

    this.vm.deletePersonButtonDisabled = data.isEmployee;
    this.vm.undeletablePersonInfoVisible = data.isEmployee;
    this.vm.preDegree.value = data.preDegree;
    this.vm.postDegree.value = data.postDegree;
    this.vm.firstname.value = data.firstname;
    this.vm.lastname.value = data.lastname;

    this.vm.email.value = data.email;
    this.vm.website.value = data.website;

    this.vm.phoneNumbers = data.phoneNumbers.map(phoneNumber => ({
      id: phoneNumber.id ? phoneNumber.id.toString() : "",
      context: this.createSelection(phoneContextItems, phoneNumber.context),
      areaCode: this.createSelection(areaCodeItems, phoneNumber.areaCode),
      number: this.createTextfield(phoneNumber.number)
    }));

    this.vm.addresses = data.addresses.map(address => ({
      id: address.id ? address.id.toString() : "",
      context: this.createSelection(addressContextItems, address.context),
      street: this.createTextfield(address.street),
      zip: this.createTextfield(address.zip),
      city: this.createTextfield(address.city),
      country: this.createSelection(countryItems, address.country)
    }));
  }

  // Helper
  private createSelection(items: SelectionItem[], selected: string): Selection {
    return {
      items,
      label: "",
      error: "",
      selected,
      loading: false
    };
  }

  private createTextfield(value: string): Textfield {
    return {
      value,
      label: "",
      error: "",
      loading: false
    };
  }

  private initAuthorization() {
    this.vm.goBack = !Authorizator.canViewPeople();
    this.vm.deletePersonButtonVisible = Authorizator.canDeletePeople();
    this.vm.undeletablePersonInfoVisible = Authorizator.canDeletePeople();
  }
}
