import { PlantChecklistEntryEntity } from "../entities/PlantEntity";
import { PlantTypeChecklistEntry } from "../settings/PlantTypeSettings";
import { Plant } from "./Plant";
import { PlantChecklistEntry } from "./PlantChecklistEntry";

export class PlantChecklistEntryCollection {
  public entries?: PlantChecklistEntry[] = [];
  public plant?: Plant;

  public get empty() {
    return (this.entries?.length ?? 0) === 0;
  }

  public get hasEntryWithoutName() {
    return this.entries?.some(e => !e.name) ?? false;
  }

  public getEntryById(id?: string) {
    return this.entries?.find(e => e.id === id);
  }

  public getEntryByName(name?: string) {
    return this.entries?.find(e => e.name === name);
  }

  public indexOf(entry: PlantChecklistEntry) {
    return this.entries?.indexOf(entry) ?? -1;
  }

  public add(entry?: PlantChecklistEntry) {
    if (!entry) {
      return;
    }

    this.initIfEmpty();
    this.entries!.push(entry);
  }

  public addEmpty() {
    this.initIfEmpty();

    const entry = new PlantChecklistEntry();
    this.entries!.push(entry);
    return entry;
  }

  public merge(newEntries?: PlantChecklistEntryCollection) {
    this.initIfEmpty();

    newEntries?.entries?.forEach(e => {
      const entry = this.getEntryByName(e.name);

      if (entry) {
        this.remove(entry);
      }

      this.add(e);
    });
  }

  public thinOut(entries?: PlantChecklistEntryCollection) {
    this.initIfEmpty();

    entries?.entries?.forEach(e => {
      const entry = this.getEntryByName(e.name);

      if (entry) {
        this.remove(entry);
      }
    });
  }

  public remove(entry: PlantChecklistEntry) {
    this.entries = this.entries?.filter(f => f !== entry);
  }

  public clear() {
    if (this.entries) {
      this.entries.splice(0);
    }
  }

  public toEntities() {
    return this.entries?.map(e => e.toEntity());
  }

  public static collectFrom(entities?: PlantChecklistEntryEntity[]) {
    return this.collect(entities?.map(e => PlantChecklistEntry.from(e)));
  }

  public static collectFromTypes(types?: PlantTypeChecklistEntry[]) {
    return this.collect(types?.map(t => PlantChecklistEntry.fromType(t)));
  }

  public static collect(fields?: PlantChecklistEntry[]) {
    const collection = new PlantChecklistEntryCollection();
    collection.entries = fields;
    return collection;
  }

  private initIfEmpty() {
    if (!this.entries) {
      this.entries = [];
    }
  }
}
