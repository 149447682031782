export class ServerResponse<T> {
  public loading = false;
  public success = false;
  public failure = false;
  public result?: T;
  public error = "";

  public clear() {
    this.loading = false;
    this.success = false;
    this.failure = false;
    this.result = undefined;
    this.error = "";
  }
}
