
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import PlantChecklistEntrySelect from "@/property/plant/widgets/PlantChecklistEntrySelect.vue";

import { Plant } from "@/property/shared/models/Plant";
import { PlantChecklistEntry } from "@/property/shared/models/PlantChecklistEntry";

@Component<TicketPlantFilter>({
  components: { PlantChecklistEntrySelect }
})
export default class TicketPlantFilter extends Vue {
  @Prop({ type: Object, default: () => new Plant() })
  protected readonly plant!: Plant;
  @Prop({ type: Object, default: () => new PlantChecklistEntry() })
  protected readonly checklistEntry!: PlantChecklistEntry;
  @Prop({ type: Boolean, default: false })
  protected readonly loading!: boolean;
  @Prop({ type: String, default: "" })
  protected readonly error!: string;

  protected get plantLabel() {
    return `Anlage: ${this.plant.name ?? "???"}`;
  }

  protected emitPlantClicked() {
    this.$emit("click:plant", this.plant);
  }

  protected emitPlantCloseClicked() {
    this.$emit("close:plant", this.plant);
  }

  protected emitChange(field: string, value: unknown) {
    this.$emit(`change:${field}`, value);
  }
}
