
import Vue from "vue";
import Component from "vue-class-component";

import { TicketLocationViewModel } from "@/expert/vms/TicketLocationViewModel";
import { TicketLocationPresenter } from "@/expert/presenters/TicketLocationPresenter";
import { TicketLocationController } from "@/expert/controllers/TicketLocationController";
import { PropertySearcher } from "../interactors/PropertySearcher";
import { TicketManager } from "../interactors/TicketManager";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connection/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { PropertySearcherGraphQLGateway } from "@/gateways/graphql/PropertySearcherGraphQLGateway";
import { TicketManagerGraphQLGateway } from "@/gateways/graphql/TicketManagerGraphQLGateway";
import { TicketViewerGraphQLGateway } from "../../gateways/graphql/TicketViewerGraphQLGateway";
import { TicketViewer } from "../../fieldwork/interactors/TicketViewer";
import { PropertyPlanViewer } from "../interactors/PropertyPlanViewer";
import { PropertyPlanViewerGraphQLGateway } from "../../gateways/graphql/PropertyPlanViewerGraphQLGateway";
import { PropertyPlanViewerCachedGateway } from "../../gateways/cached/PropertyPlanViewerCachedGateway";

import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import InfoTooltip from "@/components/basic/InfoTooltip.vue";
import Alert from "@/components/basic/Alert.vue";
import SearchSelect from "@/components/form/SearchSelect.vue";
import InteractivePlan from "@/components/services/InteractivePlan.vue";

@Component<TicketLocationView>({
  components: {
    Grid,
    Row,
    Column,
    SearchSelect,
    Alert,
    InfoTooltip,
    InteractivePlan
  },
  props: {
    ticketId: {
      type: String,
      default: ""
    },
    propertyId: {
      type: String,
      default: ""
    },
    planId: {
      type: String,
      default: ""
    },
    location: {
      type: Object,
      default() {
        return { x: "0", y: "0" };
      }
    },
    confirmable: {
      type: Boolean,
      default: false
    },
    zoom: {
      type: Number,
      default: 1
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    propertyId(id: string) {
      this.controller.init(
        id,
        this.$props.ticketId,
        this.$props.planId,
        this.$props.location
      );
    },
    planId(id: string) {
      this.controller.init(
        this.$props.propertyId,
        this.$props.ticketId,
        id,
        this.$props.location
      );
    },
    "vm.planId"(id: string) {
      // if (!!id) {
      this.$emit(
        "planSelected",
        id,
        this.vm.plan.items.find(p => p.value === id)
      );
      // }
    },
    "vm.ticketLocation"(location: { x: string; y: string }) {
      this.$emit("locationChanged", location);
    }
  }
})
export default class TicketLocationView extends Vue {
  private vm = new TicketLocationViewModel();

  private reset() {
    this.vm = new TicketLocationViewModel();
    this.controller.init(
      this.$props.propertyId,
      this.$props.ticketId,
      this.$props.planId,
      this.$props.location
    );
  }

  private get controller() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );

    return new TicketLocationController(
      new TicketLocationPresenter(this.vm),
      new PropertyPlanViewer(
        new PropertyPlanViewerCachedGateway(
          new PropertyPlanViewerGraphQLGateway(connection)
        )
      )
    );
  }

  private get ticketLocation() {
    return this.$props.location;
  }

  private get centerPlan() {
    return this.ticketLocation.x !== "0" || this.ticketLocation.y !== "0";
  }

  private mounted() {
    this.reset();
  }

  private emitConfirm() {
    this.$emit("confirm");
  }

  private emitZoom(zoom: number) {
    this.$emit("zoom", zoom);
  }
}
