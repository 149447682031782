import { Request, SelectionItem } from "@/forms/ViewModelFormTypes";

export class SettingDetailViewModel {
  // Data
  public clientId: string = "";
  public settingId: string = "";
  public settingKey: string = "";
  public fields: SettingFields = {};
  public goBack: boolean = false;

  // Requests
  public loadSettingRequest: Request = { loading: false, error: "" };
  public saveSettingRequest: Request = { loading: false, error: "" };

  // States
  public parentSettings: boolean = false;
  public settingSavedDialogVisible: boolean = false;
  public saveSettingButtonVisible: boolean = false;
}

export interface SettingFields {
  [key: string]: SettingField;
}

export type SettingField = SettingFieldViewModel | SettingListViewModel;

export interface SettingFieldViewModel {
  key: string;
  label: string;
  value: string;
  default: string;
  error: string;
  type: FieldType;
  required: boolean;
  invisible?: boolean;
  items?: SelectionItem;
}

export interface SettingListViewModel {
  key: string;
  label: string;
  type: ListType;
  values: SettingValueViewModel[];
  fields: SettingFields;
  deactivatable: boolean;
  extendable: boolean;
}

export interface SettingValueViewModel {
  disabled: boolean;
  deactivated: boolean;
  value: SettingFields;
}

type FieldType = "text" | "string";
type ListType = "list";
