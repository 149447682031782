
import { TypeChecker } from "@/shared/utils/TypeChecker";
import Vue from "vue";
import Component from "vue-class-component";

@Component<Checkbox>({
  props: {
    value: {
      type: Object,
      default() {
        return { label: "", value: false, error: "" };
      }
    },
    val: {
      type: [Boolean, String, Array],
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ""
    },
    v: {
      type: [Boolean, String],
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    }
  }
})
export default class Checkbox extends Vue {
  private v!: boolean | string;

  private emitChange(value: boolean | string | string[]) {
    if (!value && TypeChecker.isBoolean(this.v)) {
      this.$emit("change", false);
    } else {
      this.$emit("change", value);
    }
  }
}
