import { Access, AccessResource } from "@/common/shared/models/Access";
import { AccessRepository } from "@/common/shared/repositories/accessRepository/AccessRepository";
import { UseCase } from "@/shared/datastructures/UseCase";
import { RequestHandler } from "@/shared/utils/RequestHandler";

export class UpdateAccessUseCase extends UseCase<Access, Access | undefined> {
  public constructor(private accessRepo: AccessRepository) {
    super();
  }

  public async do(input: Access): Promise<Access | undefined> {
    if (!input.exists) {
      throw new Error(
        "Die Freigabe existiert nicht und kann daher nicht aktualisiert werden"
      );
    }
    if (!input.valid) {
      throw new Error(
        "Die Freigabe, die aktualisiert werden soll ist nich voll definiert"
      );
    }

    await RequestHandler.handle(
      this.accessRepo.updateAccess(input),
      this.response
    );

    return this.response.result;
  }
}
