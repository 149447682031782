import { Form, FormFieldType } from "../Form";

export class PersonForm extends Form {
  protected definition = {
    salutation: { type: FormFieldType.Selection, required: true },
    preDegree: { type: FormFieldType.Text, required: false },
    firstname: { type: FormFieldType.Text, required: true },
    lastname: { type: FormFieldType.Text, required: true },
    postDegree: { type: FormFieldType.Text, required: false }
  };
}
