import { PropertyPart } from "@/property/shared/dtos/PropertyPart";
import { Plant } from "@/property/shared/models/Plant";
import { PropertyReportContainer } from "@/property/shared/repositories/PropertyRepoContainer";
import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { PropertyPartModel } from "./PropertyPartModel";

export class PropertyStructureModel extends RequestModel<PropertyPart> {
  private base: PropertyPartModel | null = null;

  public constructor(private propertyId: number) {
    super();
  }

  public get structure(): PropertyPartModel | null {
    return this.base;
  }

  public loadStructure() {
    RequestHandler.handleModel(
      () =>
        PropertyReportContainer.propertyRepo.getPropertyBasePart(
          this.propertyId
        ),
      this
    );
  }

  public loadChildParts(parentPart: PropertyPartModel | Plant) {
    if (parentPart instanceof PropertyPartModel) {
      return parentPart.loadChildParts();
    } else {
      return parentPart.loadChildren();
    }
  }

  public from(data: PropertyPart): void {
    this.base = PropertyPartModel.from(data);
  }
}
