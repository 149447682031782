import { ModelCollection } from "@/shared/model/ModelCollection";
import { CommentEntity } from "../entities/CommentEntity";
import { Comment } from "./Comment";

export class Comments extends ModelCollection<Comment, CommentEntity> {
  public from(entities?: CommentEntity[]): void {
    this.elements = entities?.map(e => Comment.from(e)) ?? [];
  }

  protected newInstance(): Comments {
    return new Comments();
  }

  public static from(entities?: CommentEntity[]): Comments {
    const collection = new Comments();
    collection.from(entities);
    return collection;
  }

  public static collect(models?: Comment[]): Comments {
    const collection = new Comments();
    collection.collect(models);
    return collection;
  }
}
