import { DateUtils } from "@/shared/utils/DateUtils";
import { MathUtils } from "@/shared/utils/MathUtils";
import {
  FormFieldType,
  FormDefinition,
  FormFieldDefinition
} from "./FormDefinition";
import { FormData, FormField, FormHooks } from "./FormData";
import { Dictionary } from "../datastructures/Dictionary";
import { FormFieldFactory } from "./FormFieldFactory";
import Vue from "vue";
import { ObjectUtils } from "../utils/ObjectUtils";
import { StringUtils } from "../utils/StringUtils";
import { TypeChecker } from "../utils/TypeChecker";
import { timeRegex } from "@/data/regex";
import { FormArray } from "./SubFormArray";

export type SubFormDefinition = Dictionary<FormArray<unknown> | Form<unknown>>;

function isSubFormArray<T>(obj: unknown): obj is FormArray<T> {
  return obj instanceof FormArray;
}

export abstract class Form<TFormData> {
  public data: FormData = {};
  public subForms: SubFormDefinition = {};
  public parentForm?: Form<unknown>;
  protected abstract definition: FormDefinition<TFormData>;
  private hooks: FormHooks = {};

  public constructor(
    public validatedEvent?: (valid: boolean) => void,
    public name?: string,
    public target?: unknown
  ) {}

  public async init() {
    if (Object.keys(this.definition).length === Object.keys(this.data).length) {
      return;
    }

    const fieldPromises: Array<Promise<void>> = [];

    for (const fieldName of Object.keys(this.definition)) {
      const definition = this.definition[fieldName];
      if (!this.fieldAlreadyCreated(fieldName)) {
        fieldPromises.push(this.createField(fieldName, definition));
      }
    }

    await Promise.all(fieldPromises);

    this.validateForm();
  }

  public get fields() {
    return this.data;
  }

  public getField(fieldName: string) {
    this.throwErrorIfFieldNotExists(fieldName);
    return this.data[fieldName];
  }
  public getFieldValue(fieldName: string) {
    return this.getField(fieldName).value;
  }

  public setFieldValue(
    fieldName: string,
    value: unknown,
    skipValidation = false,
    userInput = true
  ) {
    let valid = true;
    const field = this.getField(fieldName);
    field.value = value;

    if (userInput) {
      field.changedFirstTime = true;
    }

    this.fieldValueChanged(fieldName, field);

    if (!skipValidation) {
      valid = this.validateField(fieldName, field);
      this.validateForm();
    }

    return valid;
  }

  public setFieldError(fieldName: string, error: string) {
    const field = this.getField(fieldName);
    field.error = error;
  }

  public getSubFormByIndex(name: string, index = 0) {
    const subForms = this.subForms[name];
    if (subForms) {
      if (isSubFormArray(subForms)) {
        return subForms.subForms[index];
      } else {
        return subForms;
      }
    } else {
      throw new Error("subform-not-existent");
    }
  }

  public getSubFormByTarget(name: string, target: object) {
    const subForms = this.subForms[name];
    if (subForms) {
      if (isSubFormArray(subForms)) {
        return subForms.getSubFormByTarget(target);
      } else if (subForms.target === target) {
        return subForms;
      }
    }

    throw new Error("subform-not-existent");
  }

  public addSubForm<T>(subform?: Form<T>, name?: string, single = false) {
    const subformName = name ?? subform?.name ?? "subform";

    if (!subform) {
      Vue.set(this.subForms, subformName, single ? null : new FormArray<T>([]));
      return;
    }

    subform.parentForm = this as Form<unknown>;

    if (single) {
      Vue.set(this.subForms, subformName, subform as Form<T>);
    } else {
      if (!this.subForms[subformName]) {
        Vue.set(this.subForms, subformName, new FormArray<T>([]));
      }

      (this.subForms[subformName] as FormArray<T>).subForms.push(
        subform as Form<T>
      );
    }
  }

  public removeSubFormByIndex(name: string, index: number) {
    this.removeSubForm(this.getSubFormByIndex(name, index));
  }

  public removeSubFormByTarget(name: string, target: object) {
    this.removeSubForm(this.getSubFormByTarget(name, target));
  }

  public removeSubForm(subForm?: Form<unknown>) {
    for (const subFormKey of Object.keys(this.subForms)) {
      const subForms = this.subForms[subFormKey];

      if (isSubFormArray(subForms)) {
        subForms.remove(subForm);
      } else if (subForms === subForm) {
        delete this.subForms[subFormKey];
      }
    }
  }

  public addFieldChangedListener<T>(
    fieldname: string,
    hook: (value: T) => void
  ) {
    this.hooks[fieldname] = hook as (value: unknown) => void;
  }

  public validateForm(evenUnchanged = false): boolean {
    Object.entries(this.data).forEach(field =>
      this.validateField(field[0], field[1], evenUnchanged)
    );
    for (const subFormKey of Object.keys(this.subForms)) {
      this.getSubFormsAsArray(subFormKey).subForms.forEach(form =>
        form.validateForm(evenUnchanged)
      );
    }

    const valid = this.isValid();
    this.performValidEvent(valid);

    return valid;
  }

  public performValidEvent(valid: boolean) {
    if (this.validatedEvent) {
      this.validatedEvent(valid);
    }
  }

  public isValid(): boolean {
    let validSubforms = true;

    for (const subFormKey of Object.keys(this.subForms)) {
      const subForms = this.getSubFormsAsArray(subFormKey);

      validSubforms =
        validSubforms && subForms.subForms.every(subForm => subForm.isValid());
    }

    const selfValid = Object.entries(this.data).every(field => {
      return (
        !field[1].error &&
        (field[1].value ||
          field[1].changedFirstTime ||
          !this.definition[field[0]].required)
      );
    });

    return selfValid && validSubforms;
  }

  public fieldExists(fieldName: string) {
    return Object.keys(this.definition).some(field => field === fieldName);
  }

  public fieldAlreadyCreated(fieldName: string) {
    return Object.keys(this.data).some(field => field === fieldName);
  }

  public getData(): TFormData {
    const data: Dictionary<unknown> = {};

    for (const field of Object.entries(this.data)) {
      const [key, fieldData] = field;
      data[key] = fieldData.value;
    }

    for (const subFormKey of Object.keys(this.subForms)) {
      const subForms = this.subForms[subFormKey];

      if (isSubFormArray(subForms)) {
        data[subFormKey] = subForms.subForms.map(subForm => subForm.getData());
      } else {
        data[subFormKey] = subForms.getData();
      }
    }

    return data as TFormData & Dictionary<unknown>;
  }

  public setData(data?: TFormData) {
    if (!data) {
      return;
    }

    for (const field of Object.entries(data)) {
      const [key, fieldData] = field;
      if (this.fieldExists(key)) {
        this.setFieldValue(key, fieldData, true, false);
      }
    }
  }

  public async reset() {
    Object.keys(this.data).forEach(fieldName =>
      this.setFieldValue(fieldName, "", true, false)
    );
    ObjectUtils.clean(this.data);

    await this.init();

    for (const subFormKey of Object.keys(this.subForms)) {
      const subForms = this.subForms[subFormKey];

      if (isSubFormArray(subForms)) {
        for (const subForm of subForms.subForms) {
          await subForm.reset();
        }
      } else {
        await subForms.reset();
      }
    }
  }

  public set loading(loading: boolean) {
    for (const fieldName of Object.keys(this.data)) {
      this.data[fieldName].loading = loading;
    }
    for (const subFormKey of Object.keys(this.subForms)) {
      this.getSubFormsAsArray(subFormKey).subForms.forEach(
        subForm => (subForm.loading = loading)
      );
    }
  }

  public set enabled(enabled: boolean) {
    for (const fieldName of Object.keys(this.data)) {
      this.data[fieldName].disabled = !enabled;
    }
    for (const subFormKey of Object.keys(this.subForms)) {
      this.getSubFormsAsArray(subFormKey).subForms.forEach(
        subForm => (subForm.enabled = enabled)
      );
    }
  }

  protected validateField(
    fieldName: string,
    field: FormField,
    evenUnchanged = false
  ) {
    const definition = this.definition[fieldName];

    field.error = "";

    if (TypeChecker.isTimeSpan(field.value)) {
      field.error = field.value.isDefined ? "" : "Ungültige Zeitspanne";
      if (field.error) {
        return false;
      }
    } else if (
      definition.required &&
      (field.changedFirstTime || evenUnchanged)
    ) {
      let filled = false;

      if (TypeChecker.isArray(field.value)) {
        filled = field.value.length > 0;
      } else if (TypeChecker.isRecurringDate(field.value)) {
        filled = field.value.isComplete;
      } else {
        filled =
          field.value ||
          field.value === 0 ||
          field.value === false ||
          field.value === true;
      }

      field.error = filled ? "" : "Erforderlich";

      if (field.error) {
        return false;
      }
    }
    if (definition.greaterThan !== undefined) {
      const value = parseInt(field.value, 10);
      field.error = isNaN(value)
        ? "Keine Zahl"
        : value > definition.greaterThan
        ? ""
        : "Muss größer als " + definition.greaterThan + " sein";
      if (field.error) {
        return false;
      }
    }
    if (definition.regex !== undefined && !!field.value) {
      const pass = definition.regex.test(field.value);
      field.error = this.buildErrorText(!pass, definition, "Ungültiges Format");

      if (field.error) {
        return false;
      }
    }
    if (
      definition.maxLength !== undefined &&
      field.value &&
      TypeChecker.isString(field.value)
    ) {
      const tooLong = field.value.length > definition.maxLength;
      field.error = this.buildErrorText(tooLong, definition, "Zu lang");

      if (field.error) {
        return false;
      }
    }
    if (definition.type === FormFieldType.Time) {
      if (!TypeChecker.isString(field.value) || !timeRegex.test(field.value)) {
        field.error = "Ungültige Zeit";
      }

      if (field.error) {
        return false;
      }
    }
    if (
      definition.validate !== undefined &&
      (field.changedFirstTime || evenUnchanged)
    ) {
      const error = definition.validate(this.getData());

      if (TypeChecker.isString(error)) {
        field.error = error ? error : "";
      } else {
        field.error = !error ? "Eingabe ist invalide" : "";
      }

      if (field.error) {
        return false;
      }
    }

    field.error = "";
    return true;
  }

  private getSubFormsAsArray(name: string) {
    const subForms = this.subForms[name];

    if (subForms) {
      if (isSubFormArray(subForms)) {
        return subForms;
      } else {
        return new FormArray([subForms]);
      }
    } else {
      return new FormArray([]);
    }
  }

  private buildErrorText(
    isError: boolean,
    definition: FormFieldDefinition<TFormData>,
    defaultError: string
  ) {
    return !isError ? "" : definition.error ? definition.error : defaultError;
  }

  private async createField(
    fieldName: string,
    definition: FormFieldDefinition<TFormData>
  ) {
    Vue.set(
      this.data,
      fieldName,
      FormFieldFactory.preCreateField(
        fieldName,
        definition as FormFieldDefinition<unknown>
      )
    );

    Vue.set(
      this.data,
      fieldName,
      await FormFieldFactory.createFieldAsync(
        fieldName,
        definition as FormFieldDefinition<unknown>
      )
    );

    this.fieldValueChanged(fieldName, this.data[fieldName]);
  }

  private fieldValueChanged(fieldName: string, field: FormField) {
    const definition = this.definition[fieldName];

    switch (definition.type) {
      case FormFieldType.Number:
        field.value = StringUtils.isString(field.value)
          ? parseFloat(field.value.replace(",", ".")) || 0
          : field.value;
        if (definition.precision) {
          field.value = MathUtils.naiveRound(
            parseFloat(field.value),
            definition.precision
          );
        }
        break;

      case FormFieldType.Date:
        field.value = DateUtils.isoDate(field.value);
        field.text = DateUtils.format(field.value);
        break;

      case FormFieldType.Checkbox:
        field.value = MathUtils.parseBoolean(field.value);
        break;
    }

    if (this.hookExists(fieldName)) {
      this.hooks[fieldName](field.value);
    }
  }

  private hookExists(fieldName: string) {
    return Object.keys(this.hooks).some(hookKey => hookKey === fieldName);
  }

  private throwErrorIfFieldNotExists(fieldName: string) {
    if (!this.fieldExists(fieldName)) {
      throw new Error("Field " + fieldName + " doesn't exist");
    }
  }
}
