
import Vue from "vue";
import Component from "vue-class-component";

import { TicketDetailViewModel } from "@/fieldwork/vms/TicketDetailViewModel";
import { TicketDetailPresenter } from "@/fieldwork/presenters/TicketDetailPresenter";
import { TicketDetailController } from "@/fieldwork/controllers/TicketDetailController";
import { TicketManager } from "../../expert/interactors/TicketManager";
import { TicketManagerGraphQLGateway } from "@/gateways/graphql/TicketManagerGraphQLGateway";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connection/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { TicketViewerGraphQLGateway } from "../../gateways/graphql/TicketViewerGraphQLGateway";
import { TicketViewer } from "../interactors/TicketViewer";

import { AssignmentManager } from "../../expert/interactors/AssignmentManager";
import { AssignmentManagerGraphQLGateway } from "../../gateways/graphql/AssignmentManagerGraphQLGateway";

import TicketLocationView from "@/expert/views/TicketLocationView.vue";

import Subtitle from "@/components/layout/Subtitle.vue";
import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import ToggleCard from "@/components/layout/ToggleCard.vue";
import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Dialog from "@/components/layout/Dialog.vue";
import CommentList from "@/common/comments/widgets/CommentList.vue";

import Value from "@/components/basic/Value.vue";
import Button from "@/components/basic/Button.vue";
import RequestButton from "@/components/basic/RequestButton.vue";
import IconButton from "@/components/basic/IconButton.vue";
import ResponsiveIconButton from "@/components/basic/ResponsiveIconButton.vue";
import { component as Viewer } from "v-viewer";

import EditableTextfield from "@/components/form/EditableTextfield.vue";
import EditableTextarea from "@/components/form/EditableTextarea.vue";
import EditableDatePicker from "@/components/form/EditableDatePicker.vue";
import SearchSelect from "@/components/form/SearchSelect.vue";

import AdditionalTicketData from "@/components/services/AdditionalTicketData.vue";
import TicketDescriptionForm from "@/inspection/ticket/views/createTicketComponents/TicketDescriptionForm.vue";

import PersonSelect from "@/property/people/widgets/PersonSelect.vue";
import PlantSelect from "@/property/plant/widgets/PlantSelect.vue";

import AccessList from "@/common/access/widgets/AccessList.vue";

import { ChecklistManager } from "../../expert/interactors/ChecklistManager";
import { ChecklistManagerGraphQLGateway } from "../../gateways/graphql/ChecklistManagerGraphQLGateway";
import { AssignmentManagerCachedGateway } from "../../gateways/cached/AssignmentManagerCachedGateway";
import { Person } from "@/property/shared/models/Person";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { InspectionRepoContainer } from "@/inspection/shared/repositories/InspectionRepoContainer";
import { InspectionSettingsContainer } from "@/inspection/shared/settings/InspectionSettingsContainer";
import { ServerResponse } from "@/shared/datastructures/Response";
import { Plant } from "@/property/shared/models/Plant";
import { UserSettings } from "@/storage/UserSettings";
import { Authorizator } from "@/common/interactors/Authorizator";
import { TicketDescription } from "@/inspection/shared/dtos/TicketDtos";
import { Ticket } from "@/inspection/shared/models/Ticket";
import { ObjectUtils } from "@/shared/utils/ObjectUtils";
import { ChangeTicketDescriptionUseCase } from "@/inspection/ticket/useCases/ChangeTicketDescriptionUseCase";

@Component<TicketDetailView>({
  components: {
    AccessList,
    TicketLocationView,
    Subtitle,
    Page,
    Section,
    ToggleCard,
    Card,
    CommentList,
    Grid,
    Row,
    Column,
    Dialog,
    EditableTextfield,
    EditableTextarea,
    Value,
    Button,
    RequestButton,
    IconButton,
    ResponsiveIconButton,
    EditableDatePicker,
    SearchSelect,
    Viewer,
    AdditionalTicketData,
    PersonSelect,
    PlantSelect,
    TicketDescriptionForm
  },
  watch: {
    "vm.goBack"(go: boolean) {
      this.$router.back();
    },
    "vm.goToTicket"(ticketId: string) {
      this.$router.push({
        name: "ticket",
        params: {
          ticketId,
          search: this.search,
          priority: this.priority,
          state: this.state,
          entry: this.entry,
          plant: this.plant,
          entryTemplate: this.entryTemplate
        }
      });
    },
    "vm.ticket"() {
      this.setInitialTicketDescription();
    },
    "vm.deletedTicket"(ticketId: string) {
      this.$router.push({
        name: "tickets",
        params: {
          search: this.search,
          priority: this.priority,
          state: this.state,
          entry: this.entry,
          plant: this.plant,
          entryTemplate: this.entryTemplate
        }
      });
    },
    "vm.selectedProperty"(propertyId: string) {
      this.$router.push({ name: "property", params: { propertyId } });
    },
    "vm.locationDialogVisible"(visible: boolean) {
      if (visible && !!this.$refs.ticketLocation) {
        (this.$refs.ticketLocation as any).reset();
      }
    },
    "vm.loadTicketRequest.loading"(loading: boolean) {
      if (!loading) {
        this.recalcAuth();
      }
    }
  }
})
export default class TicketDetailView extends Vue {
  protected ticketDescriptionChanger = new ChangeTicketDescriptionUseCase(
    InspectionRepoContainer.ticketRepo
  );

  protected displayAccessList = false;
  protected hasTicketDescriptionChanged = false;
  protected initialTicketDescription: TicketDescription = {};

  protected vm = new TicketDetailViewModel();
  protected $imageViewer: any;

  protected get controller() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    const viewerGateway = new TicketViewerGraphQLGateway(connection);

    return new TicketDetailController(
      new TicketDetailPresenter(this.vm),
      new TicketManager(
        new TicketManagerGraphQLGateway(connection, viewerGateway),
        new TicketViewer(viewerGateway)
      ),
      new AssignmentManager(
        new AssignmentManagerCachedGateway(
          new AssignmentManagerGraphQLGateway(connection)
        )
      ),
      new ChecklistManager(new ChecklistManagerGraphQLGateway(connection))
    );
  }

  protected get ticketId() {
    return this.$route.params.ticketId;
  }

  protected get numericTicketId() {
    return parseInt(this.ticketId, 10);
  }

  protected get accessResource() {
    return {
      id: this.numericTicketId,
      type: "ticket"
    };
  }

  protected get commentable() {
    return {
      id: this.numericTicketId,
      type: "ticket"
    };
  }

  protected get search() {
    return this.$route.params.search;
  }

  protected get priority() {
    return this.$route.params.priority;
  }

  protected get state() {
    return this.$route.params.state;
  }

  protected get plant() {
    return this.$route.params.plant;
  }

  protected get entryTemplate() {
    return this.$route.params.entryTemplate;
  }

  protected get entry() {
    return this.$route.params.entry;
  }

  protected get isMobile() {
    return this.$vuetify.breakpoint.xs;
  }

  protected get ticketStates() {
    return InspectionSettingsContainer.ticketStates.allStates();
  }

  protected get ticketStatesAvailable() {
    return this.ticketStates.length > 0;
  }

  protected get propertyId() {
    return parseInt(this.vm.property.id, 10);
  }

  protected get additionalDataVisible() {
    return this.loggedIn && this.vm.ticketType;
  }

  protected get loggedIn() {
    return UserSettings.loggedIn();
  }

  protected mounted() {
    this.init();
  }

  protected init() {
    this.controller.init(
      this.ticketId,
      this.search,
      this.priority,
      this.state,
      this.entry,
      this.plant ? parseInt(this.plant, 10) : undefined,
      this.entryTemplate
    );
  }

  protected initImageViewer(viewer: any) {
    this.$imageViewer = viewer;
  }

  protected imageSelected(index: number) {
    this.$imageViewer.view(index);
  }

  protected openImage(url: string) {
    window.open(url, "_blank");
  }

  protected goToPlant(plant: Plant) {
    this.$router.push({
      name: "plant",
      params: { plantId: plant.id?.toString() ?? "" }
    });
  }

  protected connectPersonResponse = new ServerResponse<number>();
  protected connectPlantResponse = new ServerResponse<number>();
  protected setStateResponse = new ServerResponse<number>();

  protected ticketDescriptionChanged() {
    this.hasTicketDescriptionChanged = !ObjectUtils.shallowEqual(
      this.initialTicketDescription,
      this.getTicketDescription(this.vm.ticket)
    );
  }

  protected async applyTicketDescription() {
    await this.ticketDescriptionChanger.do(this.vm.ticket);
    this.setInitialTicketDescription();
  }

  protected undoTicketDescription() {
    this.vm.ticket = Ticket.from({
      ...this.vm.ticket.toEntity(),
      ...this.initialTicketDescription
    });
  }

  protected setInitialTicketDescription() {
    this.initialTicketDescription = this.getTicketDescription(this.vm.ticket);
    this.hasTicketDescriptionChanged = false;
  }

  protected getTicketDescription(ticket: Ticket): TicketDescription {
    const ticketData = ticket.toEntity();

    return {
      title: ticketData.title,
      description: ticketData.description,
      action: ticketData.action
    };
  }

  protected async stateChanged(state: string) {
    if (
      await RequestHandler.handle(
        InspectionRepoContainer.ticketRepo.setState(
          parseInt(this.vm.ticketId.toString(), 10),
          state
        ),
        this.setStateResponse
      )
    ) {
      this.vm.ticketState = state;
    }
  }

  protected async toggleResponsiblePerson(person?: Person) {
    if (person) {
      if (
        await RequestHandler.handle(
          InspectionRepoContainer.ticketRepo.connectPerson(
            parseInt(this.vm.ticketId.toString(), 10),
            person?.id ?? 0,
            "responsible"
          ),
          this.connectPersonResponse
        )
      ) {
        this.vm.responsiblePerson = person;
      }
    } else {
      if (
        await RequestHandler.handle(
          InspectionRepoContainer.ticketRepo.disconnectPerson(
            parseInt(this.vm.ticketId.toString(), 10),
            "responsible"
          ),
          this.connectPersonResponse
        )
      ) {
        this.vm.responsiblePerson = new Person();
      }
    }
  }

  protected async assignPlant(plant?: Plant) {
    if (plant) {
      if (
        await RequestHandler.handle(
          InspectionRepoContainer.ticketRepo.assignPlant(
            parseInt(this.vm.ticketId.toString(), 10),
            plant?.id ?? 0
          ),
          this.connectPlantResponse
        )
      ) {
        this.vm.plant = plant;
      }
    } else {
      if (
        await RequestHandler.handle(
          InspectionRepoContainer.ticketRepo.unassignPlant(
            parseInt(this.vm.ticketId.toString(), 10)
          ),
          this.connectPlantResponse
        )
      ) {
        this.vm.plant = new Plant();
      }
    }
  }

  protected goToPerson(person: Person) {
    if (person.exists) {
      this.$router.push({
        name: "person",
        params: { personId: person.id?.toString() ?? "" }
      });
    }
  }

  protected recalcAuth() {
    this.vm.deleteTicketButtonVisible = Authorizator.canDeleteTickets();
    this.vm.editingDescriptionDeactivated = !Authorizator.canEditTickets();
    this.vm.editingOrganizationDeactivated = !Authorizator.canEditTickets();
    this.vm.editingImagesDeactivated =
      !Authorizator.canDeleteTicketImages() &&
      !Authorizator.canCreateTicketImages();
    this.vm.editingReferencesVisible = Authorizator.canEditTickets();
    this.vm.editLocationAllowed =
      Authorizator.canEditTickets() && this.loggedIn;
    this.vm.editChecklistReferenceAllowed = Authorizator.canEditTickets();
    this.vm.canEditTicket = Authorizator.canEditTickets();
    this.vm.ticketAccessButtonVisible =
      Authorizator.canViewTicketAccesses() && this.loggedIn;
    this.vm.ticketImagesVisible = Authorizator.canViewTicketImages();
    this.vm.imageUploadVisible = Authorizator.canCreateTicketImages();
    this.vm.deleteImageButtonVisible = Authorizator.canDeleteTicketImages();
    this.vm.displayComments = Authorizator.canViewTicketComments();
  }
}
