import { PlanLocationEntity } from "./PlanLocationEntity";
import { PlanLocationLocalProvider } from "./PlanLocationLocalProvider";
import { PlanLocationProvider } from "./PlanLocationProvider";

export interface PlanLocationRepository {
  getLastLocation(): Promise<PlanLocationEntity>;
  saveLastLocation(location: PlanLocationEntity): Promise<number>;
}

export class DefaultPlanLocationRepository implements PlanLocationRepository {
  public constructor(
    private provider: PlanLocationProvider = new PlanLocationLocalProvider()
  ) {}

  public getLastLocation(): Promise<PlanLocationEntity> {
    return this.provider.loadLastLocation();
  }

  public saveLastLocation(location: PlanLocationEntity): Promise<number> {
    return this.provider.saveLastLocation(location);
  }
}
