
import Vue from "vue";
import Component from "vue-class-component";

import { Prop } from "vue-property-decorator";

import { PlantChecklistEntryCollection } from "@/property/shared/models/PlantChecklistEntryCollection";
import { PlantChecklistEntry } from "@/property/shared/models/PlantChecklistEntry";

@Component<PlantChecklistEntryList>({
  components: {}
})
export default class PlantChecklistEntryList extends Vue {
  @Prop({ type: Object, default: () => new PlantChecklistEntryCollection() })
  protected readonly entries!: PlantChecklistEntryCollection;

  protected displayTickets(entry: PlantChecklistEntry) {
    this.$router.push({
      name: "tickets",
      query: {
        entryTemplate: entry.id ?? "",
        plant: this.entries.plant?.id?.toString() ?? ""
      }
    });
  }
}
