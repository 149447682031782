import { storageKeys } from "@/data/storageKeys";
import { Settings } from "@/shared/datastructures/Settings";

export interface Deadlines {
  deadlines: Deadline[];
}

export interface Deadline {
  id: string;
  name: string;
  days: number;
  default: boolean;
}

export class DeadlineSettings extends Settings<Deadlines> {
  protected get storageKey(): string {
    return storageKeys.deadlines;
  }

  public allDeadlines() {
    const settings = this.get();
    return (
      settings?.deadlines.map(d => ({
        ...d,
        days: parseInt(d.days.toString(), 10)
      })) ?? []
    );
  }

  public getDeadline(name: string) {
    return this.allDeadlines().find(d => d.name === name);
  }

  public getDefaultDeadline() {
    const defaultCandidates = this.allDeadlines().filter(
      d => d.default === true
    );

    if (defaultCandidates && defaultCandidates.length > 0) {
      return defaultCandidates[defaultCandidates.length - 1];
    }

    return undefined;
  }
}
