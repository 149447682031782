
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import ChecklistEntrySortButton from "./ChecklistEntrySortButton.vue";
import ChecklistEntryList from "./ChecklistEntryList.vue";
import EditChecklistEntryDialog from "./EditChecklistEntryDialog.vue";
import CreateTicketPage from "@/inspection/ticket/views/CreateTicketPage.vue";

import { Checklist } from "@/inspection/shared/models/Checklist";
import { ChecklistEntry } from "@/inspection/shared/models/ChecklistEntry";
import { Ticket } from "@/inspection/shared/models/Ticket";

@Component<InspectionChecklist>({
  components: {
    ChecklistEntrySortButton,
    ChecklistEntryList,
    EditChecklistEntryDialog,
    CreateTicketPage
  }
})
export default class InspectionChecklist extends Vue {
  @Prop({ type: Object, default: () => new Checklist() })
  protected readonly checklist!: Checklist;

  protected selectedEntryToEdit: ChecklistEntry | null = null;
  protected selectedEntryToAddTicket: ChecklistEntry | null = null;

  protected get propertyId() {
    return this.checklist.propertyId;
  }

  protected get entryList() {
    return this.$refs.entryList as ChecklistEntryList;
  }

  protected addToEntry(entry: ChecklistEntry) {
    this.selectedEntryToAddTicket = entry;
  }

  protected editEntry(entry: ChecklistEntry) {
    this.selectedEntryToEdit = entry;
  }

  protected ticketCreated(ticket: Ticket) {
    if (ticket) {
      this.selectedEntryToAddTicket?.addTicket(ticket);
      ticket.tryLoadPeriodically();
      // For performance reasons
      ticket.clearImages();
    }
    this.ticketDialogClosed();
  }

  protected ticketDialogClosed() {
    this.selectedEntryToAddTicket = null;
  }

  protected closeEntryList() {
    this.entryList.close();
  }
}
