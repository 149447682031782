import { emailRegex, phoneRegex, websiteRegex } from "@/data/regex";
import { areaCodes } from "@/data/settings";
import { ClientContactData } from "@/property/shared/dtos/ClientDtos";
import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";

export class ClientContactForm extends Form<ClientContactData> {
  protected definition = {
    areaCode: {
      label: "Vorwahl",
      required: false,
      type: FormFieldType.Selection,
      itemDefinition: {
        items: () => Object.values(areaCodes) as []
      },
      validate: (data: ClientContactData) => {
        if (!data.areaCode && data.phoneNumber) {
          return "Erforderlich";
        }
        return true;
      }
    },
    phoneNumber: {
      label: "Telefonnummer",
      required: false,
      type: FormFieldType.Text,
      regex: phoneRegex,
      error: "Telefonnummernformat ungültig",
      validate: (data: ClientContactData) => {
        if (data.areaCode && !data.phoneNumber) {
          return "Erforderlich";
        }
        return true;
      }
    },
    email: {
      label: "Email",
      required: false,
      type: FormFieldType.Text,
      regex: emailRegex,
      error: "Emailformat ungültig"
    },
    website: {
      label: "Website",
      required: false,
      type: FormFieldType.Text,
      regex: websiteRegex,
      error: "Websiteformat ungültig"
    },
    skype: { label: "Skype", required: false, type: FormFieldType.Text }
  };
}
