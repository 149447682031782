import { NumericalId } from "@/datastructures/NumericalId";

import {
  PlanCatalogRow,
  PlanPayload
} from "@/office/interactors/PropertyPlanManager";
import { Plan } from "@/entities/Plan";

export class PropertyPlanViewer implements IPropertyPlanViewer {
  public constructor(private gateway: PropertyPlanViewerGateway) {}

  public loadPlanCatalog(propertyId: NumericalId): Promise<PlanCatalogRow[]> {
    return this.gateway.loadPlanCatalog(propertyId);
  }

  public loadPlan(planId: NumericalId): Promise<PlanPayload> {
    return this.gateway.loadPlan(planId);
  }

  public loadTicketPlan(
    ticketId: NumericalId,
    planId: NumericalId
  ): Promise<PlanPayload> {
    return this.gateway.loadTicketPlan(ticketId, planId);
  }
}

export interface IPropertyPlanViewer {
  loadPlanCatalog(propertyId: NumericalId): Promise<PlanCatalogRow[]>;
  loadPlan(planId: NumericalId): Promise<PlanPayload>;
  loadTicketPlan(
    ticketId: NumericalId,
    planId: NumericalId
  ): Promise<PlanPayload>;
}

export interface PropertyPlanViewerGateway {
  loadPlanCatalog(propertyId: NumericalId): Promise<PlanCatalogRow[]>;
  loadPlan(planId: NumericalId): Promise<PlanPayload>;
  loadTicketPlan(
    ticketId: NumericalId,
    planId: NumericalId
  ): Promise<PlanPayload>;
}
