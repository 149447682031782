import Vue from "vue";
import Vuex, { Store } from "vuex";
import createPersistedState from "vuex-persistedstate";

import InspectionStore, {
  InspectionState
} from "@/inspection/shared/redux/InspectionRedux";

import SelectedTicketsStore, {
  SelectedTicketsState
} from "@/inspection/shared/redux/SelectedTicketsRedux";

import PropertyStore, {
  PropertyState
} from "@/property/shared/redux/PropertyRedux";

type RootState = InspectionState & PropertyState & SelectedTicketsState;

Vue.use(Vuex);

export type BluebookRedux = Store<RootState>;

export default new Vuex.Store<RootState>({
  modules: {
    property: PropertyStore,
    inspection: InspectionStore,
    selectedTickets: SelectedTicketsStore
  },
  plugins: [createPersistedState()]
});
