import { DocumentListViewModel } from "../vms/DocumentListViewModel";
import { IDocumentListPresenter } from "../controllers/DocumentListController";
import { FormResponse } from "@/forms/FormResponse";
import { DocumentRow } from "../services/DocumentService";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { Authorizator } from "@/common/interactors/Authorizator";

export class DocumentListPresenter implements IDocumentListPresenter {
  public constructor(private vm: DocumentListViewModel) {}

  public init() {
    this.vm.uploadDocumentButtonVisible = Authorizator.canCreateDocuments();
    this.vm.editDocumentButtonVisible = Authorizator.canViewDocuments();
    this.vm.openDocumentButtonVisible = Authorizator.canViewDocuments();
    this.vm.goBack = !Authorizator.canListDocuments();
  }

  public get metumId() {
    return this.vm.metumId;
  }
  public set metumId(id: string) {
    this.vm.metumId = id;
  }

  public get subjectType() {
    return this.vm.subjectType;
  }
  public set subjectType(type: string) {
    this.vm.subjectType = type;
  }

  public get subjectId() {
    return this.vm.subjectId;
  }
  public set subjectId(id: string) {
    this.vm.subjectId = id;
  }

  public set currentPage(page: number) {
    this.vm.currentPage = page;
  }

  // Data
  public get documentsPerPage(): number {
    return this.vm.documentsPerPage;
  }

  public set linkToOpen(link: string) {
    this.vm.linkToOpen = link;
  }

  public set documentToOpen(id: string) {
    this.vm.documentToOpen = id;
  }

  public set uploadDocumentDialogVisible(visible: boolean) {
    this.vm.uploadDocumentDialogVisible = visible;
  }

  // Responses
  public set loadDocumentsResponse(
    response: FormResponse<PaginatedList<DocumentRow>>
  ) {
    this.vm.loadDocumentsRequest = response;

    if (response.success) {
      this.vm.documents = response.data.items.map(doc => ({
        id: doc.id.toString(),
        name: doc.name,
        link: doc.link
      }));

      this.vm.totalDocuments = response.data.totalCount;
    }
  }

  public set uploadDocumentResponse(response: FormResponse<number>) {
    this.vm.uploadDocument.loading = response.loading;
    this.vm.uploadDocument.error = response.error;

    if (response.success) {
      this.vm.uploadedDocument = response.data;
    }
  }
}
