import { PropertyModel } from "@/property/property/models/PropertyModel";
import { Dictionary } from "@/shared/datastructures/Dictionary";
import { Module, ActionContext } from "vuex";
import { Plant } from "../dtos/Plant";
import { Property } from "../dtos/Property";
import { PropertyReportContainer } from "../repositories/PropertyRepoContainer";

// TODO: Refactor ticket list view to remove this old implementation
import { LocalStorage } from "@/storage/LocalStorage";
import { storageKeys } from "@/data/storageKeys";
const localStore = new LocalStorage();

export interface PropertyState {
  selectedProperties: Property[];
  basePlants: Dictionary<Plant[]>;
}

type PropertyAction = ActionContext<PropertyState, PropertyState>;

const redux: Module<PropertyState, PropertyState> = {
  state: (): PropertyState => ({
    selectedProperties: [],
    basePlants: {}
  }),
  mutations: {
    addSelectedProperty(state: PropertyState, property: Property) {
      state.selectedProperties.push(property);

      // TODO: Refactor this
      if (state.selectedProperties.length === 1) {
        localStore.set(storageKeys.selectedProperty, property.id.toString());
        localStore.set(
          storageKeys.selectedPropertyData,
          JSON.stringify(property)
        );
      }
    },
    removeSelectedProperty(state: PropertyState, property: Property) {
      state.selectedProperties = state.selectedProperties.filter(
        p => p.id.toString() !== property.id.toString()
      );

      // TODO: Refactor this
      if (localStore.has(storageKeys.selectedProperty)) {
        const propId = localStore.get(storageKeys.selectedProperty).toString();
        if (property.id.toString() === propId) {
          localStore.remove(storageKeys.selectedProperty);
          localStore.remove(storageKeys.selectedPropertyData);

          if (state.selectedProperties.length > 0) {
            const nextProp = state.selectedProperties[0];
            localStore.set(
              storageKeys.selectedProperty,
              nextProp.id.toString()
            );
            localStore.set(
              storageKeys.selectedPropertyData,
              JSON.stringify(nextProp)
            );
          }
        }
      }
    },
    clearSelectedProperties(state: PropertyState) {
      state.selectedProperties = [];

      // TODO: Refactor this
      localStore.remove(storageKeys.selectedProperty);
      localStore.remove(storageKeys.selectedPropertyData);
    },

    setBasePlants(state: PropertyState, plants: Dictionary<Plant[]>) {
      state.basePlants = plants;
    },
    clearBasePlants(state: PropertyState) {
      state.basePlants = {};
    }
  },
  actions: {
    selectProperty(
      { dispatch, commit, state, getters }: PropertyAction,
      property: PropertyModel
    ) {
      if (!property) {
        return;
      }

      if (!getters.hasPropertySelected(property)) {
        commit("addSelectedProperty", property.toPropertyDto());
      } else {
        commit("removeSelectedProperty", property.toPropertyDto());
      }

      dispatch("loadBasePlantsOfProperties", state.selectedProperties);
    },

    unselectProperties({ commit }: PropertyAction) {
      commit("clearSelectedProperties");
      commit("clearBasePlants");
    },

    async loadBasePlantsOfProperties(
      { commit }: PropertyAction,
      selectedProperties: Property[]
    ) {
      const propertyIds = selectedProperties.map(p => p.id);
      const plants = await PropertyReportContainer.plantRepo.getBasePlants(
        propertyIds,
        true
      );

      const plantMap = plants.reduce((map, plant) => {
        if (!map[plant.type]) {
          map[plant.type] = [];
        }

        map[plant.type].push(plant);

        return map;
      }, {} as Dictionary<Plant[]>);

      commit("setBasePlants", plantMap);
    },

    clearProperty({ commit }: PropertyAction) {
      commit("clearSelectedProperties");
      commit("clearBasePlants");
    }
  },
  getters: {
    getPropertyById: state => (id: number) => {
      return state.selectedProperties.find(p => p.id === id);
    },
    getPropertyIdsOfPlantType: (_, getters) => (plantType: string) => {
      return getters
        .getPropertiesOfPlantType(plantType)
        .map((p: Property) => p.id);
    },
    getPropertiesOfPlantType: state => (plantType: string) => {
      const properties: Property[] = [];
      const plants = state.basePlants[plantType];

      if (plants) {
        for (const plant of plants) {
          const property = state.selectedProperties.find(
            p => p.id.toString() === plant.property.id?.toString()
          );

          if (property) {
            properties.push(property);
          }
        }
      }

      return properties;
    },
    getPropertyOfPlant: state => (plant: Plant) => {
      return state.selectedProperties.find(p => p.id === plant.property.id);
    },
    getSelectedProperties: state => state.selectedProperties,
    getFirstSelectedProperty: (state, getters) =>
      getters.hasOnePropertySelected ? state.selectedProperties[0] : undefined,
    hasPropertySelected: state => (property: PropertyModel) => {
      return state.selectedProperties.some(p => p.id === property.id);
    },
    hasOnePropertySelected: state => {
      return state.selectedProperties && state.selectedProperties.length > 0;
    },
    hasManyPropertiesSelected: state => {
      return state.selectedProperties && state.selectedProperties.length > 1;
    },
    hasExactlyOnePropertySelected: state => {
      return state.selectedProperties && state.selectedProperties.length === 1;
    },
    getPlantsOfType: state => (plantType: string) => {
      return state.basePlants[plantType];
    }
  }
};

export default redux;
