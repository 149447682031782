import { ServerResponse } from "@/shared/datastructures/Response";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { ChecklistEntity } from "../entities/ChecklistEntity";
import { InspectionRepoContainer } from "../repositories/InspectionRepoContainer";
import { Assignment } from "./Assignment";
import { ChecklistEntryCollection } from "./ChecklistEntryCollection";
import { Ticket } from "./Ticket";
import { Ticketable, TicketableType } from "./Ticketable";
import { TicketCollection } from "./TicketCollection";

export class Checklist extends Ticketable<ChecklistEntity> {
  public id?: number = 0;
  public protectionGoalNiveau?: string = "";
  public preparationDone?: boolean = false;
  public type?: string = "";
  public entryCollection?: ChecklistEntryCollection = new ChecklistEntryCollection();
  public propertyId?: number = 0;
  public looseTicketCollection?: TicketCollection = new TicketCollection();

  public assignment?: Assignment;

  public protectionGoalNiveauResponse = new ServerResponse<number>();

  public get exists() {
    return !!this.id;
  }

  public get entries() {
    return this.entryCollection?.entries ?? [];
  }

  public get label(): string {
    return this.assignment?.number ?? "Checkliste";
  }

  public get ticketableType(): TicketableType {
    return TicketableType.Checklist;
  }

  public addLooseTicket(ticket: Ticket) {
    this.looseTicketCollection?.add(ticket);
  }

  public setProtectionGoalNiveau(protectionGoalNiveau: string) {
    const prevValue = this.protectionGoalNiveau;
    this.protectionGoalNiveau = protectionGoalNiveau;

    return RequestHandler.handle(
      InspectionRepoContainer.checklistRepo.setProtectionGoalNiveau(
        this.id ?? 0,
        protectionGoalNiveau
      ),
      this.protectionGoalNiveauResponse,
      {
        onFailure: () => (this.protectionGoalNiveau = prevValue)
      }
    );
  }

  public toEntity(): ChecklistEntity {
    return {
      id: this.id,
      protectionGoalNiveau: this.protectionGoalNiveau,
      preparationDone: this.preparationDone,
      type: this.type,
      entries: this.entryCollection?.toEntities(),
      propertyId: this.propertyId,
      looseTickets: this.looseTicketCollection?.toEntities()
    };
  }

  public from(entity?: ChecklistEntity) {
    this.id = entity?.id;
    this.protectionGoalNiveau = entity?.protectionGoalNiveau;
    this.preparationDone = entity?.preparationDone;
    this.type = entity?.type;
    this.entryCollection = ChecklistEntryCollection.from(entity?.entries);
    this.propertyId = entity?.propertyId;
    this.looseTicketCollection = TicketCollection.from(entity?.looseTickets);
  }

  public static from(entity?: ChecklistEntity) {
    const checklist = new Checklist();
    checklist.from(entity);
    return checklist;
  }
}
