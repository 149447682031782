import { Form, FormFieldType } from "../Form";

export class PersonAddressForm extends Form {
  protected definition = {
    context: { type: FormFieldType.Selection, required: true },
    street: { type: FormFieldType.Text, required: true },
    zip: { type: FormFieldType.Text, required: true },
    city: { type: FormFieldType.Text, required: true },
    country: { type: FormFieldType.Selection, required: true }
  };
}
