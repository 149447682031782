import { MathUtils } from "./MathUtils";
import { v4 as uuidv4 } from "uuid";

export class StringUtils {
  private static alphabeth =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

  public static uuid(): string {
    return uuidv4();
  }

  public static randomString(length: number) {
    let result = "";

    for (let i = 0; i < length; i++) {
      result += StringUtils.alphabeth.charAt(
        Math.floor(Math.random() * StringUtils.alphabeth.length)
      );
    }

    return result;
  }

  public static capitalizeFirstLetter(str: string) {
    if (str.length === 0) {
      return "";
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  // public static formatPrice(price: number, decimals = 2) {
  //   const divisor = Math.pow(10, decimals);
  //   const priceString = (Math.round(price * divisor) / divisor)
  //     .toFixed(decimals)
  //     .replace(".", ",");

  //   return (
  //     "€ " + priceString.replace(/\B(?<!,\d*)(?=(\d{3})+(?!\d))/g, ".") + ",-"
  //   );
  // }

  public static formatNumber(value: string | number, decimals = 2) {
    let numberValue = 0;
    if (this.isString(value)) {
      numberValue = parseFloat(value as string);
    } else {
      numberValue = value as number;
    }

    return MathUtils.naiveRound(numberValue, decimals)
      .toFixed(decimals)
      .replace(".", ",");
  }

  public static isNotNullOrEmpty(value: string) {
    return value !== null && value !== undefined && value !== "";
  }

  public static isString(value: unknown) {
    return typeof value === "string" || value instanceof String;
  }
}
