
import Vue from "vue";
import Component from "vue-class-component";

import IconButton from "@/components/basic/IconButton.vue";
import RequestButton from "@/components/basic/RequestButton.vue";
import ErrorMessage from "@/components/basic/ErrorMessage.vue";

import Checkbox from "@/components/form/Checkbox.vue";

import PaginatedDataTable from "@/components/collection/new/PaginatedDataTable.vue";

import {
  PropertyListModel,
  tableHeaders
} from "../../models/PropertyListModel";
import { Authorizator } from "@/common/interactors/Authorizator";
import { PropertyModel } from "../../models/PropertyModel";
import {
  PlanCollection,
  PlanSelectionCacheStrategy
} from "@/property/shared/models/PlanCollection";
import { Property } from "@/property/shared/models/Property";

@Component<PropertyList>({
  components: {
    IconButton,
    RequestButton,
    ErrorMessage,
    Checkbox,
    PaginatedDataTable
  },
  props: {
    search: {
      type: String,
      default: ""
    }
  },
  watch: {
    search(search: string) {
      this.load(1);
    },
    selectedProperties() {
      this.init();
    }
  }
})
export default class PropertyList extends Vue {
  protected model = new PropertyListModel();
  protected search!: string;

  protected get headers() {
    return tableHeaders;
  }

  protected get selectedProperties() {
    return this.$store.state.property.selectedProperties;
  }

  protected get editPropertyButtonVisible() {
    return Authorizator.canEditProperties();
  }

  protected getSelectedPropertyButtonState(property: PropertyModel) {
    const onePropertySelected = this.$store.getters.hasOnePropertySelected;

    return {
      visible: Authorizator.canSelectProperties(),
      color: property.selected ? "accent" : "primary",
      confirm: !property.selected && !onePropertySelected,
      tooltipText: property.selected
        ? "Gebäude entwählen"
        : onePropertySelected
        ? "Zur Auswahl hinzufügen"
        : "Gebäude auswählen"
    };
  }

  protected mounted() {
    this.load();
  }

  protected async load(page = 1) {
    await this.model.load(page, this.search);
    this.init();
  }

  protected init() {
    this.model.selectProperties(this.selectedProperties);
  }

  protected goToProperty(id: string) {
    this.$router.push({ name: "property", params: { propertyId: id } });
  }

  protected async selectProperty(property: PropertyModel) {
    this.$store.dispatch("selectProperty", property);

    if (await this.model.selectProperty(property)) {
      const planCollection = new PlanCollection();
      await planCollection.loadSelectionOfMultipleProperties(
        this.model.selectedProperties.map(p =>
          Property.from({
            id: p.id
          })
        ),
        {
          cacheStrategy: PlanSelectionCacheStrategy.OnlyNetwork,
          onlyOffline: true
        }
      );
    }
  }
}
