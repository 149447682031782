import { Property } from "@/entities/Property";
import { NumericalId } from "@/datastructures/NumericalId";
import { Location } from "@/entities/Location";

export class PropertyManager implements IPropertyManager {
  public constructor(
    private gateway: PropertyGateway,
    private locationGateway: LocationGateway
  ) {}

  public createProperty(data: CreatePropertyData): Promise<number> {
    return this.loadLocation(data).then(location =>
      this.createPropertyWithLocation(data, location)
    );
  }

  public deleteProperty(id: NumericalId): Promise<number> {
    return this.gateway.deleteProperty(id).then(property => property.id);
  }

  public updateGeneralData(data: UpdatePropertyGeneralData): Promise<number> {
    return this.gateway.updateGeneralData(data).then(property => property.id);
  }

  public updateDetails(data: UpdatePropertyDetails): Promise<number> {
    return this.gateway.updateDetails(data).then(property => property.id);
  }

  public updateAddress(
    data: UpdatePropertyAddress
  ): Promise<PayloadPropertyLocation> {
    return this.loadLocation(data).then(location =>
      this.updateAddressWithLocation(data, location)
    );
  }

  public loadProperty(id: NumericalId): Promise<CreatePropertyData> {
    return this.gateway.loadProperty(id).then(property => ({
      id: property.id,
      addressId: property.address.id,
      name: property.name,
      description: property.description,
      legalForm: property.legalForm,
      cadastralCommunity: property.cadastralCommunity,
      assetNumber: property.assetNumber,
      buildYear: property.buildYear,
      parcelNumber: property.parcelNumber,
      singleCadastre: property.singleCadastre,
      effectiveArea: property.effectiveArea,
      dwsBaseNumber: property.dwsBaseNumber,
      unitCount: property.unitCount,
      street: property.address.street,
      zip: property.address.zip,
      city: property.address.city,
      country: property.address.country,
      lat: property.address.location.lat,
      lng: property.address.location.lng,
      imageId: property.image.id,
      imagePath: property.image.path,
      relationships: property.relationships.map(r => ({
        id: r.id,
        relationshipId: r.role,
        roleId: r.subRole,
        personId: r.person.id,
        firstname: r.person.firstname,
        lastname: r.person.lastname,
        street:
          r.person.addresses.length > 0 ? r.person.addresses[0].street : "",
        city: r.person.addresses.length > 0 ? r.person.addresses[0].city : "",
        zip: r.person.addresses.length > 0 ? r.person.addresses[0].zip : ""
      }))
    }));
  }

  public setPropertyImage(image: File, id: NumericalId) {
    return this.gateway.setPropertyImage(image, id);
  }

  public deletePropertyImage(imageId: NumericalId) {
    return this.gateway.deletePropertyImage(imageId);
  }

  public upsertRelationship(propertyId: NumericalId, data: RelationshipInput) {
    return this.gateway.upsertRelationship(propertyId, data);
  }

  public deleteRelationship(
    propertyId: NumericalId,
    relationshipId: NumericalId
  ): Promise<void> {
    return this.gateway.deleteRelationship(propertyId, relationshipId);
  }

  private loadLocation(data: UpdatePropertyAddress) {
    return this.locationGateway.loadLocation(
      data.street,
      data.zip,
      data.city,
      data.country
    );
  }

  private createPropertyWithLocation(
    data: CreatePropertyData,
    location: Location
  ) {
    data.lat = location.lat;
    data.lng = location.lng;
    return this.gateway.createProperty(data).then(response => response.id);
  }

  private updateAddressWithLocation(
    data: UpdatePropertyAddress,
    location: Location
  ) {
    data.lat = location.lat;
    data.lng = location.lng;
    return this.gateway.updateAddress(data).then(response => ({
      id: response.id,
      lat: location.lat,
      lng: location.lng
    }));
  }
}

export interface IPropertyManager {
  createProperty(data: CreatePropertyData): Promise<number>;
  deleteProperty(id: NumericalId): Promise<number>;
  loadProperty(id: NumericalId): Promise<CreatePropertyData>;

  setPropertyImage(image: File, id: NumericalId): Promise<PropertyImage>;
  deletePropertyImage(imageId: NumericalId): Promise<void>;

  updateGeneralData(data: UpdatePropertyGeneralData): Promise<number>;
  updateDetails(data: UpdatePropertyDetails): Promise<number>;
  updateAddress(data: UpdatePropertyAddress): Promise<PayloadPropertyLocation>;
  upsertRelationship(
    propertyId: NumericalId,
    data: RelationshipInput
  ): Promise<number>;
  deleteRelationship(
    propertyId: NumericalId,
    relationshipId: NumericalId
  ): Promise<void>;
}

export interface PropertyGateway {
  createProperty(data: CreatePropertyData): Promise<Property>;
  deleteProperty(id: NumericalId): Promise<Property>;
  loadProperty(id: NumericalId): Promise<Property>;

  setPropertyImage(image: File, id: NumericalId): Promise<PropertyImage>;
  deletePropertyImage(imageId: NumericalId): Promise<void>;

  updateGeneralData(data: UpdatePropertyGeneralData): Promise<Property>;
  updateDetails(data: UpdatePropertyDetails): Promise<Property>;
  updateAddress(data: UpdatePropertyAddress): Promise<Property>;
  upsertRelationship(
    propertyId: NumericalId,
    data: RelationshipInput
  ): Promise<number>;
  deleteRelationship(
    propertyId: NumericalId,
    relationshipId: NumericalId
  ): Promise<void>;
}

export interface LocationGateway {
  loadLocation(
    street: string,
    zip?: string,
    city?: string,
    country?: string
  ): Promise<Location>;
}

export interface CreatePropertyData {
  id?: number;
  addressId?: number;
  name: string;
  description: string;
  legalForm: string;
  cadastralCommunity: string;
  assetNumber: string;
  buildYear: number;
  parcelNumber: string;
  singleCadastre: boolean;
  effectiveArea: number;
  dwsBaseNumber: string;
  unitCount: number;
  street: string;
  zip: string;
  city: string;
  country: string;
  lat: number;
  lng: number;
  imageId: number;
  imagePath: string;
  clientId?: number;
  relationships: RelationshipInput[];
}

export interface RelationshipInput {
  id: number;
  relationshipId: string;
  roleId: string;
  personId: number;
  firstname?: string;
  lastname?: string;
  city?: string;
  zip?: string;
  street?: string;
}

export interface PropertyImage {
  id: number;
  path: string;
}

export interface UpdatePropertyGeneralData {
  id?: number;
  name: string;
  description: string;
}

export interface UpdatePropertyDetails {
  id?: number;
  legalForm: string;
  cadastralCommunity: string;
  assetNumber: string;
  buildYear: number;
  parcelNumber: string;
  singleCadastre: boolean;
  effectiveArea: number;
  unitCount: number;
  dwsBaseNumber: string;
}

export interface UpdatePropertyAddress {
  id?: number;
  addressId?: number;
  street: string;
  zip: string;
  city: string;
  country: string;
  lat?: number;
  lng?: number;
}

export interface PayloadPropertyLocation {
  id: number;
  lat: number;
  lng: number;
}
