
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import ReportDataAssistent, {
  ReportDataAssistentStep
} from "./widgets/ReportDataAssistent.vue";

import { Report } from "../shared/models/Report";
import { Translator } from "@/shared/i18n/Translator";

@Component<ReportPage>({
  components: { ReportDataAssistent }
})
export default class ReportPage extends Vue {
  @Prop({ required: true }) protected readonly assignmentId!: string;
  @Prop({ required: true }) protected readonly reportType!: string;

  protected report: Report = new Report();

  protected prevStepButtonDisabled = false;
  protected nextStepButtonDisabled = false;

  protected get pageTitle() {
    return Translator.t(this.reportType) + " bearbeiten";
  }

  protected get nextStepButtonIcon() {
    return this.nextStepButtonDisabled ? "done" : "chevron_right";
  }

  protected get nextStepButtonText() {
    return this.nextStepButtonDisabled ? "Speichern" : "Weiter";
  }

  protected prevDataStep() {
    (this.$refs.reportData as ReportDataAssistent).prevStep();
  }

  protected nextDataStep() {
    (this.$refs.reportData as ReportDataAssistent).nextStep();
  }

  protected updateStepperControls(step: ReportDataAssistentStep) {
    this.prevStepButtonDisabled = !step.hasPrevStep;
    this.nextStepButtonDisabled = !step.hasNextStep;
  }

  protected downloadReport() {
    if (this.report.hasFile) {
      window.open(
        this.report.file!.path + "?nocache=" + new Date().getTime(),
        "_blank"
      );
    }
  }

  protected mounted() {
    this.init();
    this.load();
  }

  protected init() {
    this.report.init({
      assignmentId: parseInt(this.assignmentId, 10),
      reportType: this.reportType
    });
  }

  protected load() {
    this.report.load();
  }
}
