import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";
import { UserSettings } from "@/storage/UserSettings";

export interface ReportDataOtherFormData {
  missingChecksPreface: string;
  missingChecksTable: string[];
  missingChecksEpilog: string;
  annex: string;
}

export class ReportDataOtherForm extends Form<ReportDataOtherFormData> {
  protected definition = {
    missingChecksPreface: {
      label: "Noch durchzuführende Kontrollen (Vorwort)",
      type: FormFieldType.Text,
      required: true
    },
    missingChecksTable: {
      label: "Noch durchzuführende Kontrollen",
      type: FormFieldType.MultiSelect,
      required: false,
      itemDefinition: {
        items: () =>
          UserSettings.getReportContent().missing_checks_table.map(
            (check: any) => ({
              text: check.label,
              value: check.label + "/" + check.permission
            })
          )
      }
    },
    missingChecksEpilog: {
      label: "Noch durchzuführende Kontrollen (Nachwort)",
      type: FormFieldType.Text,
      required: true
    },
    annex: { label: "Annex", type: FormFieldType.Text, required: true }
  };
}
