
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { ChecklistEntry } from "@/inspection/shared/models/ChecklistEntry";
import { InspectionSelectionItemLoader } from "@/inspection/shared/dtos/InspectionSelectionItemLoader";
import { UserSettings } from "@/storage/UserSettings";

@Component<ChecklistEntryStateSelect>({
  components: {}
})
export default class ChecklistEntryStateSelect extends Vue {
  @Prop({ type: Object, default: () => new ChecklistEntry() })
  protected readonly entry!: ChecklistEntry;
  @Prop() protected readonly checklistType?: string;
  @Prop({ type: Boolean, default: false })
  protected readonly hideTitle!: boolean;

  protected get inspectionStateItems() {
    // TODO: Rethink after general state is implemented
    const items = InspectionSelectionItemLoader.loadChecklistInspectionStates(
      UserSettings.getClientId()
    );

    if (this.entry.hasTicketsInChildren) {
      const clearItem = items.find(i => i.value === "clear");
      if (clearItem) {
        clearItem.disabled = true;
      }
    }

    return items;
  }
}
