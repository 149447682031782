import { NumericalId } from "@/datastructures/NumericalId";
import {
  SettingsManagerDataGateway,
  SettingPayload
} from "@/admin/interactors/SettingsManager";
import { SettingMeta } from "./SettingMeta";
import { ArrayUtils } from "@/utils/ArrayUtils";
import { Client } from "./Client";

export class Setting {
  public static loadGlobally(key: string, gateway: SettingsManagerDataGateway) {
    return this.load(key, NumericalId.invalidId(), gateway);
  }

  public static async load(
    key: string,
    clientId: NumericalId,
    gateway: SettingsManagerDataGateway
  ) {
    let settingData: SettingPayload | undefined;

    if (clientId.valid) {
      try {
        settingData = await gateway.loadSetting(key, clientId);
        settingData.entry = settingData.entry.replace(/\n/g, "\\n");
      } catch (e) {
        settingData = undefined;
      }
    }

    let globalSettingData: SettingPayload;
    try {
      globalSettingData = await gateway.loadGlobalSetting(key);
    } catch (e) {
      globalSettingData = {
        id: 0,
        key,
        entry: "{}",
        variables: "{}"
      };
    }
    globalSettingData.entry = globalSettingData.entry.replace(/\n/g, "\\n");
    const meta = await SettingMeta.load(key, gateway);

    let setting: Setting;

    if (!settingData) {
      if (clientId.valid) {
        setting = new Setting(0);
        setting.default = JSON.parse(globalSettingData.entry);
      } else {
        setting = new Setting(globalSettingData.id);
        setting.entry = JSON.parse(globalSettingData.entry);
      }
      setting.key = globalSettingData.key;
    } else {
      setting = new Setting(settingData.id);
      setting.key = settingData.key;
      setting.entry = JSON.parse(settingData.entry);
      setting.variables = JSON.parse(settingData.variables);
      setting.default = JSON.parse(globalSettingData.entry);
    }

    setting.meta = meta;

    return setting;
  }

  public key: string = "";
  public default?: any;
  public entry: any = {};
  public variables: any = {};
  public client: Client = new Client(0, "");
  public meta: SettingMeta = new SettingMeta(0);

  public constructor(public id: number) {}

  public saveGlobalSetting(
    input: GlobalSettingInput,
    gateway: SettingsManagerDataGateway
  ) {
    return this.saveSetting(input, gateway);
  }

  public saveClientSetting(
    input: ClientSettingInput,
    gateway: SettingsManagerDataGateway
  ) {
    return this.saveSetting(input, gateway);
  }

  public get combinedEntry() {
    const combinedFields: any = {};
    for (const [key, value] of Object.entries(this.default)) {
      combinedFields[key] = this.resolveField(value, this.entry[key]);
    }
    return JSON.stringify(combinedFields);
  }

  private saveSetting(
    input: GlobalSettingInput | ClientSettingInput,
    gateway: SettingsManagerDataGateway
  ) {
    this.key = input.key;
    this.variables = JSON.stringify(input.variables);
    this.entry = JSON.stringify(input.settings);
    this.client = new Client(input.clientId.id, "");

    return gateway.saveSetting(this);
  }

  private resolveField(value: any, clientValue: any) {
    const isArray = ArrayUtils.isArray(value);

    if (!isArray) {
      return clientValue || value;
    } else {
      let filteredValues = value;

      if (clientValue) {
        const valuesToDeactivate = clientValue.filter((v: any) => !isNaN(v));
        const valuesToAdd = clientValue.filter((v: any) => isNaN(v));

        filteredValues = filteredValues.filter(
          (v: any, i: number) => !valuesToDeactivate.includes(i)
        );
        filteredValues.push(...valuesToAdd);
      }

      return filteredValues;
    }
  }
}

export interface SettingInput {
  key: string;
  variables: VariableList;
  clientId: NumericalId;
}

interface VariableList {
  [key: string]: string;
}

export interface GlobalSettingInput extends SettingInput {
  settings: GlobalSettingList;
}

export interface GlobalSettingList {
  [key: string]: GlobalSettingElement;
}
type GlobalSettingElement = string | GlobalSettingList[];

export interface ClientSettingInput extends SettingInput {
  settings: ClientSettingList;
}

export type ClientSettingList = ClientSettingListExtension | number | string;

interface ClientSettingListExtension {
  [key: string]: ClientSettingElement;
}

type ClientSettingElement = string | ClientSettingList[];
