import { ReportManagerGateway } from "@/manager/interactors/ReportManger";
import { NumericalId } from "@/datastructures/NumericalId";
import { Assignment } from "./Assignment";
import { DateUtils } from "@/utils/DateUtils";

export class Report {
  public static async load(id: NumericalId, gateway: ReportManagerGateway) {
    const data = await gateway.loadReport(id);
    const report = new Report(data.id);

    report.name = data.name;
    report.date = new Date(data.date);
    report.issueDate = new Date(data.issueDate);
    report.latestAlternationDate = new Date(data.latestAlternationDate);
    report.creator = data.creator;
    report.approvedBy = data.approvedBy;
    report.client = data.client;
    report.content = data.content;

    return report;
  }

  public name: string = "";
  public date: Date = new Date();
  public issueDate: Date = new Date();
  public latestAlternationDate: Date = new Date();
  public creator: string = "";
  public approvedBy: string = "";
  public client: string = "";

  public content: string = "";

  public assignment: Assignment = new Assignment(0);

  public constructor(public id: number) {}

  public saveReport(data: ReportInput, gateway: ReportManagerGateway) {
    const contentObject = {
      editorialTeam: data.editorialTeam,
      editorialDeadline: DateUtils.format(data.editorialDeadline),
      client: data.client,
      impressumClient: data.impressumClient,
      authors: data.authors,
      allAuthors: data.allAuthors,
      advice: data.advice,

      applicationField: data.applicationField,
      guidelines: data.guidelines,
      documents: data.documents,
      commissionBasis: data.commissionBasis,
      conditionRegardingLocation: data.conditionRegardingLocation,
      propertyDescription: data.propertyDescription,

      missingChecksPreface: data.missingChecksPreface,
      missingChecksTable: data.missingChecksTable,
      missingChecksEpilog: data.missingChecksEpilog,
      annex: data.annex
    };

    const content = JSON.stringify(contentObject);

    this.name = data.name;
    this.date = data.date;
    this.issueDate = data.issueDate;
    this.latestAlternationDate = data.latestAlternationDate;
    this.creator = data.creator;
    this.approvedBy = data.approvedBy;
    this.client = data.client;
    this.content = content;
    this.assignment = new Assignment(data.assignmentId.id);

    return gateway.upsertReport(this);
  }
}

export interface ReportInput {
  id?: NumericalId;
  assignmentId: NumericalId;

  name: string;
  date: Date;
  issueDate: Date;
  latestAlternationDate: Date;
  creator: string;
  approvedBy: string;
  client: string;

  editorialTeam: string;
  editorialDeadline: Date;
  impressumClient: string;
  authors: string;
  allAuthors: string;
  advice: string;

  applicationField: string;
  guidelines: string;
  documents: string;
  commissionBasis: string;
  conditionRegardingLocation: string;
  propertyDescription: string;

  missingChecksPreface: string;
  missingChecksTable: string[];
  missingChecksEpilog: string;
  annex: string;
}

export interface ReportData {
  id: number;
  name: string;
  date: string;
  issueDate: string;
  latestAlternationDate: string;
  creator: string;
  approvedBy: string;
  client: string;
  content: string;
}
