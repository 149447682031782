
import Vue from "vue";
import Component from "vue-class-component";

import { Prop, Watch } from "vue-property-decorator";

import { Assignment } from "../../shared/models/Assignment";
import { AssignmentForm as Form } from "../forms/AssignmentForm";
import { PlantCollection } from "@/property/shared/models/PlantCollection";
import { DateUtils } from "@/shared/utils/DateUtils";

@Component<AssignmentForm>({
  components: {}
})
export default class AssignmentForm extends Vue {
  @Prop({ type: Object, default: () => new PlantCollection() })
  protected readonly checklistPlants!: PlantCollection;
  @Prop({ type: Object, default: () => new Assignment() })
  protected readonly prevAssignment!: Assignment;

  public assignment = new Assignment();
  protected form = new Form();

  @Watch("checklistPlants")
  protected onChecklistPlantsChanged() {
    this.init();
  }

  @Watch("prevAssignment")
  protected onPrevAssignmentChanged() {
    this.init();
  }

  protected get prevAssignmentDescription() {
    return `Folgebegehung des Auftrags '${
      this.prevAssignment.number
    }' vom ${DateUtils.format(this.prevAssignment.date ?? new Date())}`;
  }

  protected fieldChanged(fieldName: string, value: unknown) {
    this.form.setFieldValue(fieldName, value);
    this.assignment.data = this.form.getData();
  }

  protected async upsertAssignment() {
    if (await this.assignment.upsert()) {
      const id = this.assignment.id?.toString() ?? "";
      const successfullyLoaded = await this.assignment.load({
        includeChecklist: true
      });

      if (successfullyLoaded && this.assignment.prepared) {
        this.$router.push({
          name: "inspection",
          params: { assignmentId: id }
        });
      } else {
        this.$router.push({
          name: "prepare-checklist",
          params: { assignmentId: id }
        });
      }
    }
  }

  protected async created() {
    await this.form.init();
    this.init();
  }

  protected init() {
    this.assignment.checklistPlants = this.checklistPlants;
    this.assignment.followUp(this.prevAssignment);
  }
}
