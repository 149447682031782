
import Vue from "vue";
import Component from "vue-class-component";

import SearchSelect from "@/components/form/SearchSelect.vue";

import { ClientSelectVm } from "../models/ClientSelectVm";
import { ClientModel } from "@/property/client/models/ClientModel";

@Component<ClientSelect>({
  components: {
    SearchSelect
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class ClientSelect extends Vue {
  private vm = new ClientSelectVm();

  private mounted() {
    this.load();
  }

  private load() {
    this.vm.load();
  }

  private emitSelect(client: ClientModel) {
    this.$emit("select", client);
  }
}
