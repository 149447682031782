
import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import { SidebarModel } from "@/shared/layout/models/SidebarModel";
import { Plant } from "@/property/shared/dtos/Plant";
import { Property } from "@/property/shared/dtos/Property";

@Component<SideBar>({
  components: {
    Row,
    Column,
    Container
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    selectedProperties(properties: Property[]) {
      this.init();
    },
    basePlants(plants: Plant[]) {
      this.init();
    }
  }
})
export default class SideBar extends Vue {
  private model = new SidebarModel();
  private minimized = false;

  private get selectedProperties() {
    return this.$store.state.property.selectedProperties;
  }

  private get basePlants() {
    return this.$store.state.property.basePlants;
  }

  private get mini(): boolean {
    return this.minimized && !this.isMobile;
  }
  private set mini(newValue: boolean) {
    this.minimized = newValue;
  }

  private get isMobile(): boolean {
    return !this.$vuetify.breakpoint.mdAndUp;
  }

  private mounted() {
    this.init();
  }

  private init() {
    this.model.init(this.selectedProperties, this.basePlants);
  }

  private toggleMini() {
    this.mini = !this.mini;
    this.$props.items.forEach((item: any) => (item.open = false));
  }

  private emitSelected(routeName: string, params?: any, query?: any) {
    this.$router.push({ name: routeName, params, query });
    // this.$emit("selected", routeName);
  }
}
