
import Vue from "vue";
import Component from "vue-class-component";

import BasicIconMessage from "./BasicIconMessage.vue";

@Component<ErrorMessage>({
  components: {
    BasicIconMessage
  },
  props: {
    icon: {
      type: String,
      default: "error_outline"
    },
    buttonText: {
      type: String,
      default: "Neu laden"
    }
  }
})
export default class ErrorMessage extends Vue {
  private emitReload() {
    this.$emit("reload");
  }
}
