import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { GraphQLConnection } from "@/gateways/graphql/connection/GraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { MorphTypeConverter } from "@/shared/utils/MorphTypeConverter";
import { CommentEntity } from "../../entities/CommentEntity";
import { Commentable, CommentableType } from "../../models/Comment";

interface GraphQlComment {
  id?: string;
  created_at?: string;
  text?: string;
  commentable_id?: string;
  commentable_type?: string;
  user_id?: string;
  user_name?: string;
  guest_id?: string;
  impersonated_user_id?: string;
  impersonated_user_name?: string;
  edited?: boolean;
  internal?: boolean;
}

export class CommentGraphQlProvider {
  public constructor(
    private connection: GraphQLConnection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    )
  ) {}

  public async loadComments(
    commentable: Commentable
  ): Promise<CommentEntity[]> {
    const result = await this.connection.query(
      "comments",
      {
        commentable_id: commentable.id,
        commentable_type: MorphTypeConverter.convertToServer(commentable.type)
      },
      [
        "id",
        "created_at",
        "text",
        "commentable_id",
        "commentable_type",
        "user_id",
        "user_name",
        "guest_id",
        "impersonated_user_id",
        "impersonated_user_name",
        "edited",
        "internal"
      ]
    );

    return this.convertGraphQlToCommentEntities(result.data);
  }

  public async addComment(comment: CommentEntity): Promise<CommentEntity> {
    const result = await this.connection.mutation(
      "addComment",
      {
        input: {
          text: comment.text,
          name: comment.creator?.person?.firstname,
          guest_id: comment.creator?.guestId,
          commentable_id: comment.commentable?.id,
          commentable_type: MorphTypeConverter.convertToServer(
            comment.commentable?.type
          ),
          impersonated_user_id: comment.impersonatedUser?.id,
          internal: comment.internal
        }
      },
      [
        "id",
        "created_at",
        "text",
        "commentable_id",
        "commentable_type",
        "user_id",
        "user_name",
        "guest_id",
        "impersonated_user_id",
        "impersonated_user_name",
        "internal"
      ]
    );

    return this.convertGraphQlToCommentEntity(result.data);
  }

  public async updateComment(comment: CommentEntity): Promise<CommentEntity> {
    const result = await this.connection.mutation(
      "updateComment",
      {
        input: {
          id: comment.id,
          commentable_id: comment.commentable?.id,
          commentable_type: MorphTypeConverter.convertToServer(
            comment.commentable?.type
          ),
          guest_id: comment.creator?.guestId,
          text: comment.text
        }
      },
      ["id"]
    );

    return this.convertGraphQlToCommentEntity(result.data);
  }

  public async changeInternalStateOfComment(
    comment: CommentEntity,
    internal: boolean
  ): Promise<CommentEntity> {
    const result = await this.connection.mutation(
      "changeCommentState",
      {
        input: {
          id: comment.id,
          commentable_id: comment.commentable?.id,
          commentable_type: MorphTypeConverter.convertToServer(
            comment.commentable?.type
          ),
          internal
        }
      },
      ["id", "internal"]
    );

    return this.convertGraphQlToCommentEntity(result.data);
  }

  public async deleteComment(comment: CommentEntity): Promise<CommentEntity> {
    const result = await this.connection.mutation(
      "deleteComment",
      {
        input: {
          id: comment.id,
          guest_id: comment.creator?.guestId,
          commentable_id: comment.commentable?.id,
          commentable_type: MorphTypeConverter.convertToServer(
            comment.commentable?.type
          )
        }
      },
      ["id"]
    );

    return this.convertGraphQlToCommentEntity(result.data);
  }

  private convertGraphQlToCommentEntities(
    data?: GraphQlComment[]
  ): CommentEntity[] {
    if (!data) {
      return [];
    }

    return data.map(c => this.convertGraphQlToCommentEntity(c));
  }

  private convertGraphQlToCommentEntity(data?: GraphQlComment): CommentEntity {
    if (!data) {
      return {};
    }

    return {
      id: data.id ? parseInt(data.id, 10) : undefined,
      date: data.created_at ? new Date(data.created_at) : undefined,
      text: data?.text,
      commentable: {
        id: data.commentable_id ? parseInt(data.commentable_id, 10) : undefined,
        type: MorphTypeConverter.convertToClient(
          data.commentable_type
        ) as CommentableType
      },
      creator: {
        id: data.user_id ? parseInt(data.user_id, 10) : undefined,
        guestId: data.guest_id,
        person: {
          firstname: data.user_name
        }
      },
      impersonatedUser: {
        id: data.impersonated_user_id
          ? parseInt(data.impersonated_user_id, 10)
          : undefined,
        person: {
          firstname: data.impersonated_user_name
        }
      },
      edited: data.edited,
      internal: data.internal
    };
  }
}
