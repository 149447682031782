import { StringUtils } from "@/shared/utils/StringUtils";
import { PropertySelectionItemLoader } from "../dtos/PropertySelectionItemLoader";
import {
  PlantFieldData,
  PlantFieldEntity,
  PlantFieldType
} from "../entities/PlantEntity";
import { PlantTypeField } from "../settings/PlantTypeSettings";

export class PlantField {
  public id?: string = "";
  public name?: string = "";
  public key?: string = "";
  public type?: PlantFieldType;
  public required?: boolean = false;
  public items?: string[] = [];

  public linkedWithType?: boolean = false;

  public updateData(data?: PlantFieldData) {
    if (!this.id) {
      this.id = StringUtils.uuid();
    }
    this.name = data?.name;
    this.key = data?.key;
    this.type = data?.type;
    this.required = data?.required;
    this.items = data?.items;
  }

  public copy() {
    return PlantField.from(this.toEntity());
  }

  public toEntity(): PlantFieldEntity {
    return {
      id: this.id,
      name: this.name,
      key: this.key,
      type: this.type,
      required: this.required,
      linkedWithType: this.linkedWithType,
      items: this.items
    };
  }

  public from(entity?: PlantFieldEntity) {
    this.id = entity?.id ?? "";
    this.name = entity?.name ?? "";
    this.key = entity?.key ?? "";
    this.type = entity?.type ?? "string";
    this.required = entity?.required ?? false;
    this.linkedWithType = entity?.linkedWithType ?? false;
    this.items = entity?.items ?? [];
  }

  public fromType(type?: PlantTypeField) {
    this.id = type?.id ?? "";
    this.name = type?.label ?? "";
    this.key = type?.key ?? "";
    this.type = type?.type ?? "string";
    this.required = type?.required || false;
    this.linkedWithType = true;
    this.items = type?.options?.map(o => o.label ?? "");
  }

  public static from(entity?: PlantFieldEntity) {
    const field = new PlantField();
    field.from(entity);
    return field;
  }

  public static fromType(type?: PlantTypeField) {
    const field = new PlantField();
    field.fromType(type);
    return field;
  }

  public static typeSelectItems() {
    return PropertySelectionItemLoader.loadPlantFieldTypes();
  }
}
