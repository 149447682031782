import { InspectionRepoContainer } from "@/inspection/shared/repositories/InspectionRepoContainer";
import { ServerResponse } from "@/shared/datastructures/Response";
import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { File } from "../file/File";
import { Fileable } from "../file/Fileable";
import { ImageEntity } from "./ImageEntity";

export class Image extends RequestModel<ImageEntity> {
  public file?: File = new File();
  public pathBig?: string = "";
  public pathMedium?: string = "";
  public pathSmall?: string = "";
  public pathThumbnail?: string = "";

  public uploadResponse = new ServerResponse<number>();
  public deleteResponse = new ServerResponse<number>();

  public get exists() {
    return this.file?.exists ?? false;
  }

  public get id() {
    return this.file?.id ?? 0;
  }
  public set id(id: number) {
    if (!this.file) {
      this.file = new File();
    }

    this.file.id = id;
  }

  public get syncId() {
    return this.file?.syncId ?? "";
  }

  public get name() {
    return this.file?.name ?? "";
  }

  public get hasUrlPath() {
    return this.path.startsWith("http");
  }

  public get path() {
    return this.file?.path ?? "";
  }
  public set path(path: string) {
    if (!this.file) {
      this.file = new File();
    }

    this.file.path = path;
  }

  public get imageable() {
    return this.file?.fileable ?? new Fileable();
  }
  public set imageable(fileable: Fileable) {
    if (!this.file) {
      this.file = new File();
    }

    this.file.fileable = fileable;
  }

  public get context() {
    return this.file?.context ?? "";
  }

  public clear() {
    this.file?.clear();
    this.pathBig = "";
    this.pathMedium = "";
    this.pathSmall = "";
    this.pathThumbnail = "";
  }

  public equals(image: Image) {
    return image.id === this.id && image.syncId === this.syncId;
  }

  public upload(cached = false) {
    if (cached && this.exists) {
      return true;
    }

    const repo = cached
      ? InspectionRepoContainer.cachedTicketRepo
      : InspectionRepoContainer.ticketRepo;

    return RequestHandler.handle(
      repo.uploadImage(this.toEntity()),
      this.uploadResponse,
      {
        onSuccess: id => (this.id = id)
      }
    );
  }

  public toEntity(): ImageEntity {
    return {
      ...this.file?.toEntity(),
      pathBig: this.pathBig,
      pathMedium: this.pathMedium,
      pathSmall: this.pathSmall,
      pathThumbnail: this.pathThumbnail
    };
  }

  public from(entity?: ImageEntity) {
    this.file = File.from(entity);
    this.pathBig = entity?.pathBig;
    this.pathMedium = entity?.pathMedium;
    this.pathSmall = entity?.pathSmall;
    this.pathThumbnail = entity?.pathThumbnail;
  }

  public static from(entity?: ImageEntity) {
    const instance = new Image();
    instance.from(entity);
    return instance;
  }
}
