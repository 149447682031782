import { websiteRegex } from "@/data/regex";
import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { PlanEntity } from "../entities/PlanEntity";
import { PropertyReportContainer } from "../repositories/PropertyRepoContainer";

export class Plan extends RequestModel<PlanEntity> {
  public id?: number = 0;
  public name?: string = "";
  public offline?: boolean = false;
  public filePath?: string = "";

  public get exists() {
    return !!this.id;
  }

  public get hasFile() {
    return !!this.filePath;
  }

  public get isLink() {
    return websiteRegex.test(this.filePath ?? "");
  }

  public load() {
    if (!this.exists) {
      return false;
    }

    return RequestHandler.handleModel(
      () => PropertyReportContainer.planRepo.getPlan(this.id ?? 0),
      this
    );
  }

  public toEntity() {
    return {
      id: this.id,
      name: this.name,
      offline: this.offline,
      filePath: this.filePath
    };
  }

  public from(plan?: PlanEntity) {
    this.id = plan?.id;
    this.name = plan?.name;
    this.offline = plan?.offline;
    this.filePath = plan?.filePath;
  }

  public static from(plan?: PlanEntity) {
    const p = new Plan();
    p.from(plan);
    return p;
  }
}
