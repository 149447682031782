import { Employee } from "@/property/shared/models/Employee";
import { UserSessionProvider } from "./UserSessionProvider";

export interface UserRepository {
  isImpersonated(): Promise<boolean>;
  getImpersonatedUser(): Promise<Employee | undefined>;
}

export class DefaultUserRepository implements UserRepository {
  public constructor(private provider = new UserSessionProvider()) {}

  public async isImpersonated(): Promise<boolean> {
    return this.provider.isImpersonated();
  }
  public async getImpersonatedUser(): Promise<Employee | undefined> {
    return this.provider.getImpersonatedUser();
  }
}
