import { Form, FormFieldType } from "../Form";
import { emailRegex, websiteRegex } from "@/data/regex";

export class PersonContactForm extends Form {
  protected definition = {
    email: {
      type: FormFieldType.Text,
      required: false,
      regex: emailRegex,
      error: "invalid-email"
    },
    website: {
      type: FormFieldType.Text,
      required: false,
      regex: websiteRegex,
      error: "invalid-website"
    }
  };
}
