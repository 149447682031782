import { TableHeader, Request } from "@/forms/ViewModelFormTypes";

export class SettingsListViewModel {
  // Routes
  public selectedGlobalSetting = "";
  public selectedClientSetting = "";
  public goBack: boolean = false;

  // Data
  public settings: SettingViewModel[] = [];
  public settingsPerPage = 20;
  public totalSettings = 20;
  public currentPage = 1;

  // States
  public editSettingsGloballyButtonVisible: boolean = false;
  public editSettingsButtonVisible: boolean = false;

  // Requests
  public loadSettingsRequest: Request = { loading: false, error: "" };

  // Table headers
  public settingsTableHeaders: TableHeader[] = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "center"
    }
  ];
  public settingsTableHeadersTablet: TableHeader[] = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "center"
    }
  ];
  public settingsTableHeadersMobile: TableHeader[] = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "center"
    }
  ];
}

interface SettingViewModel {
  id: string;
  key: string;
  name: string;
  overwritable: boolean;
}
