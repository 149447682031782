
import Vue from "vue";
import Component from "vue-class-component";

import Column from "@/components/layout/Column.vue";
import Row from "@/components/layout/Row.vue";

import { UserMenuModel } from "@/shared/layout/models/UserMenuModel";

@Component<UserMenu>({
  components: {
    Column,
    Row
  },
  props: {
    userName: {
      type: String,
      default: ""
    },
    roleName: {
      type: String,
      default: "Admin"
    },
    clientName: {
      type: String,
      default: ""
    }
  }
})
export default class UserMenu extends Vue {
  private model = new UserMenuModel();
}
