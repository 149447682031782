
import Vue from "vue";
import Component from "vue-class-component";

import { DocumentListViewModel } from "@/document/vms/DocumentListViewModel";
import { DocumentListController } from "@/document/controllers/DocumentListController";
import { DocumentListPresenter } from "@/document/presenters/DocumentListPresenter";

import Page from "@/components/layout/Page.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Section from "@/components/layout/Section.vue";
import Grid from "@/components/layout/Grid.vue";
import FullScreenDialog from "@/components/layout/FullScreenDialog.vue";
import TruncatedText from "@/components/layout/TruncatedText.vue";
import Dialog from "@/components/layout/Dialog.vue";

import PaginatedDatatable from "@/components/collection/PaginatedDatatable.vue";
import ResponsiveIconButton from "@/components/basic/ResponsiveIconButton.vue";
import SubmitTextfield from "@/components/basic/SubmitTextfield.vue";
import IconButton from "@/components/basic/IconButton.vue";

import FileInput from "@/components/form/FileInput.vue";

import { AxiosGraphQLConnection } from "../../gateways/graphql/connection/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { GraphQLDocumentService } from "../services/DocumentService";
import { UserSettings } from "../../storage/UserSettings";
import { LocalStorage } from "../../storage/LocalStorage";

@Component<DocumentListView>({
  components: {
    Page,
    Section,
    Grid,
    Row,
    Column,
    PaginatedDatatable,
    ResponsiveIconButton,
    IconButton,
    TruncatedText,
    Dialog,
    FileInput
  },
  watch: {
    "vm.goBack"(go: boolean) {
      if (go) {
        this.$router.back();
      }
    },
    "vm.documentToOpen"(documentId: string) {
      this.$router.push({ name: "document", params: { documentId } });
    },
    "vm.linkToOpen"(link: string) {
      window.open(link, "_blank");
    },
    "vm.uploadedDocument"(id: number) {
      if (id > 0) {
        this.$router.push({
          name: "document",
          params: { documentId: id.toString() }
        });
      }
    }
  }
})
export default class DocumentListView extends Vue {
  private vm = new DocumentListViewModel();

  private get metumId() {
    return this.$route.params.metumId;
  }

  private get subjectType() {
    return this.$route.params.subjectType;
  }

  private get subjectId() {
    return this.$route.params.subjectId;
  }

  private get controller() {
    return new DocumentListController(
      new DocumentListPresenter(this.vm),
      new GraphQLDocumentService(
        new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger()))
      ),
      new UserSettings(new LocalStorage())
    );
  }

  private mounted() {
    this.controller.init(this.metumId, this.subjectType, this.subjectId);
  }
}
