import { storageKeys } from "@/data/storageKeys";
import { Settings } from "@/shared/datastructures/Settings";

interface Priorities {
  priority1: string;
  priority2: string;
  priority3: string;
  priority4: string;
  priority5: string;
}

export interface Priority {
  key: string;
  label: string;
  color: string;
  icon: string;
}

export class PrioritySettings extends Settings<Priorities> {
  protected get storageKey(): string {
    return storageKeys.priorities;
  }

  public getPriority(key: string): Priority | undefined {
    return this.allPriorities().find(p => p.key === key);
  }

  public allPriorities(): Priority[] {
    const settings = this.get();

    return [
      {
        key: "priority1",
        label: settings.priority1,
        icon: "looks_one",
        color: "priority_1"
      },
      {
        key: "priority2",
        label: settings.priority2,
        icon: "looks_two",
        color: "priority_2"
      },
      {
        key: "priority3",
        label: settings.priority3,
        icon: "looks_3",
        color: "priority_3"
      },
      {
        key: "priority4",
        label: settings.priority4,
        icon: "looks_4",
        color: "priority_4"
      },
      {
        key: "priority5",
        label: settings.priority5,
        icon: "looks_5",
        color: "priority_5"
      }
    ];
  }
}
