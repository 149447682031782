
import Vue from "vue";
import Component from "vue-class-component";

import Textarea from "@/components/form/Textarea.vue";
import Value from "@/components/basic/Value.vue";

@Component<EditableTextarea>({
  components: {
    Textarea,
    Value
  },
  props: {
    value: {
      type: Object,
      default() {
        return { label: "Label", value: "", error: "" };
      }
    },
    editing: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    filled: {
      type: Boolean,
      default: false
    },
    displayTitle: {
      type: Boolean,
      default: true
    },
    boldValue: {
      type: Boolean,
      default: false
    }
  }
})
export default class EditableTextarea extends Vue {}
