import { PersonAddressEntity } from "../entities/PersonEntity";

export class PersonAddress {
  public street?: string;
  public city?: string;
  public zip?: string;

  public toString() {
    let text = this.street ?? "";

    if (this.city || this.zip) {
      text += ",";

      if (this.zip) {
        text += " " + this.zip;
      }
      if (this.city) {
        text += " " + this.city;
      }
    }

    return text;
  }

  public toEntity(): PersonAddressEntity {
    return {
      street: this.street,
      city: this.city,
      zip: this.zip
    };
  }

  public from(entity?: PersonAddressEntity) {
    this.street = entity?.street;
    this.city = entity?.city;
    this.zip = entity?.zip;
  }

  public static from(entity?: PersonAddressEntity) {
    const address = new PersonAddress();
    address.from(entity);
    return address;
  }
}
