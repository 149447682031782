
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import SyncTicketList from "./SyncTicketList.vue";
import SyncPlanList from "./SyncPlanList.vue";
import * as Sentry from "@sentry/vue";

import { Synchronizer } from "./Synchronizer";
import { OnlineChecker } from "@/utils/OnlineChecker";
import { LocalStorage } from "@/storage/LocalStorage";
import { storageKeys } from "@/data/storageKeys";
import { MathUtils } from "../utils/MathUtils";

@Component<SyncPanel>({
  components: { SyncTicketList, SyncPlanList }
})
export default class SyncPanel extends Vue implements Synchronizer {
  @Prop() protected readonly timer?: string;
  @Prop({ type: String, default: "" }) protected readonly warning!: string;

  public syncing = false;
  public paused = false;

  protected get hasWarning() {
    return !!this.warning;
  }

  protected get hasTimer() {
    return !!this.timer;
  }

  protected get tabs() {
    return [
      {
        label: "Tickets",
        key: "tickets"
      },
      {
        label: "Pläne",
        key: "plans"
      }
    ];
  }

  protected get synchronizers(): Synchronizer[] {
    return [this.ticketSynchronizer, this.planSynchronizer];
  }

  protected get ticketSynchronizer(): Synchronizer {
    return (this.$refs.ticketSynchronizer as unknown) as Synchronizer;
  }

  protected get planSynchronizer(): Synchronizer {
    return (this.$refs.planSynchronizer as unknown) as Synchronizer;
  }

  protected get pausePlayButtonText() {
    return this.paused
      ? "Synchronisation fortsetzen"
      : "Synchronisation pausieren";
  }

  protected get pausePlayButtonIcon() {
    return this.paused ? "play_arrow" : "pause";
  }

  protected pauseOrResume() {
    if (this.paused) {
      this.resume();
    } else {
      this.pause();
    }

    new LocalStorage().set(
      storageKeys.syncPaused,
      MathUtils.formatBool(this.paused)
    );
  }

  public async reload() {
    for (const synchronizer of this.synchronizers) {
      await synchronizer.reload();
    }
  }

  public async sync() {
    try {
      this.syncing = true;
      const isOnline = await OnlineChecker.isOnline();

      if (isOnline) {
        for (const synchronizer of this.synchronizers) {
          synchronizer.syncing = true;
          await synchronizer.sync();
          synchronizer.syncing = false;
        }
      }
    } catch (err) {
      // tslint:disable-next-line: no-console
      console.log("Synchronisation fehlgeschlagen... wiederhole später");

      Sentry.captureException(err);
    } finally {
      this.syncing = false;
      this.synchronizers.forEach(s => (s.syncing = false));
    }
  }

  public pause() {
    this.paused = true;

    for (const synchronizer of this.synchronizers) {
      synchronizer.pause();
    }
  }

  public resume() {
    this.paused = false;

    for (const synchronizer of this.synchronizers) {
      synchronizer.resume();
    }
  }
}
