import { Form, FormFieldType } from "../Form";
import { phoneRegex } from "@/data/regex";

export class PersonPhoneNumberForm extends Form {
  protected definition = {
    context: { type: FormFieldType.Selection, required: true },
    areaCode: { type: FormFieldType.Selection, required: true },
    number: {
      type: FormFieldType.Text,
      required: true,
      regex: phoneRegex,
      error: "invalid-phone-number"
    }
  };
}
