import { ILoginPresenter } from "../controllers/LoginController";
import { LoginViewModel } from "../vms/LoginViewModel";
import { FormResponse } from "@/forms/FormResponse";
import { AuthPayload } from "../interactors/Authenticator";
import { LoginForm } from "@/forms/authentication/LoginForm";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { storageKeys } from "@/data/storageKeys";

export class LoginPresenter implements ILoginPresenter {
  public loginForm: LoginForm;

  public constructor(
    private vm: LoginViewModel,
    private storage: SimpleStringStorage
  ) {
    this.loginForm = new LoginForm(this.vm, this.setLoginFormValid);

    this.loginForm.init();
  }

  public set loginResponse(response: FormResponse<AuthPayload>) {
    this.vm.loginRequest = response;

    if (!response.loading && !response.error) {
      this.storage.set(storageKeys.token, response.data.accessToken);
      this.storage.set(storageKeys.userId, response.data.userId.toString());
      this.storage.set(
        storageKeys.userName,
        response.data.firstname + " " + response.data.lastname
      );
      this.storage.set(storageKeys.clientId, response.data.clientId.toString());
      this.storage.set(storageKeys.clientName, response.data.clientName);
      this.storage.set(storageKeys.permissions, response.data.permissions);
      this.storage.set(storageKeys.role, response.data.role);
      this.storage.set(storageKeys.rolePrio, response.data.rolePrio.toString());
      this.vm.loggedIn = true;
    }
  }

  public get loginData() {
    return {
      email: this.vm.email.value.toLowerCase(),
      password: this.vm.password.value
    };
  }

  private setLoginFormValid(context: any, valid: boolean) {
    context.loginButtonDisabled = !valid;
  }
}
