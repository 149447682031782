
import Vue from "vue";
import Component from "vue-class-component";

import Header from "@/components/layout/Header.vue";
import SideBar from "@/components/layout/SideBar.vue";
import Container from "@/components/layout/Container.vue";

import UpdateMixin from "@/components/mixins/UpdateMixin";

import { BasicLayoutViewModel } from "@/common/vms/BasicLayoutViewModel";
import { BasicLayoutController } from "@/common/controllers/BasicLayoutController";
import { BasicLayoutPresenter } from "@/common/presenters/BasicLayoutPresenter";
import { LocalStorage } from "../../storage/LocalStorage";
import { ProfileManager } from "../../common/interactors/ProfileManager";
import { ProfileManagerGateway } from "@/gateways/ProfileManagerGateway";
import { UserSettings } from "@/storage/UserSettings";

@Component<BasicLayout>({
  mixins: [UpdateMixin],
  components: {
    Header,
    SideBar,
    Container
  },
  watch: {
    "vm.nextRoute"(route: string) {
      this.$router.push({ name: route });
    },
    "vm.propertyUnselected"(unselected: boolean) {
      if (this.$route.path !== "/") {
        this.$router.push({ name: "home" });
      }
      location.reload();
    }
  }
})
export default class BasicLayout extends UpdateMixin {
  protected vm: BasicLayoutViewModel;
  protected controller: BasicLayoutController;
  protected showNavigation = false;

  public constructor() {
    super();
    this.vm = new BasicLayoutViewModel();
    this.controller = this.createController(this.vm);
  }

  protected get loggedIn() {
    return UserSettings.loggedIn();
  }

  protected createController(vm: BasicLayoutViewModel) {
    return new BasicLayoutController(
      new BasicLayoutPresenter(vm, new LocalStorage()),
      new LocalStorage(),
      new ProfileManager(new ProfileManagerGateway(new LocalStorage()))
    );
  }

  protected mounted() {
    this.controller.init();
  }
}
