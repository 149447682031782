import { Plan } from "@/property/shared/models/Plan";
import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { PlanLocationEntity } from "./PlanLocationEntity";
import { InspectionRepoContainer } from "@/inspection/shared/repositories/InspectionRepoContainer";
import { Point } from "@/datastructures/Point";

export class PlanLocation extends RequestModel<PlanLocationEntity> {
  public plan?: Plan;
  public x?: number;
  public y?: number;
  public zoom?: number;

  public get hasPlan() {
    return this.plan?.exists;
  }

  public get planFilePath() {
    return this.plan?.filePath ?? "";
  }

  public get location(): Point {
    return {
      x: this.x ?? 0,
      y: this.y ?? 0
    };
  }

  public setPlan(plan?: Plan) {
    this.plan = plan;
    this.clearLocation();
  }

  public updateLocation(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  public clear() {
    this.setPlan(undefined);
  }

  public toString() {
    return this.hasPlan
      ? `${this.plan?.name} (${this.x}, ${this.y})`
      : "Nicht festgelegt";
  }

  public async loadLastLocation() {
    return RequestHandler.handleModel(
      () => InspectionRepoContainer.planLocationRepo.getLastLocation(),
      this
    );
  }

  public async saveLastLocation() {
    return RequestHandler.handle(
      InspectionRepoContainer.planLocationRepo.saveLastLocation(this.toEntity())
    );
  }

  public copy() {
    return PlanLocation.from(this.toEntity());
  }

  public toEntity(): PlanLocationEntity {
    return {
      plan: this.plan?.toEntity(),
      x: this.x,
      y: this.y,
      zoom: this.zoom
    };
  }

  public from(entity?: PlanLocationEntity) {
    this.plan = Plan.from(entity?.plan);
    this.x = entity?.x;
    this.y = entity?.y;
    this.zoom = entity?.zoom;
  }

  public static from(entity?: PlanLocationEntity) {
    const location = new PlanLocation();
    location.from(entity);
    return location;
  }

  public static async lastLocation() {
    const location = new PlanLocation();
    await location.loadLastLocation();
    return location;
  }

  private clearLocation() {
    this.x = 0;
    this.y = 0;
  }
}
