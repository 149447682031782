
import Vue from "vue";
import Component from "vue-class-component";

import { SettingDetailViewModel } from "../vms/SettingDetailViewModel";
import { SettingDetailPresenter } from "../presenters/SettingDetailPresenter";
import { SettingDetailController } from "../controllers/SettingDetailController";
import { SettingsManager } from "../interactors/SettingsManager";
import { SettingsManagerGraphQLGateway } from "../../gateways/graphql/SettingsManagerGraphQLGateway";
import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { UserSettings } from "../../storage/UserSettings";
import { LocalStorage } from "../../storage/LocalStorage";

import Page from "@/components/layout/Page.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Section from "@/components/layout/Section.vue";
import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";

import IconButton from "@/components/basic/IconButton.vue";
import RequestButton from "@/components/basic/RequestButton.vue";
import InfoDialog from "@/components/basic/InfoDialog.vue";

import SettingFields from "@/components/collection/SettingFields.vue";

@Component<SettingDetailView>({
  components: {
    Page,
    Row,
    Column,
    Section,
    Card,
    Grid,
    IconButton,
    RequestButton,
    InfoDialog,
    SettingFields
  },
  watch: {
    "vm.goBack"(go: boolean) {
      if (go) {
        this.$router.back();
      }
    }
  }
})
export default class SettingDetailView extends Vue {
  private vm = new SettingDetailViewModel();

  get controller() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );

    return new SettingDetailController(
      new SettingDetailPresenter(this.vm),
      new SettingsManager(new SettingsManagerGraphQLGateway(connection)),
      new UserSettings(new LocalStorage())
    );
  }

  private get settingKey() {
    return this.$route.params.settingKey;
  }

  private get isGlobal() {
    return this.$route.name === "global-setting";
  }

  private mounted() {
    this.controller.init(this.settingKey, this.isGlobal);
  }
}
