import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { GraphQLConnection } from "@/gateways/graphql/connection/GraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { MorphTypeConverter } from "@/shared/utils/MorphTypeConverter";
import { AccessEntity } from "../../entities/AccessEntity";
import {
  AccessRecipientType,
  AccessResource,
  AccessResourceType
} from "../../models/Access";
import {
  GraphQlRole,
  RoleGraphQlProvider
} from "../roleRepository/RoleGraphQlProvider";

interface GraphQlAccess {
  id?: string;
  recipient_id?: string;
  recipient_type?: string;
  role?: GraphQlRole;
  resource_id?: string;
  resource_type?: string;
  access_key?: string;
  description?: string;
}

export class AccessGraphQlProvider {
  public constructor(
    private connection: GraphQLConnection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    )
  ) {}

  public async addAccess(access: AccessEntity): Promise<AccessEntity> {
    const result = await this.connection.mutation(
      "shareAccess",
      {
        input: {
          recipient_id: access.recipient?.id,
          recipient_type: MorphTypeConverter.convertToServer(
            access.recipient?.type
          ),
          role_id: access.role?.id,
          resource_id: access.resource?.id,
          resource_type: MorphTypeConverter.convertToServer(
            access.resource?.type
          ),
          description: access.description
        }
      },
      [
        "id",
        "recipient_id",
        "recipient_type",
        { name: "role", fields: ["id", "name"] },
        "resource_id",
        "resource_type",
        "access_key",
        "description"
      ]
    );

    return this.convertGraphQlToAccessEntity(result.data);
  }

  public async loadAccessesOfResource(
    resource: AccessResource
  ): Promise<AccessEntity[]> {
    const result = await this.connection.query(
      "resourceAccesses",
      {
        resource_id: resource?.id,
        resource_type: MorphTypeConverter.convertToServer(resource?.type)
      },
      [
        "id",
        "recipient_id",
        "recipient_type",
        { name: "role", fields: ["id", "name"] },
        "resource_id",
        "resource_type",
        "access_key",
        "description"
      ]
    );

    return this.convertGraphQlToAccessEntities(result.data);
  }

  public async updateAccess(access: AccessEntity): Promise<AccessEntity> {
    const result = await this.connection.mutation(
      "updateAccess",
      {
        input: {
          id: access.id,
          recipient_id: access.recipient?.id,
          recipient_type: MorphTypeConverter.convertToServer(
            access.recipient?.type
          ),
          role_id: access.role?.id,
          description: access.description
        }
      },
      [
        "id",
        "recipient_id",
        "recipient_type",
        { name: "role", fields: ["id", "name"] },
        "resource_id",
        "resource_type",
        "access_key",
        "description"
      ]
    );

    return this.convertGraphQlToAccessEntity(result.data);
  }

  public async deleteAccess(access: AccessEntity): Promise<AccessEntity> {
    const result = await this.connection.mutation(
      "deleteAccess",
      {
        input: {
          id: access.id
        }
      },
      ["id"]
    );

    return this.convertGraphQlToAccessEntity(result.data);
  }

  private convertGraphQlToAccessEntities(
    data?: GraphQlAccess[]
  ): AccessEntity[] {
    if (!data) {
      return [];
    }

    return data.map(a => this.convertGraphQlToAccessEntity(a));
  }

  private convertGraphQlToAccessEntity(data?: GraphQlAccess): AccessEntity {
    if (!data) {
      return {};
    }

    return {
      id: data.id ? parseInt(data.id, 10) : undefined,
      key: data.access_key,
      recipient: {
        id: data.recipient_id ? parseInt(data.recipient_id, 10) : undefined,
        type: MorphTypeConverter.convertToClient(
          data.recipient_type
        ) as AccessRecipientType
      },
      role: RoleGraphQlProvider.convertGraphQlToRoleEntity(data.role),
      resource: {
        id: data.resource_id ? parseInt(data.resource_id, 10) : undefined,
        type: MorphTypeConverter.convertToClient(
          data.resource_type
        ) as AccessResourceType
      },
      description: data.description
    };
  }
}
