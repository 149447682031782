import { Property } from "@/property/shared/dtos/Property";
import { ServerResponse } from "@/shared/datastructures/Response";

export class PropertyModel {
  public static from(property: Partial<Property>): PropertyModel {
    return new PropertyModel(
      property.id || 0,
      property.name || "",
      property.buildYear || 0,
      property.unitCount || 0,
      property.legalForm || "",
      property.effectiveArea || 0,
      property.street || "",
      property.zip || "",
      property.city || ""
    );
  }

  public selected = false;

  public selectResponse = new ServerResponse<number[]>();

  public constructor(
    public id: number,
    public name: string,
    public buildYear: number,
    public unitCount: number,
    public legalForm: string,
    public effectiveArea: number,
    public street: string,
    public zip: string,
    public city: string
  ) {}

  public get address(): string {
    return `${this.street} - ${this.fullCity}`;
  }

  public get fullCity(): string {
    return `${this.zip} ${this.city}`;
  }

  public select(force?: boolean) {
    if (force === undefined) {
      this.selected = !this.selected;
    } else {
      this.selected = force;
    }
  }

  public toPropertyDto(): Property {
    return this;
  }
}
