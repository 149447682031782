import { emailRegex, passwordRegex } from "@/data/regex";
import { NewEmployeeData } from "@/property/shared/dtos/EmployeeDtos";
import { PropertySelectionItemLoader } from "@/property/shared/entities/PropertyDomainSelectionItemLoader";
import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";

export class NewEmployeeForm extends Form<NewEmployeeData> {
  public constructor(
    private clientId: number,
    public validatedEvent?: (valid: boolean) => void,
    public name?: string,
    public target?: unknown
  ) {
    super(validatedEvent, name, target);
  }

  private getClientId() {
    return this.clientId;
  }

  protected definition = {
    personId: {
      label: "Vorhandene Person",
      type: FormFieldType.Selection,
      required: false,
      validate: (data: NewEmployeeData) => {
        if (!data.personId && (!data.firstname || !data.lastname)) {
          return "Vorhandene Person wählen oder neuen Namen angeben";
        }

        return true;
      },
      itemDefinition: {
        items: () => PropertySelectionItemLoader.loadPeople(this.getClientId())
      }
    },
    firstname: {
      label: "Vorname",
      type: FormFieldType.Text,
      required: false,
      validate: (data: NewEmployeeData) => {
        if (!data.firstname && data.lastname) {
          return "Erforderlich";
        }

        return true;
      }
    },
    lastname: {
      label: "Nachname",
      type: FormFieldType.Text,
      required: false,
      validate: (data: NewEmployeeData) => {
        if (!data.lastname && data.firstname) {
          return "Erforderlich";
        }

        return true;
      }
    },
    role: {
      label: "Rolle",
      type: FormFieldType.Selection,
      required: true,
      itemDefinition: {
        items: () => PropertySelectionItemLoader.loadRoles()
      }
    },
    email: {
      label: "Username (Email)",
      type: FormFieldType.Text,
      required: true,
      regex: emailRegex,
      error: "Email-Format ungültig"
    },
    password: {
      label: "Passwort",
      type: FormFieldType.Text,
      required: true,
      regex: passwordRegex,
      error:
        "Passwort vorgaben: 8 Zeichen mit jeweils mind. 1 Zahl und KLein- und Großbuchstaben"
    },
    passwordConfirmed: {
      label: "Passwort wiederholen",
      type: FormFieldType.Text,
      required: true,
      validate: (data: NewEmployeeData) => {
        return data.password === data.passwordConfirmed
          ? true
          : "Passwort-Eingaben stimmen nicht überein";
      }
    }
  };
}
