
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import TicketLocationView from "@/expert/views/TicketLocationView.vue";
import { Plant } from "@/property/shared/models/Plant";
import { PlanLocation } from "@/shared/project/planLocation/PlanLocation";
import { SelectionItem } from "@/shared/form/FormTypes";
import { Plan } from "@/property/shared/models/Plan";
import { Point } from "@/datastructures/Point";

@Component<PlantLocation>({
  components: {
    TicketLocationView
  }
})
export default class PlantLocation extends Vue {
  @Prop({ type: Number, required: true })
  protected readonly propertyId!: number;
  @Prop({ type: Object, default: () => new Plant() })
  protected readonly plant!: Plant;

  protected planLocation = new PlanLocation();
  protected locationDialogVisible = false;

  protected get propertyIdAsString() {
    return this.propertyId.toString();
  }

  protected get planId() {
    return this.planLocation.plan?.id?.toString();
  }

  protected get location() {
    return this.planLocation.location;
  }

  protected async applyLocation() {
    if (await this.plant.updateLocation(this.planLocation.copy())) {
      this.planLocation.saveLastLocation();
      await this.reset();
      this.locationDialogVisible = false;
    }
  }

  protected planSelected(_: string, planSelection?: SelectionItem) {
    if (!planSelection) {
      return;
    }

    const plan = new Plan();
    plan.name = planSelection.text;
    plan.id = parseInt(planSelection.value.toString(), 10);

    this.planLocation.setPlan(plan);
  }

  protected locationChanged(location: Point) {
    this.planLocation.updateLocation(location.x, location.y);
  }

  protected async created() {
    this.reset();
  }

  public async reset() {
    if (this.plant.hasLocation) {
      this.planLocation = this.plant.location!.copy();
    } else {
      this.planLocation = await PlanLocation.lastLocation();
    }
  }
}
