
import Vue from "vue";
import Component from "vue-class-component";

@Component<Icon>({
  props: {
    size: {
      type: Number,
      default: undefined
    },
    color: {
      type: String,
      default: "primary"
    }
  }
})
export default class Icon extends Vue {}
