
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import IconButton from "@/components/basic/IconButton.vue";

@Component<Img>({
  components: {
    IconButton
  }
})
export default class Img extends Vue {
  @Prop({
    type: String,
    default: ""
  })
  protected readonly src!: string;
  @Prop({
    type: Boolean,
    default: false
  })
  protected readonly deletable!: boolean;
  @Prop({
    type: Boolean,
    default: false
  })
  protected readonly deleteLoading!: boolean;
  @Prop({
    type: String,
    default: ""
  })
  protected readonly deleteError!: string;
  @Prop({
    type: [Number, String],
    default: undefined
  })
  protected readonly height!: string | number;
  @Prop({
    type: [Number, String],
    default: undefined
  })
  protected readonly width!: string | number;
  @Prop({
    type: [Number, String],
    default: undefined
  })
  protected readonly maxHeight!: string | number;
  @Prop({
    type: [Number, String],
    default: undefined
  })
  protected readonly maxWidth!: string | number;
  @Prop({
    type: Boolean,
    default: false
  })
  protected readonly contain!: boolean;
  @Prop({
    type: Boolean,
    default: false
  })
  protected readonly loading!: boolean;
  @Prop({
    type: String,
    default: undefined
  })
  protected readonly error!: string;
  @Prop({
    type: Boolean,
    default: true
  })
  protected readonly showErrorText!: boolean;
  @Prop({
    type: Boolean,
    default: false
  })
  protected readonly done!: boolean;

  protected get overlayColor() {
    return this.done ? "accent" : this.loading ? "primary" : "error";
  }

  protected overlayVisible(hover: boolean) {
    return (this.deletable && hover) || this.loading || this.error || this.done;
  }

  protected emitReload() {
    this.$emit("reload");
  }

  protected emitDelete() {
    this.$emit("delete");
  }
}
