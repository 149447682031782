
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { NewEmployeeForm } from "../../forms/NewEmployeeForm";
import { Person } from "@/property/shared/models/Person";
import { Employee } from "@/property/shared/models/Employee";
import { Client } from "@/property/shared/models/Client";

@Component<CreateEmployeeForm>({
  components: {}
})
export default class CreateEmployeeForm extends Vue {
  @Prop({ type: Number, required: true }) protected readonly clientId!: number;

  protected employee = new Employee();

  protected form = new NewEmployeeForm(this.clientId);
  protected emailHint = "";
  protected emailSuccess = "";

  protected get personSelected() {
    return !!this.form.fields.personId.value;
  }

  protected selectPerson(id: number, person: Person) {
    this.setField("personId", id);

    if (person) {
      this.setField("firstname", person.firstname);
      this.setField("lastname", person.lastname);
    } else {
      this.setField("firstname", "");
      this.setField("lastname", "");
    }
  }

  protected async setEmail(email: string) {
    if (this.setField("email", email)) {
      this.emailHint = "Verfügbarkeit der Mail wird geprüft";
      this.emailSuccess = "";

      await this.employee.checkExistence();

      this.emailHint = "";
      this.emailSuccess = "Email steht noch zur Verfügung";
    }
  }

  protected setField(fieldName: string, value: unknown) {
    if (this.form.setFieldValue(fieldName, value)) {
      this.employee.creationData = this.form.getData();
      return true;
    }
    return false;
  }

  protected async createEmployee() {
    if (await this.employee.create()) {
      this.$emit("created", this.employee);

      this.resetEmployee();
      await this.form.reset();
    }
  }

  protected async created() {
    this.resetEmployee();
    this.form.init();
  }

  protected resetEmployee() {
    this.employee = new Employee({ client: new Client({ id: this.clientId }) });
    this.emailHint = "";
    this.emailSuccess = "";
  }
}
