import { PlantChecklistEntryData } from "@/property/shared/entities/PlantEntity";
import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";

export class PlantChecklistEntryForm extends Form<PlantChecklistEntryData> {
  protected definition = {
    name: {
      label: "Name",
      type: FormFieldType.Text,
      required: true
    },
    description: {
      label: "Beschreibung",
      type: FormFieldType.Text,
      required: false
    },
    interval: {
      label: "Intervall",
      type: FormFieldType.Text,
      required: false
    }
  };
}
