
import { SelectionItem } from "@/shared/form/FormTypes";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component<DropdownButton>({
  components: {}
})
export default class DropdownButton extends Vue {
  @Prop({ required: true }) protected readonly text!: string;
  @Prop() protected readonly icon?: string;
  @Prop({ type: Boolean, default: true })
  protected readonly outlined!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly displayIcon!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly iconButton!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly leftIcon!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly skipTooltip!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly responsive!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly loading!: boolean;
  @Prop() protected readonly error?: string;
  @Prop({ type: Boolean, default: false })
  protected readonly disabled!: boolean;
  @Prop() protected readonly height?: string;
  @Prop({
    type: Array,
    required: true
  })
  protected readonly items!: SelectionItem[];

  protected dropDownVisible = false;

  protected toggleDropdownOrSelectFirst() {
    if (this.items.length === 1) {
      this.selectItem(this.items[0]);
    } else {
      this.dropDownVisible = true;
    }
  }

  protected selectItem(item: SelectionItem) {
    if (!item.disabled) {
      this.$emit("click", item.value);
    }
  }
}
