import { Settings } from "@/shared/datastructures/Settings";

export interface ReportTemplates {
  report_templates?: ReportTemplate[];
}

export interface ReportTemplate {
  id?: string;
  key?: string;
  name?: string;
  data?: ReportTemplateData;
  layout?: ReportTemplateLayout;
}

export interface ReportTemplateData {
  id?: string;
  steps?: ReportTemplateDataStep[];
}

export interface ReportTemplateDataStep {
  id?: string;
  key?: string;
  label?: string;
  fields?: ReportTemplateDataField[];
}

export interface ReportTemplateDataField {
  id?: string;
  key?: string;
  label?: string;
  type?: ReportTemplateDataFieldType;
  width?: ReportTemplateDataFieldWidth;
  options?: string[];
  default?: string;
}

export interface ReportTemplateLayout {
  id?: string;
  pages?: ReportTemplateLayoutPage;
}

export interface ReportTemplateLayoutPage {
  id?: string;
  options?: ReportTemplateLayoutPageOptions;
  elements?: ReportTemplateLayoutElement;
}

export interface ReportTemplateLayoutPageOptions {
  id?: string;
}

export interface ReportTemplateLayoutElement {
  id?: string;
  type?: ReportTemplateLayoutElementType;
}

export type ReportTemplateDataFieldType = "string" | "text" | "number" | "date";
export type ReportTemplateDataFieldWidth =
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12";
export type ReportTemplateLayoutElementType = "image" | "section" | "checklist";

export class ReportTemplateSettings extends Settings<ReportTemplates> {
  protected get storageKey(): string {
    return JSON.stringify({
      report_templates: [
        {
          key: "move_out_checklist",
          name: "Wohnungsübergabeprotokoll",
          data: {} // Currently hardcoded below
        }
      ]
    });
  }

  public getDataStructure(templateKey: string): ReportTemplateData {
    // const settings = this.get();
    // const template = settings.report_templates?.find(
    //   t => t.key === templateKey
    // );
    return {
      steps: [
        {
          key: "title",
          label: "Titelblatt",
          fields: [
            {
              key: "title",
              label: "Titel",
              type: "string",
              width: "8",
              default: "Wohnungsübergabeprotokoll"
            },
            {
              key: "date",
              label: "Berichtdatum",
              type: "date",
              width: "4"
            },
            {
              key: "property",
              label: "Liegenschaft",
              type: "string",
              width: "6",
              default: "%%property.name%%"
            },
            {
              key: "top",
              label: "Topbezeichnung",
              type: "string",
              width: "6",
              default: "%%plant.name%%"
            },
            {
              key: "creator",
              label: "Ersteller",
              type: "string",
              width: "12"
            },
            {
              key: "handed_over_from",
              label: "Übergeben von",
              type: "text",
              width: "6"
            },
            {
              key: "handed_over_to",
              label: "Übergeben an",
              type: "text",
              width: "6"
            },
            {
              key: "top_structure",
              label: "Topstruktur",
              type: "text",
              width: "12",
              default: "%%plant.checklist_entries( - %%entry.name%% \n)%%"
            }
          ]
        },
        {
          key: "keys_documents",
          label: "Schlüssel und Unterlagen",
          fields: [
            {
              key: "property_appartment_keys",
              label: "Kombination Wohnung - Hausschlüssel",
              type: "number",
              width: "6",
              default: "0"
            },
            {
              key: "property_keys",
              label: "Hausschlüssel",
              type: "number",
              width: "6",
              default: "0"
            },
            {
              key: "appartment_keys",
              label: "Wohnungsschlüssel",
              type: "number",
              width: "6",
              default: "0"
            },
            {
              key: "room_keys",
              label: "Zimmerschlüssel",
              type: "number",
              width: "6",
              default: "0"
            },
            {
              key: "mailbox_keys",
              label: "Briefkastenschlüssel",
              type: "number",
              width: "6",
              default: "0"
            },
            {
              key: "cellar_keys",
              label: "Kellerschlüssel",
              type: "number",
              width: "6",
              default: "0"
            },
            {
              key: "garage_keys",
              label: "Garagenschlüssel",
              type: "number",
              width: "6",
              default: "0"
            },
            {
              key: "radio_transmitters",
              label: "Funksender",
              type: "number",
              width: "6",
              default: "0"
            },
            {
              key: "other_documents",
              label: "Unterlagen und Weiteres",
              type: "text",
              width: "12"
            }
          ]
        }
      ]
    };
  }
}
