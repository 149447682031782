
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ContextMenu extends Vue {
  @Prop({ type: Boolean, default: false }) protected readonly visible!: boolean;

  protected trackingLocked = false;
  protected lockTimeoutHandle = -1;
  protected dialogX = 0;
  protected dialogY = 0;
  protected mouseX = 0;
  protected mouseY = 0;

  @Watch("visible")
  protected onVisibilityChanged() {
    if (this.visible) {
      this.dialogX = this.mouseX;
      this.dialogY = this.mouseY;
      this.trackingLocked = true;
      clearTimeout(this.lockTimeoutHandle);
    } else {
      this.lockTimeoutHandle = setTimeout(() => {
        this.trackingLocked = false;
      }, 500);
    }
  }

  protected created() {
    window.addEventListener("mousemove", this.trackMousePosition);
  }

  protected destroyed() {
    window.removeEventListener("mousemove", this.trackMousePosition);
  }

  protected trackMousePosition(e: MouseEvent) {
    e.preventDefault();

    if (!this.visible && !this.trackingLocked) {
      this.mouseX = e.clientX;
      this.mouseY = e.clientY;
    }
  }

  protected emitInput(visible: boolean) {
    this.$emit("input", visible);
  }
}
