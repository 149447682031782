import {
  PropertyPlanManagerGateway,
  PlanData,
  CreatePlanData,
  PlanImageData,
  PlanCatalogRow,
  PlanPayload
} from "@/office/interactors/PropertyPlanManager";
import { GraphQLConnection } from "./connection/GraphQLConnection";
import { NumericalId } from "@/datastructures/NumericalId";
import { Plan } from "@/entities/Plan";
import { StringUtils } from "@/utils/StringUtils";
import { PropertyPlanViewerGateway } from "@/expert/interactors/PropertyPlanViewer";

export class PropertyPlanManagerGraphQLGateway
  implements PropertyPlanManagerGateway {
  public constructor(
    private connection: GraphQLConnection,
    private planViewer: PropertyPlanViewerGateway
  ) {}

  public loadPlanCatalog(propertyId: NumericalId): Promise<PlanCatalogRow[]> {
    return this.planViewer.loadPlanCatalog(propertyId);
  }

  public loadPlan(planId: NumericalId): Promise<PlanPayload> {
    return this.planViewer.loadPlan(planId);
  }

  public loadTicketPlan(
    ticketId: NumericalId,
    planId: NumericalId
  ): Promise<PlanPayload> {
    return this.planViewer.loadTicketPlan(ticketId, planId);
  }

  public createPlan(
    propertyId: NumericalId,
    data: CreatePlanData
  ): Promise<Plan> {
    return this.connection
      .mutation(
        "createPlan",
        {
          input: {
            name: data.name,
            point_pixel_a_x: 0,
            point_pixel_a_y: 0,
            point_pixel_b_x: 100,
            point_pixel_b_y: 100,
            point_real_a_x: 0,
            point_real_a_y: 0,
            point_real_b_x: 1,
            point_real_b_y: 1,
            property: { connect: propertyId.id }
          }
        },
        ["id"]
      )
      .then(planData =>
        this.uploadPlanImage(data.file, propertyId.id, planData.data.id)
      )
      .then(file => {
        const plan = new Plan(file.data.fileable_id);
        plan.name = data.name;
        return plan;
      });
  }

  public updatePlan(data: PlanData): Promise<void> {
    return this.connection
      .mutation(
        "updatePlan",
        {
          input: {
            id: data.id.id,
            name: data.name,
            offline: data.offline,
            point_pixel_a_x: data.pixelDistance.a.x,
            point_pixel_a_y: data.pixelDistance.a.y,
            point_pixel_b_x: data.pixelDistance.b.x,
            point_pixel_b_y: data.pixelDistance.b.y,
            point_real_a_x: data.realDistance.a.x,
            point_real_a_y: data.realDistance.a.y,
            point_real_b_x: data.realDistance.b.x,
            point_real_b_y: data.realDistance.b.y
          }
        },
        ["id"]
      )
      .then();
  }

  public deletePlan(id: NumericalId): Promise<NumericalId> {
    return this.connection
      .mutation("deletePlan", { id: id.id }, ["id"])
      .then(response => new NumericalId(response.data.id));
  }

  public async replacePlanImage(
    propertyId: NumericalId,
    planId: NumericalId,
    imageId: NumericalId,
    newPlanImage: File
  ): Promise<PlanImageData> {
    await this.connection.mutation("deleteFile", { id: imageId.id }, ["id"]);

    const file = await this.uploadPlanImage(
      newPlanImage,
      propertyId.id,
      planId.id
    );

    return {
      id: NumericalId.fromString(file.data.id),
      path: file.data.path
    };
  }

  private uploadPlanImage(planImage: File, propertyId: number, planId: number) {
    return this.connection.uploadFile(
      planImage,
      `property/${propertyId}/plans`,
      StringUtils.uuid(),
      ["id", "fileable_id", "path"],
      undefined,
      { id: planId, type: "App\\\\Plan" }
    );
  }
}
