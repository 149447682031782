import { RequestModel } from "@/shared/model/RequestModel";
import { ImageEntity } from "./ImageEntity";
import { Image } from "./Image";
import { ServerResponse } from "@/shared/datastructures/Response";
import { FileUtils } from "@/shared/utils/FileUtils";
import { ImageProcessor } from "@/shared/utils/ImageProcessor";
import { Fileable } from "../file/Fileable";

interface ImageCollectionFromOptions {
  imageable: Fileable;
}

export class ImageCollection extends RequestModel<ImageEntity[]> {
  public images?: Image[] = [];

  public uploadResponse = new ServerResponse<number>();

  public get empty() {
    return (this.images?.length ?? 0) === 0;
  }

  public async addImageFromFileIfNotExistent(image: File) {
    const name = FileUtils.generateUniqueName(image);
    const existingImage = this.findImageByName(name);

    if (!existingImage) {
      const base64 = await ImageProcessor.toBase64(image);
      const compressedFile = await FileUtils.fromUrl(
        base64,
        name + ".jpg",
        "image/jpg"
      );
      const thumbnailBase64 = await ImageProcessor.toBase64(
        compressedFile,
        true
      );

      const ticketImage = Image.from({
        name,
        path: base64,
        pathThumbnail: thumbnailBase64
      });

      this.add(ticketImage);
    }
  }

  public add(image: Image) {
    if (!this.images) {
      this.images = [];
    }

    this.images?.unshift(image);
  }

  public remove(image: Image) {
    const imagesToClear = this.images?.filter(i => image.equals(i));
    imagesToClear?.forEach(i => i.clear());

    this.images = this.images?.filter(i => !image.equals(i));
  }

  public clear() {
    this.clearImages();

    if (this.images) {
      this.images = [];
    }
  }

  public clearImages() {
    this.images?.forEach(i => i.clear());
  }

  public toEntities() {
    return this.images?.map(i => i.toEntity());
  }

  public from(entities?: ImageEntity[]) {
    this.images = entities?.map(e => Image.from(e));
  }

  public static collectFrom(entities?: ImageEntity[]) {
    return this.collect(entities?.map(e => Image.from(e)));
  }

  public static collect(images?: Image[]) {
    const collection = new ImageCollection();
    collection.images = images;
    return collection;
  }

  private findImageByName(name: string) {
    return this.images?.find(i => i.name === name);
  }
}
