
import Vue from "vue";
import Component from "vue-class-component";

@Component<Tabs>({
  props: {
    tabs: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
})
export default class Tabs extends Vue {
  private tab: any = null;
}
