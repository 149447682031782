import { Location } from "./Location";

export class Address {
  public id = 0;
  public country = "";
  public context = "";
  public location = new Location(0, 0);

  public constructor(
    public street: string,
    public zip: string,
    public city: string
  ) {}
}
