
import Vue from "vue";
import Component from "vue-class-component";

import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

@Component<FullScreenDialog>({
  components: {
    Grid,
    Row,
    Column
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Title"
    }
  }
})
export default class FullScreenDialog extends Vue {}
