import { IDWSBaseSyncPresenter } from "../controllers/DWSBaseSyncController";
import { DWSBaseSyncViewModel } from "../vms/DWSBaseSyncViewModel";
import { FormResponse } from "@/forms/FormResponse";
import { DWSBaseSync } from "../services/DWSBaseService";

export class DWSBaseSyncPresenter implements IDWSBaseSyncPresenter {
  public constructor(private vm: DWSBaseSyncViewModel) {}

  public set syncDWSBaseResponse(response: FormResponse<DWSBaseSync>) {
    this.vm.syncDWSBaseRequest = response;

    if (response.success) {
      this.vm.newDocuments.value = response.data.newDocuments;
      this.vm.updatedDocuments.value = response.data.updatedDocuments;
      this.vm.failedDocumentsVisible = response.data.failedDocuments.length > 0;
      this.vm.failedDocuments = response.data.failedDocuments;
    }
  }

  public set close(close: boolean) {
    this.vm.close = close;
  }
}
