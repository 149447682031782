import { NumericalId } from "@/datastructures/NumericalId";
import { Page } from "@/datastructures/Page";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { IPersonEditor, PersonCatalogRow } from "../interactors/PersonEditor";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { storageKeys } from "@/data/storageKeys";

export class PersonListController {
  public constructor(
    private presenter: IPersonListPresenter,
    private viewer: IPersonEditor,
    private storage: SimpleStringStorage
  ) {}

  public init() {
    this.loadPeople(this.getClientId(), 1, "");
  }

  public loadPeople(clientId: string, pageNumber: number, search: string) {
    NumericalId.onValidId(clientId, id => {
      this.presenter.personSearch = search;
      this.presenter.currentPage = pageNumber;
      const page = new Page(this.presenter.peoplePerPage, pageNumber);
      const request = this.viewer.loadPeopleCatalog(id, page, search);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadPeopleResponse = response),
        "Personen konnten nicht geladen werden"
      );
    });
  }

  // Input
  public searchChanged(search: string) {
    this.presenter.personSearch = search;
  }

  // Actions
  public createPersonButtonClicked() {
    this.presenter.createPersonVisible = true;
  }

  public editPersonButtonClicked(personId: string) {
    this.presenter.personToEdit = personId;
  }

  public addPersonDialogCloseButtonClicked() {
    this.presenter.createPersonVisible = false;
  }

  public newPersonCreated(personId: string) {
    this.presenter.createdPerson = personId;
  }

  public pageSwitched(page: number) {
    this.loadPeople(this.getClientId(), page, this.presenter.personSearch);
  }

  public searchPeople(search: string) {
    this.loadPeople(this.getClientId(), 1, search);
  }

  // Helper
  private getClientId() {
    return this.storage.get(storageKeys.clientId);
  }
}

export interface IPersonListPresenter {
  personSearch: string;
  createPersonVisible: boolean;
  personToEdit: string;
  createdPerson: string;
  currentPage: number;
  peoplePerPage: number;

  loadPeopleResponse: FormResponse<PaginatedList<PersonCatalogRow>>;
}
