
import Vue from "vue";
import Component from "vue-class-component";

import { StringUtils } from "@/utils/StringUtils";

@Component<RequestButton>({
  components: {
    ConfirmDialog: () => import("./ConfirmDialog.vue"),
    IconButton: () => import("./IconButton.vue"),
    ResponsiveIconButton: () => import("./ResponsiveIconButton.vue")
  },
  props: {
    text: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "primary"
    },
    error: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disableOnLoad: {
      type: Boolean,
      default: true
    },
    confirm: {
      type: Boolean,
      default: false
    },
    confirmTitle: {
      type: String,
      default: "Title"
    },
    confirmText: {
      type: String,
      default: "Text"
    },
    confirming: {
      type: Boolean,
      default: false
    },
    tooltipWidth: {
      type: Number,
      default: 100
    },
    skipTooltip: {
      type: Boolean,
      default: false
    },
    tooltipTextColor: {
      type: String,
      default: "white"
    },
    outlined: {
      type: Boolean,
      default: true
    },
    block: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "normal"
    },
    icon: {
      type: String,
      default: ""
    },
    iconButton: {
      type: Boolean,
      default: false
    },
    urlButton: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    hoverColor: {
      type: String,
      default: "secondary"
    },
    pressedColor: {
      type: String,
      default: "secondary"
    },
    filled: {
      type: Boolean,
      default: false
    },
    responsive: {
      type: Boolean,
      default: false
    },
    height: {
      type: [Number, String],
      default: 36
    },
    displayIcon: {
      type: Boolean,
      default: false
    },
    leftIcon: {
      type: Boolean,
      default: false
    }
  }
})
export default class RequestButton extends Vue {
  private confirmDialogVisible = false;
  private block!: boolean;

  private get buttonDisabled() {
    return (
      this.$props.disabled || (this.$props.disableOnLoad && this.$props.loading)
    );
  }

  private get buttonColor() {
    return !this.$props.error ? this.$props.color : "error";
  }

  private get containerClass() {
    return {
      "d-flex": this.block,
      "align-center": true,
      "d-inline-block": !this.block
    };
  }

  private get normalButtonClass() {
    return this.$props.responsive ? "d-none d-md-flex" : "";
  }

  private get iconButtonClass() {
    return this.$props.responsive ? "d-flex d-md-none" : "";
  }

  private get heightStyle() {
    const height = this.$props.height;

    if (StringUtils.isString(height)) {
      return "height: " + height + ";";
    } else {
      return "height: " + height + "px;";
    }
  }

  private buttonClicked() {
    if (this.$props.confirm && !this.$props.error) {
      this.confirmDialogVisible = true;
      this.$emit("update:confirming", true);
    } else {
      this.emitClick();
    }
  }

  private confirmRequest() {
    this.emitClick();
    this.closeDialog();
  }

  private closeDialog() {
    this.confirmDialogVisible = false;
    this.$emit("update:confirming", false);
  }
  private emitClick() {
    this.$emit("click");
  }
}
