import { vatRegex } from "@/data/regex";
import { ClientGeneralData } from "@/property/shared/dtos/ClientDtos";
import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";

export class ClientGeneralDataForm extends Form<ClientGeneralData> {
  protected definition = {
    name: { label: "Name", type: FormFieldType.Text, required: true },
    vat: {
      label: "UID",
      type: FormFieldType.Text,
      required: false,
      regex: vatRegex,
      error: "Ungültige UID"
    }
  };
}
