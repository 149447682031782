export class SafeRetry {
  private handler = -1;
  private isLoading = false;

  public constructor(
    public action: () => Promise<boolean>,
    public interval = 5000,
    private onDone?: () => void,
    private onError?: (error: any) => void
  ) {}

  public start() {
    this.clear();

    this.handler = setInterval(() => {
      if (!this.isLoading) {
        this.performAction();
      }
    }, this.interval);
  }

  private async performAction() {
    this.isLoading = true;

    try {
      if (await this.action()) {
        this.onDone?.();
        this.clear();
      }
    } catch (error) {
      this.onError?.(error);
    } finally {
      this.isLoading = false;
    }
  }

  private clear() {
    if (this.handler >= 0) {
      clearInterval(this.handler);
      this.handler = -1;
    }

    this.isLoading = false;
  }
}
