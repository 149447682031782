
import Vue from "vue";
import Component from "vue-class-component";

@Component<FileInput>({
  props: {
    value: {
      type: Object,
      default() {
        return { label: "Label", value: null, error: "", loading: false };
      }
    },
    v: {
      type: [Array, Object],
      default: undefined
    },
    val: {
      type: [Object, Array],
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: "attach_file"
    },
    label: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ""
    },
    dense: {
      type: Boolean,
      default: false
    }
  }
})
export default class FileInput extends Vue {
  private changed(data: File[]) {
    this.$emit("change", data);
  }
}
