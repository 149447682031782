import { Role } from "@/property/shared/models/Role";
import { Model } from "@/shared/model/Model";
import { AccessEntity } from "../entities/AccessEntity";

export class Access extends Model<AccessEntity> {
  public key?: string = "";
  public recipient?: AccessRecipient = {};
  public role?: Role = new Role();
  public resource?: AccessResource = {};
  public link?: string = "";
  public description?: string = "";

  public get valid() {
    return this.hasValidRecipient && this.hasValidRole && this.hasValidResource;
  }

  public get hasDescription() {
    return !!this.description;
  }

  private get hasValidRecipient() {
    return (
      !!this.recipient &&
      (this.recipient.id ?? -1) >= 0 &&
      !!this.recipient.type
    );
  }

  private get hasValidRole() {
    return this.role?.exists ?? false;
  }

  private get hasValidResource() {
    return !!this.resource && !!this.resource.id && !!this.resource.type;
  }

  public toEntity(): AccessEntity {
    return {
      id: this.id,
      key: this.key,
      recipient: this.recipient,
      role: this.role?.toEntity(),
      resource: this.resource,
      link: this.link,
      description: this.description
    };
  }

  public from(entity?: AccessEntity): void {
    this.id = entity?.id;
    this.key = entity?.key;
    this.recipient = entity?.recipient;
    this.role = Role.from(entity?.role);
    this.resource = entity?.resource;
    this.link = entity?.link;
    this.description = entity?.description;
  }

  protected newInstance(): Model<AccessEntity> {
    return new Access();
  }

  public static from(entity?: AccessEntity): Access {
    const model = new Access();
    model.from(entity);
    return model;
  }
}

export interface AccessRecipient {
  id?: number;
  type?: AccessRecipientType;
}

export interface AccessResource {
  id?: number;
  type?: AccessResourceType;
}

export type AccessRecipientType = "guest";
export type AccessResourceType = "ticket";
