import { SimpleStringStorage } from "./SimpleStringStorage";

export class LocalStorage implements SimpleStringStorage {
  private storage = window.localStorage;

  public constructor(session = false) {
    if (!session) {
      this.storage = window.localStorage;
    } else {
      this.storage = window.sessionStorage;
    }
  }

  public set(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  public setJson(key: string, obj: any): void {
    this.set(key, JSON.stringify(obj));
  }

  public get(key: string): string {
    return this.storage.getItem(key) || "";
  }

  public getJson(key: string, returnArrayIfEmpty?: boolean) {
    if (this.has(key)) {
      return JSON.parse(this.get(key));
    } else {
      return returnArrayIfEmpty ? [] : {};
    }
  }

  public remove(key: string): boolean {
    if (this.has(key)) {
      this.storage.removeItem(key);
      return true;
    }
    return false;
  }

  public has(key: string): boolean {
    return !!this.storage.getItem(key);
  }

  public clear(): void {
    this.storage.clear();
  }

  public empty(): boolean {
    return this.storage.length === 0;
  }

  public available(): boolean {
    let storage;
    try {
      storage = window.localStorage;
      const x = "__storage_test__";
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return (
        e instanceof DOMException &&
        (e.code === 22 ||
          e.code === 1014 ||
          e.name === "QuotaExceededError" ||
          e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
        !!storage && storage.length !== 0
      );
    }
  }
}
