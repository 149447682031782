import {
  Request,
  TableHeader,
  SingleFileInput
} from "@/forms/ViewModelFormTypes";

export class DocumentListViewModel {
  public goBack: boolean = false;

  public metumId: string = "";
  public subjectType: string = "";
  public subjectId: string = "";
  public documents: DocumentViewModel[] = [];
  public linkToOpen: string = "";
  public documentToOpen: string = "";
  public uploadDocumentDialogVisible: boolean = false;
  public uploadedDocument: number = 0;

  public uploadDocumentButtonVisible: boolean = false;
  public editDocumentButtonVisible: boolean = false;
  public openDocumentButtonVisible: boolean = false;

  public loadDocumentsRequest: Request = { loading: false, error: "" };

  public uploadDocument: SingleFileInput = {
    label: "Dokument",
    value: undefined,
    loading: false,
    error: ""
  };

  public documentsPerPage = 20;
  public totalDocuments = 20;
  public currentPage = 1;
  public documentsTableHeaders: TableHeader[] = [
    {
      text: "Name",
      value: "name",
      width: "100%"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "right"
    }
  ];
}

interface DocumentViewModel {
  id: string;
  name: string;
  link: string;
}
