
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { Client } from "@/property/shared/models/Client";
import { ClientAddressForm as Form } from "../../forms/ClientAddressForm";
import { RequestHandler } from "@/shared/utils/RequestHandler";

@Component<ClientAddressForm>({
  components: {}
})
export default class ClientAddressForm extends Vue {
  @Prop({
    default() {
      return new Client();
    }
  })
  protected readonly client!: Client;
  @Prop({ type: Boolean, default: false })
  protected readonly disabled!: boolean;

  protected form = new Form();

  @Watch("client.success")
  protected onClientLoaded() {
    this.form.setFieldValue("street", this.client.street, true);
    this.form.setFieldValue("zip", this.client.zip, true);
    this.form.setFieldValue("city", this.client.city, true);
    this.form.setFieldValue("country", this.client.country, true);
  }

  public clearAddress() {
    this.form.setFieldValue("street", "");
    this.form.setFieldValue("zip", "");
    this.form.setFieldValue("city", "");
    this.form.setFieldValue("country", "");
    this.updateClient("street", "");
  }

  protected async updateClient(fieldName: string, value: unknown) {
    RequestHandler.handleFormUpdate(
      () => this.client.updateAddress(this.form.getData()),
      {
        form: this.form,
        fieldName,
        value
      }
    );
  }

  protected async created() {
    this.form = new Form();
    await this.form.init();
    this.form.enabled = !this.disabled;
  }

  protected mounted() {
    this.load();
  }

  protected load() {
    if (!this.client.loadingOrLoaded) {
      this.client.load();
    }
  }
}
