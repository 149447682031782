import { SelectionItem } from "@/shared/form/FormTypes";
import { InspectionSettingsContainer } from "../settings/InspectionSettingsContainer";

export class InspectionSelectionItemLoader {
  public static loadTicketStates(): SelectionItem[] {
    const states = InspectionSettingsContainer.ticketStates.allStates();

    return states.map(s => ({
      value: s.state_id,
      text: s.name,
      item: s
    }));
  }

  public static loadChecklistPreparationStates(): SelectionItem[] {
    return [
      {
        value: "prove",
        text: "Zu prüfen",
        item: "accent"
      },
      {
        value: "irrelevant",
        text: "Nicht relevant",
        item: "irrelevant"
      },
      {
        value: "inavailable",
        text: "Nicht vorhanden",
        item: "inavailable"
      }
    ];
  }

  public static loadChecklistInspectionStates(_?: string): SelectionItem[] {
    // if (clientId === "10") {
    //   return [
    //     {
    //       value: "clear",
    //       text: "Nicht geprüft",
    //       item: "deep-purple lighten-3"
    //     },
    //     {
    //       value: "new",
    //       text: "Neu",
    //       item: "accent"
    //     },
    //     {
    //       value: "like_new",
    //       text: "Neuwertig",
    //       item: "yellow"
    //     },
    //     {
    //       value: "signs_of_usage",
    //       text: "Leichte Gebrauchsspuren",
    //       item: "orange"
    //     },
    //     {
    //       value: "damaged",
    //       text: "Beschädigt / Instand zu setzen",
    //       item: "error"
    //     }
    //   ];
    // } else {
    return [
      {
        value: "clear",
        text: "Nicht geprüft",
        item: "deep-purple lighten-3"
      },
      {
        value: "proven",
        text: "Geprüft",
        item: "accent"
      },
      {
        value: "maintenance",
        text: "Wartungsvertrag",
        item: "ternary"
      },
      {
        value: "inspection",
        text: "Aktuelles Gutachten",
        item: "primary"
      }
    ];
    // }
  }
}
