
import Vue from "vue";
import Component from "vue-class-component";

import { PropertyPlanViewModel } from "../vms/PropertyPlanViewModel";
import { PropertyPlanPresenter } from "../presenters/PropertyPlanPresenter";
import { PropertyPlanController } from "../controllers/PropertyPlanController";
import { PropertyPlanManager } from "../interactors/PropertyPlanManager";
import { PropertyPlanManagerGraphQLGateway } from "@/gateways/graphql/PropertyPlanManagerGraphQLGateway";
import { PropertyPlanViewer } from "@/expert/interactors/PropertyPlanViewer";
import { PropertyPlanViewerGraphQLGateway } from "@/gateways/graphql/PropertyPlanViewerGraphQLGateway";
import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";

import Page from "@/components/layout/Page.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Section from "@/components/layout/Section.vue";
import Grid from "@/components/layout/Grid.vue";
import Card from "@/components/layout/Card.vue";
import Subtitle from "@/components/layout/Subtitle.vue";

import Value from "@/components/basic/Value.vue";
import InfoDialog from "@/components/basic/InfoDialog.vue";

import Textfield from "@/components/form/Textfield.vue";
import Checkbox from "@/components/form/Checkbox.vue";
import EditableTextfield from "@/components/form/EditableTextfield.vue";
import EditableSelect from "@/components/form/EditableSelect.vue";
import SearchSelect from "@/components/form/SearchSelect.vue";
import FileInput from "@/components/form/FileInput.vue";

import InteractivePlan from "@/components/services/InteractivePlan.vue";

import RequestButton from "@/components/basic/RequestButton.vue";
import ResponsiveIconButton from "@/components/basic/ResponsiveIconButton.vue";
import { LocalStorage } from "../../storage/LocalStorage";
import { Authorizator } from "../../common/interactors/Authorizator";
import { AuthorizatorStorageGateway } from "../../gateways/storage/AuthorizatorStorageGateway";
import { PropertyPlanViewerCachedGateway } from "../../gateways/cached/PropertyPlanViewerCachedGateway";

@Component<PropertyPlanView>({
  components: {
    Page,
    Row,
    Column,
    Section,
    Grid,
    Card,
    Subtitle,
    Textfield,
    Checkbox,
    FileInput,
    EditableTextfield,
    EditableSelect,
    SearchSelect,
    RequestButton,
    ResponsiveIconButton,
    InteractivePlan,
    Value,
    InfoDialog
  },
  watch: {
    "vm.goBack"(go: boolean) {
      if (go) {
        this.$router.back();
      }
    },
    "vm.plans.selected"(planId: string) {
      if (planId) {
        this.controller.loadPlan(planId);
      }
    }
  }
})
export default class PropertyPlanView extends Vue {
  private vm = new PropertyPlanViewModel();
  private controller!: PropertyPlanController;

  private createController() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    const planViewerGateway = new PropertyPlanViewerCachedGateway(
      new PropertyPlanViewerGraphQLGateway(connection)
    );

    return new PropertyPlanController(
      new PropertyPlanPresenter(this.vm),
      new PropertyPlanManager(
        new PropertyPlanManagerGraphQLGateway(connection, planViewerGateway),
        new PropertyPlanViewer(planViewerGateway)
      ),
      new LocalStorage()
    );
  }

  private get propertyId(): string {
    return this.$route.params.propertyId;
  }

  private created() {
    this.controller = this.createController();
  }

  private mounted() {
    this.controller.init(this.propertyId);
  }
}
