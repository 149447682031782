import { NumericalId } from "@/datastructures/NumericalId";
import { AssignmentForm } from "@/forms/checklist/AssignmentForm";
import {
  IAssignmentManager,
  FullAssignmentData,
  AssignmentDataData
} from "../interactors/AssignmentManager";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";

export class AssignmentDetailController {
  public constructor(
    private presenter: IAssignmentDetailPresenter,
    private assignmentManager: IAssignmentManager
  ) {}

  public init(plantId: string, assignmentId: string) {
    this.presenter.plantId = plantId;
    this.presenter.assignmentId = assignmentId;

    this.loadAssignment(assignmentId);
    this.presenter.init();
  }

  // Input
  public numberChanged(num: string) {
    this.presenter.assignmentForm.setFieldValue("number", num);
  }

  public dateChanged(date: string) {
    this.presenter.assignmentForm.setFieldValue("date", date);
  }

  public descriptionChanged(description: string) {
    this.presenter.assignmentForm.setFieldValue("description", description);
  }

  // Actions
  public enableDataEditing() {
    this.presenter.editingAssignmentData = true;
  }

  // Requests
  public loadAssignment(assignmentId: string) {
    NumericalId.onValidId(assignmentId, id => {
      const request = this.assignmentManager.loadAssignment(id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadAssignmentResponse = response),
        "Auftrag konnte nicht geladen werden"
      );
    });
  }

  public disableDataEditing() {
    const request = this.assignmentManager.updateAssignmentData(
      this.presenter.assignment
    );

    FormRequestHandler.handle(
      request,
      response => (this.presenter.updateDataResponse = response)
    );
  }

  public createAssignmentButtonClicked() {
    NumericalId.onValidId(this.presenter.plantId, () => {
      const request = this.assignmentManager.createAssignment(
        this.presenter.assignment
      );

      FormRequestHandler.handle(
        request,
        response => (this.presenter.createAssignmentResponse = response),
        "Auftrag konnte nicht erstellt werden"
      );
    });
  }

  public deleteAssignmentButtonClicked(assignmentId: string) {
    NumericalId.onValidId(assignmentId, id => {
      const request = this.assignmentManager.deleteAssignment(id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.deleteAssignmentResponse = response),
        "Auftrag kann nicht gelöscht werden"
      );
    });
  }
}

export interface IAssignmentDetailPresenter {
  // Forms
  assignmentForm: AssignmentForm;

  // Data
  plantId: string;
  assignmentId: string;
  assignment: FullAssignmentData;
  editingAssignmentData: boolean;

  // Responses
  createAssignmentResponse: FormResponse<NumericalId>;
  loadAssignmentResponse: FormResponse<FullAssignmentData>;
  updateDataResponse: FormResponse<NumericalId>;
  deleteAssignmentResponse: FormResponse<NumericalId>;

  init(): void;
}
