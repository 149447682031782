import {
  PropertyGateway,
  CreatePropertyData,
  UpdatePropertyGeneralData,
  UpdatePropertyDetails,
  UpdatePropertyAddress,
  RelationshipInput
} from "@/admin/interactors/PropertyManager";
import { GraphQLConnection } from "./connection/GraphQLConnection";
import { Property } from "@/entities/Property";
import { NumericalId } from "@/datastructures/NumericalId";
import { Address } from "@/entities/Address";
import { Location } from "@/entities/Location";
import { Image } from "@/entities/Image";
import { Relationship } from "@/entities/Relationship";
import { Person } from "@/entities/Person";
import { ArrayUtils } from "@/utils/ArrayUtils";

export class PropertyGraphQLGateway implements PropertyGateway {
  public constructor(private connection: GraphQLConnection) {}

  public createProperty(data: CreatePropertyData): Promise<Property> {
    return this.createProp(data)
      .then(propertyId => this.createAddress(data, propertyId))
      .then(propertyId => new Property(propertyId));
  }

  public deleteProperty(id: NumericalId): Promise<Property> {
    return this.connection
      .mutation("deleteProperty", { id: id.id }, ["id"])
      .then(response => new Property(response.data.id));
  }

  public loadProperty(id: NumericalId): Promise<Property> {
    return this.loadProp(id);
  }

  public updateGeneralData(data: UpdatePropertyGeneralData): Promise<Property> {
    return this.connection
      .mutation(
        "updateProperty",
        {
          input: { id: data.id, name: data.name, description: data.description }
        },
        ["id"]
      )
      .then(response => new Property(response.data.id));
  }

  public updateDetails(data: UpdatePropertyDetails): Promise<Property> {
    return this.connection
      .mutation(
        "updateProperty",
        {
          input: {
            id: data.id,
            legal_form: data.legalForm,
            cadastral_community: data.cadastralCommunity,
            asset_number: data.assetNumber,
            build_year: data.buildYear,
            parcel_number: data.parcelNumber,
            single_cadastre: data.singleCadastre,
            effective_area: data.effectiveArea,
            unit_count: data.unitCount,
            dws_base_number: data.dwsBaseNumber
          }
        },
        ["id"]
      )
      .then(response => new Property(response.data.id));
  }

  public updateAddress(data: UpdatePropertyAddress): Promise<Property> {
    return this.connection
      .mutation(
        "updateProperty",
        {
          input: {
            id: data.id,
            latitude: data.lat,
            longitude: data.lng,
            address: {
              update: {
                id: data.addressId,
                street: data.street,
                postcode: data.zip,
                city: data.city,
                country: data.country
              }
            }
          }
        },
        ["id"]
      )
      .then(response => new Property(response.data.id));
  }

  public async setPropertyImage(image: File, propertyId: NumericalId) {
    const fileUpload = await this.connection.uploadFile(
      image,
      `property/${propertyId.id}`,
      image.name,
      ["id", "path"],
      undefined,
      { id: propertyId.id, type: "App\\\\Property" }
    );
    return {
      id: fileUpload.data.id,
      path: fileUpload.data.path
    };
  }

  public async deletePropertyImage(id: NumericalId): Promise<void> {
    await this.connection.mutation("deleteFile", { id: id.id }, ["id"]);
  }

  public async upsertRelationship(
    propertyId: NumericalId,
    data: RelationshipInput
  ): Promise<number> {
    const relationship = {
      id: data.id ? data.id : undefined,
      person_id: data.personId,
      role: data.relationshipId,
      subrole: data.roleId
    };

    const result = await this.connection.mutation(
      "updateProperty",
      {
        input: {
          id: propertyId.id,
          relationships: {
            create: !data.id ? [relationship] : undefined,
            update: data.id ? [relationship] : undefined
          }
        }
      },
      ["id", { name: "relationships", fields: ["id"] }]
    );

    return ArrayUtils.findMax(result.data.relationships, "id").id;
  }

  public async deleteRelationship(
    propertyId: NumericalId,
    relationshipId: NumericalId
  ): Promise<void> {
    await this.connection.mutation(
      "updateProperty",
      {
        input: {
          id: propertyId.id,
          relationships: {
            delete: [relationshipId.id]
          }
        }
      },
      ["id"]
    );
  }

  private createProp(data: CreatePropertyData): Promise<number> {
    return this.connection
      .mutation(
        "createProperty",
        {
          input: {
            name: data.name,
            description: data.description,
            legal_form: data.legalForm,
            cadastral_community: data.cadastralCommunity,
            asset_number: data.assetNumber,
            build_year: data.buildYear,
            parcel_number: data.parcelNumber,
            single_cadastre: data.singleCadastre,
            effective_area: data.effectiveArea,
            dws_base_number: data.dwsBaseNumber,
            unit_count: data.unitCount,
            longitude: data.lng,
            latitude: data.lat,
            client: {
              connect: data.clientId
            },
            relationships: {
              create: data.relationships.map(r => ({
                person_id: r.personId,
                role: r.relationshipId,
                subrole: r.roleId
              }))
            }
          }
        },
        ["id"]
      )
      .then(response => response.data.id);
  }

  private createAddress(
    data: CreatePropertyData,
    propertyId: number
  ): Promise<number> {
    return this.connection
      .mutation(
        "createAddress",
        {
          input: {
            context: "property",
            street: data.street,
            postcode: data.zip,
            city: data.city,
            country: data.country,
            addressable: {
              connect: {
                id: propertyId,
                type: "App\\Property"
              }
            }
          }
        },
        ["id", { name: "addressable", fragment: "Property", fields: ["id"] }]
      )
      .then(response => response.data.addressable.id);
  }

  private loadProp(id: NumericalId): Promise<Property> {
    return this.connection
      .query("property", { id: id.id }, [
        "id",
        "name",
        "description",
        "legal_form",
        "cadastral_community",
        "asset_number",
        "build_year",
        "parcel_number",
        "single_cadastre",
        "effective_area",
        "dws_base_number",
        "unit_count",
        "longitude",
        "latitude",
        {
          name: "address",
          fields: ["id", "street", "postcode", "city", "country"]
        },
        {
          name: "image",
          fields: ["id", "path"]
        },
        {
          name: "relationships",
          fields: [
            "id",
            "role",
            "subrole",
            {
              name: "person",
              fields: [
                "id",
                "firstname",
                "lastname",
                { name: "addresses", fields: ["street", "city", "postcode"] }
              ]
            }
          ]
        }
      ])
      .then(response => {
        const data = response.data;
        const property = new Property(data.id);

        property.name = data.name;
        property.description = data.description;
        property.legalForm = data.legal_form;
        property.cadastralCommunity = data.cadastral_community;
        property.assetNumber = data.asset_number;
        property.buildYear = data.build_year;
        property.parcelNumber = data.parcel_number;
        property.singleCadastre = data.single_cadastre;
        property.effectiveArea = data.effective_area;
        property.dwsBaseNumber = data.dws_base_number;
        property.unitCount = data.unit_count;

        const address = new Address(
          data.address.street,
          data.address.postcode,
          data.address.city
        );
        address.id = data.address.id;
        address.location = new Location(data.latitude, data.longitude);
        address.country = data.address.country;

        if (!!data.image) {
          const imageData = data.image;
          property.image = new Image(imageData.id, imageData.path);
        }

        property.relationships = data.relationships.map((r: any) => {
          const relationship = new Relationship(r.id);
          relationship.role = r.role;
          relationship.subRole = r.subrole;

          relationship.person = new Person(r.person.id);
          relationship.person.firstname = r.person.firstname;
          relationship.person.lastname = r.person.lastname;

          if (r.person.addresses && r.person.addresses.length > 0) {
            relationship.person.addresses.push(
              new Address(
                r.person.addresses[0].street,
                r.person.addresses[0].postcode,
                r.person.addresses[0].city
              )
            );
          }

          return relationship;
        });

        property.address = address;

        return property;
      });
  }
}
