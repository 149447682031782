
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { Ticket } from "@/inspection/shared/models/Ticket";
import { InspectionSettingsContainer } from "@/inspection/shared/settings/InspectionSettingsContainer";

@Component<TicketPriorityIcon>({
  components: {}
})
export default class TicketPriorityIcon extends Vue {
  @Prop({ type: Object, default: () => new Ticket() })
  protected readonly ticket!: Ticket;
  @Prop({ type: Boolean, default: false })
  protected readonly displayLabel!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly noWrap!: boolean;
  @Prop({ type: String })
  protected readonly label?: string;

  protected get priorityLabel() {
    return this.label ?? this.priority?.label ?? "";
  }

  protected get icon() {
    return this.priority?.icon ?? "";
  }

  protected get iconColor() {
    return this.priority?.color ?? "";
  }

  protected get priority() {
    return InspectionSettingsContainer.priorities.getPriority(
      this.ticket.priority ?? ""
    );
  }
}
