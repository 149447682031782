import { IndexDictionary, Dictionary } from "@/datastructures/Dictionary";
import { AssignmentStorage } from "./AssignmentStorage";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { storageKeys } from "@/data/storageKeys";
import { Page } from "@/datastructures/Page";
import { AssignmentCatalogRow } from "@/expert/interactors/AssignmentManager";

export class PlantStorage {
  private plantAssignments: IndexDictionary<AssignmentStorage> = {};
  private typeToIdMap: Dictionary<number> = {};

  public constructor(private storage: SimpleStringStorage) {
    this.load();
  }

  public upsertPlantId(propertyId: number, type: string, id: number) {
    this.typeToIdMap[propertyId.toString() + "_" + type] = id;
    this.save();
  }

  public upsertAssignments(
    plantId: number,
    assignments: AssignmentCatalogRow[]
  ) {
    this.getAssignmentStorage(plantId).upsertAssignments(assignments);
    this.save();
  }

  public loadPlantByType(propertyId: number, type: string): number {
    const id = this.typeToIdMap[propertyId.toString() + "_" + type];
    if (!!id) {
      return id;
    } else {
      throw new Error("Keine Anlagen zwischengespeichert");
    }
  }

  public loadAssignmentsPaginated(plantId: number, page: Page) {
    return this.getAssignmentStorage(plantId).loadPaginated(page);
  }

  private load() {
    this.storage.getJson(storageKeys.plants, true).map((plant: any) => {
      this.typeToIdMap[plant.type] = plant.id;
      this.getAssignmentStorage(plant.id).upsertAssignments(plant.assignments);
    });
  }

  private save() {
    this.storage.setJson(
      storageKeys.plants,
      Object.entries(this.typeToIdMap).map(([type, id]) => ({
        type,
        id,
        assignments: this.getAssignmentStorage(id).loadAll()
      }))
    );
  }

  private getAssignmentStorage(plantId: number) {
    if (!this.plantAssignments[plantId]) {
      this.plantAssignments[plantId] = new AssignmentStorage();
    }

    return this.plantAssignments[plantId];
  }
}
