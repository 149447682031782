import { SettingsManagerDataGateway } from "@/admin/interactors/SettingsManager";
import { GraphQLConnection } from "./connection/GraphQLConnection";
import { NumericalId } from "@/datastructures/NumericalId";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { Page } from "@/datastructures/Page";
import { SettingMeta } from "@/entities/SettingMeta";
import { Setting } from "@/entities/Setting";

export class SettingsManagerGraphQLGateway
  implements SettingsManagerDataGateway {
  public constructor(private connection: GraphQLConnection) {}

  public loadSettingsCatalog(
    clientId: NumericalId,
    page: Page
  ): Promise<PaginatedList<SettingMeta>> {
    return this.connection
      .queryPaginated(
        "settingMetas",
        page.itemsPerPage,
        page.page,
        {},
        ["id", "key", "overwritable"],
        ""
      )
      .then(response => ({
        items: response.data.map((meta: any) => {
          const setting = new SettingMeta(meta.id);

          setting.key = meta.key;
          setting.overwritable = meta.overwritable;

          return setting;
        }),
        totalCount: response.count
      }));
  }

  public async loadSettings(userId: NumericalId) {
    const settingsResponse = await this.connection.query(
      "user",
      { id: userId.id },
      ["settings"]
    );

    const settings = JSON.parse(settingsResponse.data.settings);

    return Object.entries(settings).map(setting => ({
      key: setting[0],
      setting: JSON.stringify(setting[1])
    }));
  }

  public loadSetting(settingKey: string, clientId: NumericalId) {
    return this.connection
      .query("setting", { key: settingKey, client_id: clientId.id }, [
        "id",
        "key",
        "entry",
        "variables"
      ])
      .then(response => ({
        id: response.data.id,
        key: response.data.key,
        entry: response.data.entry,
        variables: response.data.variables
      }));
  }

  public loadGlobalSetting(settingKey: string) {
    return this.connection
      .query("setting", { key: settingKey, client_id: 0 }, [
        "id",
        "key",
        "entry",
        "variables"
      ])
      .then(response => ({
        id: response.data.id,
        key: response.data.key,
        entry: response.data.entry,
        variables: response.data.variables
      }));
  }

  public loadSettingMeta(settingKey: string) {
    return this.connection
      .query("settingMeta", { key: settingKey }, [
        "id",
        "key",
        "fields",
        "variables"
      ])
      .then(response => ({
        id: response.data.id,
        key: response.data.key,
        fields: response.data.fields,
        variables: response.data.variables
      }));
  }

  public async saveSetting(setting: Setting) {
    const action = setting.id > 0 ? "updateSetting" : "createSetting";
    const response = await this.connection.mutation(
      action,
      {
        input: {
          id: setting.id > 0 ? setting.id : undefined,
          key: setting.key,
          entry: setting.entry,
          variables: setting.variables,
          client_id: setting.client.id
        }
      },
      ["id"]
    );

    return NumericalId.fromString(response.data.id);
  }
}
