
import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import StyledText from "@/components/basic/StyledText.vue";

import ClientSelect from "@/property/shared/widgets/views/ClientSelect.vue";
import EmployeeSelect from "@/property/shared/widgets/views/EmployeeSelect.vue";

import { AccessRecipientVm } from "./vms/AccessRecipientVm";
import { EmployeeModel } from "../../models/EmployeeModel";

@Component<AccessRecipient>({
  components: {
    Row,
    Column,
    ClientSelect,
    EmployeeSelect,
    StyledText
  },
  props: {
    vm: {
      type: Object,
      default() {
        return new AccessRecipientVm();
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
export default class AccessRecipient extends Vue {
  private vm!: AccessRecipientVm;

  private selectEmployee(employee: EmployeeModel) {
    this.vm.selectEmployee(employee);
    this.$emit("select", employee);
  }
}
