import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { GraphQLConnection } from "@/gateways/graphql/connection/GraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { Dictionary } from "@/shared/datastructures/Dictionary";
import { Paginated } from "@/shared/datastructures/Paginated";
import { PlanLocationEntity } from "@/shared/project/planLocation/PlanLocationEntity";
import { TypeChecker } from "@/shared/utils/TypeChecker";
import { Plant } from "../../dtos/Plant";
import {
  GeneralPlantData,
  PlantEntity,
  PlantStructure
} from "../../entities/PlantEntity";
import { PlantProvider } from "./PlantProvider";

export class PlantGraphQLProvider implements PlantProvider {
  public constructor(
    private connection: GraphQLConnection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    )
  ) {}

  public async loadPlant(id: number): Promise<PlantEntity> {
    const result = await this.connection.query(
      "plant",
      {
        id
      },
      [
        "id",
        "name",
        "bim_number",
        "description",
        "type",
        "data",
        "plant_types",
        "field_structure",
        "checklist_entries",
        "checklist_template",
        "location_x",
        "location_y",
        { name: "plan", fields: ["id", "name"] },
        { name: "property", fields: ["id", "name"] },
        { name: "parts", fields: ["id", "name"] },
        { name: "images", fields: ["id", "path"] },
        { name: "parentPlants", fields: ["id", "name"] },
        { name: "childPlants", fields: ["id", "name"] },
        { name: "siblingPlants", fields: ["id", "name"] }
      ]
    );

    return PlantGraphQLProvider.convertToPlantEntity(result.data);
  }

  public async loadPlantLocation(id: number): Promise<PlanLocationEntity> {
    const result = await this.connection.query(
      "plant",
      {
        id
      },
      [
        "location_x",
        "location_y",
        { name: "plan", fields: ["id", "name"] },
        { name: "property", fields: ["id", "name"] }
      ]
    );

    return (
      PlantGraphQLProvider.convertToPlantEntity(result.data).location ?? {}
    );
  }

  public async loadChildPlants(id: number): Promise<PlantEntity[]> {
    const result = await this.connection.query(
      "childPlants",
      {
        plant_id: id
      },
      ["id", "name", "has_child_plants"]
    );

    return PlantGraphQLProvider.convertToPlantEntities(result.data);
  }

  public async loadBasePlants(
    propertyId: number | number[],
    distinctByType: boolean
  ): Promise<Plant[]> {
    if (!TypeChecker.isArray(propertyId)) {
      propertyId = [propertyId];
    }

    const result = await this.connection.query(
      "basePlants",
      { property_ids: propertyId, distinctByType },
      [
        "id",
        "type",
        "checklist_template",
        {
          name: "property",
          fields: [
            "id",
            { name: "address", fields: ["street", "postcode", "city"] }
          ]
        }
      ]
    );

    const plants = this.convertToPlants(result.data);

    return plants;
  }

  public async getPlantsPaginated(
    propertyIds: number[],
    includePropertyPartPlants?: boolean,
    search?: string,
    page?: number,
    count?: number
  ): Promise<Paginated<PlantEntity>> {
    const result = await this.connection.queryPaginated(
      "plantsPaginated",
      count ?? 20,
      page ?? 1,
      {
        property_ids: propertyIds,
        include_property_part_plants: includePropertyPartPlants
      },
      [
        "id",
        "name",
        "description",
        "type",
        "plant_types",
        "field_structure",
        "checklist_entries",
        "checklist_template",
        "sibling_part_id",
        "location_x",
        "location_y",
        { name: "plan", fields: ["id", "name"] },
        { name: "property", fields: ["id", "name"] },
        { name: "parts", fields: ["id", "name"] }
      ],
      search ?? ""
    );

    return new Paginated(
      PlantGraphQLProvider.convertToPlantEntities(result.data),
      result.count
    );
  }

  public static convertToPlantEntities(data: any[]): PlantEntity[] {
    return data.map(p => this.convertToPlantEntity(p));
  }

  public static convertToPlantEntity(data?: any): PlantEntity {
    if (!data) {
      return {};
    }

    return {
      id: parseInt(data.id?.toString() ?? "0", 10),
      name: data.name,
      bimNumber: data.bim_number,
      description: data.description,
      types: data.plant_types,
      data: data.data,
      siblingPartId: data.sibling_part_id,
      property: data.property
        ? {
            id: parseInt(data.property.id.toString(), 10),
            name: data.property.name
          }
        : undefined,
      parts:
        data.parts?.map((part: Dictionary<any>) => ({
          id: parseInt(part.id.toString(), 10),
          name: part.name
        })) ?? undefined,
      hasChildPlants: data.has_child_plants,
      fieldCategories: data.field_structure
        ? JSON.parse(data.field_structure)
        : undefined,
      checklistEntries: data.checklist_entries
        ? JSON.parse(data.checklist_entries)
        : undefined,
      location: data.plan
        ? {
            plan: {
              id: parseInt(data.plan.id.toString(), 10),
              name: data.plan.name
            },
            x: data.location_x,
            y: data.location_y
          }
        : undefined,
      images:
        data.images?.map((image: Dictionary<unknown>) => ({
          id: image.id,
          path: image.path
        })) ?? undefined,
      parentPlants:
        data.parentPlants?.map((plant: Dictionary<any>) =>
          this.convertToPlantEntity(plant)
        ) ?? undefined,
      childPlants:
        data.childPlants?.map((plant: Dictionary<any>) =>
          this.convertToPlantEntity(plant)
        ) ?? undefined,
      siblingPlants:
        data.siblingPlants?.map((plant: Dictionary<any>) =>
          this.convertToPlantEntity(plant)
        ) ?? undefined
    };
  }

  private convertToPlants(data: any[]): Plant[] {
    return data.map(p => ({
      id: p.id,
      type: p.type,
      checklist: p.checklist_template,
      property: {
        id: p.property?.id || 0,
        street: p.property?.address?.street || "",
        zip: p.property?.address?.postcode || "",
        city: p.property?.address?.city || ""
      }
    }));
  }

  public async createPlant(
    plant: PlantEntity,
    propertyId: number,
    partId?: number,
    parentPlantId?: number,
    siblingPartId?: number
  ): Promise<string> {
    const result = await this.connection.mutation(
      "createPlant",
      {
        input: {
          plant: {
            name: plant.name,
            description: plant.description,
            bim_number: plant.bimNumber,
            plant_types: plant.types,
            location: plant.location?.plan?.id
              ? {
                  plan_id: plant.location?.plan?.id,
                  x: plant.location?.x,
                  y: plant.location?.y
                }
              : undefined,
            field_categories: plant.fieldCategories,
            checklist_entries: plant.checklistEntries
          },
          property_id: propertyId,
          part_id: partId,
          sibling_part_id: siblingPartId,
          parent_plant_id: parentPlantId
        }
      },
      []
    );

    return result.data;
  }

  public async updateGeneralData(
    id: number,
    data: GeneralPlantData
  ): Promise<string> {
    const result = await this.connection.mutation(
      "updateGeneralPlantData",
      {
        input: {
          id,
          name: data.name,
          description: data.description,
          bim_number: data.bimNumber
        }
      },
      []
    );

    return result.data;
  }

  public async updateData(id: number, data: string): Promise<string> {
    const result = await this.connection.mutation(
      "updatePlantData",
      {
        input: {
          id,
          data
        }
      },
      []
    );

    return result.data;
  }

  public async updateLocation(
    id: number,
    location: PlanLocationEntity
  ): Promise<string> {
    const result = await this.connection.mutation(
      "updatePlantLocation",
      {
        input: {
          id,
          location: { plan_id: location.plan?.id, x: location.x, y: location.y }
        }
      },
      []
    );

    return result.data;
  }

  public async updateStructure(
    id: number,
    structure: PlantStructure
  ): Promise<string> {
    const result = await this.connection.mutation(
      "updatePlantStructure",
      {
        input: {
          id,
          plant_types: structure.types,
          field_categories: structure.fieldCategories,
          checklist_entries: structure.checklistEntries
        }
      },
      []
    );

    return result.data;
  }

  public async uploadPlantImage(id: number, image: File): Promise<number> {
    const result = await this.connection.mutation(
      "uploadPlantImage",
      {
        input: {
          id,
          image: null
        }
      },
      [],
      undefined,
      {
        file: image,
        path: "input.image"
      }
    );

    return result.data;
  }

  public async deletePlantImage(id: number): Promise<number> {
    const result = await this.connection.mutation(
      "deletePlantImage",
      {
        id
      },
      []
    );

    return result.data;
  }

  public async deletePlant(id: number): Promise<string> {
    const result = await this.connection.mutation(
      "deletePlant",
      {
        id
      },
      []
    );

    return result.data;
  }
}
