import { RoleEntity } from "../entities/RoleEntity";

interface RoleProps {
  id?: number;
  name?: string;
}

export class Role {
  public id?: number;
  public name?: string;

  public constructor(props?: RoleProps) {
    this.id = props?.id;
    this.name = props?.name;
  }

  public get exists() {
    return !!this.id;
  }

  public toEntity(): RoleEntity {
    return {
      id: this.id,
      name: this.name
    };
  }

  public static from(role?: RoleEntity) {
    return new Role({
      id: role?.id,
      name: role?.name
    });
  }
}
