import { DateUtils } from "../utils/DateUtils";
import { TypeChecker } from "../utils/TypeChecker";

export class TimeSpan {
  public from: Date;
  public to: Date;

  public constructor(
    from: Date | string | number = new Date(0),
    to: Date | string | number = new Date(0)
  ) {
    if (TypeChecker.isDate(from)) {
      this.from = from;
    } else {
      this.from = new Date(from);
    }

    if (TypeChecker.isDate(to)) {
      this.to = to;
    } else {
      this.to = new Date(to);
    }
  }

  public get isDefined() {
    return this.hasFrom && this.hasTo;
  }

  public get hasFrom() {
    return this.from.getTime() !== 0;
  }

  public get hasTo() {
    return this.to.getTime() !== 0;
  }

  public get isMoment() {
    return this.from.getTime() === this.to.getTime();
  }
}
