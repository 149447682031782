import {
  Textfield,
  Request,
  Selection,
  Checkbox,
  Numberfield,
  SingleFileInput,
  TableHeader
} from "@/forms/ViewModelFormTypes";
import { Dictionary } from "@/datastructures/Dictionary";

export class PropertyDetailViewModel {
  public propertyId = 0;
  public addressId = 0;
  public goBack = false;

  public createdProperty = 0;
  public deletedProperty = "";
  public propertyImageId = 0;
  public propertyImagePath = "";
  public relationships: Dictionary<RelationshipStructureDTO> = {};

  public relationshipDialogVisible = false;
  public relationshipDialogTitle = "";
  public saveRelationshipButtonDisabled = false;
  public addRelationshipButtonVisible = false;
  public editRelationshipButtonVisible = false;
  public deleteRelationshipButtonVisible = false;

  public deletePropertyButtonVisible = false;
  public createPropertyButtonVisible = false;
  public createPropertyButtonDisabled = false;

  public propertyLocationVisible = false;
  public propertyImageVisible = false;
  public propertyImageInputVisible = true;

  public editPropertyImageAllowed = false;

  // Requests
  public loadPropertyRequest: Request = { loading: false, error: "" };
  public createPropertyRequest: Request = { loading: false, error: "" };
  public deletePropertyRequest: Request = { loading: false, error: "" };

  public editingGeneralDataRequest: Request = { loading: false, error: "" };
  public editingDetailsRequest: Request = { loading: false, error: "" };
  public editingAddressRequest: Request = { loading: false, error: "" };

  // public setPropertyImageRequest: Request = { loading: false, error: "" };
  public deletePropertyImageRequest: Request = { loading: false, error: "" };
  public deleteRelationshipRequest: Request = { loading: false, error: "" };
  public upsertRelationshipRequest: Request = { loading: false, error: "" };

  // Edits
  public editingGeneralData = false;
  public editingGeneralDataDisabled = false;
  public editingGeneralDataDeactivated = false;

  public editingDetails = false;
  public editingDetailsDisabled = false;
  public editingDetailsDeactivated = false;

  public editingAddress = false;
  public editingAddressDisabled = false;
  public editingAddressDeactivated = false;

  // Form fields
  public name: Textfield = {
    label: "Name",
    value: "",
    error: "",
    loading: false
  };
  public description: Textfield = {
    label: "Beschreibung",
    value: "",
    error: "",
    loading: false
  };
  public legalForm: Textfield = {
    label: "Rechtsform",
    value: "",
    error: "",
    loading: false
  };
  public cadastralCommunity: Textfield = {
    label: "Katastralgemeinde",
    value: "",
    error: "",
    loading: false
  };
  public assetNumber: Textfield = {
    label: "Einlagezahl",
    value: "",
    error: "",
    loading: false
  };
  public buildYear: Numberfield = {
    label: "Baujahr",
    value: 0,
    error: "",
    loading: false
  };
  public parcelNumber: Textfield = {
    label: "Grundstücksnummer",
    value: "",
    error: "",
    loading: false
  };
  public effectiveArea: Numberfield = {
    label: "Nutzfläche",
    value: 0,
    error: "",
    loading: false
  };
  public unitCount: Numberfield = {
    label: "Anzahl Einheiten",
    value: 0,
    error: "",
    loading: false
  };
  public singleCadastre: Checkbox = {
    label: "Einzelgrundbuchblätter",
    value: false,
    selected: false,
    error: "",
    loading: false
  };
  public dwsBaseNumber: Textfield = {
    label: "DWSBase-Nummer",
    value: "",
    error: "",
    loading: false
  };
  public street: Textfield = {
    label: "Straße",
    value: "",
    error: "",
    loading: false
  };
  public zip: Textfield = {
    label: "Postleitzahl",
    value: "",
    error: "",
    loading: false
  };
  public city: Textfield = {
    label: "Stadt",
    value: "",
    error: "",
    loading: false
  };
  public country: Selection = {
    label: "Land",
    selected: "",
    error: "",
    loading: false,
    items: []
  };
  public latitude = 0;
  public longitude = 0;

  public propertyImage: SingleFileInput = {
    label: "Gebäudebild",
    value: undefined,
    loading: false,
    error: ""
  };

  public selectedRelationship?: RelationshipDTO;
  public relationshipTypeId: string = "";
  public relationshipId: number = 0;
  public relationshipRole: Selection = {
    label: "Rolle",
    selected: "",
    error: "",
    loading: false,
    items: []
  };
  public relationshipPerson: Selection = {
    label: "Person",
    selected: "",
    error: "",
    loading: false,
    items: []
  };

  public relationshipTableHeadersMobile: TableHeader[] = [
    { text: "Beziehungen", value: "role" }
  ];
}

interface RelationshipStructureDTO {
  id: string;
  name: string;
  tableHeader: TableHeader[];
  tableHeaderTablet: TableHeader[];
  tableHeaderMobile: TableHeader[];
  roles: RelationshipRoleDTO[];
  relationships: RelationshipDTO[];
}

interface RelationshipRoleDTO {
  id: string;
  name: string;
}

export interface RelationshipDTO {
  id: number;
  relationshipTypeId: string;
  roleId: string;
  roleLabel: string;
  personId: number;
  personLabel: string;
}
