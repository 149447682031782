import { GeneralPlantData } from "@/property/shared/entities/PlantEntity";
import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";

export class GeneralPlantDataForm extends Form<GeneralPlantData> {
  protected definition = {
    name: {
      label: "Anlagenbezeichnung",
      type: FormFieldType.Text,
      required: true
    },
    description: {
      label: "Beschreibung",
      type: FormFieldType.Text,
      required: false
    },
    bimNumber: {
      label: "BIM-Nummer",
      type: FormFieldType.Text,
      required: false
    }
  };
}
