
import Vue from "vue";
import Component from "vue-class-component";

import PropertyStructure from "./propertyStructureComponents/PropertyStructure.vue";

@Component<PropertyStructurePage>({
  components: {
    PropertyStructure
  }
})
export default class PropertyStructurePage extends Vue {
  protected get propertyId() {
    return parseInt(this.$route.params.propertyId, 10);
  }
}
