import { Property } from "@/property/shared/dtos/Property";
import { PropertyReportContainer } from "@/property/shared/repositories/PropertyRepoContainer";
import { Paginated } from "@/shared/datastructures/Paginated";
import { ServerResponse } from "@/shared/datastructures/Response";
import { TableHeader } from "@/shared/datastructures/TableHeader";
import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { UserSettings } from "@/storage/UserSettings";
import { PropertyModel } from "./PropertyModel";

export const tableHeaders: TableHeader[] = [
  {
    text: "Name",
    value: "name",
    visibleOnSize: ["xl", "lg", "md", "sm"]
  },
  { text: "PLZ", value: "zip", visibleOnSize: ["xl", "lg", "md", "sm"] },
  {
    text: "Adresse",
    value: "address",
    visibleOnSize: ["xl", "lg", "md", "sm"]
  },
  {
    text: "Baujahr",
    value: "buildYear",
    align: "center",
    visibleOnSize: ["xl", "lg"]
  },
  {
    text: "Anzahl",
    value: "unitCount",
    align: "center",
    visibleOnSize: ["xl", "lg"]
  },
  {
    text: "Rechtsform",
    value: "legalForm",
    align: "center",
    visibleOnSize: ["xl", "lg"]
  },
  {
    text: "Nutzfläche",
    value: "effectiveArea",
    align: "center",
    visibleOnSize: ["xl", "lg"]
  },
  { text: "Details", value: "details", visibleOnSize: ["md", "sm"] },
  { text: "Gebäude", value: "property", visibleOnSize: ["xs"] },
  { text: "", value: "actions", align: "end" }
];

export class PropertyListModel extends RequestModel<Paginated<Property>> {
  public properties: PropertyModel[] = [];
  public totalProperties = 20;
  public currentPage = 1;

  public selectResponse = new ServerResponse<number[]>();

  public get selectedProperties() {
    return this.properties.filter(p => p.selected);
  }

  public load(page: number, search = "") {
    this.currentPage = page;
    return RequestHandler.handleModel(
      () =>
        PropertyReportContainer.propertyRepo.getProperties(
          UserSettings.getNumericUserId(),
          page,
          search
        ),
      this
    );
  }

  public selectProperties(selectedProperties: PropertyModel[]) {
    this.properties.forEach(p => p.select(false));

    selectedProperties.forEach(selectedProperty => {
      const property = this.properties.find(p => p.id === selectedProperty.id);
      if (property) {
        property.select(true);
      }
    });
  }

  public async selectProperty(property: PropertyModel) {
    const prevState = property.selected;
    property.select();

    if (property.selected) {
      return await RequestHandler.handle(
        PropertyReportContainer.propertyRepo.selectProperties(
          this.selectedProperties
        ),
        [this.selectResponse, property.selectResponse],
        {
          onFailure: () => property.select(prevState)
        }
      );
    }

    return false;
  }

  public from(data: Paginated<Property>): void {
    this.totalProperties = data.totalCount;
    this.properties = data.items.map(p => PropertyModel.from(p));
  }
}
