
import Vue from "vue";
import Component from "vue-class-component";

import TicketFilter from "../widgets/TicketFilter.vue";
import TicketPlantFilter from "../widgets/TicketPlantFilter.vue";
import TicketTable from "../widgets/TicketTable.vue";
import TicketCard from "@/inspection/ticket/widgets/TicketCard.vue";
import CreateTicketPage from "@/inspection/ticket/views/CreateTicketPage.vue";

import { CheckTicketExistenceUseCase } from "../useCases/CheckTicketExistenceUseCase";
import {
  LoadTicketsOptions,
  LoadTicketsUseCase
} from "../useCases/LoadTicketsUseCase";
import { InspectionRepoContainer } from "@/inspection/shared/repositories/InspectionRepoContainer";
import { Ticket } from "@/inspection/shared/models/Ticket";
import { UserSettings } from "@/storage/UserSettings";
import { TicketCollection } from "@/inspection/shared/models/TicketCollection";
import { MathUtils } from "@/shared/utils/MathUtils";
import { Plant } from "@/property/shared/models/Plant";
import { PlantChecklistEntry } from "@/property/shared/models/PlantChecklistEntry";
import { Dictionary } from "@/datastructures/Dictionary";
import { Property } from "@/property/shared/models/Property";

@Component<TicketListPage>({
  components: {
    TicketTable,
    TicketFilter,
    TicketPlantFilter,
    TicketCard,
    CreateTicketPage
  }
})
export default class TicketListPage extends Vue {
  protected ticketChecker = new CheckTicketExistenceUseCase(
    InspectionRepoContainer.ticketRepo
  );

  protected ticketsLoader = new LoadTicketsUseCase(
    InspectionRepoContainer.ticketRepo
  );

  protected tickets = new TicketCollection();
  protected addedTickets = new TicketCollection();
  protected plant = new Plant();

  protected createTicketDialog = false;

  protected get ticketLoadOptions(): LoadTicketsOptions {
    return {
      client: UserSettings.getClient(),
      properties: this.properties,
      search: this.search,
      priorities: this.priorities,
      plant: this.plant,
      plantChecklistEntry: this.plantChecklistEntry,
      states: this.states,
      missingChecklistEntryReference: this.reference,
      page: this.page
    };
  }

  protected get hasPlant() {
    return !!this.plantFromQuery && this.plantFromQuery.exists;
  }

  protected get propertyId() {
    return this.properties.length === 1 ? this.properties[0].id : undefined;
  }

  protected get plantId() {
    return this.plantFromQuery?.id;
  }

  protected get plantChecklistEntryId() {
    return this.plantChecklistEntry?.id;
  }

  protected get search() {
    return (this.$route.query.search as string) ?? "";
  }

  protected get properties(): Property[] {
    const propertiesFromQuery = this.$route.query.properties as string;

    if (!propertiesFromQuery) {
      return this.$store.getters.getSelectedProperties ?? [];
    } else {
      const ids = JSON.parse(propertiesFromQuery) as number[];
      return ids.map(id => Property.from({ id }));
    }
  }

  protected get priorities() {
    const prioritiesFromQuery = this.$route.query.priorities as string;
    return !!prioritiesFromQuery
      ? (JSON.parse(prioritiesFromQuery) as string[])
      : [];
  }

  protected get states() {
    const statesFromQuery = this.$route.query.states as string;
    return !!statesFromQuery ? JSON.parse(statesFromQuery) : [];
  }

  protected get reference() {
    const referenceFromQuery = this.$route.query.reference as string;
    return MathUtils.parseBooleanOrUndefined(referenceFromQuery);
  }

  protected get plantFromQuery() {
    const plantIdFromQuery = this.$route.query.plant as string;
    return plantIdFromQuery
      ? Plant.from({ id: parseInt(plantIdFromQuery, 10) })
      : undefined;
  }

  protected get plantChecklistEntry() {
    const entryIdFromQuery = this.$route.query.entryTemplate as string;
    return entryIdFromQuery
      ? PlantChecklistEntry.from({ id: entryIdFromQuery })
      : undefined;
  }

  protected get page() {
    const pageFromQuery = this.$route.query.page as string;
    return pageFromQuery ? parseInt(pageFromQuery, 10) : 1;
  }

  protected get createTicketContext() {
    const context: string[] = [];

    if (this.plant.exists) {
      const checklistEntry = this.plant.getChecklistEntryById(
        this.plantChecklistEntryId
      );

      if (this.plant.name) context.push(this.plant.name);
      if (checklistEntry && checklistEntry.name)
        context.push(checklistEntry.name);
    }

    return context;
  }

  protected async checkAndOpenTicketById(idString: string) {
    const id = parseInt(idString, 10);
    const exists = await this.ticketChecker.do(Ticket.from({ id }));

    if (exists) {
      this.$router.push({
        name: "ticket",
        params: { ticketId: id.toString() }
      });
    } else {
      this.ticketChecker.setError("Ticket existiert nicht");
    }
  }

  protected checklistEntryChanged(checklistEntry?: PlantChecklistEntry) {
    this.$router.push({
      name: "tickets",
      params: this.$route.params,
      query: {
        ...this.$route.query,
        page: "1",
        entryTemplate: checklistEntry?.id
      }
    });
  }

  protected searchChanged(search: string) {
    this.$router.push({
      name: "tickets",
      params: this.$route.params,
      query: {
        ...this.$route.query,
        page: "1",
        search
      }
    });
  }

  protected propertiesChanged(properties: number[]) {
    this.$router.push({
      name: "tickets",
      params: this.$route.params,
      query: {
        ...this.$route.query,
        page: "1",
        properties: JSON.stringify(properties)
      }
    });
  }

  protected prioritiesChanged(priorities: string[]) {
    this.$router.push({
      name: "tickets",
      params: this.$route.params,
      query: {
        ...this.$route.query,
        page: "1",
        priorities: JSON.stringify(priorities)
      }
    });
  }

  protected statesChanged(states: string) {
    this.$router.push({
      name: "tickets",
      params: this.$route.params,
      query: {
        ...this.$route.query,
        page: "1",
        states: JSON.stringify(states)
      }
    });
  }

  protected referenceChanged(reference: boolean) {
    this.$router.push({
      name: "tickets",
      params: this.$route.params,
      query: {
        ...this.$route.query,
        page: "1",
        reference: reference ? "1" : "0"
      }
    });
  }

  protected pageChanged(page: number) {
    this.$router.push({
      name: "tickets",
      params: this.$route.params,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }

  protected removePlantFilter() {
    this.$router.push({
      name: "tickets",
      params: this.$route.params,
      query: {
        ...this.$route.query,
        page: "1",
        plant: undefined,
        entryTemplate: undefined
      }
    });
  }

  protected ticketCreated(ticket?: Ticket) {
    this.createTicketDialog = false;

    if (ticket) {
      ticket.tryLoadPeriodically();
      this.addedTickets.add(ticket);
    }
  }

  protected goToTicket(ticket: Ticket) {
    const params: Dictionary<string> = {
      ticketId: (ticket.id ?? 0).toString()
    };

    if (this.search) {
      params.search = this.search;
    }

    if (this.priorities.length > 0) {
      params.priority = this.priorities[0];
    }

    if (this.states.length > 0) {
      params.state = this.states[0];
    }

    if (this.plant) {
      params.plant = (this.plant.id ?? 0).toString();
    }

    if (this.plantChecklistEntry) {
      params.entryTemplate = this.plantChecklistEntry.id ?? "";
    }

    this.$router.push({ name: "ticket", params });
  }

  protected goToPlant(plant?: Plant) {
    if (!!plant && plant.exists) {
      this.$router.push({
        name: "plant",
        params: { plantId: (plant.id ?? 0).toString() }
      });
    }
  }

  protected async loadTickets() {
    this.tickets = await this.ticketsLoader.do(this.ticketLoadOptions);
  }

  protected mounted() {
    if (this.hasPlant) {
      this.plant = this.plantFromQuery ?? new Plant();
      this.plant.load();
    }

    this.loadTickets();
  }
}
