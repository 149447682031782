import { Ticket } from "@/inspection/shared/models/Ticket";
import { TicketRepository } from "@/inspection/shared/repositories/ticketRepository/TicketRepository";
import { UseCase } from "@/shared/datastructures/UseCase";
import { RequestHandler } from "@/shared/utils/RequestHandler";

export class CheckTicketExistenceUseCase extends UseCase<Ticket, boolean> {
  public constructor(private ticketRepo: TicketRepository) {
    super();
  }

  public async do(ticket: Ticket): Promise<boolean> {
    let exists = false;

    await RequestHandler.handle(
      this.ticketRepo.checkTicketExistence(ticket),
      this.response,
      {
        onSuccess: result => (exists = result)
      }
    );

    return exists;
  }
}

export interface CheckTicketExistenceUseCaseInput {
  ticketId: number;
}
