
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import ChecklistEntryTicket from "./ChecklistEntryTicket.vue";

import { ChecklistEntry } from "@/inspection/shared/models/ChecklistEntry";

@Component<ChecklistEntryTicketList>({
  components: { ChecklistEntryTicket }
})
export default class ChecklistEntryTicketList extends Vue {
  @Prop({ type: Object, default: () => new ChecklistEntry() })
  protected readonly entry!: ChecklistEntry;
}
