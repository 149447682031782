
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import TicketPriorityIcon from "./TicketPriorityIcon.vue";

import { TicketCollection } from "@/inspection/shared/models/TicketCollection";
import { TableHeader } from "@/shared/datastructures/TableHeader";
import { DateUtils } from "@/shared/utils/DateUtils";
import { Ticket } from "@/inspection/shared/models/Ticket";

@Component<TicketTable>({
  components: { TicketPriorityIcon }
})
export default class TicketTable extends Vue {
  @Prop({ type: Object, default: () => new TicketCollection() })
  protected readonly tickets!: TicketCollection;
  @Prop({ type: Boolean, default: false })
  protected readonly loading!: boolean;
  @Prop({ type: String, default: "" })
  protected readonly error!: string;
  @Prop({ type: Number, default: 1 })
  protected readonly page!: number;

  protected headers: TableHeader[] = [
    // { text: "", value: "selection", width: "1%" },
    { text: "Status", value: "state", visibleOnSize: ["sm", "md", "lg", "xl"] },
    {
      text: "Information",
      value: "information"
    },
    { text: "Gebäude", value: "property", visibleOnSize: ["lg", "xl"] },
    { text: "", value: "actions", align: "right", width: "1%" }
  ];

  protected pageChanged(page: number) {
    this.$emit("change:page", page);
  }

  protected formatDate(date: Date) {
    return DateUtils.format(date);
  }

  protected emitTicketClicked(ticket: Ticket) {
    this.$emit("click:ticket", ticket);
  }
}
