
import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";

@Component<ToggleCard>({
  components: {
    Card
  },
  props: {
    title: {
      type: String,
      default: "Titel"
    },
    loading: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "primary"
    },
    onIcon: {
      type: String,
      default: ""
    },
    offIcon: {
      type: String,
      default: ""
    },
    toggleLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    deactivated: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ""
    },
    expandable: {
      type: Boolean,
      default: false
    },
    startExpanded: {
      type: Boolean,
      default: false
    }
  }
})
export default class ToggleCard extends Vue {
  private state = false;
  private onIconVisible = false;
  private offIconVisible = true;
  private transitioning = false;

  private get headerColor() {
    return !!this.$props.error ? "error" : this.$props.color;
  }

  private toggleState() {
    if (this.transitioning || this.$props.deactivated) {
      return;
    }

    this.state = !this.state;
    this.transitioning = true;
    this.onIconVisible = false;
    this.offIconVisible = false;

    setTimeout(() => {
      this.onIconVisible = this.state;
      this.offIconVisible = !this.state;
      this.transitioning = false;

      if (this.state) {
        this.emitToggleOn();
      } else {
        this.emitToggleOff();
      }
    }, 400);
  }

  private emitToggleOn() {
    this.$emit("toggleOn");
  }

  private emitToggleOff() {
    this.$emit("toggleOff");
  }
}
