import { StringUtils } from "@/shared/utils/StringUtils";
import {
  PlantFieldCategoryData,
  PlantFieldCategoryEntity
} from "../entities/PlantEntity";
import { PlantTypeFieldCategory } from "../settings/PlantTypeSettings";
import { PlantField } from "./PlantField";
import { PlantFieldCollection } from "./PlantFieldCollection";

export class PlantFieldCategory {
  public id?: string = "";
  public name?: string = "";
  public fieldCollection?: PlantFieldCollection = new PlantFieldCollection();
  public linkedWithType?: boolean = false;

  public get fields() {
    return this.fieldCollection?.fields ?? [];
  }

  public get hasFields() {
    return this.fields.length > 0;
  }

  public containsField(field: PlantField) {
    return this.fieldCollection?.contains(field);
  }

  public merge(category?: PlantFieldCategory) {
    if (!category) {
      return;
    }

    this.initIfEmpty();

    this.id = category.id;
    this.name = category.name;
    this.fieldCollection!.merge(category.fieldCollection);
  }

  public thinOut(category?: PlantFieldCategory) {
    if (!category) {
      return;
    }

    this.initIfEmpty();

    this.fieldCollection!.thinOut(category.fieldCollection);
  }

  public updateData(data?: PlantFieldCategoryData) {
    if (!this.id) {
      this.id = StringUtils.uuid();
    }
    this.name = data?.name;
  }

  public copy() {
    return PlantFieldCategory.from(this.toEntity());
  }

  public toEntity(): PlantFieldCategoryEntity {
    return {
      id: this.id,
      name: this.name,
      fields: this.fieldCollection?.toEntities(),
      linkedWithType: this.linkedWithType
    };
  }

  public from(entity?: PlantFieldCategoryEntity) {
    this.id = entity?.id ?? "";
    this.name = entity?.name ?? "";
    this.fieldCollection = PlantFieldCollection.collectFrom(entity?.fields);
    this.linkedWithType = entity?.linkedWithType ?? false;
  }

  public fromType(type?: PlantTypeFieldCategory) {
    this.id = type?.id ?? "";
    this.name = type?.name ?? "";
    this.fieldCollection = PlantFieldCollection.collectFromTypes(type?.fields);
    this.linkedWithType = true;
  }

  public static from(entity?: PlantFieldCategoryEntity) {
    const category = new PlantFieldCategory();
    category.from(entity);
    return category;
  }

  public static fromType(type?: PlantTypeFieldCategory) {
    const category = new PlantFieldCategory();
    category.fromType(type);
    return category;
  }

  private initIfEmpty() {
    if (!this.fieldCollection) {
      this.fieldCollection = new PlantFieldCollection();
    }
  }
}
