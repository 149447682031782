import { AsyncKeyValueStorage } from "@/storage/AsyncKeyValueStorage";
import { DBSchema, IDBPDatabase, StoreKey, StoreNames, StoreValue } from "idb";

export class IndexedDBStorage<
  TDB extends DBSchema,
  TKey extends StoreKey<TDB, StoreNames<TDB>>,
  TValue extends StoreValue<TDB, StoreNames<TDB>>
> implements AsyncKeyValueStorage<TKey, TValue>
{
  public constructor(
    private db: IDBPDatabase<TDB> | Promise<IDBPDatabase<TDB> | undefined>,
    private store: StoreNames<TDB>
  ) {}

  public async set(key: TKey, value: TValue): Promise<void> {
    await (await this.db)?.put(this.store, value, key);
  }

  public async get(key: TKey): Promise<TValue | undefined> {
    const value = await (await this.db)?.get(this.store, key);
    return value;
  }

  public async move(oldKey: TKey, newKey: TKey): Promise<void> {
    const value = await this.get(oldKey);

    if (value) {
      await this.set(newKey, value);
    }
  }

  public async remove(key: TKey): Promise<boolean> {
    if (await this.has(key)) {
      await (await this.db)?.delete(this.store, key);
      return true;
    } else {
      return false;
    }
  }

  public async keys(): Promise<TKey[]> {
    return (await this.db)?.getAllKeys(this.store) as unknown as TKey[];
  }

  public async has(key: TKey): Promise<boolean> {
    const value = await this.get(key);
    return !!value;
  }

  public async clear(): Promise<void> {
    await (await this.db)?.clear(this.store);
  }

  public async empty(): Promise<boolean> {
    return (await (await this.db)?.count(this.store)) === 0;
  }

  public available(): boolean {
    return true;
  }
}
