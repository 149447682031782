import { Selection, Request } from "@/forms/ViewModelFormTypes";

export class TicketLocationViewModel {
  // Data
  public planId = "";
  public selectedPlanImagePath = "";
  public ticketLocation: PointViewModel = { x: "0", y: "0" };

  // States
  public planVisible = false;

  // Requests
  public loadPlanRequest: Request = { loading: false, error: "" };

  // Inputs
  public plan: Selection = {
    label: "Plan",
    selected: "",
    error: "",
    loading: false,
    items: []
  };
}

interface PointViewModel {
  x: string;
  y: string;
}
