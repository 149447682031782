import { SelectedProperty } from "@/datastructures/SelectedProperty";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { storageKeys } from "@/data/storageKeys";
import { IProfileManager } from "../interactors/ProfileManager";
import { PropertyReportContainer } from "@/property/shared/repositories/PropertyRepoContainer";
import { Plant } from "@/property/shared/dtos/Plant";

export class BasicLayoutController {
  public constructor(
    private presenter: IBasicLayoutPresenter,
    private storage: SimpleStringStorage,
    private profileManager: IProfileManager
  ) {}

  public async init() {
    await this.loadUserData();

    this.presenter.init();
  }

  public unselectProperty() {
    this.storage.remove(storageKeys.selectedPropertyData);
    this.presenter.propertyUnselected = this.storage.remove(
      storageKeys.selectedProperty
    );
  }

  public navigationItemSelected(route: string) {
    this.presenter.selectedRoute = route;
  }

  public userMenuItemSelected(route: string) {
    this.presenter.selectedRoute = route;
  }

  private async loadUserData() {
    this.presenter.userName = this.storage.get(storageKeys.userName);
    this.presenter.clientName = this.storage.get(storageKeys.clientName);
    this.presenter.role = this.storage.get(storageKeys.role);
    this.presenter.clientId = await this.profileManager.loadClientId();
  }
}

export interface IBasicLayoutPresenter {
  selectedProperty: SelectedProperty;
  userName: string;
  clientId: number;
  clientName: string;
  role: string;
  propertyUnselected: boolean;
  selectedRoute: string;
  plants: Plant[];

  init(): void;
}
