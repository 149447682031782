import { ActionContext, Module } from "vuex";
import { TicketEntity } from "../entities/TicketEntity";
import { Ticket } from "../models/Ticket";

export interface SelectedTicketsState {
  selectedTickets: TicketEntity[];
}

export function initSelectedTicketsState(): SelectedTicketsState {
  return { selectedTickets: [] };
}

export const mutations = {
  selectTicket(state: SelectedTicketsState, ticket: Ticket) {
    if (!ticket.exists) {
      throw new Error("Ticket existiert nicht");
    }

    if (state.selectedTickets.every(t => t.id !== ticket.id)) {
      state.selectedTickets.push(ticket.toEntity());
    }
  },

  unselectTicket(state: SelectedTicketsState, ticket: Ticket) {
    if (!ticket.exists) {
      throw new Error("Ticket existiert nicht");
    }

    state.selectedTickets = state.selectedTickets.filter(
      t => t.id !== ticket.id
    );
  },

  unselectAllTickets(state: SelectedTicketsState) {
    state.selectedTickets = [];
  }
};

export const getters = {
  selectedTickets(state: SelectedTicketsState) {
    return () => state.selectedTickets.map(t => Ticket.from(t));
  },

  isTicketSelected(state: SelectedTicketsState) {
    return (ticket: Ticket) => {
      if (!ticket.exists) {
        throw new Error("Ticket existiert nicht");
      }

      return state.selectedTickets.some(t => t.id === ticket.id);
    };
  }
};

type SelectedTicketsAction = ActionContext<
  SelectedTicketsState,
  SelectedTicketsState
>;

const redux: Module<SelectedTicketsState, SelectedTicketsState> = {
  state: () => initSelectedTicketsState(),
  mutations,
  getters,
  actions: {
    selectTicket({ commit }: SelectedTicketsAction, ticket: Ticket) {
      commit("selectTicket", ticket);
    },
    unselectTicket({ commit }: SelectedTicketsAction, ticket: Ticket) {
      commit("unselectTicket", ticket);
    },
    unselectAllTickets({ commit }: SelectedTicketsAction) {
      commit("unselectAllTickets");
    }
  }
};

export default redux;
