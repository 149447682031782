import { storageKeys } from "@/data/storageKeys";
import { Settings } from "@/shared/datastructures/Settings";

export interface Checklists {
  checklists: Checklist[];
}

export interface Checklist {
  id: string;
  name: string;
  key: string;
  ticket_type: string;
  sections: ChecklistSection[];
}

interface ChecklistSection {
  id: string;
  name: string;
  entries: ChecklistEntry[];
}

interface ChecklistEntry {
  id: string;
  name: string;
  description: string;
  interval: string;
}

export class ChecklistSettings extends Settings<Checklists> {
  protected get storageKey(): string {
    return storageKeys.checklists;
  }

  public allChecklists() {
    const settings = this.get();
    return settings.checklists ? settings.checklists : [];
  }

  public getChecklist(checklistKey: string) {
    return this.allChecklists().find(c => c.key === checklistKey);
  }
}
