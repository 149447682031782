import { Employee } from "@/property/shared/models/Employee";
import { Model } from "@/shared/model/Model";
import { CommentEntity } from "../entities/CommentEntity";

export class Comment extends Model<CommentEntity> {
  public commentable?: Commentable;
  public date?: Date = new Date();
  public text?: string = "";
  public realCreator?: Employee = new Employee();
  public impersonatedUser?: Employee = new Employee();
  public edited?: boolean = false;
  public internal?: boolean = false;

  public get valid() {
    return !!this.text;
  }

  public get isSelfCreated() {
    return (
      !this.creator.exists || (this.realCreator?.equals(this.creator) ?? true)
    );
  }

  public get isGuestComment() {
    return this.creator.isGuest;
  }

  public get creator() {
    if (this.impersonatedUser?.exists ?? false) {
      return this.impersonatedUser!;
    } else if (this.realCreator?.exists ?? false) {
      return this.realCreator!;
    } else {
      return new Employee();
    }
  }

  public get hasCommentable() {
    const commentableId = this.commentable?.id ?? 0;
    return !!commentableId;
  }

  public isCreator(potentialCreator: Employee) {
    return (
      (this.realCreator?.equals(potentialCreator) ?? false) ||
      (this.impersonatedUser?.equals(potentialCreator) ?? false)
    );
  }

  public toEntity(): CommentEntity {
    return {
      id: this.id,
      commentable: this.commentable,
      date: this.date,
      text: this.text,
      creator: this.realCreator?.toEntity(),
      impersonatedUser: this.impersonatedUser?.toEntity(),
      deletedAt: this.deletedAt,
      edited: this.edited,
      internal: this.internal
    };
  }

  public from(entity?: CommentEntity): void {
    this.id = entity?.id;
    this.commentable = entity?.commentable;
    this.date = entity?.date;
    this.text = entity?.text;
    this.realCreator = Employee.from(entity?.creator);
    this.impersonatedUser = Employee.from(entity?.impersonatedUser);
    this.deletedAt = entity?.deletedAt;
    this.edited = entity?.edited;
    this.internal = entity?.internal;
  }

  protected newInstance(): Comment {
    return new Comment();
  }

  public static from(entity?: CommentEntity) {
    const comment = new Comment();
    comment.from(entity);
    return comment;
  }
}

export interface Commentable {
  id?: number;
  type: CommentableType;
}

export type CommentableType = "ticket";
