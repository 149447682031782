import {
  SalutationList,
  salutations,
  phoneContexts,
  areaCodes,
  countries,
  addressContexts
} from "@/data/settings";
import { PersonForm } from "@/forms/person/PersonForm";
import { Textfield, Selection } from "@/forms/ViewModelFormTypes";
import { ObjectUtils } from "@/utils/ObjectUtils";
import { PersonContactForm } from "@/forms/person/PersonContactForm";
import { Form } from "@/forms/Form";
import { PersonAddressesForm } from "@/forms/person/PersonAddressesForm";
import { IPersonManager, FullPersonData } from "../interactors/PersonManager";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { storageKeys } from "@/data/storageKeys";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";

export class CreatePersonController {
  public constructor(
    private presenter: ICreatePersonPresenter,
    private personManager: IPersonManager,
    private storage: SimpleStringStorage
  ) {}

  public init(clientId: string) {
    this.presenter.salutations = salutations;
    // this.presenter.newPhoneNumber = ObjectUtils.deepCopy(basePhoneNumber);
    // this.presenter.newAddress = ObjectUtils.deepCopy(baseAddress);
    this.presenter.clientId = clientId !== "0" ? clientId : this.getClientId();
  }

  public salutationSelected(salutation: string) {
    this.presenter.personForm.setFieldValue("salutation", salutation);
  }

  public preDegreeChanged(degree: string) {
    this.presenter.personForm.setFieldValue("preDegree", degree);
  }

  public firstnameChanged(name: string) {
    this.presenter.personForm.setFieldValue("firstname", name);
  }

  public lastnameChanged(name: string) {
    this.presenter.personForm.setFieldValue("lastname", name);
  }

  public postDegreeChanged(degree: string) {
    this.presenter.personForm.setFieldValue("postDegree", degree);
  }

  public completePersonDataButtonClicked() {
    this.presenter.personFormCompleted = true;
  }

  public emailChanged(email: string) {
    this.presenter.contactForm.setFieldValue("email", email);
  }

  public websiteChanged(website: string) {
    this.presenter.contactForm.setFieldValue("website", website);
  }

  public phoneContextSelected(phoneNumberIndex: number, context: string) {
    this.presenter.contactForm.setFieldValue(
      "context",
      context,
      phoneNumberIndex
    );
  }

  public areaCodeSelected(phoneNumberIndex: number, areaCode: string) {
    this.presenter.contactForm.setFieldValue(
      "areaCode",
      areaCode,
      phoneNumberIndex
    );
  }

  public phoneNumberChanged(phoneNumberIndex: number, phoneNumber: string) {
    this.presenter.contactForm.setFieldValue(
      "number",
      phoneNumber,
      phoneNumberIndex
    );
  }

  public addPhoneNumberButtonClicked() {
    this.presenter.newPhoneNumber = ObjectUtils.deepCopy(basePhoneNumber);
  }

  public deletePhoneNumberButtonClicked(numberToDelete: PhoneNumberData) {
    this.presenter.phoneNumberToDelete = numberToDelete;
  }

  public cancelContactFormButtonClicked() {
    this.presenter.personFormCompleted = false;
  }

  public completeContactFormButtonClicked() {
    this.presenter.contactFormCompleted = true;
  }

  public addressContextSelected(addressIndex: number, context: string) {
    this.presenter.addressesForm.setFieldValue(
      "context",
      context,
      addressIndex
    );
  }

  public streetChanged(addressIndex: number, street: string) {
    this.presenter.addressesForm.setFieldValue("street", street, addressIndex);
  }

  public zipChanged(addressIndex: number, zip: string) {
    this.presenter.addressesForm.setFieldValue("zip", zip, addressIndex);
  }

  public cityChanged(addressIndex: number, city: string) {
    this.presenter.addressesForm.setFieldValue("city", city, addressIndex);
  }

  public countrySelected(addressIndex: number, country: string) {
    this.presenter.addressesForm.setFieldValue(
      "country",
      country,
      addressIndex
    );
  }

  public addAddressButtonClicked() {
    this.presenter.newAddress = ObjectUtils.deepCopy(baseAddress);
  }

  public deleteAddressButtonClicked(addressToDelete: AddressData) {
    this.presenter.addressToDelete = addressToDelete;
  }

  public cancelAddressFormButtonClicked() {
    this.presenter.contactFormCompleted = false;
  }

  public createPersonButtonClicked() {
    const request = this.personManager.createPerson(this.presenter.personData);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.createPersonResponse = response)
    );
  }

  // Helper
  private getClientId() {
    return this.storage.get(storageKeys.clientId);
  }
}

export interface ICreatePersonPresenter {
  clientId: string;

  personData: FullPersonData;
  personForm: PersonForm;
  contactForm: PersonContactForm;
  addressesForm: PersonAddressesForm;
  personFormCompleted: boolean;
  contactFormCompleted: boolean;

  salutations: SalutationList;

  newPhoneNumber: PhoneNumberData;
  phoneNumberToDelete: PhoneNumberData;

  newAddress: AddressData;
  addressToDelete: AddressData;

  createPersonResponse: FormResponse<number>;
}

export interface PhoneNumberData {
  context: Selection;
  areaCode: Selection;
  number: Textfield;
  deletable?: boolean;
}

export interface AddressData {
  context: Selection;
  street: Textfield;
  zip: Textfield;
  city: Textfield;
  country: Selection;
  deletable?: boolean;
}

const basePhoneNumber: PhoneNumberData = {
  context: {
    label: "Kontext",
    selected: "",
    error: "",
    items: Form.generateSelectionList(phoneContexts),
    loading: false
  },
  areaCode: {
    label: "Vorwahl",
    selected: "",
    error: "",
    items: Form.generateSelectionList(areaCodes),
    loading: false
  },
  number: {
    label: "Nummer",
    value: "",
    error: "",
    loading: false
  }
};

const baseAddress: AddressData = {
  context: {
    label: "Kontext",
    selected: "",
    error: "",
    items: Form.generateSelectionList(addressContexts),
    loading: false
  },
  street: {
    label: "Straße",
    value: "",
    error: "",
    loading: false
  },
  zip: {
    label: "PLZ",
    value: "",
    error: "",
    loading: false
  },
  city: {
    label: "Stadt",
    value: "",
    error: "",
    loading: false
  },
  country: {
    label: "Land",
    selected: "",
    error: "",
    items: Form.generateSelectionList(countries),
    loading: false
  }
};
