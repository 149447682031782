
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import TicketCreationWorkflow, {
  WorkflowStep
} from "./createTicketComponents/TicketCreationWorkflow.vue";

import { Ticket } from "@/inspection/shared/models/Ticket";
import { Ticketable } from "@/inspection/shared/models/Ticketable";
import { Plant } from "@/property/shared/models/Plant";
import { PropertyList } from "@/property/shared/models/PropertyList";
import { UserSettings } from "@/storage/UserSettings";

@Component<CreateTicketPage>({
  components: {
    TicketCreationWorkflow
  }
})
export default class CreateTicketPage extends Vue {
  @Prop({ type: Boolean, default: false }) protected readonly header!: boolean;
  @Prop({ type: Number }) protected readonly propertyId?: number;
  @Prop({ type: Number }) protected readonly plantId?: number;
  @Prop({ type: Array, default: () => [] })
  protected readonly context!: string[];
  @Prop() protected readonly checklistEntryTemplateId?: string;
  @Prop() protected readonly checklistType?: string;
  @Prop() protected readonly ticketable?: Ticketable<unknown>;

  protected properties = new PropertyList();

  @Watch("ticketable")
  protected onTicketableChanged() {
    this.ticket.ticketable = this.ticketable;
  }

  @Watch("plantId")
  protected onPlantIdChanged() {
    this.changePlant(this.plantId);
  }

  @Watch("checklistEntryTemplateId")
  protected onChecklistEntryTemplateIdChanged() {
    this.ticket.checklistEntryTemplateId = this.checklistEntryTemplateId;
  }

  protected ticket = new Ticket();

  protected currentWorkflowStep: WorkflowStep = {
    label: "Verortung",
    index: 1,
    isLastStep: false
  };
  protected nextButtonDisabled = false;

  protected get headerTitle() {
    return `${this.currentWorkflowStep.label}`;
  }

  protected get workflow() {
    return this.$refs.workflow as TicketCreationWorkflow;
  }

  protected get backButtonDisabled() {
    return this.currentWorkflowStep.index === 1;
  }

  protected get nextButtonIcon() {
    return this.currentWorkflowStep.isLastStep ? "done" : "chevron_right";
  }

  protected get hasBreadcrumb() {
    return this.fullContext.length > 0;
  }

  protected get hasMultiBreadcrumb() {
    return this.fullContext.length > 1;
  }

  protected get breadcrumbPath() {
    return this.fullContext.slice(0, -1).join(" → ");
  }

  protected get lastBreadcrumb() {
    const c = this.fullContext;
    return c[c.length - 1];
  }

  protected get fullContext() {
    const dynamicContext: string[] = [];

    if (this.propertyContext) dynamicContext.push(this.propertyContext);

    return [...dynamicContext, ...this.context];
  }

  protected get propertyContext() {
    return this.ticketable?.exists || this.plantId
      ? this.properties.findById(this.propertyId)?.address
      : undefined;
  }

  protected close(ticket?: Ticket) {
    this.workflow?.reset();
    this.emitClose(ticket);
  }

  protected emitClose(ticket?: Ticket) {
    this.$emit("close", ticket);
  }

  protected prevStep() {
    this.workflow?.prevStep();
    this.scrollToTop();
  }

  protected async nextStep() {
    if (!this.currentWorkflowStep.isLastStep) {
      this.workflow?.nextStep();

      this.scrollToTop();
    } else {
      if (await this.ticket.create(true)) {
        this.close(this.ticket.copy());
      }
    }
  }

  protected scrollToTop() {
    let firstScrolledElement: Element | null = (this.workflow as Vue).$el;

    while (
      firstScrolledElement !== undefined &&
      firstScrolledElement !== null &&
      firstScrolledElement.scrollTop === 0
    ) {
      firstScrolledElement = firstScrolledElement.parentElement;
    }

    if (
      !!firstScrolledElement &&
      firstScrolledElement.nodeName.toLowerCase() !== "html"
    ) {
      firstScrolledElement.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  protected onWorkflowStepChanged(currentStep: WorkflowStep) {
    this.currentWorkflowStep = currentStep;
  }

  protected checkValidation(valid: boolean) {
    this.nextButtonDisabled = !valid;
  }

  protected changePlant(id?: number) {
    if (id) {
      this.ticket.plant = Plant.from({ id });
    }
  }

  protected mounted() {
    this.ticket.ticketable = this.ticketable;
    this.ticket.checklistEntryTemplateId = this.checklistEntryTemplateId;
    this.changePlant(this.plantId);
    this.properties.loadSelection(UserSettings.getNumericUserId(), true);
  }
}
