import { PlantFieldCategory } from "@/property/shared/models/PlantFieldCategory";
import { DynamicForm } from "@/shared/form/DynamicForm";
import { FormDefinition, FormFieldType } from "@/shared/form/FormDefinition";

export class PlantFieldCategoryFormGenerator {
  public constructor(private category: PlantFieldCategory) {}

  public generateForm(): DynamicForm {
    const definition: FormDefinition<unknown> = {};

    for (const field of this.category.fields) {
      definition[field.id ?? ""] = {
        label: field.name ?? "",
        required: field.required ?? false,
        type:
          field.type === "string"
            ? FormFieldType.Text
            : field.type === "number"
            ? FormFieldType.Number
            : field.type === "bool"
            ? FormFieldType.Checkbox
            : field.type === "date"
            ? FormFieldType.Date
            : field.type === "select"
            ? FormFieldType.Selection
            : field.type === "interval"
            ? FormFieldType.Interval
            : FormFieldType.Text,
        itemDefinition:
          field.type === "select"
            ? {
                items: () =>
                  field.items?.map(i => ({
                    text: i,
                    value: i
                  })) ?? []
              }
            : undefined
      };
    }

    return new DynamicForm(definition);
  }
}
