import { IndexedDBKeyValueStorage } from "@/storage/IndexedDBKeyValueStorage";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";

export class LogoutController {
  public constructor(
    private presenter: ILogoutPresenter,
    private storage: SimpleStringStorage
  ) {}

  public init() {
    const graphQlCache = new IndexedDBKeyValueStorage("graphQlCache", "cache");

    graphQlCache.clear();
    this.storage.clear();
    this.presenter.loggedOut = true;
  }
}

export interface ILogoutPresenter {
  loggedOut: boolean;
}
