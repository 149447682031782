import { Form, FormFieldType } from "../Form";

export class PropertyAddressForm extends Form {
  protected definition = {
    street: { type: FormFieldType.Text, required: true },
    zip: { type: FormFieldType.Text, required: true },
    city: { type: FormFieldType.Text, required: true },
    country: { type: FormFieldType.Selection, required: true }
  };
}
