import { ServerResponse } from "@/shared/datastructures/Response";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { Dictionary } from "@/shared/datastructures/Dictionary";
import { RequestModel } from "@/shared/model/RequestModel";
import { File } from "@/shared/project/file/File";
import { UserSettings } from "@/storage/UserSettings";
import { ReportEntity } from "../entities/ReportEntity";
import { InspectionRepoContainer } from "../repositories/InspectionRepoContainer";
import { InspectionSettingsContainer } from "../settings/InspectionSettingsContainer";
import { Assignment } from "./Assignment";
import { ReportData } from "./ReportData";

interface ReportInitOptions {
  id?: number;
  reportType?: string;
  assignmentId?: number;
}

export class Report extends RequestModel<ReportEntity> {
  public id?: number = 0;
  public type?: string = "";
  public data?: ReportData = new ReportData();
  public file?: File = new File();
  public assignment?: Assignment = new Assignment();

  public storeResponse = new ServerResponse<number>();
  public saveDataResponse = new ServerResponse<string>();

  public get hasFile() {
    return !!this.file?.path;
  }

  public get dataStructure() {
    return Report.getReportTemplateDateStructure(this.type);
  }

  public init(options?: ReportInitOptions) {
    this.id = options?.id ?? this.id;
    this.type = options?.reportType ?? this.type;

    if (options?.assignmentId) {
      this.assignment = Assignment.from({ id: options.assignmentId });
    }
  }

  public updateData(key: string, data: Dictionary<unknown>) {
    if (!this.data) {
      this.data = new ReportData();
    }

    this.data.setSection(key, data);
  }

  public load() {
    return RequestHandler.handleModel(
      () =>
        InspectionRepoContainer.reportRepo.getReportByType(
          UserSettings.getNumericUserId(),
          this.assignment?.id ?? 0,
          this.type ?? ""
        ),
      this
    );
  }

  public saveData() {
    return RequestHandler.handle(
      InspectionRepoContainer.reportRepo.updateReportData(
        this.data?.toEntity() ?? "{}",
        this.assignment?.id ?? 0,
        this.type ?? ""
      ),
      this.saveDataResponse,
      {
        onSuccess: url => {
          if (!this.file) {
            this.file = new File();
          }
          this.file.path = url;
        }
      }
    );
  }

  public storeReportInDocuments() {
    return RequestHandler.handle(
      InspectionRepoContainer.reportRepo.storeReport(
        this.assignment?.id ?? 0,
        this.type ?? ""
      ),
      this.storeResponse
    );
  }

  public toEntity(): ReportEntity {
    return {
      id: this.id,
      type: this.type,
      assignment: this.assignment?.toEntity(),
      data: this.data?.toEntity(),
      file: this.file?.toEntity()
    };
  }

  public from(entity?: ReportEntity) {
    this.id = entity?.id;
    this.type = entity?.type;
    this.assignment = Assignment.from(entity?.assignment);
    this.data = ReportData.from(entity?.data);
    this.file = File.from(entity?.file);
  }

  public static from(entity?: ReportEntity) {
    const model = new Report();
    model.from(entity);
    return model;
  }

  public static getReportTemplateDateStructure(type?: string) {
    return InspectionSettingsContainer.reportTemplates.getDataStructure(
      type ?? ""
    );
  }
}
