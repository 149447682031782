import { Assignment } from "@/entities/Assignment";
import { NumericalId } from "@/datastructures/NumericalId";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { Page } from "@/datastructures/Page";

export class AssignmentManager implements IAssignmentManager {
  public constructor(private gateway: AssignmentManagerGateway) {}

  public createAssignment(data: FullAssignmentData): Promise<NumericalId> {
    return this.gateway
      .createAssignment(data)
      .then(assignment => new NumericalId(assignment.id));
  }

  public loadAssignmentCatalog(
    propertyId: NumericalId,
    plantId: NumericalId,
    page: Page,
    search: string
  ): Promise<AssignmentCatalog> {
    if (plantId.valid) {
      return this.loadPlantAssignmentCatalog(plantId, page, search);
    } else {
      return this.gateway
        .getPlantIdByType(propertyId, "b1300")
        .then(id =>
          this.loadPlantAssignmentCatalog(new NumericalId(id), page, search)
        );
    }
  }

  public loadAssignment(id: NumericalId): Promise<FullAssignmentData> {
    return this.gateway.loadAssignment(id).then(assignment => ({
      id: new NumericalId(assignment.id),
      plantId: new NumericalId(),
      number: assignment.number,
      date: assignment.date,
      description: assignment.description
    }));
  }

  public loadAssignmentList(propertyId: NumericalId) {
    return this.gateway.loadAssignmentList(propertyId).then(assignments =>
      assignments.map(assignment => ({
        id: new NumericalId(assignment.id),
        plantType: assignment.plant.type,
        number: assignment.number
      }))
    );
  }

  public updateAssignmentData(data: AssignmentDataData): Promise<NumericalId> {
    return this.gateway
      .updateAssignmentData(data)
      .then(assignment => new NumericalId(assignment.id));
  }

  public deleteAssignment(id: NumericalId): Promise<NumericalId> {
    return this.gateway
      .deleteAssignment(id)
      .then(assignment => new NumericalId(assignment.id));
  }

  private loadPlantAssignmentCatalog(
    plantId: NumericalId,
    page: Page,
    search: string
  ): Promise<AssignmentCatalog> {
    return this.gateway
      .loadAssignmentCatalog(plantId, page, search)
      .then(rows => ({
        plantId,
        rows
      }));
  }
}

export interface IAssignmentManager {
  loadAssignmentCatalog(
    propertyId: NumericalId,
    plantId: NumericalId,
    page: Page,
    search: string
  ): Promise<AssignmentCatalog>;
  loadAssignment(id: NumericalId): Promise<FullAssignmentData>;

  loadAssignmentList(propertyId: NumericalId): Promise<AssignmentListRow[]>;

  createAssignment(data: FullAssignmentData): Promise<NumericalId>;
  updateAssignmentData(data: AssignmentDataData): Promise<NumericalId>;
  deleteAssignment(id: NumericalId): Promise<NumericalId>;
}

export interface AssignmentManagerGateway {
  loadAssignmentCatalog(
    plantId: NumericalId,
    page: Page,
    search: string
  ): Promise<PaginatedList<AssignmentCatalogRow>>;
  loadAssignment(id: NumericalId): Promise<Assignment>;

  loadAssignmentList(propertyId: NumericalId): Promise<Assignment[]>;

  createAssignment(data: FullAssignmentData): Promise<Assignment>;
  getPlantIdByType(propertyId: NumericalId, type: string): Promise<number>;
  updateAssignmentData(data: AssignmentDataData): Promise<Assignment>;
  deleteAssignment(id: NumericalId): Promise<Assignment>;
}

export interface FullAssignmentData {
  id?: NumericalId;
  plantId: NumericalId;
  number: string;
  date: Date;
  description: string;
}

export interface AssignmentDataData {
  id?: NumericalId;
  number: string;
  date: Date;
  description: string;
}

export interface AssignmentCatalog {
  plantId: NumericalId;
  rows: PaginatedList<AssignmentCatalogRow>;
}

export interface AssignmentCatalogRow {
  id: number;
  number: string;
  date: Date;
  description: string;
  checklistId: string;
  checklistPrepared: boolean;
  state: AssignmentState;
  street: string;
  zip: string;
  city: string;
}

export enum AssignmentState {
  Preparation,
  Inspection,
  Finsihed
}

export interface AssignmentListRow {
  id: NumericalId;
  plantType: string;
  number: string;
}
