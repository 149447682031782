
import Vue from "vue";
import Component from "vue-class-component";

import GeneralPlantDataForm from "./GeneralPlantDataForm.vue";
import PlantDropdownItemsDefinition from "./PlantDropdownItemsDefinition.vue";

import { Prop, Watch } from "vue-property-decorator";

import { TableHeader } from "@/shared/datastructures/TableHeader";
import { PlantFieldForm as Form } from "../forms/PlantFieldForm";
import { PlantField } from "@/property/shared/models/PlantField";
import { FormArray } from "@/shared/form/SubFormArray";
import { PlantFieldEntity } from "@/property/shared/entities/PlantEntity";
import { PlantFieldCollection } from "@/property/shared/models/PlantFieldCollection";

@Component<PlantFieldDefinitionTable>({
  components: {
    GeneralPlantDataForm,
    PlantDropdownItemsDefinition
  }
})
export default class PlantFieldDefinitionTable extends Vue {
  @Prop({ type: Object, default: () => new PlantFieldCollection() })
  protected readonly fields!: PlantFieldCollection;
  @Prop({ type: Object, default: () => new FormArray<PlantFieldEntity>([]) })
  protected readonly forms!: FormArray<PlantFieldEntity>;

  protected headers: TableHeader[] = [
    { text: "Name", value: "name" },
    { text: "ID", value: "key" },
    { text: "Typ", value: "type" },
    { text: "Pflicht?", value: "required", align: "center", width: "1%" },
    { text: "", value: "actions", align: "right", width: "1%" }
  ];

  protected selectedFieldForItemEditing: PlantField | null = null;

  @Watch("fields.fields")
  protected async onFieldsChanged() {
    await this.initForms();
  }

  protected get fieldTypeItems() {
    return PlantField.typeSelectItems();
  }

  protected addField() {
    const newField = this.fields.addEmpty();
    const newFieldForm = new Form(undefined, "plantField", newField);

    this.forms.push(newFieldForm);
  }

  protected removeField(field: PlantField) {
    this.forms.removeByTarget(field);
    this.fields.remove(field);
  }

  protected getFormField(fieldName: string, field: PlantField) {
    const form = this.forms.getSubFormByTarget(field);
    return form?.getField(fieldName);
  }

  protected setFormField(fieldName: string, value: unknown, field: PlantField) {
    const form = this.forms.getSubFormByTarget(field);
    form?.setFieldValue(fieldName, value);
    field.updateData(form?.getData());

    this.$forceUpdate();
  }

  protected async created() {
    await this.initForms();
    await this.initForms();
  }

  protected async initForms() {
    this.forms.clear();

    const formPromises =
      this.fields.fields?.map(async f => {
        const form = new Form(undefined, "plantField", f);
        await form.init();
        form.setData(f.toEntity());
        this.forms.push(form);
      }) ?? [];

    await Promise.all(formPromises);
  }

  public reset() {
    this.fields.clear();
    this.forms.clear();
  }
}
