import { InspectionSettingsContainer } from "../settings/InspectionSettingsContainer";

export class InspectionSelectionItemLoader {
  public static loadTicketTemplates() {
    const templates = InspectionSettingsContainer.ticketTemplates.allTicketTemplates();

    return templates.map(t => ({
      value: t.title,
      text: t.title,
      item: t
    }));
  }

  public static loadDeadlines() {
    const deadlines = InspectionSettingsContainer.deadlines.allDeadlines();

    return deadlines.map(d => ({
      value: d.name,
      text: d.name,
      item: d
    }));
  }

  public static loadPriorities() {
    const priorities = InspectionSettingsContainer.priorities.allPriorities();

    return priorities.map(p => ({
      value: p.key,
      text: p.label,
      item: p
    }));
  }

  public static loadTicketTypes() {
    const ticketTypes = InspectionSettingsContainer.ticketTypes.allTicketTypes();

    return ticketTypes.map(t => ({
      value: t.key,
      text: t.name,
      item: t
    }));
  }
}
