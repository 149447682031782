
import Vue from "vue";
import Component from "vue-class-component";

import RequestButton from "@/components/basic/RequestButton.vue";

@Component<InfoDialog>({
  components: {
    RequestButton
  },
  props: {
    title: {
      type: String,
      default: "Titel"
    },
    text: {
      type: String,
      default: ""
    },
    continueText: {
      type: String,
      default: "OK"
    },
    value: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 300
    },
    persistent: {
      type: Boolean,
      default: true
    }
  }
})
export default class InfoDialog extends Vue {
  protected ok() {
    this.$emit("click");
    this.$emit("input");
  }
}
