
import Vue from "vue";
import Component from "vue-class-component";

@Component<RadioGroup>({
  props: {
    value: {
      type: Object,
      default() {
        return { label: "Label", value: "", error: "", items: [] };
      }
    },
    v: {
      type: [String],
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: undefined
    },
    items: {
      type: Array,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  }
})
export default class RadioGroup extends Vue {}
