import Vue from "vue";
import Router, { Route } from "vue-router";
import { LocalStorage } from "./storage/LocalStorage";

// Property
import PlantListPage from "@/property/plant/PlantListPage.vue";
import PlantPage from "@/property/plant/PlantPage.vue";

// Inspection
import InspectionPage from "@/inspection/inspection/InspectionPage.vue";

// Common
import LoginView from "@/common/views/LoginView.vue";
import LogoutView from "@/common/views/LogoutView.vue";
import MapView from "@/common/views/MapView.vue";
import PersonListView from "@/common/views/PersonListView.vue";
import PersonDetailView from "@/common/views/PersonDetailView.vue";

// Documents
import DocumentListView from "@/document/views/DocumentListView.vue";
import DocumentDetailView from "@/document/views/DocumentDetailView.vue";
import DWSBaseSyncView from "@/document/views/DWSBaseSyncView.vue";

// Fieldwork
import TicketListPage from "@/inspection/ticket/views/TicketListPage.vue";
import TicketDetailView from "@/fieldwork/views/TicketDetailView.vue";

// Expert
import AssignmentListPage from "@/inspection/assignment/views/AssignmentListPage.vue";
import AssignmentDetailView from "@/expert/views/AssignmentDetailView.vue";
import ChecklistPreparationView from "@/expert/views/ChecklistPreparationView.vue";
import InspectionView from "@/expert/views/InspectionView.vue";
import EditReportPage from "@/inspection/report/views/EditReportPage.vue";
import ReportPage from "@/inspection/report/ReportPage.vue";

// Office
import PropertyPlanView from "@/office/views/PropertyPlanView.vue";
import PropertyStructurePage from "@/property/structure/views/PropertyStructurePage.vue";

// Manager
import PropertyListPage from "@/property/property/views/PropertyListPage.vue";

// Admin
import ClientDetailPage from "@/property/client/views/ClientDetailPage.vue";
import PropertyDetailView from "@/admin/views/PropertyDetailView.vue";
import SettingsListView from "@/admin/views/SettingsListView.vue";
import SettingDetailView from "@/admin/views/SettingDetailView.vue";

// Superadmin
import ClientListPage from "@/property/client/views/ClientListPage.vue";
import AccessManagementPage from "@/property/client/views/AccessManagementPage.vue";
import { Authorizator } from "./common/interactors/Authorizator";
import { UserSettings } from "./storage/UserSettings";
import { AuthorizatorStorageGateway } from "./gateways/storage/AuthorizatorStorageGateway";
import { storageKeys } from "./data/storageKeys";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/map/:action?",
      name: "home",
      component: MapView,
      children: [
        {
          path: "dwsbase-sync",
          name: "dwsbase-sync",
          component: DWSBaseSyncView
        }
      ]
    },
    {
      path: "/",
      name: "map",
      component: MapView
    },
    {
      path: "/login",
      name: "login",
      component: LoginView,
      meta: {
        guest: true
      }
    },
    {
      path: "/logout",
      name: "logout",
      component: LogoutView
    },
    {
      path: "/people",
      name: "people",
      component: PersonListView
    },
    {
      path: "/person/:personId",
      name: "person",
      component: PersonDetailView
    },
    {
      path: "/clients",
      name: "clients",
      component: ClientListPage,
      meta: {
        module: "clients",
        operation: "list"
      }
    },
    {
      path: "/client/create",
      name: "create-client",
      component: ClientDetailPage,
      meta: {
        module: "clients",
        operation: "create"
      }
    },
    {
      path: "/client/:clientId?",
      name: "client",
      component: ClientDetailPage,
      beforeEnter: (to, _, next) => {
        const ownClient = to.params.clientId === UserSettings.getClientId();

        if (
          (ownClient && Authorizator.canViewOwnClient()) ||
          (!ownClient && Authorizator.canViewClients())
        ) {
          next();
        } else {
          next(false);
        }
      }
    },
    {
      path: "/clients/access",
      name: "client-access",
      component: AccessManagementPage
    },
    {
      path: "/settings",
      name: "settings",
      component: SettingsListView
    },
    {
      path: "/setting/:settingKey",
      name: "client-setting",
      component: SettingDetailView
    },
    {
      path: "/setting/:settingKey/global",
      name: "global-setting",
      component: SettingDetailView
    },
    {
      path: "/property/create",
      name: "create-property",
      component: PropertyDetailView
    },
    {
      path: "/property/:propertyId?",
      name: "property",
      component: PropertyDetailView
    },
    {
      path: "/properties",
      name: "properties",
      component: PropertyListPage
    },
    {
      path: "/property/:propertyId?/plans",
      name: "property-plans",
      component: PropertyPlanView
    },
    {
      path: "/property/:propertyId?/structure",
      name: "property-structure",
      component: PropertyStructurePage
    },
    {
      path: "/plants",
      name: "plants",
      component: PlantListPage
    },
    {
      path: "/plant/:plantId",
      name: "plant",
      component: PlantPage
    },
    {
      path: "/tickets",
      name: "tickets",
      component: TicketListPage
    },
    {
      path: "/ticket/:ticketId/search/:search?/priority/:priority?/entry/:entry?/state/:state?/plant/:plant?/entryTemplate/:entryTemplate?",
      name: "ticket",
      component: TicketDetailView
    },
    {
      path: "/plants/:plantType?/assignments",
      name: "assignments",
      component: AssignmentListPage,
      props: route => ({
        plantType: route.params.plantType,
        name: route.query.name
      })
    },
    {
      path: "/property/:propertyId?/assignments",
      name: "property-assignments",
      component: AssignmentListPage,
      props: route => ({
        propertyId: route.params.propertyId,
        name: route.query.name
      })
    },
    {
      path: "/plant/:plantId?/assignment/:assignmentId?",
      name: "assignment",
      component: AssignmentDetailView
    },
    {
      path: "/assignment/:assignmentId/checklist/preparation",
      name: "prepare-checklist",
      component: ChecklistPreparationView
    },
    {
      path: "/assignment/:assignmentId/checklist/inspection",
      name: "inspection",
      component: InspectionPage
    },
    {
      path: "/assignment/:assignmentId/report/edit",
      name: "edit-report",
      component: EditReportPage,
      props: route => ({
        assignmentId: route.params.assignmentId,
        reportType: route.query.reportType
      })
    },
    {
      path: "/assignment/:assignmentId/report/edit/new",
      name: "edit-report-new",
      component: ReportPage,
      props: route => ({
        assignmentId: route.params.assignmentId,
        reportType: route.query.reportType
      })
    },
    {
      path: "/documents/:metumId/:subjectType?/:subjectId?",
      name: "documents",
      component: DocumentListView
    },
    {
      path: "/document/:documentId",
      name: "document",
      component: DocumentDetailView
    }
  ]
});

router.beforeEach((routeTo, _, next) => {
  const module = routeTo.meta?.module;
  const operation = routeTo.meta?.operation;
  let hasPermission = true;

  if (module && operation) {
    hasPermission = new AuthorizatorStorageGateway().checkPermission(
      module,
      operation
    );
  }

  if (isGuestRoute(routeTo)) {
    isGuest() ? next() : next({ name: "home" });
  } else {
    isGuest() ? next({ path: "login" }) : hasPermission ? next() : next(false);
  }
});

function isGuestRoute(route: Route) {
  return route.meta?.guest === true || hasAccessKey(route);
}

function hasAccessKey(route: Route) {
  const key = route.query.accessKey as string;

  if (key) {
    new LocalStorage().set(storageKeys.accessKey, key);
    return true;
  } else {
    return false;
  }
}

function isGuest() {
  return !new LocalStorage().has("token");
}

export default router;
