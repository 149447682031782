import { PropertyReportContainer } from "@/property/shared/repositories/PropertyRepoContainer";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { AccessClientVm } from "./AccessClientVm";
import { AccessResourceVm } from "./AccessResourceVm";

export class AccessClientsVm extends AccessResourceVm {
  private clients: AccessResourceVm[] = [];

  public get children() {
    return this.clients;
  }

  public get shareable() {
    return false;
  }

  public get type() {
    return "";
  }

  public async loadChildren() {
    await RequestHandler.handle(
      PropertyReportContainer.clientRepo.getClientSelection(),
      undefined,
      {
        onSuccess: clients =>
          this.addResources(
            clients.map(c => new AccessClientVm(c.id, c.name)),
            this.clients
          )
      }
    );
  }
}
