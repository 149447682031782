
import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import ResponsiveIconButton from "@/components/basic/ResponsiveIconButton.vue";
import SubmitTextfield from "@/components/basic/SubmitTextfield.vue";

import PropertyList from "./propertyListComponents/PropertyList.vue";
import { Authorizator } from "@/common/interactors/Authorizator";

@Component<PropertyListPage>({
  components: {
    Page,
    Section,
    Card,
    Grid,
    Row,
    Column,
    ResponsiveIconButton,
    SubmitTextfield,
    PropertyList
  }
})
export default class PropertyListPage extends Vue {
  private search = "";

  private get addPropertyButtonVisible() {
    return Authorizator.canCreateProperties();
  }

  private setSearch(search: string) {
    this.search = search;
  }

  private goToNewPropertyPage() {
    this.$router.push({ name: "create-property" });
  }
}
