import { Plan } from "@/property/shared/models/Plan";
import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { PlanLocationEntity } from "../entities/PlanLocationEntity";
import { InspectionRepoContainer } from "../repositories/InspectionRepoContainer";

interface PlanLocationProps {
  plan?: Plan;
  x?: number;
  y?: number;
  zoom?: number;
}

export class PlanLocation extends RequestModel<PlanLocationEntity> {
  public plan?: Plan;
  public x?: number;
  public y?: number;
  public zoom?: number;

  public constructor(props?: PlanLocationProps) {
    super();
    this.plan = props?.plan ?? new Plan();
    this.x = props?.x;
    this.y = props?.y;
    this.zoom = props?.zoom;
  }

  public get hasPlan() {
    return this.plan?.exists;
  }

  public get planFilePath() {
    return this.plan?.filePath ?? "";
  }

  public setPlan(plan?: Plan) {
    this.plan = plan;
    this.clearLocation();
  }

  public updateLocation(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  public clear() {
    this.setPlan(undefined);
  }

  public async loadLastLocation() {
    return RequestHandler.handleModel(
      () => InspectionRepoContainer.planLocationRepo.getLastLocation(),
      this
    );
  }

  public async saveLastLocation() {
    return RequestHandler.handle(
      InspectionRepoContainer.planLocationRepo.saveLastLocation(this.toEntity())
    );
  }

  public toEntity(): PlanLocationEntity {
    return {
      plan: this.plan?.toEntity(),
      x: this.x,
      y: this.y,
      zoom: this.zoom
    };
  }

  public from(entity?: PlanLocationEntity) {
    this.plan = Plan.from(entity?.plan);
    this.x = entity?.x;
    this.y = entity?.y;
    this.zoom = entity?.zoom;
  }

  public static from(entity?: PlanLocationEntity) {
    const location = new PlanLocation();
    location.from(entity);
    return location;
  }

  private clearLocation() {
    this.x = 0;
    this.y = 0;
  }
}
