import { ImageEntity } from "@/shared/project/images/ImageEntity";
import { TicketEntity } from "../../entities/TicketEntity";
import { TicketGraphQLProvider } from "./TicketGraphQLProvider";
import { TicketProvider } from "./TicketProvider";
import { BluebookRedux } from "@/plugins/vuex";
import { Ticket } from "../../models/Ticket";
import { TicketCollection } from "../../models/TicketCollection";
import { LoadTicketsOptions } from "@/inspection/ticket/useCases/LoadTicketsUseCase";
import { TicketDescription } from "../../dtos/TicketDtos";

export interface TicketRepository {
  getTickets(options: LoadTicketsOptions): Promise<TicketCollection>;

  getTicket(id: number, syncId: string): Promise<TicketEntity>;
  getSelectedTickets(): Promise<TicketCollection>;
  checkTicketExistence(ticket: Ticket): Promise<boolean>;
  selectTicket(ticket: Ticket): Promise<void>;
  unselectTicket(ticket: Ticket): Promise<void>;
  unselectAllTickets(): Promise<void>;
  create(ticket: TicketEntity): Promise<number>;
  changeDescription(
    id: number,
    description: TicketDescription
  ): Promise<number>;
  uploadImage(image: ImageEntity): Promise<number>;
  setState(ticketId: number, state: string): Promise<number>;

  connectPerson(
    ticketId: number,
    personId: number,
    role: string
  ): Promise<number>;

  assignPlant(ticketId: number, plantId: number): Promise<number>;

  disconnectPerson(ticketId: number, role: string): Promise<number>;
  unassignPlant(ticketId: number): Promise<number>;

  delete(ticket: TicketEntity): Promise<number>;
}

export class DefaultTicketRepository implements TicketRepository {
  public constructor(
    private provider: TicketProvider = new TicketGraphQLProvider(),
    private redux?: BluebookRedux
  ) {}

  public async getTickets(
    options: LoadTicketsOptions
  ): Promise<TicketCollection> {
    const ticketsPaginated = await this.provider.loadTickets(options);

    return TicketCollection.from(
      ticketsPaginated.items,
      ticketsPaginated.totalCount
    );
  }

  public async getTicket(id: number, syncId: string): Promise<TicketEntity> {
    const ticket = await this.provider.loadTicket(id, syncId);

    ticket.selected =
      this.redux?.getters?.isTicketSelected(Ticket.from(ticket)) ?? false;

    return ticket;
  }

  public async getSelectedTickets(): Promise<TicketCollection> {
    return TicketCollection.collect(this.redux?.getters?.selectedTickets());
  }

  public async checkTicketExistence(ticket: Ticket): Promise<boolean> {
    if (!ticket.exists) {
      throw new Error("Ticket existiert nicht");
    }

    return this.provider.checkTicketExistence(ticket.toEntity());
  }

  public async selectTicket(ticket: Ticket): Promise<void> {
    await this.redux?.dispatch("selectTicket", ticket);
  }

  public async unselectTicket(ticket: Ticket): Promise<void> {
    await this.redux?.dispatch("unselectTicket", ticket);
  }

  public async unselectAllTickets(): Promise<void> {
    await this.redux?.dispatch("unselectAllTickets");
  }

  public create(ticket: TicketEntity): Promise<number> {
    return this.provider.create(ticket);
  }

  public changeDescription(
    id: number,
    description: TicketDescription
  ): Promise<number> {
    return this.provider.changeDescription(id, description);
  }

  public uploadImage(image: ImageEntity): Promise<number> {
    return this.provider.uploadImage(image);
  }

  public setState(ticketId: number, state: string): Promise<number> {
    return this.provider.setState(ticketId, state);
  }

  public connectPerson(
    ticketId: number,
    personId: number,
    role: string
  ): Promise<number> {
    return this.provider.connectPerson(ticketId, personId, role);
  }

  public assignPlant(ticketId: number, plantId: number): Promise<number> {
    return this.provider.assignPlant(ticketId, plantId);
  }

  public disconnectPerson(ticketId: number, role: string): Promise<number> {
    return this.provider.disconnectPerson(ticketId, role);
  }

  public unassignPlant(ticketId: number): Promise<number> {
    return this.provider.unassignPlant(ticketId);
  }

  public delete(ticket: TicketEntity): Promise<number> {
    return this.provider.delete(ticket);
  }
}
