
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import ClientGeneralDataForm from "./ClientGeneralDataForm.vue";
import ClientContactForm from "./ClientContactForm.vue";
import ClientAddressForm from "./ClientAddressForm.vue";
import ClientLogoUpload from "./ClientLogoUpload.vue";

import { Client } from "@/property/shared/models/Client";
import { Authorizator } from "@/common/interactors/Authorizator";

@Component<ClientForm>({
  components: {
    ClientGeneralDataForm,
    ClientContactForm,
    ClientAddressForm,
    ClientLogoUpload
  }
})
export default class ClientForm extends Vue {
  @Prop({
    default() {
      return new Client();
    }
  })
  protected readonly client!: Client;

  protected get canEditClient() {
    return (
      (!this.client.exists && Authorizator.canCreateClients()) ||
      (this.client.isOwn && Authorizator.canEditOwnClient()) ||
      Authorizator.canEditClients()
    );
  }

  protected clearAddress() {
    (this.$refs.addressForm as ClientAddressForm).clearAddress();
  }
}
