
import Vue from "vue";
import Component from "vue-class-component";

import { DocumentDetailViewModel } from "@/document/vms/DocumentDetailViewModel";
import { DocumentDetailController } from "@/document/controllers/DocumentDetailController";
import { DocumentDetailPresenter } from "@/document/presenters/DocumentDetailPresenter";

import Page from "@/components/layout/Page.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Section from "@/components/layout/Section.vue";
import Grid from "@/components/layout/Grid.vue";
import TruncatedText from "@/components/layout/TruncatedText.vue";

import IconButton from "@/components/basic/IconButton.vue";
import RequestButton from "@/components/basic/RequestButton.vue";

import Textfield from "@/components/form/Textfield.vue";
import DatePicker from "@/components/form/DatePicker.vue";

import { AxiosGraphQLConnection } from "../../gateways/graphql/connection/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { GraphQLDocumentService } from "../services/DocumentService";
import { UserSettings } from "../../storage/UserSettings";
import { LocalStorage } from "../../storage/LocalStorage";

@Component<DocumentDetailView>({
  components: {
    Page,
    Section,
    Card,
    Grid,
    Row,
    Column,
    TruncatedText,
    Textfield,
    DatePicker,
    IconButton,
    RequestButton
  },
  watch: {
    "vm.goBack"(go: boolean) {
      if (go) {
        this.$router.back();
      }
    },
    "vm.documentToOpen"(link: string) {
      window.open(link, "_blank");
    },
    "vm.metumId"(id: number) {
      this.controller.loadMetum(id);
    },
    "vm.savedDocument"(id: number) {
      if (id > 0) {
        this.$router.back();
      }
    },
    "vm.deletedDocument"(id: number) {
      if (id > 0) {
        this.$router.back();
      }
    }
  }
})
export default class DocumentDetailView extends Vue {
  private vm = new DocumentDetailViewModel();
  private controller: any;

  private get documentId() {
    return this.$route.params.documentId;
  }

  private createController() {
    return new DocumentDetailController(
      new DocumentDetailPresenter(this.vm),
      new GraphQLDocumentService(
        new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger()))
      )
    );
  }

  private created() {
    this.controller = this.createController();
  }

  private mounted() {
    this.controller.init(this.documentId);
  }
}
