import {
  IAuthenticator,
  LoginData,
  AuthPayload
} from "../interactors/Authenticator";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { LoginForm } from "@/forms/authentication/LoginForm";

export class LoginController {
  public constructor(
    private presenter: ILoginPresenter,
    private auth: IAuthenticator
  ) {}

  public init() {
    // Init
  }

  public emailChanged(email: string) {
    this.presenter.loginForm.setFieldValue("email", email);
  }

  public passwordChanged(password: string) {
    this.presenter.loginForm.setFieldValue("password", password);
  }

  public loginButtonClicked() {
    const request = this.auth.login(this.presenter.loginData);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.loginResponse = response),
      "Login nicht möglich"
    );
  }
}

export interface ILoginPresenter {
  loginForm: LoginForm;
  loginData: LoginData;

  loginResponse: FormResponse<AuthPayload>;
}
