import { Ticket } from "@/entities/Ticket";
import { NumericalId } from "@/datastructures/NumericalId";
import {
  ITicketViewer,
  TicketViewerGateway,
  TicketCatalogRow
} from "@/fieldwork/interactors/TicketViewer";
import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { Image } from "@/entities/Image";

export class TicketManager implements ITicketManager {
  public constructor(
    private gateway: TicketManagerGateway,
    private ticketViewer: ITicketViewer
  ) {}

  public async createTicket(
    data: CreateTicketData,
    images: File[]
  ): Promise<number> {
    const ticket = await this.gateway.createTicket(data, images);
    return ticket.id;
  }

  public deleteTicket(id: NumericalId): Promise<number> {
    return this.gateway.deleteTicket(id).then(ticket => ticket.id);
  }

  public updateDescription(data: UpdateTicketDescription): Promise<number> {
    return this.gateway.updateDescription(data).then(ticket => ticket.id);
  }

  public updateAdditionalData(data: UpdateTicketData): Promise<number> {
    return this.gateway.updateAdditionalData(data).then(ticket => ticket.id);
  }

  public updateOrganization(data: UpdateTicketOrganization): Promise<number> {
    return this.gateway.updateOrganization(data).then(ticket => ticket.id);
  }

  public upsertLocation(
    ticketId: NumericalId,
    data: UpsetTicketLocation
  ): Promise<UpdateTicketLocationData> {
    return this.gateway.upsertLocation(ticketId, data).then(ticket => ({
      id: new NumericalId(ticket.location.id),
      planId: new NumericalId(ticket.location.plan.id),
      planName: ticket.location.plan.name,
      x: ticket.location.x,
      y: ticket.location.y
    }));
  }

  public deleteLocation(
    ticketId: NumericalId,
    locationId: NumericalId
  ): Promise<number> {
    return this.gateway
      .deleteLocation(ticketId, locationId)
      .then(ticket => ticket.id);
  }

  public upsertChecklistReference(
    ticketId: NumericalId,
    checklistId: NumericalId,
    entryId: NumericalId,
    prevChecklistId?: NumericalId
  ) {
    return this.gateway
      .upsertChecklistReference(ticketId, checklistId, entryId, prevChecklistId)
      .then(ticket => ({
        assignmentId: new NumericalId(
          ticket.entry.section.checklist.assignment.id
        ),
        checklistId: new NumericalId(ticket.entry.section.checklist.id),
        sectionId: new NumericalId(ticket.entry.section.id),
        entryId: new NumericalId(ticket.entry.id),
        assignmentNumber: ticket.entry.section.checklist.assignment.number,
        sectionName: ticket.entry.section.name,
        entryName: ticket.entry.name
      }));
  }

  public deleteChecklistReference(
    ticketId: NumericalId,
    checklistId: NumericalId
  ) {
    return this.gateway
      .deleteChecklistReference(ticketId, checklistId)
      .then(ticket => ticket.id);
  }

  public uploadImage(ticketId: NumericalId, image: File): Promise<TicketImage> {
    return this.gateway.uploadImage(ticketId, image).then(ticket => ({
      id: ticket.id,
      path: ticket.path
    }));
  }

  public deleteImage(
    ticketId: NumericalId,
    imageId: NumericalId
  ): Promise<number> {
    return this.gateway.deleteImage(ticketId, imageId).then(image => image.id);
  }

  public ticketExists(ticketId: NumericalId) {
    return this.ticketViewer.ticketExists(ticketId);
  }

  public loadTicket(
    ticketId: NumericalId,
    clientId: string,
    propertyId?: string,
    search?: string,
    priority?: string,
    state?: string,
    entryId?: string,
    plantId?: number,
    entryTemplate?: string
  ) {
    return this.ticketViewer.loadTicket(
      ticketId,
      clientId,
      propertyId,
      search,
      priority,
      state,
      entryId,
      plantId,
      entryTemplate
    );
  }

  public loadTicketCatalog(
    page: Page,
    client: NumericalId,
    search: string = "",
    priority?: string,
    state?: string,
    plantId?: number,
    entryTemplate?: string,
    propertyId?: NumericalId,
    entryId?: string
  ): Promise<PaginatedList<TicketCatalogRow>> {
    return this.ticketViewer.loadTicketCatalog(
      page,
      client,
      search,
      priority,
      state,
      plantId,
      entryTemplate,
      propertyId,
      entryId
    );
  }

  public generateTicketReport(
    priorities: string[],
    propertyId?: NumericalId
  ): Promise<string> {
    return this.ticketViewer.generateTicketReport(priorities, propertyId);
  }
}

export interface ITicketManager extends ITicketViewer {
  createTicket(data: CreateTicketData, images: File[]): Promise<number>;
  deleteTicket(id: NumericalId): Promise<number>;

  updateDescription(data: UpdateTicketDescription): Promise<number>;
  updateAdditionalData(data: UpdateTicketData): Promise<number>;
  updateOrganization(data: UpdateTicketOrganization): Promise<number>;

  upsertLocation(
    ticketId: NumericalId,
    data: UpsetTicketLocation
  ): Promise<UpdateTicketLocationData>;
  deleteLocation(
    ticketId: NumericalId,
    locationId: NumericalId
  ): Promise<number>;

  upsertChecklistReference(
    ticketId: NumericalId,
    checklistId: NumericalId,
    entryId: NumericalId,
    prevChecklistId?: NumericalId
  ): Promise<UpdateChecklistReferenceData>;
  deleteChecklistReference(
    ticketId: NumericalId,
    checklistId: NumericalId
  ): Promise<number>;

  deleteImage(ticketId: NumericalId, imageId: NumericalId): Promise<number>;
  uploadImage(ticketId: NumericalId, image: File): Promise<TicketImage>;
}

export interface TicketManagerGateway extends TicketViewerGateway {
  createTicket(
    data: CreateTicketData,
    images: File[],
    failedImageUploads?: (ticketId: number, imageIndices: number[]) => void
  ): Promise<Ticket>;
  deleteTicket(id: NumericalId): Promise<Ticket>;

  updateDescription(data: UpdateTicketDescription): Promise<Ticket>;
  updateAdditionalData(data: UpdateTicketData): Promise<Ticket>;
  updateOrganization(data: UpdateTicketOrganization): Promise<Ticket>;

  upsertLocation(
    ticketId: NumericalId,
    data: UpsetTicketLocation
  ): Promise<Ticket>;
  deleteLocation(
    ticketId: NumericalId,
    locationId: NumericalId
  ): Promise<Ticket>;

  upsertChecklistReference(
    ticketId: NumericalId,
    checklistId: NumericalId,
    entryId: NumericalId,
    prevChecklistId?: NumericalId
  ): Promise<Ticket>;
  deleteChecklistReference(
    ticketId: NumericalId,
    checklistId: NumericalId
  ): Promise<Ticket>;

  deleteImage(ticketId: NumericalId, imageId: NumericalId): Promise<Image>;
  uploadImage(ticketId: NumericalId, image: File): Promise<Image>;
}

export interface CreateTicketData {
  title: string;
  description: string;
  action: string;
  dueDate: string;
  deadline: string;
  priority: string;
  property: NumericalId;
  client: NumericalId;
  checklist?: NumericalId;
  checklistEntry?: NumericalId;
  checklistEntryTemplateId?: string;
  planId?: NumericalId;
  plantId?: NumericalId;
  creatorId: NumericalId;
  x: number;
  y: number;
  additionalData: string;
  type: string;
  syncId?: string;
}

export interface EditTicketData {
  id?: number;
  title: string;
  description: string;
  action: string;
  priority: string;
  dueDate: Date;
  type: string;
  data: string;
}

export interface UpdateTicketDescription {
  id?: number;
  title: string;
  description: string;
  action: string;
}

export interface UpdateTicketData {
  id?: number;
  data: string;
}

export interface UpdateTicketOrganization {
  id?: number;
  dueDate: Date;
  priority: string;
}

export interface UpsetTicketLocation {
  id?: NumericalId;
  planId: NumericalId;
  x: number;
  y: number;
}

export interface UpdateTicketLocationData {
  id: NumericalId;
  planId: NumericalId;
  planName: string;
  x: number;
  y: number;
}

export interface TicketImage {
  id: number;
  path: string;
}

export interface UpdateChecklistReferenceData {
  assignmentId: NumericalId;
  checklistId: NumericalId;
  sectionId: NumericalId;
  entryId: NumericalId;
  assignmentNumber: string;
  sectionName: string;
  entryName: string;
}
