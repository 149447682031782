
import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

@Component<Section>({
  components: {
    Container,
    Column,
    Row
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ""
    },
    scroll: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "surface"
    }
  }
})
export default class Section extends Vue {
  private mounted() {
    if (this.$props.scroll) {
      const elem = document.getElementById(this.$props.scroll);

      if (elem) {
        const startingPos = elem.getBoundingClientRect().top;

        window.addEventListener("scroll", () => {
          if (window.pageYOffset > startingPos - 80) {
            elem.style.top = window.pageYOffset - (startingPos - 80) + "px";
          } else {
            elem.style.top = "0px";
          }
        });
      }
    }
  }
}
