import { PersonEditorGateway } from "@/common/interactors/PersonEditor";
import { GraphQLConnection } from "./connection/GraphQLConnection";
import { NumericalId } from "@/datastructures/NumericalId";
import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { Person } from "@/entities/Person";
import { Address } from "@/entities/Address";
import { PhoneNumber } from "@/entities/PhoneNumber";
import { Employee } from "@/entities/Employee";

export class PersonEditorGraphQLGateway implements PersonEditorGateway {
  public constructor(private connection: GraphQLConnection) {}

  public loadPeopleCatalog(
    clientId: NumericalId,
    page: Page,
    search: string
  ): Promise<PaginatedList<Person>> {
    return this.connection
      .queryPaginated(
        "people",
        page.itemsPerPage,
        page.page,
        { client_id: clientId.id },
        [
          "id",
          "pre_degree",
          "post_degree",
          "firstname",
          "lastname",
          "email",
          { name: "phone_numbers", fields: ["area_code", "number"] },
          {
            name: "addresses",
            fields: ["street", "postcode", "city", "country"]
          }
        ],
        search
      )
      .then(response => ({
        items: response.data.map((data: any) => {
          const person = new Person(data.id);
          const address = new Address("", "", "");
          const phoneNumber = new PhoneNumber(0);

          person.addresses.push(address);
          person.phoneNumbers.push(phoneNumber);

          person.preTitle = data.pre_degree;
          person.postTitle = data.post_degree;
          person.firstname = data.firstname;
          person.lastname = data.lastname;
          person.email = data.email;

          if (data.addresses && data.addresses.length > 0) {
            const addressData = data.addresses[0];
            address.street = addressData.street;
            address.zip = addressData.postcode;
            address.city = addressData.city;
            address.country = addressData.country;
          }

          if (data.phone_numbers && data.phone_numbers.length > 0) {
            const numberData = data.phone_numbers[0];
            phoneNumber.areaCode = numberData.area_code;
            phoneNumber.number = numberData.number;
          }

          return person;
        }),
        totalCount: response.count
      }));
  }

  public loadPerson(personId: NumericalId): Promise<Person> {
    return this.connection
      .query("person", { id: personId.id }, [
        "id",
        "pre_degree",
        "post_degree",
        "firstname",
        "lastname",
        "email",
        "website",
        {
          name: "phone_numbers",
          fields: ["id", "context", "area_code", "number"]
        },
        {
          name: "addresses",
          fields: ["id", "context", "street", "postcode", "city", "country"]
        },
        { name: "user", fields: ["id"] }
      ])
      .then(response => {
        const data = response.data;
        const person = new Person(data.id);

        person.preTitle = data.pre_degree;
        person.postTitle = data.post_degree;
        person.firstname = data.firstname;
        person.lastname = data.lastname;
        person.email = data.email;
        person.website = data.website;

        if (data.user) {
          person.employee = new Employee(data.user.id);
        }

        person.phoneNumbers = data.phone_numbers.map((phoneNumber: any) => {
          const num = new PhoneNumber(phoneNumber.id);
          num.context = phoneNumber.context;
          num.areaCode = phoneNumber.area_code;
          num.number = phoneNumber.number;
          return num;
        });

        person.addresses = data.addresses.map((address: any) => {
          const addr = new Address(
            address.street,
            address.postcode,
            address.city
          );

          addr.context = address.context;
          addr.country = address.country;
          addr.id = address.id;

          return addr;
        });

        return person;
      });
  }

  public deletePerson(personId: NumericalId): Promise<Person> {
    return this.connection
      .mutation("deletePerson", { id: personId.id }, ["id"])
      .then(repsonse => new Person(repsonse.data.id));
  }
}
