import { IChecklistPreparationPresenter } from "../controllers/ChecklistPreparationController";
import { ChecklistPreparationViewModel } from "../vms/ChecklistPreparationViewModel";
import { FormResponse } from "@/forms/FormResponse";
import {
  ChecklistPreparationData,
  ChecklistSectionPreparationData
} from "../interactors/ChecklistManager";
import { preparationStates, PreparationStateList } from "@/data/settings";
import { Form } from "@/forms/Form";
import { IndexedFormResponse } from "@/forms/IndexedFormResponse";
import { RequestMapFunctions } from "@/forms/ViewModelFormTypes";
import { NumericalId } from "@/datastructures/NumericalId";

export class ChecklistPreparationPresenter
  implements IChecklistPreparationPresenter {
  public constructor(private vm: ChecklistPreparationViewModel) {}

  // Settings
  public set preparationStates(states: PreparationStateList) {
    this.vm.states = Form.generateSelectionList(states);
    this.vm.remainingStates.items = Form.generateSelectionList(states);
  }

  // Data
  public get sectionsWithoutState(): NumericalId[] {
    return this.vm.checklistSections
      .filter(section => section.state === "mixed")
      .map(section => NumericalId.fromString(section.id));
  }

  // Responses
  public set loadChecklistResponse(
    response: FormResponse<ChecklistPreparationData>
  ) {
    this.vm.loadChecklistRequest = response;

    if (!response.loading && !response.error) {
      this.vm.checklistId = response.data.id
        ? response.data.id.id.toString()
        : "";
      this.vm.checklistSections = response.data.sections.map(section =>
        this.buildSection(section)
      );
    }

    this.updateContinueButtonState();
  }

  public set setSectionStateResponse(
    response: IndexedFormResponse<ChecklistSectionPreparationData>
  ) {
    this.updateStateRequest(response.index, response);

    if (!response.loading && !response.error) {
      this.stateRequestFinished(response.index, response.data.preparationState);
    }
  }

  public set setSectionStatesResponse(
    response: FormResponse<ChecklistSectionPreparationData[]>
  ) {
    const sections = this.sectionsWithoutState;
    this.vm.setRemainindStatesRequest = response;
    sections.forEach(section =>
      this.updateStateRequest(section.id.toString(), response)
    );

    if (!response.loading && !response.error) {
      sections.forEach(section =>
        this.stateRequestFinished(section.id.toString(), response.params.state)
      );
    }
  }

  public set finishPreparationResponse(response: FormResponse<NumericalId>) {
    this.vm.finishPreparationRequest = response;

    if (!response.loading && !response.error) {
      this.vm.goToInspection = true;
    }
  }

  // Helper
  private updateContinueButtonState() {
    this.vm.continueButtonDisabled = !this.isChecklistPrepared();
  }

  private isChecklistPrepared(): boolean {
    return this.vm.checklistSections.every(
      section => section.state !== "mixed"
    );
  }
  private buildSection(data: ChecklistSectionPreparationData) {
    return {
      id: data.id.id.toString(),
      name: data.name,
      state: data.preparationState,
      color: preparationStates[data.preparationState]
        ? preparationStates[data.preparationState].color
        : "white"
    };
  }
  private getStateColor(stateKey: string) {
    return preparationStates[stateKey]
      ? preparationStates[stateKey].color
      : "white";
  }

  private updateStateRequest(index: string, response: FormResponse<any>) {
    this.vm.setStateRequests = RequestMapFunctions.updateRequest(
      this.vm.setStateRequests,
      index,
      response
    );
  }

  private stateRequestFinished(index: string, state: string) {
    RequestMapFunctions.removeRequest(this.vm.setStateRequests, index);

    const sectionId = this.vm.checklistSections.findIndex(
      section => section.id === index
    );
    if (sectionId >= 0) {
      this.vm.checklistSections[sectionId].color = this.getStateColor(state);
      this.vm.checklistSections[sectionId].state = state;
    }

    this.updateContinueButtonState();
  }
}
