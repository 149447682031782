import {
  convertGraphQlToImageEntity,
  ImageEntity
} from "@/shared/project/images/ImageEntity";
import { PlanEntity } from "@/property/shared/entities/PlanEntity";
import {
  convertGraphQlToPropertyEntity,
  PropertyEntity
} from "@/property/shared/entities/PropertyEntity";
import { PlanLocationEntity } from "./PlanLocationEntity";
import { TicketableEntity } from "./TicketableEntity";
import { PlantEntity } from "@/property/shared/entities/PlantEntity";
import { Dictionary } from "@/datastructures/Dictionary";

export interface TicketEntity {
  id?: number;
  syncId?: string;
  title?: string;
  description?: string;
  action?: string;
  deadline?: string;
  maturity?: Date;
  priority?: TicketPriority;
  type?: string;
  details?: string;

  ticketable?: TicketableEntity;
  property?: PropertyEntity;
  plant?: PlantEntity;
  checklistEntryTemplateId?: string;
  location?: PlanLocationEntity;
  plan?: PlanEntity;
  x?: number;
  y?: number;
  images?: ImageEntity[];

  selected?: boolean;
  plantFieldsToSync?: Dictionary<unknown>;
}

export type TicketPriority =
  | "priority1"
  | "priority2"
  | "priority3"
  | "priority4"
  | "priority5";

export function convertGraphQlToTicketEntities(data?: any[]): TicketEntity[] {
  return data?.map(t => convertGraphQlToTicketEntity(t)) ?? [];
}

export function convertGraphQlToTicketEntity(t?: any): TicketEntity {
  if (!t) {
    return {};
  }

  return {
    id: t.id,
    syncId: t.sync_id,
    title: t.title,
    description: t.description,
    priority: t.priority,
    action: t.action,
    maturity: t.maturity ? new Date(t.maturity) : undefined,
    images: t.images
      ? t.images.map((i: any) => convertGraphQlToImageEntity(i))
      : [],
    property: convertGraphQlToPropertyEntity(t.property)
  };
}
