import { storageKeys } from "@/data/storageKeys";
import { Settings } from "@/shared/datastructures/Settings";

export interface PlantTypes {
  plants?: PlantType[];
}

export interface PlantType {
  id?: string;
  name?: string;
  fieldCategories?: PlantTypeFieldCategory[];
  checklistEntries?: PlantTypeChecklistEntry[];
}

export interface PlantTypeFieldCategory {
  id?: string;
  name?: string;
  fields?: PlantTypeField[];
}

export interface PlantTypeField {
  id?: string;
  label?: string;
  key?: string;
  type?: PlantTypeFieldType;
  required?: boolean;
  options?: PlantTypeFieldOption[];
}

export interface PlantTypeFieldOption {
  id?: string;
  label?: string;
}

export interface PlantTypeChecklistEntry {
  id?: string;
  name?: string;
  description?: string;
  intervall?: string;
}

export type PlantTypeFieldType = "string" | "number" | "bool" | "select";

export class PlantTypeSettings extends Settings<PlantTypes> {
  protected get storageKey(): string {
    return storageKeys.plant_templates;
  }

  public allTypes() {
    const settings = this.get();
    return settings.plants ? settings.plants : [];
  }
}
