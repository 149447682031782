import { IPropertyDetailPresenter } from "../controllers/PropertyDetailController";
import {
  PropertyDetailViewModel,
  RelationshipDTO
} from "../vms/PropertyDetailViewModel";
import { PropertyForm } from "@/forms/property/PropertyForm";
import { CountryList } from "@/data/settings";
import { Form } from "@/forms/Form";
import { FormResponse } from "@/forms/FormResponse";
import {
  CreatePropertyData,
  PayloadPropertyLocation,
  PropertyImage,
  RelationshipInput
} from "../interactors/PropertyManager";
import { PropertyGeneralDataForm } from "@/forms/property/PropertyGeneralDataForm";
import { PropertyDetailsForm } from "@/forms/property/PropertyDetailsForm";
import { PropertyAddressForm } from "@/forms/property/PropertyAddressForm";
import { UserSettings } from "@/storage/UserSettings";
import { Authorizator } from "@/common/interactors/Authorizator";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { PersonCatalogRow } from "@/common/interactors/PersonEditor";
import { getSelectedText, TableHeader } from "@/forms/ViewModelFormTypes";
import { PropertyRelationshipForm } from "@/forms/property/PropertyRelationshipForm";
import { ObjectUtils } from "@/utils/ObjectUtils";
import { ArrayUtils } from "@/utils/ArrayUtils";
import { StringUtils } from "@/utils/StringUtils";

export class PropertyDetailPresenter implements IPropertyDetailPresenter {
  public propertyForm: PropertyForm;
  public generalDataForm: PropertyGeneralDataForm;
  public detailsForm: PropertyDetailsForm;
  public addressForm: PropertyAddressForm;
  public relationshipForm: PropertyRelationshipForm;

  private id = "";
  private deletePropertiesAllowed = false;
  private editPropertiesAllowed = false;

  public constructor(private vm: PropertyDetailViewModel) {
    this.propertyForm = new PropertyForm(this.vm, this.setPropertyFormValid);
    this.generalDataForm = new PropertyGeneralDataForm(
      this.vm,
      this.generalDataFormValidated
    );
    this.detailsForm = new PropertyDetailsForm(
      this.vm,
      this.detailsFormValidated
    );
    this.addressForm = new PropertyAddressForm(
      this.vm,
      this.addressFormValidated
    );
    this.relationshipForm = new PropertyRelationshipForm(
      this.vm,
      this.relationshipFormValidated
    );

    this.propertyForm.init();
    this.generalDataForm.init();
    this.detailsForm.init();
    this.addressForm.init();
    this.relationshipForm.init();

    this.initAuthorization();
  }

  public init() {
    const relationships = UserSettings.getRelationships().relationships;
    if (relationships) {
      for (const relationship of relationships) {
        const tableHeader: TableHeader[] = [
          {
            text: relationship.name,
            value: "relationship",
            width: "30%",
            bold: true
          },
          {
            text: "Person",
            value: "person"
          }
        ];

        const tableHeaderTablet: TableHeader[] = [
          {
            text: relationship.name,
            value: "relationship",
            width: "30%",
            bold: true
          },
          {
            text: "Person",
            value: "person"
          }
        ];

        const tableHeaderMobile: TableHeader[] = [
          {
            text: relationship.name,
            value: "relationship",
            width: "100%",
            bold: true
          }
        ];

        if (Authorizator.canEditProperties()) {
          const actionHeader = {
            text: "Beziehung hinzufügen",
            value: "action",
            icon: "add",
            align: "end"
          };

          tableHeader.push(actionHeader);
          tableHeaderTablet.push(actionHeader);
          tableHeaderMobile.push(actionHeader);
        }

        this.vm.relationships[relationship.id] = {
          id: relationship.id,
          name: relationship.name,
          tableHeader,
          tableHeaderTablet,
          tableHeaderMobile,
          roles: relationship.roles.map(role => ({
            id: role.id,
            name: role.name
          })),
          relationships: []
        };
      }
    }
  }

  public set propertyId(id: string) {
    this.id = id;
  }

  public set editProperty(edit: boolean) {
    this.vm.editingGeneralDataDeactivated =
      !edit || !this.deletePropertiesAllowed;
    this.vm.editingGeneralDataDisabled = !edit;
    this.vm.editingGeneralData = !edit;

    this.vm.editingDetailsDeactivated = !edit || !this.deletePropertiesAllowed;
    this.vm.editingDetailsDisabled = !edit;
    this.vm.editingDetails = !edit;

    this.vm.editingAddressDeactivated = !edit || !this.deletePropertiesAllowed;
    this.vm.editingAddressDisabled = !edit;
    this.vm.editingAddress = !edit;

    this.vm.createPropertyButtonVisible = !edit;
    this.vm.deletePropertyButtonVisible = edit && this.deletePropertiesAllowed;

    this.vm.propertyLocationVisible = edit;
  }

  public set countries(countries: CountryList) {
    this.vm.country.items = Form.generateSelectionList(countries);
  }

  public set relationshipDialogVisible(visible: boolean) {
    this.vm.relationshipDialogVisible = visible;
    if (!visible) {
      this.relationshipForm.reset();
    }
  }

  public set selectedRelationshipType(id: string) {
    const relationship = this.vm.relationships[id];

    if (relationship) {
      this.vm.relationshipTypeId = id;
      this.vm.relationshipDialogTitle = relationship.name + " bearbeiten";
      this.vm.relationshipRole.items = relationship.roles.map(role => ({
        text: role.name,
        value: role.id
      }));
    }
  }

  public set loadPropertyResponse(response: FormResponse<CreatePropertyData>) {
    this.vm.loadPropertyRequest = response;

    if (!response.loading && !response.error) {
      this.editProperty = true;
      this.property = response.data;
    }
  }

  public set loadPeopleResponse(
    response: FormResponse<PaginatedList<PersonCatalogRow>>
  ) {
    this.vm.relationshipRole.loading = response.loading;
    this.vm.relationshipRole.error = response.error;

    if (response.success) {
      this.vm.relationshipPerson.items = response.data.items.map(person => ({
        text:
          person.firstname +
          " " +
          person.lastname +
          " - " +
          person.street +
          ", " +
          person.zip +
          " " +
          person.city,
        value: person.id.toString()
      }));
    }
  }

  public set createPropertyResponse(response: FormResponse<number>) {
    this.vm.createPropertyRequest = response;

    if (!response.loading && !response.error) {
      this.vm.createdProperty = response.data;
    }
  }

  public set deletePropertyResponse(response: FormResponse<number>) {
    this.vm.deletePropertyRequest = response;

    if (!response.loading && !response.error) {
      this.vm.deletedProperty = response.data.toString();
    }
  }

  public set updateGeneralDataResponse(response: FormResponse<number>) {
    this.vm.editingGeneralDataRequest = response;

    if (!response.loading) {
      this.vm.editingGeneralDataDisabled = false;
      if (!response.error) {
        this.vm.editingGeneralData = false;
      }
    }
  }

  public set updateDetailsResponse(response: FormResponse<number>) {
    this.vm.editingDetailsRequest = response;

    if (!response.loading) {
      this.vm.editingDetailsDisabled = false;
      if (!response.error) {
        this.vm.editingDetails = false;
      }
    }
  }

  public set updateAddressResponse(
    response: FormResponse<PayloadPropertyLocation>
  ) {
    this.vm.editingAddressRequest = response;

    if (!response.loading) {
      this.vm.editingAddressDisabled = false;
      if (!response.error) {
        this.vm.editingAddress = false;
        this.vm.latitude = response.data.lat;
        this.vm.longitude = response.data.lng;
      }
    }
  }

  public set setPropertyImageResponse(response: FormResponse<PropertyImage>) {
    this.vm.propertyImage.loading = response.loading;
    this.vm.propertyImage.error = response.error;

    if (response.success) {
      this.vm.propertyImageId = response.data.id;
      this.vm.propertyImagePath = response.data.path;
      this.vm.propertyImageVisible = true;
      this.vm.propertyImageInputVisible = false;
    }
  }

  public set deletePropertyImageResponse(response: FormResponse<void>) {
    this.vm.deletePropertyImageRequest = response;

    if (response.success) {
      this.vm.propertyImageId = 0;
      this.vm.propertyImagePath = "";
      this.vm.propertyImageVisible = false;
      this.vm.propertyImageInputVisible = true;
    }
  }

  public set upsertRelationshipResponse(response: FormResponse<number>) {
    this.vm.upsertRelationshipRequest = response;

    if (response.success) {
      const updatedRelationship = response.params.relationship;
      if (!updatedRelationship) {
        this.vm.relationshipId = response.data;
        this.addRelationship(this.relationshipUnderEdit);
      } else {
        this.vm.relationshipId = updatedRelationship.id;
        this.updateRelationship(updatedRelationship);
      }
    }
  }

  public set deleteRelationshipResponse(response: FormResponse<void>) {
    this.vm.deleteRelationshipRequest = response;

    if (response.success) {
      this.removeRelationship(response.params.relationship);
    }
  }

  public set editingGeneralData(editing: boolean) {
    this.vm.editingGeneralData = editing;
  }

  public set editingDetails(editing: boolean) {
    this.vm.editingDetails = editing;
  }

  public set editingAddress(editing: boolean) {
    this.vm.editingAddress = editing;
  }

  public set property(data: CreatePropertyData) {
    this.vm.propertyId = data.id || 0;
    this.vm.addressId = data.addressId || 0;

    this.generalDataForm.setFieldValue("name", data.name);
    this.generalDataForm.setFieldValue("description", data.description);

    this.detailsForm.setFieldValue("legalForm", data.legalForm);
    this.detailsForm.setFieldValue(
      "cadastralCommunity",
      data.cadastralCommunity
    );
    this.detailsForm.setFieldValue("assetNumber", data.assetNumber);
    this.detailsForm.setFieldValue("buildYear", data.buildYear.toString());
    this.detailsForm.setFieldValue("parcelNumber", data.parcelNumber);
    this.detailsForm.setFieldValue(
      "singleCadastre",
      data.singleCadastre.toString()
    );
    this.detailsForm.setFieldValue(
      "effectiveArea",
      data.effectiveArea.toString()
    );
    this.detailsForm.setFieldValue("unitCount", data.unitCount.toString());
    this.detailsForm.setFieldValue("dwsBaseNumber", data.dwsBaseNumber);

    this.addressForm.setFieldValue("street", data.street);
    this.addressForm.setFieldValue("zip", data.zip);
    this.addressForm.setFieldValue("city", data.city);
    this.addressForm.setFieldValue("country", data.country);

    this.vm.latitude = data.lat;
    this.vm.longitude = data.lng;

    this.vm.propertyImageInputVisible = !data.imagePath;
    this.vm.propertyImageVisible = !!data.imagePath;
    this.vm.propertyImageId = data.imageId;
    this.vm.propertyImagePath = data.imagePath;

    data.relationships.map(r => {
      let roleLabel = "";
      const structure = this.vm.relationships[r.relationshipId];
      if (structure) {
        const foundRole = structure.roles.find(role => role.id === r.roleId);
        if (foundRole) {
          roleLabel = foundRole.name;
        }
      }

      this.addRelationship({
        id: r.id,
        relationshipTypeId: r.relationshipId,
        roleId: r.roleId,
        roleLabel,
        personId: r.personId,
        personLabel:
          r.firstname +
          " " +
          r.lastname +
          " - " +
          r.street +
          ", " +
          r.zip +
          " " +
          r.city
      });
    });
  }
  public get property(): CreatePropertyData {
    return {
      id: this.vm.propertyId,
      addressId: this.vm.addressId,
      name: this.vm.name.value,
      description: this.vm.description.value,
      legalForm: this.vm.legalForm.value,
      cadastralCommunity: this.vm.cadastralCommunity.value,
      assetNumber: this.vm.assetNumber.value,
      buildYear: this.vm.buildYear.value,
      parcelNumber: this.vm.parcelNumber.value,
      singleCadastre: this.vm.singleCadastre.value,
      effectiveArea: this.vm.effectiveArea.value,
      dwsBaseNumber: this.vm.dwsBaseNumber.value,
      unitCount: this.vm.unitCount.value,
      street: this.vm.street.value,
      zip: this.vm.zip.value,
      city: this.vm.city.value,
      country: this.vm.country.selected,
      lat: this.vm.latitude,
      lng: this.vm.longitude,
      imageId: this.vm.propertyImageId,
      imagePath: this.vm.propertyImagePath,
      clientId: parseInt(UserSettings.getClientId(), 10),
      relationships: Object.values(this.vm.relationships).flatMap(structure =>
        structure.relationships.map(r => ({
          id: r.id,
          personId: r.personId,
          relationshipId: r.relationshipTypeId,
          roleId: r.roleId
        }))
      )
    };
  }

  public get relationship(): RelationshipInput {
    return {
      id: this.vm.relationshipId,
      relationshipId: this.vm.relationshipTypeId,
      roleId: this.vm.relationshipRole.selected,
      personId: parseInt(this.vm.relationshipPerson.selected, 10)
    };
  }

  public get relationshipUnderEdit(): RelationshipDTO {
    return {
      id: this.vm.relationshipId
        ? this.vm.relationshipId
        : this.vm.relationships[this.vm.relationshipTypeId].relationships
            .length,
      relationshipTypeId: this.vm.relationshipTypeId,
      roleId: this.vm.relationshipRole.selected,
      roleLabel: getSelectedText(this.vm.relationshipRole),
      personId: parseInt(this.vm.relationshipPerson.selected, 10),
      personLabel: getSelectedText(this.vm.relationshipPerson)
    };
  }

  public addRelationship(relationship: RelationshipDTO) {
    const relStructure = this.vm.relationships[relationship.relationshipTypeId];

    if (relStructure) {
      relStructure.relationships.push(relationship);
      this.vm.relationships = ObjectUtils.deepCopy(this.vm.relationships);
      this.relationshipDialogVisible = false;
    }
  }

  public editRelationship(relationship?: RelationshipDTO) {
    this.vm.relationshipId = relationship ? relationship.id : 0;
    this.vm.selectedRelationship = relationship;
    if (relationship) {
      this.selectedRelationshipType = relationship.relationshipTypeId;
      this.relationshipDialogVisible = true;
      this.relationshipForm.setFieldValue(
        "relationshipRole",
        relationship.roleId
      );
      this.relationshipForm.setFieldValue(
        "relationshipPerson",
        relationship.personId.toString()
      );
    }
  }

  public updateRelationship(relationship: RelationshipDTO) {
    const newRelationship = this.relationshipUnderEdit;

    relationship.personId = newRelationship.personId;
    relationship.personLabel = newRelationship.personLabel;
    relationship.roleId = newRelationship.roleId;
    relationship.roleLabel = newRelationship.roleLabel;

    this.vm.relationships = ObjectUtils.deepCopy(this.vm.relationships);
    this.relationshipDialogVisible = false;
  }

  public removeRelationship(relationship: RelationshipDTO) {
    const relStructure = this.vm.relationships[relationship.relationshipTypeId];
    relStructure.relationships = ArrayUtils.remove(
      relationship,
      relStructure.relationships
    );

    this.vm.relationships = ObjectUtils.deepCopy(this.vm.relationships);
  }

  private setPropertyFormValid(context: any, valid: boolean) {
    context.createPropertyButtonDisabled = !valid;
  }

  private generalDataFormValidated(context: any, valid: boolean) {
    context.editingGeneralDataDisabled = !valid;
  }

  private detailsFormValidated(context: any, valid: boolean) {
    context.editingDetailsDisabled = !valid;
  }

  private addressFormValidated(context: any, valid: boolean) {
    context.editingAddressDisabled = !valid;
  }

  private relationshipFormValidated(context: any, valid: boolean) {
    context.saveRelationshipButtonDisabled = !valid;
  }

  private initAuthorization() {
    if (this.id === "") {
      this.vm.goBack = !Authorizator.canCreateProperties();
    }
    this.vm.goBack = !Authorizator.canViewProperties();

    this.vm.deletePropertyButtonVisible = Authorizator.canDeleteProperties();
    this.deletePropertiesAllowed = Authorizator.canDeleteProperties();

    this.vm.editingGeneralDataDeactivated = Authorizator.canEditProperties();
    this.vm.editingDetailsDeactivated = Authorizator.canEditProperties();
    this.vm.editingAddressDeactivated = Authorizator.canEditProperties();
    this.vm.editPropertyImageAllowed = Authorizator.canEditProperties();
    this.vm.addRelationshipButtonVisible = Authorizator.canEditProperties();
    this.vm.editRelationshipButtonVisible = Authorizator.canEditProperties();
    this.vm.deleteRelationshipButtonVisible = Authorizator.canEditProperties();
    this.editPropertiesAllowed = Authorizator.canEditProperties();
  }
}
