import { Paginated } from "../datastructures/Paginated";
import { RequestModel } from "./RequestModel";

export abstract class PaginatedRequestModel<T> extends RequestModel<
  Paginated<T>
> {
  public totalCount = 0;

  public from(data: Paginated<T>) {
    this.totalCount = data.totalCount;
    this.fromPaginated(data.items);
  }

  public abstract fromPaginated(entities: T[]): void;
}
