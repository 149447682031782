
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { Client } from "@/property/shared/models/Client";
import { ClientContactForm as Form } from "../../forms/ClientContactForm";
import { RequestHandler } from "@/shared/utils/RequestHandler";

@Component<ClientContactForm>({
  components: {}
})
export default class ClientContactForm extends Vue {
  @Prop({
    default() {
      return new Client();
    }
  })
  protected readonly client!: Client;
  @Prop({ type: Boolean, default: false })
  protected readonly disabled!: boolean;

  protected form = new Form();

  @Watch("client.success")
  protected onClientLoaded(loaded: boolean) {
    this.form.setFieldValue("areaCode", this.client.areaCode, true);
    this.form.setFieldValue("phoneNumber", this.client.phoneNumber, true);
    this.form.setFieldValue("email", this.client.email, true);
    this.form.setFieldValue("website", this.client.website, true);
    this.form.setFieldValue("skype", this.client.skype, true);
  }

  protected clearPhoneNumber() {
    this.form.setFieldValue("areaCode", "");
    this.form.setFieldValue("phoneNumber", "");
    this.updateClient("phoneNumber", "");
  }

  protected async updateClient(fieldName: string, value: unknown) {
    RequestHandler.handleFormUpdate(
      () => this.client.updateContactData(this.form.getData()),
      {
        form: this.form,
        fieldName,
        value
      }
    );
  }

  protected async created() {
    this.form = new Form();
    await this.form.init();
    this.form.enabled = !this.disabled;
  }

  protected mounted() {
    this.load();
  }

  protected load() {
    if (!this.client.loadingOrLoaded) {
      this.client.load();
    }
  }
}
