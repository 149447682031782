import { Dictionary } from "@/shared/datastructures/Dictionary";

const translation: Dictionary<string> = {
  preparation: "In Vorbereitung",
  inspection: "In Bearbeitung",
  finished: "Abgeschlossen",
  report_content: "Berichtdaten",
  ticket_templates: "Ticket-Vorlagen",
  priorities: "Prioritäten",
  b1300: "B1300",
  relationships: "Gebäudebeziehungen",
  structure_types: "Gebäude-Strukturtypen",
  checklists: "Checklisten",
  ticket_types: "Ticketarten",
  deadlines: "Fristen",
  ticket_states: "Ticketstatus",
  austria: "Österreich",
  germany: "Deutschland",
  plant_templates: "Anlagentypen",
  move_out_checklist: "Wohnungsübergabeprotokoll"
};

export class InMemoryTranslator {
  public t(text: string) {
    return translation[text] || text;
  }
}
