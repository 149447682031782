import { ISettingsManager } from "../interactors/SettingsManager";
import { FormResponse } from "@/forms/FormResponse";
import { NumericalId } from "@/datastructures/NumericalId";
import { IUserSettings } from "@/storage/UserSettings";
import {
  Setting,
  GlobalSettingInput,
  ClientSettingInput
} from "@/entities/Setting";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { DynamicForm } from "@/forms/DynamicForm";
import { SettingValueViewModel } from "../vms/SettingDetailViewModel";

export class SettingDetailController {
  public constructor(
    private presenter: ISettingDetailPresenter,
    private settingsManager: ISettingsManager,
    private userSettings: IUserSettings
  ) {}

  // Requests
  public init(settingKey: string, isGlobal: boolean) {
    this.presenter.clientId = isGlobal ? "0" : this.userSettings.clientId;

    this.presenter.init();

    NumericalId.onValidId(this.userSettings.clientId, clientId => {
      let request: Promise<Setting>;
      if (isGlobal) {
        request = this.settingsManager.loadSetting(settingKey);
      } else {
        request = this.settingsManager.loadSetting(settingKey, clientId);
      }

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadSettingResponse = response),
        "Einstellung konnte nicht geladen werden"
      );
    });
  }

  // Actions
  public settingFieldChanged(keyChain: Array<string | number>, value: string) {
    this.presenter.changeFieldValue(keyChain, value);
  }

  public settingResetted(key: string) {
    this.presenter.reset(key);
  }

  public settingLineAdded(keyChain: string[], values: SettingValueViewModel) {
    this.presenter.addLine(keyChain, values);
  }

  public settingLineRemoved(keyChain: Array<string | number>, index: number) {
    this.presenter.removeLine(keyChain, index);
  }

  public settingDeactivatedToggled(
    keyChain: Array<string | number>,
    index: number
  ) {
    this.presenter.toggleDeactivated(keyChain, index);
  }

  public saveSettingButtonClicked(settingId: string, clientId: string) {
    const numericalClientId = NumericalId.fromString(clientId);

    NumericalId.onValidId(
      settingId,
      id => {
        this.saveSetting(numericalClientId, id);
      },
      () => {
        this.saveSetting(numericalClientId);
      }
    );
  }

  public settingSavedDialogButtonClicked() {
    this.presenter.settingSavedDialogVisible = false;
  }

  // Helper
  private saveSetting(clientId: NumericalId, settingId?: NumericalId) {
    let request: Promise<NumericalId> = Promise.resolve(new NumericalId());

    if (!clientId.valid) {
      request = this.settingsManager.saveGlobalSetting(
        this.presenter.globalSetting,
        settingId
      );
    } else {
      request = this.settingsManager.saveClientSetting(
        this.presenter.clientSetting,
        settingId
      );
    }

    FormRequestHandler.handle(
      request,
      response => (this.presenter.saveSettingResponse = response),
      "Einstellungen konnten nicht gespeichert werden"
    );
  }
}

export interface ISettingDetailPresenter {
  // Data
  globalSetting: GlobalSettingInput;
  clientSetting: ClientSettingInput;
  clientId: string;

  // States
  settingSavedDialogVisible: boolean;

  // Forms
  settingForm: DynamicForm;

  // Responses
  loadSettingResponse: FormResponse<Setting>;
  saveSettingResponse: FormResponse<NumericalId>;

  // Methods
  init(): void;
  addLine(keyChain: string[], values: SettingValueViewModel): void;
  removeLine(keyChain: Array<string | number>, index: number): void;
  changeFieldValue(keyChain: Array<string | number>, value: string): void;
  reset(key: string): void;
  toggleDeactivated(keyChain: Array<string | number>, index: number): void;
}

export interface SettingValues {
  [key: string]: string;
}
