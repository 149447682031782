
import Vue from "vue";
import Component from "vue-class-component";

@Component<TruncatedText>({
  props: {
    width: {
      type: String,
      default: "100%"
    }
  }
})
export default class TruncatedText extends Vue {}
