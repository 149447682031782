import { AxiosGraphQLConnection } from "@/gateways/graphql/connection/AxiosGraphQLConnection";
import { PropertyViewerGraphQLGateway } from "@/gateways/graphql/PropertyViewerGraphQLGateway";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { BluebookRedux } from "@/plugins/vuex";
import { Paginated } from "@/shared/datastructures/Paginated";
import { LocalStorage } from "@/storage/LocalStorage";
import { NewPropertyPartData } from "../../dtos/NewPropertyPartData";
import { Property } from "../../dtos/Property";
import { PropertyPart, PropertyPartsAndPlants } from "../../dtos/PropertyPart";
import { PropertyEntity } from "../../entities/PropertyEntity";
import { PropertyList } from "../../models/PropertyList";
import { PlanPool } from "../planRepository/PlanPool";
import { PropertyGraphQLProvider } from "./PropertyGraphQLProvider";
import { PropertyProvider } from "./PropertyProvider";

export interface PropertyRepository {
  getProperties(
    userId: number,
    page: number,
    search: string
  ): Promise<Paginated<Property>>;
  getPropertySelection(userId: number): Promise<PropertyEntity[]>;
  getPropertyBasePart(propertyId: number): Promise<PropertyPart>;
  getPropertyChildPartsAndPlants(
    parentPartId: number
  ): Promise<PropertyPartsAndPlants>;
  getSelectedProperties(): Promise<PropertyList>;

  selectProperties(properties: Property[]): Promise<number[]>;

  createPropertyPart(
    parentPartId: number,
    data: NewPropertyPartData
  ): Promise<number>;
  editPropertyPart(partId: number, data: NewPropertyPartData): Promise<number>;
  deletePropertyPart(partId: number): Promise<number>;
}

interface PropertyRepositoryOptions {
  offlinePlanCache?: PlanPool;
}

export class DefaultPropertyRepository implements PropertyRepository {
  public constructor(
    private webProvider: PropertyProvider = new PropertyGraphQLProvider(),
    private redux: BluebookRedux,
    private options?: PropertyRepositoryOptions
  ) {}

  public getProperties(
    userId: number,
    page: number,
    search: string
  ): Promise<Paginated<Property>> {
    return this.webProvider.loadProperties(userId, page, search);
  }

  public getPropertySelection(userId: number): Promise<PropertyEntity[]> {
    return this.webProvider.loadPropertySelection(userId);
  }

  public getPropertyBasePart(propertyId: number): Promise<PropertyPart> {
    return this.webProvider.loadPropertyBasePart(propertyId);
  }

  public getPropertyChildPartsAndPlants(
    parentPartId: number
  ): Promise<PropertyPartsAndPlants> {
    return this.webProvider.loadPropertyChildPartsAndPlants(parentPartId);
  }

  public async getSelectedProperties(): Promise<PropertyList> {
    return PropertyList.collect(this.redux.getters.getSelectedProperties);
  }

  public async selectProperties(properties: Property[]): Promise<number[]> {
    // TODO: Refactor to new architecture
    if (properties.length === 1) {
      const connection = new AxiosGraphQLConnection(
        new AxiosLogger(new ConsoleLogger())
      );
      const propGateway = new PropertyViewerGraphQLGateway(
        connection,
        new LocalStorage()
      );

      await propGateway.selectProperty(properties[0]);
    }

    return properties.map(p => p.id);
  }

  public createPropertyPart(
    parentPartId: number,
    data: NewPropertyPartData
  ): Promise<number> {
    return this.webProvider.createPropertyPart(parentPartId, data);
  }

  public editPropertyPart(
    partId: number,
    data: NewPropertyPartData
  ): Promise<number> {
    return this.webProvider.editPropertyPart(partId, data);
  }

  public deletePropertyPart(partId: number): Promise<number> {
    return this.webProvider.deletePropertyPart(partId);
  }
}
