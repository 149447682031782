import { DocumentSettings, DocumentCategory } from "./DocumentSettings";
import { PlantTypeSettings } from "./PlantTypeSettings";
import { PropertyPartTypeSettings } from "./PropertyPartTypeSettings";

export class PropertySettingsContainer {
  public static propertyPartTypes = new PropertyPartTypeSettings();
  public static generalDocuments = new DocumentSettings(
    DocumentCategory.General
  );
  public static propertyDocuments = new DocumentSettings(
    DocumentCategory.Property
  );
  public static plantTypes = new PlantTypeSettings();
}
