import { TicketEntity } from "@/inspection/shared/entities/TicketEntity";
import { PlanEntity } from "@/property/shared/entities/PlanEntity";
import { ImageEntity } from "@/shared/project/images/ImageEntity";
import { DBSchema, openDB } from "idb";
import * as Sentry from "@sentry/vue";

export interface BluebookIndexedDB extends DBSchema {
  tickets: {
    key: string;
    value: TicketEntity;
  };
  ticketImages: {
    key: string;
    value: ImageEntity;
  };
  plans: {
    key: string;
    value: PlanEntity;
  };
  offlinePlans: {
    key: string;
    value: PlanEntity;
  };
}

const dbName = "bluebook";

export const bluebookDB = openDB<BluebookIndexedDB>(dbName, 2, {
  upgrade: (db, oldVersion, newVersion) => {
    const pass = !oldVersion || !newVersion;

    if (pass || (oldVersion! < 1 && newVersion! >= 1)) {
      db.createObjectStore("tickets");
      db.createObjectStore("ticketImages");
      db.createObjectStore("plans");
    }

    if (pass || (oldVersion! < 2 && newVersion! >= 2)) {
      db.createObjectStore("offlinePlans");
    }
  }
}).catch(err => {
  Sentry.captureException(err);
  return undefined;
});
