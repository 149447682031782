
import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Grid from "@/components/layout/Grid.vue";
import Section from "@/components/layout/Section.vue";

import Select from "@/components/form/Select.vue";
import Textfield from "@/components/form/Textfield.vue";
import IconButton from "@/components/basic/IconButton.vue";
import RequestButton from "@/components/basic/RequestButton.vue";
import ResponsiveIconButton from "@/components/basic/ResponsiveIconButton.vue";

import { CreatePersonViewModel } from "@/office/vms/CreatePersonViewModel";
import { CreatePersonController } from "@/office/controllers/CreatePersonController";
import { CreatePersonPresenter } from "@/office/presenters/CreatePersonPresenter";
import { PersonManager } from "../interactors/PersonManager";
import { PersonGraphQLGateway } from "../../gateways/graphql/PersonGraphQLGateway";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connection/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { LocalStorage } from "../../storage/LocalStorage";

@Component<CreatePersonView>({
  components: {
    Grid,
    Row,
    Column,
    IconButton,
    Section,
    Textfield,
    Select,
    ResponsiveIconButton,
    RequestButton
  },
  props: {
    clientId: {
      type: String,
      default: "0"
    }
  },
  watch: {
    "vm.personCreated"(id: number) {
      this.$emit("created", this.vm.personCreated);
      this.reset();
    }
  }
})
export default class CreatePersonView extends Vue {
  private vm = new CreatePersonViewModel();

  public reset() {
    this.vm = new CreatePersonViewModel();
    this.controller.init(this.$props.clientId);
  }

  private get controller() {
    return new CreatePersonController(
      new CreatePersonPresenter(this.vm),
      new PersonManager(
        new PersonGraphQLGateway(
          new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger()))
        )
      ),
      new LocalStorage()
    );
  }
  private mounted() {
    this.controller.init(this.$props.clientId);
  }
}
