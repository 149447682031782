import Vue from "vue";
import "./plugins/sentry";
import "./plugins/googlemaps.js";
import "./plugins/konva.js";
import "./plugins/visibility";
import "./plugins/viewer";
import App from "./App.vue";
import router from "./router";
import store from "./plugins/vuex";
import vuetify from "./plugins/vuetify";
import "./registerServiceWorker";
import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

import "./components/ComponentRegistration.ts";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
