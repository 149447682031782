import SubjectTypes from "@/document/utils/SubjectTypes";
import { AccessResourceVm } from "./AccessResourceVm";

export class AccessClientVm extends AccessResourceVm {
  public get children() {
    return undefined;
  }

  public get shareable() {
    return true;
  }

  public get type() {
    return SubjectTypes.client();
  }

  public async loadChildren() {
    // Load properties
  }
}
