import { RestConnection } from "./connection/RestConnection";
import { Location } from "@/entities/Location";
import { LocationGateway } from "@/admin/interactors/PropertyManager";

const geocodeUrl = process.env.VUE_APP_GMAP_URL;
const geocodeApiKey = process.env.VUE_APP_GMAP_API_KEY;

export class GmapRestGateway implements LocationGateway {
  public constructor(private connection: RestConnection) {}

  public loadLocation(
    street: string,
    zip?: string,
    city?: string,
    country?: string
  ): Promise<Location> {
    return this.connection
      .get(geocodeUrl || "", {
        address: this.buildAddress(street, zip, city, country),
        key: geocodeApiKey
      })
      .then(response => response.results)
      .then(results => {
        if (results.length > 0) {
          const location = results[0].geometry.location;
          return new Location(location.lat, location.lng);
        } else {
          return new Location(0, 0);
        }
      });
  }

  private buildAddress(
    street: string,
    zip?: string,
    city?: string,
    country?: string
  ) {
    return (
      street +
      (zip ? ", " + zip : "") +
      (city ? " " + city : "") +
      (country ? ", " + country : "")
    );
  }
}
