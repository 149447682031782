
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import RoleSelect from "@/property/shared/widgets/views/RoleSelect.vue";

import { Access, AccessResource } from "@/common/shared/models/Access";
import { CommonRepoContainer } from "@/common/shared/repositories/CommonRepoContainer";
import { AddAccessUseCase } from "@/common/access/useCases/AddAccessUseCase";
import { UpdateAccessUseCase } from "@/common/access/useCases/UpdateAccessUseCase";

@Component<AccessForm>({
  components: { RoleSelect }
})
export default class AccessForm extends Vue {
  @Prop({ type: Object, required: true })
  protected readonly resource!: AccessResource;
  @Prop({ type: Object, default: () => new Access() })
  protected readonly access!: Access;

  protected accessCopy = new Access();
  protected accessCreator = new AddAccessUseCase(
    CommonRepoContainer.accessRepo
  );
  protected accessUpdater = new UpdateAccessUseCase(
    CommonRepoContainer.accessRepo
  );

  @Watch("access")
  protected onAccessChanged() {
    this.init();
  }

  protected get loading() {
    return this.accessCreator.loads || this.accessUpdater.loads;
  }

  protected get error() {
    return this.accessCreator.failed
      ? this.accessCreator.error
      : this.accessUpdater.error;
  }

  protected async upsertAccess() {
    const upsertedAccess = this.accessCopy.exists
      ? await this.accessUpdater.do(this.accessCopy)
      : await this.accessCreator.do(this.accessCopy);

    if (upsertedAccess) {
      this.init();
      this.$emit("upserted", upsertedAccess);
    }
  }

  protected mounted() {
    this.init();
  }

  protected init() {
    this.accessCopy = this.access.copy() as Access;
    this.accessCopy.recipient = { id: 0, type: "guest" };
    this.accessCopy.resource = this.resource;
  }
}
