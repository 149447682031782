import { NumericalId } from "@/datastructures/NumericalId";
import { Line } from "@/datastructures/Line";
import { Plan } from "@/entities/Plan";
import {
  IPropertyPlanViewer,
  PropertyPlanViewerGateway
} from "@/expert/interactors/PropertyPlanViewer";

export class PropertyPlanManager implements IPropertyPlanManager {
  public constructor(
    private gateway: PropertyPlanManagerGateway,
    private planViewer: IPropertyPlanViewer
  ) {}

  public loadPlanCatalog(propertyId: NumericalId): Promise<PlanCatalogRow[]> {
    return this.planViewer.loadPlanCatalog(propertyId);
  }

  public loadPlan(planId: NumericalId): Promise<PlanPayload> {
    return this.planViewer.loadPlan(planId);
  }

  public loadTicketPlan(
    ticketId: NumericalId,
    planId: NumericalId
  ): Promise<PlanPayload> {
    return this.planViewer.loadTicketPlan(ticketId, planId);
  }

  public createPlan(
    propertyId: NumericalId,
    data: CreatePlanData
  ): Promise<PlanCatalogRow> {
    return this.gateway.createPlan(propertyId, data).then(plan => ({
      id: new NumericalId(plan.id),
      name: plan.name,
      offline: plan.offline,
      file: {
        id: new NumericalId(plan.file.id),
        path: plan.file.path
      }
    }));
  }

  public updatePlan(data: PlanData): Promise<void> {
    return this.gateway.updatePlan(data);
  }

  public deletePlan(id: NumericalId): Promise<NumericalId> {
    return this.gateway.deletePlan(id);
  }

  public replacePlanImage(
    propertyId: NumericalId,
    planId: NumericalId,
    imageId: NumericalId,
    newPlanImage: File
  ): Promise<PlanImageData> {
    return this.gateway.replacePlanImage(
      propertyId,
      planId,
      imageId,
      newPlanImage
    );
  }
}

export interface IPropertyPlanManager extends IPropertyPlanViewer {
  createPlan(
    propertyId: NumericalId,
    data: CreatePlanData
  ): Promise<PlanCatalogRow>;
  updatePlan(data: PlanData): Promise<void>;
  deletePlan(id: NumericalId): Promise<NumericalId>;
  replacePlanImage(
    propertyId: NumericalId,
    planId: NumericalId,
    imageId: NumericalId,
    newPlanImage: File
  ): Promise<PlanImageData>;
}

export interface PropertyPlanManagerGateway extends PropertyPlanViewerGateway {
  createPlan(propertyId: NumericalId, data: CreatePlanData): Promise<Plan>;
  updatePlan(data: PlanData): Promise<void>;
  deletePlan(id: NumericalId): Promise<NumericalId>;
  replacePlanImage(
    propertyId: NumericalId,
    planId: NumericalId,
    imageId: NumericalId,
    newPlanImage: File
  ): Promise<PlanImageData>;
}

export interface PlanPayload {
  id: NumericalId;
  name: string;
  offline: boolean;
  file: PlanImageData;
  pixelDistance: Line;
  realDistance: Line;
}

export interface PlanData {
  id: NumericalId;
  name: string;
  offline: boolean;
  file: File;
  pixelDistance: Line;
  realDistance: Line;
}

export interface PlanImageData {
  id: NumericalId;
  path: string;
}

export interface CreatePlanData {
  name: string;
  file: File;
}

export interface PlanCatalogRow {
  id: NumericalId;
  name: string;
  offline: boolean;
  file: PlanImageData;
}
