
import Vue from "vue";
import Component from "vue-class-component";

import ResponsiveIconButton from "@/components/basic/ResponsiveIconButton.vue";

import { CreateAssignmentButtonModel } from "../../models/CreateAssignmentButtonModel";
import { Plant } from "@/property/shared/dtos/Plant";

@Component<CreateAssignmentButton>({
  components: {
    ResponsiveIconButton
  },
  props: {
    plantType: {
      type: String,
      default: undefined
    }
  }
})
export default class CreateAssignmentButton extends Vue {
  protected model = new CreateAssignmentButtonModel();
  protected dropDownVisible = false;
  protected plantType!: string;

  protected get plants() {
    return this.$store.getters.getPlantsOfType(this.plantType);
  }

  protected mounted() {
    this.model.init(this.plants);
  }

  protected toggleDropdownOrSelectFirstPlant() {
    const firstPlant = this.model.firstPlant;
    if (this.model.hasSinglePlant && firstPlant) {
      this.goToNewAssignmentPage(firstPlant.id.toString());
    } else if (this.model.hasMultiplePlants) {
      this.dropDownVisible = !this.dropDownVisible;
    } else {
      this.goToPlantsListPage();
    }
  }

  protected goToNewAssignmentPage(plantId: string) {
    this.$router.push({ name: "assignment", params: { plantId } });
  }

  protected goToPlantsListPage() {
    this.$router.push({ name: "plants" });
  }
}
