import { ReportData } from "../../dtos/ReportData";
import { ReportSettings } from "../../dtos/ReportSettings";
import { ReportEntity } from "../../entities/ReportEntity";
import { ReportGraphQLProvider } from "./ReportGraphQLProvider";
import { ReportProvider } from "./ReportProvider";

export interface ReportRepository {
  getReportByType(
    userId: number,
    assignmentId: number,
    reportType: string
  ): Promise<ReportEntity>;

  getReportSettings(
    userId: number,
    assignmentId: string,
    reportType: string
  ): Promise<ReportSettings>;

  getReportData(
    userId: number,
    assignmentId: string,
    reportType: string
  ): Promise<ReportData>;

  updateReportSettings(
    settings: string,
    assignmentId: number,
    reportType: string,
    reportId: number
  ): Promise<string>;

  updateReportData(
    data: string,
    assignmentId: number,
    reportType: string
  ): Promise<string>;

  storeReport(assignmentId: number, reportType: string): Promise<number>;
  generateSimpleReport(
    assignmentId: number,
    reportType: string
  ): Promise<string>;
}

export class DefaultReportRepository implements ReportRepository {
  public constructor(
    private webProvider: ReportProvider = new ReportGraphQLProvider()
  ) {}

  public getReportByType(
    userId: number,
    assignmentId: number,
    reportType: string
  ): Promise<ReportEntity> {
    return this.webProvider.loadReportByType(userId, assignmentId, reportType);
  }

  public getReportSettings(
    userId: number,
    assignmentId: string,
    reportType: string
  ): Promise<ReportSettings> {
    return this.webProvider.loadReportSettings(
      userId,
      assignmentId,
      reportType
    );
  }

  public getReportData(
    userId: number,
    assignmentId: string,
    reportType: string
  ): Promise<ReportData> {
    return this.webProvider.loadReportData(userId, assignmentId, reportType);
  }

  public updateReportSettings(
    settings: string,
    assignmentId: number,
    reportType: string,
    reportId: number
  ): Promise<string> {
    return this.webProvider.updateReportSettings(
      settings,
      assignmentId,
      reportType,
      reportId
    );
  }

  public updateReportData(
    data: string,
    assignmentId: number,
    reportType: string
  ): Promise<string> {
    return this.webProvider.updateReportData(data, assignmentId, reportType);
  }

  public storeReport(
    assignmentId: number,
    reportType: string
  ): Promise<number> {
    return this.webProvider.storeReport(assignmentId, reportType);
  }

  public generateSimpleReport(
    assignmentId: number,
    reportType: string
  ): Promise<string> {
    return this.webProvider.generateSimpleReport(assignmentId, reportType);
  }
}
