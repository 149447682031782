
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { RecurringDate } from "@/shared/datastructures/RecurringDate";
import {
  buildDurationInvervalSelectionItems,
  Duration,
  DurationInterval
} from "@/shared/datastructures/Duration";

import { FormField } from "@/shared/form/FormData";
import { DateUtils } from "@/shared/utils/DateUtils";

@Component<IntervalField>({
  components: {}
})
export default class IntervalField extends Vue {
  @Prop({
    type: Object,
    default() {
      return { label: "Label", value: new RecurringDate(), error: "" };
    }
  })
  protected readonly value!: FormField;
  @Prop({
    type: Object,
    default: undefined
  })
  protected readonly val?: RecurringDate;
  @Prop({
    type: String,
    default: undefined
  })
  protected readonly label?: string;
  @Prop({
    type: String,
    default: undefined
  })
  protected readonly error?: string;
  @Prop({
    type: Boolean,
    default: false
  })
  protected readonly disabled!: boolean;
  @Prop({
    type: Boolean,
    default: true
  })
  protected readonly outlined!: boolean;
  @Prop({
    type: Boolean,
    default: false
  })
  protected readonly loading!: boolean;
  @Prop({
    type: Boolean,
    default: false
  })
  protected readonly hideDetails!: boolean;
  @Prop({
    type: Boolean,
    default: false
  })
  protected readonly dense!: boolean;

  protected get effectiveValue() {
    return this.val ?? (this.value.value as RecurringDate);
  }

  protected get startDate() {
    const date = DateUtils.toDate(this.effectiveValue.startDate ?? new Date());
    return DateUtils.isoDate(isNaN(date.getTime()) ? new Date() : date);
  }

  protected get amount() {
    const amount = this.effectiveValue.recurringDuration
      ? this.effectiveValue.recurringDuration[this.interval]
      : 0;
    return amount;
  }

  protected get interval(): DurationInterval {
    if (this.effectiveValue.recurringDuration) {
      for (const interval of Object.keys(
        this.effectiveValue.recurringDuration
      )) {
        return interval as DurationInterval;
      }
    }

    return "months";
  }

  protected get intervals() {
    return buildDurationInvervalSelectionItems([
      "days",
      "weeks",
      "months",
      "years"
    ]);
  }

  protected get nextDateInfoText() {
    const nextDate = this.nextDate;
    const formattedNextDate = DateUtils.format(nextDate);

    if (nextDate.getTime() < DateUtils.beginningOfDay().getTime()) {
      return "Der Termin ist bereits am " + formattedNextDate + " vergangen";
    } else if (this.amount === 0) {
      return "Einmaliger Termin am " + formattedNextDate;
    } else {
      return "Nächster Termin am " + formattedNextDate;
    }
  }

  protected get nextDate() {
    const startDate = DateUtils.toDate(
      this.effectiveValue.startDate ?? new Date()
    );

    if (this.amount === 0) {
      return startDate;
    } else {
      const today = DateUtils.beginningOfDay();
      let nextDate = startDate;

      while (today.getTime() > nextDate.getTime()) {
        nextDate = DateUtils.add(
          this.effectiveValue.recurringDuration ?? {},
          nextDate
        );
      }

      return nextDate;
    }
  }

  protected get isMobile() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  protected dateChanged(newDate: string) {
    const currentInterval = this.buildInterval();
    currentInterval.startDate = DateUtils.toDate(newDate);
    this.emitInputAndChange(currentInterval);
  }

  protected amountChanged(newAmountString: string) {
    let amount = parseInt(newAmountString, 10);

    if (isNaN(amount) || amount < 0) {
      amount = 0;
    }

    const currentInterval = this.buildInterval();
    currentInterval.recurringDuration![this.interval] = amount;
    this.emitInputAndChange(currentInterval);
  }

  protected intervalChanged(newInterval: DurationInterval) {
    const currentInterval = this.buildInterval();
    currentInterval.recurringDuration = {};
    currentInterval.recurringDuration![newInterval] = this.amount;
    this.emitInputAndChange(currentInterval);
  }

  protected buildInterval() {
    const duration: Duration = {};

    duration[this.interval] = this.amount;

    return new RecurringDate(
      DateUtils.toDate(this.startDate ?? new Date()),
      duration
    );
  }

  protected emitInputAndChange(interval: RecurringDate) {
    this.$emit("input", interval);
    this.$emit("change", interval);
  }
}
