import { render, staticRenderFns } from "./DocumentDetailView.vue?vue&type=template&id=d6b1d15e&scoped=true&"
import script from "./DocumentDetailView.vue?vue&type=script&lang=ts&"
export * from "./DocumentDetailView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6b1d15e",
  null
  
)

export default component.exports