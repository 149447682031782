import { IPropertyPlanViewer } from "../interactors/PropertyPlanViewer";
import { NumericalId } from "@/datastructures/NumericalId";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import {
  PlanPayload,
  PlanCatalogRow
} from "@/office/interactors/PropertyPlanManager";
import { Point } from "@/datastructures/Point";

export class TicketLocationController {
  private ticketId = "";

  public constructor(
    private presenter: ITicketLocationPresenter,
    private planViewer: IPropertyPlanViewer
  ) {}

  public async init(
    propertyId: string,
    ticketId: string,
    planId: string,
    location: Point
  ) {
    this.ticketId = ticketId;
    try {
      await this.loadPlans(propertyId);
    } finally {
      await this.planChanged(planId);
    }
    this.ticketLocationChanged(location);
  }

  // Actions
  public ticketLocationChanged(location: Point) {
    this.presenter.ticketLocation = location;
  }

  // Requests
  public planChanged(planId: string) {
    this.presenter.selectedPlanId = planId;
    const id = NumericalId.fromString(planId);
    const ticketId = NumericalId.fromString(this.ticketId);

    if (id.valid) {
      const request = ticketId.valid
        ? this.planViewer.loadTicketPlan(ticketId, id)
        : this.planViewer.loadPlan(id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadPlanResponse = response),
        "Plan konnte nicht geladen werden"
      );

      return request;
    }
  }

  private loadPlans(propertyId: string) {
    const id = NumericalId.fromString(propertyId);

    if (id.valid) {
      const request = this.planViewer.loadPlanCatalog(id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadPlansResponse = response),
        "Pläne konnten nicht geladen werden"
      );

      return request;
    }
  }
}

export interface ITicketLocationPresenter {
  // Data
  selectedPlanId: string;
  ticketLocation: Point;

  // Responses
  loadPlansResponse: FormResponse<PlanCatalogRow[]>;
  loadPlanResponse: FormResponse<PlanPayload>;
}
