
import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import { StepperStep } from "@/shared/form/FormTypes";

@Component<Stepper>({
  components: {
    Container,
    Column,
    Row
  },
  props: {
    step: {
      type: Number,
      default: 1
    },
    steps: {
      type: Array,
      required: true
    },
    hideHeader: {
      type: Boolean,
      default: false
    }
  }
})
export default class Stepper extends Vue {
  private step!: number;
  private steps!: StepperStep[];

  private get stepCount() {
    return this.steps.length;
  }

  private emitChange(step: number) {
    this.$emit("change", step);
  }
}
