
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { Ticket } from "@/inspection/shared/models/Ticket";

@Component<SyncTicket>({
  components: {}
})
export default class SyncTicket extends Vue {
  @Prop({
    default() {
      return new Ticket();
    }
  })
  protected readonly ticket!: Ticket;
  @Prop({ type: Boolean, default: false })
  protected readonly syncing!: boolean;

  protected get titleColor() {
    return this.ticket.exists
      ? "accent"
      : this.ticket.createResponse.error
      ? "error"
      : "black";
  }

  protected get imageTextColor() {
    return this.ticket.exists
      ? "primary"
      : this.imageHasError
      ? "error"
      : "black";
  }

  protected get progress() {
    const totalParts = this.totalImageCount + 1;
    const ticketCompleted = this.ticket.exists ? 1 : 0;
    const completedParts = ticketCompleted + this.doneImageCount;

    return (completedParts / totalParts) * 100;
  }

  protected get totalImageCount() {
    return this.ticket.images.length;
  }

  protected get doneImageCount() {
    return this.ticket.images.filter(i => i.exists).length;
  }

  protected get imageHasError() {
    return this.ticket.images.some(i => i.uploadResponse.failure);
  }

  protected async deleteTicket() {
    this.$emit("delete", this.ticket);
  }
}
