import { PropertyPartEntity } from "../entities/PropertyPartEntity";

export class PropertyPart {
  public id?: number;
  public name?: string;

  public toEntity(): PropertyPartEntity {
    return {
      id: this.id,
      name: this.name
    };
  }

  public from(entity?: PropertyPartEntity) {
    this.id = entity?.id;
    this.name = entity?.name;
  }

  public static from(entity?: PropertyPartEntity) {
    const part = new PropertyPart();
    part.from(entity);
    return part;
  }
}
