
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import CreateEmployeeForm from "../employeeDetailComponents/CreateEmployeeForm.vue";

import { EmployeeList } from "@/property/shared/models/EmployeeList";
import { TableHeader } from "@/shared/datastructures/TableHeader";
import { Employee } from "@/property/shared/models/Employee";
import { Authorizator } from "@/common/interactors/Authorizator";

@Component<EmployeeTable>({
  components: { CreateEmployeeForm }
})
export default class EmployeeTable extends Vue {
  @Prop({
    default() {
      return new EmployeeList();
    }
  })
  protected readonly employees!: EmployeeList;
  @Prop({ type: Number }) protected readonly clientId!: number;

  protected createEmployeeDialogVisible = false;

  protected get effectiveClientId() {
    return this.clientId ?? parseInt(this.$route.params?.clientId ?? "0", 10);
  }

  protected get tableHeaders(): TableHeader[] {
    return [
      {
        text: "Mitarbeiter",
        value: "name"
      },
      {
        text: "Email",
        value: "email",
        visibleOnSize: ["xl", "lg", "md"]
      },
      {
        text: "Rolle",
        value: "role",
        align: "center",
        visibleOnSize: ["xl", "lg"]
      },
      { text: "", value: "actions", align: "right" }
    ];
  }

  protected get isSuperUser() {
    return Authorizator.canCreateClients();
  }

  protected get canCreateEmployees() {
    return Authorizator.canCreateEmployees();
  }

  protected get canDeleteEmployees() {
    return Authorizator.canDeleteEmployees();
  }

  protected getDeleteButtonIcon(employee: Employee) {
    return employee.deleted ? "delete" : "not_interested";
  }

  protected getDeleteButtonText(employee: Employee) {
    return employee.deleted
      ? "Mitarbeiter löschen"
      : "Mitarbeiter deaktivieren";
  }

  protected getDeleteButtonConfirmTitle(employee: Employee) {
    return employee.deleted ? "Wirklich löschen?" : "Wirklich deaktivieren?";
  }

  protected getDeleteButtonConfirmText(employee: Employee) {
    return employee.deleted
      ? "Sind Sie sicher, dass sie den Mitarbeiter endgültig löschen möchten? Falls Sie dies tun, gehen die Verbindungen zu den Tickets, die der Mitarbeiter erstellt verloren!"
      : "Sind Sie sicher, dass sie den Mitarbeiter deaktivieren möchten? Er ist dann nicht mehr in der Lage, sich anzumelden. Nur ein Superadmin kann den Mitarbeiter endgültig löschen.";
  }

  protected impersonate(employee: Employee) {
    employee.impersonate();
    this.$router.push({ name: "home", params: { action: "impersonate" } });
  }

  protected mounted() {
    this.load();
  }

  protected load() {
    this.employees.load(this.effectiveClientId);
  }
}
