import { Access } from "@/property/shared/dtos/Access";

interface AccessModelProps {
  recipientId?: number;
  recipientType?: string;
  roleId?: number;
  resourceId?: number;
  resourceType?: string;
}

export class AccessModel {
  public static from(access: Access) {
    return new AccessModel({
      recipientId: access.recipientId,
      recipientType: access.recipientType,
      roleId: access.roleId,
      resourceId: access.resourceId,
      resourceType: access.resourceType
    });
  }

  public recipientId?: number;
  public recipientType?: string;
  public roleId?: number;
  public resourceId?: number;
  public resourceType?: string;

  public constructor(props: AccessModelProps) {
    this.recipientId = props.recipientId;
    this.recipientType = props.recipientType;
    this.roleId = props.roleId;
    this.resourceId = props.resourceId;
    this.resourceType = props.resourceType;
  }

  public mergeCopy(other?: AccessModel) {
    return new AccessModel({
      recipientId: other?.recipientId ?? this.recipientId,
      recipientType: other?.recipientType ?? this.recipientType,
      roleId: other?.roleId ?? this.roleId,
      resourceId: other?.resourceId ?? this.resourceId,
      resourceType: other?.resourceType ?? this.resourceType
    });
  }
}
