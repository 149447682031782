
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import GeneralPlantDataForm from "./GeneralPlantDataForm.vue";
import PlantLocation from "./PlantLocation.vue";
import PlantTypeList from "./PlantTypeList.vue";
import PlantFieldCategoryList from "./PlantFieldCategoryList.vue";
import PlantChecklistEntryTable from "./PlantChecklistEntryTable.vue";

import { Plant } from "../../shared/models/Plant";
import { CreatePlantForm as Form } from "../forms/CreatePlantForm";
import { GeneralPlantDataForm as GeneralForm } from "../forms/GeneralPlantDataForm";

@Component<CreatePlantForm>({
  components: {
    GeneralPlantDataForm,
    PlantLocation,
    PlantTypeList,
    PlantFieldCategoryList,
    PlantChecklistEntryTable
  }
})
export default class CreatePlantForm extends Vue {
  @Prop({ type: Number, required: true })
  protected readonly propertyId!: number;
  @Prop({ type: Boolean, default: false })
  protected readonly siblingPart!: boolean;
  @Prop({ type: [Number, String] }) protected readonly partId?: number | string;
  @Prop({ type: [Number, String] }) protected readonly parentPlantId?:
    | number
    | string;

  protected plant = new Plant();
  protected form = new Form();

  protected get generalForm() {
    return this.$refs.generalForm as GeneralPlantDataForm;
  }

  protected get fieldCategories() {
    return this.$refs.fieldCategories as PlantFieldCategoryList;
  }

  protected get checklistEntries() {
    return this.$refs.checklistEntries as PlantChecklistEntryTable;
  }

  protected get plantLocation() {
    return this.$refs.plantLocation as PlantLocation;
  }

  protected get plantTypes() {
    return this.$refs.plantTypes as PlantTypeList;
  }

  protected async createPlant() {
    if (await this.plant.create()) {
      this.$emit("create", this.plant);
    }
  }

  protected async created() {
    this.form.addSubForm(new GeneralForm(), "general", true);
    this.form.addSubForm(undefined, "fieldCategories");
    this.form.addSubForm(undefined, "checklistEntries");

    await this.init();
  }

  public async init() {
    await this.form.init();

    this.plant.init({
      propertyId: parseInt(this.propertyId.toString(), 10),
      partId: this.siblingPart
        ? 0
        : parseInt(this.partId?.toString() ?? "0", 10),
      siblingPartId: this.siblingPart
        ? parseInt(this.partId?.toString() ?? "0", 10)
        : 0,
      parentPlantId: parseInt(this.parentPlantId?.toString() ?? "0", 10)
    });
  }

  public reset() {
    this.plant.clear();
    this.form.reset();
    this.fieldCategories.reset();
    this.checklistEntries.reset();
    this.plantLocation.reset();
    this.plantTypes.reset();
  }
}
