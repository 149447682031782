import { Image } from "./Image";
import { Line } from "@/datastructures/Line";

export class Plan {
  public name: string = "";
  public offline: boolean = false;
  public file: Image = new Image(0, "");
  public pixelDistance: Line = { a: { x: 0, y: 0 }, b: { x: 0, y: 0 } };
  public realDistance: Line = { a: { x: 0, y: 0 }, b: { x: 0, y: 0 } };

  public constructor(public id: number) {}
}
