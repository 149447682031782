import imageCompression from "browser-image-compression";

export class ImageProcessor {
  private static readonly imageSizeLimit = 4 * 1024 * 1024;

  public static async toBase64(image: File, thumbnail = false) {
    if (!/image/i.test(image.type)) {
      throw new Error("Gegebene Datei ist kein Bild!");
    }

    if (image.size === 0) {
      throw new Error("Das Bild hat keinen Inhalt!");
    }

    const compressedImage = await imageCompression(image, {
      maxSizeMB: thumbnail ? 0.3 : 2,
      maxWidthOrHeight: thumbnail ? 300 : 1920,
      useWebWorker: true
    });

    return imageCompression.getDataUrlFromFile(compressedImage);
  }
}
