import { RequestModel } from "@/shared/model/RequestModel";
import { TicketableEntity } from "../entities/TicketableEntity";

export enum TicketableType {
  Checklist = "CHECKLIST",
  ChecklistEntry = "CHECKLIST_ENTRY"
}

export abstract class Ticketable<T> extends RequestModel<T> {
  public id?: number;

  public get exists() {
    return !!this.id;
  }

  public abstract get label(): string;
  public abstract get ticketableType(): TicketableType;

  public toTicketableEntity(): TicketableEntity {
    return {
      id: this.id,
      type: this.ticketableType
    };
  }
}
