import { RequestModel } from "@/shared/model/RequestModel";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { ClientEntity } from "../entities/ClientEntity";
import { PropertyReportContainer } from "../repositories/PropertyRepoContainer";
import { Client } from "./Client";

interface ClientListProps {
  clients?: Client[];
}

export class ClientList extends RequestModel<ClientEntity[]> {
  public clients?: Client[];
  public search = "";

  public constructor(props?: ClientListProps) {
    super();
    this.clients = props?.clients;
  }

  public get empty() {
    return (this.clients?.length ?? 0) === 0;
  }

  public get filteredClients() {
    return (
      this.clients?.filter(
        c =>
          c.name?.toLowerCase()?.includes(this.search?.toLowerCase() ?? "") ??
          false
      ) ?? []
    );
  }

  public load() {
    RequestHandler.handleModel(
      () => PropertyReportContainer.clientRepo.getClients(),
      this
    );
  }

  public from(clients?: ClientEntity[]) {
    this.clients = clients?.map(c => Client.from(c));
  }
}
