import { SafeRetry } from "@/shared/datastructures/SafeRetry";
import {
  TicketData,
  TicketDescription,
  TicketLocationData
} from "@/inspection/shared/dtos/TicketDtos";
import { Property } from "@/property/shared/models/Property";
import { RequestModel } from "@/shared/model/RequestModel";
import { ImageCollection } from "@/shared/project/images/ImageCollection";
import { RequestHandler } from "@/shared/utils/RequestHandler";
import { TicketEntity, TicketPriority } from "../entities/TicketEntity";
import { InspectionRepoContainer } from "../repositories/InspectionRepoContainer";
import { PlanLocation } from "./PlanLocation";
import { Ticketable, TicketableType } from "./Ticketable";
import { Fileable } from "@/shared/project/file/Fileable";
import { ServerResponse } from "@/shared/datastructures/Response";
import { StringUtils } from "@/shared/utils/StringUtils";
import { Plant } from "@/property/shared/models/Plant";
import { Checklist } from "./Checklist";
import { ChecklistEntry } from "./ChecklistEntry";
import { Dictionary } from "@/datastructures/Dictionary";

export class Ticket extends RequestModel<TicketEntity> {
  public id?: number = 0;
  public clientId?: string = "";
  // Data
  public syncId?: string = "";
  public title?: string = "";
  public description?: string = "";
  public action?: string = "";
  public deadline?: string = "";
  public maturity?: Date = new Date();
  public priority?: TicketPriority = "priority3";
  public type?: string = "";
  public details?: string = "";
  public imageCollection?: ImageCollection = new ImageCollection();
  private selectedRaw?: boolean = false;
  // References
  public ticketable?: Ticketable<unknown>;
  public location?: PlanLocation = new PlanLocation();
  public property?: Property = new Property();
  public plant?: Plant = new Plant();
  public checklistEntryTemplateId?: string = "";
  // Other
  public plantFieldsToSync: Dictionary<unknown> = {};

  private loadRetry?: SafeRetry;
  public createResponse = new ServerResponse<number>();
  public deleteResponse = new ServerResponse<number>();

  public constructor() {
    super();
    this.syncId = StringUtils.uuid();
  }

  public get exists() {
    return !!this.id;
  }

  public get completed() {
    return this.exists && this.images.every(i => i.exists);
  }

  public get selected() {
    return !!this.selectedRaw;
  }

  public get hasImages() {
    return this.images.length > 0;
  }

  public get images() {
    return this.imageCollection?.images ?? [];
  }

  public get propertyId() {
    return this.property?.id ?? 0;
  }

  public get hasPropertyReference() {
    return this.property?.exists ?? false;
  }

  public get hasTicketable() {
    return this.ticketable?.exists ?? false;
  }

  public get hasPlant() {
    return this.plant?.exists ?? false;
  }

  public isSameAs(otherTicket: Ticket) {
    if (this.id) {
      return this.id === otherTicket.id;
    }
    return this.syncId === otherTicket.syncId;
  }

  public clearSyncId() {
    this.syncId = StringUtils.uuid();
  }

  public updateLocation(data: TicketLocationData) {
    this.setLocation(data);
  }

  public updateDescription(description: TicketDescription) {
    this.setDescription(description);
  }

  public updateData(data: TicketData) {
    this.setData(data);
  }

  public updateDetails(details: string) {
    this.details = details;
  }

  public hasPlantFieldToSync(id?: string) {
    if (!id) {
      return false;
    }

    return this.plantFieldsToSync[id] !== undefined;
  }

  public togglePlantFieldToSync(id?: string, value?: unknown) {
    if (id) {
      if (this.plantFieldsToSync[id]) {
        this.addPlantFieldToSync(id, value);
      } else {
        this.removePlantFieldToSync(id);
      }
    }
  }

  public addPlantFieldToSync(id?: string, value?: unknown) {
    if (id) {
      this.plantFieldsToSync[id] = value;
    }
  }

  public removePlantFieldToSync(id?: string) {
    if (id) {
      delete this.plantFieldsToSync[id];
    }
  }

  public clearPropertyReference() {
    this.property = new Property();
  }

  public clearLocation() {
    this.setLocation(undefined);
  }

  public clearImages(keepImageContainer = false) {
    if (keepImageContainer) {
      this.imageCollection?.clearImages();
    } else {
      this.imageCollection?.clear();
    }
  }

  public select() {
    this.selectedRaw = true;
  }

  public unselect() {
    this.selectedRaw = false;
  }

  public async create(cached = false) {
    if (
      (await this.createTicket(cached)) &&
      (await this.uploadImages(cached))
    ) {
      if (cached) {
        this.rememberDataForNextTicketCreation();
      }

      return true;
    }

    return false;
  }

  public tryLoadPeriodically() {
    if (!this.loadRetry) {
      this.loadRetry = new SafeRetry(() => this.load());
    }
    this.loadRetry.start();
  }

  public load(cached = false) {
    const repo = cached
      ? InspectionRepoContainer.cachedTicketRepo
      : InspectionRepoContainer.ticketRepo;

    return RequestHandler.handleModel(
      () => repo.getTicket(this.id ?? 0, this.syncId ?? ""),
      this
    );
  }

  public async delete(cached = false) {
    const repo = cached
      ? InspectionRepoContainer.cachedTicketRepo
      : InspectionRepoContainer.ticketRepo;

    return await RequestHandler.handle(
      repo.delete(this.toEntity()),
      this.deleteResponse
    );
  }

  public copy() {
    return Ticket.from(this.toEntity());
  }

  public toEntity(deep = true): TicketEntity {
    return {
      id: this.id,
      syncId: this.syncId,
      title: this.title,
      description: this.description,
      action: this.action,
      deadline: this.deadline,
      maturity: this.maturity,
      priority: this.priority,
      type: this.type,
      details: this.details,
      ticketable: deep ? this.ticketable?.toTicketableEntity() : undefined,
      property: deep ? this.property?.toEntity() : undefined,
      location: deep ? this.location?.toEntity() : undefined,
      images: deep ? this.images.map(i => i.toEntity()) : undefined,
      plant: deep ? this.plant?.toEntity() : undefined,
      checklistEntryTemplateId: this.checklistEntryTemplateId,
      plantFieldsToSync: this.plantFieldsToSync
    };
  }

  public from(entity?: TicketEntity, deep = true) {
    this.id = entity?.id;
    this.syncId = entity?.syncId ?? StringUtils.uuid();
    this.title = entity?.title;
    this.description = entity?.description;
    this.action = entity?.action;
    this.deadline = entity?.deadline;
    this.maturity = entity?.maturity;
    this.priority = entity?.priority;
    this.type = entity?.type;
    this.details = entity?.details;
    this.selectedRaw = entity?.selected;
    this.property = deep ? Property.from(entity?.property) : undefined;
    this.plant = deep ? Plant.from(entity?.plant) : undefined;
    this.checklistEntryTemplateId = entity?.checklistEntryTemplateId;
    this.ticketable =
      entity?.ticketable?.type === TicketableType.Checklist
        ? Checklist.from({ id: entity.ticketable.id })
        : ChecklistEntry.from({ id: entity?.ticketable?.id });
    this.location = deep
      ? PlanLocation.from(entity?.location)
      : new PlanLocation();
    this.imageCollection = deep
      ? ImageCollection.collectFrom(entity?.images)
      : new ImageCollection();
    this.plantFieldsToSync = entity?.plantFieldsToSync ?? {};
  }

  public static from(entity?: TicketEntity, deep = true) {
    const ticket = new Ticket();
    ticket.from(entity, deep);
    return ticket;
  }

  private setLocation(data?: TicketLocationData) {
    this.location = new PlanLocation({
      plan: data?.plan,
      x: data?.location?.x,
      y: data?.location?.y
    });
  }

  private setDescription(description?: TicketDescription) {
    this.title = description?.title;
    this.description = description?.description;
    this.action = description?.action;
  }

  private setData(data?: TicketData) {
    this.deadline = data?.deadline;
    this.maturity = data?.maturity;
    this.priority = data?.priority;
    this.type = data?.type;
  }

  private async createTicket(cached = false) {
    if (cached && this.exists) {
      return true;
    }

    const repo = cached
      ? InspectionRepoContainer.cachedTicketRepo
      : InspectionRepoContainer.ticketRepo;

    return await RequestHandler.handle(
      repo.create(this.toEntity()),
      this.createResponse,
      {
        onSuccess: id => (this.id = id)
      }
    );
  }

  private async uploadImages(cached = false) {
    let allSuccess = true;

    for (const image of this.images) {
      try {
        image.imageable = Fileable.from({
          id: this.id,
          type: "Ticket"
        });
        await image.upload(cached);
      } catch (_) {
        allSuccess = false;
      }
    }

    return allSuccess;
  }

  private rememberDataForNextTicketCreation() {
    this.location?.saveLastLocation();
    Property.saveLast(this.property);
  }
}
