
import Vue from "vue";
import Component from "vue-class-component";

@Component<SubmitTextfield>({
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: "search"
    },
    clearable: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ""
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: undefined
    },
    dense: {
      type: Boolean,
      default: false
    }
  }
})
export default class SubmitTextfield extends Vue {
  protected innerValue = "";
  protected tooltip = false;

  protected emitChange(text: string) {
    if (!text) {
      text = "";
    }
    this.$emit("change", text);
    this.innerValue = text;
  }

  protected clear() {
    this.$emit("submit", "");
  }

  protected submit() {
    this.$emit("submit", this.innerValue || this.$props.value);
  }
}
