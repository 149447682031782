import { InspectionSelectionItemLoader } from "@/inspection/shared/dtos/InspectionDomainSelectionItemLoader";
import { TicketData } from "@/inspection/shared/dtos/TicketDtos";
import { Form } from "@/shared/form/Form";
import { FormFieldType } from "@/shared/form/FormDefinition";

export class CreateTicketDataForm extends Form<TicketData> {
  protected definition = {
    deadline: {
      label: "Frist",
      required: true,
      type: FormFieldType.Selection,
      itemDefinition: {
        items: () => InspectionSelectionItemLoader.loadDeadlines()
      }
    },
    maturity: {
      label: "Fälligkeit",
      required: true,
      type: FormFieldType.Date
    },
    priority: {
      label: "Priorität",
      required: true,
      type: FormFieldType.Selection,
      itemDefinition: {
        items: () => InspectionSelectionItemLoader.loadPriorities()
      }
    },
    type: {
      label: "Ticketart",
      required: false,
      type: FormFieldType.Selection,
      itemDefinition: {
        items: () => InspectionSelectionItemLoader.loadTicketTypes()
      }
    }
  };
}
