import {
  OrderByClause,
  SortOrder
} from "@/gateways/graphql/connection/GraphQLConnection";
import { TableSort } from "./ViewModelFormTypes";

export class TypeConverter {
  public static toOrderByClause(
    tableSorts?: TableSort[]
  ): OrderByClause | undefined {
    if (!tableSorts || tableSorts.length === 0) {
      return undefined;
    }

    return {
      field: tableSorts[0].by,
      order: tableSorts[0].desc ? SortOrder.DESC : SortOrder.ASC
    };
  }
}
