import { GraphQLConnection } from "@/gateways/graphql/connection/GraphQLConnection";

export class GraphQLDWSBaseService implements DWSBaseService {
  public constructor(private connection: GraphQLConnection) {}

  public async syncDWSBaseReports() {
    const result = await this.connection.mutation("syncDWSBase", {}, [
      "new_documents",
      "updated_documents",
      "failed_documents"
    ]);

    return {
      newDocuments: result.data.new_documents,
      updatedDocuments: result.data.updated_documents,
      failedDocuments: result.data.failed_documents
    };
  }
}

export interface DWSBaseService {
  syncDWSBaseReports(): Promise<DWSBaseSync>;
}

export interface DWSBaseSync {
  newDocuments: number;
  updatedDocuments: number;
  failedDocuments: string[];
}
