import { Address } from "./Address";
import { Image } from "./Image";
import { Relationship } from "./Relationship";

export class Property {
  public name: string = "";
  public description: string = "";
  public legalForm: string = "";
  public cadastralCommunity: string = "";
  public assetNumber: string = "";
  public buildYear: number = 0;
  public parcelNumber: string = "";
  public singleCadastre: boolean = false;
  public effectiveArea: number = 0;
  public dwsBaseNumber: string = "";
  public unitCount: number = 0;
  public address: Address = new Address("", "", "");
  public image: Image = new Image(0, "");
  public relationships: Relationship[] = [];

  public constructor(public id: number) {}
}
