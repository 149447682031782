
import Vue from "vue";
import Component from "vue-class-component";
import { Framework } from "vuetify";

@Component<SearchSelect>({
  props: {
    value: {
      type: Object,
      default() {
        return {
          label: "Label",
          value: "",
          error: "",
          search: "",
          loading: false,
          items: []
        };
      }
    },
    label: {
      type: String,
      default: undefined
    },
    val: {
      default: undefined
    },
    items: {
      type: Array,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    blurOnSelect: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: false
    },
    enableOnLoad: {
      type: Boolean,
      default: false
    },
    noDataText: {
      type: String,
      default() {
        return (this.$vuetify as Framework & { noDataText: string }).noDataText;
      }
    },
    clearable: {
      type: Boolean,
      default: false
    },
    smallChips: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    "value.selected"(selected: string) {
      if (!selected) {
        this.search = "";
      }
    }
  }
})
export default class SearchSelect extends Vue {
  protected blurOnSelect!: boolean;
  protected multiple!: boolean;
  protected search = "";
  protected lastSearch = "";

  protected searchChanged(search: string) {
    if (!!search && search.length > 3 && search !== this.lastSearch) {
      this.lastSearch = search;
      this.$emit("search", search);
    }
  }

  protected changed(value: any) {
    if (!this.multiple) {
      this.$emit("change", value?.value, value?.item);
    } else {
      this.$emit("change", value);
    }
    if (this.blurOnSelect) {
      (this.$refs.input as any).blur();
    }
  }

  protected openItemMenu() {
    (this.$refs.input as any).isMenuActive = true;
  }
}
