import { GraphQLConnection } from "@/gateways/graphql/connection/GraphQLConnection";
import { Page } from "@/datastructures/Page";
import SubjectTypes from "../utils/SubjectTypes";
import { PaginatedList } from "@/datastructures/PaginatedList";

export class GraphQLDocumentService implements DocumentService {
  public constructor(private connection: GraphQLConnection) {}

  public async getAllGeneralDocumentMeta() {
    const result = await this.connection.query(
      "documentMeta",
      { general: true },
      ["id", "key", "label", "fields"]
    );
    return result.data.map((metum: any) => ({
      id: metum.id,
      key: metum.key,
      label: metum.label,
      fields: metum.fields
    }));
  }

  public async getAllPropertyDocumentMeta() {
    const result = await this.connection.query(
      "documentMeta",
      { associated_with: SubjectTypes.property(false) },
      ["id", "key", "label", "fields"]
    );
    return result.data.map((metum: any) => ({
      id: metum.id,
      key: metum.key,
      label: metum.label,
      fields: metum.fields
    }));
  }

  public async getDocumentMetum(id: number) {
    const result = await this.connection.query("documentMetum", { id }, [
      "id",
      "key",
      "label",
      "fields"
    ]);

    return {
      id: result.data.id,
      key: result.data.key,
      label: result.data.label,
      fields: result.data.fields
    };
  }

  public async getDocuments(
    metumId: number,
    subjectType: string,
    subjectId: number,
    page: Page,
    search: string
  ) {
    const result = await this.connection.queryPaginated(
      "documents",
      page.itemsPerPage,
      page.page,
      {
        subject_id: subjectId,
        subject_type: SubjectTypes[subjectType],
        document_metum_id: metumId
      },
      ["id", "name", { name: "file", fields: ["id", "path"] }],
      search
    );
    return {
      totalCount: result.count,
      items: result.data.map((document: any) => ({
        id: document.id,
        name: document.name,
        link: document.file.path
      }))
    };
  }

  public async getDocument(id: number) {
    const result = await this.connection.query("document", { id }, [
      "id",
      "name",
      "attributes",
      "created_at",
      { name: "metum", fields: ["id"] },
      { name: "file", fields: ["id", "path"] }
    ]);

    return {
      id: result.data.id,
      metumId: result.data.metum.id,
      fileId: result.data.file.id,
      filePath: result.data.file.path,
      name: result.data.name,
      attributes: result.data.attributes,
      createdAt: new Date(result.data.created_at)
    };
  }

  public async uploadDocument(
    document: File,
    metumId: number,
    subjectType: string,
    subjectId: number
  ) {
    const upload = await this.connection.uploadFile(
      document,
      `${subjectType}/${subjectId}/documents`,
      document.name,
      ["id"]
    );
    const result = await this.connection.mutation(
      "createDocument",
      {
        input: {
          name: document.name,
          file_id: upload.data.id,
          document_metum_id: metumId,
          subject_type: SubjectTypes[subjectType],
          subject_id: subjectId
        }
      },
      ["id"]
    );

    return result.data.id;
  }

  public async saveDocument(document: Document) {
    const result = await this.connection.mutation(
      "updateDocument",
      {
        input: {
          id: document.id,
          name: document.name,
          attributes: document.attributes
        }
      },
      ["id"]
    );

    return result.data.id;
  }

  public async deleteDocument(id: number) {
    const result = await this.connection.mutation("deleteDocument", { id }, [
      "id"
    ]);
    return result.data.id;
  }
}

export interface DocumentService {
  getAllGeneralDocumentMeta(): Promise<DocumentMetaDescription>;
  getAllPropertyDocumentMeta(): Promise<DocumentMetaDescription>;
  getDocumentMetum(metumId: number): Promise<DocumentMetumDescription>;

  getDocuments(
    metumId: number,
    subjectType: string,
    subjectId: number,
    page: Page,
    search: string
  ): Promise<PaginatedList<DocumentRow>>;
  getDocument(id: number): Promise<Document>;

  uploadDocument(
    document: File,
    metumId: number,
    subjectType: string,
    subjectId: number
  ): Promise<number>;

  saveDocument(document: Document): Promise<number>;
  deleteDocument(id: number): Promise<number>;
}

export type DocumentMetaDescription = DocumentMetumDescription[];

export interface DocumentMetumDescription {
  id: number;
  key: string;
  label: string;
  fields: string;
}

export type DocumentRows = DocumentRow[];

export interface DocumentRow {
  id: number;
  name: string;
  link: string;
}

export interface Document {
  id: number;
  name: string;
  metumId: number;
  fileId: number;
  filePath: string;
  attributes: string;
  createdAt: Date;
}
