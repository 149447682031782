import { DynamicForm } from "@/shared/form/DynamicForm";
import { FormDefinition, FormFieldType } from "@/shared/form/FormDefinition";
import { TicketType } from "./TicketTypeSettings";

export class TicketTypeFormGenerator {
  public constructor(private ticketType: TicketType) {}

  public generateForm(): DynamicForm {
    const definition: FormDefinition<unknown> = {};

    for (const field of this.ticketType.fields) {
      definition[field.key || field.label] = {
        label: field.label,
        required: false,
        itemDefinition: field.options
          ? {
              items: () =>
                field.options?.map(o => ({
                  value: o.id,
                  text: o.label
                })) ?? []
            }
          : undefined,
        type:
          field.type === "string"
            ? FormFieldType.Text
            : field.type === "number"
            ? FormFieldType.Number
            : field.type === "bool"
            ? FormFieldType.Checkbox
            : field.type === "date"
            ? FormFieldType.Date
            : field.type === "select"
            ? FormFieldType.Selection
            : FormFieldType.Text
      };
    }

    return new DynamicForm(definition);
  }
}
