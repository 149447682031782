import { ServerResponse } from "./Response";

export abstract class UseCase<TInput, TOutput> {
  protected response = new ServerResponse<TOutput>();

  public get succeeded() {
    return this.response.success;
  }

  public get loads() {
    return this.response.loading;
  }

  public get loading() {
    return this.response.loading;
  }

  public get error() {
    return this.response.error;
  }

  public get failed() {
    return this.response.failure;
  }

  public setError(error: string) {
    this.response.error = error;
  }

  public abstract do(input: TInput): Promise<TOutput>;
}
