
import { SelectionItem } from "@/shared/form/FormTypes";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component<SearchSelectDropdown>({
  components: {}
})
export default class SearchSelectDropdown extends Vue {
  @Prop() protected readonly selectedText?: string;
  @Prop({ default: "Nicht zugewiesen" })
  protected readonly notSelectedText!: string;
  @Prop() protected readonly label?: string;
  @Prop({ type: Boolean, default: false })
  protected readonly loading!: boolean;
  @Prop() protected readonly error?: string;
  @Prop({ type: Boolean, default: false })
  protected readonly disabled!: boolean;
  @Prop({ type: Boolean, default: false })
  protected readonly clearable!: boolean;
  @Prop({ default: "Suche" }) protected readonly searchLabel!: string;
  @Prop({ type: Boolean, default: false })
  protected readonly searchLoading!: boolean;
  @Prop() protected readonly searchError?: string;
  @Prop({ type: Array, default: () => [] })
  protected readonly items?: SelectionItem[];
  @Prop({ default: "Wirklich entfernen?" })
  protected readonly confirmTitle!: string;
  @Prop({ default: "Woll Sie die Entität wirklich lösen?" })
  protected readonly confirmText!: string;

  protected search = "";
  protected expanded = false;
  protected timeoutHandle = -1;
  protected confirmDialogVisible = false;

  @Watch("expanded")
  protected onExpandedChanged() {
    if (this.expanded) {
      setTimeout(() => {
        this.searchField.focus();
      }, 100);
    } else {
      this.emitClearSearch();
    }
  }

  @Watch("search")
  protected onSearchChanged(search: string) {
    if (this.timeoutHandle >= 0) {
      clearTimeout(this.timeoutHandle);
    }

    if (search) {
      this.timeoutHandle = setTimeout(() => {
        this.emitSearch(search);
      }, 300);
    } else {
      this.emitClearSearch();
    }
  }

  protected get text() {
    return this.selectedText ?? this.notSelectedText;
  }

  protected get initialized() {
    return this.text !== this.notSelectedText;
  }

  protected get hasItems() {
    return (this.items?.length ?? 0) > 0;
  }

  protected get searchField() {
    return this.$refs.searchField as HTMLElement;
  }

  protected emitSearch(search?: string) {
    this.$emit("search", search);
  }

  protected emitClearSearch() {
    this.$emit("clear:search");
    this.search = "";
  }

  protected emitClear() {
    this.$emit("clear");
    this.confirmDialogVisible = false;
  }

  protected emitSelect(item?: unknown) {
    this.$emit("select", item);
    this.expanded = false;
  }
}
