
import Vue from "vue";
import Component from "vue-class-component";

@Component<Button>({
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: "primary"
    },
    block: {
      type: Boolean,
      default: false
    },
    depressed: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class Button extends Vue {
  private emitClick() {
    this.$emit("click");
  }
}
