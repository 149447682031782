
import Vue from "vue";
import Component from "vue-class-component";

import { PropertyDetailController } from "@/admin/controllers/PropertyDetailController";
import { PropertyDetailPresenter } from "@/admin/presenters/PropertyDetailPresenter";
import { PropertyDetailViewModel } from "@/admin/vms/PropertyDetailViewModel";

import Page from "@/components/layout/Page.vue";
import Dialog from "@/components/layout/Dialog.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Section from "@/components/layout/Section.vue";
import Grid from "@/components/layout/Grid.vue";
import Card from "@/components/layout/Card.vue";
import ToggleCard from "@/components/layout/ToggleCard.vue";
import TruncatedText from "@/components/layout/TruncatedText.vue";

import Select from "@/components/form/Select.vue";
import SearchSelect from "@/components/form/SearchSelect.vue";
import EditableCheckbox from "@/components/form/EditableCheckbox.vue";
import EditableSelect from "@/components/form/EditableSelect.vue";
import EditableTextfield from "@/components/form/EditableTextfield.vue";
import EditableTextarea from "@/components/form/EditableTextarea.vue";
import SingleImageUpload from "@/components/form/SingleImageUpload.vue";

import RequestButton from "@/components/basic/RequestButton.vue";
import ResponsiveIconButton from "@/components/basic/ResponsiveIconButton.vue";
import ConfirmDialog from "@/components/basic/ConfirmDialog.vue";
import IconButton from "@/components/basic/IconButton.vue";

import Datatable from "@/components/collection/Datatable.vue";

import GoogleMaps from "@/components/services/GoogleMaps.vue";

import { PropertyManager } from "../interactors/PropertyManager";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connection/AxiosGraphQLConnection";
import { AxiosRestConnection } from "../../gateways/rest/connection/AxiosRestConnection";
import { GmapRestGateway } from "../../gateways/rest/GmapRestGateway";
import { PropertyGraphQLGateway } from "../../gateways/graphql/PropertyGraphQLGateway";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { Authorizator } from "../../common/interactors/Authorizator";
import { AuthorizatorStorageGateway } from "../../gateways/storage/AuthorizatorStorageGateway";
import { LocalStorage } from "../../storage/LocalStorage";
import { PersonEditorGraphQLGateway } from "../../gateways/graphql/PersonEditorGraphQLGateway";
import { PersonEditor } from "../../common/interactors/PersonEditor";

@Component<PropertyDetailView>({
  components: {
    Page,
    Dialog,
    Row,
    Column,
    Section,
    Grid,
    Card,
    ToggleCard,
    TruncatedText,
    Select,
    SearchSelect,
    EditableTextfield,
    EditableTextarea,
    SingleImageUpload,
    RequestButton,
    ResponsiveIconButton,
    ConfirmDialog,
    IconButton,
    EditableSelect,
    EditableCheckbox,
    GoogleMaps,
    Datatable
  },
  watch: {
    "vm.goBack"(go: boolean) {
      if (go) {
        this.$router.back();
      }
    },
    "vm.createdProperty"(propertyId: number) {
      this.$router.push({
        name: "property",
        params: { propertyId: propertyId.toString() }
      });
    },
    "vm.deletedProperty"(propertyId: string) {
      this.$router.push({ name: "properties" });
    }
  }
})
export default class PropertyDetailView extends Vue {
  private vm = new PropertyDetailViewModel();
  private controller: any;

  private getController() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new PropertyDetailController(
      new PropertyDetailPresenter(this.vm),
      new PropertyManager(
        new PropertyGraphQLGateway(connection),
        new GmapRestGateway(
          new AxiosRestConnection(new AxiosLogger(new ConsoleLogger()))
        )
      ),
      new PersonEditor(new PersonEditorGraphQLGateway(connection))
    );
  }

  private get propertyId() {
    return this.$route.params.propertyId;
  }

  private created() {
    this.controller = this.getController();
  }

  private mounted() {
    this.controller.init(this.propertyId);
  }

  private relationshipAction(action: string, relationshipId: string) {
    if (action === "action") {
      this.controller.addRelationshipButtonClicked(relationshipId);
    }
  }
}
