import { render, staticRenderFns } from "./AdditionalTicketData.vue?vue&type=template&id=5a04e800&scoped=true&"
import script from "./AdditionalTicketData.vue?vue&type=script&lang=ts&"
export * from "./AdditionalTicketData.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a04e800",
  null
  
)

export default component.exports