import { render, staticRenderFns } from "./EditableCheckbox.vue?vue&type=template&id=4a37960d&scoped=true&"
import script from "./EditableCheckbox.vue?vue&type=script&lang=ts&"
export * from "./EditableCheckbox.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a37960d",
  null
  
)

export default component.exports