import { TicketEntity } from "../entities/TicketEntity";
import { Ticket } from "./Ticket";

export class TicketCollection {
  public tickets?: Ticket[] = [];

  private totalCount?: number = 0;

  public get notEmpty() {
    return !this.empty;
  }

  public get empty() {
    return (this.tickets?.length ?? 0) === 0;
  }

  public get anySelected() {
    return this.tickets?.some(t => t.selected) ?? false;
  }

  public get length() {
    return this.totalCount || this.actualLength;
  }

  public get actualLength() {
    return this.tickets?.length ?? 0;
  }

  public at(index: number) {
    return this.tickets?.[index];
  }

  public add(ticket: Ticket) {
    this.tickets?.push(ticket);
  }

  public unselectAll() {
    this.tickets?.forEach(t => t.unselect());
  }

  public collect(tickets: Ticket[]) {
    this.tickets = tickets;
  }

  public toEntities(): TicketEntity[] {
    return this.tickets?.map(e => e.toEntity()) ?? [];
  }

  public from(entities?: TicketEntity[], totalCount?: number) {
    this.tickets = entities?.map(e => Ticket.from(e));
    this.totalCount = totalCount;
  }

  public static from(entities?: TicketEntity[], totalCount?: number) {
    const entries = new TicketCollection();
    entries.from(entities, totalCount);
    return entries;
  }

  public static collect(tickets: Ticket[]) {
    const collection = new TicketCollection();
    collection.collect(tickets);
    return collection;
  }
}
