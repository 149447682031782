import { NumericalId } from "@/datastructures/NumericalId";
import { Checklist } from "@/entities/Checklist";
import { ChecklistSection } from "@/entities/ChecklistSection";
import { ChecklistEntry } from "@/entities/ChecklistEntry";
import { Ticket } from "@/entities/Ticket";
import { Assignment } from "@/entities/Assignment";

export class ChecklistManager implements IChecklistManager {
  public constructor(private gateway: ChecklistManagerGateway) {}

  public loadChecklist(assignmentId: NumericalId): Promise<ChecklistData> {
    return this.gateway.loadChecklist(assignmentId).then(assignment => ({
      id: new NumericalId(assignment.checklist.id),
      protectionGoalNiveau: assignment.checklist.protectionGoalNiveau,
      reportId:
        assignment.reports.length > 0
          ? new NumericalId(assignment.reports[0].id)
          : new NumericalId(),
      type: assignment.checklist.type,
      sections: assignment.checklist.sections.map(section => ({
        id: section.id,
        name: section.name,
        state: this.reducePreparationState(section.entries),
        entries: section.entries.map(entry => ({
          id: entry.id,
          name: entry.name,
          state: entry.state,
          preparationState: entry.preparationState,
          ticketCount: entry.ticketCount,
          plantId: entry.plant.id,
          checklistEntryTemplateId: entry.checklistEntryTemplateId
        }))
      }))
    }));
  }

  public loadChecklistForPreparation(assignmentId: NumericalId) {
    return this.gateway
      .loadChecklistForPreparation(assignmentId)
      .then(checklist => ({
        id: new NumericalId(checklist.id),
        sections: checklist.sections.map(section => ({
          id: new NumericalId(section.id),
          name: section.name,
          preparationState: this.reducePreparationState(section.entries)
        }))
      }));
  }

  public async loadChecklistEntryTickets(entryId: NumericalId) {
    let tickets = await this.gateway.loadChecklistEntryTickets(entryId);

    tickets = tickets.sort((a, b) => a.id - b.id);

    return tickets.map(ticket => ({
      id: new NumericalId(ticket.id),
      image: ticket.images.length > 0 ? ticket.images[0].path : "",
      title: ticket.title,
      priority: ticket.priority,
      description: ticket.description
    }));
  }

  public loadChecklistSectionAndEntryNames(assignmentId: NumericalId) {
    return this.gateway
      .loadChecklistSectionAndEntryNames(assignmentId)
      .then(checklist => ({
        id: checklist.id.toString(),
        sections: checklist.sections.map(section => ({
          id: new NumericalId(section.id),
          name: section.name,
          entries: section.entries.map(entry => ({
            id: new NumericalId(entry.id),
            name: entry.name
          }))
        }))
      }));
  }

  public async loadLooseTickets(
    checklistId: NumericalId
  ): Promise<ChecklistTicket[]> {
    return await this.gateway.loadLooseTickets(checklistId);
  }

  public finishPreparation(checklistId: NumericalId): Promise<NumericalId> {
    return this.gateway.finishPreparation(checklistId);
  }

  public updateProtectionGoalNiveau(
    text: string,
    checklistId: NumericalId
  ): Promise<void> {
    return this.gateway.updateProtectionGoalNiveau(text, checklistId);
  }

  public setSectionPreparationState(
    state: string,
    sectionId: NumericalId
  ): Promise<ChecklistSectionPreparationData> {
    return this.gateway
      .setSectionPreparationState(state, sectionId)
      .then(section => ({
        id: sectionId,
        name: section.name,
        preparationState: state
      }));
  }

  public setSectionPreparationStates(
    state: string,
    checklistId: NumericalId,
    sectionId: NumericalId[]
  ): Promise<ChecklistSectionPreparationData[]> {
    return this.gateway
      .setSectionPreparationStates(state, checklistId, sectionId)
      .then(sections =>
        sections.map(section => ({
          id: new NumericalId(section.id),
          name: section.name,
          preparationState: state
        }))
      );
  }

  public setEntryPreparationState(state: string, entryId: NumericalId) {
    return this.gateway
      .setEntryPreparationState(state, entryId)
      .then(entry => new NumericalId(entry.id));
  }

  public setEntryInspectionState(state: string, entryId: NumericalId) {
    return this.gateway
      .setEntryInspectionState(state, entryId)
      .then(entry => new NumericalId(entry.id));
  }

  private reducePreparationState(entries: ChecklistEntry[]): string {
    return entries.reduce(
      (prev, next) => (prev === next.preparationState ? prev : "mixed"),
      entries.length > 0 && entries[0].preparationState
        ? entries[0].preparationState
        : "mixed"
    );
  }
}

export interface IChecklistManager {
  loadChecklist(assignmentId: NumericalId): Promise<ChecklistData>;

  loadChecklistForPreparation(
    assignmentId: NumericalId
  ): Promise<ChecklistPreparationData>;

  loadChecklistEntryTickets(
    entryId: NumericalId
  ): Promise<ChecklistEntryTicketData[]>;

  loadChecklistSectionAndEntryNames(
    assignmentId: NumericalId
  ): Promise<ChecklistName>;

  loadLooseTickets(checklistId: NumericalId): Promise<ChecklistTicket[]>;

  finishPreparation(checklistId: NumericalId): Promise<NumericalId>;
  updateProtectionGoalNiveau(
    text: string,
    checklistId: NumericalId
  ): Promise<void>;

  setSectionPreparationState(
    state: string,
    sectionId: NumericalId
  ): Promise<ChecklistSectionPreparationData>;

  setSectionPreparationStates(
    state: string,
    checklistId: NumericalId,
    sectionId: NumericalId[]
  ): Promise<ChecklistSectionPreparationData[]>;

  setEntryPreparationState(
    state: string,
    entryId: NumericalId
  ): Promise<NumericalId>;

  setEntryInspectionState(
    state: string,
    entryId: NumericalId
  ): Promise<NumericalId>;
}

export interface ChecklistManagerGateway {
  loadChecklist(assignmentId: NumericalId): Promise<Assignment>;
  loadChecklistForPreparation(assignmentId: NumericalId): Promise<Checklist>;
  loadChecklistEntryTickets(entryId: NumericalId): Promise<Ticket[]>;

  loadChecklistSectionAndEntryNames(
    assignmentId: NumericalId
  ): Promise<Checklist>;

  loadLooseTickets(checklistId: NumericalId): Promise<ChecklistTicket[]>;

  finishPreparation(checklistId: NumericalId): Promise<NumericalId>;
  updateProtectionGoalNiveau(
    text: string,
    checklistId: NumericalId
  ): Promise<void>;

  setSectionPreparationState(
    state: string,
    sectionId: NumericalId
  ): Promise<ChecklistSection>;

  setSectionPreparationStates(
    state: string,
    checklistId: NumericalId,
    sectionId: NumericalId[]
  ): Promise<ChecklistSection[]>;

  setEntryPreparationState(
    state: string,
    entryId: NumericalId
  ): Promise<ChecklistEntry>;

  setEntryInspectionState(
    state: string,
    entryId: NumericalId
  ): Promise<ChecklistEntry>;
}

export interface ChecklistData {
  id: NumericalId;
  reportId: NumericalId;
  protectionGoalNiveau: string;
  type: string;
  sections: ChecklistSectionData[];
}

export interface ChecklistSectionData {
  id: number;
  name: string;
  state: string;
  entries: ChecklistEntryData[];
}

export interface ChecklistEntryData {
  id: number;
  name: string;
  state: string;
  preparationState: string;
  ticketCount: number;
  plantId: number;
  checklistEntryTemplateId: string;
}

export interface ChecklistPreparationData {
  id?: NumericalId;
  sections: ChecklistSectionPreparationData[];
}

export interface ChecklistSectionPreparationData {
  id: NumericalId;
  name: string;
  preparationState: string;
}

export interface ChecklistEntryTicketData {
  id: NumericalId;
  image: string;
  title: string;
  priority: string;
  description: string;
}

export interface ChecklistName {
  id: string;
  sections: ChecklistSectionName[];
}

export interface ChecklistSectionName {
  id: NumericalId;
  name: string;
  entries: ChecklistEntryName[];
}

interface ChecklistEntryName {
  id: NumericalId;
  name: string;
}

export interface ChecklistTicket {
  id: string;
  title: string;
}
