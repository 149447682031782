import { Module, ActionContext } from "vuex";

export interface InspectionState {
  reportState: ReportState;
}

interface ReportState {
  reportUrl: string;
}

type InspectionAction = ActionContext<InspectionState, InspectionState>;

const redux: Module<InspectionState, InspectionState> = {
  state: (): InspectionState => ({
    reportState: {
      reportUrl: ""
    }
  }),
  mutations: {
    setReportUrl(state: InspectionState, response: string) {
      state.reportState.reportUrl = response;
    }
  },
  actions: {
    setReportUrl({ commit }: InspectionAction, response: string) {
      commit("setReportUrl", response);
    }
  }
};

export default redux;
