import { Request, Textfield, Date } from "@/forms/ViewModelFormTypes";

export class AssignmentDetailViewModel {
  // Routes
  public goBack = false;
  public createdAssignment = "";
  public deletedAssignment = "";

  // Input
  public number: Textfield = {
    label: "Auftragsnummer",
    value: "",
    loading: false,
    error: ""
  };
  public date: Date = {
    label: "Auftragsdatum",
    value: "",
    text: "",
    loading: false,
    error: ""
  };
  public description: Textfield = {
    label: "Beschreibung",
    value: "",
    loading: false,
    error: ""
  };

  // Requests
  public loadAssignmentRequest: Request = { loading: false, error: "" };
  public editingDataRequest: Request = { loading: false, error: "" };
  public createAssignmentRequest: Request = { loading: false, error: "" };
  public deleteAssignmentRequest: Request = { loading: false, error: "" };

  // States (Visibility, Activated, Disabled, ...)
  public editingDataDisabled = false;
  public editingDataDeactivated = false;
  public editingData = false;
  public deleteAssignmentButtonVisible = false;
  public createAssignmentButtonVisible = false;
  public createAssignmentButtonDisabled = false;
}
