import { Comment } from "@/common/shared/models/Comment";
import { CommentRepository } from "@/common/shared/repositories/commentRepository/CommentRepository";
import { UseCase } from "@/shared/datastructures/UseCase";
import { RequestHandler } from "@/shared/utils/RequestHandler";

export class DeleteCommentUseCase extends UseCase<
  Comment,
  Comment | undefined
> {
  public constructor(private commentRepo: CommentRepository) {
    super();
  }

  public async do(comment: Comment): Promise<Comment | undefined> {
    if (!comment.exists) {
      throw new Error("Der zu löschende Kommentar muss eine ID haben");
    }

    await RequestHandler.handle(
      this.commentRepo.deleteComment(comment),
      this.response
    );

    return this.response.result;
  }
}
